import React from 'react';
import { HotKeyHandler } from '../../types/CoreTypes';
import {
  ActionSubscriber,
  MagicHotkeyProps,
  withMagicHotkey,
} from '../../helpers/MagicHotkeyProvider';
import { isEqual } from 'lodash';

interface BaseProps extends MagicHotkeyProps {
  enabled: boolean;
  name: string;
  handlers: HotKeyHandler[];
  // catchAllHandler?: (e: KeyboardEvent) => void;
}
interface Props extends BaseProps {
  forwardedRef?: React.Ref<any>;
}

// export interface ModifierInt {
//   ctrlKey?: boolean;
//   metaKey?: boolean;
//   shiftKey?: boolean;
// }

class ActionOnKeyPress extends React.Component<Props> {
  // handleKeyPress = (e: KeyboardEvent) => {
  //   const { catchAllHandler } = this.props;
  //   // handlers.forEach(handler => {
  //   //   if (this.isKeyMatch(e, handler.combination.key)) {
  //   //     if (this.isModifierMatch(e, handler.combination.modifiers)) {
  //   //       console.log(`${this.props.logPrefix}: matched with`, handler);
  //   //       e.preventDefault();
  //   //       handler.action();
  //   //     }
  //   //   }
  //   // });
  //   if (catchAllHandler) {
  //     console.log(`${this.props.name}: handleKeyPress catchall...`);
  //     catchAllHandler(e);
  //   }
  // };

  private actionSubscriber?: ActionSubscriber;

  componentDidUpdate(prevProps: Props) {
    // special handling for Always Open modal
    // if path or context changed
    if (
      this.props.enabled !== prevProps.enabled ||
      !isEqual(this.props.handlers, prevProps.handlers)
    ) {
      if (this.props.enabled) {
        // console.log(`loading ${this.props.logPrefix} key listener...`);
        this.actionSubscriber?.unsubscribe();
        this.actionSubscriber = this.props.registerActions(
          this.props.name,
          this.props.handlers
        );
        // document.addEventListener('keydown', this.handleKeyPress, false);
      } else {
        // console.log(`unloading ${this.props.logPrefix} key listener...`);
        this.actionSubscriber?.unsubscribe();
        // document.removeEventListener('keydown', this.handleKeyPress, false);
      }
    }
  }

  componentDidMount() {
    if (this.props.enabled) {
      this.actionSubscriber = this.props.registerActions(
        this.props.name,
        this.props.handlers
      );
      // console.log(`${this.props.logPrefix}: register key listener`);
      // document.addEventListener('keydown', this.handleKeyPress, false);
    }
  }

  componentWillUnmount() {
    if (this.props.enabled) {
      this.actionSubscriber?.unsubscribe();
      // console.log(`${this.props.logPrefix}: de-register key listener`);
      // document.removeEventListener('keydown', this.handleKeyPress, false);
    }
  }

  render() {
    return null;
  }
}

export default withMagicHotkey(ActionOnKeyPress);
