import { IonBadge, IonIcon, IonListHeader } from '@ionic/react';
import React from 'react';
import { ProjectPositionContextEnum } from '../../types/CoreTypes';
import ProjectListView from './ProjectListView';
import { withStorage } from '../../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import { StorageDeps } from '../../helpers/InitStorage';
import { CountersProps, withCounters } from '../../helpers/CountersProvider';
import { projectStatuses, ProjectStatusKey } from '../../helpers/ProjectHelper';
import { isEqual } from 'lodash';
import { ListNavigationApi } from '../Common/ListNavigationCommon';

interface Props extends StorageDeps, WorkspaceProps, CountersProps {
  listNavigationApi?: ListNavigationApi;
  statuses: ProjectStatusKey[];
}

class ProjectStatusListDesktop extends React.Component<Props> {
  private readonly listRef: Map<ProjectStatusKey, React.RefObject<any>>;

  private readonly setCounterFnMap: Map<ProjectStatusKey, (cnt: number) => void>;

  constructor(props: Props) {
    super(props);
    const rs: [ProjectStatusKey, React.RefObject<any>][] = Object.keys(
      projectStatuses
    ).map((key) => [key as ProjectStatusKey, React.createRef()]);
    this.listRef = new Map(rs);

    const setCounterFns: [ProjectStatusKey, (cnt: number) => void][] = Object.keys(
      projectStatuses
    ).map((key) => [
      key as ProjectStatusKey,
      this.setCounter(key as ProjectStatusKey),
    ]);
    this.setCounterFnMap = new Map(setCounterFns);

    this.reportCounters(props);
  }

  getCount(statusKey: ProjectStatusKey): number | undefined {
    const counters = this.props.counters[this.props.context.id];
    if (!counters) return undefined;
    return counters.projectSources[statusKey]?.size;
  }

  setCounter = (statusKey: ProjectStatusKey) => (cnt: number) => {
    const { listNavigationApi } = this.props;
    listNavigationApi?.setCounter(statusKey, cnt);
  };

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: any,
    nextContext: any
  ): boolean {
    return !isEqual(nextProps, this.props);
  }

  renderBlock = (statusKey: ProjectStatusKey) => {
    const { listNavigationApi } = this.props;
    const status = projectStatuses[statusKey];
    const count = this.getCount(statusKey) || 0;
    const selectedIdxForBlock =
      listNavigationApi?.selectedCollectionName === statusKey
        ? listNavigationApi.selectedCollectionIdx
        : undefined;
    const filter = projectStatuses[statusKey].filter;
    if (!filter) {
      throw new Error(
        'misconfiguration: project status used in filter that does not have a filter'
      );
    }
    return (
      <div key={statusKey}>
        <IonListHeader>
          <span>
            <IonIcon icon={status.icon} />
            &nbsp;
            <span>{status.label}</span>
            &nbsp;
            <IonBadge>{count}</IonBadge>
          </span>
        </IonListHeader>
        {count > 0 && (
          <ProjectListView
            setCounter={this.setCounterFnMap.get(statusKey)}
            getRef={
              listNavigationApi?.getRef
                ? listNavigationApi.getRef(statusKey)
                : undefined
            }
            positionContext={ProjectPositionContextEnum.STATUS}
            selectedIdx={selectedIdxForBlock}
            filter={filter}
          />
        )}
      </div>
    );
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      !isEqual(
        prevProps.listNavigationApi?.setCounter,
        this.props.listNavigationApi?.setCounter
      ) ||
      !isEqual(prevProps.counters, this.props.counters) ||
      !isEqual(prevProps.context.id, this.props.context.id)
    ) {
      this.reportCounters(this.props);
    }
  }

  reportCounters(nextProps: Readonly<Props>) {
    nextProps.statuses.forEach((statusKey) => {
      const count = this.getCount(statusKey) || 0;
      if (count === 0) {
        const fn = this.setCounterFnMap.get(statusKey);
        if (fn) fn(0);
      }
    });
  }

  // renderList = (statusKey: ProjectStatusKey) => {
  //   const status = projectStatuses[statusKey];
  //   const count = this.getCount(statusKey) || 0;
  //   return (
  //     <div key={`project-status-list-${statusKey}`}>
  //       <IonListHeader>
  //         <span>
  //           <IonIcon icon={status.icon} />
  //           &nbsp;
  //           <span>{status.label}</span>
  //           &nbsp;
  //           <IonBadge>{count}</IonBadge>
  //         </span>
  //       </IonListHeader>
  //       {count > 0 && <div>{this.renderBlock(statusKey)}</div>}
  //     </div>
  //   );
  // };

  render() {
    return <>{this.props.statuses.map(this.renderBlock)}</>;
  }
}

export default withCounters(withStorage(withWorkspace(ProjectStatusListDesktop)));
