import React from 'react';
import { TagType } from '../../types/CoreTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import TagListElementMobile from './TagListElementMobile';
import {
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from '@ionic/react';
import { create, trash } from 'ionicons/icons';
import { Routes } from '../../lib/routes';
import deepEqual from 'fast-deep-equal';

interface Props extends RouteComponentProps {
  tag: TagType;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  onDelete?: (id: string) => void;
}

class TagListRowMobile extends React.Component<Props> {
  private slidingRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.slidingRef = React.createRef();
  }

  closeOpenedSlider() {
    this.slidingRef.current && this.slidingRef.current.closeOpened();
  }

  render() {
    const { tag, isSelected, onAfterNavigate, onDelete } = this.props;

    return (
      <IonItemSliding ref={this.slidingRef}>
        <TagListElementMobile
          tag={tag}
          isSelected={isSelected}
          onAfterNavigate={onAfterNavigate}
        />

        <IonItemOptions side="end">
          <IonItemOption
            routerLink={`/${Routes.tags}/edit/${tag.id}`}
            routerDirection={'root'}
            color="warning"
          >
            <IonIcon style={{ fontSize: '25px' }} slot="start" icon={create} />
          </IonItemOption>
          {onDelete && (
            <IonItemOption
              color="danger"
              onClick={() => {
                this.closeOpenedSlider();
                onDelete(tag.id);
              }}
            >
              <IonIcon style={{ fontSize: '25px' }} slot="start" icon={trash} />
            </IonItemOption>
          )}
        </IonItemOptions>
      </IonItemSliding>
    );
  }
}

const Memoized = React.memo(TagListRowMobile, deepEqual);

export default withRouter(Memoized);
