import React from 'react';
import { IonApp, IonLoading, IonSplitPane, IonToast } from '@ionic/react';
import { Auth, ContextType, ItemType } from './types/CoreTypes';
import { setUpLocal, setUpSync } from './lib/data/pouchdb';
import { RxChangeEvent } from 'rxdb/dist/types/core';
import { ClientTypeProvider } from './helpers/ClientTypeProvider';
import { ToastProvider } from './helpers/useToast';
import { ModalsProvider } from './helpers/ModalsProvider';
import { WorkspaceProvider } from './helpers/WorkspaceProviders';
import { CountersProvider } from './helpers/CountersProvider';
import { MagicHotkeyProvider } from './helpers/MagicHotkeyProvider';
import { GlobalActionsProvider } from './helpers/GlobalActionsProvider';
import Menu from './components/Menu';
import { Router } from './components/Router';
import { DbDeps, withDb } from './helpers/DbProvider';
import { AuthUserProps, withAuthUser } from './helpers/AuthUserProvider';
import history from './helpers/history';
import { SyncStatusProps, withSyncStatusState } from './helpers/SyncStatusProvider';
import { IonReactRouter } from './ionicFork/Router/IonReactRouter';
import { logDebug } from './lib/logger';
import { StorageDeps } from './helpers/InitStorage';
import { Subscription } from 'rxjs';
import { withStorage } from './helpers/StorageProviders';
import { DateProvider } from './helpers/DateProvider';
import FreeTierProvider from './helpers/FreeTierProvider';
import { HelpProvider } from './helpers/HelpProvider';

const SHOW_ERROR_MS = 5000; // 5 sec in millis
const SHOW_SUCCESS_MS = 6000; // 6 sec in millis

interface Props extends StorageDeps, SyncStatusProps, DbDeps, AuthUserProps {
  isLocal: boolean;
  isPaid: boolean;
}

interface State {
  context?: ContextType;
  allContexts?: ContextType[];
  isFirstSyncing: boolean;
  errorMessage: string | null;
  successMessage: string | null;
}

class MainAppInner extends React.Component<Props, State> {
  readonly state: State = {
    isFirstSyncing: false,
    errorMessage: null,
    successMessage: null,
  };

  sub?: Subscription;

  getFirstOrSavedContext = async (contextId: string | null): Promise<void> => {
    logDebug(['sync'], 'getFirstOrSavedContext...');
    const contexts = await this.props.repos.contextRepo.getAll();
    if (contexts.length) {
      logDebug(['sync'], 'found some contexts, checking...');
      let maybeContext;
      if (contextId) {
        const saved = contexts.filter((c) => c.id === contextId)[0];
        if (saved) {
          maybeContext = saved;
        }
      }
      if (!maybeContext) {
        maybeContext = contexts[0];
      }
      if (maybeContext) {
        logDebug(['sync'], 'found the context!');
        this.setState({
          context: maybeContext,
          allContexts: contexts,
        });
        return;
      }
    }
    logDebug(['sync'], 'creating new context');
    await this.props.repos.contextRepo.create({ name: 'Default' });
    return this.getFirstOrSavedContext(null);
  };

  loadData = async () => {
    logDebug(['sync', 'auth'], 'loadData...');
    this.props.localSettingsRepo.getContextId().then((contextId) => {
      this.getFirstOrSavedContext(contextId);
    });
  };

  startAppUsingLocalMode = async () => {
    logDebug(['sync', 'auth'], 'use local mode');
    await setUpLocal(this.props.localDb).then(this.loadData);
  };

  startAppUsingSyncMode = (
    firstSync: boolean,
    auth: Auth,
    userId: string,
    deviceId: string
  ) => {
    logDebug(['sync', 'auth'], 'use syncing');
    // if it's the first sync, rendering page won't make sense,
    // so delay loading until we sync
    // const remotes: Remotes = JSON.parse(token);
    if (firstSync) {
      logDebug(['sync', 'auth'], 'first syncing...');
    }
    this.setState({
      isFirstSyncing: firstSync,
    });
    return (
      setUpSync({
        localDb: this.props.localDb,
        auth,
        deviceId,
        userId,
        isFirst: firstSync,
        setSyncStatus: this.props.setSyncStatus,
        reLogIn: () => this.props.reloadAuthentication(true),
      })
        .then((count) => {
          return this.loadData().then(() => {
            logDebug(['sync', 'auth'], 'done with first syncing!');
            return count;
          });
        })
        .then((importedCount) => {
          if (firstSync) {
            let msg = 'Sync was successfully set up.';
            if (importedCount > 100) {
              msg += ` ${importedCount} items imported. It may take a few moments to index the database.`;
            }
            this.setState({
              successMessage: msg,
            });
          }
        })
        .catch((err) => console.error(err))
        // .then(() => fixTasks(taskRepo))
        .finally(() => {
          this.setState({
            isFirstSyncing: false,
          });
        })
    );
    // } else {
    //   // if we synced before, then proceed with syncing in the BG, and load data
    //   setUpSync(localDb, remotes);
    //   return loadData();
    // }
  };

  handleSaveContext = (context: ContextType) => {
    this.setState({
      context,
    });
    this.props.localSettingsRepo.saveContextId(context.id);
  };

  updateContexts = async () => {
    const contexts = await this.props.repos.contextRepo.getAll();
    this.setState({
      allContexts: contexts,
    });
  };

  setErrorMessage = (msg: string) => {
    this.setState({ errorMessage: msg });
  };

  setSuccessMessage = (msg: string) => {
    this.setState({ successMessage: msg });
  };

  eraseErrorMessage = () => {
    this.setState({ errorMessage: null });
  };

  eraseSuccessMessage = () => {
    this.setState({ successMessage: null });
  };

  handleDbChangeEvent = async (evt: RxChangeEvent) => {
    if ([ItemType.context].includes(evt.collectionName as ItemType)) {
      await this.updateContexts();
    }
  };

  componentDidMount(): void {
    this.initSync();
    this.sub = this.props.subscribeForDbChanges(this.handleDbChangeEvent);
  }

  componentWillUnmount() {
    this.sub?.unsubscribe();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.userInfo?.auth?.token !== prevProps.userInfo?.auth?.token ||
      this.props.isLocal !== prevProps.isLocal
    ) {
      this.initSync();
    }
  }

  initSync = async () => {
    logDebug(['sync'], 'MainAppInner: initSync');
    if (this.props.isLocal) {
      await this.startAppUsingLocalMode();
      return;
    }

    if (!this.props.userInfo?.auth) {
      logDebug(['sync'], 'sync mode and no user info');
      await this.startAppUsingLocalMode();
      return;
      // throw new Error('sync mode and no user info');
    }

    const {
      token,
      encryptionPassword,
      activeEntitlement,
      userId,
      userName,
      userEmail,
    } = this.props.userInfo.auth;

    const contexts = await this.props.repos.contextRepo.getAll();
    const isFirstSync = contexts.length === 0;
    if (this.props.isLocal) {
      await this.startAppUsingLocalMode();
      return;
    }

    const deviceId = await this.props.localSettingsRepo.getDeviceId();

    if (!this.props.userInfo?.auth) {
      throw new Error('sync mode and no user info');
    }
    if (!deviceId) {
      throw new Error('sync mode and no device ID');
    }
    await this.startAppUsingSyncMode(
      isFirstSync,
      {
        userId,
        token,
        encryptionPassword,
        activeEntitlement,
        userName,
        userEmail,
      },
      userId,
      deviceId
    );
  };

  render() {
    // let {isLocal} = this.props;
    // const {
    //   subscribeForDbChanges,
    //   repos: {contextRepo},
    //   localSettingsRepo,
    // } = useStorage();
    // const {syncStatus, setSyncStatus} = useSyncStatusState();
    // const {localDb} = useDb();
    // const {userInfo} = useAuthUser();

    // const getFirstOrSavedContext = useCallback(
    //   ,
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    //   [contextRepo]
    // );
    //
    // const loadData = useCallback(
    //   ,
    //   [getFirstOrSavedContext, localSettingsRepo]
    // );

    // const startAppUsingLocalMode = useCallback(
    //   ,
    //   [localDb, loadData]
    // );

    // const startAppUsingSyncMode = useCallback(
    //   ,
    //   [localDb, setSyncStatus, loadData]
    //   // [periodicSync, loadData]
    // );

    // const handleSaveContext = useCallback(
    //   ,
    //   [setContext, localSettingsRepo]
    // );

    // const handleImportCallBack = useCallback((e: ImportCallBackEvent) => {
    //   if (e.operation === SyncOperation.SYNCING) {
    //     setIsSyncing(!e.done);
    //   }
    //   if (e.operation === SyncOperation.UPLOADING) {
    //     setIsUploading(!e.done);
    //   }
    // }, []);
    //
    // useEffect(() => {
    //   ()();
    //   // });
    //   // }, 100);
    // }, [
    //   contextRepo,
    //   isLocal,
    //   startAppUsingLocalMode,
    //   startAppUsingSyncMode,
    //   userInfo,
    // ]);

    // useEffect(() => {
    //   // function handleDbChangeEvent(evt: RxChangeEvent) {
    //   //   if ([ItemType.context].includes(evt.collectionName as ItemType)) {
    //   //     updateContexts();
    //   //   }
    //   // }
    //
    //   const sub = subscribeForDbChanges(handleDbChangeEvent);
    //   return () => {
    //     sub.unsubscribe();
    //   };
    // }, [updateContexts, subscribeForDbChanges]);

    if (
      this.state.isFirstSyncing ||
      !this.state.context ||
      !this.state.allContexts
    ) {
      return (
        <IonApp>
          <IonLoading
            isOpen={this.state.isFirstSyncing}
            message={'Initial syncing, it may take a while...'}
            duration={180000}
          />
          <IonLoading
            isOpen={!this.state.isFirstSyncing && !this.state.context}
            message={'Loading contexts...'}
            duration={180000}
          />
        </IonApp>
      );
    }

    if (!this.props.syncStatus) {
      throw new Error('no syncStatus');
    }

    return (
      <IonApp>
        <IonToast
          color="danger"
          isOpen={Boolean(this.state.errorMessage)}
          onDidDismiss={this.eraseErrorMessage}
          message={this.state.errorMessage || ''}
          position="top"
          duration={SHOW_ERROR_MS}
        />
        <IonToast
          color="success"
          isOpen={Boolean(this.state.successMessage)}
          onDidDismiss={this.eraseSuccessMessage}
          message={this.state.successMessage || ''}
          position="top"
          duration={SHOW_SUCCESS_MS}
        />
        <ClientTypeProvider>
          <DateProvider>
            <ToastProvider>
              <ModalsProvider>
                <WorkspaceProvider
                  context={this.state.context}
                  setContext={this.handleSaveContext}
                >
                  <IonReactRouter history={history}>
                    <CountersProvider allContexts={this.state.allContexts}>
                      <FreeTierProvider isPaid={this.props.isPaid}>
                        <HelpProvider>
                          <MagicHotkeyProvider>
                            <GlobalActionsProvider>
                              <IonSplitPane
                                when="(min-width: 1280px)"
                                contentId="main"
                              >
                                <Menu
                                  allContexts={this.state.allContexts}
                                  syncStatus={this.props.syncStatus}
                                />

                                <Router />
                              </IonSplitPane>
                            </GlobalActionsProvider>
                          </MagicHotkeyProvider>
                        </HelpProvider>
                      </FreeTierProvider>
                    </CountersProvider>
                  </IonReactRouter>
                </WorkspaceProvider>
              </ModalsProvider>
            </ToastProvider>
          </DateProvider>
        </ClientTypeProvider>
      </IonApp>
    );
  }
}

export default withAuthUser(withDb(withSyncStatusState(withStorage(MainAppInner))));
