export enum Routes {
  next = 'next',
  waiting = 'waiting',
  focus = 'focus',
  sometime = 'sometime',
  inbox = 'inbox',
  projects = 'projects',
  tasks = 'tasks',
  tags = 'tags',
  agenda = 'agenda',
  archive = 'archive',
  review = 'review',
  contexts = 'contexts',
  purchase = 'purchase',
  settings = 'settings',
  help = 'help',
  gantt = 'gantt',
}
