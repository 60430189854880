import React from 'react';
import { useClientType } from '../../helpers/ClientTypeProvider';

export interface Props {
  children: React.ReactNode;
}

const HotKeyComment = ({ children }: Props) => {
  const { isDesktop } = useClientType();
  if (!isDesktop) return null;
  return <div className="hot-key-comment">{children}</div>;
};

export default HotKeyComment;
