import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  Mention,
  MentionItem,
  MentionsInput,
  SuggestionDataItem,
} from 'react-mentions';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import { generateColorFromString } from '../../helpers/colors';
import { getIconById } from '../../helpers/icons';
import { ProjectPositionContextEnum, ProjectStatus } from '../../types/CoreTypes';
import { useDate } from '../../helpers/DateProvider';

interface BaseProps {
  value: string;
  onChange: (val: string) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

export enum MentionType {
  project = 'project',
  tag = 'tag',
}

export enum Ops {
  due = 'due',
  schedule = 'schedule',
  recurring = 'recurring',
  project = 'project',
  tags = 'tags',
  priority = 'priority',
  time = 'time',
  energy = 'energy',
}

// const prioritiesKeys = Object.keys(Priority).filter(
//   k => typeof Priority[k as any] === 'number'
// );
// const priorities = prioritiesKeys.map(k => ({
//   display: getPriorityLabel((Priority[k as any] as unknown) as Priority),
//   id: Priority[k as any]
// }));
const ops: { id: Ops; display: string }[] = [
  {
    id: Ops.due,
    display: 'set due date',
  },
  {
    id: Ops.schedule,
    display: 'set scheduled date',
  },
  {
    id: Ops.priority,
    display: 'set priority',
  },
];

const TaskNameMention = ({ onChange, value, forwardRef }: Props) => {
  const [allProjects, setAllProjects] = useState<SuggestionDataItem[]>([]);
  const [allTags, setAllTags] = useState<SuggestionDataItem[]>([]);

  const {
    repos: { projectRepo, tagRepo },
  } = useStorage();
  const { context } = useWorkspace();

  const { dateFormatted } = useDate();

  useEffect(() => {
    const sub = projectRepo
      .getAllForFilter$(
        context.id,
        dateFormatted,
        { notStatus: ProjectStatus.STATUS_ARCHIVE },
        ProjectPositionContextEnum.STATUS
      )
      .subscribe((projects) => {
        setAllProjects(
          projects.map((project) => ({
            id: project.id,
            display: project.name,
            color: generateColorFromString(project.id),
          }))
        );
      });
    return () => sub.unsubscribe();
  }, [dateFormatted, context.id, projectRepo]);

  useEffect(() => {
    const sub = tagRepo.getAllForFilter$(context.id, {}).subscribe((tags) => {
      setAllTags(
        tags.map((tag) => ({
          id: tag.id,
          display: tag.name,
          color: tag.color,
          icon: getIconById(tag.icon),
        }))
      );
    });
    return () => sub.unsubscribe();
  }, [context.id, tagRepo]);

  const handleChange = useCallback(
    (
      event: { target: { value: string } },
      newValue: string,
      newPlainTextValue: string,
      mentions: MentionItem[]
    ) => {
      onChange(newValue);
    },
    [onChange]
  );

  // const generateDateSuggestions = useCallback(
  //   (text: string): SuggestionDataItem[] => {
  //     console.log('$$$', 'generateDateSuggestions', text);
  //     const dates = getDateSuggestions(text);
  //     console.log('$$$', 'dates', dates);
  //     return dates.map(d => ({
  //       id: d.date.getTime(),
  //       display: formatDate(d)
  //     }));
  //   },
  //   []
  // );

  // renderSuggestion

  return (
    <div>
      <MentionsInput
        // @ts-ignore
        inputRef={forwardRef}
        value={value}
        onChange={handleChange}
        placeholder="Task name, use shortcuts"
        className="mentions"
        maxLength={1000}
        allowSpaceInQuery={true}
      >
        <Mention
          markup={`@{{${MentionType.project}||__id__||__display__}}`}
          trigger="@"
          data={[...ops, ...allProjects]}
          displayTransform={(id, d) => `${d}`}
          className="mentions__project"
          appendSpaceOnAdd
        />
        <Mention
          markup={`@{{${MentionType.tag}||__id__||__display__}}`}
          trigger="#"
          data={allTags}
          displayTransform={(id, d) => `# ${d}`}
          className="mentions__tag"
          appendSpaceOnAdd
        />
      </MentionsInput>
    </div>
  );
};

const Memoized = React.memo(TaskNameMention);

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <Memoized forwardRef={ref} {...props} />
));
