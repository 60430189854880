import React, { useCallback, useEffect, useState } from 'react';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { RxChangeEvent } from 'rxdb/dist/types/core';
import { DataPoint, ItemType } from '../../types/CoreTypes';
import WideMessage from '../Common/WideMessage';
import { colors } from '../../helpers/styles';
import {
  checkmarkCircle,
  chevronForwardCircle,
  medal,
  rocket,
  thumbsUp,
} from 'ionicons/icons';
import DailyHistogram from '../Common/DailyHistogram';
import { groupBy, last } from 'lodash';
import { format } from 'date-fns';
import { isoDaysShort } from '../../lib/DateHelper';
import subDays from 'date-fns/subDays';
import { useDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';

function getMessageAndIcon(created: number): { message: string; icon: string } {
  if (created > 10) {
    return {
      icon: medal,
      message: `You created ${created} tasks today, you're killing it!`,
    };
  }
  if (created > 5) {
    return {
      icon: rocket,
      message: `You created ${created} tasks today, great job!`,
    };
  }
  if (created > 1) {
    return {
      icon: thumbsUp,
      message: `You created ${created} tasks today, nice!`,
    };
  }
  if (created > 0) {
    return { icon: checkmarkCircle, message: `You created one task today!!` };
  }
  return {
    icon: chevronForwardCircle,
    message: 'Nothing created today yet, but you still have time!',
  };
}

const StatsCreatedToday = () => {
  const {
    subscribeForDbChanges,
    repos: { taskRepo },
  } = useStorage();
  const { context } = useWorkspace();
  const { dateFormatted, date } = useDate();

  const [dataPoints, setDataPoints] = useState<DataPoint[] | undefined>(undefined);

  const loadData = useCallback(
    function loadData() {
      logDebug([], 'load data: StatsCreatedToday');
      const today = date;
      taskRepo
        .getAllForFilterWithoutSort(
          context.id,
          {
            createdAt: {
              from: subDays(startOfDay(today), 6).getTime(),
              to: endOfDay(today).getTime(),
            },
          },
          dateFormatted
        )
        .then((resx) =>
          groupBy(resx, (r) => (r.createdAt ? format(r.createdAt, 'i') : null))
        )
        .then((d) =>
          Object.entries(d).map(
            ([key, tasks]) => [parseInt(key), tasks.length] as [number, number]
          )
        )
        .then((s) => new Map(s))
        .then((counts) => {
          const dow = parseInt(format(today, 'i')); // 1 ... 7
          const pastDays: number[] = [];
          for (let diff = 6; diff >= 0; diff--) {
            let newDow = dow - diff;
            if (newDow <= 0) {
              newDow = 7 + newDow;
            }
            pastDays.push(newDow);
          }
          const dataPoints = pastDays.map((d) => ({
            label: isoDaysShort[d - 1],
            value: counts.get(d) || 0,
          }));
          setDataPoints(dataPoints);
        });
    },
    [date, taskRepo, context.id, dateFormatted]
  );

  useEffect(() => {
    function handleDbChangeEvent(evt: RxChangeEvent) {
      if (evt.operation === 'INSERT') {
        if ([ItemType.task].includes(evt.collectionName as ItemType)) {
          loadData();
        }
      }
    }

    const sub = subscribeForDbChanges(handleDbChangeEvent);
    return () => {
      sub.unsubscribe();
    };
  }, [loadData, subscribeForDbChanges]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (dataPoints === undefined) return null;
  const todaysCounts = last(dataPoints)?.value || 0;
  const { message, icon } = getMessageAndIcon(todaysCounts);
  // if (statsCount === 0) return null;
  return (
    <WideMessage text={message} icon={icon} color={colors.grayOut}>
      <DailyHistogram color={colors.grayOut} dataPoints={dataPoints} />
    </WideMessage>
  );
};

export default StatsCreatedToday;
