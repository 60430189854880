import { IonActionSheet } from '@ionic/react';
import {
  CMD_OR_CTRL,
  CMD_OR_CTRL_SHIFT,
  HotKeyHandler,
  Key,
  KeyModifier,
  Nullable,
  PriorityValues,
  RecurringOptions,
  TagAssignment,
  TaskEnergy,
  TaskFields,
  TaskStatus,
  TaskTime,
} from '../../types/CoreTypes';
import { TaskHandlersInner } from '../../types/TaskHandlers';
import EditPriorityModal from '../propertyEditModals/EditPriorityModal';
import { getTaskStatusLabel, getTimeLabel } from '../../helpers/CopyHelper';
import EditTagsModal from '../propertyEditModals/EditTagsModal';
import EditProjectModal from '../propertyEditModals/EditProjectModal';
import EditNameModal from '../propertyEditModals/EditNameModal';
import { TASK_NAME_MAX_LENGTH } from '../../lib/Constants';
import EditDateModal from '../propertyEditModals/EditDateModal';
import React from 'react';
import EditStateModal from '../propertyEditModals/EditStateModal';
import EditTimeModal from '../propertyEditModals/EditTimeModal';
import EditEnergyModal from '../propertyEditModals/EditEnergyModal';
import RecurringModal from '../propertyEditModals/RecurringModal';
import { ClientTypeProps, withClientType } from '../../helpers/ClientTypeProvider';
import { formatDateWithoutTime } from '../../helpers/RecurringHelper';
import { logDebug } from '../../lib/logger';

interface Props extends ClientTypeProps {
  selectedTask?: TaskFields;
  onClose: () => void;
  children: (api: TaskHandlersInner) => React.ReactNode;

  handleChangePriority: (priorityValues: Nullable<PriorityValues>) => void;
  handleChangeTags: (tags: TagAssignment[] | undefined) => void;
  handleChangeProjectId: (projectId: string | undefined) => void;
  handleChangeName: (name: string) => void;
  handleRecurring: (recurringOptions: RecurringOptions | undefined) => void;
  handleReschedule: (date: string | undefined) => void;
  handleChangeDue: (date: string | undefined) => void;
  handleChangeTime: (time: Nullable<TaskTime>) => void;
  handleChangeEnergy: (energy: Nullable<TaskEnergy>) => void;
  handleChangeState: (status: TaskStatus | undefined) => void;
}

enum ChangeStatus {
  state = 'state',
  recurring = 'recurring',
  schedule = 'schedule',
  dueDate = 'dueDate',
  time = 'time',
  priority = 'priority',
  energy = 'energy',
  tags = 'tags',
  project = 'project',
  name = 'name',
}

interface State {
  changeStatus?: ChangeStatus;
  resolve?: () => void;
  // completedTask?: {
  //   task: TaskFields;
  //   prevState: TaskStatus;
  //   prevScheduled?: number;
  // };
}

class TaskActionsUI extends React.PureComponent<Props, State> {
  state: State = {};

  setChangeStatus = (changeStatus?: ChangeStatus) => {
    this.setState({ changeStatus });
  };

  private readonly modalRefs: Map<ChangeStatus, React.RefObject<any>> = new Map();

  constructor(props: Props) {
    super(props);

    Object.keys(ChangeStatus).forEach((k) => {
      this.modalRefs.set((k as unknown) as ChangeStatus, React.createRef());
    });
  }

  rescheduleHandler = () => {
    this.show(ChangeStatus.schedule).then(() => {
      this.focusRef(ChangeStatus.schedule);
    });
  };
  recurringHandler = () => {
    this.show(ChangeStatus.recurring).then(() => {
      this.focusRef(ChangeStatus.recurring);
    });
  };
  startStateChangeHandler = () => {
    this.show(ChangeStatus.state).then(() => {
      if (this.props.isDesktop) {
        this.focusRef(ChangeStatus.state);
      }
    });
  };
  changeDueHandler = () => {
    if (this.props.selectedTask?.recurringOptions) return;
    this.show(ChangeStatus.dueDate).then(() => {
      this.focusRef(ChangeStatus.dueDate);
    });
  };
  changeTimeHandler = () => {
    this.show(ChangeStatus.time).then(() => {
      if (this.props.isDesktop) {
        this.focusRef(ChangeStatus.time);
      }
    });
  };
  changeEnergyHandler = () => {
    this.show(ChangeStatus.energy).then(() => {
      if (this.props.isDesktop) {
        this.focusRef(ChangeStatus.energy);
      }
    });
  };
  changePriorityHandler = () => {
    this.show(ChangeStatus.priority);
  };
  changeTagsHandler = () => {
    this.show(ChangeStatus.tags).then(() => {
      this.focusRef(ChangeStatus.tags);
    });
  };
  changeProjectHandler = () => {
    this.show(ChangeStatus.project).then(() => {
      this.focusRef(ChangeStatus.project);
    });
  };
  changeNameHandler = () => {
    this.show(ChangeStatus.name).then(() => {
      this.focusRef(ChangeStatus.name);
    });
  };
  basicCloseHandler = () => {
    // this.setSelectedTask(undefined);
    this.setChangeStatus(undefined);
    this.props.onClose();

    // for (const ref in this.modalRefs) {
    //   this.modalRefs.get((ref as unknown) as ChangeStatus)?.current?.blur();
    // }
  };

  makeHandleChangeState = (status: TaskStatus) => () => {
    this.props.handleChangeState(status);
  };

  show = (changeStatus: ChangeStatus) => {
    return new Promise<void>((resolve) => {
      this.setState({
        changeStatus,
        resolve,
        //         setTimeout(resolve, 100);
      });
    });
  };

  componentDidUpdate() {
    const { resolve } = this.state;
    if (resolve) {
      this.setState({ resolve: undefined });
      setTimeout(resolve, 250);
    }
  }

  getRef = (status: ChangeStatus) => this.modalRefs.get(status);
  focusRef = (status: ChangeStatus) => {
    // console.log('$$$', this.modalRefs);
    // debugger;
    const current = this.modalRefs.get(status)?.current;
    if (current) {
      logDebug([], `$$$ focus: ${status}`, current);
      if (current.setFocus) {
        current.setFocus();
      } else if (current.focus) {
        current.focus();
      } else {
        throw Error('Cannot focus: dont know how to focus!');
      }
    } else {
      throw Error(`Cannot focus ${status}: no current ref!`);
    }
  };

  isChangeStatus = (desiredStatus: ChangeStatus) => {
    return this.state.changeStatus === desiredStatus;
  };

  getValueForModal = <K extends keyof TaskFields, A extends TaskFields[K]>(
    desiredStatus: ChangeStatus,
    getter: (t: TaskFields) => A
  ): A | undefined => {
    if (!this.isChangeStatus(desiredStatus)) return undefined;
    if (!this.props.selectedTask) return undefined;
    return getter(this.props.selectedTask);
  };

  getHandlers = (): HotKeyHandler[] => {
    return [
      {
        name: 'change task name',
        combination: {
          key: Key.M,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.changeNameHandler(),
      },
      {
        name: 'set task tags',
        combination: {
          key: Key.T,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.changeTagsHandler(),
      },
      {
        name: 'set task project',
        combination: {
          key: Key.P,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.changeProjectHandler(),
      },
      {
        name: 'set task state',
        combination: {
          key: Key.S,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.startStateChangeHandler(),
      },
      {
        name: 'set task due',
        combination: {
          key: Key.W,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.changeDueHandler(),
      },
      {
        name: 'reschedule task',
        combination: {
          key: Key.U,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.rescheduleHandler(),
      },
      {
        name: 'set task recurring settings',
        combination: {
          key: Key.O,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.recurringHandler(),
      },
      {
        name: 'change task energy',
        combination: {
          key: Key.G,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.changeEnergyHandler(),
      },
      {
        name: 'change task time',
        combination: {
          key: Key.I,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.changeTimeHandler(),
      },
      {
        name: 'change task priority',
        combination: {
          key: Key.L,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.changePriorityHandler(),
      },
      {
        name: 'toggle task in progress (focus)',
        combination: {
          key: Key.D,
          modifiers: CMD_OR_CTRL,
        },
        action: () => {
          if (this.props.selectedTask?.status === TaskStatus.STATUS_IN_PROGRESS) {
            this.props.handleChangeState(TaskStatus.STATUS_ACTION_LIST);
          } else {
            this.props.handleChangeState(TaskStatus.STATUS_IN_PROGRESS);
          }
        },
      },
      {
        name: 'set task status: complete',
        combination: {
          key: Key.Enter,
          modifiers: [
            [KeyModifier.Ctrl, KeyModifier.Shift],
            [KeyModifier.Cmd, KeyModifier.Shift],
          ],
        },
        action: () => this.props.handleChangeState(TaskStatus.STATUS_DONE),
      },
      {
        name: 'set task status: in progress (focus)',
        combination: {
          key: Key._0,
          modifiers: CMD_OR_CTRL_SHIFT,
        },
        action: () => {
          this.props.handleChangeState(TaskStatus.STATUS_IN_PROGRESS);
        },
      },
      {
        name: 'set task status: next',
        combination: {
          key: Key._9,
          modifiers: CMD_OR_CTRL_SHIFT,
        },
        action: () => {
          this.props.handleChangeState(TaskStatus.STATUS_ACTION_LIST);
        },
      },
      {
        name: 'set task status: waiting',
        combination: {
          key: Key._8,
          modifiers: CMD_OR_CTRL_SHIFT,
        },
        action: () => this.props.handleChangeState(TaskStatus.STATUS_WAITING),
      },
      {
        name: 'set task status: some time',
        combination: {
          key: Key._7,
          modifiers: CMD_OR_CTRL_SHIFT,
        },
        action: () => this.props.handleChangeState(TaskStatus.STATUS_SOME_TIME),
      },
      {
        name: 'set task status: inbox',
        combination: {
          key: Key._1,
          modifiers: CMD_OR_CTRL_SHIFT,
        },
        action: () => this.props.handleChangeState(TaskStatus.STATUS_INBOX),
      },
    ];
  };

  render() {
    const { children } = this.props;
    return (
      <>
        {children({
          onReschedule: this.rescheduleHandler,
          onStartStateChange: this.startStateChangeHandler,
          onSetRecurring: this.recurringHandler,
          onChangeDue: this.changeDueHandler,
          onChangeTime: this.changeTimeHandler,
          onChangeEnergy: this.changeEnergyHandler,
          onChangePriority: this.changePriorityHandler,
          onChangeTags: this.changeTagsHandler,
          onChangeProject: this.changeProjectHandler,
          onChangeName: this.changeNameHandler,
          getHandlers: this.getHandlers,
        })}

        <EditPriorityModal
          show={this.isChangeStatus(ChangeStatus.priority)}
          priority={this.getValueForModal(
            ChangeStatus.priority,
            (t) => t.priorityValues
          )}
          onSave={this.props.handleChangePriority}
          onClose={this.basicCloseHandler}
        />

        <EditTagsModal
          ref={this.getRef(ChangeStatus.tags)}
          show={this.isChangeStatus(ChangeStatus.tags)}
          tags={this.getValueForModal(ChangeStatus.tags, (t) => t.tags)}
          onSave={this.props.handleChangeTags}
          onClose={this.basicCloseHandler}
        />

        <EditProjectModal
          ref={this.getRef(ChangeStatus.project)}
          show={this.isChangeStatus(ChangeStatus.project)}
          projectId={this.getValueForModal(ChangeStatus.project, (t) => t.projectId)}
          onSave={this.props.handleChangeProjectId}
          onClose={this.basicCloseHandler}
        />

        <EditNameModal
          ref={this.getRef(ChangeStatus.name)}
          show={this.isChangeStatus(ChangeStatus.name)}
          name={this.getValueForModal(ChangeStatus.name, (t) => t.name) || ''}
          onSave={this.props.handleChangeName}
          onClose={this.basicCloseHandler}
          placeholder="name"
          maxLength={TASK_NAME_MAX_LENGTH}
        />

        <RecurringModal
          ref={this.getRef(ChangeStatus.recurring)}
          show={this.isChangeStatus(ChangeStatus.recurring)}
          value={this.getValueForModal(
            ChangeStatus.recurring,
            (t) => t.recurringOptions
          )}
          onSave={this.props.handleRecurring}
          onClose={this.basicCloseHandler}
          title="Set recurring schedule"
        />

        {/* SNOOZE */}
        <EditDateModal
          ref={this.getRef(ChangeStatus.schedule)}
          show={this.isChangeStatus(ChangeStatus.schedule)}
          date={this.getValueForModal(ChangeStatus.schedule, (t) => t.dateScheduled)}
          onSave={this.props.handleReschedule}
          onClose={this.basicCloseHandler}
          title="Snooze"
        />

        {/* DUE */}
        <EditDateModal
          ref={this.getRef(ChangeStatus.dueDate)}
          show={this.isChangeStatus(ChangeStatus.dueDate)}
          date={this.getValueForModal(ChangeStatus.dueDate, (t) => t.dateDue)}
          onSave={this.props.handleChangeDue}
          onClose={this.basicCloseHandler}
          title="Due date"
        />

        {/* STATE */}
        {this.props.isDesktop && (
          <EditStateModal
            ref={this.getRef(ChangeStatus.state)}
            show={this.isChangeStatus(ChangeStatus.state)}
            state={this.getValueForModal(ChangeStatus.state, (t) => t.status)}
            onSave={this.props.handleChangeState}
            onClose={this.basicCloseHandler}
          />
        )}
        {!this.props.isDesktop && (
          <IonActionSheet
            isOpen={this.isChangeStatus(ChangeStatus.state)}
            onDidDismiss={this.basicCloseHandler}
            header="Select new state"
            buttons={[
              {
                text: getTaskStatusLabel(TaskStatus.STATUS_SOME_TIME),
                handler: this.makeHandleChangeState(TaskStatus.STATUS_SOME_TIME),
              },
              {
                text: 'Due today',
                handler: () => {
                  this.props.handleChangeDue(formatDateWithoutTime(new Date()));
                },
              },
              {
                text: getTaskStatusLabel(TaskStatus.STATUS_ACTION_LIST),
                handler: this.makeHandleChangeState(TaskStatus.STATUS_ACTION_LIST),
              },
              {
                text: getTaskStatusLabel(TaskStatus.STATUS_IN_PROGRESS),
                handler: this.makeHandleChangeState(TaskStatus.STATUS_IN_PROGRESS),
              },
              {
                text: getTaskStatusLabel(TaskStatus.STATUS_WAITING),
                handler: this.makeHandleChangeState(TaskStatus.STATUS_WAITING),
              },
              {
                text: 'Cancel',
                icon: 'close',
                role: 'cancel',
              },
            ]}
          />
        )}

        {/* TIME */}
        {this.props.isDesktop && (
          <EditTimeModal
            ref={this.getRef(ChangeStatus.time)}
            show={this.isChangeStatus(ChangeStatus.time)}
            time={this.getValueForModal(ChangeStatus.time, (t) => t.time)}
            onSave={this.props.handleChangeTime}
            onClose={this.basicCloseHandler}
          />
        )}
        {!this.props.isDesktop && (
          <IonActionSheet
            isOpen={this.isChangeStatus(ChangeStatus.time)}
            onDidDismiss={this.basicCloseHandler}
            header="Select new time"
            buttons={[
              ...Object.values(TaskTime)
                .filter((t) => typeof t === 'number')
                .map((tt) => ({
                  text: getTimeLabel(tt as TaskTime),
                  handler: () => {
                    this.props.handleChangeTime(tt as TaskTime);
                  },
                })),
              {
                text: 'reset',
                handler: () => {
                  this.props.handleChangeTime(undefined);
                },
              },
              {
                text: 'Cancel',
                icon: 'close',
                role: 'cancel',
              },
            ]}
          />
        )}

        {/* ENERGY */}
        {this.props.isDesktop && (
          <EditEnergyModal
            ref={this.getRef(ChangeStatus.energy)}
            show={this.isChangeStatus(ChangeStatus.energy)}
            energy={this.getValueForModal(ChangeStatus.energy, (t) => t.energy)}
            onSave={this.props.handleChangeEnergy}
            onClose={this.basicCloseHandler}
          />
        )}
        {!this.props.isDesktop && (
          <IonActionSheet
            isOpen={this.isChangeStatus(ChangeStatus.energy)}
            onDidDismiss={this.basicCloseHandler}
            header="Select new energy"
            buttons={[
              {
                text: 'low',
                handler: () => {
                  this.props.handleChangeEnergy(TaskEnergy.ENERGY_LOW);
                },
              },
              {
                text: 'med',
                handler: () => {
                  this.props.handleChangeEnergy(TaskEnergy.ENERGY_MED);
                },
              },
              {
                text: 'high',
                handler: () => {
                  this.props.handleChangeEnergy(TaskEnergy.ENERGY_HIGH);
                },
              },
              {
                text: 'reset',
                handler: () => {
                  this.props.handleChangeEnergy(undefined);
                },
              },
              {
                text: 'Cancel',
                icon: 'close',
                role: 'cancel',
              },
            ]}
          />
        )}
      </>
    );
  }
}

export default withClientType(TaskActionsUI);
