import React, { useEffect, useState } from 'react';
import { IonBadge, IonIcon, IonList, IonListHeader } from '@ionic/react';
import { folderOpen } from 'ionicons/icons';
import TaskActionsWrapper from './TaskActionsWrapper';
import TaskListRow from './TaskListRow';
import {
  ProjectEnrichedWithTaskStats,
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
  TaskFilter,
} from '../../types/CoreTypes';
import { ListNavigationApi } from '../Common/ListNavigationCommon';
import TaskRepo from '../../lib/TaskRepo';
import { useDate } from '../../helpers/DateProvider';
import { TaskType } from '@todo/common';

interface Props {
  handle: string;
  filter: TaskFilter;
  setUniqueAndStatsFilterValues?: (
    collectionName: string,
    values: TaskType[]
  ) => void;
  listNavigationApi?: ListNavigationApi;
  projectsWithNextTasks: {
    task: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;
    project: ProjectEnrichedWithTaskStats;
  }[];
}

const ProjectNextTask = (props: Props) => {
  const {
    filter,
    setUniqueAndStatsFilterValues,
    // setCounter,
    listNavigationApi,
    projectsWithNextTasks,
    handle,
  } = props;

  const { dateFormatted } = useDate();

  const setCounter = listNavigationApi?.setCounter;

  const [tasksToShow, setTasksToShow] = useState<
    {
      task: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;
      project: ProjectEnrichedWithTaskStats;
    }[]
  >([]);

  useEffect(() => {
    const fn = TaskRepo.makeFilterFn(filter, dateFormatted);
    const tasks = projectsWithNextTasks.filter((pt) => {
      return fn(pt.task);
    });
    setTasksToShow(tasks);

    setUniqueAndStatsFilterValues &&
      setUniqueAndStatsFilterValues(
        handle,
        tasks.map((o) => o.task)
      );
    setCounter && setCounter(handle, tasks.length);
  }, [
    dateFormatted,
    filter,
    handle,
    setCounter,
    projectsWithNextTasks,
    setUniqueAndStatsFilterValues,
  ]);

  return (
    <>
      <IonListHeader>
        <span>
          <IonIcon icon={folderOpen} />
          &nbsp;
          <span>Project next actions</span>
          &nbsp;
          <IonBadge>{tasksToShow.length}</IonBadge>
        </span>
      </IonListHeader>
      <TaskActionsWrapper>
        {(taskHandlers) => (
          <IonList>
            {tasksToShow.map(({ task, project }, idx) => (
              <TaskListRow
                key={`projects-${project.id}-${task.id}`}
                ref={
                  listNavigationApi
                    ? listNavigationApi.getRef(handle)(idx)
                    : undefined
                }
                isSelected={
                  listNavigationApi?.selectedCollectionName === handle &&
                  idx === listNavigationApi?.selectedCollectionIdx
                }
                task={task}
                taskHandlers={{ ...taskHandlers }}
              />
            ))}
          </IonList>
        )}
      </TaskActionsWrapper>
    </>
  );
};

export default ProjectNextTask;
