import { TaskType } from '../../types/CoreTypes';
import { RxdbRevData, GraphqlCreateItemMutation, ItemType } from '@todo/common';
import { omit } from 'lodash';

export const pushQueryBuilder = (userId: string, deviceId: string) => {
  return (
    doc: TaskType & { serverUpdatedAt: number } & RxdbRevData & {
        itemType: ItemType;
      }
  ): GraphqlCreateItemMutation => {
    const query = `
        mutation replicate($create: CreateInput!, $type: String!) {
            replicate(data: $create, type: $type){
                id
                serverUpdatedAt
            }
       }
    `;

    const variables = {
      data: { ...omit(doc, ['serverUpdatedAt', 'itemType']), deviceId },
      itemType: doc['itemType'],
    };
    // or can use `modifier: d => d`

    return {
      query,
      variables,
    };
  };
};
