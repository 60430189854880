import React from 'react';
import { IonIcon } from '@ionic/react';
import { colorWandOutline } from 'ionicons/icons';
import { useMagicHotkey } from '../helpers/MagicHotkeyProvider';

const MagicButton = () => {
  const { triggerMagicPanel } = useMagicHotkey();

  /*
  <Tooltip
      destroyTooltipOnHide
      placement="left"
      trigger={['hover', 'click']}
      overlay={
        <div style={{ width: '120px', height: '40px' }}>
          Magic commands
          <br /> (⌘ or ctrl + K)
        </div>
      }
    >
   */

  return (
    <IonIcon
      onClick={triggerMagicPanel}
      style={{ fontSize: '24px', padding: '10px', cursor: 'pointer' }}
      icon={colorWandOutline}
    />
  );
};

export default MagicButton;
