import React, { forwardRef } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonRow,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { Key, KeyModifier, TagCreateFormFillable } from '../../types/CoreTypes';
import { TAG_NAME_MAX_LENGTH } from '../../lib/Constants';
import { DocumentEditorSync } from '../Common/DocumentEditor';
import { DaoCatalog } from '../../helpers/InitStorage';
import HotKeyQuickAdd from '../Common/HotKeyQuickAdd';
import ActionOnKeyPress from '../Common/ActionOnKeyPress';
import QuickAddContextSwitcher from '../Common/QuickAddContextSwitcher';
import { ItemType } from '@todo/common';

interface BaseProps {
  onClose: () => void;
  onSave: (args: TagCreateFormFillable) => Promise<void>;
  isOpen: boolean;
  daoCatalog: DaoCatalog;
}
interface Props extends BaseProps {
  forwardedRef?: React.Ref<any>;
}

interface State {
  tag: TagCreateFormFillable;
  error: string | null;
}

// eslint-disable-next-line react/display-name
class QuickAddFormTag extends React.Component<Props> {
  private documentEditorRef: React.RefObject<DocumentEditorSync>;

  constructor(props: Props) {
    super(props);
    this.documentEditorRef = React.createRef();
  }

  state: State = {
    tag: this.getDefaultTagData(),
    error: null,
  };

  private getDefaultTagData(): TagCreateFormFillable {
    return { name: '' };
  }

  setTag(tag: TagCreateFormFillable) {
    this.setState({ tag });
  }
  setError(error: string | null) {
    this.setState({ error });
  }

  setValue = (fields: Partial<TagCreateFormFillable>) => {
    this.setTag({ ...this.state.tag, ...fields });
  };

  handleSave = async () => {
    this.setError(null);
    const dump = await this.documentEditorRef.current?.dump();
    this.props
      .onSave({ ...this.state.tag, blocks: dump ? dump.docs : [] })
      .then(() => {
        this.setTag(this.getDefaultTagData());
        this.documentEditorRef.current?.eraseDb();
      })
      .catch((err) => this.setError(err.toString()));
  };

  handleArrowUp = () => {
    this.focus();
  };

  focus = () => {
    // @ts-ignore
    if (this.props.forwardedRef && this.props.forwardedRef.current) {
      // @ts-ignore
      this.props.forwardedRef.current.setFocus();
    }
  };

  render() {
    const { tag, error } = this.state;
    const { onClose, forwardedRef } = this.props;

    return (
      <>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            this.handleSave();
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <QuickAddContextSwitcher
                      onClose={this.focus}
                      kind={ItemType.tag}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonButton slot="end" color="light" onClick={onClose}>
                <IonIcon icon={close} />
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonInput
                    maxlength={TAG_NAME_MAX_LENGTH}
                    id="quickAddInput"
                    autocorrect="on"
                    placeholder="Tag name"
                    value={tag.name}
                    clearInput
                    ref={forwardedRef}
                    onIonChange={(event: any) =>
                      this.setValue({ name: event.target.value })
                    }
                  />
                </IonCol>
              </IonRow>
              <DocumentEditorSync
                contextId={'fake'}
                inMemory={true}
                ref={this.documentEditorRef}
                isHidden={!this.props.isOpen}
                onArrowUp={this.handleArrowUp}
                daoCatalog={this.props.daoCatalog}
              />
            </IonGrid>
            <HotKeyQuickAdd />
            <ActionOnKeyPress
              name="quick add form tag"
              enabled={this.props.isOpen}
              handlers={[
                {
                  action: this.handleSave,
                  name: 'save',
                  combination: {
                    key: Key.Enter,
                    modifiers: [[KeyModifier.Cmd], [KeyModifier.Ctrl]],
                  },
                },
                {
                  action: this.props.onClose,
                  name: 'close',
                  combination: {
                    key: Key.Escape,
                  },
                },
              ]}
            />
            <IonButton type="submit">Submit</IonButton>
            <IonToast
              isOpen={Boolean(error)}
              onDidDismiss={() => this.setError(null)}
              message={error || ''}
              position="top"
              color="danger"
              duration={3000}
            />
          </div>
        </form>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <QuickAddFormTag forwardedRef={ref} {...props} />
));
