import React, { forwardRef } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonRow,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import {
  cafe,
  chevronForwardCircle,
  close,
  partlySunny,
  star,
} from 'ionicons/icons';
import {
  Key,
  KeyModifier,
  ProjectCreateFormFillable,
  ProjectStatus,
} from '../../types/CoreTypes';
import { PROJECT_NAME_MAX_LENGTH } from '../../lib/Constants';
import { DocumentEditorSync } from '../Common/DocumentEditor';
import { DaoCatalog } from '../../helpers/InitStorage';
import HotKeyQuickAdd from '../Common/HotKeyQuickAdd';
import ActionOnKeyPress from '../Common/ActionOnKeyPress';
import QuickAddContextSwitcher from '../Common/QuickAddContextSwitcher';
import { ItemType } from '@todo/common';
import {
  FreeTierEntity,
  FreeTierProps,
  withFreeTier,
} from '../../helpers/FreeTierProvider';
import UpgradeBanner from '../Common/UpgradeBanner';

interface MinProps {
  onClose: () => void;
  onSave: (args: ProjectCreateFormFillable) => Promise<void>;
  isOpen: boolean;
  daoCatalog: DaoCatalog;
}

interface ExtProps extends MinProps {
  forwardedRef?: React.Ref<any>;
}
interface Props extends ExtProps, FreeTierProps {
  forwardedRef?: React.Ref<any>;
}

interface Status {
  icon: string;
  label: string;
  status: ProjectStatus;
}

const statuses: Status[] = [
  {
    label: 'In Progress',
    icon: star,
    status: ProjectStatus.STATUS_ACTION,
  },
  {
    label: 'Waiting',
    icon: cafe,
    status: ProjectStatus.STATUS_WAITING,
  },
  {
    label: 'Next',
    icon: chevronForwardCircle,
    status: ProjectStatus.STATUS_NEXT,
  },
  {
    label: 'Someday',
    icon: partlySunny,
    status: ProjectStatus.STATUS_SOME_TIME,
  },
];

interface State {
  project: ProjectCreateFormFillable;
  error: string | null;
}

// eslint-disable-next-line react/display-name
class QuickAddFormProject extends React.Component<Props> {
  private documentEditorRef: React.RefObject<DocumentEditorSync>;

  constructor(props: Props) {
    super(props);
    this.documentEditorRef = React.createRef();
  }

  state: State = {
    project: this.getDefaultProjectData(),
    error: null,
  };

  private getDefaultProjectData(): ProjectCreateFormFillable {
    return { name: '', status: ProjectStatus.STATUS_ACTION };
  }

  setProject(project: ProjectCreateFormFillable) {
    this.setState({ project });
  }
  setError(error: string | null) {
    this.setState({ error });
  }

  setValue = (fields: Partial<ProjectCreateFormFillable>) => {
    this.setProject({ ...this.state.project, ...fields });
  };

  handleSave = async () => {
    this.setError(null);
    const dump = await this.documentEditorRef.current?.dump();
    this.props
      .onSave({ ...this.state.project, blocks: dump ? dump.docs : [] })
      .then(() => {
        this.setProject(this.getDefaultProjectData());
        this.documentEditorRef.current?.eraseDb();
      })
      .catch((err) => this.setError(err.toString()));
  };

  handleArrowUp = () => {
    this.focus();
  };

  focus = () => {
    // @ts-ignore
    if (this.props.forwardedRef && this.props.forwardedRef.current) {
      // @ts-ignore
      this.props.forwardedRef.current.setFocus();
    }
  };

  render() {
    const { project, error } = this.state;
    const { onClose, forwardedRef, hasUsedFreeTier } = this.props;

    if (hasUsedFreeTier(FreeTierEntity.project)) {
      return (
        <div ref={forwardedRef}>
          <IonHeader>
            <IonToolbar>
              <IonButton slot="end" color="light" onClick={onClose}>
                <IonIcon icon={close} />
                Close
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <UpgradeBanner onAfterNavigate={onClose}>
            Please upgrade to Premium Access to create more projects!
          </UpgradeBanner>
        </div>
      );
    }

    return (
      <>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            this.handleSave();
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <QuickAddContextSwitcher
                      onClose={this.focus}
                      kind={ItemType.project}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonButton slot="end" color="light" onClick={onClose}>
                <IonIcon icon={close} />
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonInput
                    maxlength={PROJECT_NAME_MAX_LENGTH}
                    id="quickAddInput"
                    autocorrect="on"
                    placeholder="Project name"
                    value={project.name}
                    clearInput
                    ref={forwardedRef}
                    onIonChange={(event: any) =>
                      this.setValue({ name: event.target.value })
                    }
                  />
                </IonCol>
              </IonRow>
              <div>
                {statuses.map((status) => (
                  <IonButton
                    key={`status-button-${status.status}`}
                    size="small"
                    color={status.status === project.status ? 'tertiary' : 'light'}
                    onClick={() => this.setValue({ status: status.status })}
                  >
                    <IonIcon slot="start" icon={status.icon} />
                    {status.label}
                  </IonButton>
                ))}
              </div>
              <DocumentEditorSync
                contextId={'fake'}
                inMemory={true}
                ref={this.documentEditorRef}
                isHidden={!this.props.isOpen}
                onArrowUp={this.handleArrowUp}
                daoCatalog={this.props.daoCatalog}
              />
            </IonGrid>
            <HotKeyQuickAdd />
            <ActionOnKeyPress
              name="quick add form project"
              enabled={this.props.isOpen}
              handlers={[
                {
                  action: this.handleSave,
                  name: 'save',
                  combination: {
                    key: Key.Enter,
                    modifiers: [[KeyModifier.Cmd], [KeyModifier.Ctrl]],
                  },
                },
                {
                  action: this.props.onClose,
                  name: 'close',
                  combination: {
                    key: Key.Escape,
                  },
                },
              ]}
            />
            <IonButton type="submit">Submit</IonButton>
            <IonToast
              isOpen={Boolean(error)}
              onDidDismiss={() => this.setError(null)}
              message={error || ''}
              position="top"
              color="danger"
              duration={3000}
            />
          </div>
        </form>
      </>
    );
  }
}

const WithDeps = withFreeTier(QuickAddFormProject);

// eslint-disable-next-line react/display-name
export default forwardRef<any, ExtProps>((props: MinProps, ref: React.Ref<any>) => (
  <WithDeps forwardedRef={ref} {...props} />
));
