import React, { forwardRef } from 'react';
import { NamedHotKeyHandler } from '../types/CoreTypes';
import { chain, debounce, isEqual } from 'lodash';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { ModalsProps, withModalsState } from '../helpers/ModalsProvider';
import MagicResultsList from './MagicResultsList';
import Fuse from 'fuse.js';
import { logDebug } from '../lib/logger';

interface MinProps {
  isHidden: boolean;
  onClose: () => void;
  handlers: NamedHotKeyHandler[];
}

interface ExtProps extends MinProps {
  forwardedRef?: React.Ref<any>;
}

interface Props extends ModalsProps, ExtProps {}

// interface MagicResult {
//   categoryName: string;
//   name: string;
//   action: () => void;
//   hotKey?: HotKeyCombination;
// }

interface State {
  name: string;
  // results: MagicResult[];
  results: NamedHotKeyHandler[];
  index?: Fuse<NamedHotKeyHandler>;
  handlers?: NamedHotKeyHandler[];
}

class MagicPanel extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      results: [],
    };

    if (!this.props.isHidden) this.loadData();
  }

  updateIndex = () => {
    const { handlers } = this.props;
    const index = MagicPanel.buildIndex(handlers);
    return new Promise<void>((resolve) => {
      this.setState(
        {
          index,
          handlers,
        },
        resolve
      );
    });
  };

  static buildIndex(handlers: NamedHotKeyHandler[]): Fuse<NamedHotKeyHandler> {
    logDebug([], 'buildIndex');
    return new Fuse(handlers, {
      includeScore: false,
      keys: ['handler.name'],
    });
  }

  loadData = async () => {
    const { handlers } = this.props;

    if (!this.state.index || !isEqual(this.props.handlers, this.state.handlers)) {
      await this.updateIndex();
    }
    const { name, index } = this.state;
    if (!index) {
      throw new Error('No magic index!');
    }

    const text = name.trim();

    let results: NamedHotKeyHandler[];

    if (text.length) {
      results = index.search(text).map((r) => r.item);
    } else {
      results = chain(handlers)
        .sortBy((h) => `${h.categoryName}${h.handler.name}`)
        .value();
    }

    // todo could also abstract hot keys as a repo like actionRepo, with add method
    //  when registering, and getAllForFilter
    // results = chain(results)
    //   .sortBy(h => `${h.categoryName}${h.handler.name}`)
    //   .value();

    // const maxDup = chain(results)
    //   .groupBy(h => JSON.stringify(h.handler.combination))
    //   .map(o => o)
    //   .maxBy(o => o.length)
    //   .value();
    // if (maxDup.length > 1) {
    //   throw Error(
    //     `more than one match for the same hot key: ${JSON.stringify(maxDup)}`
    //   );
    // }

    this.setState({ results });
  };

  textFilter = (text: string) => (handler: NamedHotKeyHandler) => {
    return handler.handler.name.toLowerCase().includes(text.toLowerCase());
  };

  handleSearchChange = debounce(this.loadData, 200);

  handleType = (event: any) => {
    const text = event.target.value;
    this.setState({ name: text });
    this.handleSearchChange();
  };

  handleClose = () => {
    // this.setState({ name: '' });
    this.props.onClose();
  };

  keyBinding() {
    if (this.props.isHidden) {
      this.unmountKeyListeners();
    } else {
      if (this.props.secondLayerModalIsOpen) {
        this.unmountKeyListeners();
      } else {
        this.mountKeyListeners();
      }
    }
  }

  componentDidMount(): void {
    this.keyBinding();
  }

  componentWillUnmount() {
    this.unmountKeyListeners();
  }

  componentDidUpdate(prevProps: Props) {
    // if (!isEqual(prevProps.handlers, this.state.handlers)) {
    //   this.updateIndex();
    // }

    if (
      this.props.isHidden !== prevProps.isHidden ||
      this.props.secondLayerModalIsOpen !== prevProps.secondLayerModalIsOpen
    ) {
      this.keyBinding();
    }
    if (!this.props.isHidden && this.props.isHidden !== prevProps.isHidden) {
      this.loadData();
    }
  }

  mountKeyListeners() {
    logDebug([], 'loading magic modal keyboard listeners...');
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  unmountKeyListeners() {
    logDebug([], 'unloading magic modal keyboard listeners...');
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  // todo: have a reusable modal that can be closed by esc
  handleKeyPress = (e: KeyboardEvent) => {
    logDebug([], 'handling key press in search');
    // need this for "AlwaysVisible" modal
    if (e.key === 'Escape') {
      e.preventDefault();
      this.handleClose();
    }
  };

  render() {
    const { forwardedRef, isHidden } = this.props;
    const { name, results } = this.state;

    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonInput
                    id="search_actions"
                    autocorrect="on"
                    placeholder={'search actions...'}
                    value={name}
                    clearInput
                    ref={forwardedRef}
                    onIonChange={this.handleType}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonButton slot="end" color="light" onClick={this.handleClose}>
              <IonIcon icon={close} />
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {isHidden ? null : (
            <MagicResultsList onClose={this.handleClose} results={results} />
          )}
        </IonContent>
      </>
    );
  }
}

const MagicPanelWithDeps = withModalsState(MagicPanel);

// eslint-disable-next-line react/display-name
export default forwardRef<any, ExtProps>((props: MinProps, ref: React.Ref<any>) => (
  <MagicPanelWithDeps forwardedRef={ref} {...props} />
));
