import React, { createContext, useContext } from 'react';
import { Routes } from '../lib/routes';
import HelpArticles from '../components/HelpArticles';
import MyIonModal from './MyIonModal';
import { HelpArticle } from '../types/CoreTypes';
import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import {
  addCircle,
  alarm,
  calendarOutline,
  cellular,
  colorWandOutline,
  filter,
  folderOpen,
  repeat,
  search,
  star,
  stopwatch,
} from 'ionicons/icons';

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/display-name */

export interface HelpProps {
  triggerHelpPopup: (path?: string) => void;
}

export interface ActionSubscriber {
  unsubscribe: () => void;
}

// @ts-ignore
export const HelpContext = createContext<HelpProps>(undefined);

interface Props {
  children: any;
}

interface State {
  showingHelpPopup: boolean;
  articles: HelpArticle[];
}

function renderChip(text: string, icon?: string) {
  return (
    <IonChip color="secondary">
      {icon && <IonIcon icon={icon} />}
      <IonLabel color="dark">{text}</IonLabel>
    </IonChip>
  );
}

const helpArticles: {
  [key: string]: HelpArticle;
} = {
  general: {
    title: 'General',
    render: (isDesktop) => (
      <>
        <p>Welcome to EasyBusy!</p>
        <p>
          Our goal is to help organize your tasks, keep you focused, and do it with
          no stress and much fun!
        </p>
        <p>
          All content is organized in {renderChip('contexts')}. You can use them as
          you want, but good approach could be to organize your work, home, and hobby
          tasks and projects in different contexts. It will help to keep you focused
          when you're immersing in one kind of activity.
        </p>
        {isDesktop && (
          <p>
            There's a hot key to switch between contexts:{' '}
            {renderChip('Cmd or Ctrl + number of the context')} that is shown in the
            left menu next to it.
          </p>
        )}
        <p>
          You can use Projects, Tags, Priority, Time and Energy properties to
          organize your tasks. Try to use it as much as possible, as it will help you
          better understand your tasks and will help with planning.
        </p>
        <p>
          To add a new content, press {renderChip('the quick add button', addCircle)}
          at the bottom of most pages.
        </p>
        {isDesktop && (
          <p>
            We try to make it as easy as possible to work with only keyboard on
            computers. For the list of available hot keys, press{' '}
            {renderChip('the magic button', colorWandOutline)} visible on every page,
            or click
            {renderChip('Cmd or Ctrl + K')} to show it.
          </p>
        )}
        {!isDesktop && (
          <p>
            For the list of available actions, press{' '}
            {renderChip('the magic button', colorWandOutline)} visible on every page.
          </p>
        )}
        <p>
          You can search for any entity on any page using
          {renderChip('the search button', search)}.
        </p>
        <p>
          For more details, including information about our other applications, visit
          us at <a href="https://easybusydo.com">https://easybusydo.com</a>
        </p>
      </>
    ),
  },
  [`/${Routes.focus}`]: {
    title: 'Focus tasks',
    render: () => (
      <>
        <p>
          When you're ready to start working, select a few tasks to focus on. It can
          be small list for short time slot that you have right now, or a todo list
          for today.
        </p>
        <p>
          Bring task to the Focus page by changing it's state or pressing{' '}
          {renderChip('the star button', star)}.
        </p>
        <p>
          If you have just a few minutes or hours, use{' '}
          {renderChip('the Find tool', filter)} in
          {renderChip('the Search modal', search)} to select tasks for{' '}
          {renderChip('Time', stopwatch)} you have depending on your current{' '}
          {renderChip('Energy', cellular)}
          level. You can also use Priority and Tags to further organize tasks.
        </p>
        <p>
          The {renderChip('Time', stopwatch)} icon at the top of the Focus page will
          show how much time will the selected tasks take.
        </p>
        <p>
          It's best to clear your Focus list after you finish working if you still
          have some tasks left. Next time you may have different criteria and would
          like to choose other tasks.
        </p>
        <p>
          Tasks that are {renderChip('Due Today', alarm)} or{' '}
          {renderChip('scheduled for today', calendarOutline)} will appear at the top
          of this page. Due tasks are shown in a red counter badge. Due today usually
          means that task absolutely has to be completed today.
        </p>
        <p>
          This page also shows your {renderChip('Achievements', star)}. Every time
          you complete a task you will get an achievement, in appreciation of your
          hard work! Not only completing tasks will bring achievements, but also any
          actions that will keep your tasks organized, like adding Tags, Energy,
          Time, Priority to tasks, completing scheduled tasks on their due date,
          clearing Focus, and more!
        </p>
      </>
    ),
  },
  [`/${Routes.next}`]: {
    title: 'Next tasks',
    render: () => (
      <>
        <p>
          Tasks that you need to do soon but not yet sure when, go into the Next
          list. This list may be the primary place to look when you're choosing tasks
          to Focus on.
        </p>
        <p>
          Try not to keep tasks here for long, as over time your priorities may
          change, and the Next list will become not actionable. Try to make a habit
          to revisit it once in a while.
        </p>
        <p>Bring task to this page by changing it's state.</p>
        <p>
          This list also shows tasks that will be {renderChip('Due', alarm)} soon, to
          help you plan for them, in case it makes sense to finish them earlier.
        </p>
        <p>
          At the bottom there's a list of {renderChip('Projects', folderOpen)} that
          are in the {renderChip('In Progress', star)} state, showing their Next
          tasks. It's often better to work through project sequentially one task at a
          time. If you find it hard to focus on some project, try to pick up it's
          next task once in a while from this page, to make a steady progress.
        </p>
      </>
    ),
  },
  [`/${Routes.waiting}`]: {
    title: 'Waiting tasks',
    render: () => (
      <>
        <p>
          This page shows tasks that are blocked at the moment. You may be waiting
          for other person to do something, or for a right event to happen.
        </p>
        <p>
          It's best to visit this page once in a while and double check that these
          tasks are still blocked. If they are unblocked, move them either to Next of
          Focus.
        </p>
        <p>
          You can add more details about why the task is blocked on the task details
          page.
        </p>
        <p>Bring task to this page by changing it's state.</p>
      </>
    ),
  },
  [`/${Routes.sometime}`]: {
    title: 'Some time tasks',
    render: () => (
      <>
        <p>
          This page shows tasks that have to be done some time, but this time is
          unknown at the moment, and you cannot place them to the Next yet.
        </p>
        <p>
          Some brainstorming ideas, distant plans and nice-to-have tasks go here.
          Just try not to forget about them and revisit regularly!
        </p>
        <p>Bring task to this page by changing it's state.</p>
      </>
    ),
  },
  [`/${Routes.inbox}`]: {
    title: 'Inbox Tasks',
    render: () => (
      <p>
        Quick add window for Tasks gives you options to add all information to the
        new task. But you don't have to fill any of it if you have no time. If you
        only fill the task name, it will go to the Inbox to be sorted out later. This
        makes adding new tasks a breeze. Just don't forget to get back some time
        later and sort out Inbox.
      </p>
    ),
  },
  [`/${Routes.tasks}/details/.*`]: {
    title: 'Task Details',
    render: (isDesktop) => (
      <>
        <p>
          On this page you can add or edit any of the Task's properties: Project,
          Tags, Scheduling, Time, Energy and Priority
        </p>
        <p>
          You can also add more content to the task. Content is organized in Content
          Blocks. Each content block can be a text, heading, a checkbox or a bulleted
          list. This will allow you to create reach documents, embedding todo lists
          into tasks, and organize them as you want.
        </p>
        <p>
          You can change the block Type using the toolbar that is visible when the
          block is selected. There's also a few shortcuts, when you type them at the
          beginning of the block, followed by a space:
          <ul>
            <li>{renderChip('[ ]')} converts to a checkbox</li>
            <li>{renderChip('*')} converts to a bullet list</li>
            <li>{renderChip('#')} converts to a header 1</li>
            <li>{renderChip('##')} converts to a header 2</li>
            <li>{renderChip('###')} converts to a header 3</li>
          </ul>
        </p>
        <p>
          You can move blocks left and right by using arrows on the toolbar{' '}
          {isDesktop && <>or {renderChip('Tab')}</>}.
        </p>
        <p>
          Remove the indent, or reset the block type to Text by using{' '}
          {renderChip('Backspace')}.
        </p>
        {isDesktop && (
          <p>
            Move the blocks up or down with{' '}
            {renderChip('Cmd or Ctrl + Shift + Arrow')}.
          </p>
        )}
        <p>
          If the task has at least one checkbox, the number of uncompleted checkboxes
          will be visible in any task lists, hinting that the task has more to do.
        </p>
        <p>
          To complete a checkbox, click on it{' '}
          {isDesktop && (
            <>
              or press {renderChip('Cmd or Ctrl + Enter')} when the block is selected
            </>
          )}
          .
        </p>
      </>
    ),
  },
  [`/${Routes.projects}.*`]: {
    title: 'Projects',
    render: () => (
      <>
        <p>
          Projects are groupings of tasks that have a final outcome, and completing
          each task in it will bring that outcome closer. Try to define the outcome,
          and put in in the project description that you can edit on the project
          page.
        </p>
        <p>
          Projects have different statuses, and priorities, just like tasks do. Next
          to each project in the project list is a number of open tasks and a summary
          of time needed for these tasks.
        </p>
        <p>
          We encourage you to estimate time and energy needed for each task in a
          project, and projects will show number of unestimated tasks in red.
        </p>
        <p>
          If the project that is in progress has no tasks left, or all remaining
          tasks are in Waiting, the project will be highlighted in red and trigger a
          red badge.
        </p>
        <p>
          You can also see projects in a calendar-like timeline view, using a link at
          the top of the project list.
        </p>
        <p>
          You can also add new projects using the project selector when editing
          task's project or creating a new task.
        </p>
      </>
    ),
  },
  [`/${Routes.gantt}`]: {
    title: 'Projects timeline',
    render: () => (
      <>
        <p>
          This view shows projects on a calendar. You can drag them to different
          dates, and resize left or right to allocate more days.
        </p>
        <p>
          Projects can have dependencies on each other. You can select one dependency
          for each project in the project details. Project details can also be viewed
          by clicking on the project bar.
        </p>
        <p>
          Filling of the project bars depends on the percentage of completed tasks
          within the project.
        </p>
      </>
    ),
  },
  [`/${Routes.projects}/details/.*`]: {
    title: 'Project Details',
    render: () => (
      <>
        <p>Here you can see, reorder, organize and add new project's tasks.</p>
        <p>
          Project can have content blocks just like tasks. See the help article for
          Task Details for more info. Content block is a good place to define the
          desired outcome for the project, and take notes about it's details and
          progress.
        </p>
        <p>
          Start date and duration are used for the Timeline view, and can be edited
          here or in the timeline view.
        </p>
      </>
    ),
  },
  [`/${Routes.tags}.*`]: {
    title: 'Tags',
    render: () => (
      <>
        <p>
          Tags are used to organize tasks by grouping them by some common features.
          This is the most flexible tool and you can define your own way of using it.
        </p>
        <p>
          Tags can be nested, you can add more child tags to a tag on the Tag details
          page.
        </p>
        <p>
          You can also add new tags using the tag selector when editing task's tags
          or creating a new task.
        </p>
        <p>
          Tags have associated color and icon, that can be edited on the Tag details
          page.
        </p>
      </>
    ),
  },
  [`/${Routes.tags}/details/.*`]: {
    title: 'Tag Details',
    render: () => (
      <>
        <p>Here you can see, reorder, organize and add new tag's tasks.</p>
        <p>
          Tag can have content blocks just like tasks. See the help article for Task
          Details for more info.
        </p>
      </>
    ),
  },
  [`/${Routes.agenda}`]: {
    title: 'Agenda',
    render: () => (
      <>
        <p>
          Agenda page shows which tasks are planned for the future days. These
          include scheduled/snoozed tasks, tasks that are due on a certain date, and
          recurring tasks.
        </p>
        <p>
          Only few months are shown at a time. You can navigate between months using
          arrow buttons.
        </p>
        <p>
          EasyBusy promotes flexible schedule and strives to make your work less
          stressful. That's why you don't have to assign tasks to dates, instead you
          can add them to the Next and Focus pages and work whenever you want to!
        </p>
        <p>
          But sometimes it's easier to plan ahead for a few days, and Agenda is here
          to help!
        </p>
        <p>
          To add tasks to your agenda, {renderChip('schedule', calendarOutline)} your
          task for a future date, if the task is relatively flexible, or
          {renderChip('set a due date', alarm)}
          if the task absolutely has to be completed on that date. If the task is
          recurring,
          {renderChip('set a recurring schedule', repeat)}
          for it.
        </p>
      </>
    ),
  },
  [`/${Routes.archive}`]: {
    title: 'Archive',
    render: () => (
      <>
        <p>Archive page shows all previously completed tasks.</p>
        <p>
          Only few days are shown at a time. You can navigate between months using
          arrow buttons.
        </p>
      </>
    ),
  },
  [`/${Routes.contexts}`]: {
    title: 'Contexts',
    render: () => (
      <>
        <p>
          All content is organized in {renderChip('contexts')}. You can use them as
          you want, but good approach could be to organize your work, home, and hobby
          tasks and projects in different contexts. It will help to keep you focused
          when you're immersing in one kind of activity.
        </p>
        <p>Reordering contexts will affect how they appear in the left menu.</p>
      </>
    ),
  },
  [`/${Routes.settings}`]: {
    title: 'Settings',
    render: () => (
      <>
        <p>
          Settings page shows your current subscription status, and allows you to
          subscribe for Premium Access. Premium Access allows to:
          <ul>
            <li>
              synchronize tasks and other content between multiple mobile devices or
              computers
            </li>
            <li>back up content in the cloud</li>
            <li>create unlimited tasks</li>
            <li>use recurring tasks</li>
          </ul>
        </p>
        <p>
          Without subscription, you can create:
          <ul>
            <li>up to 20 tasks</li>
            <li>up to 3 projects</li>
            <li>up to 2 contexts</li>
          </ul>
        </p>
        <p>
          Here you can log out of the app. Note that after logging out all your data
          on the device will be erased. If you haven't backed up your data using
          Premium Access, your data will be lost.
        </p>
      </>
    ),
  },
};

export class HelpProvider extends React.PureComponent<Props> {
  state: State = {
    showingHelpPopup: false,
    articles: [],
  };

  private readonly magicPanelRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.magicPanelRef = React.createRef();
  }

  handleOpenHelpPopup = (path?: string) => {
    if (path) {
      const matchKeys = Object.keys(helpArticles).filter((k) => path.match(k));
      this.setState({
        showingHelpPopup: true,
        articles: matchKeys.map((k) => helpArticles[k]),
      });
    } else {
      this.setState({
        showingHelpPopup: true,
        articles: Object.values(helpArticles),
      });
    }
  };

  handleCloseModal = () => {
    this.setState({ showingHelpPopup: false, articles: [] });
  };

  render() {
    const { children } = this.props;
    const { showingHelpPopup, articles } = this.state;

    return (
      <HelpContext.Provider
        value={{
          triggerHelpPopup: this.handleOpenHelpPopup,
        }}
      >
        {children}
        <MyIonModal
          animated={false}
          cssClass={`help-modal`}
          isOpen={showingHelpPopup}
          backdropDismiss={true}
          onDidDismiss={this.handleCloseModal}
        >
          <HelpArticles articles={articles} onClose={this.handleCloseModal} />
        </MyIonModal>
      </HelpContext.Provider>
    );
  }
}

export const useHelp: () => HelpProps = () => useContext(HelpContext);

export const withHelp = <P extends Partial<HelpProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof HelpProps>> => {
  return function withHelpContext(props) {
    return (
      <HelpContext.Consumer>
        {(contextProps: HelpProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </HelpContext.Consumer>
    );
  };
};
