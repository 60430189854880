import { GrantedPermissions, SettingsType } from '../types/CoreTypes';
import { RxCollection } from 'rxdb/plugins/core';

enum LocalRepoKeys {
  // token = 'token',
  // userName = 'userName',
  // userEmail = 'userEmail',
  // auth0Id = 'auth0Id',
  context = 'context',
  setUpAt = 'setUpAt',
  wasAuthenticated = 'wasAuthenticated',
  lastSubscriptionType = 'lastSubscriptionType',
  lastNativeSubscription = 'lastNativeSubscription',
  user_id = 'user_id',
  device_id = 'device_id',
  askedForPermissions = 'askedForPermissions',
  // password = 'password',
  // activeEntitlement = 'activeEntitlement',
}

export default class LocalSettingsRepo {
  private readonly collection: RxCollection<SettingsType>;

  constructor(collection: RxCollection<SettingsType>) {
    this.collection = collection;
  }

  private async erase(id: LocalRepoKeys): Promise<void> {
    await this.collection.upsertLocal(id, { value: null });
  }

  private async set(id: LocalRepoKeys, value: any): Promise<void> {
    await this.collection.upsertLocal(id, { value });
  }

  private async get(id: LocalRepoKeys): Promise<string | null> {
    return this.collection.getLocal(id).then((d) => (d && d.get('value')) || null);
  }

  // getToken = (): Promise<string | null> => {
  //   return this.get(LocalRepoKeys.token);
  // };
  //
  // saveToken = (token: string | null): Promise<void> => {
  //   return this.set(LocalRepoKeys.token, token);
  // };
  //
  // eraseToken = (): Promise<void> => {
  //   return this.erase(LocalRepoKeys.token);
  // };
  //
  // getUserName = (): Promise<string | null> => {
  //   return this.get(LocalRepoKeys.userName);
  // };
  //
  // saveUserName = (userName: string | null): Promise<void> => {
  //   return this.set(LocalRepoKeys.userName, userName);
  // };
  //
  // eraseUserName = (): Promise<void> => {
  //   return this.erase(LocalRepoKeys.userName);
  // };
  //
  // getUserEmail = (): Promise<string | null> => {
  //   return this.get(LocalRepoKeys.userEmail);
  // };
  //
  // saveUserEmail = (userEmail: string | null): Promise<void> => {
  //   return this.set(LocalRepoKeys.userEmail, userEmail);
  // };
  //
  // eraseUserEmail = (): Promise<void> => {
  //   return this.erase(LocalRepoKeys.userEmail);
  // };
  //
  // getAuth0Id = (): Promise<string | null> => {
  //   return this.get(LocalRepoKeys.auth0Id);
  // };
  //
  // saveAuth0Id = (auth0Id: string | null): Promise<void> => {
  //   return this.set(LocalRepoKeys.auth0Id, auth0Id);
  // };
  //
  // eraseAuth0Id = (): Promise<void> => {
  //   return this.erase(LocalRepoKeys.auth0Id);
  // };

  getContextId(): Promise<string | null> {
    return this.get(LocalRepoKeys.context);
  }

  saveContextId(contextId: string): Promise<void> {
    return this.set(LocalRepoKeys.context, contextId);
  }

  getSetUpAt = (): Promise<number | null> => {
    return this.get(LocalRepoKeys.setUpAt).then((r) => (r ? parseInt(r) : null));
  };

  saveSetUpAt = (setUpAt: number | null): Promise<void> => {
    return this.set(LocalRepoKeys.setUpAt, setUpAt);
  };

  eraseSetUpAt = (): Promise<void> => {
    return this.erase(LocalRepoKeys.setUpAt);
  };

  getWasAuthenticated = (): Promise<boolean> => {
    return this.get(LocalRepoKeys.wasAuthenticated).then((r) => r === '1');
  };

  saveWasAuthenticated = (bool: boolean | null): Promise<void> => {
    return this.set(LocalRepoKeys.wasAuthenticated, bool ? '1' : '0');
  };

  eraseWasAuthenticated = (): Promise<void> => {
    return this.erase(LocalRepoKeys.wasAuthenticated);
  };

  getLastSubscriptionType = (): Promise<string | null> => {
    return this.get(LocalRepoKeys.lastSubscriptionType);
  };

  saveLastSubscriptionType = (value: string | null): Promise<void> => {
    return this.set(LocalRepoKeys.lastSubscriptionType, value);
  };

  eraseLastSubscriptionType = (): Promise<void> => {
    return this.erase(LocalRepoKeys.lastSubscriptionType);
  };

  getLastNativeSubscription = (): Promise<string | null> => {
    return this.get(LocalRepoKeys.lastNativeSubscription);
  };

  saveLastNativeSubscription = (value: string | null): Promise<void> => {
    return this.set(LocalRepoKeys.lastNativeSubscription, value);
  };

  eraseLastNativeSubscription = (): Promise<void> => {
    return this.erase(LocalRepoKeys.lastNativeSubscription);
  };

  getUserId = (): Promise<string | null> => {
    return this.get(LocalRepoKeys.user_id);
  };

  saveUserId = (userId: string | null): Promise<void> => {
    return this.set(LocalRepoKeys.user_id, userId);
  };

  eraseUserId = (): Promise<void> => {
    return this.erase(LocalRepoKeys.user_id);
  };

  getDeviceId = (): Promise<string | null> => {
    return this.get(LocalRepoKeys.device_id);
  };

  saveDeviceId = (deviceId: string | null): Promise<void> => {
    return this.set(LocalRepoKeys.device_id, deviceId);
  };

  eraseDeviceId = (): Promise<void> => {
    return this.erase(LocalRepoKeys.device_id);
  };

  getAskedForPermissions = (): Promise<GrantedPermissions> => {
    return this.get(LocalRepoKeys.askedForPermissions).then((r) =>
      r ? JSON.parse(r) : {}
    );
  };

  saveAskedForPermissions = (value: GrantedPermissions | null): Promise<void> => {
    return this.set(LocalRepoKeys.askedForPermissions, JSON.stringify(value));
  };

  eraseAskedForPermissions = (): Promise<void> => {
    return this.erase(LocalRepoKeys.askedForPermissions);
  };
  //
  // getEncryptionPassword = (): Promise<string | null> => {
  //   return this.get(LocalRepoKeys.password);
  // };
  //
  // saveEncryptionPassword = (password: string | null): Promise<void> => {
  //   return this.set(LocalRepoKeys.password, password);
  // };
  //
  // eraseEncryptionPassword = (): Promise<void> => {
  //   return this.erase(LocalRepoKeys.password);
  // };
  //
  // getActiveEntitlement = (): Promise<string | null> => {
  //   return this.get(LocalRepoKeys.activeEntitlement);
  // };
  //
  // saveActiveEntitlement = (activeEntitlement: string | null): Promise<void> => {
  //   return this.set(LocalRepoKeys.activeEntitlement, activeEntitlement);
  // };
  //
  // eraseActiveEntitlement = (): Promise<void> => {
  //   return this.erase(LocalRepoKeys.activeEntitlement);
  // };
}
