import React from 'react';
import TaskStatusList from '../components/Tasks/TaskStatusList';
import { IonContent, IonPage } from '@ionic/react';
import { withStorage } from '../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../helpers/WorkspaceProviders';
import { Repos, StorageDeps } from '../helpers/InitStorage';
import {
  ItemType,
  ExcludesNull,
  ProjectEnrichedWithTaskStats,
  ProjectStatus,
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
} from '../types/CoreTypes';
import { getNextTaskForProject } from '../helpers/ProjectHelper';
import TaskListFilter from '../components/Tasks/TaskListFilter';
import CommonHeader from '../components/Common/CommonHeader';
import { TaskStatusKey } from '../helpers/TaskHelper';
import { Subscription } from 'rxjs';
import { RxChangeEvent } from 'rxdb/plugins/core';
import ListNavigation from '../components/Common/ListNavigation';
import ProjectNextTask from '../components/Tasks/ProjectNextTask';
import { debounce } from 'lodash';
import { DateProps, withDate } from '../helpers/DateProvider';
import { logDebug } from '../lib/logger';

interface State {
  projectsWithNextTasks: {
    task: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;
    project: ProjectEnrichedWithTaskStats;
  }[];
}

interface Props extends WorkspaceProps, StorageDeps, DateProps {
  repos: Repos;
}

const PROJECT_NEXT = 'PROJECT_NEXT';

class Next extends React.Component<Props, State> {
  state: State = {
    projectsWithNextTasks: [],
  };

  sub?: Subscription;

  componentDidUpdate(prevProps: Props) {
    if (this.props.context.id !== prevProps.context.id) {
      this.loadProjectNextTaskData();
    }
  }

  handleDbChangeEvent = (evt: RxChangeEvent) => {
    if (evt.operation === 'INSERT') {
      if ([ItemType.task].includes(evt.collectionName as ItemType)) {
        this.loadProjectNextTaskData();
      }
    }
    if (evt.operation === 'UPDATE' || evt.operation === 'DELETE') {
      if (
        [ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)
      ) {
        this.loadProjectNextTaskData();
      }
    }
  };

  componentDidMount(): void {
    this.loadProjectNextTaskData();
    this.sub = this.props.subscribeForDbChanges(this.handleDbChangeEvent);
  }
  componentWillUnmount() {
    this.sub?.unsubscribe();
  }

  loadProjectNextTaskData = debounce(async () => {
    logDebug([], 'loadProjectNextTaskData...');
    const { context, repos, dateFormatted } = this.props;
    // get active projects
    const activeProjects = await repos.projectRepo.getAllForStatus(
      context.id,
      ProjectStatus.STATUS_ACTION
    );
    const projectTaskPairs = activeProjects.map(async (project) => {
      const task = await getNextTaskForProject(
        context,
        project,
        repos.taskRepo,
        dateFormatted
      );
      if (task) {
        return { project, task };
      }
      return null;
    });
    const projectsWithNextTasks = (await Promise.all(projectTaskPairs)).filter(
      (Boolean as any) as ExcludesNull
    );
    const tagIds = projectsWithNextTasks
      .map((p) => p.task.tags || [])
      .flat()
      .map((t) => t.id);
    const tags = await repos.tagRepo.getByIds(tagIds);
    const enriched = projectsWithNextTasks.map((r) => ({
      project: r.project,
      task: {
        ...r.task,
        tagsData: (r.task.tags || []).map(
          (t) => tags.filter((tag) => tag.id === t.id)[0]
        ),
        project: r.project,
      },
    }));
    this.setState({ projectsWithNextTasks: enriched });
  }, 500);

  render() {
    const { projectsWithNextTasks } = this.state;
    return (
      <IonPage>
        <CommonHeader />
        <IonContent>
          <ListNavigation
            collections={[TaskStatusKey.DueSoon, TaskStatusKey.Next, PROJECT_NEXT]}
          >
            {(listNavigationApi) => (
              <TaskListFilter>
                {({ filter, setUniqueAndStatsFilterValues }) => (
                  <>
                    <TaskStatusList
                      // defaultStatus={TaskStatus.STATUS_ACTION_LIST}
                      listNavigationApi={listNavigationApi}
                      filter={filter}
                      setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                      statuses={[TaskStatusKey.DueSoon, TaskStatusKey.Next]}
                      emptyMessage="No tasks here yet. Tasks in the 'Next' state are your action list to be done soon"
                    />

                    <ProjectNextTask
                      handle={PROJECT_NEXT}
                      filter={filter}
                      setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                      listNavigationApi={listNavigationApi}
                      projectsWithNextTasks={projectsWithNextTasks}
                    />
                  </>
                )}
              </TaskListFilter>
            )}
          </ListNavigation>
        </IonContent>
      </IonPage>
    );
  }
}

export default withDate(withStorage(withWorkspace(Next)));
