import React from 'react';
import { withAuth0, WithAuth0Props } from '../Auth0ReactFork';
import { shouldUseStripe, subscribeStripe } from '../lib/SubscriptionHelper';
import { AuthUserProps, withAuthUser } from '../helpers/AuthUserProvider';
import { RouteComponentProps } from 'react-router';
import { Routes } from '../lib/routes';
import { IonContent, IonLoading, IonPage, IonTitle, IonToast } from '@ionic/react';
import StripeForm from './StripeForm';
import { StripePriceType } from '@todo/common';
import { AuthError } from '../lib/Errors';
import { logInfo, logWarn } from '../lib/logger';
import CommonHeader from '../components/Common/CommonHeader';
import { Capacitor } from '@capacitor/core';
import { getEnvVarOrDie } from '../lib/EnvManager';
import { withRouter } from 'react-router-dom';
import { AUTH_TIMEOUT_SEC } from '../lib/Constants';
import {
  SubscriptionStatusProps,
  withSubscriptionStatus,
} from '../helpers/SubscriptionStatusProvider';

const APP_HANDLE = getEnvVarOrDie('REACT_APP_APP_HANDLE');

interface Props
  extends SubscriptionStatusProps,
    WithAuth0Props,
    RouteComponentProps,
    AuthUserProps {}

interface State {
  error: string | null;
  product: StripePriceType | null;
  success: string | null;
  willUseStripe: boolean | null;
  processing: boolean | null;
}

class PurchasePage extends React.Component<Props, State> {
  state: State = {
    product: null,
    error: null,
    success: null,
    willUseStripe: null,
    processing: null,
  };

  redirectBack = () => {
    const { history } = this.props;
    history.push(Routes.settings, { direction: 'root' });
  };

  getProduct = () => {
    const { location } = this.props;

    const product = new URLSearchParams(location.search).get('product') as
      | StripePriceType
      | -1;

    if (product === -1) {
      this.setState({ error: 'No product identifier found!' });
      logWarn([], 'No product identifier found!');
      this.redirectBack();
      return;
    }

    return product;
  };

  async componentDidMount() {
    const willUseStripe = shouldUseStripe();
    const product = this.getProduct();
    if (product) this.setState({ willUseStripe, product });

    if (willUseStripe) {
      if (!this.props.userInfo?.auth) {
        await this.logIn();
      }
    } else {
      // await this.nativeSubscribe();
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    // console.log('abc componentDidUpdate', { prevProps, props: this.props });
    // if (!this.props.userInfo?.auth) {
    //   await this.logIn();
    // }
    // if (!prevProps.userInfo?.auth && !!this.props.userInfo?.auth) {
    //   // was not logged in, now logged in
    //   await this.nativeSubscribe();
    // }
    // if (prevState.willUseStripe !== this.state.willUseStripe) {
    //   // was not logged in, now logged in
    //   await this.nativeSubscribe();
    // }
    if (prevProps.location.search !== this.props.location.search) {
      const product = this.getProduct();
      if (product) this.setState({ product });
    }
  }

  eraseSuccess = () => {
    this.setState({ success: null });
  };

  eraseError = () => {
    this.setState({ error: null });
  };

  logIn = async () => {
    const { product } = this.state;

    const {
      userInfo,
      loadingUserInfo,
      auth0: { loginWithPopup },
    } = this.props;
    if (!userInfo) {
      throw new Error('no user info...');
    }
    const auth = userInfo.auth;

    if (auth) return;

    if (!loadingUserInfo) {
      logInfo([], 'purchase.log_in');
      // Note: this can cause double token fetching and screen blinking
      // if a race condition occurs that this code is called after redirect
      // but before Auth0 updates itself with the new authorized state
      // May need to rethink how AuthUserProvider sets setLoading
      await loginWithPopup(
        {
          screen_hint: 'signup',
          redirectUri: Capacitor.isNative
            ? `${APP_HANDLE}://${Routes.purchase}?product=${product}`
            : `${window.location.origin}/${Routes.purchase}?product=${product}`,
        },
        { timeoutInSeconds: AUTH_TIMEOUT_SEC },
        true
      ).catch(this.redirectBack);
    }
  };

  // Stripe (web) subscribe flow.
  processStripeSubscribe = async (
    stripeToken: string,
    handleNextAction: (secret: string) => Promise<string>
  ) => {
    return this.processStripeSubscribeRecursive({
      handleNextAction,
      stripeToken,
    });
  };

  processStripeSubscribeRecursive = async ({
    handleNextAction,
    stripePaymentIntent,
    stripeToken,
  }: {
    handleNextAction: (secret: string) => Promise<string>;
    stripeToken?: string;
    stripePaymentIntent?: string;
  }) => {
    const { userInfo, reloadAuthentication } = this.props;
    const { product } = this.state;

    const auth = userInfo?.auth;

    if (!auth) throw new Error('User not authorized!');

    if (product === null) return;

    try {
      logInfo([], 'purchase.subscribe_stripe');
      const subResponse = await subscribeStripe({
        productIdentifier: product,
        token: auth.token,
        userId: auth.userId,
        stripeToken,
        stripePaymentIntent,
      });

      if (subResponse.action) {
        const paymentIntent = await handleNextAction(
          subResponse.action.client_secret
        );
        await this.processStripeSubscribeRecursive({
          handleNextAction,
          stripePaymentIntent: paymentIntent,
        });
        return;
      }

      this.setState({
        success: 'Created Subscription!',
      });
      logInfo([], 'purchase.subscribe_stripe.success');
      reloadAuthentication(true);
      setTimeout(this.redirectBack, 3000);
    } catch (e) {
      if (e instanceof AuthError) {
        logInfo([], 'purchase.subscribe_stripe.log_in');
        reloadAuthentication(true);
      } else {
        logWarn([], 'purchase.subscribe_stripe.error', { error: e });
        this.setState({ error: e.message });
        setTimeout(this.redirectBack, 5000);
      }
    }
  };

  // IOS subscribe flow, possibly (not tested) will support Android too

  render() {
    const { willUseStripe, processing, error, success, product } = this.state;

    // stripe check is loading
    if (willUseStripe === null) return null;
    // product is loading
    if (product === null) return null;

    const { userInfo } = this.props;

    // todo: can guarantee that user ID is always set by having
    //  another provider that gets user info as input.
    //  If this tree is rendered, user info is always already set!
    if (!userInfo) {
      throw new Error('no user info...');
    }

    const auth = userInfo?.auth;

    return (
      <IonPage>
        <CommonHeader>
          <IonTitle>Purchase</IonTitle>
        </CommonHeader>
        <IonContent>
          <div>
            {auth && willUseStripe && product && (
              <StripeForm
                packageId={product}
                onSuccess={this.processStripeSubscribe}
              />
            )}
            {processing && (
              <IonLoading
                duration={60000}
                isOpen={!willUseStripe || !auth}
                message={'Please wait...'}
              />
            )}
            <IonToast
              isOpen={Boolean(error)}
              onDidDismiss={this.eraseError}
              message={error || ''}
              position="top"
              color="danger"
              duration={5000}
            />
            <IonToast
              isOpen={Boolean(success)}
              onDidDismiss={this.eraseSuccess}
              message={success || ''}
              position="top"
              color="success"
              duration={5000}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withSubscriptionStatus(
  withAuthUser(withRouter(withAuth0(PurchasePage)))
);
