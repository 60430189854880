import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { IonIcon, IonItem, IonLabel, IonReorder } from '@ionic/react';
import {
  HotKeyHandler,
  ProjectEnrichedWithTaskStats,
  ProjectStatus,
} from '../../types/CoreTypes';
import { create, reorderFourOutline, squareOutline } from 'ionicons/icons';
import { genericStyles } from '../../helpers/styles';
import { TableCell } from '../Common/TableCell';
import ProjectProgressIcon from './ProjectProgressIcon';
import PriorityInfo from '../Common/PriorityInfo';
import ProjectStatusInfo from './ProjectStatusInfo';
import { ProjectHandlers } from '../../types/ProjectHandlers';
import { useHistory } from 'react-router';
import ProjectLink from '../Common/ProjectLink';
import ProjectTimeWidget from './ProjectTimeWidget';
import deepEqual from 'fast-deep-equal';
import TaskDateDueInfo from '../Tasks/TaskDateDueInfo';
import { useDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';

interface Props {
  project: ProjectEnrichedWithTaskStats;
  projectHandlers: ProjectHandlers;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  manualReorder?: (up: boolean) => Promise<boolean>;
  ref?: any;
  compact?: boolean;
}

// eslint-disable-next-line react/display-name
const ProjectListRowDesktop: React.FunctionComponent<Props> = forwardRef<any, Props>(
  (props: Props, ref) => {
    const {
      project,
      isSelected,
      onAfterNavigate,
      compact,
      manualReorder,
      projectHandlers: {
        onChangeDue,
        onChangePriority,
        onChangeName,
        onChangeState,
        onStartStateChange,
        getHandlers,
      },
    } = props;

    const history = useHistory();
    const { date } = useDate();
    const nodeRef = useRef<any>(null);

    const url = `/projects/details/${project.id}`;

    useImperativeHandle(ref, () => ({
      impress() {
        history.push(url, { direction: 'root' });
      },
      scrollIntoView() {
        nodeRef.current &&
          nodeRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      },
      canMove(): boolean {
        return manualReorder !== undefined;
      },
      async moveDown(): Promise<boolean | undefined> {
        if (manualReorder) {
          return await manualReorder(false);
        }
      },
      async moveUp(): Promise<boolean | undefined> {
        if (manualReorder) {
          return await manualReorder(true);
        }
      },
      getHandlers(): HotKeyHandler[] | undefined {
        if (getHandlers) {
          return getHandlers(project);
        }
      },
    }));

    logDebug([], 'refresh: project row render');

    return (
      <IonItem ref={nodeRef} color={isSelected ? 'light' : undefined}>
        <IonReorder slot="start" style={{ marginRight: 10 }}>
          <IonIcon color="medium" icon={reorderFourOutline} />
        </IonReorder>
        <IonLabel
          className={
            'list-desktop project-list-row list-row' +
            ' ' +
            (compact ? 'table-container-compact' : 'table-container-regular')
          }
        >
          <TableCell
            className="table-cell-icon"
            onClick={
              onChangeState
                ? () => {
                    onChangeState(ProjectStatus.STATUS_ARCHIVE, project);
                  }
                : undefined
            }
          >
            <IonIcon
              color="success"
              className="done-button"
              style={genericStyles.icon}
              icon={squareOutline}
            />
          </TableCell>

          <TableCell className="table-cell-status">
            <ProjectStatusInfo
              status={project.status}
              onClick={
                onStartStateChange ? () => onStartStateChange(project) : undefined
              }
            />
          </TableCell>

          {onChangeName && (
            <TableCell className="table-cell-icon">
              <IonIcon
                color="medium"
                style={genericStyles.icon}
                icon={create}
                onClick={() => onChangeName(project)}
              />
            </TableCell>
          )}

          <TableCell title="Total tasks" className="">
            <ProjectProgressIcon project={project} width={30} />
          </TableCell>

          <TableCell
            takeRemainingSpace
            className="table-cell-project"
            onClick={onAfterNavigate}
          >
            <ProjectLink project={project} />
          </TableCell>

          <TableCell className="table-cell-small-text non-clickable" title="Time">
            <ProjectTimeWidget project={project} />
          </TableCell>

          <TableCell className="table-cell-small-text clickable" title="Due date">
            <TaskDateDueInfo
              currentDate={date}
              dateDue={project.dateDue}
              onClick={onChangeDue ? () => onChangeDue(project) : undefined}
            />
          </TableCell>

          <TableCell className="large-icon" title="Priority">
            <PriorityInfo
              onClick={
                onChangePriority ? () => onChangePriority(project) : undefined
              }
              priority={project.priorityValues}
            />
          </TableCell>
        </IonLabel>
      </IonItem>
    );
  }
);

const Memoized = React.memo(ProjectListRowDesktop, deepEqual);

export default Memoized;
