import chroma from 'chroma-js';
import React, { CSSProperties } from 'react';
import { OptionTypeWithColor } from '../../types/CoreTypes';
import { IonIcon } from '@ionic/react';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import Select from 'react-select';
import { Props as SelectProps } from 'react-select';

export interface Props {
  data: OptionTypeWithColor;
  isDisabled?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
}

const DEFAULT_COLOR = 'black';

function colorToUse(color: string | undefined) {
  return color || DEFAULT_COLOR;
}

function selectOptionStyle(
  styles: CSSProperties,
  { data, isDisabled, isFocused, isSelected }: Props
): CSSProperties {
  const color = chroma(colorToUse(data.color));
  return {
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? colorToUse(data.color)
      : isFocused
      ? color.alpha(0.1).css()
      : undefined,
    color: isDisabled
      ? '#ccc'
      : isSelected
      ? chroma.contrast(color, 'white') > 2
        ? 'white'
        : 'black'
      : colorToUse(data.color),
    cursor: isDisabled ? 'not-allowed' : 'default',
    fontWeight: 'bold',

    // @ts-ignore
    ':active': {
      // @ts-ignore
      ...styles[':active'],
      backgroundColor:
        !isDisabled &&
        (isSelected ? colorToUse(data.color) : color.alpha(0.3).css()),
    },
  };
}

export const formatOptionLabel = ({ label, icon }: OptionTypeWithColor) => (
  <div style={{ display: 'flex' }}>
    {icon && <IonIcon icon={icon} />}
    <div>{label}</div>
  </div>
);

export const selectStyles = {
  option: selectOptionStyle,
  singleValue: selectOptionStyle,
  multiValue: (styles: CSSProperties, { data }: Props) => {
    const color = chroma(colorToUse(data.color));
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),

      'ion-icon': {
        color: colorToUse(data.color),
      },
    };
  },
  multiValueLabel: (styles: CSSProperties, { data }: Props) => ({
    ...styles,
    color: colorToUse(data.color),
  }),
  multiValueRemove: (styles: CSSProperties, { data }: Props) => ({
    ...styles,
    color: colorToUse(data.color),
    ':hover': {
      backgroundColor: colorToUse(data.color),
      color: 'white',
    },
  }),
  menu: (styles: CSSProperties, { data }: Props) =>
    ({
      ...styles,
      zIndex: 99999,
    } as CSSProperties),
};

export const MySelect = (
  props: SelectProps<OptionTypeWithColor> &
    CreatableProps<OptionTypeWithColor> & { isCreatable?: boolean } & {
      forwardRef: any;
    }
) => {
  if (props.isCreatable) {
    return <CreatableSelect ref={props.forwardRef} {...props} />;
  }
  return <Select ref={props.forwardRef} {...props} />;
};
