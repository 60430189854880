import React from 'react';
import { NamedHotKeyHandler } from '../types/CoreTypes';
import { withStorage } from '../helpers/StorageProviders';
import { StorageDeps } from '../helpers/InitStorage';
import { ListNavigationNoHotKey } from './Common/ListNavigationCommon';
import MagicResultsListView, { collections } from './MagicResultsListView';

interface Props extends StorageDeps {
  // todo: if added not hot keyed results, can add them to a wrapper like SearchResults
  results: NamedHotKeyHandler[];
  onClose: () => void;
}

interface State {
  selectedIdx: number;
}

class MagicResultsList extends React.PureComponent<Props, State> {
  render() {
    const { results, onClose } = this.props;

    return (
      <ListNavigationNoHotKey
        onAfterNavigate={onClose}
        useSecondLevelModal
        allowNavWhenMagicOpen
        collections={collections}
      >
        {(listNavigationApi) => (
          <MagicResultsListView
            listNavigationApi={listNavigationApi}
            onClose={onClose}
            results={results}
          />
        )}
      </ListNavigationNoHotKey>
    );
  }
}

export default withStorage(MagicResultsList);
