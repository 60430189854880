import { Priority, PriorityICE, PriorityValues } from '../types/CoreTypes';

export function getPriorityScore(
  priority?: PriorityValues | null
): number | undefined {
  if (!priority) return undefined;
  if (priority.preset) {
    return getPriorityBoundaries(priority.preset)[1];
  }
  if (priority && priority.ice) {
    return getICE(priority.ice);
  }
  return undefined;
}

export function getICE(ice: PriorityICE) {
  return ice.impact * ice.confidence * ice.ease;
}

export function getPriorityBoundaries(priority: Priority) {
  if (
    ![
      Priority.PRIORITY_LOW,
      Priority.PRIORITY_MEDIUM,
      Priority.PRIORITY_HIGH,
    ].includes(priority)
  ) {
    throw Error('invalid priority passed!');
  }
  switch (priority) {
    case Priority.PRIORITY_LOW:
      return [0, 200];
    case Priority.PRIORITY_MEDIUM:
      return [201, 499];
    case Priority.PRIORITY_HIGH:
      return [500, 1000];
  }
}

export function getPriorityEnum(
  priority?: PriorityValues | null
): Priority | undefined {
  const score = getPriorityScore(priority);
  if (!score) return undefined;
  if (score <= getPriorityBoundaries(Priority.PRIORITY_LOW)[1])
    return Priority.PRIORITY_LOW;
  if (score >= getPriorityBoundaries(Priority.PRIORITY_HIGH)[0])
    return Priority.PRIORITY_HIGH;
  return Priority.PRIORITY_MEDIUM;
}
