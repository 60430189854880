import React, { createContext, useContext } from 'react';
import { Auth, UserInfo } from '../types/CoreTypes';
import { withAuth0, WithAuth0Props } from '../Auth0ReactFork';
import { DbDeps, withDb } from './DbProvider';
import { logDebug, logInfo, logWarn } from '../lib/logger';

// @ts-ignore
export const AuthUserContext = createContext<AuthUserProps>(undefined);

interface Props extends DbDeps, WithAuth0Props {
  // auth?: Auth;
  // setAuth: (auth: Auth) => Promise<void>;
  // userId: string;
  domain: string;
  children: any;
}

export interface AuthUserProps {
  userInfo: UserInfo | null;
  setLocalMode: () => void;
  reloadAuthentication: (ignoreCache?: boolean) => void;
  // loading user data, should block UI and show loading dialog
  loadingUserInfo: boolean;
  // there's token refresh going on behind the scenes
  updatingUserInfo: boolean;
  reLoginRequired: boolean;
  userIdConflict: boolean;
}

interface ClaimsType {
  email: string;
  name: string;
  sub: string;
  // 'http://todo/userId'?: string;
  'http://todo/encryptionPassword'?: string;
  'http://todo/activeEntitlement'?: string;
}

interface State {
  userInfo: UserInfo | null;
  loadingUserInfo: boolean;
  updating: boolean;
  reLoginRequired: boolean;
  userIdConflict: boolean;
}

enum GetTokenResponse {
  OK,
  NEED_RELOGIN,
  NOT_AVAILABLE,
}

class GetTokenValue {
  readonly status: GetTokenResponse;
  readonly token?: string;

  constructor(status: GetTokenResponse, token?: string) {
    this.status = status;
    this.token = token;
  }
}

// const syncURL = process.env.REACT_APP_REPLICATION_URL;

class AuthUserProviderInner extends React.Component<Props, State> {
  readonly state: State = {
    userInfo: null,
    loadingUserInfo: false,
    updating: false,
    reLoginRequired: false,
    userIdConflict: false,
  };

  getUserInfo = async (): Promise<UserInfo | null> => {
    logDebug(['auth'], 'getUserInfo');
    const [
      setUpAt,
      wasAuthenticated,
      lastUsedUserId,
      lastSubscriptionType,
      // encryptionPassword,
      // activeEntitlement,
      // auth0Id,
      // userEmail,
      // userName,
      // token
    ] = await Promise.all([
      this.props.localSettingsRepo.getSetUpAt(),
      this.props.localSettingsRepo.getWasAuthenticated(),
      this.props.localSettingsRepo.getUserId(),
      this.props.localSettingsRepo.getLastSubscriptionType(),
      // localSettingsRepo.getEncryptionPassword(),
      // localSettingsRepo.getActiveEntitlement(),
      // localSettingsRepo.getAuth0Id(),
      // localSettingsRepo.getUserEmail(),
      // localSettingsRepo.getUserName(),
      // localSettingsRepo.getToken()
    ]);
    // if (setUpAt) {
    // let auth = null;
    // if (auth0Id && encryptionPassword && token && userEmail && userName) {
    //   auth = {
    //     auth0Id,
    //     token,
    //     claims: {
    //       encryptionPassword,
    //       activeEntitlement,
    //       userEmail,
    //       userName
    //     }
    //   };
    // }
    return {
      wasAuthenticated,
      setUpAt,
      lastUsedUserId,
      lastSubscriptionType,
      auth: null,
    };
    // } else return null;
  };

  setUserInfo = (userInfo: UserInfo | null) => {
    this.setState({
      userInfo,
    });
  };

  // eraseUser = async () => {
  //   await this.props.localSettingsRepo.eraseSetUpAt();
  //   this.setUserInfo(null);
  // };

  setLocalMode = async () => {
    // await this.eraseUser();
    // await this.props.localSettingsRepo.eraseSetUpAt();
    // this.setUserInfo(null);

    const setUpAt = new Date().getTime();
    const wasAuthenticated = false;
    await this.props.localSettingsRepo.saveSetUpAt(setUpAt);
    await this.props.localSettingsRepo.saveWasAuthenticated(wasAuthenticated);
    await this.props.localSettingsRepo.eraseUserId();
    await this.props.localSettingsRepo.eraseLastSubscriptionType();
    this.setUserInfo({
      setUpAt,
      wasAuthenticated,
      lastSubscriptionType: null,
      auth: null,
      lastUsedUserId: null,
    });
  };

  saveAuthenticatedUser = async (setUpAt: number, auth: Auth) => {
    logDebug([], '[auth] token changed: updating saved user info');
    // await eraseUser();
    const wasAuthenticated = true;
    await this.props.localSettingsRepo.saveSetUpAt(setUpAt);
    await this.props.localSettingsRepo.saveWasAuthenticated(wasAuthenticated);
    await this.props.localSettingsRepo.saveUserId(auth.userId);
    await this.props.localSettingsRepo.saveLastSubscriptionType(
      auth.activeEntitlement
    );
    this.setUserInfo({
      setUpAt,
      wasAuthenticated,
      lastSubscriptionType: auth.activeEntitlement,
      auth,
      lastUsedUserId: auth.userId,
    });
  };

  async componentDidMount(): Promise<void> {
    await this.initUserInfo();
    await this.reloadAuthenticationEffect();
  }

  async componentDidUpdate(prevProps: Props) {
    if (
      this.props.auth0.isAuthenticated !== prevProps.auth0.isAuthenticated ||
      this.props.auth0.isLoading !== prevProps.auth0.isLoading
    ) {
      await this.reloadAuthenticationEffect();
    }
  }

  // call it every time auth status from Auth0 changes
  reloadAuthenticationEffect = async () => {
    logDebug(['sync', 'auth'], 'reloadAuthentication Effect');
    await this.reloadAuthentication();
  };

  initUserInfo = async () => {
    logDebug(['auth'], 'initUserInfo');
    await this.getUserInfo().then((userInfo) => {
      if (userInfo) {
        this.setUserInfo(userInfo);
      }
    });
  };

  getToken = async (ignoreCache = false): Promise<GetTokenValue> => {
    // if (userInfo?.auth?.token) {
    //   return userInfo?.auth?.token;
    // }
    return await this.props.auth0
      .getAccessTokenSilently({
        ignoreCache,
        // audience: syncURL
        // scope: 'read:posts'
      })
      .then((token) => new GetTokenValue(GetTokenResponse.OK, token))
      .catch(async (e) => {
        logWarn([], 'getAccessTokenSilently', { e, msg: e.message });
        if (
          [
            'Login required',
            'Consent required',
            'Unknown or invalid refresh token.',
          ].includes(e.message)
        ) {
          try {
            logInfo([], 'auth.getAccessTokenWithPopup');
            return this.props.auth0
              .tryGetAccessTokenWithPopupOrReLogin({
                display: 'page',
                // audience: syncURL
              })
              .then((token) => {
                if (token) return new GetTokenValue(GetTokenResponse.OK, token);
                // no token means that Native re-login flow was initiated,
                // and will result in page reload
                else return new GetTokenValue(GetTokenResponse.NOT_AVAILABLE);
              });
          } catch (e) {
            logWarn([], 'getAccessTokenWithPopup', { e, msg: e.message });
            return new GetTokenValue(GetTokenResponse.NEED_RELOGIN);
          }
        } else {
          logWarn([], 'getAccessTokenSilently errored out', { e });
          return new GetTokenValue(GetTokenResponse.NOT_AVAILABLE);
        }
      });
  };

  reloadAuthentication = async (ignoreCache = false) => {
    const { reLoginRequired, userInfo, loadingUserInfo } = this.state;
    const {
      auth0: { isAuthenticated, isLoading, getIdTokenClaims },
    } = this.props;
    if (reLoginRequired) {
      this.setState({ reLoginRequired: false });
    }
    if (this.state.userIdConflict) {
      this.setState({ userIdConflict: false });
    }
    logDebug([], '[auth] reloading authentication!', { isLoading, isAuthenticated });
    if (reLoginRequired) {
      logDebug([], '[auth] isReLogin');
    }

    if (
      (userInfo?.wasAuthenticated || isAuthenticated) &&
      !isLoading &&
      !loadingUserInfo
    ) {
      // need to show loading after initial render sets userInfo to stored ID and no auth
      // to avoid double auth on purchase page
      const shouldShowLoading = !userInfo?.auth;
      const newState: any = {
        updating: true,
      };
      if (shouldShowLoading) {
        newState.loadingUserInfo = true;
      }

      this.setState(newState);
      const getTokenValue = await this.getToken(ignoreCache);

      // todo: all branches need to set loadingUserInfo state to false to avoid
      //  deadlock. It's hard to comprehend and better refactor
      switch (getTokenValue.status) {
        case GetTokenResponse.NEED_RELOGIN: {
          // // if already tried to get the token, log user out and erase all data
          // if (reLoginRequired) {
          //   alert('need to erase data!');
          //   this.setState({
          //     reLoginRequired: true,
          //     updating: false,
          //     loadingUserInfo: false,
          //   });
          //   // todo
          //   // can pass eraseAll from storageDeps instance
          //   //  when calling reloadAuthentication in App.tsx
          //   // eraseAll().then(()ø => window.location.reload());
          // } else {
          logInfo([], 'auth.relogin_required');
          this.setState({
            reLoginRequired: true,
            updating: false,
            loadingUserInfo: false,
          });
          // }
          return;
        }
        case GetTokenResponse.NOT_AVAILABLE: {
          this.setState({
            updating: false,
            loadingUserInfo: false,
          });
          return;
        }
        case GetTokenResponse.OK: {
          const newToken = getTokenValue.token;

          if (!newToken)
            throw new Error('got OK when getting token, but the token in empty');

          // @ts-ignore
          const claims: ClaimsType | undefined = await getIdTokenClaims();
          // if (ignoreCache) {
          //   debugger;
          // }

          if (!claims) {
            throw new Error('claims are not set!');
          }
          // const userId: string | undefined = claims['http://todo/userId'];
          // const auth0Id: string | undefined = user.sub;
          const userId: string | undefined = claims.sub;
          const encryptionPassword: string | undefined =
            claims['http://todo/encryptionPassword'];
          const activeEntitlement: string | null =
            claims['http://todo/activeEntitlement'] || null;
          const email: string | null = claims.email;
          const name: string | null = claims.name;
          // if (!auth0Id) {
          //   throw new Error('no auth0id set!');
          // }
          if (!userId) {
            throw new Error('no userId set!');
          }
          // note: removed this because not using enc passwd
          // if (!encryptionPassword) {
          //   throw new Error('no encryptionPassword set!');
          // }

          if (userInfo?.lastUsedUserId && userId !== userInfo.lastUsedUserId) {
            logInfo([], 'auth.userIdConflict');
            this.setState({
              userIdConflict: true,
              updating: false,
              loadingUserInfo: false,
            });
            return;
          }

          // getIdTokenClaims

          // if auth0 doesn't have metadata, need to set it from local metadata
          // this is only done first time!
          // if (!userId || !encryptionPassword) {
          //   if (!userId) {
          //     if (!prevUserId) {
          //       logDebug([], 'user ID was not yet set, generating it');
          //       userId = generateUserId();
          //     } else {
          //       logDebug([], 'using user ID from local set up');
          //       userId = prevUserId;
          //     }
          //   }
          //   encryptionPassword = await setUserMetadata(userId, auth0Id, token);
          // }
          if (
            // userId !== userInfo?.userId ||
            newToken !== userInfo?.auth?.token
            // !isEqual(newAuth.claims, userInfo.auth?.claims)
          ) {
            // if (ignoreCache) {
            //   debugger;
            // }
            // todo: problem with this code executed 2-3 times on initial load,
            //  as this fn is called as dependencies change.
            //  But token is still the same as it's cached in local storage by Auth0
            const newAuth: Auth = {
              token: newToken,
              userId,
              // note: added this because not using enc passwd
              encryptionPassword: encryptionPassword || '',
              activeEntitlement,
              userName: name,
              userEmail: email,
            };
            const setUpAt = userInfo?.setUpAt || new Date().getTime();
            await this.saveAuthenticatedUser(setUpAt, newAuth);
          }

          this.setState({
            updating: false,
            loadingUserInfo: false,
          });
          // setUserInfo({ userId, auth });
          // } catch (e) {
          //   console.error(e);
          // }
        }
      }
    }
  };

  render() {
    const {
      children,
      auth0: { isLoading },
    } = this.props;

    const {
      userInfo,
      reLoginRequired,
      updating,
      loadingUserInfo,
      userIdConflict,
    } = this.state;

    return (
      <AuthUserContext.Provider
        value={{
          userIdConflict,
          userInfo,
          setLocalMode: this.setLocalMode,
          reLoginRequired,
          updatingUserInfo: updating,
          loadingUserInfo: loadingUserInfo || isLoading,
          reloadAuthentication: this.reloadAuthentication,
        }}
      >
        {children}
      </AuthUserContext.Provider>
    );
  }
}

export const AuthUserProvider = withAuth0(withDb(AuthUserProviderInner));

export const useAuthUser: () => AuthUserProps = () => useContext(AuthUserContext);

export const withAuthUser = <P extends Partial<AuthUserProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof AuthUserProps>> => {
  return function withAuthUserContext(props) {
    return (
      <AuthUserContext.Consumer>
        {(contextProps: AuthUserProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </AuthUserContext.Consumer>
    );
  };
};
