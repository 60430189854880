import React from 'react';
import SetUpWizard from './components/SetUpWizard';
import { useAuthUser } from './helpers/AuthUserProvider';
import { IonApp } from '@ionic/react';
import { ClientTypeProvider } from './helpers/ClientTypeProvider';

// interface Props {
//   // onComplete: () => void;
// }

const FreshStartApp = () => {
  const { setLocalMode } = useAuthUser();

  return (
    <IonApp>
      <ClientTypeProvider>
        <SetUpWizard
          // todo: change what it returns
          onLocalMode={setLocalMode}
          // onComplete();
          // setIsFreshSetUp(false);
          // if (syncMode && auth) {
          //   startAppUsingSyncMode(true, auth)
          //     // if failed setting up with sync, set up using local mode instead
          //     .catch(async (err: Error) => {
          //       console.error(err);
          //       await localSettingsRepo.eraseUserId();
          //       await localSettingsRepo.eraseToken();
          //       await localSettingsRepo.eraseEncryptionPassword();
          //       setErrorMessage(err.toString());
          //       startAppUsingLocalMode(true);
          //     });
          // } else {
          //   startAppUsingLocalMode(true);
          // }
        />
      </ClientTypeProvider>
    </IonApp>
  );
};

export default FreshStartApp;
