import React from 'react';
import { colors } from '../../helpers/styles';
import ProgressIcon from '../Common/ProgressIcon';
import { ProjectEnrichedWithTaskStats } from '../../types/CoreTypes';

interface Props {
  project: ProjectEnrichedWithTaskStats;
  width: number;
}

function getColor(project: ProjectEnrichedWithTaskStats) {
  if (project.remainingNext > 0) return colors.primary;
  // if (project.remainingWaiting > 0) return colors.grayOut;
  return colors.danger;
}

const ProjectProgressIcon = ({ project, width }: Props) => {
  return (
    <ProgressIcon
      color={getColor(project)}
      percentage={
        ((project.totalTasks - project.remainingTasks) / project.totalTasks) * 100
      }
      text={project.remainingTasks}
      width={width}
    />
  );
};

export default ProjectProgressIcon;
