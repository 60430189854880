import React, { createContext, useContext } from 'react';
import { logDebug } from '../lib/logger';

export interface ModalsProps {
  recordModalOpen: (modalIsOpen: boolean) => void;
  recordMagicModalOpen: (modalIsOpen: boolean) => void;
  modalIsOpen: boolean;
  secondLayerModalIsOpen: boolean;
  nonMagicModalIsOpen: boolean;
  secondLayerModalIsOpenIncMagic: boolean;
}

// @ts-ignore
export const ModalsContext = createContext<ModalsProps>(undefined);

interface Props {
  children: any;
}

interface State {
  magicModalIsOpen: boolean;
  cntOpen: number;
}

export class ModalsProvider extends React.PureComponent<Props> {
  state: State = {
    magicModalIsOpen: false,
    cntOpen: 0,
  };

  handleOpenModal = (modalIsOpen: boolean) => {
    logDebug([], 'handleOpenModal', { modalIsOpen });
    const { cntOpen } = this.state;
    let newOpen = cntOpen;
    if (modalIsOpen) {
      newOpen++;
    } else {
      newOpen--;
    }
    if (newOpen < 0) {
      console.error(`newOpen was less than 0: ${newOpen}`);
      newOpen = 0;
    }
    logDebug([], 'newOpen', { newOpen });
    this.setState({ cntOpen: newOpen });
  };

  handleOpenMagicModal = (modalIsOpen: boolean) => {
    logDebug([], 'handleOpenMagicModal', { modalIsOpen });
    this.setState({
      magicModalIsOpen: modalIsOpen,
    });
  };

  private getModalIsOpen() {
    const { magicModalIsOpen, cntOpen } = this.state;
    return magicModalIsOpen || cntOpen > 0;
  }

  render() {
    const { cntOpen, magicModalIsOpen } = this.state;
    const { children } = this.props;

    return (
      <ModalsContext.Provider
        value={{
          recordModalOpen: this.handleOpenModal,
          recordMagicModalOpen: this.handleOpenMagicModal,
          modalIsOpen: this.getModalIsOpen(),
          nonMagicModalIsOpen: cntOpen > 0,
          // magicModalIsOpen: magicModalIsOpen,
          secondLayerModalIsOpenIncMagic: magicModalIsOpen || cntOpen > 1,
          secondLayerModalIsOpen: cntOpen > 1,
        }}
      >
        {children}
      </ModalsContext.Provider>
    );
  }
}

export const useModalsState: () => ModalsProps = () => useContext(ModalsContext);

export const withModalsState = <P extends Partial<ModalsProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof ModalsProps>> => {
  return function withModalsStateContext(props) {
    return (
      <ModalsContext.Consumer>
        {(contextProps: ModalsProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </ModalsContext.Consumer>
    );
  };
};
