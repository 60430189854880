import {
  CompletionHistory,
  RecurringOptionsDays,
  RecurringOptionsMonths,
  RecurringOptionsWeeks,
  RecurringOptionsYears,
  TaskType,
} from '../types/CoreTypes';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import getDay from 'date-fns/getDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import endOfDay from 'date-fns/endOfDay';
import setDay from 'date-fns/setDay';
import startOfDay from 'date-fns/startOfDay';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import { maxBy } from 'lodash';
import format from 'date-fns/format';
import { RecurringOptions } from '@todo/common';

export function willHaveMoreRepeats(date: Date, task: TaskType): boolean {
  if (!task.recurringOptions) return false;

  const gen = generateNextScheduledDate(task, date);
  return gen.next().value !== null;
}

export function formatDateWithoutTime(date: Date | number): string {
  return format(date, 'yyyy-MM-dd');
}

export function makeDateWithoutTime(str: string): Date {
  return new Date(`${str}T00:00:00`);
}

export function getLastCompleted(
  completionHistory: CompletionHistory[] | undefined
): CompletionHistory | null {
  const max = maxBy(completionHistory, (h) => h.timestamp);
  if (!max) return null;
  return max;
}

function getLastCompletedDate(
  completionHistory: CompletionHistory[] | undefined
): Date | null {
  const max = getLastCompleted(completionHistory);
  if (!max) return null;
  return makeDateWithoutTime(max.dueDate);
}

export function mergeCompletionHistory(
  date: Date,
  completionHistory: CompletionHistory[] | undefined
): CompletionHistory[] {
  const current = [...(completionHistory || [])];
  const dueDate = formatDateWithoutTime(date);
  const contains = current.some((d) => d.dueDate === dueDate);
  if (!contains) {
    current.push({
      dueDate,
      timestamp: date.getTime(),
    });
  }
  // discard too old completions
  return current.slice(-20);
}

export function taskIsRepeatedScheduledOnDate(
  date: Date,
  task: TaskType
): CheckResult {
  if (!task.recurringOptions) return { dueDate: null };
  return recurringIsRepeatedScheduledOnDate(
    date,
    task.recurringOptions,
    task.completionHistory
  );
}

export function recurringIsRepeatedScheduledOnDate(
  date: Date,
  recurringOptions: RecurringOptions,
  completionHistory?: CompletionHistory[]
): CheckResult {
  if (recurringOptions.endsAfter.occurrences) {
    if ((completionHistory?.length || 0) >= recurringOptions.endsAfter.occurrences) {
      return { dueDate: null };
    }
  }

  const startingDate = makeDateWithoutTime(recurringOptions.starting.date);

  if (startingDate > date) {
    return { dueDate: null };
  }
  const endingDate = recurringOptions.endsAfter.date
    ? makeDateWithoutTime(recurringOptions.endsAfter.date)
    : null;
  const lastCompleted = getLastCompletedDate(completionHistory);

  let result: CheckResult | null = null;
  const dateToUse = endingDate && endingDate < date ? endingDate : date;

  if (recurringOptions.repeatingEvery.days) {
    result = checkForDays(
      dateToUse,
      recurringOptions.repeatingEvery.days,
      startingDate,
      lastCompleted
    );
  }

  if (recurringOptions.repeatingEvery.weeks) {
    result = checkForWeeks(
      dateToUse,
      recurringOptions.repeatingEvery.weeks,
      startingDate,
      lastCompleted
    );
  }

  if (recurringOptions.repeatingEvery.months) {
    result = checkForMonths(
      dateToUse,
      recurringOptions.repeatingEvery.months,
      startingDate,
      lastCompleted
    );
  }

  if (recurringOptions.repeatingEvery.years) {
    result = checkForYears(
      dateToUse,
      recurringOptions.repeatingEvery.years,
      startingDate,
      lastCompleted
    );
  }

  if (!result) {
    // this case cannot happen
    throw new Error('taskIsRepeatedScheduledOnDate: should not get here!');
  }

  if (endingDate) {
    if (result.dueDate && endOfDay(endingDate) < result.dueDate) {
      return { dueDate: null };
    }
  }

  return result;
}

interface CheckResult {
  dueDate: Date | null;
}

function checkForDays(
  date: Date,
  options: RecurringOptionsDays,
  startingDate: Date,
  lastCompleted: Date | null
): CheckResult {
  const lastCompletedAdjusted = lastCompleted
    ? startOfDay(lastCompleted)
    : undefined;
  // // For cases after last completed, it's now certain that it won't be due
  // if (options.afterLastCompleted) return { dueDate: null };
  const countStartFrom = options.afterLastCompleted
    ? lastCompletedAdjusted || startingDate
    : startingDate;

  const thisDateStartOfDay = startOfDay(date);
  let until = thisDateStartOfDay;
  if (options.afterLastCompleted) {
    if (lastCompleted) {
      const diffWithDateStart = differenceInDays(thisDateStartOfDay, lastCompleted);
      if (diffWithDateStart > options.days) {
        until = addDays(lastCompleted, options.days);
      }
    } else {
      const diffWithDateStart = differenceInDays(thisDateStartOfDay, startingDate);
      if (diffWithDateStart > options.days) {
        until = startingDate;
      }
    }
  }

  // for due every X days after starting date, need to do math
  const diff = differenceInDays(until, countStartFrom);
  const rate = Math.floor(diff / options.days);
  const nextDue = addDays(countStartFrom, rate * options.days);
  if (nextDue.getTime() > (lastCompletedAdjusted || 0)) {
    return {
      dueDate: nextDue,
    };
  }
  return { dueDate: null };
}

function getLastPastMatchingWeekday(
  refDate: Date,
  weekDate: Date,
  weekdays: number[]
): Date | null {
  const ref = startOfDay(refDate);
  const adjustedDate = startOfDay(weekDate);

  let match = null;

  for (const weekday of weekdays) {
    const day = setDay(adjustedDate, weekday);
    if (day <= ref) {
      match = day;
    }
  }

  return match;
}

function checkForWeeks(
  date: Date,
  options: RecurringOptionsWeeks,
  startingDate: Date,
  lastCompleted: Date | null
): CheckResult {
  function fn(refDate: Date, weekDate: Date, weekdays: number[]): Date | null {
    const counting = getLastPastMatchingWeekday(refDate, weekDate, weekdays);
    if (counting === null) return null;
    return counting;
  }

  function getNextDueWeek(dt: Date) {
    const diff = differenceInCalendarWeeks(dt, startingDate);
    const rate = Math.floor(diff / options.weeks);
    return startOfDay(addWeeks(startingDate, rate * options.weeks));
  }

  const res = fn(date, getNextDueWeek(date), options.weekdays);
  if (res !== null) {
    if (res > (lastCompleted || 0) && res >= startingDate) return { dueDate: res };
    else return { dueDate: null };
  }

  // try 1 weeks ago, for cases when day of week this week is in the future,
  // and past week's wasn't completed
  const weekAgo = subWeeks(date, 1);
  const newRes = fn(date, getNextDueWeek(weekAgo), options.weekdays);
  if (newRes === null) return { dueDate: null };
  if (newRes > (lastCompleted || 0) && newRes >= startingDate)
    return { dueDate: newRes };
  else return { dueDate: null };
}

function checkNthWeekDay(date: Date, weekday: number, weekdayNumber: number) {
  let counting = startOfMonth(date);
  const dateToEnd = endOfMonth(date);
  let counter = 0;
  let found = false;
  let prev = null;
  while (counting <= dateToEnd) {
    if (getDay(counting) === weekday) {
      prev = counting;
      counter++;
      if (counter === weekdayNumber) {
        found = true;
        break;
      }
    }
    counting = addDays(counting, 1);
  }
  // such as no 5th monday this month
  // in that case, due on the last day of month
  // (may need to revisit in the future)
  if (!found && prev) {
    return prev;
  }

  return counting;
}

function checkForMonths(
  date: Date,
  options: RecurringOptionsMonths,
  startingDate: Date,
  lastCompleted: Date | null
): CheckResult {
  const dateShifted = startOfDay(date);
  function fn(newDate: Date, weekday: number, weekdayNumber: number): Date | null {
    const counting = checkNthWeekDay(newDate, weekday, weekdayNumber);
    if (counting > dateShifted) return null;
    return counting;
  }

  function getNextDueMonth(dt: Date) {
    const diff = differenceInCalendarMonths(dt, startingDate);
    const rate = Math.ceil(diff / options.months);
    return startOfDay(addMonths(startingDate, rate * options.months));
  }

  // need this for very edge cases, when getCurrentDueMonth(subMonths(dateShifted, 1))
  // will be still in the future, but is overdue
  // can catch: "last sunday, every 3 months / due yesterday, december, overdue"
  function getCurrentDueMonth(dt: Date) {
    const next = getNextDueMonth(dt);
    if (startOfMonth(next) > dt) {
      return getNextDueMonth(subMonths(dt, options.months));
    }
    return next;
  }

  function getCurrentDueMonthForDaily(dt: Date) {
    const next = getNextDueMonth(dt);
    if (next > dt) {
      return getNextDueMonth(subMonths(dt, options.months));
    }
    return next;
  }

  if (options.weekday) {
    const res = fn(
      getCurrentDueMonth(dateShifted),
      options.weekday.weekday,
      options.weekday.weekdayNumber
    );
    if (res !== null) {
      if (res > (lastCompleted || 0)) return { dueDate: res };
      else return { dueDate: null };
    }

    // try 1 months ago, for cases when 4th thursday this month is in the future,
    // and past month's wasn't completed
    const newRes = fn(
      getCurrentDueMonth(subMonths(dateShifted, 1)),
      options.weekday.weekday,
      options.weekday.weekdayNumber
    );
    if (newRes === null) return { dueDate: null };
    if (newRes > (lastCompleted || 0)) return { dueDate: newRes };
    else return { dueDate: null };
  } else {
    const due = getCurrentDueMonthForDaily(dateShifted);
    if (due <= dateShifted && due > (lastCompleted || 0)) {
      return { dueDate: due };
    }
  }
  return { dueDate: null };
}

function checkForYears(
  date: Date,
  options: RecurringOptionsYears,
  startingDate: Date,
  lastCompleted: Date | null
): CheckResult {
  const lastCompletedAdjusted = lastCompleted
    ? startOfDay(lastCompleted)
    : undefined;
  // // For cases after last completed, it's now certain that it won't be due
  // if (options.afterLastCompleted) return false;
  const countStartFrom = options.afterLastCompleted
    ? lastCompletedAdjusted || startingDate
    : startingDate;

  const thisDateStartOfDay = startOfDay(date);
  let until = thisDateStartOfDay;
  if (options.afterLastCompleted) {
    if (lastCompleted) {
      const diffWithDateStart = differenceInYears(thisDateStartOfDay, lastCompleted);
      if (diffWithDateStart > options.years) {
        until = addYears(lastCompleted, options.years);
      }
    } else {
      const diffWithDateStart = differenceInYears(thisDateStartOfDay, startingDate);
      if (diffWithDateStart > options.years) {
        until = startingDate;
      }
    }
  }

  // for due every X years after starting date, need to do math
  const diff = differenceInYears(until, countStartFrom);
  const rate = Math.floor(diff / options.years);
  const nextDue = addYears(countStartFrom, rate * options.years);
  if (nextDue.getTime() > (lastCompletedAdjusted || 0)) {
    return { dueDate: nextDue };
  }
  return { dueDate: null };
}

export function* generateNextScheduledDate(task: TaskType, date: Date) {
  if (!task.recurringOptions) return null;
  const recurringOptions = task.recurringOptions;
  let curDate = date;
  let cnt = task.completionHistory?.length || 0;

  const startingDate = makeDateWithoutTime(task.recurringOptions.starting.date);
  const endingDate = task.recurringOptions.endsAfter.date
    ? makeDateWithoutTime(task.recurringOptions.endsAfter.date)
    : null;

  const completedAt = getLastCompletedDate(task.completionHistory);

  let fn;
  if (recurringOptions.repeatingEvery.days) {
    const days = recurringOptions.repeatingEvery.days;
    fn = (d: Date) => generateNextDaysSince(d, days, startingDate, completedAt);
  }
  if (recurringOptions.repeatingEvery.weeks) {
    const weeks = recurringOptions.repeatingEvery.weeks;
    fn = (d: Date) => generateNextWeeksSince(d, weeks, startingDate);
  }
  if (recurringOptions.repeatingEvery.months) {
    const months = recurringOptions.repeatingEvery.months;
    fn = (d: Date) => generateNextMonthsSince(d, months, startingDate);
  }
  if (recurringOptions.repeatingEvery.years) {
    const years = recurringOptions.repeatingEvery.years;
    fn = (d: Date) => generateNextYearsSince(d, years, startingDate, completedAt);
  }

  if (!fn) return null;
  while (true) {
    const nextDate = fn(curDate);
    if (!nextDate) return null;
    curDate = nextDate;
    if (
      (!endingDate || endOfDay(endingDate) >= curDate) &&
      (!recurringOptions.endsAfter.occurrences ||
        cnt < recurringOptions.endsAfter.occurrences)
    ) {
      yield curDate;
    } else {
      return null;
    }
    cnt++;
  }
}

function generateNextDaysSince(
  date: Date,
  options: RecurringOptionsDays,
  startingDate: Date,
  lastCompleted: Date | null
): Date {
  let dateShifted = addDays(date, 1);
  if (dateShifted < startingDate) {
    dateShifted = startingDate;
  }
  dateShifted = startOfDay(dateShifted);
  let since = startingDate;
  if (options.afterLastCompleted) {
    const thisDateStartOfDay = startOfDay(date);
    if (lastCompleted) {
      const diffWithDateStart = differenceInDays(thisDateStartOfDay, lastCompleted);
      if (diffWithDateStart > options.days) {
        since = thisDateStartOfDay;
      } else {
        since = startOfDay(lastCompleted);
      }
    } else {
      if (thisDateStartOfDay < startingDate) {
        since = startingDate;
      } else {
        since = thisDateStartOfDay;
      }
    }
  }
  const diff = differenceInDays(dateShifted, since);
  const rate = Math.ceil(diff / options.days);
  return addDays(since, rate * options.days);
}

function generateNextWeeksSince(
  date: Date,
  options: RecurringOptionsWeeks,
  startingDate: Date
): Date | null {
  function getNextFutureWeekday(
    refDate: Date,
    weekDate: Date,
    weekdays: number[]
  ): Date | null {
    const ref = startOfDay(refDate);
    const adjustedDate = startOfDay(weekDate);

    for (const weekday of weekdays) {
      const day = setDay(adjustedDate, weekday);
      if (day >= ref) {
        return day;
      }
    }

    return null;
  }

  function getNextFutureDueWeek(dt: Date) {
    const diff = differenceInCalendarWeeks(dt, startingDate);
    const rate = Math.ceil(diff / options.weeks);
    return startOfDay(addWeeks(startingDate, rate * options.weeks));
  }

  let dateShifted = addDays(date, 1);
  if (dateShifted < startingDate) {
    dateShifted = startingDate;
  }
  dateShifted = startOfDay(dateShifted);
  const res = getNextFutureWeekday(
    dateShifted,
    getNextFutureDueWeek(dateShifted),
    options.weekdays
  );
  if (res) {
    return res;
  }

  // for case when this week does not have any remaining matching days, need to go over to the next week
  const nextWeek = getNextFutureDueWeek(addWeeks(dateShifted, 1));
  return getNextFutureWeekday(dateShifted, nextWeek, options.weekdays);
}

function generateNextMonthsSince(
  date: Date,
  options: RecurringOptionsMonths,
  startingDate: Date
): Date {
  let dateShifted = addDays(date, 1);
  if (dateShifted < startingDate) {
    dateShifted = startingDate;
  }
  dateShifted = startOfDay(dateShifted);
  function getNextDueMonth(dt: Date) {
    const diff = differenceInCalendarMonths(dt, startingDate);
    const rate = Math.ceil(diff / options.months);
    return startOfDay(addMonths(startingDate, rate * options.months));
  }

  if (options.weekday) {
    const res = checkNthWeekDay(
      getNextDueMonth(dateShifted),
      options.weekday.weekday,
      options.weekday.weekdayNumber
    );
    if (res >= dateShifted) {
      return res;
    }

    // for case when this week does not have any remaining matching days, need to go over to the next week
    const nextMonth = getNextDueMonth(addMonths(dateShifted, 1));
    return checkNthWeekDay(
      nextMonth,
      options.weekday.weekday,
      options.weekday.weekdayNumber
    );
  } else {
    const res = getNextDueMonth(dateShifted);
    if (res >= dateShifted) {
      return res;
    }
    return getNextDueMonth(addMonths(dateShifted, 1));
  }
}

function generateNextYearsSince(
  date: Date,
  options: RecurringOptionsYears,
  startingDate: Date,
  lastCompleted: Date | null
): Date {
  let dateShifted = addYears(date, 1);
  if (dateShifted < startingDate) {
    dateShifted = startingDate;
  }
  dateShifted = startOfDay(dateShifted);
  let since = startingDate;
  if (options.afterLastCompleted) {
    const thisDateStartOfDay = startOfDay(date);
    if (lastCompleted) {
      const diffWithDateStart = differenceInYears(thisDateStartOfDay, lastCompleted);
      if (diffWithDateStart > options.years) {
        since = thisDateStartOfDay;
      } else {
        since = startOfDay(lastCompleted);
      }
    } else {
      if (thisDateStartOfDay < startingDate) {
        since = startingDate;
      } else {
        since = thisDateStartOfDay;
      }
    }
  }
  const diff = differenceInYears(dateShifted, since);
  const rate = Math.ceil(diff / options.years);
  return addYears(since, rate * options.years);
}
