import React, { useCallback, useState } from 'react';
import Subscribe from './Subscribe';
import { EntitlementType } from '@todo/common';
import { IonButton, IonCol, IonRow, IonSpinner } from '@ionic/react';
import { cancelSubStripe } from '../lib/SubscriptionHelper';
import { AuthError } from '../lib/Errors';
import { useAuthUser } from '../helpers/AuthUserProvider';

interface Props {
  useStripe: boolean;
  entitlement: EntitlementType;
  onUpdate: () => void;
}

const SubscriptionInfoInner = ({ onUpdate, entitlement, useStripe }: Props) => {
  const { userInfo } = useAuthUser();
  const [processingUnSubscribe, setProcessingUnSubscribe] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const unSubscribe = useCallback(async () => {
    setErrorMsg(null);
    setProcessingUnSubscribe(true);
    if (!userInfo?.auth) return null;
    try {
      await cancelSubStripe(userInfo.auth);
      await onUpdate();
    } catch (e) {
      if (e instanceof AuthError) {
        window.location.reload();
        // reloadAuthentication(true);
      } else {
        setErrorMsg(e.message || 'Unknown error');
      }
    }
    setProcessingUnSubscribe(false);
  }, [onUpdate, userInfo]);

  return (
    <div>
      {entitlement.isActive && (
        <div>
          <div>Active subscription</div>
          {entitlement.willRenew && (
            <div>
              Will renew at {entitlement.expiresAt?.toLocaleDateString()}
              {useStripe && entitlement.store === 'stripe' && (
                <div>
                  <IonButton onClick={unSubscribe} disabled={processingUnSubscribe}>
                    {processingUnSubscribe && <IonSpinner />}
                    Cancel Subscription
                  </IonButton>
                </div>
              )}
              {!useStripe && entitlement.store === 'stripe' && (
                <div>To cancel subscription, open the app on desktop</div>
              )}
              {entitlement.store === 'app_store' && (
                <div>You can cancel subscription through iOS settings</div>
              )}
              {entitlement.store === 'play_store' && (
                <div>You can cancel subscription through Google Play Store</div>
              )}
            </div>
          )}
          {!entitlement.willRenew && entitlement.expiresAt && (
            <div>
              Subscription expires at {entitlement.expiresAt.toLocaleDateString()}
            </div>
          )}
        </div>
      )}
      {!entitlement.isActive && (
        <div>
          <div>No active subscription</div>
          {entitlement.expiresAt && (
            <div>Expired at {entitlement.expiresAt.toLocaleDateString()}</div>
          )}
          <Subscribe onUpdate={onUpdate} />
        </div>
      )}
      {errorMsg && (
        <IonRow>
          <IonCol size="12" style={{ color: 'red' }}>
            {errorMsg}
          </IonCol>
        </IonRow>
      )}
    </div>
  );
};

export default SubscriptionInfoInner;
