import React from 'react';
import { DataPoint } from '../../types/CoreTypes';
import { Bar } from 'react-chartjs-2';

interface Props {
  dataPoints: DataPoint[];
  overlayLine?: number;
  color: string;
}

const DailyHistogram = ({ dataPoints, overlayLine, color }: Props) => {
  const datasets: Record<any, any>[] = [
    {
      label: 'data',
      backgroundColor: color,
      data: dataPoints.map((d) => d.value),
      order: 2,
    },
  ];
  if (overlayLine) {
    datasets.push({
      label: 'overlay',
      borderColor: 'gray',
      backgroundColor: 'transparent',
      data: dataPoints.map(() => overlayLine),
      type: 'line',
      borderDash: [5, 4],
      borderDashOffset: 4,
      borderWidth: 1,
      pointHitRadius: 0,
      pointRadius: 0,
      order: 1,
    });
  }
  return (
    <Bar
      data={{
        labels: dataPoints.map((d) => d.label),
        datasets,
      }}
      legend={{ display: false }}
      width={150}
      height={80}
      options={{
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                min: 0,
                precision: 0,
                maxTicksLimit: 4,
              },
            },
          ],
        },
      }}
    />
  );
};

export default DailyHistogram;
