import React, { forwardRef } from 'react';
import ContextListRowDesktop from './ContextListRowDesktop';
import ContextListRowMobile from './ContextListRowMobile';
import { ContextType } from '../../types/CoreTypes';
import deepEqual from 'fast-deep-equal';
import { useClientType } from '../../helpers/ClientTypeProvider';

interface Props {
  context: ContextType;
  ref?: any;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  compact?: boolean;
  onDelete?: (id: string) => void;
  manualReorder?: (up: boolean) => Promise<boolean>;
}

// eslint-disable-next-line react/display-name
const ContextListRow: React.FunctionComponent<Props> = forwardRef<any, Props>(
  (props: Props, ref) => {
    const { isDesktop } = useClientType();
    if (isDesktop) {
      return (
        <ContextListRowDesktop
          manualReorder={props.manualReorder}
          context={props.context}
          ref={ref}
          isSelected={props.isSelected}
          onAfterNavigate={props.onAfterNavigate}
          onDelete={props.onDelete}
        />
      );
    }

    return (
      <ContextListRowMobile
        context={props.context}
        isSelected={props.isSelected}
        onAfterNavigate={props.onAfterNavigate}
        onDelete={props.onDelete}
      />
    );
  }
);

const Memoized = React.memo(ContextListRow, deepEqual);

export default Memoized;
