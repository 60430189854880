import React, { Component, CSSProperties, forwardRef } from 'react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import isSameDay from 'date-fns/isSameDay';
import Select, {
  components as SelectComponents,
  GroupProps,
  OptionProps,
  ValueType,
} from 'react-select';
import { OptionTypeWithColor } from '../../types/CoreTypes';
import { first } from 'lodash';
import {
  createCalendarOptions,
  createOptionForDate,
  DateOption,
  days,
  defaultOptions,
  getDateSuggestions,
} from '../../lib/DateHelper';
import { logDebug } from '../../lib/logger';

const daysHeaderStyles: CSSProperties = {
  marginTop: '5px',
  paddingTop: '5px',
  paddingLeft: '2%',
  borderTop: '1px solid #eee',
};
const daysHeaderItemStyles: CSSProperties = {
  color: '#999',
  cursor: 'default',
  // display: 'block',
  display: 'inline-block',
  fontSize: '75%',
  fontWeight: 500,
  width: '12%',
  margin: '0 1%',
  textAlign: 'center',
};
const daysContainerStyles = {
  paddingTop: '5px',
  paddingLeft: '2%',
};

// eslint-disable-next-line react/display-name
const Group = (focus: () => void) => (
  props: GroupProps<DateOption> & { innerProps?: any; theme?: any }
) => {
  const {
    Heading,
    getStyles,
    children,
    label,
    innerProps,
    headingProps,
    cx,
    theme,
    setValue,
  } = props;
  const month = label?.toString()
    ? parse(label?.toString(), 'MMMM yyyy', new Date())
    : new Date();
  const prevMonth = subMonths(month, 1);
  const nextMonth = addMonths(month, 1);
  const fmt = (val: Date) => {
    return format(val, 'MMMM yyyy');
  };
  const setVal = (val: Date) => () => {
    setValue({ label: fmt(prevMonth), value: val }, 'set-value');
    focus();
  };
  return (
    <div aria-label={label} style={getStyles('group', props)} {...innerProps}>
      <Heading
        className="date-picker-calendar-header"
        theme={theme}
        getStyles={getStyles}
        cx={cx}
        {...headingProps}
      >
        <a className="month-switch--left" onClick={setVal(prevMonth)}>
          {'<'} {fmt(prevMonth)}
        </a>
        <span className="month-switch--current">{label}</span>
        <a className="month-switch--right" onClick={setVal(nextMonth)}>
          {fmt(nextMonth)} {'>'}
        </a>
      </Heading>
      <div style={daysHeaderStyles}>
        {days.map((day, i) => (
          <span key={`${i}-${day}`} style={daysHeaderItemStyles}>
            {day}
          </span>
        ))}
      </div>
      <div style={daysContainerStyles}>{children}</div>
    </div>
  );
};

const getOptionStyles = (defaultStyles: CSSProperties): CSSProperties => ({
  ...defaultStyles,
  display: 'inline-block',
  width: '12%',
  margin: '0 1%',
  textAlign: 'center',
  borderRadius: '4px',
});

interface OptionPropz extends OptionProps<DateOption> {
  data: DateOption;
}

const Option = (props: OptionPropz) => {
  const { data, getStyles, innerRef, innerProps } = props;
  if (data.display === 'calendar' && data.value) {
    const defaultStyles = getStyles('option', props);
    const styles = getOptionStyles(defaultStyles);
    if (data.value.getDate() === 1) {
      const indentBy = data.value.getDay();
      if (indentBy) {
        styles.marginLeft = `${indentBy * 14 + 1}%`;
      }
    }
    if (isSameDay(data.value, new Date())) {
      styles.backgroundColor = 'red';
    }
    return (
      <span {...innerProps} style={styles} ref={innerRef}>
        {format(data.value, 'd')}
      </span>
    );
  } else return <SelectComponents.Option {...props} />;
};

interface BaseProps {
  placeholder: string;
  value?: string;
  onChange: (value?: string) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

interface State {
  options: DateOption[];
}

class DatePicker extends Component<Props, State> {
  private privateRef: React.RefObject<any> = React.createRef();

  state: State = {
    options: defaultOptions(),
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      if (prevProps.value === undefined) {
        setTimeout(() => {
          this.setState({
            options: defaultOptions(
              this.props.value ? new Date(this.props.value) : undefined
            ),
          });
        }, 100);
      } else {
        this.setState({
          options: defaultOptions(
            this.props.value ? new Date(this.props.value) : undefined
          ),
        });
      }
    }
  }

  handleInputChange = (value: string) => {
    if (!value) {
      this.setState({ options: defaultOptions() });
      return;
    }

    const dates = getDateSuggestions(value);

    if (dates) {
      const firstDate = first(dates);
      this.setState({
        options: [
          ...dates.map(createOptionForDate),
          createCalendarOptions(firstDate?.date),
        ],
      });
    } else {
      this.setState({
        options: [],
      });
    }
  };

  handleChange = (selectedOption: ValueType<DateOption>) => {
    logDebug([], `date picker value`, { selectedOption });
    if (!selectedOption) {
      setTimeout(() => {
        // @ts-ignore
        this.props.forwardRef?.current?.blur();
      }, 10);
    }
    if (Array.isArray(selectedOption)) {
      throw new Error('Unexpected type passed to ReactSelect onChange handler');
    }
    const value = (selectedOption as OptionTypeWithColor | undefined)?.value;
    this.props.onChange(value);
  };

  handleFocus = () => {
    const ref = this.props.forwardRef || this.privateRef;
    // @ts-ignore
    ref?.current?.blur();
    // @ts-ignore
    ref?.current?.focus();
  };

  render() {
    const { value, forwardRef } = this.props;
    const { options } = this.state;
    return (
      <Select
        openMenuOnFocus
        closeMenuOnSelect
        value={value ? createOptionForDate({ date: new Date(value) }) : undefined}
        ref={forwardRef || this.privateRef}
        components={{
          // @ts-ignore
          Group: Group(this.handleFocus),
          Option,
        }}
        filterOption={null}
        isMulti={false}
        isOptionSelected={(o, v) =>
          v.some((i: DateOption) => {
            if (!i.value) return false;
            if (!o.value) return false;
            return isSameDay(i.value, o.value);
          })
        }
        isClearable
        maxMenuHeight={600}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={options}
        styles={{
          menu: (provided) => {
            if (window.innerWidth > 768) return provided;
            return {
              width: '100vw',
              maxHeight: '74vh',
              overflow: 'scroll',
              left: 0,
              position: 'fixed',
              zIndex: 99999999,
              background: '#fff',
            };
          },
        }}
      />
    );
  }
}

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <DatePicker forwardRef={ref} {...props} />
));
