import { ActionFilter, ActionModel } from '../types/CoreTypes';

export default class ActionRepo {
  private actions: ActionModel[] = [
    {
      name: 'open focus',
      execute: ({ navigate }) => {
        navigate('/focus');
      },
    },
    {
      name: 'open next',
      execute: ({ navigate }) => {
        navigate('/next');
      },
    },
    {
      name: 'open waiting',
      execute: ({ navigate }) => {
        navigate('/waiting');
      },
    },
    {
      name: 'open some time',
      execute: ({ navigate }) => {
        navigate('/sometime');
      },
    },
    {
      name: 'open inbox',
      execute: ({ navigate }) => {
        navigate('/inbox');
      },
    },
    {
      name: 'open projects',
      execute: ({ navigate }) => {
        navigate('/projects');
      },
    },
    {
      name: 'open tags',
      execute: ({ navigate }) => {
        navigate('/tags');
      },
    },
    {
      name: 'open agenda',
      execute: ({ navigate }) => {
        navigate('/agenda');
      },
    },
    {
      name: 'open archive',
      execute: ({ navigate }) => {
        navigate('/archive');
      },
    },
    {
      name: 'open contexts',
      execute: ({ navigate }) => {
        navigate('/contexts');
      },
    },
    {
      name: 'open config / settings',
      execute: ({ navigate }) => {
        navigate('/settings');
      },
    },
  ];

  async getAllForFilter(filter: ActionFilter): Promise<ActionModel[]> {
    return this.actions.filter(this.getFilterConditionFn(filter));
  }

  private getFilterConditionFn(filter: ActionFilter) {
    return (action: ActionModel) => {
      let isMatch = true;
      if (filter.anyText)
        isMatch =
          isMatch &&
          action.name.toLowerCase().includes(filter.anyText.toLowerCase());
      return isMatch;
    };
  }
}
