import React, { createContext, useContext } from 'react';
import { StorageDeps } from './InitStorage';

// @ts-ignore
export const StorageContext = createContext<StorageDeps>(undefined);

interface Props {
  storageDeps: StorageDeps;
  children: any;
}

export const StorageProvider = ({ storageDeps, children }: Props) => (
  <StorageContext.Provider value={storageDeps}>{children}</StorageContext.Provider>
);

export const useStorage: () => StorageDeps = () => useContext(StorageContext);

export const withStorage = <P extends Partial<StorageDeps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof StorageDeps>> => {
  return function withStorageContext(props) {
    return (
      <StorageContext.Consumer>
        {(storageProps: StorageDeps) => (
          <Component {...(props as P)} {...storageProps} />
        )}
      </StorageContext.Consumer>
    );
  };
};
