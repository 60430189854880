import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Routes } from '../lib/routes';
import { AUTH_TIMEOUT_SEC } from '../lib/Constants';
import { useAuth0 } from '../Auth0ReactFork';
import { getEnvVarOrDie } from '../lib/EnvManager';

const APP_HANDLE = getEnvVarOrDie('REACT_APP_APP_HANDLE');

const SignUpAfterPurchaseButton = () => {
  const { loginWithPopup } = useAuth0();

  return (
    <div>
      <p>
        To enable data synchronization and to access the content from any of your
        devices, please register.
      </p>
      <IonButton
        color="primary"
        onClick={async () => {
          await loginWithPopup(
            {
              screen_hint: 'signup',
              redirectUri: Capacitor.isNative
                ? `${APP_HANDLE}://${Routes.settings}`
                : `${window.location.origin}/${Routes.settings}`,
            },
            { timeoutInSeconds: AUTH_TIMEOUT_SEC }
          );
          // reloadAuthentication(true);
        }}
      >
        Register
        <IonIcon slot="end" icon={arrowForward} />
      </IonButton>
    </div>
  );
};

export default SignUpAfterPurchaseButton;
