import { IonActionSheet } from '@ionic/react';
import React, { useCallback } from 'react';
import {
  CMD_OR_CTRL,
  CTRL_ALT,
  HotKeyHandler,
  Key,
  Nullable,
  PriorityValues,
  ProjectEnrichedWithTaskStats,
  ProjectStatus,
  ProjectType,
  TaskType,
} from '../../types/CoreTypes';
import { ProjectHandlers } from '../../types/ProjectHandlers';
import { getProjectStatusLabel } from '../../helpers/CopyHelper';
import EditNameModal from '../propertyEditModals/EditNameModal';
import { PROJECT_NAME_MAX_LENGTH } from '../../lib/Constants';
import EditDateModal from '../propertyEditModals/EditDateModal';
import EditPriorityModal from '../propertyEditModals/EditPriorityModal';
import { useStorage } from '../../helpers/StorageProviders';
import { logDebug } from '../../lib/logger';
import EditProjectModal from '../propertyEditModals/EditProjectModal';
import EditNumberModal from '../propertyEditModals/EditNumberModal';

interface Props {
  handleChangeProject: (
    projectData: Partial<ProjectType>,
    projectId: string
  ) => void;
  children: (api: ProjectHandlers) => React.ReactNode;
}

enum ChangeStatus {
  state = 'state',
  name = 'name',
  priority = 'priority',
  dueDate = 'dueDate',
  startDate = 'startDate',
  dependsOn = 'dependsOn',
  duration = 'duration',
}

interface State {
  selectedProject?: ProjectEnrichedWithTaskStats;
  changeStatus?: ChangeStatus;
}

class ProjectActionsWrapperInternal extends React.PureComponent<Props, State> {
  state: State = {};

  setSelectedProject = (selectedProject?: ProjectEnrichedWithTaskStats) => {
    this.setState({ selectedProject });
  };
  setChangeStatus = (changeStatus?: ChangeStatus) => {
    this.setState({ changeStatus });
  };

  handleChangeProject = (projectData: Partial<ProjectType>, projectId: string) =>
    this.props.handleChangeProject(projectData, projectId);

  private readonly modalRefs: Map<ChangeStatus, React.RefObject<any>> = new Map();

  constructor(props: Props) {
    super(props);

    Object.keys(ChangeStatus).forEach((k) => {
      this.modalRefs.set((k as unknown) as ChangeStatus, React.createRef());
    });
  }

  getHandlers = (project: ProjectEnrichedWithTaskStats): HotKeyHandler[] => {
    return [
      {
        name: 'change project name',
        combination: {
          key: Key.E,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.changeNameHandler(project),
      },
      {
        name: 'set project state',
        combination: {
          key: Key.Y,
          modifiers: CMD_OR_CTRL,
        },
        action: () => this.startStateChangeHandler(project),
      },
      {
        name: 'set project status: archive',
        combination: {
          key: Key._0,
          modifiers: CTRL_ALT,
        },
        action: () => this.changeStateHandler(ProjectStatus.STATUS_ARCHIVE, project),
      },
      {
        name: 'set project status: progress',
        combination: {
          key: Key._9,
          modifiers: CTRL_ALT,
        },
        action: () => {
          this.changeStateHandler(ProjectStatus.STATUS_ACTION, project);
        },
      },
      {
        name: 'set project status: next',
        combination: {
          key: Key._8,
          modifiers: CTRL_ALT,
        },
        action: () => {
          this.changeStateHandler(ProjectStatus.STATUS_NEXT, project);
        },
      },
      {
        name: 'set project status: waiting',
        combination: {
          key: Key._7,
          modifiers: CTRL_ALT,
        },
        action: () => this.changeStateHandler(ProjectStatus.STATUS_WAITING, project),
      },
      {
        name: 'set project status: some time',
        combination: {
          key: Key._6,
          modifiers: CTRL_ALT,
        },
        action: () => {
          this.changeStateHandler(ProjectStatus.STATUS_SOME_TIME, project);
        },
      },
    ];
  };

  handleChangeState = (status: ProjectStatus) => {
    const { selectedProject } = this.state;
    if (!selectedProject) return;
    this.handleChangeProject({ status }, selectedProject.id);
  };

  changeStateHandler = (status: ProjectStatus, project: ProjectType) => {
    this.handleChangeProject({ status }, project.id);
  };

  handleChangeDue = (date: string | undefined) => {
    const { selectedProject } = this.state;
    if (!selectedProject) return;
    if (date) {
      this.handleChangeProject({ dateDue: date }, selectedProject.id);
      return;
    }
    this.handleChangeProject({ dateDue: undefined }, selectedProject.id);
  };

  handleChangeStartDate = (date: string | undefined) => {
    const { selectedProject } = this.state;
    if (!selectedProject) return;
    if (date) {
      this.handleChangeProject({ dateStart: date }, selectedProject.id);
      return;
    }
    this.handleChangeProject({ dateStart: undefined }, selectedProject.id);
  };

  handleChangeDependsOn = (projectId: string | undefined) => {
    const { selectedProject } = this.state;
    if (!selectedProject) return;
    // cannot depend on itself
    if (projectId === selectedProject.id) return;
    if (projectId) {
      this.handleChangeProject(
        { dependsOnProjectId: projectId },
        selectedProject.id
      );
      return;
    }
    this.handleChangeProject({ dependsOnProjectId: undefined }, selectedProject.id);
  };

  handleChangePriority = (priorityValues: Nullable<PriorityValues>) => {
    const { selectedProject } = this.state;
    if (!selectedProject) return;
    this.handleChangeProject({ priorityValues }, selectedProject.id);
  };

  handleChangeName = (name: string) => {
    const { selectedProject } = this.state;
    if (!selectedProject) return;
    this.handleChangeProject({ name }, selectedProject.id);
  };

  handleChangeDuration = (duration: number | null) => {
    const { selectedProject } = this.state;
    if (!selectedProject) return;
    this.handleChangeProject(
      { duration: duration || undefined },
      selectedProject.id
    );
  };

  startStateChangeHandler = (project: ProjectEnrichedWithTaskStats) => {
    this.setSelectedProject(project);
    this.setChangeStatus(ChangeStatus.state);
  };
  changeDueHandler = (project: ProjectEnrichedWithTaskStats) => {
    this.show(project, ChangeStatus.dueDate).then(() => {
      this.focusRef(ChangeStatus.dueDate);
    });
  };
  changeStartHandler = (project: ProjectEnrichedWithTaskStats) => {
    this.show(project, ChangeStatus.startDate).then(() => {
      this.focusRef(ChangeStatus.startDate);
    });
  };
  changeDependsOnHandler = (project: ProjectEnrichedWithTaskStats) => {
    this.show(project, ChangeStatus.dependsOn).then(() => {
      this.focusRef(ChangeStatus.dependsOn);
    });
  };
  changeDurationHandler = (project: ProjectEnrichedWithTaskStats) => {
    this.show(project, ChangeStatus.duration).then(() => {
      this.focusRef(ChangeStatus.duration);
    });
  };
  changeNameHandler = (project: ProjectEnrichedWithTaskStats) => {
    this.show(project, ChangeStatus.name).then(() => {
      this.focusRef(ChangeStatus.name);
    });
  };
  changePriorityHandler = (project: ProjectEnrichedWithTaskStats) => {
    this.show(project, ChangeStatus.priority);
  };

  basicCloseHandler = () => {
    this.setSelectedProject(undefined);
    this.setChangeStatus(undefined);
  };

  show = (project: ProjectEnrichedWithTaskStats, status: ChangeStatus) => {
    return new Promise((resolve) => {
      this.setSelectedProject(project);
      this.setChangeStatus(status);
      setTimeout(resolve, 300);
    });
  };

  getRef = (status: ChangeStatus) => this.modalRefs.get(status);
  focusRef = (status: ChangeStatus) => {
    // console.log('$$$', this.modalRefs);
    // debugger;
    const current = this.modalRefs.get(status)?.current;
    if (current) {
      logDebug([], `focus: ${status}`, current);
      if (current.setFocus) {
        current.setFocus();
      } else if (current.focus) {
        current.focus();
      } else {
        throw Error('Cannot focus: dont know how to focus!');
      }
    } else {
      throw Error(`Cannot focus ${status}: no current ref!`);
    }
  };

  isChangeStatus = (desiredStatus: ChangeStatus) => {
    const { selectedProject } = this.state;
    if (!selectedProject) return false;
    return this.state.changeStatus === desiredStatus;
  };

  getValueForModal = <K extends keyof TaskType, A extends TaskType[K]>(
    desiredStatus: ChangeStatus,
    getter: (t: ProjectEnrichedWithTaskStats) => A
  ): A | undefined => {
    const { selectedProject } = this.state;
    if (!this.isChangeStatus(desiredStatus)) return undefined;
    if (!selectedProject) return undefined;
    return getter(selectedProject);
  };

  render() {
    const { children } = this.props;
    return (
      <>
        {children({
          onStartStateChange: this.startStateChangeHandler,
          onChangeDue: this.changeDueHandler,
          onChangeStart: this.changeStartHandler,
          onChangeDependsOn: this.changeDependsOnHandler,
          onChangeDuration: this.changeDurationHandler,
          onChangeState: this.changeStateHandler,
          onChangePriority: this.changePriorityHandler,
          onChangeName: this.changeNameHandler,
          getHandlers: this.getHandlers,
        })}

        <EditPriorityModal
          show={this.isChangeStatus(ChangeStatus.priority)}
          priority={this.getValueForModal(
            ChangeStatus.priority,
            (t) => t.priorityValues
          )}
          onSave={this.handleChangePriority}
          onClose={this.basicCloseHandler}
        />

        {/* DUE */}
        <EditDateModal
          ref={this.getRef(ChangeStatus.dueDate)}
          show={this.isChangeStatus(ChangeStatus.dueDate)}
          date={this.getValueForModal(ChangeStatus.dueDate, (t) => t.dateDue)}
          onSave={(date) => this.handleChangeDue(date)}
          onClose={this.basicCloseHandler}
          title="Due date"
        />

        {/* START */}
        <EditDateModal
          ref={this.getRef(ChangeStatus.startDate)}
          show={this.isChangeStatus(ChangeStatus.startDate)}
          date={this.getValueForModal(ChangeStatus.startDate, (t) => t.dateStart)}
          onSave={(date) => this.handleChangeStartDate(date)}
          onClose={this.basicCloseHandler}
          title="Start date"
        />

        <EditProjectModal
          ref={this.getRef(ChangeStatus.dependsOn)}
          show={this.isChangeStatus(ChangeStatus.dependsOn)}
          projectId={this.getValueForModal(
            ChangeStatus.dependsOn,
            (t) => t.dependsOnProjectId
          )}
          onSave={this.handleChangeDependsOn}
          onClose={this.basicCloseHandler}
        />

        {/* STATE */}
        <IonActionSheet
          isOpen={this.isChangeStatus(ChangeStatus.state)}
          onDidDismiss={this.basicCloseHandler}
          header="Select new state"
          buttons={[
            // {
            //   text: 'Due today',
            //   handler: () => {
            //     this.handleChangeDue(formatDateWithoutTime(new Date()));
            //   },
            // },
            {
              text: getProjectStatusLabel(ProjectStatus.STATUS_ACTION),
              handler: () => {
                this.handleChangeState(ProjectStatus.STATUS_ACTION);
              },
            },
            {
              text: getProjectStatusLabel(ProjectStatus.STATUS_NEXT),
              handler: () => {
                this.handleChangeState(ProjectStatus.STATUS_NEXT);
              },
            },
            {
              text: getProjectStatusLabel(ProjectStatus.STATUS_WAITING),
              handler: () => {
                this.handleChangeState(ProjectStatus.STATUS_WAITING);
              },
            },
            {
              text: getProjectStatusLabel(ProjectStatus.STATUS_SOME_TIME),
              handler: () => {
                this.handleChangeState(ProjectStatus.STATUS_SOME_TIME);
              },
            },
            {
              text: 'Cancel',
              icon: 'close',
              role: 'cancel',
            },
          ]}
        />

        <EditNumberModal
          ref={this.getRef(ChangeStatus.duration)}
          show={this.isChangeStatus(ChangeStatus.duration)}
          value={
            this.getValueForModal(ChangeStatus.duration, (t) => t.duration) || null
          }
          onSave={this.handleChangeDuration}
          onClose={this.basicCloseHandler}
          placeholder="estimated duration, days"
          title="Estimated duration, days"
        />

        <EditNameModal
          ref={this.getRef(ChangeStatus.name)}
          show={this.isChangeStatus(ChangeStatus.name)}
          name={this.getValueForModal(ChangeStatus.name, (t) => t.name) || ''}
          onSave={this.handleChangeName}
          onClose={this.basicCloseHandler}
          placeholder="name"
          maxLength={PROJECT_NAME_MAX_LENGTH}
        />
      </>
    );
  }
}

interface WrapperProps {
  children: (api: ProjectHandlers) => React.ReactNode;
}

const ProjectActionsWrapper = ({ children }: WrapperProps) => {
  const {
    repos: { projectRepo },
  } = useStorage();

  const updateProject = useCallback(
    (projectData: Partial<ProjectType>, id: string) => {
      return projectRepo.edit({
        ...projectData,
        id: id,
      });
    },
    [projectRepo]
  );

  return (
    <ProjectActionsWrapperInternal handleChangeProject={updateProject}>
      {children}
    </ProjectActionsWrapperInternal>
  );
};

export default ProjectActionsWrapper;
