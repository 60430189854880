import React from 'react';
import { colors } from '../../helpers/styles';
import { format } from 'date-fns';
import { makeDateWithoutTime } from '../../helpers/RecurringHelper';
import { AchievementWithMetadata } from '../../types/CoreTypes';
import { IonBadge } from '@ionic/react';
import { useDate } from '../../helpers/DateProvider';

interface Props {
  achievements?: AchievementWithMetadata[];
  dateString: string;
  onClick: (date: string) => void;
  isSelected: boolean;
}

const AchievementCalendarCell = ({
  achievements,
  dateString,
  onClick,
  isSelected,
}: Props) => {
  const usedDate = useDate();
  const currentMonth = format(usedDate.date, 'MMM');

  const date = makeDateWithoutTime(dateString);

  const dayColor = currentMonth === format(date, 'MMM') ? '#000' : '#bbb';

  return (
    <div
      onClick={() => onClick(dateString)}
      style={{
        cursor: 'pointer',
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '13px',
        textAlign: 'center',
        width: '35px',
        height: '35px',
        borderRadius: '100%',
        background: achievements?.length ? colors.primary : 'none',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: isSelected
          ? colors.danger
          : achievements?.length
          ? colors.primary
          : dayColor,
        justifyContent: 'center',
        padding: '3px',
        color: isSelected
          ? achievements?.length
            ? '#fff'
            : colors.danger
          : dayColor,
      }}
    >
      {achievements?.length && (
        <div>
          <IonBadge
            style={{
              float: 'right',
              marginBottom: '-100%',
              marginTop: '-14px',
              marginRight: '-12px',
            }}
            color="danger"
          >
            {achievements?.reduce((acc, a) => acc + a.counter, 0)}
          </IonBadge>
        </div>
      )}
      <div>{format(date, 'd')}</div>
    </div>
  );
};

export default AchievementCalendarCell;
