import React, { useRef, useState } from 'react';
import { ItemType } from '@todo/common';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import { getItemTypeLabel } from '../../helpers/CopyHelper';
import { genericStyles } from '../../helpers/styles';
import { IonChip, IonLabel } from '@ionic/react';
import EditContextModal from '../propertyEditModals/EditContextModal';
import { logDebug } from '../../lib/logger';

interface Props {
  kind: ItemType;
  onClose: () => void;
}

const QuickAddContextSwitcher = ({ kind, onClose }: Props) => {
  const { context, setContext } = useWorkspace();
  const nodeRef = useRef<any>(null);
  const [showContextModal, setShowContextModal] = useState(false);

  // const {
  //   repos: { contextRepo },
  // } = useStorage();
  //
  // const [contextName, setContextName] = useState<string | null>();
  //
  // useEffect(() => {
  //   contextRepo.getById(contextId).then((c) => {
  //     if (c) {
  //       setContextName(c.name);
  //     }
  //   });
  // }, [contextId, contextRepo]);

  return (
    <div>
      Adding new {getItemTypeLabel(kind)} to
      <IonChip
        onClick={() => {
          setShowContextModal(true);
          setTimeout(() => {
            const current = nodeRef?.current;
            if (current) {
              logDebug([], `focus context`, current);
              if (current.setFocus) {
                current.setFocus();
              } else if (current.focus) {
                current.focus();
              } else {
                throw Error('Cannot focus: dont know how to focus!');
              }
            } else {
              throw Error(`Cannot focus context: no current ref!`);
            }
          }, 250);
        }}
        style={{
          ...genericStyles.chip,
          ...genericStyles.iconLabel,
          ...genericStyles.propertyClickable,
        }}
        color="primary"
      >
        <IonLabel>{context.name}</IonLabel>
      </IonChip>
      <EditContextModal
        ref={nodeRef}
        show={showContextModal}
        contextId={context.id}
        onSave={(c) => {
          setContext(c);
          setShowContextModal(false);
          setTimeout(onClose, 250);
        }}
        onClose={() => {
          setShowContextModal(false);
          setTimeout(onClose, 250);
        }}
      />
    </div>
  );
};

export default QuickAddContextSwitcher;
