import React from 'react';
import { ContextType } from '../../types/CoreTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import ContextListElementMobile from './ContextListElementMobile';
import {
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from '@ionic/react';
import { create, trash } from 'ionicons/icons';
import deepEqual from 'fast-deep-equal';

interface Props extends RouteComponentProps {
  context: ContextType;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  onDelete?: (id: string) => void;
}

class ContextListRowMobile extends React.Component<Props> {
  private slidingRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.slidingRef = React.createRef();
  }

  closeOpenedSlider() {
    this.slidingRef.current && this.slidingRef.current.closeOpened();
  }

  render() {
    const { context, isSelected, onAfterNavigate, onDelete } = this.props;

    return (
      <IonItemSliding ref={this.slidingRef}>
        <ContextListElementMobile
          context={context}
          isSelected={isSelected}
          onAfterNavigate={onAfterNavigate}
        />

        <IonItemOptions side="end">
          <IonItemOption
            routerLink={`/contexts/edit/${context.id}`}
            routerDirection={'root'}
            color="warning"
          >
            <IonIcon style={{ fontSize: '25px' }} slot="start" icon={create} />
          </IonItemOption>
          {onDelete && (
            <IonItemOption
              color="danger"
              onClick={() => {
                this.closeOpenedSlider();
                onDelete(context.id);
              }}
            >
              <IonIcon style={{ fontSize: '25px' }} slot="start" icon={trash} />
            </IonItemOption>
          )}
        </IonItemOptions>
      </IonItemSliding>
    );
  }
}

const Memoized = React.memo(ContextListRowMobile, deepEqual);

export default withRouter(Memoized);
