import React, { forwardRef } from 'react';
import {
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
} from '../../types/CoreTypes';
import { TaskHandlers } from '../../types/TaskHandlers';
import TaskListRowDesktop from './TaskListRowDesktop';
import TaskListRowMobile from './TaskListRowMobile';
import deepEqual from 'fast-deep-equal';
import { useClientType } from '../../helpers/ClientTypeProvider';

interface Props {
  task: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;
  taskHandlers: TaskHandlers;
  ref?: any;
  isSelected?: boolean;
  manualReorder?: (up: boolean) => Promise<boolean>;
  onAfterNavigate?: () => void;
  compact?: boolean;
  hideTags?: boolean;
  hideProject?: boolean;
  hideDate?: boolean;
}

// eslint-disable-next-line react/display-name
const TaskListRow: React.FunctionComponent<Props> = forwardRef<any, Props>(
  (props: Props, ref) => {
    const { isDesktop } = useClientType();
    if (isDesktop) {
      return (
        <TaskListRowDesktop
          manualReorder={props.manualReorder}
          task={props.task}
          taskHandlers={props.taskHandlers}
          ref={ref}
          isSelected={props.isSelected}
          onAfterNavigate={props.onAfterNavigate}
          compact={props.compact}
          hideTags={props.hideTags}
          hideProject={props.hideProject}
          hideDate={props.hideDate}
        />
      );
    }

    return (
      <TaskListRowMobile
        task={props.task}
        taskHandlers={props.taskHandlers}
        isSelected={props.isSelected}
        onAfterNavigate={props.onAfterNavigate}
        hideTags={props.hideTags}
        hideProject={props.hideProject}
        hideDate={props.hideDate}
      />
    );
  }
);

const Memoized = React.memo(TaskListRow, deepEqual);

export default Memoized;
