import React from 'react';
import { time } from 'ionicons/icons';
import DateInfo from '../Common/DateInfo';
import { makeDateWithoutTime } from '../../helpers/RecurringHelper';

interface Props {
  dateStart: string | undefined;
  currentDate: Date;
  onClick?: any;
}

const ProjectDateStartInfo = ({ dateStart, onClick, currentDate }: Props) => {
  return (
    <DateInfo
      currentDate={currentDate}
      onClick={onClick}
      date={dateStart ? makeDateWithoutTime(dateStart) : undefined}
      icon={time}
    />
  );
};

export default ProjectDateStartInfo;
