import React, { forwardRef, useCallback } from 'react';

import { ValueType } from 'react-select';
import { first } from 'lodash';
import { OptionTypeWithColor } from '../../types/CoreTypes';
import { formatOptionLabel, MySelect, selectStyles } from './selectHelper';

interface BaseProps {
  placeholder: string;
  value?: string;
  options: OptionTypeWithColor[];
  onChange: (value?: string) => void;
  onCreate?: (value: string) => void;
  isClearable?: boolean;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

function SingleSelect({
  onChange,
  onCreate,
  options,
  placeholder,
  value,
  forwardRef,
  isClearable,
}: Props) {
  const itIsClearable = isClearable !== undefined ? isClearable : true;
  const handleChange = useCallback(
    (selectedOption: ValueType<OptionTypeWithColor>) => {
      if (!selectedOption) {
        setTimeout(() => {
          // @ts-ignore
          forwardRef?.current?.blur();
        }, 10);
      }
      if (Array.isArray(selectedOption)) {
        throw new Error('Unexpected type passed to ReactSelect onChange handler');
      }
      const value = (selectedOption as OptionTypeWithColor | undefined)?.value;
      onChange(value);
    },
    [forwardRef, onChange]
  );

  return (
    <MySelect
      forwardRef={forwardRef}
      // @ts-ignore
      className="basic-single"
      classNamePrefix="select"
      value={first(options.filter((p) => p.value === value))}
      placeholder={placeholder}
      isClearable={itIsClearable}
      isSearchable
      name="color"
      options={options}
      onChange={handleChange}
      // @ts-ignore
      formatOptionLabel={formatOptionLabel}
      // @ts-ignore
      styles={selectStyles}
      onCreateOption={onCreate}
      isCreatable={!!onCreate}
      openMenuOnFocus
      closeMenuOnSelect
    />
  );
}

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <SingleSelect forwardRef={ref} {...props} />
));
