import { TaskStatus, TaskType } from '@todo/common';
import { differenceInCalendarDays } from 'date-fns';

const OLD_TASK_THRESHOLD_DAYS = 22;
const ANCIENT_TASK_THRESHOLD_DAYS = 120;
const OLD_NEXT_STATUS_THRESHOLD_DAYS = 8;

export enum TaskAge {
  normal = 'normal',
  old = 'old',
  ancient = 'ancient',
}

export function taskTooLongInStatus(task: TaskType, date: Date): boolean {
  if (!task.statusUpdatedAt) return false;
  if (
    ![
      TaskStatus.STATUS_IN_PROGRESS,
      TaskStatus.STATUS_INBOX,
      TaskStatus.STATUS_ACTION_LIST,
    ].includes(task.status)
  )
    return false;
  if (task.projectId && task.status === TaskStatus.STATUS_ACTION_LIST) return false;
  const statusOldness = differenceInCalendarDays(
    date.getTime(),
    task.statusUpdatedAt
  );
  return statusOldness > OLD_NEXT_STATUS_THRESHOLD_DAYS;
}

export function getTaskAge(task: TaskType, date: Date): TaskAge {
  const oldness = differenceInCalendarDays(date.getTime(), task.createdAt);
  if (oldness > ANCIENT_TASK_THRESHOLD_DAYS) {
    return TaskAge.ancient;
  }
  if (oldness > OLD_TASK_THRESHOLD_DAYS) {
    return TaskAge.old;
  }
  return TaskAge.normal;
}
