import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonReorder,
  IonRow,
} from '@ionic/react';
import React from 'react';
import {
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
} from '../../types/CoreTypes';
import { reorderFourOutline } from 'ionicons/icons';
import TaskStatusInfo from './TaskStatusInfo';
import { TableCell } from '../Common/TableCell';
import ProjectLink from '../Common/ProjectLink';
import TaskDetailsIcon from './TaskDetailsIcon';
import PriorityInfo from '../Common/PriorityInfo';
import TimeInfo from '../Common/TimeInfo';
import EnergyInfo from '../Common/EnergyInfo';
import TagsWidget from '../Common/TagsWidget';
import TaskWrapperDateInfo from './TaskWrapperDateInfo';
import TaskProductivityIcons from './TaskProductivityIcons';
import { useDate } from '../../helpers/DateProvider';

interface Props {
  task: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  hideTags?: boolean;
  hideProject?: boolean;
  hideDate?: boolean;
}

// eslint-disable-next-line react/display-name
const TaskListElementMobile: React.FunctionComponent<Props> = ({
  task,
  hideTags,
  hideProject,
  hideDate,
  onAfterNavigate,
}: Props) => {
  const { date } = useDate();
  const url = `/tasks/details/${task.id}`;

  return (
    <IonItem
      // color={isSelected ? 'light' : undefined}
      routerLink={url}
      routerDirection={'root'}
      onClick={onAfterNavigate}
      detail={false}
    >
      <IonReorder slot="start" style={{ marginRight: 10 }}>
        <IonIcon style={{ fontSize: 25 }} color="medium" icon={reorderFourOutline} />
      </IonReorder>

      {/*<IonIcon*/}
      {/*  style={{ ...genericStyles.icon, marginLeft: 10 }}*/}
      {/*  slot="end"*/}
      {/*  color="warning"*/}
      {/*  icon={task.status === TaskStatus.STATUS_IN_PROGRESS ? star : starOutline}*/}
      {/*/>*/}
      <IonLabel
        className={
          'list-mobile task-list-row table-list-row' +
          ' ' +
          (hideDate ? 'hide-date' : 'no-hide-date') +
          ' ' +
          (hideTags ? 'hide-tags' : 'no-hide-tags') +
          ' ' +
          (hideProject ? 'hide-project' : 'no-hide-project')
        }
      >
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="6" className="list-row">
              <TableCell className="table-cell-name">{task.name}</TableCell>
            </IonCol>
            {!hideProject && (
              <IonCol sizeXs="12" sizeSm="6" className="list-row">
                <TableCell className="table-cell-project">
                  <ProjectLink disableLink project={task.project} />
                </TableCell>
              </IonCol>
            )}
            {!hideTags && (
              <IonCol sizeXs="12" sizeSm="6" className="list-row">
                <TableCell className="table-cell-tags">
                  <TagsWidget disableLink tags={task.tagsData} compact />
                </TableCell>
              </IonCol>
            )}
          </IonRow>

          <IonRow>
            <IonCol sizeXs="12" sizeSm="6" className="list-row row-justify">
              <TableCell className="table-cell-status">
                <TaskStatusInfo status={task.status} />
              </TableCell>
              {!hideDate && (
                <TaskWrapperDateInfo
                  currentDate={date}
                  Wrapper={({ children, colspan }) => (
                    <TableCell className={`table-cell-dates colspan-${colspan}`}>
                      {children}
                    </TableCell>
                  )}
                  task={task}
                />
              )}
            </IonCol>
            <IonCol sizeXs="12" sizeSm="6" className="list-row row-justify">
              <TableCell className="table-cell-details-icons">
                <div style={{ display: 'flex' }}>
                  <TaskDetailsIcon task={task} />
                  <TaskProductivityIcons date={date} task={task} />
                </div>
              </TableCell>

              <TableCell className="table-cell-large-icon" title="Priority">
                <PriorityInfo priority={task.priorityValues} />
              </TableCell>

              <TableCell className="table-cell-large-icon" title="Time">
                <TimeInfo time={task.time} />
              </TableCell>

              <TableCell className="table-cell-large-icon" title="Energy">
                <EnergyInfo energy={task.energy} />
              </TableCell>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );
};

export default TaskListElementMobile;
