import React, { useCallback } from 'react';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';

interface Props {
  show: boolean;
  text?: string;
  children?: React.ReactNode;
  title?: string;
  onClose: () => void;
  onAccept: () => void;
}

const AreYouSureModal = ({
  onClose,
  onAccept,
  text,
  show,
  title,
  children,
}: Props) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAccept = useCallback(() => {
    onAccept();
  }, [onAccept]);

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleAccept}
      show={show}
      title={title || 'Are you sure?'}
      saveText="Accept"
    >
      {children || text}
    </PropertyEditModalWrapper>
  );
};

export default AreYouSureModal;
