import React, { useEffect, useState } from 'react';
import { AchievementWithMetadata } from '../../types/CoreTypes';
import { addDays } from 'date-fns';
import {
  formatDateWithoutTime,
  makeDateWithoutTime,
} from '../../helpers/RecurringHelper';
import AchievementCalendarCell from './AchievementCalendarCell';

interface Props {
  achievements: {
    [key: string]: AchievementWithMetadata[];
  };
  startingDate: Date;
  selectedDate: string;
  onSelectedChange: (date: string) => void;
  daysToRender: number;
}

const AchievementsCalendar = ({
  achievements,
  startingDate,
  selectedDate,
  onSelectedChange,
  daysToRender,
}: Props) => {
  const [dates, setDates] = useState<string[] | null>(null);

  useEffect(() => {
    const dts = [];
    for (let i = 0; i < daysToRender; i++) {
      dts.push(formatDateWithoutTime(addDays(startingDate, i)));
    }
    setDates(dts);
  }, [daysToRender, startingDate]);

  // loading
  if (!dates) return null;

  const firstWeekDay = makeDateWithoutTime(dates[0]).getDay();
  const padding = Array.from(Array(firstWeekDay).keys());

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '3px',
        flexWrap: 'wrap',
      }}
    >
      {padding.map((k) => (
        <div
          style={{
            display: 'flex',
            width: '35px',
            height: '35px',
            margin: '5px',
          }}
          key={`padding-${k}`}
        />
      ))}
      {dates.map((d) => (
        <AchievementCalendarCell
          isSelected={d === selectedDate}
          onClick={onSelectedChange}
          dateString={d}
          achievements={achievements[d]}
          key={d}
        />
      ))}
    </div>
  );
};

export default AchievementsCalendar;
