import React from 'react';
import { AchievementKind } from '@todo/common';
import { IonBadge, IonIcon, IonLabel } from '@ionic/react';
import {
  alarmOutline,
  arrowRedoOutline,
  checkmarkCircleOutline,
  chevronForwardCircleOutline,
  flame,
  hourglassOutline,
  mailOpenOutline,
  planetOutline,
  starOutline,
  sunnyOutline,
} from 'ionicons/icons';
import { colors } from '../../helpers/styles';
import { AchievementWithStreak } from '../../types/CoreTypes';
import Tooltip from 'rc-tooltip';

interface Props {
  achievement: AchievementWithStreak;
}

function achievementToIcon(
  kind: AchievementKind
): { icon: string; color: string; label: string } {
  switch (kind) {
    case AchievementKind.clearedFocus:
      return { icon: starOutline, color: colors.warning, label: 'cleared focus' };
    case AchievementKind.clearedInbox:
      return {
        icon: mailOpenOutline,
        color: colors.primary,
        label: 'cleared inbox',
      };
    case AchievementKind.clearedNext:
      return {
        icon: chevronForwardCircleOutline,
        color: colors.tertiary,
        label: 'cleared next',
      };
    case AchievementKind.completedOldTask:
      return {
        icon: hourglassOutline,
        color: colors.orange,
        label: 'completed old task',
      };
    case AchievementKind.completedAncientTask:
      return {
        icon: planetOutline,
        color: colors.danger,
        label: 'completed ancient task',
      };
    case AchievementKind.movedOldTaskFromNext:
      return {
        icon: arrowRedoOutline,
        color: colors.green,
        label: 'moved old task from next',
      };
    case AchievementKind.completedTask:
      return {
        icon: checkmarkCircleOutline,
        color: colors.warning,
        label: 'completed task',
      };
    case AchievementKind.completedAtDueDate:
      return {
        icon: alarmOutline,
        color: colors.secondary,
        label: 'completed at due date',
      };
    case AchievementKind.sortOutTasks:
      return {
        icon: sunnyOutline,
        color: colors.primary,
        label: 'sorted out tasks',
      };
    default:
      throw new Error(
        'achievement kind is not handled in achievementToIcon:' + kind
      );
  }
}

const AchievementBadge = (props: Props) => {
  const { icon, color, label } = achievementToIcon(props.achievement.kind);
  return (
    <Tooltip
      destroyTooltipOnHide
      placement="top"
      trigger={['hover', 'click']}
      overlay={
        <div style={{ width: '120px', height: '40px' }}>
          {props.achievement.achievement
            ? `completed on this day` +
              ((props.achievement?.achievement?.counter || 0) > 1
                ? ` ${props.achievement?.achievement?.counter} times`
                : '')
            : 'not completed on this day'}
        </div>
      }
    >
      <div
        style={{
          margin: '4px',
          display: 'flex',
          flexDirection: 'column',
          width: '99px',
          borderRadius: '10px',
          background: props.achievement.achievement ? color : colors.grayOut,
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: color,
          justifyContent: 'flex-start',
          padding: '3px',
        }}
      >
        {props.achievement.achievement &&
          props.achievement.achievement?.counter > 1 && (
            <div>
              <IonBadge
                style={{
                  float: 'right',
                  marginBottom: '-100%',
                }}
                color="danger"
              >
                {props.achievement.achievement.counter}
              </IonBadge>
            </div>
          )}
        <IonIcon
          icon={icon}
          style={{ color: '#000', alignSelf: 'center', fontSize: '27px' }}
        />
        <IonLabel
          style={{
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: '13px',
            fontWeight: '400',
            paddingTop: '5px',
          }}
        >
          {label}
        </IonLabel>
        {props.achievement.streak.hit >
          (props.achievement.achievement?.counter || 0) && (
          <IonLabel
            style={{
              fontSize: '11px',
              marginTop: '5px',
              textAlign: 'center',
              color: '#444',
            }}
          >
            <IonIcon icon={flame} slot="start" />
            <span>{props.achievement.streak.hit} in the days before</span>
          </IonLabel>
        )}
      </div>
    </Tooltip>
  );
};

export default AchievementBadge;
