import React from 'react';
import {
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from '@ionic/react';
import { ProjectEnrichedWithTaskStats, ProjectStatus } from '../../types/CoreTypes';
import { checkboxOutline, ellipsisVertical } from 'ionicons/icons';
import ProjectListElementMobile from './ProjectListElementMobile';
import { ProjectHandlers } from '../../types/ProjectHandlers';
import { RouteComponentProps, withRouter } from 'react-router';
import deepEqual from 'fast-deep-equal';

interface Props extends RouteComponentProps {
  project: ProjectEnrichedWithTaskStats;
  projectHandlers: ProjectHandlers;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
}

class ProjectListRowMobile extends React.Component<Props> {
  private readonly slidingRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.slidingRef = React.createRef();
  }

  closeOpenedSlider() {
    this.slidingRef.current && this.slidingRef.current.closeOpened();
  }

  render() {
    const { project, onAfterNavigate, isSelected } = this.props;
    const { onChangeState, onStartStateChange } = this.props.projectHandlers;
    return (
      <IonItemSliding ref={this.slidingRef}>
        <ProjectListElementMobile
          project={project}
          isSelected={isSelected}
          onAfterNavigate={onAfterNavigate}
        />

        <IonItemOptions side="start">
          {onChangeState && (
            <IonItemOption
              color="success"
              onClick={() => onChangeState(ProjectStatus.STATUS_ARCHIVE, project)}
            >
              <IonIcon
                style={{ fontSize: '25px' }}
                slot="start"
                icon={checkboxOutline}
              />
            </IonItemOption>
          )}
        </IonItemOptions>

        <IonItemOptions side="end">
          <IonItemOption
            color="primary"
            onClick={() => {
              this.closeOpenedSlider();
              onStartStateChange && onStartStateChange(project);
            }}
          >
            <IonIcon
              style={{ fontSize: '25px' }}
              slot="start"
              icon={ellipsisVertical}
            />
          </IonItemOption>
          {/*<IonItemOption*/}
          {/*  color="warning"*/}
          {/*  onClick={() => {*/}
          {/*    this.closeOpenedSlider();*/}
          {/*    // probably IonItemOption works differently from IonItem,*/}
          {/*    // and onClick handler prevents routerLink from working*/}
          {/*    this.props.history.push(`/projects/edit/${project.id}`, { direction: 'root' });*/}
          {/*    onAfterNavigate && onAfterNavigate();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <IonIcon style={{ fontSize: '25px' }} slot="start" icon={create} />*/}
          {/*</IonItemOption>*/}
        </IonItemOptions>
      </IonItemSliding>
    );
  }
}

const Memoized = React.memo(ProjectListRowMobile, deepEqual);

export default withRouter(Memoized);
