// eslint-disable-next-line import/named
import { RxCollection, RxDatabase } from 'rxdb/plugins/core';
import {
  ItemType,
  BlockFields,
  ContextType,
  BlockKind,
  Identifiable,
  ProjectType,
  TaskType,
  TagType,
  TaskTime,
  TaskEnergy,
  Priority,
  TaskStatus,
  ProjectStatus,
  TaskFields,
  PriorityValues,
  AchievementType,
  AchievementKind,
} from '@todo/common';
import React from 'react';
export * from '@todo/common';

export type Nullable<A> = A | undefined;

// the same as BlockFields but making contextId optional
export type BlockForm = {
  type: BlockKind;
  // state: BlockState;
  completed?: boolean;
  indent: number;
  bold?: boolean;
  color?: string;
  content: string;
  position: number;
  contextId?: string;
  taskId?: string;
  projectId?: string;
};

export type BlockChangeSet = Partial<BlockFields> & Identifiable;
export interface BlockMinValue {
  value: string;
  type: BlockKind;
}

// interface CheckboxProps {
//   completed?: boolean;
// }

// export interface BlockChangeSet extends Partial<Block> {
//   id: string;
// }

// export interface GoalFields {
//   name: string;
//   description: string;
//   dateDue?: number;
//   status: GoalStatus;
//   contextId: string;
// }
// export type GoalType = GoalFields & Identifiable & TimeStampable;

export interface SettingsType {
  id: string;
  value: string;
}

interface ActionArgs {
  navigate: (url: string) => void;
}

export interface ActionModel {
  name: string;
  execute: (args: ActionArgs) => void;
}

export interface ProjectTaskStats {
  totalTasks: number;
  remainingTasks: number;
  remainingNext: number;
  remainingWaiting: number;
  remainingTime: number;
  totalTime: number;
  unpointedTime: number;
  unpointedEnergy: number;
  unpointedPriority: number;
}

export interface ProjectDepending {
  dependsOnProject: ProjectType | null;
}

export interface TaskBlockStats {
  totalCheckboxes: number;
  completedCheckboxes: number;
  anyBlocks: boolean;
}

export interface TaskTags {
  tagsData: TagType[];
}

export interface TaskProject {
  project: ProjectType | null;
}

export type ProjectEnrichedWithTaskStats = ProjectType & ProjectTaskStats;
export type ProjectEnrichedWithTaskStatsAndDependingProject = ProjectEnrichedWithTaskStats &
  ProjectDepending;

export type TaskEnrichedWithBlockStats = TaskType & TaskBlockStats;

export interface ProjectEnrichedWithNextTask extends ProjectType {
  nextTask?: TaskType | null;
}

export interface ChecklistItem {
  name: string;
  complete: boolean;
}

export type TaskEnrichedWithProject = TaskType & TaskProject;
export type TaskEnrichedWithProjectAndTags = TaskEnrichedWithProject & TaskTags;

export type TagEnrichedWithParent = TagType & { parent: TagType | null };

export interface TaskFilter {
  contextId?: string;
  status?: TaskStatus;
  statusIn?: TaskStatus[];
  notStatus?: TaskStatus;
  tags?: string[];
  projectId?: string;
  hasNoProject?: true;
  hasProject?: true;
  projectStatuses?: ProjectStatus[];
  name?: string;
  anyText?: string;
  due?: string;
  dueNow?: true;
  agendaFor?: string;
  completedAt?: { from: number; to: number };
  createdAt?: { from: number; to: number };
  updatedAt?: { from: number; to: number };
  forToday?: true;
  dueSoon?: true;
  time?: TaskTime | null;
  energy?: TaskEnergy | null;
  priority?: Priority | null;
}

export interface ProjectFilter {
  contextId?: string;
  status?: ProjectStatus;
  notStatus?: ProjectStatus;
  statusIn?: ProjectStatus[];
  anyText?: string;
  dueToday?: boolean;
}

export interface ContextFilter {
  anyText?: string;
}

export interface TagFilter {
  anyText?: string;
  parentId?: string | null;
}

export interface ActionFilter {
  anyText?: string;
}

export interface TaskCreateForm extends TaskCreateFormFillable {
  contextId: string;
}

// export interface TaskCreateFormFillable {
//   name: string;
//   blocks?: BlockForm[];
//   contextId: string;
//   project?: ProjectType;
//   tags?: TagType[];
//   status: TaskStatus;
//   dateDue?: number;
//   priority?: Priority;
//   dateScheduled?: number;
//   recurringOptions?: RecurringOptions;
//   time?: TaskTime;
//   energy?: TaskEnergy;
//   priorityValues?: PriorityValues;
// }

export type TaskCreateFormFillable = TaskFields &
  TaskProject &
  TaskTags & { blocks?: BlockForm[] };

export interface TagCreateFormFillable {
  name: string;
  blocks?: BlockForm[];
  contextId?: string;
  parentId?: string;
  color?: string;
  icon?: string;
}

export interface TagCreateForm extends TagCreateFormFillable {
  contextId: string;
}

export interface ProjectCreateFormFillable {
  name: string;
  blocks?: BlockForm[];
  contextId?: string;
  status: ProjectStatus;
  priorityValues?: PriorityValues;
}

export interface ProjectCreateForm extends ProjectCreateFormFillable {
  contextId: string;
}

export interface GoalCreateForm {
  name: string;
  contextId: string;
}

export enum GoalStatus {
  STATUS_CURRENT = 'CURRENT',
  STATUS_SOME_TIME = 'SOME_TIME',
  STATUS_LONG_TERM = 'LONG_TERM',
  STATUS_ARCHIVE = 'ARCHIVE',
}

export interface GoalFilter {
  status?: GoalStatus;
}

//
// export interface ContextChangeSet extends Partial<ContextType> {
//   id: string;
// }
//
// export interface ProjectChangeSet extends Partial<ProjectType> {
//   id: string;
// }
//
// export interface GoalChangeSet extends Partial<GoalType> {
//   id: string;
// }
//
// export interface TaskChangeSet extends Partial<TaskType> {
//   id: string;
// }

export interface Typable {
  type: ItemType;
}

export type PartialUpdatedRecord<T> = Partial<T> & Identifiable;

export type UpdatedRecord<T extends Identifiable> = Partial<T> & {
  id: string;
  lastUpdated: number;
};

export interface CategorySearchResults<K> {
  ts: number;
  data: K[];
}

export interface SearchResults {
  tasks: CategorySearchResults<
    TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats
  >;
  projects: CategorySearchResults<ProjectEnrichedWithTaskStats>;
  contexts: CategorySearchResults<ContextType>;
  tags: CategorySearchResults<TagType>;
  // actions: ActionModel[];
}

export enum CustomAppEvent {
  EVENT_TASK_UPDATED,
  EVENT_PROJECT_UPDATED,
  EVENT_CONTEXT_UPDATED,
}

export enum RelationFields {
  Project = 'project',
  DependsOnProject = 'dependsOnProject',
  Context = 'context',
  Task = 'task',
  Tags = 'tagsData',
  Parent = 'parent',
}

type ProjectCollection = RxCollection<ProjectType>;
export type MyDatabaseCollections = {
  [key in ItemType]: any;
};
export type MyDB = RxDatabase<MyDatabaseCollections>;

export interface OptionGroup<A> {
  label: string;
  options: A[];
}

export interface OptionType {
  value: string;
  label: string;
}

export interface OptionTypeWithColor extends OptionType {
  color?: string;
  icon?: string;
}

export interface HotKeyHandler {
  name: string;
  combination: HotKeyCombination;
  action: () => void;
}

export interface HotKeyCombination {
  key: Key;
  modifiers?: KeyModifier[][];
}

export enum Key {
  Escape = 'Escape',
  Enter = 'Enter',
  Up = 'Up',
  Down = 'Down',
  F = 'f',
  N = 'n',
  E = 'e',
  Y = 'y',
  M = 'm',
  T = 't',
  P = 'p',
  S = 's',
  W = 'w',
  U = 'u',
  O = 'o',
  G = 'g',
  I = 'i',
  L = 'l',
  D = 'd',
  _0 = '0',
  _1 = '1',
  _2 = '2',
  _3 = '3',
  _4 = '4',
  _5 = '5',
  _6 = '6',
  _7 = '7',
  _8 = '8',
  _9 = '9',
}

export enum KeyCode {
  Escape = 'Escape',
  Enter = 'Enter',
  Up = 'Up',
  ArrowUp = 'ArrowUp',
  Down = 'Down',
  ArrowDown = 'ArrowDown',
  KeyF = 'KeyF',
  KeyN = 'KeyN',
  KeyE = 'KeyE',
  KeyY = 'KeyY',
  KeyM = 'KeyM',
  KeyT = 'KeyT',
  KeyP = 'KeyP',
  KeyS = 'KeyS',
  KeyW = 'KeyW',
  KeyU = 'KeyU',
  KeyO = 'KeyO',
  KeyG = 'KeyG',
  KeyI = 'KeyI',
  KeyL = 'KeyL',
  KeyD = 'KeyD',
  KeyK = 'KeyK',
  Digit0 = 'Digit0',
  Digit1 = 'Digit1',
  Digit2 = 'Digit2',
  Digit3 = 'Digit3',
  Digit4 = 'Digit4',
  Digit5 = 'Digit5',
  Digit6 = 'Digit6',
  Digit7 = 'Digit7',
  Digit8 = 'Digit8',
  Digit9 = 'Digit9',
}

export enum KeyModifier {
  Ctrl = 'Ctrl',
  Cmd = 'Cmd',
  Shift = 'Shift',
  Alt = 'Alt',
}

export const CMD_OR_CTRL = [[KeyModifier.Ctrl], [KeyModifier.Cmd]];
export const CMD_OR_CTRL_SHIFT = [
  [KeyModifier.Ctrl, KeyModifier.Shift],
  [KeyModifier.Cmd, KeyModifier.Shift],
];
export const CTRL_ALT = [[KeyModifier.Ctrl, KeyModifier.Alt]];
export const SHIFT_ALT = [[KeyModifier.Shift, KeyModifier.Alt]];

export interface NamedHotKeyHandler {
  categoryName: string;
  subscriptionId: string;
  handler: HotKeyHandler;
}

export interface DataPoint {
  label: string;
  value: number;
}

export interface Auth {
  token: string;
  // auth0Id: string;
  userId: string;
  encryptionPassword: string;
  activeEntitlement: string | null;
  userName: string;
  userEmail: string;
}

export interface UserInfo {
  wasAuthenticated: boolean;
  setUpAt: number | null;
  lastUsedUserId: string | null;
  lastSubscriptionType: string | null;
  auth: Auth | null;
}

export interface Dict {
  [key: string]: any;
}

export enum SyncStatus {
  Succeeding = 'Succeeding',
  Offline = 'Offline',
  NoSyncError = 'NoSyncError',
  Error = 'Error',
  SyncEnabledNotYetSucceeded = 'SyncEnabledNotYetSucceeded',
  LocalMode = 'LocalMode',
}

export enum SyncEvent {
  Active = 'Active',
  ActiveRepeat = 'ActiveRepeat',
  Offline = 'Offline',
  NoSyncError = 'NoSyncError',
  Error = 'Error',
}

export interface AchievementWithMetadata {
  achievement: AchievementType;
  counter: number;
}

export interface AchievementWithStreak {
  kind: AchievementKind;
  streak: {
    hit: number;
    days: number;
  };
  achievement?: AchievementWithMetadata;
}

export interface HelpArticle {
  title: string;
  render: (isDesktop: boolean) => React.ReactNode;
}

export interface GrantedPermissions {
  badge?: boolean;
}
