import React, { Component, createRef } from 'react';
import Gantt, { GanttTask, viewMode } from '../../ganttFork';
import deepEqual from 'fast-deep-equal';
import { addDays } from 'date-fns';

export type IGanttTask = GanttTask;

interface Props {
  formatLink?: (vm: GanttTask) => string;
  tasks: IGanttTask[];
  viewMode: viewMode;
  onViewChange?: (vm: viewMode) => void;
  onClick: (task: GanttTask) => void;
  onBlur?: () => void;
  onDateChange: (task: GanttTask, start: Date, end: Date) => void;
  onProgressChange?: (task: GanttTask, progress: number) => void;
  // onTasksChange: (tasks: GanttTask[]) => void;
}

export default class FrappeGantt extends Component<Props> {
  private _gantt: Gantt | null;

  private readonly _svg: React.RefObject<any>;
  private readonly _target: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this._target = createRef();
    this._svg = createRef();
    this._gantt = null;

    // this.state = {
    //   viewMode: props.viewMode,
    //   // tasks: [],
    // };
  }
  //
  // static getDerivedStateFromProps(nextProps: Props) {
  //   return {
  //     viewMode: nextProps.viewMode,
  //     // tasks: nextProps.tasks,
  //   };
  // }

  componentDidUpdate(prevProps: Props) {
    // console.log('gantt: componentDidUpdate');
    if (this._gantt) {
      if (!deepEqual(prevProps.tasks, this.props.tasks)) {
        this._gantt.refresh(this.props.tasks);
      }
      if (!deepEqual(prevProps.viewMode, this.props.viewMode)) {
        this._gantt.change_view_mode(this.props.viewMode);
      }
    }
  }

  componentDidMount() {
    // console.log('gantt: componentDidMount');
    this._gantt = new Gantt(this._svg.current, this.props.tasks, {
      view_mode: this.props.viewMode,
      allowProgressChange: false,
      on_click: this.props.onClick,
      on_blur: this.props.onBlur,
      on_view_change: this.props.onViewChange,
      on_progress_change: (task, progress) => {
        this.props.onProgressChange && this.props.onProgressChange(task, progress);
        // this.props.onTasksChange(this.props.tasks);
      },
      on_date_change: (task, start, end) => {
        if (start.getHours() >= 12) {
          start = addDays(start, 1);
        }
        this.props.onDateChange(task, start, end);
        // this.props.onTasksChange(this.props.tasks);
      },
      showPopup: false,
      // custom_popup_html: (task) => {
      //   const customHtml = this.props.tasks.find((t) => t.id === task.id)
      //     ?.detailsHtml;
      //   if (customHtml) {
      //     return customHtml;
      //   }
      //   // the task object will contain the updated
      //   // dates and progress value
      //   // const end_date = task._end.toLocaleDateString();
      //   return `
      //     <div class="gantt-details-container">
      //         <div class="name">
      //         ${
      //           this.props.formatLink ? (
      //             <a href="${this.props.formatLink(task)}">${task.name}</a>
      //           ) : (
      //             task.name
      //           )
      //         }
      //         </div>
      //     </div>
      //       `;
      // },
    });

    if (this._gantt) {
      setTimeout(() => {
        if (this._gantt) {
          this._gantt.change_view_mode(this.props.viewMode);
        }
      }, 1);
    }

    // const midOfSvg = this._svg.current.clientWidth * 0.5;
    // this._target.current.scrollLeft = midOfSvg;
  }

  render() {
    return (
      <div ref={this._target}>
        <svg
          ref={this._svg}
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        />
      </div>
    );
  }
}
