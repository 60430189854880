const schema = {
  keyCompression: false,
  title: 'block schema',
  version: 0,
  description: 'describes a block',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    type: {
      type: 'string',
    },
    content: {
      type: 'string',
    },
    completed: {
      type: 'boolean',
    },
    indent: {
      type: 'number',
    },
    bold: {
      type: 'boolean',
    },
    color: {
      type: 'string',
    },
    contextId: {
      ref: 'task',
      type: 'string',
    },
    taskId: {
      ref: 'task',
      type: 'string',
    },
    projectId: {
      ref: 'project',
      type: 'string',
    },
    tagId: {
      ref: 'tag',
      type: 'string',
    },
    position: {
      type: 'number',
    },
    createdAt: {
      type: 'number',
      final: true,
    },
    lastUpdated: {
      type: 'number',
    },
    serverUpdatedAt: {
      type: 'number',
    },
    last_pulled_rev: {
      type: 'string',
    },
    deviceId: {
      type: 'string',
    },
    data: {
      type: 'object',
    },
    // userId: {
    //   ref: 'task',
    //   type: 'string',
    // },
  },
  required: [
    'contextId',
    'content',
    'position',
    'type',
    'indent',
    'createdAt',
    // 'userId',
  ],
  // encrypted: ['secret'],
  // attachments: {
  //   encrypted: true
  // },
  indexes: [
    ['taskId', 'type'],
    ['projectId', 'type'],
    ['tagId', 'type'],
  ],
};

export default schema;
