import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/browser';
// eslint-disable-next-line import/named
import { Capacitor, Plugins } from '@capacitor/core';
import * as serviceWorker from './serviceWorkerRegistration';
import { EventProvider } from './helpers/EventProvider';
import { initDb } from './lib/data/pouchdb';
import { SettingsModel, SettingsModelClass } from './lib/data/Models';
import { MyDB } from './types/CoreTypes';
import LocalSettingsRepo from './lib/LocalSettingsRepo';
import { DbProvider } from './helpers/DbProvider';
import { AuthUserProvider } from './helpers/AuthUserProvider';
import { getEnvVarOrDie } from './lib/EnvManager';
import history from './helpers/history';
import { SafariViewController } from '@ionic-native/safari-view-controller';
// @ts-ignore
import { Auth0Provider } from './Auth0ReactFork/index';
import {
  InAppBrowser,
  InAppBrowserEvent,
  InAppBrowserEventType,
  InAppBrowserObject,
} from '@ionic-native/in-app-browser';
import { Subscription } from 'rxjs';

// import { datadogLogs } from '@datadog/browser-logs';

const { SplashScreen, Browser } = Plugins;

// @ts-ignore
function isElectron() {
  // @ts-ignore
  return window.electron !== null && window.electron !== undefined;
}

class AdapterInAppBrowserTab {
  private readonly browserInstance: InAppBrowserObject;
  private subscribers: { [key: string]: Subscription[] } = {};

  constructor(browserInstance: InAppBrowserObject) {
    this.browserInstance = browserInstance;
  }

  addEventListener(
    type: InAppBrowserEventType,
    handler: (e: InAppBrowserEvent) => void
  ) {
    const sub = this.browserInstance.on(type).subscribe(handler);
    const subscribers = this.subscribers[type] || [];
    subscribers.push(sub);
  }

  removeEventListener(
    type: InAppBrowserEventType,
    handler: (e: InAppBrowserEvent) => void
  ) {
    // simplify to remove all listeners
    const subscribers = this.subscribers[type] || [];
    subscribers.forEach((s) => s.unsubscribe());
    this.subscribers[type] = [];
  }

  close() {
    this.browserInstance.close();
  }
}

const isNative = Capacitor.isNative;

if (isNative) {
  // @ts-ignore
  window.open = (url, target, options: any) => {
    if (!url) return;
    //   SafariViewController.show({
    //     url,
    //     hidden: false,
    //     // animated: false,
    //   });
    // };
    Browser.open({
      // @ts-ignore
      url,
      windowName: target,
      presentationStyle: 'popover',
    });
  };

  if (!window.cordova) {
    // @ts-ignore
    window.cordova = {};
  }
  // @ts-ignore
  window.cordova.InAppBrowser = {
    open: (url: string, target: string, options: any) => {
      return new AdapterInAppBrowserTab(InAppBrowser.create(url, target));
    },
  };
}

// if (isElectron()) {
//   const default_window_open = window.open;
//   // @ts-ignore
//   window.open = (url, target, options: any) => {
//     // console.log('window.open', { url, target, options });
//     // if (!url) return;
//     if (target === '_blank' || target === undefined) {
//       // @ts-ignore
//       return window.electron.defaultBrowserWindow(url);
//     } else {
//       return default_window_open(url, target, options);
//     }
//   };
//   // if (!window.cordova) {
//   //   // @ts-ignore
//   //   window.cordova = {};
//   // }
//   // // @ts-ignore
//   // window.cordova.InAppBrowser = {
//   //   open: (url: string, target: string, options: any) => {
//   //     return new AdapterElectronTab(window.open(url, target, options));
//   //   },
//   // };
//   // // @ts-ignore
//   // console.log('window.cordova.InAppBrowser', window.cordova.InAppBrowser);
// }

const AUTH0_DOMAIN = getEnvVarOrDie('REACT_APP_AUTH0_DOMAIN');
const AUTH0_CLIENT_ID = getEnvVarOrDie('REACT_APP_AUTH0_CLIENT_ID');
const AUTH0_AUDIENCE = getEnvVarOrDie('REACT_APP_AUTH0_AUDIENCE');

const APP_HANDLE = getEnvVarOrDie('REACT_APP_APP_HANDLE');

// this is not required by the way
const redirectUri = Capacitor.isNative
  ? `${APP_HANDLE}://focus`
  : window.location.origin;

// TODO: different way for ios once it's ready?
if (process.env.NODE_ENV !== 'development') {
  const SENTRY_DSN = getEnvVarOrDie('REACT_APP_SENTRY_DSN');
  try {
    Sentry.init({ dsn: SENTRY_DSN });
  } catch (e) {
    console.error('failure initializing Sentry!', e);
  }
}

// if (process.env.NODE_ENV !== 'development') {
//   const DATADOG_CLIENT_TOKEN = getEnvVarOrDie('REACT_APP_DATADOG_CLIENT_TOKEN');
//   const DATADOG_SITE = getEnvVarOrDie('REACT_APP_DATADOG_SITE');
//   datadogLogs.init({
//     clientToken: DATADOG_CLIENT_TOKEN,
//     site: DATADOG_SITE,
//     forwardErrorsToLogs: true,
//     sampleRate: 100,
//   });
// }

if (!isElectron() && !Capacitor.isNative) {
  serviceWorker.register();
}

async function setUpDb() {
  const localDb = await initDb();
  // TODO consider user ID changing when signing up?...
  //  this can only be done when logging out, and then signing in again

  const localSettingsRepo = await setUpLocalSettings(localDb, SettingsModel);

  // const userHelper = new UserHelper(localSettingsRepo);

  return { localDb, localSettingsRepo };
}

const setUpLocalSettings = async (
  db: MyDB,
  model: SettingsModelClass
): Promise<LocalSettingsRepo> => {
  const collection = await model.initCollection(db);
  return new LocalSettingsRepo(collection);
};

// Plugins.App.addListener('appUrlOpen', () => {
//   console.log('app url open');
//   SafariViewController.hide();
//   Plugins.App.getLaunchUrl().then((res) => {
//     console.log({ res });
//     // window.location.assign(res.url.replace(APP_HANDLE, ''));
//   });
// });
Plugins.App.addListener('appUrlOpen', (data: any) => {
  // Auth0Cordova.onRedirectUri(data.url);
  SafariViewController.hide();
  window.location.assign(data.url.replace(`${APP_HANDLE}://`, ''));
});

const onRedirectCallback = (appState: any) => {
  let url: string;
  if (appState && appState.returnTo) {
    url = appState.returnTo;
  } else {
    const params = [...new URLSearchParams(window.location.search)];

    const keepParams = params.filter(([k, v]) => k !== 'code' && k !== 'state');

    url = `${window.location.pathname}?${keepParams
      .map(([k, v]) => `${k}=${v}`)
      .join('&')}`;
  }

  // setTimeout(() => {
  history.replace(url);
  // }, 10000);
};

setUpDb().then((dbDeps) => {
  ReactDOM.render(
    <div className={isNative ? 'is-native' : 'not-native'}>
      <EventProvider>
        <DbProvider dbDeps={dbDeps}>
          <Auth0Provider
            useRefreshTokens={true}
            cacheLocation="localstorage"
            audience={AUTH0_AUDIENCE}
            domain={AUTH0_DOMAIN}
            clientId={AUTH0_CLIENT_ID}
            redirectUri={redirectUri}
            skipRedirectCallback={false}
            onRedirectCallback={onRedirectCallback}
            isNative={Capacitor.isNative || false}
          >
            <AuthUserProvider domain={AUTH0_DOMAIN}>
              <App />
            </AuthUserProvider>
          </Auth0Provider>
        </DbProvider>
      </EventProvider>
    </div>,
    document.getElementById('root')
  );
  SplashScreen.hide();
});
