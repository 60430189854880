import {
  TaskEnrichedWithProjectAndTags,
  TaskFilter,
  TaskStatus,
  TaskTime,
  TaskType,
} from '../types/CoreTypes';
import {
  alarm,
  cafe,
  calendarOutline,
  checkbox,
  chevronForwardCircle,
  mailOpen,
  partlySunny,
  star,
} from 'ionicons/icons';
import { getTaskStatusLabel } from './CopyHelper';
import { getPriorityScore } from './PriorityHelper';
import { sortByProp } from './ListHelper';
import { sortByStatusAndPosition } from './ProjectHelper';

export interface TaskStatusView {
  icon: string;
  label: string;
  filter?: TaskFilter;
}

export enum TaskStatusKey {
  Inbox = 'Inbox',
  Next = 'Next',
  ProjectNext = 'ProjectNext',
  ProjectFocus = 'ProjectFocus',
  DueToday = 'DueToday',
  Due = 'Due',
  DueSoon = 'DueSoon',
  Waiting = 'Waiting',
  SomeTime = 'SomeTime',
  // SomeTimeAll = 'SomeTimeAll',
  All = 'All',
  InProgress = 'InProgress',
}

export const taskStatuses: { [key in TaskStatusKey]: TaskStatusView } = {
  [TaskStatusKey.Inbox]: {
    label: getTaskStatusLabel(TaskStatus.STATUS_INBOX),
    icon: getTaskIcon(TaskStatus.STATUS_INBOX),
    filter: {
      status: TaskStatus.STATUS_INBOX,
    },
  },
  [TaskStatusKey.Next]: {
    label: getTaskStatusLabel(TaskStatus.STATUS_ACTION_LIST),
    icon: getTaskIcon(TaskStatus.STATUS_ACTION_LIST),
    filter: {
      status: TaskStatus.STATUS_ACTION_LIST,
      hasNoProject: true,
    },
  },
  [TaskStatusKey.ProjectNext]: {
    label: 'Next for projects',
    icon: chevronForwardCircle,
    // filter: {},
  },
  [TaskStatusKey.ProjectFocus]: {
    label: 'Focus for projects',
    icon: chevronForwardCircle,
    // filter: {},
  },
  [TaskStatusKey.InProgress]: {
    label: getTaskStatusLabel(TaskStatus.STATUS_IN_PROGRESS),
    icon: getTaskIcon(TaskStatus.STATUS_IN_PROGRESS),
    filter: {
      status: TaskStatus.STATUS_IN_PROGRESS,
    },
  },
  [TaskStatusKey.DueToday]: {
    label: 'For Today',
    icon: alarm,
    filter: {
      forToday: true,
      notStatus: TaskStatus.STATUS_DONE,
    },
  },
  [TaskStatusKey.Due]: {
    label: 'Due Today',
    icon: alarm,
    filter: {
      dueNow: true,
      notStatus: TaskStatus.STATUS_DONE,
    },
  },
  [TaskStatusKey.DueSoon]: {
    label: 'Due Soon',
    icon: alarm,
    filter: {
      dueSoon: true,
      notStatus: TaskStatus.STATUS_DONE,
    },
  },
  [TaskStatusKey.Waiting]: {
    label: getTaskStatusLabel(TaskStatus.STATUS_WAITING),
    icon: getTaskIcon(TaskStatus.STATUS_WAITING),
    filter: {
      status: TaskStatus.STATUS_WAITING,
    },
  },
  [TaskStatusKey.SomeTime]: {
    label: getTaskStatusLabel(TaskStatus.STATUS_SOME_TIME),
    icon: getTaskIcon(TaskStatus.STATUS_SOME_TIME),
    filter: {
      status: TaskStatus.STATUS_SOME_TIME,
      hasNoProject: true,
    },
  },
  // [TaskStatusKey.SomeTimeAll]: {
  //   label: getTaskStatusLabel(TaskStatus.STATUS_SOME_TIME),
  //   icon: getTaskIcon(TaskStatus.STATUS_SOME_TIME),
  //   filter: {
  //     status: TaskStatus.STATUS_SOME_TIME,
  //   },
  // },
  [TaskStatusKey.All]: {
    label: 'all',
    icon: '',
    filter: {
      // status: TaskStatus.STATUS_SOME_TIME,
    },
  },
};

export function getTaskColorLabel(status: TaskStatus) {
  switch (status) {
    case TaskStatus.STATUS_INBOX:
      return 'medium';
    case TaskStatus.STATUS_SOME_TIME:
      return 'danger';
    case TaskStatus.STATUS_SCHEDULED:
      return 'secondary';
    case TaskStatus.STATUS_ACTION_LIST:
      return 'primary';
    case TaskStatus.STATUS_IN_PROGRESS:
      return 'warning';
    case TaskStatus.STATUS_WAITING:
      return 'tertiary';
    case TaskStatus.STATUS_DONE:
      return 'success';
  }
}

export function getTaskIcon(status: TaskStatus) {
  switch (status) {
    case TaskStatus.STATUS_INBOX:
      return mailOpen;
    case TaskStatus.STATUS_SOME_TIME:
      return partlySunny;
    case TaskStatus.STATUS_SCHEDULED:
      return calendarOutline;
    case TaskStatus.STATUS_ACTION_LIST:
      return chevronForwardCircle;
    case TaskStatus.STATUS_IN_PROGRESS:
      return star;
    case TaskStatus.STATUS_WAITING:
      return cafe;
    case TaskStatus.STATUS_DONE:
      return checkbox;
  }
}

export function getMinutesFromTaskTime(time?: TaskTime): number {
  return time || 0;
}

export const sortByPriority = (a: TaskType, b: TaskType): number => {
  const taskPriorityScoreA = getPriorityScore(a.priorityValues) || 0;
  const taskPriorityScoreB = getPriorityScore(b.priorityValues) || 0;
  if (taskPriorityScoreA === taskPriorityScoreB) {
    return sortByProp(a, b, 'createdAt', 'desc');
  }
  return taskPriorityScoreA > taskPriorityScoreB ? -1 : 1;
};

export const sortByProjectStatus = (
  a: TaskEnrichedWithProjectAndTags,
  b: TaskEnrichedWithProjectAndTags
): number => {
  if (!a.project && !b.project) return 0;
  if (!a.project) return -1;
  if (!b.project) return 1;
  return sortByStatusAndPosition(a.project, b.project);
};
