import React from 'react';
import { TaskType } from '../../types/CoreTypes';
import {
  getTaskAge,
  TaskAge,
  taskTooLongInStatus,
} from '../../helpers/productivityHelper';
import Tooltip from 'rc-tooltip';
import { genericStyles } from '../../helpers/styles';
import { arrowRedoOutline, hourglassOutline, planetOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

interface Props {
  task: TaskType;
  date: Date;
}

const TaskProductivityIcons = ({ task, date }: Props) => {
  const isTooLongInStatus = taskTooLongInStatus(task, date);
  const taskAge = getTaskAge(task, date);
  return (
    <div style={{ alignSelf: 'center' }}>
      {isTooLongInStatus && (
        <Tooltip
          destroyTooltipOnHide
          placement="top"
          trigger={['hover', 'click']}
          overlay={
            <div style={{ width: '120px', height: '40px' }}>
              task has not changed status for too long
            </div>
          }
        >
          <IonIcon
            color="danger"
            style={genericStyles.icon}
            icon={arrowRedoOutline}
          />
        </Tooltip>
      )}
      {taskAge === TaskAge.ancient && (
        <Tooltip
          destroyTooltipOnHide
          placement="top"
          trigger={['hover', 'click']}
          overlay={
            <div style={{ width: '120px', height: '40px' }}>task is ancient</div>
          }
        >
          <IonIcon color="danger" style={genericStyles.icon} icon={planetOutline} />
        </Tooltip>
      )}
      {taskAge === TaskAge.old && (
        <Tooltip
          destroyTooltipOnHide
          placement="top"
          trigger={['hover', 'click']}
          overlay={<div style={{ width: '120px', height: '40px' }}>task is old</div>}
        >
          <IonIcon
            color="danger"
            style={genericStyles.icon}
            icon={hourglassOutline}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default TaskProductivityIcons;
