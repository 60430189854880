import React from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { HelpArticle } from '../types/CoreTypes';
import { useClientType } from '../helpers/ClientTypeProvider';

interface Props {
  onClose: () => void;
  articles: HelpArticle[];
}

const HelpArticles = ({ onClose, articles }: Props) => {
  const { isDesktop } = useClientType();
  return (
    <>
      <IonHeader>
        <IonToolbar>
          Help
          <IonButton slot="end" onClick={onClose} color="light">
            <IonIcon slot="start" icon={close} />
            Close
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="help-content">
        {!articles.length && (
          <IonCardContent>
            There&apos;s no help article for this page yet. Please go to the help
            page and explore other help articles!
          </IonCardContent>
        )}
        {articles.map((a) => (
          <IonCard key={a.title}>
            <IonCardHeader>
              <IonCardTitle>{a.title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>{a.render(isDesktop)}</IonCardContent>
          </IonCard>
        ))}
      </IonContent>
    </>
  );
};

export default HelpArticles;
