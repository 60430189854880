import {
  ContextType,
  ProjectFilter,
  ProjectStatus,
  ProjectType,
  TaskEnrichedWithBlockStats,
  TaskPositionContextEnum,
  TaskStatus,
} from '../types/CoreTypes';
import TaskRepo from '../lib/TaskRepo';
import {
  alarm,
  cafe,
  checkbox,
  chevronForwardCircle,
  chevronForwardCircleOutline,
  partlySunny,
  star,
} from 'ionicons/icons';
import { getProjectStatusLabel } from './CopyHelper';
import { getOrDefault } from './func';

export const getNextTaskForProject = async (
  context: ContextType,
  project: ProjectType,
  taskRepo: TaskRepo,
  dateFormatted: string
): Promise<null | TaskEnrichedWithBlockStats> => {
  const nextTasksOrdered = await taskRepo.getAllForFilterWithOnlyStatsEnrichment(
    context.id,
    {
      projectId: project.id,
      statusIn: [TaskStatus.STATUS_ACTION_LIST, TaskStatus.STATUS_IN_PROGRESS],
    },
    TaskPositionContextEnum.PROJECT,
    dateFormatted
  );
  if (!nextTasksOrdered.length) return null;
  return nextTasksOrdered[0];
};

export const getSomeTasksForProject = async (
  context: ContextType,
  project: ProjectType,
  taskRepo: TaskRepo,
  dateFormatted: string
): Promise<TaskEnrichedWithBlockStats[]> => {
  const someTasksOrdered = await taskRepo.getAllForFilter(
    context.id,
    {
      projectId: project.id,
      statusIn: [TaskStatus.STATUS_SOME_TIME],
    },
    TaskPositionContextEnum.PROJECT,
    dateFormatted
  );
  return someTasksOrdered;
};

export interface ProjectStatusView {
  icon: string;
  label: string;
  showInList: boolean;
  filter?: ProjectFilter;
}

export enum ProjectStatusKey {
  Action = 'Action',
  Waiting = 'Waiting',
  Next = 'Next',
  DueToday = 'DueToday',
  ProjectWithNext = 'ProjectWithNext',
  SomeTime = 'SomeTime',
  All = 'All',
}

export const projectStatuses: { [key in ProjectStatusKey]: ProjectStatusView } = {
  [ProjectStatusKey.Action]: {
    label: getProjectStatusLabel(ProjectStatus.STATUS_ACTION),
    icon: getProjectIcon(ProjectStatus.STATUS_ACTION),
    showInList: true,
    filter: {
      status: ProjectStatus.STATUS_ACTION,
    },
  },
  [ProjectStatusKey.DueToday]: {
    label: 'Due Today',
    icon: alarm,
    showInList: false,
    filter: {
      dueToday: true,
      notStatus: ProjectStatus.STATUS_ARCHIVE,
    },
  },
  [ProjectStatusKey.Waiting]: {
    label: getProjectStatusLabel(ProjectStatus.STATUS_WAITING),
    icon: getProjectIcon(ProjectStatus.STATUS_WAITING),
    showInList: true,
    filter: {
      status: ProjectStatus.STATUS_WAITING,
    },
  },
  [ProjectStatusKey.Next]: {
    label: getProjectStatusLabel(ProjectStatus.STATUS_NEXT),
    icon: getProjectIcon(ProjectStatus.STATUS_NEXT),
    showInList: true,
    filter: {
      status: ProjectStatus.STATUS_NEXT,
    },
  },
  [ProjectStatusKey.SomeTime]: {
    label: getProjectStatusLabel(ProjectStatus.STATUS_SOME_TIME),
    icon: getProjectIcon(ProjectStatus.STATUS_SOME_TIME),
    showInList: true,
    filter: {
      status: ProjectStatus.STATUS_SOME_TIME,
    },
  },
  [ProjectStatusKey.All]: {
    label: 'All',
    icon: '',
    showInList: false,
    filter: {
      // status: ProjectStatus.STATUS_SOME_TIME,
    },
  },
  [ProjectStatusKey.ProjectWithNext]: {
    label: 'Project with next',
    icon: chevronForwardCircle,
    showInList: false,
    // filter: {},
  },
};

export function getProjectColorLabel(status: ProjectStatus): string {
  switch (status) {
    case ProjectStatus.STATUS_SOME_TIME:
      return 'danger';
    case ProjectStatus.STATUS_ACTION:
      return 'warning';
    case ProjectStatus.STATUS_NEXT:
      return 'primary';
    case ProjectStatus.STATUS_WAITING:
      return 'tertiary';
    case ProjectStatus.STATUS_ARCHIVE:
      return 'success';
  }
}

export function getProjectIcon(status: ProjectStatus): string {
  switch (status) {
    case ProjectStatus.STATUS_ACTION:
      return star;
    case ProjectStatus.STATUS_NEXT:
      return chevronForwardCircleOutline;
    case ProjectStatus.STATUS_SOME_TIME:
      return partlySunny;
    case ProjectStatus.STATUS_WAITING:
      return cafe;
    case ProjectStatus.STATUS_ARCHIVE:
      return checkbox;
  }
}

export function getProjectEmoji(status: ProjectStatus): string {
  switch (status) {
    case ProjectStatus.STATUS_ACTION:
      return '⭐';
    case ProjectStatus.STATUS_NEXT:
      return '🔥';
    case ProjectStatus.STATUS_SOME_TIME:
      return '🌤️';
    case ProjectStatus.STATUS_WAITING:
      return '☕';
    case ProjectStatus.STATUS_ARCHIVE:
      return '✅';
  }
}

function projectStatusToInt(status: ProjectStatus) {
  switch (status) {
    case ProjectStatus.STATUS_ACTION:
      return 0;
    case ProjectStatus.STATUS_WAITING:
      return 1;
    case ProjectStatus.STATUS_NEXT:
      return 2;
    case ProjectStatus.STATUS_SOME_TIME:
      return 3;
    case ProjectStatus.STATUS_ARCHIVE:
      return 4;
  }
}

export function sortByDate(a: ProjectType, b: ProjectType) {
  if (a.createdAt === b.createdAt) {
    return 0;
  } else {
    return a.createdAt < b.createdAt ? -1 : 1;
  }
}

export function sortByPositionStatus(a: ProjectType, b: ProjectType) {
  if (a.positionStatus === b.positionStatus) {
    return sortByDate(a, b);
  } else {
    return getOrDefault(a.positionStatus, Number.MAX_SAFE_INTEGER) <
      getOrDefault(b.positionStatus, Number.MAX_SAFE_INTEGER)
      ? -1
      : 1;
  }
}

export function sortByStatusAndPosition(a: ProjectType, b: ProjectType) {
  if (a.status === b.status) {
    return sortByPositionStatus(a, b);
  } else {
    return projectStatusToInt(a.status) < projectStatusToInt(b.status) ? -1 : 1;
  }
}
