import React, { useCallback, useEffect, useState } from 'react';
import { useAuthUser } from '../helpers/AuthUserProvider';
import {
  getLastEntitlementNative,
  getPurchaserInfo,
  getSubStatus,
  shouldUseStripe,
} from '../lib/SubscriptionHelper';
import Subscribe from './Subscribe';
import { EntitlementType } from '@todo/common';
import { IonLoading } from '@ionic/react';
import { AuthError } from '../lib/Errors';
import BindAccountToPurchaseButton from './BindAccountToPurchaseButton';
import SignUpAfterPurchaseButton from './SignUpAfterPurchaseButton';
import { logDebug } from '../lib/logger';
import { PurchaserInfo } from '@ionic-native/purchases';
import SubscriptionInfoInner from './SubscriptionInfoInner';

const SubscriptionInfo = () => {
  const { userInfo } = useAuthUser();
  const [entitlement, setEntitlement] = useState<EntitlementType | null | undefined>(
    undefined
  );
  const [nativePurchaserInfo, setNativePurchaserInfo] = useState<
    PurchaserInfo | null | undefined
  >(undefined);
  const [useStripe, setUseStripe] = useState<boolean | null>(null);
  const [loadedSubStatus, setLoadedSubStatus] = useState(false);
  const [failedToLoadSubStatus, setFailedToLoadSubStatus] = useState(false);

  const token = userInfo?.auth?.token;

  const refetchEntitlement = useCallback(async () => {
    // use any user id to check for active sub: either auto generated by RC, or from auth0
    const userId = userInfo?.auth?.userId || userInfo?.lastUsedUserId;
    // if (!userId) {
    //   throw Error('No known user ID!');
    // }
    // if (!userInfo?.auth?.token || !userInfo?.auth?.userId) {
    //   throw Error('No authentication data or user ID!');
    // }
    try {
      // if either auth acc or RC purchase have active entitlements, use it, or none;
      let entitlementData = null;
      if (userId) {
        entitlementData = await getSubStatus(userId);
      }
      const nativePurchaserData = await getPurchaserInfo();
      setEntitlement(entitlementData);
      setNativePurchaserInfo(nativePurchaserData);
      setFailedToLoadSubStatus(false);
    } catch (e) {
      if (e instanceof AuthError) {
        window.location.reload();
        // reloadAuthentication(true);
      } else {
        logDebug(['auth'], e.toString());
        setFailedToLoadSubStatus(true);
      }
    } finally {
      setLoadedSubStatus(true);
    }
  }, [userInfo]);

  useEffect(() => {
    (async () => {
      await refetchEntitlement();
    })();
  }, [refetchEntitlement]);

  useEffect(() => {
    (async () => {
      setUseStripe(shouldUseStripe());
      // await shouldUseStripe().then(setUseStripe);
    })();
  }, []);

  // const processSubscribe = useCallback(async () => {
  //   if (!token || !userInfo?.userId) return null;
  //
  //   const entitlementData = await subscribe({ token, userId: userInfo.userId });
  //   setUser(entitlementData);
  //   // todo later: start syncing, remove any block for syncing
  // }, [token, userInfo]);

  if (entitlement === undefined && !loadedSubStatus) {
    return <IonLoading isOpen={true} message={'loading subscription data...'} />;
  }

  if (useStripe === null) {
    return <IonLoading isOpen={true} message={'loading...'} />;
  }

  if (!loadedSubStatus) return null;

  if ((!entitlement && failedToLoadSubStatus) || (entitlement && !userInfo?.auth)) {
    const showEntitlement =
      entitlement?.entitlementId || userInfo?.auth?.activeEntitlement;
    return (
      <div>
        Failed to load subscription data, you may be offline
        {showEntitlement && (
          <div>
            Last time we checked, you had this plan: <b>{showEntitlement}</b>
          </div>
        )}
      </div>
    );
  }

  if (!useStripe && (!entitlement?.isActive || !userInfo?.auth?.activeEntitlement)) {
    if (nativePurchaserInfo) {
      const lastEntitlement = getLastEntitlementNative(nativePurchaserInfo);
      if (lastEntitlement?.isActive && !userInfo?.auth?.activeEntitlement) {
        if (token && userInfo?.auth?.userId) {
          // logInfo([], 'subscription.bind_account_to_purchase');
          // if user is auth but auth0 has no entitlement
          // this can happen if user logs in and then makes a purchase on Native
          return (
            <>
              <SubscriptionInfoInner
                useStripe={useStripe}
                entitlement={lastEntitlement}
                onUpdate={refetchEntitlement}
              />
              <BindAccountToPurchaseButton />
            </>
          );
        } else {
          return (
            <>
              <SubscriptionInfoInner
                useStripe={useStripe}
                entitlement={lastEntitlement}
                onUpdate={refetchEntitlement}
              />
              <SignUpAfterPurchaseButton />
            </>
          );
        }
      }
    }
  }

  if (!entitlement || !token || !userInfo?.auth?.userId)
    return <Subscribe onUpdate={refetchEntitlement} />;

  return (
    <div>
      {userInfo.auth?.userEmail !== userInfo.auth?.userName
        ? userInfo.auth?.userName
        : ''}{' '}
      ({userInfo.auth?.userEmail})
      <SubscriptionInfoInner
        useStripe={useStripe}
        entitlement={entitlement}
        onUpdate={refetchEntitlement}
      />
    </div>
  );
};

export default SubscriptionInfo;
