import React from 'react';
import { IonModal } from '@ionic/react';
import { ModalOptions } from '@ionic/core';
import { ReactOverlayProps } from '@ionic/react/dist/types/components/createOverlayComponent';
import { omit } from 'lodash';
import { ModalsProps, withModalsState } from './ModalsProvider';

type Props = Pick<
  ModalOptions<import('@ionic/core').ComponentRef>,
  | 'mode'
  | 'animated'
  | 'id'
  | 'cssClass'
  | 'backdropDismiss'
  | 'keyboardClose'
  | 'enterAnimation'
  | 'leaveAnimation'
  | 'showBackdrop'
  | 'presentingElement'
  | 'delegate'
  | 'swipeToClose'
> &
  ReactOverlayProps &
  ModalsProps;

// todo: if count doesn't work, generate uuid for modal, and report it
class MyIonModal extends React.Component<Props> {
  componentWillUnmount() {
    if (this.props.isOpen) {
      this.props.recordModalOpen(false);
    }
  }

  componentDidUpdate(prevProps: Props) {
    // special handling for Always Open modal
    // if path or context changed
    if (this.props.isOpen !== prevProps.isOpen) {
      // console.log('$$$ componentDidUpdate', prevProps, this.props);
      this.props.recordModalOpen(this.props.isOpen);
    }
  }

  render() {
    return (
      <IonModal {...omit(this.props, 'children')}>{this.props.children}</IonModal>
    );
  }
}

export default withModalsState(MyIonModal);
