import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { TaskStatus } from '../../types/CoreTypes';
import SingleSelect from '../Common/SingleSelect';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import { getTaskStatusLabel } from '../../helpers/CopyHelper';

interface BaseProps {
  show: boolean;
  state: TaskStatus | undefined;
  onClose: () => void;
  onSave: (status: TaskStatus | undefined) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

const states = Object.values(TaskStatus)
  .filter((t) => t !== TaskStatus.STATUS_SCHEDULED)
  .map((t) => ({
    value: t,
    label: getTaskStatusLabel(t),
  }));

const EditStateModal = ({ onClose, onSave, state, show, forwardRef }: Props) => {
  const [selectedStateId, setSelectedStateId] = useState<TaskStatus | undefined>(
    undefined
  );
  useEffect(() => {
    setSelectedStateId(state);
  }, [state, show]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedStateId(undefined);
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave(selectedStateId);
    handleClose();
  }, [handleClose, onSave, selectedStateId]);

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title="Choose a status"
    >
      <SingleSelect
        ref={forwardRef}
        value={selectedStateId}
        placeholder="State"
        options={states || []}
        onChange={(s) => setSelectedStateId(s as TaskStatus | undefined)}
      />
    </PropertyEditModalWrapper>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <EditStateModal forwardRef={ref} {...props} />
));
