// @ts-ignore
import nearestColor from 'nearest-color';

/**
 * https://medialab.github.io/iwanthue/
 * Other:
 * https://graphicdesign.stackexchange.com/questions/3682/where-can-i-find-a-large-palette-set-of-contrasting-colors-for-coloring-many-d
 * https://stackoverflow.com/questions/33295120/how-to-generate-gif-256-colors-palette/33295456#33295456
 * http://phrogz.net/tmp/24colors.html
 */

export const colors = [
  '#9c1f49',
  '#ff5f87',
  '#ff9bab',
  '#c3004d',
  '#ff4c6b',
  '#91313e',
  '#da0040',
  '#ff484f',
  '#faafa8',
  '#b10020',
  '#ff9589',
  '#c98579',
  '#be0e0e',
  '#972d1d',
  '#7c4332',
  '#ff8c45',
  '#ffb387',
  '#d26c00',
  '#c69670',
  '#ffaa51',
  '#b16700',
  '#f1bc8b',
  '#a48100',
  '#e7c34a',
  '#5f530b',
  '#cacd3f',
  '#8c9c00',
  '#b5d23f',
  '#485905',
  '#547600',
  '#a0d531',
  '#81b904',
  '#b0d17b',
  '#00730c',
  '#2e5d33',
  '#00e37c',
  '#01ab5e',
  '#8ad7a3',
  '#017c4b',
  '#7ed6c1',
  '#02d0b2',
  '#00dcde',
  '#48baff',
  '#015c90',
  '#008ce7',
  '#007dff',
  '#959eff',
  '#4b57de',
  '#aa7dff',
  '#782ba8',
  '#793788',
  '#da68ee',
  '#8b1d95',
  '#f8aef2',
  '#892d7a',
  '#ff83e0',
  '#c088af',
  '#dc38b6',
  '#962360',
  '#ff87b8',
];

export function changeColorLightness(colorStr: string, lightness: number): string {
  const color = rgbToInt(colorStr);
  const newColor =
    Math.max(0, Math.min((color & 0xff0000) / 0x10000 + lightness, 0xff)) * 0x10000 +
    Math.max(0, Math.min((color & 0x00ff00) / 0x100 + lightness, 0xff)) * 0x100 +
    Math.max(0, Math.min((color & 0x0000ff) + lightness, 0xff));
  return intToRGB(newColor);
}

function hashCode(str: string) {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i: number) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '#' + '00000'.substring(0, 6 - c.length) + c;
}

function rgbToInt(colorStr: string) {
  return parseInt(colorStr.replace('#', ''), 16);
}

const colorFinder = nearestColor.from(colors);

export function generateColorFromString(str: string) {
  const rgb = intToRGB(hashCode(str));
  return colorFinder(rgb);
}
