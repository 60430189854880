import {
  BlockType,
  ContextType,
  ItemType,
  MyDB,
  ProjectPositionContextEnum,
  ProjectType,
  RelationFields,
  TagType,
  TaskPositionContextEnum,
  TaskType,
} from '../../types/CoreTypes';
import {
  getProjectPositionKey,
  getTaskPositionKey,
} from '../../helpers/PositionHelper';
import {
  BelongsToManyRelation,
  BelongsToRelation,
  BlockCounterEnrichment,
  Model,
  ModelTypeConstraints,
  sortByPosition,
  sortByPositionSimple,
  sortByPositionTask,
  TaskCounterEnrichment,
} from './ModelTooling';
import taskSchema from './schemas/taskSchema';
import projectSchema from './schemas/projectSchema';
import contextSchema from './schemas/contextSchema';
import blockSchema from './schemas/blockSchema';
import tagSchema from './schemas/tagSchema';
import settingsSchema from './schemas/settingsSchema';
import { AchievementType } from '@todo/common';
import achievementSchema from './schemas/achievementSchema';

// todo https://github.com/rafamel/rxdb-utils

// const MigrationCopyAsIs = function (oldDoc: any) {
//   return oldDoc;
// };

const pouchSettings = {
  // Specify how many old revisions we keep track (not a copy) of. Specifying a
  // low value means Pouch may not be able to figure out whether a new revision
  // received via replication is related to any it currently has which could result
  // in a conflict. Defaults to 1000
  // revs_limit: 500,

  // This turns on auto compaction, which means compact() is called after every
  // change to the database
  auto_compaction: true,
};

export class SettingsModelClass {
  readonly type = ItemType.settings;

  initCollection(localDb: MyDB) {
    return localDb.collection({
      name: ItemType.settings,
      schema: settingsSchema,
      autoMigrate: false,
      migrationStrategies: {},
      pouchSettings: pouchSettings,
    });
  }
}
export const SettingsModel = new SettingsModelClass();

class ContextModelClass extends Model<ContextType, undefined> {
  readonly type = ItemType.context;
  defaultSort = sortByPositionSimple<ContextType>('position', 'asc');

  initCollection(localDb: MyDB) {
    return localDb.collection({
      name: ItemType.context,
      schema: contextSchema,
      autoMigrate: false,
      // add a key with a fn describing how to migrate to the new version
      migrationStrategies: {
        // 1: MigrationCopyAsIs,
      },
      pouchSettings: pouchSettings,
    });
  }
}
export const ContextModel = new ContextModelClass();

type BTRProject = BelongsToRelation<
  ModelTypeConstraints,
  ProjectType,
  RelationFields.DependsOnProject
>;

class ProjectModelClass extends Model<ProjectType, ProjectPositionContextEnum> {
  defaultSort = sortByPosition(getProjectPositionKey);

  readonly type = ItemType.project;

  readonly taskCounterEnrichment = new TaskCounterEnrichment();

  readonly contextRelation = new BelongsToRelation(
    this,
    ContextModel,
    'contextId',
    RelationFields.Context
  );

  private dependsOnProjectRelation?: BTRProject;

  getDependsOnProjectRelation(): BTRProject {
    if (!this.dependsOnProjectRelation) {
      this.dependsOnProjectRelation = new BelongsToRelation(
        this,
        ProjectModel,
        'dependsOnProjectId',
        RelationFields.DependsOnProject
      );
    }
    return this.dependsOnProjectRelation;
  }

  initCollection(localDb: MyDB) {
    return localDb.collection({
      name: ItemType.project,
      schema: projectSchema,
      autoMigrate: false,
      // add a key with a fn describing how to migrate to the new version
      migrationStrategies: {
        // 1: MigrationCopyAsIs,
      },
      pouchSettings: pouchSettings,
    });
  }
}
export const ProjectModel = new ProjectModelClass();

type BTR = BelongsToRelation<ModelTypeConstraints, TagType, RelationFields.Parent>;

class TagModelClass extends Model<TagType, undefined> {
  readonly type = ItemType.tag;
  defaultSort = sortByPositionSimple<ContextType>('position', 'asc');

  private tagRelation?: BTR;

  getTagRelation(): BTR {
    if (!this.tagRelation) {
      this.tagRelation = new BelongsToRelation(
        this,
        TagModel,
        'parentId',
        RelationFields.Parent
      );
    }
    return this.tagRelation;
  }

  initCollection(localDb: MyDB) {
    return localDb.collection({
      name: ItemType.tag,
      schema: tagSchema,
      autoMigrate: false,
      // add a key with a fn describing how to migrate to the new version
      migrationStrategies: {
        // 1 means, this transforms data from version 0 to version 1
        // 1: MigrationCopyAsIs,
      },
      pouchSettings: pouchSettings,
    });
  }
}
export const TagModel = new TagModelClass();

class TaskModelClass extends Model<TaskType, TaskPositionContextEnum> {
  defaultSort = sortByPositionTask(getTaskPositionKey);

  readonly type = ItemType.task;

  readonly projectRelation = new BelongsToRelation(
    this,
    ProjectModel,
    'projectId',
    RelationFields.Project
  );

  readonly tagRelation = new BelongsToManyRelation<
    TaskType,
    TagType,
    RelationFields.Tags
  >(this, TagModel, 'tags', RelationFields.Tags);

  readonly blockCounterEnrichment = new BlockCounterEnrichment();

  readonly contextRelation = new BelongsToRelation(
    this,
    ContextModel,
    'contextId',
    RelationFields.Context
  );

  initCollection(localDb: MyDB) {
    return localDb.collection({
      name: ItemType.task,
      schema: taskSchema,
      autoMigrate: false,
      // add a key with a fn describing how to migrate to the new version
      migrationStrategies: {
        // 1 means, this transforms data from version 0 to version 1
        // 1: MigrationCopyAsIs,
      },
      pouchSettings: pouchSettings,
    });
  }
}
export const TaskModel = new TaskModelClass();

class BlockModelClass extends Model<BlockType, undefined> {
  defaultSort = sortByPositionSimple<BlockType>('position', 'asc');

  readonly type = ItemType.block;

  readonly projectRelation = new BelongsToRelation(
    this,
    ProjectModel,
    'projectId',
    RelationFields.Project
  );

  readonly taskRelation = new BelongsToRelation(
    this,
    TaskModel,
    'taskId',
    RelationFields.Task
  );

  initCollection(localDb: MyDB) {
    return localDb.collection({
      name: ItemType.block,
      schema: blockSchema,
      autoMigrate: false,
      migrationStrategies: {
        // 1 means, this transforms data from version 0 to version 1
        // 1: MigrationCopyAsIs,
      },
      pouchSettings: pouchSettings,
    });
  }
}
export const BlockModel = new BlockModelClass();

class AchievementModelClass extends Model<AchievementType, undefined> {
  defaultSort = sortByPositionSimple<AchievementType>('createdAt', 'desc');

  readonly type = ItemType.achievement;

  // readonly projectRelation = new BelongsToRelation(
  //   this,
  //   ProjectModel,
  //   'projectId',
  //   RelationFields.Project
  // );
  //
  // readonly taskRelation = new BelongsToRelation(
  //   this,
  //   TaskModel,
  //   'taskId',
  //   RelationFields.Task
  // );

  initCollection(localDb: MyDB) {
    return localDb.collection({
      name: ItemType.achievement,
      schema: achievementSchema,
      autoMigrate: false,
      migrationStrategies: {
        // 1 means, this transforms data from version 0 to version 1
        // 1: MigrationCopyAsIs,
      },
      pouchSettings: pouchSettings,
    });
  }
}
export const AchievementModel = new AchievementModelClass();
