import React from 'react';
import { useAuth0 } from '../Auth0ReactFork';
import SubscriptionInfo from './SubscriptionInfo';

const UserProfile = () => {
  const { isLoading } = useAuth0();
  // const { isAuthenticated, isLoading, error } = useAuth0();
  // const { userInfo } = useAuthUser();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return <SubscriptionInfo />;
  // return isAuthenticated ||
  //   (error && userInfo?.wasAuthenticated && userInfo?.lastUsedUserId) ? (
  //   <div>
  //     <SubscriptionInfo />
  //   </div>
  // ) : (
  //   <Subscribe />
  // );
};

export default UserProfile;
