import { pullQueryBuilder } from './pullReplication';
import { pushQueryBuilder } from './pushReplication';
import collectionsConfig from './collectionsConfig';
import { Auth, Dict, MyDatabaseCollections, SyncEvent } from '../../types/CoreTypes';
// import { decryptDoc, encryptDoc } from '../encryptDoc';
import { getEnvVarOrDie } from '../EnvManager';
import { logDebug, logWarn } from '../logger';
import {
  RxGraphQLReplicationState,
  SyncOptionsGraphQL,
} from './replication-graphql';
import { RxDatabase } from 'rxdb/dist/types/core';

const syncURL = getEnvVarOrDie('REACT_APP_REPLICATION_URL');

// export interface ReplicationState {
//   replicationState: RxGraphQLReplicationState;
//   // subscriptionQuery: string;
//   tableName: string;
// }

function skipNull(doc: Dict): Dict {
  const copy = Object.assign({}, doc);
  for (const propName in copy) {
    if (copy[propName] === null || copy[propName] === undefined) {
      delete copy[propName];
    }
  }
  return copy;
}

function ensureRev(doc: Dict): Dict {
  const copy = Object.assign({}, doc);
  if (!copy['_rev']) copy['_rev'] = '1-0';
  if (!copy['_revisions'])
    copy['_revisions'] = {
      ids: ['0'],
      start: 1,
    };
  return copy;
}

// type MakesAllRequired<Z> = (x: Z) => x is Required<Z>;
const RELOGIN_THRESHOLD_MS = 60 * 1000;
const MAX_SEQUENTIAL_AUTH_ERRORS = 3;

export type ExtendedDbType<T = MyDatabaseCollections> = RxDatabase<T> & {
  syncGraphQL: (opts: SyncOptionsGraphQL) => RxGraphQLReplicationState;
};

export interface GraphQLReplicatorArgs {
  db: ExtendedDbType;
  auth: Auth;
  userId: string;
  deviceId: string;
  setSyncStatus: (event: SyncEvent) => void;
  reLogIn: () => void;
  isNative: boolean;
}

class LimitedSeqList<K> {
  private list: K[] = [];
  private readonly limit: number;

  constructor(limit: number) {
    this.limit = limit;
  }

  add(_type: K) {
    this.list.push(_type);
    while (this.list.length > this.limit) {
      this.list.shift();
    }
  }

  getLastSequenceLenAndType() {
    let _type: K | null = null;
    let len = 0;
    for (let i = this.list.length - 1; i >= 0; i--) {
      const elt = this.list[i];
      if (_type === null) {
        _type = elt;
      }
      if (elt === _type) {
        len++;
      } else {
        break;
      }
    }
    return {
      _type,
      len,
    };
  }
}

enum SyncType {
  auth_error,
  error,
  success,
}

export class GraphQLReplicator {
  private replicationState?: RxGraphQLReplicationState;
  private readonly subscriptionClient: any;

  private readonly db: ExtendedDbType;
  private readonly auth: Auth;
  private readonly userId: string;
  private readonly deviceId: string;
  private readonly setSyncStatus: (event: SyncEvent) => void;
  private readonly reLogIn: () => void;
  private readonly isNative: boolean;

  constructor({
    db,
    auth,
    userId,
    deviceId,
    setSyncStatus,
    reLogIn,
    isNative,
  }: GraphQLReplicatorArgs) {
    // this.db = db;
    // this.replicationStates = [];
    this.subscriptionClient = null;

    this.db = db;
    this.auth = auth;
    this.userId = userId;
    this.deviceId = deviceId;
    this.setSyncStatus = setSyncStatus;
    this.reLogIn = reLogIn;
    this.isNative = isNative;
  }

  async stop() {
    logDebug(['sync'], 'stopping replication');

    // window.removeEventListener('unhandledrejection', this.handleGlobalError);
    if (this.replicationState) {
      const success = await this.replicationState.cancel();
      logDebug(['sync'], 'stopping replication: did stop replicationState', {
        success,
      });
    }
    if (this.subscriptionClient) {
      this.subscriptionClient.close();
    }
    logDebug(['sync'], 'stopping replication done!');
  }

  async start(): Promise<RxGraphQLReplicationState> {
    await this.stop();

    logDebug(['sync'], 'starting replication');

    // window.addEventListener('unhandledrejection', this.handleGlobalError);
    return await this.setupGraphQLReplication();
  }

  private static lastTriedToReLogin = 0;

  private lastSeqList = new LimitedSeqList<SyncType>(5);

  // private handleGlobalError = (err: PromiseRejectionEvent) => {
  //   const stack = err.reason?.stack?.toString() || '';
  //   if (
  //     stack.includes('Cannot convert undefined or null to object') ||
  //     stack.includes('RxGraphQLReplicationState') ||
  //     stack.includes('asyncToGenerator')
  //   ) {
  //     logWarn(['sync'], 'RxGraphQL promise rejection', { err });
  //     const now = new Date().getTime();
  //     if (now - GraphQLReplicator.lastTriedToReLogin > RELOGIN_THRESHOLD_MS) {
  //       logWarn(['sync'], 'RxGraphQL will relogin');
  //       GraphQLReplicator.lastTriedToReLogin = now;
  //       this.stop();
  //       this.reLogIn();
  //     } else {
  //       this.setSyncStatus(SyncEvent.Error);
  //       throw err;
  //     }
  //   }
  // };

  private handleAuthError = async (err: Error) => {
    const lastSync = this.lastSeqList.getLastSequenceLenAndType();
    if (
      lastSync._type === SyncType.auth_error &&
      lastSync.len >= MAX_SEQUENTIAL_AUTH_ERRORS
    ) {
      const now = new Date().getTime();
      if (now - GraphQLReplicator.lastTriedToReLogin > RELOGIN_THRESHOLD_MS) {
        logWarn(['sync'], 'RxGraphQL will relogin');
        GraphQLReplicator.lastTriedToReLogin = now;
        await this.stop();
        this.reLogIn();
      } else {
        this.setSyncStatus(SyncEvent.Error);
        console.error('ReLogin attempts exhausted!');
        logWarn(['sync'], 'ReLogin attempts exhausted!', { err });
        // await this.stop();
      }
    } else {
      logWarn(['sync'], 'Auth error, retry', { err, lastSync });
    }
  };

  private getLiveIntervalWithSub() {
    /**
     * Because the websocket is used to inform the client
     * when something has changed,
     * we can set the liveIntervall to a high value
     */
    return 1000 * 60 * 10; // 10 minutes;
  }

  private getLiveIntervalSimple() {
    return 1000 * 20; // 20 sec;
  }

  private async setupGraphQLReplication() {
    const collectionNames = collectionsConfig
      .filter((v) => v.sync)
      .map((v) => v.name);

    this.replicationState = this.db.syncGraphQL({
      collectionNames,
      url: syncURL,
      headers: {
        Authorization: `Bearer ${this.auth.token}`,
      },
      push: {
        batchSize: 10,
        queryBuilder: pushQueryBuilder(this.userId, this.deviceId),
        // modifier: encryptDoc(this.auth.encryptionPassword),
      },
      pull: {
        queryBuilder: pullQueryBuilder(this.userId, this.deviceId),
        modifier: (d: any) => ensureRev(skipNull(d)),
      },
      live: true,
      liveInterval: this.getLiveIntervalSimple(),
      deletedFlag: 'deleted',
      tsField: 'serverUpdatedAt',
      syncRevisions: true,
      waitForLeadership: !this.isNative,
    });

    this.replicationState.error$.subscribe((err: Error) => {
      console.error('replicationState error:', err);
      const errMessage = err.toString().toLowerCase();
      /*
      "TypeError: Failed to fetch"
      "TypeError: Could not connect to the server."
       */
      if (errMessage.includes('fetch') || errMessage.includes('connect')) {
        this.setSyncStatus(SyncEvent.Offline);
      } else if (
        errMessage.includes('unauthorized') ||
        errMessage.includes('authenticated')
      ) {
        // this.setSyncStatus(SyncEvent.NoSyncError);
        this.lastSeqList.add(SyncType.auth_error);
        this.handleAuthError(err);
      } else {
        this.lastSeqList.add(SyncType.error);
        this.setSyncStatus(SyncEvent.Error);
        throw err;
      }
    });

    this.replicationState.successfulPull$.subscribe((a: any) => {
      this.lastSeqList.add(SyncType.success);
      this.setSyncStatus(SyncEvent.Active);
    });

    this.replicationState.send$.subscribe((a: any) => {
      this.lastSeqList.add(SyncType.success);
    });

    return this.replicationState;
  }

  // setupGraphQLSubscription(auth: string, replicationStates: ReplicationState[]) {
  //   const wsClient = new SubscriptionClient(syncURL, {
  //     reconnect: true,
  //     connectionParams: {
  //       headers: {
  //         Authorization: `Bearer ${auth}`
  //       }
  //     },
  //     timeout: 1000 * 60,
  //     // @ts-ignore
  //     onConnect: () => {
  //       console.log('SubscriptionClient.onConnect()');
  //     },
  //     connectionCallback: () => {
  //       console.log('SubscriptionClient.connectionCallback:');
  //     },
  //     reconnectionAttempts: 10000,
  //     inactivityTimeout: 10 * 1000,
  //     lazy: true
  //   });
  //
  //   replicationStates.forEach(rs => {
  //     const ret = wsClient.request({ query: rs.subscriptionQuery });
  //
  //     ret.subscribe({
  //       next(data) {
  //         console.log('subscription emitted => trigger run');
  //         console.dir(data);
  //         rs.replicationState.run();
  //       },
  //       error(error) {
  //         console.log('got error:');
  //         console.dir(error);
  //       }
  //     });
  //   });
  //
  //   return wsClient;
  // }
}

// export const graphQLReplicator = GraphQLReplicator.getInstance();
