import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { IonIcon, IonItem, IonLabel, IonReorder, IonRouterLink } from '@ionic/react';
import { create, reorderFourOutline, trash } from 'ionicons/icons';
import { genericStyles } from '../../helpers/styles';
import { useHistory } from 'react-router';
import { ContextType } from '../../types/CoreTypes';
import { TableCell } from '../Common/TableCell';
import deepEqual from 'fast-deep-equal';
import { logDebug } from '../../lib/logger';

interface Props {
  context: ContextType;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  manualReorder?: (up: boolean) => Promise<boolean>;
  ref?: any;
  onDelete?: (id: string) => void;
}

// eslint-disable-next-line react/display-name
const ContextListRowDesktop: React.FunctionComponent<Props> = forwardRef<any, Props>(
  (props: Props, ref) => {
    const { context, isSelected, onAfterNavigate, onDelete } = props;

    const history = useHistory();
    const nodeRef = useRef<any>(null);

    const editUrl = `/contexts/edit/${context.id}`;

    useImperativeHandle(ref, () => ({
      impress() {
        history.push(editUrl, { direction: 'root' });
      },
      scrollIntoView() {
        nodeRef.current &&
          nodeRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      },
      canMove(): boolean {
        return props.manualReorder !== undefined;
      },
      async moveDown(): Promise<boolean | undefined> {
        if (props.manualReorder) {
          return await props.manualReorder(false);
        }
      },
      async moveUp(): Promise<boolean | undefined> {
        if (props.manualReorder) {
          return await props.manualReorder(true);
        }
      },
      getHandlers() {
        return undefined;
      },
    }));

    logDebug([], 'refresh: context row render');

    return (
      <IonItem ref={nodeRef} color={isSelected ? 'light' : undefined}>
        <IonReorder slot="start" style={{ marginRight: 10 }}>
          <IonIcon color="medium" icon={reorderFourOutline} />
        </IonReorder>
        <IonLabel className={'list-desktop context-list-row list-row'}>
          <TableCell className="table-cell-icon">
            <IonRouterLink
              routerLink={editUrl}
              routerDirection={'root'}
              onClick={onAfterNavigate}
              color="medium"
            >
              <IonIcon style={genericStyles.icon} icon={create} />
            </IonRouterLink>
          </TableCell>

          <TableCell className="table-cell-name" takeRemainingSpace>
            {context.name}
          </TableCell>

          {onDelete && (
            <TableCell className="table-cell-icon">
              <IonIcon
                color="danger"
                style={genericStyles.icon}
                icon={trash}
                onClick={() => onDelete(props.context.id)}
              />
            </TableCell>
          )}
        </IonLabel>
      </IonItem>
    );
  }
);

const Memoized = React.memo(ContextListRowDesktop, deepEqual);

export default Memoized;
