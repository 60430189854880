import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  OptionGroup,
  OptionTypeWithColor,
  TagAssignment,
} from '../../types/CoreTypes';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import MultiSelect from '../Common/MultiSelect';
import { first, groupBy } from 'lodash';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import { getIconById } from '../../helpers/icons';

interface BaseProps {
  show: boolean;
  tags: TagAssignment[] | undefined;
  onClose: () => void;
  onSave: (tags: TagAssignment[] | undefined) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

const EditTagsModal = ({ onSave, onClose, show, tags, forwardRef }: Props) => {
  const [allTags, setAllTags] = useState<
    OptionGroup<OptionTypeWithColor>[] | undefined
  >(undefined);
  const [selectedTags, setSelectedTags] = useState<string[] | undefined>(undefined);

  const {
    repos: { tagRepo },
  } = useStorage();
  const { context } = useWorkspace();

  useEffect(() => {
    const sub = tagRepo.getAllForFilter$(context.id, {}).subscribe((tags) => {
      const grouped = groupBy(tags, (t) => t.parentId);
      const groupedTags = Object.values(grouped).map((g) => {
        const parentId = first(g)?.parentId;
        let parent;
        if (parentId) {
          parent = first(tags.filter((t) => t.id === parentId));
        }

        return {
          label: parent ? parent.name : 'top level tags',
          options: g.map((tag) => ({
            value: tag.id,
            label: tag.name,
            color: tag.color,
            icon: getIconById(tag.icon),
          })),
        };
      });
      setAllTags(groupedTags);
    });
    return () => sub.unsubscribe();
  }, [context.id, tagRepo]);

  useEffect(() => {
    setSelectedTags(tags?.map((t) => t.id));
  }, [tags, show]);

  // const selectRef = useRef<any>(null);

  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => {
  //       selectRef.current && selectRef.current.focus();
  //     }, 200);
  //   }
  //   // if doesn't work, consider waiting for allTags to load!
  // }, [show]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedTags(undefined);
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave(
      selectedTags?.map((nt) => {
        // preserve position data for the old tag. And create new TagAssignment for new tags
        const existing = first(tags?.filter((t) => t.id === nt));
        if (existing) return existing;
        return { id: nt };
      })
    );
    handleClose();
  }, [handleClose, onSave, tags, selectedTags]);

  const handleCreateNewTag = useCallback(
    (newTagName: string) => {
      tagRepo.create({ name: newTagName, contextId: context.id }).then((id) => {
        setSelectedTags([...(selectedTags || []), id]);
      });
    },
    [context.id, selectedTags, tagRepo]
  );

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title="Edit tags"
      klass="edit-tags"
    >
      <MultiSelect
        ref={forwardRef}
        values={selectedTags}
        placeholder="Select or create tags"
        options={allTags || []}
        onChange={setSelectedTags}
        onCreate={handleCreateNewTag}
      />
    </PropertyEditModalWrapper>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <EditTagsModal forwardRef={ref} {...props} />
));
