import React from 'react';
import { ProjectEnrichedWithTaskStats } from '../../types/CoreTypes';
import { genericStyles } from '../../helpers/styles';
import Tooltip from 'rc-tooltip';
import { formatTime } from '../../lib/DateHelper';

interface Props {
  project: ProjectEnrichedWithTaskStats;
}

function getColor(time: number) {
  if (time <= 0) {
    return genericStyles.grayOutColor;
  }
  return genericStyles.primaryColor;
}

function getUnpointedColor(value: number) {
  if (value <= 0) {
    return genericStyles.grayOutColor;
  }
  return genericStyles.dangerColor;
}

const ProjectTimeWidget = ({ project }: Props) => {
  return (
    <span style={{ display: 'flex' }}>
      <Tooltip
        destroyTooltipOnHide
        placement="top"
        trigger={['hover', 'click']}
        overlay={
          <div style={{ width: '120px', height: '40px' }}>
            Summary time of all remaining tasks
          </div>
        }
      >
        <span
          style={{
            ...genericStyles.property,
            ...getColor(project.remainingTime),
            width: 32,
          }}
        >
          {formatTime(project.remainingTime)}
        </span>
      </Tooltip>

      <Tooltip
        destroyTooltipOnHide
        placement="top"
        trigger={['hover', 'click']}
        overlay={
          <div style={{ width: '130px', height: '50px' }}>
            {project.unpointedTime + project.unpointedEnergy > 0 && (
              <span>
                some tasks have missing data:
                <br />
                {project.unpointedTime > 0 && (
                  <span> time: {project.unpointedTime}</span>
                )}
                {project.unpointedEnergy > 0 && (
                  <span> energy: {project.unpointedEnergy}</span>
                )}
              </span>
            )}
            {project.unpointedTime + project.unpointedEnergy === 0 && (
              <span>
                All tasks in this project have time and energy specified. Great job!
              </span>
            )}
          </div>
        }
      >
        <span
          style={{
            ...genericStyles.property,
            marginLeft: 10,
            width: 54,
          }}
        >
          <span
            style={{
              ...getUnpointedColor(project.unpointedTime),
            }}
          >
            T{project.unpointedTime}
          </span>{' '}
          |{' '}
          <span
            style={{
              ...getUnpointedColor(project.unpointedEnergy),
            }}
          >
            E{project.unpointedEnergy}
          </span>
        </span>
      </Tooltip>
    </span>
  );
};

export default ProjectTimeWidget;
