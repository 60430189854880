import React from 'react';
import { SearchResults } from '../types/CoreTypes';
import { withStorage } from '../helpers/StorageProviders';
import { StorageDeps } from '../helpers/InitStorage';
import ListNavigation from './Common/ListNavigation';
import SearchResultsListView, { collections } from './SearchResultsListView';

interface Props extends StorageDeps {
  results: SearchResults;
  onClose: () => void;
}

interface State {
  selectedIdx: number;
}

class SearchResultsList extends React.PureComponent<Props, State> {
  render() {
    const { results, onClose } = this.props;

    return (
      <ListNavigation
        onAfterNavigate={onClose}
        useSecondLevelModal
        collections={collections}
      >
        {(listNavigationApi) => (
          <SearchResultsListView
            listNavigationApi={listNavigationApi}
            onClose={onClose}
            results={results}
          />
        )}
      </ListNavigation>
    );
  }
}

export default withStorage(SearchResultsList);
