// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import React from 'react';
import { withModalsState } from '../../helpers/ModalsProvider';
import { withWorkspace } from '../../helpers/WorkspaceProviders';
import { withMagicHotkey } from '../../helpers/MagicHotkeyProvider';
import { ListNavigationBase } from './ListNavigationCommon';
import { withClientType } from '../../helpers/ClientTypeProvider';

export default withClientType(
  withMagicHotkey(withWorkspace(withModalsState(ListNavigationBase)))
);
