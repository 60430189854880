import React, { createContext, useContext } from 'react';
import { CustomAppEvent } from '../types/CoreTypes';
import { logDebug } from '../lib/logger';

// @ts-ignore
export const EventContext = createContext<EventProps>(undefined);

interface Props {
  children: any;
}

export interface EventProps {
  emitEvent: (event: CustomAppEvent) => void;
  registerListener: (
    fn: (event: CustomAppEvent) => void,
    events: CustomAppEvent[]
  ) => void;
  removeListener: (fn: (event: CustomAppEvent) => void) => void;
}

export class EventProvider extends React.Component<Props> {
  private listeners: Map<
    CustomAppEvent,
    Set<(event: CustomAppEvent) => void>
  > = new Map();

  emitEventHandler = (event: CustomAppEvent) => {
    const listeners = this.listeners.get(event);
    if (listeners) {
      listeners.forEach((listener) => {
        listener(event);
      });
    }
  };

  registerListenerHandler = (
    fn: (event: CustomAppEvent) => void,
    events: CustomAppEvent[]
  ) => {
    logDebug([], 'EventProvider: register listener');
    events.forEach((event) => {
      const current = this.listeners.get(event) || new Set();
      current.add(fn);
      this.listeners.set(event, current);
    });
  };

  removeListenerHandler = (fn: (event: CustomAppEvent) => void) => {
    logDebug([], 'EventProvider: remove listener');
    this.listeners.forEach((listeners) => {
      if (listeners.has(fn)) {
        const result = listeners.delete(fn);
        if (result) {
          logDebug([], 'removed cb');
        } else {
          logDebug([], 'failed to remove cb');
        }
      }
    });
  };

  render() {
    const { children } = this.props;
    return (
      <EventContext.Provider
        value={{
          emitEvent: this.emitEventHandler,
          registerListener: this.registerListenerHandler,
          removeListener: this.removeListenerHandler,
        }}
      >
        {children}
      </EventContext.Provider>
    );
  }
}

export const useEvent: () => EventProps = () => useContext(EventContext);

export const withEvent = <P extends Partial<EventProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof EventProps>> => {
  return function withEventContext(props) {
    return (
      <EventContext.Consumer>
        {(eventProps: EventProps) => <Component {...(props as P)} {...eventProps} />}
      </EventContext.Consumer>
    );
  };
};
