import { Priority } from '../types/CoreTypes';
import { CSSProperties } from 'react';

export const colors = {
  primary: '#3880ff',
  secondary: '#0cd1e8',
  tertiary: '#7044ff',
  grayOut: '#92949c',
  orange: '#f2704e',
  danger: '#d33939',
  warning: '#ffce00',
  green: '#0ec254',
  white: '#fff',
};

export const genericStyles = {
  chipWrapper: {
    whiteSpace: 'normal',
    display: 'flex',
  } as CSSProperties,
  chip: {
    fontSize: '10px',
    height: 'auto',
    padding: '5px 10px',
    margin: '3px',
  } as CSSProperties,
  icon: {
    fontSize: '17px',
    verticalAlign: 'middle',
  } as CSSProperties,
  iconLabel: {
    verticalAlign: 'middle',
  } as CSSProperties,
  primaryColor: { color: colors.primary } as CSSProperties,
  secondaryColor: { color: colors.secondary } as CSSProperties,
  tertiaryColor: { color: colors.tertiary } as CSSProperties,
  grayOutColor: { color: colors.grayOut } as CSSProperties,
  orangeColor: { color: colors.orange } as CSSProperties,
  dangerColor: { color: colors.danger } as CSSProperties,
  warningColor: { color: colors.warning } as CSSProperties,
  greenColor: { color: colors.green } as CSSProperties,
  listFieldBasic: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'auto',
    marginRight: '5px',
  } as CSSProperties,
  listFieldSpaceOut: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'auto',
    margin: '5px',
  } as CSSProperties,
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  } as CSSProperties,
  listMainField: {
    fontSize: '14px',
  } as CSSProperties,
  listField: {
    fontSize: '12px',
    cursor: 'pointer',
  } as CSSProperties,
  propertyClickable: {
    fontSize: '12px',
    cursor: 'pointer',
  } as CSSProperties,
  property: {
    fontSize: '12px',
  } as CSSProperties,
  clickable: {
    cursor: 'pointer',
  } as CSSProperties,
};

export const desktopListStyles = {
  listRow: {
    display: 'flex',
    margin: 0,
  } as CSSProperties,
};

export const mobileListStyles = {
  listRow: {
    overflow: 'hidden',
    display: 'flex',
    margin: 0,
    padding: '1px 0',
  },
};

// deprecated
export const mapPriorityToColor = new Map([
  [Priority.PRIORITY_LOW, 'tertiary'],
  [Priority.PRIORITY_MEDIUM, 'warning'],
  [Priority.PRIORITY_HIGH, 'danger'],
]);
