import React from 'react';
import {
  BlockType,
  Nullable,
  TaskEnrichedWithBlockStats,
} from '../../types/CoreTypes';
import { colors, genericStyles } from '../../helpers/styles';
import { IonIcon } from '@ionic/react';
import { document } from 'ionicons/icons';
import ProgressIcon from '../Common/ProgressIcon';
import Tooltip from 'rc-tooltip';

export const getCheckboxStats = (blocks: Nullable<BlockType[]>) => {
  const def = { total: 0, completed: 0 };
  if (!blocks) return def;
  return blocks
    .filter((b) => b.type === 'checkbox')
    .reduce(
      ({ total, completed }, b) => ({
        total: total + 1,
        completed: completed + (b.completed ? 1 : 0),
      }),
      def
    );
};

interface Props {
  task: TaskEnrichedWithBlockStats;
}

const TaskDetailsIcon = ({ task }: Props) => {
  if (task.anyBlocks) {
    if (task.totalCheckboxes) {
      const remainingCheckboxes = task.totalCheckboxes - task.completedCheckboxes;
      return (
        <ProgressIcon
          color={colors.primary}
          percentage={(task.completedCheckboxes / task.totalCheckboxes) * 100}
          text={remainingCheckboxes}
          width={30}
        />
      );
    } else {
      return (
        <Tooltip
          destroyTooltipOnHide
          placement="top"
          trigger={['hover', 'click']}
          overlay={
            <div style={{ width: '120px', height: '40px' }}>task has a document</div>
          }
        >
          <IonIcon color="primary" style={genericStyles.icon} icon={document} />
        </Tooltip>
      );
    }
  } else {
    return (
      <Tooltip
        destroyTooltipOnHide
        placement="top"
        trigger={['hover', 'click']}
        overlay={
          <div style={{ width: '120px', height: '40px' }}>
            task does not have a document
          </div>
        }
      >
        <IonIcon color="medium" style={genericStyles.icon} icon={document} />
      </Tooltip>
    );
  }
};

export default TaskDetailsIcon;
