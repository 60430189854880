import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import { IonInput } from '@ionic/react';

interface BaseProps {
  show: boolean;
  name: string;
  maxLength: number;
  placeholder: string;
  onClose: () => void;
  onSave: (name: string) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

const EditNameModal = ({
  onClose,
  onSave,
  name,
  show,
  placeholder,
  maxLength,
  forwardRef,
}: Props) => {
  const [selectedName, setSelectedName] = useState('');

  useEffect(() => {
    setSelectedName(name);
  }, [name, show]);

  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => {
  //       inputRef.current && inputRef.current.setFocus();
  //     }, 200);
  //   }
  // }, [show]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedName('');
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave(selectedName);
    handleClose();
  }, [handleClose, onSave, selectedName]);

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title="Edit name"
      klass="edit-name"
    >
      <IonInput
        style={{ backgroundColor: 'white' }}
        maxlength={maxLength}
        id="editNameInput"
        autocorrect="on"
        placeholder={placeholder}
        value={selectedName}
        clearInput
        ref={forwardRef}
        onIonChange={(event: any) => setSelectedName(event.target.value)}
      />
    </PropertyEditModalWrapper>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <EditNameModal forwardRef={ref} {...props} />
));
