import React from 'react';
import { colors } from '../../helpers/styles';

interface Props {
  children: React.ReactNode;
}

const FieldLabel = ({ children }: Props) => {
  return (
    <span
      style={{
        fontSize: '11px',
        color: colors.grayOut,
      }}
    >
      {children}
    </span>
  );
};

export default FieldLabel;
