import React from 'react';
import { TaskEnrichedWithProjectAndTags, TaskStatus } from '../../types/CoreTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import { IonButton, IonCol, IonGrid, IonIcon, IonNote } from '@ionic/react';
import { checkbox, create, star, starOutline } from 'ionicons/icons';
import formatRelative from 'date-fns/formatRelative';
import { genericStyles } from '../../helpers/styles';
import { withStorage } from '../../helpers/StorageProviders';
import { StorageDeps } from '../../helpers/InitStorage';
import TaskStatusInfo from './TaskStatusInfo';
import { TaskHandlers } from '../../types/TaskHandlers';
import { TableCell } from '../Common/TableCell';
import ProjectLink from '../Common/ProjectLink';
import PriorityInfo from '../Common/PriorityInfo';
import TimeInfo from '../Common/TimeInfo';
import EnergyInfo from '../Common/EnergyInfo';
import { DocumentEditorSync } from '../Common/DocumentEditor';
import TagsWidget from '../Common/TagsWidget';
import ActionOnKeyPress from '../Common/ActionOnKeyPress';
import { ModalsProps, withModalsState } from '../../helpers/ModalsProvider';
import QuickAddFormTask from './QuickAddFormTask';
import QuickAdd from '../Common/QuickAdd';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import TaskWrapperDateInfo from './TaskWrapperDateInfo';
import RepeatingStatusWidget from './RepeatingStatusWidget';
import { DateProps, withDate } from '../../helpers/DateProvider';
import TaskProductivityIcons from './TaskProductivityIcons';

interface Props
  extends RouteComponentProps,
    StorageDeps,
    ModalsProps,
    WorkspaceProps,
    DateProps {
  task: TaskEnrichedWithProjectAndTags;
  onChange: () => void;
  taskHandlers: TaskHandlers;
}

interface State {
  blockIsSelected: boolean;
}

class TaskDetails extends React.PureComponent<Props, State> {
  state: State = { blockIsSelected: false };

  setBlockSelected = () => {
    this.setState({ blockIsSelected: true });
  };

  setBlockNotSelected = () => {
    this.setState({ blockIsSelected: false });
  };

  render() {
    const {
      task,
      taskHandlers: {
        onStartStateChange,
        onReschedule,
        onSetRecurring,
        onChangeDue,
        onChangeState,
        onChangePriority,
        onChangeTime,
        onChangeEnergy,
        onChangeTags,
        onCompleteEndRecurring,
        onChangeProject,
        onChangeName,
        getHandlers,
      },
      date,
    } = this.props;

    if (!task) {
      return <div>No task details found...</div>;
    }

    return (
      <QuickAdd
        hideQuickAdd={this.state.blockIsSelected}
        form={({ onClose, ref, onSave, isOpen }) => (
          <QuickAddFormTask
            isOpen={isOpen}
            onClose={onClose}
            daoCatalog={this.props._techDebt_daoCatalog}
            onSave={(args) =>
              this.props.repos.taskRepo
                .create(args)
                .then((taskId) => {
                  if (args.blocks) {
                    return Promise.all(
                      args.blocks.map((block) => {
                        return this.props.repos.blockRepo.create({
                          ...block,
                          taskId,
                          contextId: args.contextId,
                        });
                      })
                    );
                  }
                })
                .then(onSave)
            }
            ref={ref}
          />
        )}
      >
        <div className="ion-padding detail-page-with-blocks details-page details-page-task">
          {getHandlers && (
            <ActionOnKeyPress
              name="task details"
              enabled={!this.props.nonMagicModalIsOpen}
              handlers={getHandlers(task)}
            />
          )}
          {onChangeName && (
            <h1 style={genericStyles.clickable} onClick={() => onChangeName(task)}>
              <IonIcon color="medium" style={genericStyles.icon} icon={create} />
              {task.name}
            </h1>
          )}
          {!onChangeName && <h1>{task.name}</h1>}

          <div>
            <IonNote>
              Created: {formatRelative(task.createdAt, this.props.date)}
            </IonNote>
          </div>
          {task.lastUpdated && (
            <div>
              <IonNote>
                Modified: {formatRelative(task.lastUpdated, this.props.date)}
              </IonNote>
            </div>
          )}
          {task.statusUpdatedAt && (
            <div>
              <IonNote>
                Last status change:{' '}
                {formatRelative(task.statusUpdatedAt, this.props.date)}
              </IonNote>
            </div>
          )}

          <div className="list-row">
            <IonGrid>
              <IonCol sizeXs="12" sizeSm="6" className="list-row">
                {task.status !== TaskStatus.STATUS_DONE && (
                  <TableCell className="table-cell">
                    <IonButton
                      size="small"
                      color="success"
                      onClick={() =>
                        onChangeState && onChangeState(TaskStatus.STATUS_DONE, task)
                      }
                    >
                      <IonIcon icon={checkbox} />
                      &nbsp; Done
                    </IonButton>
                  </TableCell>
                )}

                <TableCell className="table-cell">
                  <TaskStatusInfo
                    status={task.status}
                    onClick={() => onStartStateChange && onStartStateChange(task)}
                  />
                </TableCell>

                <TableCell
                  className={`table-cell-icon ${
                    onChangeState ? 'clickable-control' : ''
                  }`}
                  onClick={
                    onChangeState
                      ? () => {
                          const newState =
                            task.status === TaskStatus.STATUS_IN_PROGRESS
                              ? TaskStatus.STATUS_ACTION_LIST
                              : TaskStatus.STATUS_IN_PROGRESS;
                          onChangeState(newState, task);
                        }
                      : undefined
                  }
                >
                  <IonIcon
                    style={genericStyles.icon}
                    color="warning"
                    icon={
                      task.status === TaskStatus.STATUS_IN_PROGRESS
                        ? star
                        : starOutline
                    }
                  />
                </TableCell>
              </IonCol>
              <IonCol sizeXs="12" sizeSm="6" className="list-row">
                <RepeatingStatusWidget
                  date={date}
                  task={task}
                  onClick={
                    onCompleteEndRecurring
                      ? () => onCompleteEndRecurring(task)
                      : undefined
                  }
                />
              </IonCol>
              <IonCol sizeXs="12" sizeSm="6" className="list-row">
                <TableCell className="table-cell">
                  <ProjectLink
                    onEdit={
                      onChangeProject ? () => onChangeProject(task) : undefined
                    }
                    project={task.project}
                  />
                </TableCell>
              </IonCol>
              <IonCol sizeXs="12" sizeSm="6" className="list-row">
                <TableCell className="table-cell">
                  <TagsWidget
                    onEdit={onChangeTags ? () => onChangeTags(task) : undefined}
                    tags={task.tagsData}
                  />
                </TableCell>
              </IonCol>
              <IonCol sizeXs="12" sizeSm="6" className="list-row">
                <TaskWrapperDateInfo
                  currentDate={this.props.date}
                  Wrapper={({ title, children, colspan }) => (
                    <TableCell
                      title={title}
                      className={`table-cell-dates colspan-${colspan}`}
                    >
                      {children}
                    </TableCell>
                  )}
                  task={task}
                  clickHandlers={{
                    reschedule: onReschedule ? () => onReschedule(task) : undefined,
                    recurring: onSetRecurring
                      ? () => onSetRecurring(task)
                      : undefined,
                    due: onChangeDue ? () => onChangeDue(task) : undefined,
                  }}
                />
              </IonCol>
              <IonCol sizeXs="12" sizeSm="6" className="list-row">
                <TableCell className="table-cell" title="Priority">
                  <PriorityInfo
                    onClick={
                      onChangePriority ? () => onChangePriority(task) : undefined
                    }
                    priority={task.priorityValues}
                  />
                </TableCell>

                <TableCell className="table-cell" title="Time">
                  <TimeInfo
                    onClick={onChangeTime ? () => onChangeTime(task) : undefined}
                    time={task.time}
                  />
                </TableCell>

                <TableCell className="table-cell" title="Energy">
                  <EnergyInfo
                    onClick={onChangeEnergy ? () => onChangeEnergy(task) : undefined}
                    energy={task.energy}
                  />
                </TableCell>

                <TableCell className="table-cell">
                  <div style={{ display: 'flex' }}>
                    <TaskProductivityIcons task={task} date={date} />
                  </div>
                </TableCell>
              </IonCol>
            </IonGrid>
          </div>

          {/*{task.description && <ReactMarkdown source={task.description} />}*/}

          <DocumentEditorSync
            isHidden={this.props.modalIsOpen}
            contextId={task.contextId}
            syncParams={{
              selector: { taskId: task.id },
              localBlockCollection: this.props._techDebt_localBlockDb,
            }}
            daoCatalog={this.props._techDebt_daoCatalog}
            onBlockBlur={this.setBlockNotSelected}
            onBlockFocus={this.setBlockSelected}
          />
        </div>
      </QuickAdd>
    );
  }
}

export default withDate(
  withWorkspace(withModalsState(withRouter(withStorage(TaskDetails))))
);
