import { GraphqlGetRecentQuery } from '@todo/common';

interface DocForUpdate {
  id: string;
  serverUpdatedAt: number;
}

export const pullQueryBuilder = (userId: string, deviceId: string) => {
  return (doc: DocForUpdate): GraphqlGetRecentQuery => {
    // if (!doc) {
    //   doc = {
    //     id: '',
    //     serverUpdatedAt: 0
    //   };
    // }

    const query = `
        query(
          $limit: Int
          $userId: String
          $lastId: String
          $lastServerUpdatedAt: Timestamp
        ) {
            results: pullQuery(
              where: {
              OR: [
                { serverUpdatedAt: { gt: $lastServerUpdatedAt } }
                {
                  serverUpdatedAt: { equals: $lastServerUpdatedAt }
                  id: { gt: $lastId }
                }
              ]
              userId: { equals: $userId }
            }
            take: $limit
            orderBy: [{ serverUpdatedAt: asc }, { id: asc }]
            ) {
                allFields
                _rev
                _revisions
            }
        }`;
    return {
      query,
      variables: {
        lastId: doc?.id || '',
        since: doc?.serverUpdatedAt || 0,
        // itemType: tableName,
        deviceId,
      },
    };
  };
};

// export const buildSubscriptionQuery = (tableName: keyof typeof graphqlFields) => {
//   const fields = graphqlFields[tableName];
//   return `subscription on_${fields.subName}_changed {
//             ${fields.subName} {
//                 ${fields.fields}
//             }
//         }`;
// };
