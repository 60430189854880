import React, { useEffect } from 'react';
import { useAuthUser } from './helpers/AuthUserProvider';
import { checkIfSubStatusIsInSyncAndRefetch } from './lib/SubscriptionHelper';
import { AuthError } from './lib/Errors';
import MainAppInner from './MainAppInner';
import { SyncStatusProvider } from './helpers/SyncStatusProvider';
import { logDebug } from './lib/logger';
import { useSubscriptionStatus } from './helpers/SubscriptionStatusProvider';

// interface Props {
//   userId: string;
// }

const MainApp = () => {
  const { userInfo, reloadAuthentication, updatingUserInfo } = useAuthUser();

  const { isLocal, isPaid } = useSubscriptionStatus();

  useEffect(() => {
    (async () => {
      if (userInfo?.auth && !updatingUserInfo) {
        logDebug(['sync', 'auth'], 'app: check if sub data is in sync');

        // this will call RevenueCat API to get most recent Entitlement, and
        // if entitlement status is not in sync with Auth0, update Auth0
        try {
          const inSync = await checkIfSubStatusIsInSyncAndRefetch(userInfo);
          if (!inSync) {
            logDebug(['sync', 'auth'], 'not in sync!');
            reloadAuthentication(true);
          } else {
            logDebug(['sync', 'auth'], 'ok, in sync!');
          }
        } catch (e) {
          if (e instanceof AuthError) {
            logDebug(['sync', 'auth'], 'error with sync!');
            reloadAuthentication(true);
          }
          // if (e.message.toLower() !== 'Failed to fetch') {
          //   setErrorMessage(
          //     'There was an error validating the purchase. Please reload the application.'
          //   );
          // }
          console.info(`Failed to check subscription data: ${e}`);
        }
      }
    })();
  }, [reloadAuthentication, userInfo, updatingUserInfo]);

  if (isLocal === null) return null;
  if (isPaid === null) return null;

  logDebug(['sync', 'auth'], `MainApp isLocal=${isLocal}`);

  return (
    <SyncStatusProvider localMode={isLocal}>
      <MainAppInner isLocal={isLocal} isPaid={isPaid} />
    </SyncStatusProvider>
  );
};

export default MainApp;
