import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import RecurringDatePicker from '../Common/RecurringDatePicker';
import { RecurringOptions } from '../../types/CoreTypes';
import { IonButton } from '@ionic/react';
import { formatDateWithoutTime } from '../../helpers/RecurringHelper';
import { useFreeTier } from '../../helpers/FreeTierProvider';
import UpgradeBanner from '../Common/UpgradeBanner';

interface BaseProps {
  show: boolean;
  value: RecurringOptions | undefined;
  onClose: () => void;
  onSave: (recurring: RecurringOptions | undefined) => void;
  title: string;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

function getDefaultRecurring(): RecurringOptions {
  return {
    starting: {
      date: formatDateWithoutTime(new Date()),
    },
    endsAfter: {},
    repeatingEvery: {
      days: {
        days: 7,
      },
    },
  };
}

const RecurringModal = ({
  onClose,
  onSave,
  value,
  show,
  forwardRef,
  title,
}: Props) => {
  const [recurring, setRecurring] = useState<RecurringOptions>(
    value || getDefaultRecurring()
  );

  const { isPaid } = useFreeTier();

  useEffect(() => {
    setRecurring(value || getDefaultRecurring());
  }, [value, show]);

  const handleClose = useCallback(() => {
    onClose();
    setRecurring(getDefaultRecurring());
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave(recurring);
    handleClose();
  }, [handleClose, onSave, recurring]);

  const handleSaveValue = useCallback(
    (value: RecurringOptions | undefined) => {
      onSave(value);
      handleClose();
    },
    [handleClose, onSave]
  );

  const setRecurringOptions = useCallback((recurring: RecurringOptions) => {
    setRecurring(recurring);
  }, []);

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title={title}
    >
      {show && !isPaid && (
        <div ref={forwardRef}>
          <UpgradeBanner>
            Please upgrade to Premium Access to use recurring tasks feature
          </UpgradeBanner>
        </div>
      )}
      {show && isPaid && (
        <RecurringDatePicker
          ref={forwardRef}
          placeholder="Make Recurring"
          value={recurring}
          onChange={setRecurringOptions}
        />
      )}
      <IonButton onClick={() => handleSaveValue(undefined)}>Do not repeat</IonButton>
    </PropertyEditModalWrapper>
  );
};

const Memoized = React.memo(RecurringModal);

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <Memoized forwardRef={ref} {...props} />
));
