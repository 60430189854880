import React, { forwardRef } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import HotKeyQuickAdd from './HotKeyQuickAdd';
import ActionOnKeyPress from './ActionOnKeyPress';
import { Key, KeyModifier } from '../../types/CoreTypes';

interface BaseProps {
  maxLen: number;
  placeholder: string;
  onClose: () => void;
  ref?: any;
  onSave: (args: { name: string }) => Promise<void>;
  isOpen: boolean;
}

interface Props extends BaseProps {
  forwardedRef?: React.Ref<any>;
}

interface State {
  name: string;
}

class QuickAddForm extends React.PureComponent<Props, State> {
  state: State = {
    name: '',
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { name } = this.state;
    onSave({ name }).then(() => this.setState({ name: '' }));
  };

  render() {
    const { placeholder, onClose, forwardedRef } = this.props;
    const { name } = this.state;

    return (
      <>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            this.handleSave();
          }}
        >
          <IonHeader>
            <IonToolbar>
              <IonGrid>
                <IonRow>
                  <IonCol size="12">{''}</IonCol>
                </IonRow>
              </IonGrid>
              <IonButton slot="end" color="light" onClick={onClose}>
                <IonIcon icon={close} />
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonInput
                    maxlength={this.props.maxLen}
                    id="quickAddInput"
                    autocorrect="on"
                    placeholder={placeholder}
                    value={name}
                    clearInput
                    ref={forwardedRef}
                    onIonChange={(event: any) =>
                      this.setState({ name: event.target.value })
                    }
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            <HotKeyQuickAdd />
            <ActionOnKeyPress
              name="quick add form generic"
              enabled={this.props.isOpen}
              handlers={[
                {
                  action: this.handleSave,
                  name: 'save',
                  combination: {
                    key: Key.Enter,
                    modifiers: [[KeyModifier.Cmd], [KeyModifier.Ctrl]],
                  },
                },
                {
                  action: this.props.onClose,
                  name: 'close',
                  combination: {
                    key: Key.Escape,
                  },
                },
              ]}
            />
            <IonButton type="submit">Submit</IonButton>
          </div>
        </form>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <QuickAddForm forwardedRef={ref} {...props} />
));
