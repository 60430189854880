import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonReorder,
  IonRow,
} from '@ionic/react';
import React from 'react';
import { ProjectEnrichedWithTaskStats } from '../../types/CoreTypes';
import { reorderFourOutline } from 'ionicons/icons';
import ProjectProgressIcon from './ProjectProgressIcon';
import ProjectStatusInfo from './ProjectStatusInfo';
import { TableCell } from '../Common/TableCell';
import ProjectLink from '../Common/ProjectLink';
import PriorityInfo from '../Common/PriorityInfo';
import ProjectTimeWidget from './ProjectTimeWidget';
import TaskDateDueInfo from '../Tasks/TaskDateDueInfo';
import { useDate } from '../../helpers/DateProvider';

interface Props {
  project: ProjectEnrichedWithTaskStats;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
}

// eslint-disable-next-line react/display-name
const ProjectListElementMobile: React.FunctionComponent<Props> = ({
  project,
  onAfterNavigate,
}: Props) => {
  const { date } = useDate();
  const url = `/projects/details/${project.id}`;

  return (
    <IonItem
      // color={isSelected ? 'light' : undefined}
      routerLink={url}
      routerDirection={'root'}
      onClick={onAfterNavigate}
    >
      <IonReorder slot="start" style={{ marginRight: 10 }}>
        <IonIcon style={{ fontSize: 25 }} color="medium" icon={reorderFourOutline} />
      </IonReorder>

      <IonLabel className={'list-mobile project-list-row'}>
        <IonGrid>
          <IonRow>
            <IonCol className="list-row">
              <TableCell className="table-cell-project">
                <ProjectLink disableLink project={project} />
              </TableCell>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol sizeXs="12" sizeSm="6" className="list-row">
              <TableCell className="table-cell-status">
                <ProjectStatusInfo status={project.status} />
              </TableCell>
            </IonCol>

            <IonCol sizeXs="12" sizeSm="6" className="list-row row-justify">
              <TableCell title="Total tasks" className="table-cell-large-icon">
                <ProjectProgressIcon project={project} width={30} />
              </TableCell>

              <TableCell
                className="table-cell-small-text non-clickable"
                title="Time"
              >
                <ProjectTimeWidget project={project} />
              </TableCell>

              <TableCell
                title="Due date"
                className="table-cell-small-text clickable"
              >
                <TaskDateDueInfo currentDate={date} dateDue={project.dateDue} />
              </TableCell>

              <TableCell title="Priority" className="table-cell-large-icon">
                <PriorityInfo priority={project.priorityValues} />
              </TableCell>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );
};

export default ProjectListElementMobile;
