import React from 'react';
import { IonNote } from '@ionic/react';

interface Props {
  description?: string;
  children: any;
}

const styles: {
  [name: string]: { [key: string]: string };
} = {
  note: {
    marginLeft: '0',
    marginRight: '0',
    marginTop: '0',
    marginBottom: '2px',
    fontSize: '14px',
    lineHeight: 'normal',
    textOverflow: 'inherit',
    overflow: 'inherit',
  },
  wrapper: {
    width: '100%',
    overflow: 'visible',
  },
};

// style form
const FormElement: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div
      style={{
        margin: '6px',
        overflow: 'visible',
      }}
    >
      <div style={styles.wrapper}>
        {props.children}
        {props.description && (
          <IonNote style={styles.note}>{props.description}</IonNote>
        )}
      </div>
    </div>
  );
};

export default FormElement;
