import React, { useState } from 'react';
import { IonButton, IonIcon, IonToast } from '@ionic/react';
import { nativeRegisterSubscription } from '../lib/SubscriptionHelper';
import { useAuthUser } from '../helpers/AuthUserProvider';
import { arrowForward } from 'ionicons/icons';

const BindAccountToPurchaseButton = () => {
  const { userInfo, reloadAuthentication } = useAuthUser();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const auth = userInfo?.auth;

  if (!auth || !auth?.userId) {
    return null;
  }

  return (
    <div>
      <IonButton
        color="primary"
        onClick={async () => {
          try {
            await nativeRegisterSubscription({
              auth: auth,
            });
            setSuccess('Success!');
          } catch (e) {
            setError(e.toString());
          }
          reloadAuthentication(true);
        }}
      >
        Click here to enable synchronization
        <IonIcon slot="end" icon={arrowForward} />
      </IonButton>
      <IonToast
        isOpen={Boolean(error)}
        onDidDismiss={() => setError(null)}
        message={error || ''}
        position="top"
        color="danger"
        duration={5000}
      />
      <IonToast
        isOpen={Boolean(success)}
        onDidDismiss={() => setSuccess(null)}
        message={success || ''}
        position="top"
        color="success"
        duration={5000}
      />
    </div>
  );
};

export default BindAccountToPurchaseButton;
