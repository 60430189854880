import {
  ContextFields,
  ContextFilter,
  PartialUpdatedRecord,
  ContextType,
} from '../types/CoreTypes';
import { BaseRepo } from './BaseRepo';
import { changePositionsInEntities } from './SortHelper';
import { Observable } from 'rxjs';
import { CONTEXT_NAME_MAX_LENGTH } from './Constants';

export default class ContextRepo extends BaseRepo<ContextFields, undefined> {
  async create(args: { name: string }): Promise<string> {
    const nameTrimmed = args.name.trim();
    const entity = {
      name: nameTrimmed.substring(0, CONTEXT_NAME_MAX_LENGTH),
      lastUpdated: new Date().getTime(),
      createdAt: new Date().getTime(),
    };
    return this.dao.create(entity);
  }

  _forceCreate(doc: ContextType) {
    return this.dao._forceCreate(doc);
  }

  async reorder(contexts: ContextType[], from: number, to: number) {
    const positionKey = 'position';
    const changeSets = changePositionsInEntities(contexts, from, to).map(
      (context, idx) => ({
        id: context.id,
        [positionKey]: idx,
      })
    );
    return this.dao.editManyWithDebounce(changeSets);
  }

  async edit(entity: PartialUpdatedRecord<ContextType>): Promise<void> {
    return this.dao.edit(entity);
  }

  async delete(id: string): Promise<void> {
    return this.dao.delete(id);
  }

  async getById(id: string): Promise<ContextType | null> {
    return this.dao.getById(id);
  }

  async getAll(): Promise<ContextType[]> {
    return this.dao.getAll();
  }

  getAll$(): Observable<ContextType[]> {
    return this.dao.getAll$();
  }

  async getAllForFilter(filter: ContextFilter): Promise<ContextType[]> {
    const { selector, filterFn } = this.makeSelector(filter);
    return this.dao.findAll({
      selector,
      filterFn,
    });
  }

  private makeSelector(filter: ContextFilter) {
    const filterFn = (context: ContextType) => {
      let isMatch = true;
      if (filter.anyText)
        isMatch =
          isMatch &&
          context.name.toLowerCase().includes(filter.anyText.toLowerCase());
      return isMatch;
    };
    return { selector: {}, filterFn };
  }
}
