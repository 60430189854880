import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  OptionTypeWithColor,
  ProjectPositionContextEnum,
  ProjectStatus,
} from '../../types/CoreTypes';
import SingleSelect from '../Common/SingleSelect';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import { generateColorFromString } from '../../helpers/colors';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import { useDate } from '../../helpers/DateProvider';
import { sortByStatusAndPosition } from '../../helpers/ProjectHelper';

interface BaseProps {
  show: boolean;
  projectId: string | undefined;
  onClose: () => void;
  onSave: (project: string | undefined) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

const EditProjectModal = ({
  onClose,
  onSave,
  projectId,
  show,
  forwardRef,
}: Props) => {
  const [allProjects, setAllProjects] = useState<OptionTypeWithColor[] | undefined>(
    undefined
  );
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>(
    undefined
  );

  const {
    repos: { projectRepo },
  } = useStorage();
  const { context } = useWorkspace();
  const { dateFormatted } = useDate();

  useEffect(() => {
    const sub = projectRepo
      .getAllForFilter$(
        context.id,
        dateFormatted,
        { notStatus: ProjectStatus.STATUS_ARCHIVE },
        ProjectPositionContextEnum.STATUS
      )
      .subscribe((projects) => {
        setAllProjects(
          projects.sort(sortByStatusAndPosition).map((project) => ({
            value: project.id,
            label: project.name,
            color: generateColorFromString(project.id),
          }))
        );
      });
    return () => sub.unsubscribe();
  }, [dateFormatted, context.id, projectRepo]);

  useEffect(() => {
    setSelectedProjectId(projectId);
  }, [projectId, show]);

  // const selectRef = useRef<any>(null);

  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => {
  //       selectRef.current && selectRef.current.focus();
  //     }, 200);
  //   }
  // }, [show]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedProjectId(undefined);
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave(selectedProjectId);
    handleClose();
  }, [handleClose, onSave, selectedProjectId]);

  const handleCreateNewTag = useCallback(
    (newTagName: string) => {
      projectRepo
        .create({
          name: newTagName,
          contextId: context.id,
          status: ProjectStatus.STATUS_NEXT,
        })
        .then(setSelectedProjectId);
    },
    [context.id, setSelectedProjectId, projectRepo]
  );

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title="Choose a project"
      klass="choose-a-project"
    >
      <SingleSelect
        ref={forwardRef}
        value={selectedProjectId}
        placeholder="Select or create project"
        options={allProjects || []}
        onChange={setSelectedProjectId}
        onCreate={handleCreateNewTag}
      />
    </PropertyEditModalWrapper>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <EditProjectModal forwardRef={ref} {...props} />
));
