import React, { forwardRef } from 'react';
import { withStorage } from '../../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import { IonList, IonReorderGroup } from '@ionic/react';
import { ItemReorderEventDetail } from '@ionic/core';
import TaskListRow from './TaskListRow';
import TaskActionsWrapper from './TaskActionsWrapper';
import { TaskHandlers } from '../../types/TaskHandlers';
import { RxChangeEvent } from 'rxdb/plugins/core';
import { debounce, isEqual } from 'lodash';
import { swap } from '../../helpers/PositionHelper';
import { StorageDeps } from '../../helpers/InitStorage';
import { Subscription } from 'rxjs';
import WideMessage from '../Common/WideMessage';
import { warning } from 'ionicons/icons';
import { colors } from '../../helpers/styles';
import { DateProps, withDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';
import {
  ItemType,
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
  TaskFilter,
} from '../../types/CoreTypes';
import { TaskType } from '@todo/common';

interface MinProps {
  filter: TaskFilter;
  setUniqueAndStatsFilterValues?: (values: TaskType[]) => void;
  setCounter?: (count: number) => void;
  selectedIdx?: number;
  getRef?: (idx: number) => React.RefObject<any>;
  taskHandlers?: TaskHandlers;
  tagContext: string;
  emptyMessage?: string;
}

interface ExtProps extends MinProps {
  forwardedRef?: React.Ref<any>;
}

interface Props extends WorkspaceProps, StorageDeps, ExtProps, DateProps {}

type TasksTypeToUseHere = TaskEnrichedWithProjectAndTags &
  TaskEnrichedWithBlockStats;

interface State {
  tasks: TasksTypeToUseHere[];
}

// maybe ref will be used later for navigating list with arrows

// todo: some code dup with TaskListView, consider reusing
// eslint-disable-next-line react/display-name
class TaskListViewTags extends React.Component<Props, State> {
  state: State = {
    tasks: [],
  };

  dbUpdateSubscription?: Subscription;

  constructor(props: Props) {
    super(props);
    this.refresh();
  }

  // constructor and vars go above ^^

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (!isEqual(prevState.tasks, this.state.tasks)) {
      this.refreshCounters();
    }
    if (
      !isEqual(prevProps.filter, this.props.filter) ||
      !isEqual(prevProps.context, this.props.context)
    ) {
      this.refresh();
    }
  }

  componentDidMount(): void {
    this.dbUpdateSubscription = this.props.subscribeForDbChanges(
      this.handleDbChangeEvent
    );
  }

  componentWillUnmount() {
    this.dbUpdateSubscription?.unsubscribe();
  }

  // lifecycle goes above ^^

  refreshCounters = () => {
    this.props.setCounter && this.props.setCounter(this.state.tasks.length);
  };

  async loadDataNotDebounced() {
    const {
      repos: { taskRepo },
      context,
      dateFormatted,
    } = this.props;

    taskRepo
      .getAllForFilterForTags(
        context.id,
        this.props.filter,
        this.props.tagContext,
        dateFormatted
      )
      .then((tasks) => {
        this.setTasksDebounced(tasks);
        this.props.setUniqueAndStatsFilterValues &&
          this.props.setUniqueAndStatsFilterValues(tasks);
      });
  }

  loadData = debounce(this.loadDataNotDebounced, 200);

  setTasks = (tasks: TasksTypeToUseHere[]) => {
    this.setState({ tasks });
  };

  setTasksDebounced = debounce((tasks: TasksTypeToUseHere[]) => {
    this.setTasks(tasks);
  }, 200);

  refresh = () => {
    this.loadData();
    this.loadData.flush();
    logDebug([], 'task list in tags refresh');
  };

  handleDbChangeEvent = (evt: RxChangeEvent) => {
    if (evt.operation === 'INSERT') {
      if ([ItemType.task].includes(evt.collectionName as ItemType)) {
        this.loadData();
      }
    }
    if (evt.operation === 'UPDATE' || evt.operation === 'DELETE') {
      if (
        [ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)
      ) {
        this.loadData();
      }
    }
  };

  doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    const { tasks } = this.state;
    this.props.repos.taskRepo
      .reorderForTags(
        this.props.tagContext,
        tasks,
        event.detail.from,
        event.detail.to
      )
      // need solely to replace `tasks` to allow subsequent sorting
      // .then(loadData)
      // Finish the reorder and position the item in the DOM based on
      // where the gesture ended. This method can also be called directly
      // by the reorder group
      .then(() => event.detail.complete());
  };

  manualReorder = (up: boolean) => {
    const idx = this.props.selectedIdx;
    if (idx === undefined) return Promise.resolve(false);
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    const to = up ? idx - 1 : idx + 1;
    // important, to read it before all following ops
    const { tasks } = this.state;
    if (to < 0 || to >= tasks.length) return Promise.resolve(false);
    this.loadData.cancel();
    this.setTasksDebounced.cancel();
    this.setTasksDebounced(swap(tasks, idx, to));
    this.setTasksDebounced.flush();
    return this.props.repos.taskRepo
      .reorderForTags(this.props.tagContext, tasks, idx, to)
      .then(() => true);
  };

  renderEmptyMessage() {
    const { emptyMessage } = this.props;
    const { tasks } = this.state;
    if (!emptyMessage || tasks.length) return null;
    return <WideMessage text={emptyMessage} icon={warning} color={colors.grayOut} />;
  }

  render() {
    const overrideTaskHandlers = this.props.taskHandlers || {};

    const { tasks } = this.state;

    return (
      <TaskActionsWrapper>
        {(taskHandlers) => (
          <IonList style={{ marginBottom: '0', padding: 0 }}>
            <IonReorderGroup disabled={false} onIonItemReorder={this.doReorder}>
              {tasks.map((task, idx) => (
                <TaskListRow
                  manualReorder={this.manualReorder}
                  ref={this.props.getRef ? this.props.getRef(idx) : undefined}
                  key={task.id}
                  task={task}
                  isSelected={idx === this.props.selectedIdx}
                  taskHandlers={{
                    ...taskHandlers,
                    ...overrideTaskHandlers,
                  }}
                />
              ))}
              {this.renderEmptyMessage()}
            </IonReorderGroup>
          </IonList>
        )}
      </TaskActionsWrapper>
    );
  }
}

const TaskListViewWithDeps = withDate(withStorage(withWorkspace(TaskListViewTags)));

// eslint-disable-next-line react/display-name
export default forwardRef<any, ExtProps>((props: MinProps, ref: React.Ref<any>) => (
  <TaskListViewWithDeps forwardedRef={ref} {...props} />
));
