import React from 'react';
import {
  ProjectEnrichedWithTaskStatsAndDependingProject,
  TaskPositionContextEnum,
  TaskStatus,
} from '../../types/CoreTypes';
import TaskListView from '../Tasks/TaskListView';
import QuickAdd from '../Common/QuickAdd';
import { withStorage } from '../../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import { IonLoading } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';
import QuickAddFormTask from '../Tasks/QuickAddFormTask';
import { StorageDeps } from '../../helpers/InitStorage';
import TaskListFilter from '../Tasks/TaskListFilter';
import { ProjectHandlers } from '../../types/ProjectHandlers';
import { ModalsProps, withModalsState } from '../../helpers/ModalsProvider';
import ListNavigation from '../Common/ListNavigation';
import WrapForCollectionBasedCallbacks from '../Common/WrapForCollectionBasedCallbacks';
import { DateProps, withDate } from '../../helpers/DateProvider';
import ProjectDetailsInner from './ProjectDetailsInner';

interface Props
  extends StorageDeps,
    RouteComponentProps,
    WorkspaceProps,
    ModalsProps,
    DateProps {
  project: ProjectEnrichedWithTaskStatsAndDependingProject | null;
  isLoading: boolean;
  isEmbed?: boolean;
  projectHandlers: ProjectHandlers;
}

interface State {
  blockIsSelected: boolean;
}

const TASKS_COLLECTION_NAME = 'tasks';

class ProjectDetails extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      blockIsSelected: false,
    };
  }

  private handleBlockIsSelectedChange = (v: boolean) => {
    this.setState({ blockIsSelected: v });
  };

  //
  // setNewTaskFields = (args: TaskCreateFormFillable): TaskCreateForm => {
  //   const { context } = this.props;
  //   const { project } = this.props;
  //   if (!project) throw new Error('No project');
  //   return {
  //     ...args,
  //     project: project,
  //     contextId: context.id,
  //     status: args.status || TaskStatus.STATUS_ACTION_LIST,
  //   };
  // };

  render() {
    const { isLoading, project, projectHandlers, isEmbed } = this.props;
    if (!project) {
      if (isLoading) {
        return <IonLoading isOpen={isLoading} message={'Loading...'} />;
      }
      return <div>No project details found..</div>;
    }

    return (
      <QuickAdd
        isEmbed={!!isEmbed}
        form={({ onClose, ref, onSave, isOpen }) => (
          <QuickAddFormTask
            isOpen={isOpen}
            onClose={onClose}
            daoCatalog={this.props._techDebt_daoCatalog}
            defaultProject={this.props.project || undefined}
            onSave={(args) =>
              this.props.repos.taskRepo
                .create(args)
                .then((taskId) => {
                  if (args.blocks) {
                    return Promise.all(
                      args.blocks.map((block) => {
                        return this.props.repos.blockRepo.create({
                          ...block,
                          taskId,
                          contextId: args.contextId,
                        });
                      })
                    );
                  }
                })
                .then(onSave)
            }
            ref={ref}
          />
        )}
      >
        <div className={isEmbed ? '' : 'ion-padding'}>
          <ProjectDetailsInner
            onBlockSelectedChange={this.handleBlockIsSelectedChange}
            project={project}
            isLoading={isLoading}
            projectHandlers={projectHandlers}
          />
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
            }}
          >
            <ListNavigation
              isDisabled={this.state.blockIsSelected}
              collections={[TASKS_COLLECTION_NAME]}
            >
              {(listNavigationApi) => (
                <TaskListFilter>
                  {({ filter, setUniqueAndStatsFilterValues }) => (
                    <WrapForCollectionBasedCallbacks
                      setCounter={listNavigationApi.setCounter}
                      setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                      collectionName={TASKS_COLLECTION_NAME}
                    >
                      {({ setCounter, setUniqueAndStatsFilterValues }) => (
                        <TaskListView
                          hideProject
                          selectedIdx={listNavigationApi.selectedCollectionIdx}
                          positionContext={TaskPositionContextEnum.PROJECT}
                          setUniqueAndStatsFilterValues={
                            setUniqueAndStatsFilterValues
                          }
                          setCounter={setCounter}
                          getRef={
                            listNavigationApi?.getRef
                              ? listNavigationApi.getRef(TASKS_COLLECTION_NAME)
                              : undefined
                          }
                          filter={{
                            projectId: project.id,
                            notStatus: TaskStatus.STATUS_DONE,
                            ...filter,
                          }}
                          emptyMessage="No tasks here yet. Add more tasks to this project to capture some work"
                        />
                      )}
                    </WrapForCollectionBasedCallbacks>
                  )}
                </TaskListFilter>
              )}
            </ListNavigation>
          </div>
        </div>
      </QuickAdd>
    );
  }
}

export default withDate(
  withModalsState(withRouter(withStorage(withWorkspace(ProjectDetails))))
);
