import React, { createContext, useContext } from 'react';
import { SyncEvent, SyncStatus } from '../types/CoreTypes';
import { logDebug, logWarn } from '../lib/logger';

export interface SyncStatusProps {
  setSyncStatus: (event: SyncEvent) => void;
  syncStatus: SyncStatus;
}

interface SyncInfo {
  status: SyncStatus;
  statusTime: number;
}

// @ts-ignore
export const SyncStatusContext = createContext<SyncStatusProps>(undefined);

interface Props {
  localMode: boolean;
  children: any;
}

interface State {
  syncInfo: SyncInfo;
}

export class SyncStatusProvider extends React.PureComponent<Props> {
  readonly state: State;

  constructor(props: Props) {
    super(props);

    this.state = this.getInitialStatus(props);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.localMode !== prevProps.localMode) {
      clearTimeout(this.activeResetTimeout);

      this.setState(this.getInitialStatus(this.props));
    }
  }

  getInitialStatus = (props: Props) => {
    const initialStatus = props.localMode
      ? SyncStatus.LocalMode
      : SyncStatus.SyncEnabledNotYetSucceeded;

    return {
      syncInfo: {
        status: initialStatus,
        statusTime: 0,
      },
    };
  };

  private activeResetTimeout: number | undefined;

  handleSyncStatus = (event: SyncEvent) => {
    const eventTime = new Date().getTime();
    if (!this.state.syncInfo) return;
    const { statusTime, status } = this.state.syncInfo;
    let newStatus: SyncStatus | undefined = undefined;
    switch (event) {
      case SyncEvent.Active: {
        // be suspicious of this status, since it doesn't really mean that the sync was successful
        // instead it's emitted every time there's activity
        clearTimeout(this.activeResetTimeout);

        this.activeResetTimeout = (setTimeout(() => {
          this.handleSyncStatus(SyncEvent.NoSyncError);
        }, 5 * 60 * 1000) as unknown) as number;
        if (eventTime - statusTime < 2000) {
          logDebug(['sync'], 'discarding Succeeding');
          return;
        }
        setTimeout(() => {
          this.handleSyncStatus(SyncEvent.ActiveRepeat);
        }, 1500);

        break;
      }
      case SyncEvent.ActiveRepeat: {
        if (eventTime - statusTime < 2000) {
          logDebug(['sync'], 'discarding ActiveRepeat');
          return;
        }
        newStatus = SyncStatus.Succeeding;

        break;
      }
      case SyncEvent.Offline: {
        newStatus = SyncStatus.Offline;

        break;
      }
      case SyncEvent.NoSyncError: {
        if (status !== SyncStatus.Succeeding) {
          logDebug(['sync'], 'discarding NoSyncError');
          return;
        }
        newStatus = SyncStatus.NoSyncError;

        logWarn(['sync'], 'SyncStatus.NoSyncError');

        break;
      }
      case SyncEvent.Error: {
        newStatus = SyncStatus.Error;

        logWarn(['sync'], 'SyncStatus.Error');

        break;
      }
    }

    if (newStatus) {
      this.setState({
        syncInfo: {
          status: newStatus,
          statusTime: eventTime,
        },
      });
      logDebug(['sync'], `status: ${status}`);
    }
  };

  render() {
    const { syncInfo } = this.state;
    const { children } = this.props;

    return (
      <SyncStatusContext.Provider
        value={{
          setSyncStatus: this.handleSyncStatus,
          syncStatus: syncInfo?.status || null,
        }}
      >
        {children}
      </SyncStatusContext.Provider>
    );
  }
}

export const useSyncStatusState: () => SyncStatusProps = () =>
  useContext(SyncStatusContext);

export const withSyncStatusState = <P extends Partial<SyncStatusProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof SyncStatusProps>> => {
  return function withSyncStatusStateContext(props) {
    return (
      <SyncStatusContext.Consumer>
        {(contextProps: SyncStatusProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </SyncStatusContext.Consumer>
    );
  };
};
