// import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

export enum LogCategory {
  debug = 'debug',
  info = 'info',
  warn = 'warn',
}

const DEBUG = process.env.REACT_APP_DEBUG || '0';
const useDebug = DEBUG === '1';

function log(
  category: LogCategory,
  tags: string[],
  message: string,
  details?: Record<string, any>
) {
  const msg = `${new Date().getTime()} [${category}] ${tags
    .map((t) => `[${t}]`)
    .join(' ')} ${message}`;
  if (details) {
    console.log(msg, details);
  } else {
    console.log(msg);
  }
}

export function logDebug(
  tags: string[],
  message: string,
  details?: Record<string, any>
) {
  if (!useDebug) return;
  return log(LogCategory.debug, tags, message, details);
}

export function logInfo(
  tags: string[],
  message: string,
  details?: Record<string, any>
) {
  // datadogLogs.logger.info(message, details);
  Sentry.captureEvent({
    message,
    level: Severity.Info,
    extra: details,
  });
  if (!useDebug) return;
  return log(LogCategory.info, tags, message, details);
}

export function logWarn(
  tags: string[],
  message: string,
  details?: Record<string, any>
) {
  // datadogLogs.logger.warn(message, details);
  Sentry.captureEvent({
    message,
    level: Severity.Warning,
    extra: details,
  });
  if (!useDebug) return;
  return log(LogCategory.warn, tags, message, details);
}
