import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import {
  IonButton,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonNote,
  IonRange,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import {
  Nullable,
  Priority,
  PriorityICE,
  PriorityValues,
} from '../../types/CoreTypes';
import { checkmark, close, trash } from 'ionicons/icons';
import { getICE, getPriorityEnum } from '../../helpers/PriorityHelper';
import { getPriorityLabel } from '../../helpers/CopyHelper';
import MyIonModal from '../../helpers/MyIonModal';

interface BaseProps {
  show: boolean;
  priority: Nullable<PriorityValues>;
  onClose: () => void;
  onSave: (priorityValues: Nullable<PriorityValues>) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

interface SliderProps {
  label: string;
  value: number | undefined;
  onChange: (value: number) => void;
}

const Slider = ({ value, label, onChange }: SliderProps) => {
  return (
    <>
      <IonItemDivider>{label}</IonItemDivider>
      <IonItem>
        <IonRange
          debounce={200}
          min={0}
          max={10}
          step={1}
          snaps
          ticks
          pin
          value={value || 0}
          onIonChange={(e) => {
            onChange(e.detail.value as number);
          }}
          color="secondary"
        >
          <IonLabel slot="start">0</IonLabel>
          <IonLabel slot="end">10</IonLabel>
        </IonRange>
      </IonItem>
    </>
  );
};

interface PriorityButtonProps {
  value: Nullable<Priority>;
  label: string;
  priority: Priority;
  onChange: (preset: Nullable<Priority>) => void;
}

// eslint-disable-next-line react/display-name
const PriorityButton = forwardRef<any, PriorityButtonProps>(
  (
    { value, label, priority, onChange }: PriorityButtonProps,
    ref: React.Ref<any>
  ) => {
    return (
      <IonButton
        ref={ref}
        fill={value && value === priority ? 'solid' : 'outline'}
        onClick={() => onChange(priority)}
      >
        {label}
      </IonButton>
    );
  }
);

const emptyPriority = {
  preset: undefined,
  ice: undefined,
};

const defaultIce = {
  impact: 0,
  confidence: 0,
  ease: 0,
};

const EditPriorityModal = (props: Props) => {
  const [priority, setPriority] = useState<Nullable<PriorityValues>>(undefined);

  useEffect(() => {
    setPriority(props.priority);
    // reset priority once a new different value is passed in
  }, [props.priority]);

  const setPreset = useCallback(
    (preset: Nullable<Priority>) => {
      setPriority({
        ...(priority || emptyPriority),
        preset,
      });
    },
    [priority, setPriority]
  );

  const setPriorityValue = useCallback(
    (priority: Nullable<PriorityValues>, value: Partial<PriorityICE>) => {
      setPriority({
        preset: undefined,
        ice: { ...((priority && priority.ice) || defaultIce), ...value },
      });
    },
    [setPriority]
  );

  const handleSave = (priority: Nullable<PriorityValues>) => {
    props.onSave(priority);
    props.onClose();
    setPriority(undefined);
  };

  return (
    <MyIonModal
      isOpen={props.show}
      backdropDismiss={true}
      onDidDismiss={props.onClose}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Set Priority</IonTitle>
          <IonButton slot="end" color="light" onClick={props.onClose}>
            <IonIcon icon={close} />
            Close
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonNote style={{ marginLeft: '10px' }}>
        Set priority manually to override
      </IonNote>
      <div style={{ display: 'flow', marginLeft: 10 }}>
        <PriorityButton
          ref={props.forwardRef}
          value={priority && priority.preset}
          label="High (P1)"
          priority={Priority.PRIORITY_HIGH}
          onChange={setPreset}
        />
        <PriorityButton
          value={priority && priority.preset}
          label="Medium (P2)"
          priority={Priority.PRIORITY_MEDIUM}
          onChange={setPreset}
        />
        <PriorityButton
          value={priority && priority.preset}
          label="Low (P3)"
          priority={Priority.PRIORITY_LOW}
          onChange={setPreset}
        />
      </div>

      <IonNote style={{ marginLeft: '10px' }}>
        Or change the values below to set priority based on ICE score
      </IonNote>

      <Slider
        onChange={(impact) => setPriorityValue(priority, { impact })}
        label="Impact"
        value={priority && priority.ice && priority.ice.impact}
      />
      <Slider
        onChange={(confidence) => setPriorityValue(priority, { confidence })}
        label="Confidence"
        value={priority && priority.ice && priority.ice.confidence}
      />
      <Slider
        onChange={(ease) => setPriorityValue(priority, { ease })}
        label="Ease"
        value={priority && priority.ice && priority.ice.ease}
      />
      {priority && !priority.preset && priority.ice && (
        <div style={{ margin: '0 10px' }}>
          ICE score: {getICE(priority.ice)}, which translates to a priority of{' '}
          {getPriorityLabel(getPriorityEnum(priority))}
        </div>
      )}

      <div style={{ margin: 10 }}>
        <IonButton color="success" onClick={() => handleSave(priority)}>
          <IonIcon icon={checkmark} />
          Save
        </IonButton>

        <div style={{ float: 'right' }}>
          <IonButton color="danger" onClick={() => handleSave(undefined)}>
            <IonIcon slot="start" icon={trash} />
            Reset
          </IonButton>
        </div>
      </div>
    </MyIonModal>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <EditPriorityModal forwardRef={ref} {...props} />
));
