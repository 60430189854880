import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Capacitor } from '@capacitor/core';
import { useAuthUser } from './AuthUserProvider';
import { useAuth0 } from '../Auth0ReactFork';
import { useStorage } from './StorageProviders';
import { logDebug } from '../lib/logger';
import {
  getLastEntitlementNative,
  getPurchaserInfo,
  getSubStatus,
} from '../lib/SubscriptionHelper';
import { EntitlementType } from '@todo/common';

export const SubscriptionStatusContext = createContext<SubscriptionStatusProps>(
  // @ts-ignore
  undefined
);

interface Props {
  children: any;
}

export interface SubscriptionStatusProps {
  isLocal: boolean | null;
  isPaid: boolean | null;
  refreshSubscriptionStatus: () => Promise<void>;
  saveNativeSubscription: (val: string | null) => Promise<void>;
}

export const SubscriptionStatusProvider = (props: Props) => {
  const { userInfo } = useAuthUser();
  const { localSettingsRepo } = useStorage();
  const auth0 = useAuth0();
  const [isLocal, setIsLocal] = useState<boolean | null>(null);
  const [isPaid, setIsPaid] = useState<boolean | null>(null);
  const { children } = props;

  const refreshSubscriptionStatus = useCallback(async () => {
    logDebug(['sync'], 'SubscriptionStatusProvider: refresh');

    if (
      (Capacitor.isNative && !userInfo?.auth) ||
      !userInfo?.auth?.activeEntitlement
    ) {
      const lastNativeSub = await localSettingsRepo.getLastNativeSubscription();
      try {
        const purchaserInfo = await getPurchaserInfo();
        const appUserEntitlementData = purchaserInfo
          ? getLastEntitlementNative(purchaserInfo)
          : null;
        if (appUserEntitlementData?.isActive) {
          logDebug(
            ['sync', 'auth'],
            'native app user is has a purchase but is unauth'
          );
          // sanity check
          if (!lastNativeSub) {
            localSettingsRepo.saveLastNativeSubscription('1');
          }
          setIsLocal(!userInfo?.auth || !userInfo?.auth?.activeEntitlement);
          setIsPaid(true);
          return;
        }
      } catch (e) {
        logDebug(
          ['sync', 'auth'],
          'possibly offline, but user had a native sub before'
        );
        if (lastNativeSub) {
          setIsLocal(!userInfo?.auth || !userInfo?.auth?.activeEntitlement);
          setIsPaid(true);
          return;
        }
      }
    }

    if (!userInfo?.auth) {
      if (userInfo) {
        const { lastSubscriptionType, lastUsedUserId } = userInfo;
        if (auth0.error) {
          if (lastSubscriptionType && lastUsedUserId) {
            let entitlementData: EntitlementType | null = null;
            let failedToFetchEntitlementData = false;
            try {
              entitlementData = await getSubStatus(lastUsedUserId);
            } catch (e) {
              failedToFetchEntitlementData = true;
            }

            if (entitlementData?.isActive || failedToFetchEntitlementData) {
              logDebug(
                ['sync', 'auth'],
                'possibly offline, but user was authenticated before'
              );
              setIsLocal(false);
              setIsPaid(true);
              return;
            }
          }
        }
      }

      logDebug(['sync', 'auth'], 'no auth, do nothing');
      setIsLocal(true);
      setIsPaid(false);
      return;
    }
    logDebug(['sync', 'auth'], 'auth, setting up auth0');
    const { token, encryptionPassword, activeEntitlement, userId } = userInfo.auth;

    if (userId && token && encryptionPassword && activeEntitlement) {
      // if sync mode already set up, start up with syncing
      setIsLocal(false);
      setIsPaid(true);
    } else if (userId) {
      if (!activeEntitlement) {
        logDebug(['sync', 'auth'], 'no active subscription');
      } else {
        logDebug(['sync', 'auth'], 'auth data missing or malformed');
      }
      // otherwise, just load data
      setIsLocal(true);
      setIsPaid(false);
    } else {
      throw new Error('No user id is set!');
    }
  }, [auth0.error, localSettingsRepo, userInfo]);

  const saveNativeSubscription = useCallback(
    async (val: string | null) => {
      await localSettingsRepo.saveLastNativeSubscription(val);
      await refreshSubscriptionStatus();
    },
    [localSettingsRepo, refreshSubscriptionStatus]
  );

  useEffect(() => {
    refreshSubscriptionStatus();
  }, [refreshSubscriptionStatus]);

  return (
    <SubscriptionStatusContext.Provider
      value={{ isLocal, isPaid, refreshSubscriptionStatus, saveNativeSubscription }}
    >
      {children}
    </SubscriptionStatusContext.Provider>
  );
};

export const useSubscriptionStatus: () => SubscriptionStatusProps = () =>
  useContext(SubscriptionStatusContext);

export const withSubscriptionStatus = <P extends Partial<SubscriptionStatusProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof SubscriptionStatusProps>> => {
  return function withSubscriptionStatusContext(props) {
    return (
      <SubscriptionStatusContext.Consumer>
        {(contextProps: SubscriptionStatusProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </SubscriptionStatusContext.Consumer>
    );
  };
};
