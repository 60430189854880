import React from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
} from '@ionic/react';
import { Badge } from '@ionic-native/badge';
import { checkmarkOutline } from 'ionicons/icons';
import { GrantedPermissions } from '../types/CoreTypes';

interface Props {
  grantedPermissions: GrantedPermissions;
  onComplete: (grantedPermissions: GrantedPermissions) => void;
}
//
// interface State {
//   isRequestingBadgePermissions: boolean;
// }

class PermissionsWalkthrough extends React.Component<Props> {
  // state: State = { isRequestingBadgePermissions: false };

  render() {
    if (!this.props.grantedPermissions.badge) {
      return (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>Permissions</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <div>
              We would like to send you notifications about tasks that are due today,
              that include app icon badges and push notifications. Please grant
              permissions to do so.
            </div>
            <IonButton
              color="primary"
              onClick={() =>
                Badge.requestPermission().then(() =>
                  this.props.onComplete({
                    ...this.props.grantedPermissions,
                    badge: true,
                  })
                )
              }
            >
              <IonIcon slot="start" icon={checkmarkOutline} />
              Grant permissions
            </IonButton>
          </IonCardContent>
        </IonCard>
      );
    }

    return <div>All permissions granted!</div>;
  }
}

export default PermissionsWalkthrough;
