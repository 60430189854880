import React, { Component, forwardRef } from 'react';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import MyIonModalAlwaysVisible from '../../helpers/MyIonModalAlwaysVisible';
import {
  ActionSubscriber,
  MagicHotkeyProps,
  withMagicHotkey,
} from '../../helpers/MagicHotkeyProvider';
import { Key, KeyModifier } from '../../types/CoreTypes';
import { isEqual } from 'lodash';
import { ClientTypeProps, withClientType } from '../../helpers/ClientTypeProvider';
import { logDebug } from '../../lib/logger';

interface MinProps {
  children?: any;
  form: (api: QuickAddAPI) => React.ReactNode;
  isEmbed?: boolean;
  hideQuickAdd?: boolean;
}

type ExtProps = MinProps;

interface Props extends ExtProps, MagicHotkeyProps, ClientTypeProps {
  forwardedRef?: React.Ref<any>;
}

interface State {
  showQuickAdd: boolean;
}

export interface QuickAddAPI {
  onClose: () => void;
  ref?: React.RefObject<any>;
  onSave: () => void;
  isOpen: boolean;
}

class QuickAddInner extends Component<Props, State> {
  state: State = {
    showQuickAdd: false,
  };

  private readonly quickAddRef: React.RefObject<any>;
  private readonly shouldUseGestures: boolean;

  constructor(props: Props) {
    super(props);
    this.quickAddRef = React.createRef();

    this.shouldUseGestures = !props.isDesktop;
  }

  componentDidMount() {
    if (!this.props.isEmbed) {
      logDebug([], 'quick add: register key listener');

      this.actionSubscriber = this.props.registerActions('quick add', [
        {
          name: 'Quick add',
          combination: {
            key: Key.N,
            modifiers: [[KeyModifier.Ctrl], [KeyModifier.Cmd]],
          },
          action: this.handleOpen,
        },
      ]);
      // document.addEventListener('keydown', this.handleKeyPress, false);
    }
  }

  private actionSubscriber?: ActionSubscriber;

  componentWillUnmount() {
    if (!this.props.isEmbed) {
      logDebug([], 'quick add: de-register key listener');
      this.actionSubscriber?.unsubscribe();
      // document.removeEventListener('keydown', this.handleKeyPress, false);
    }
  }

  closeQuickAdd = () => {
    if (this.quickAddRef.current) {
      if (this.quickAddRef.current.getInputElement) {
        this.quickAddRef.current.getInputElement().then((el: any) => el.blur());
      } else {
        this.quickAddRef.current.blur();
      }
    }
    this.close();
  };

  handleOpen = () => {
    this.show();
    // this.sleep(100);

    this.focus();
    // temp, until mobile app is ready: render simple input in mobile, and use timeout on desktop
    setTimeout(this.focus, 100);
  };

  // sleep = (ms: number) => {
  //   const end = Date.now() + ms;
  //   while (Date.now() < end) {
  //     continue;
  //   }
  // };

  focus = () => {
    if (this.quickAddRef.current) {
      if (this.quickAddRef.current.setFocus) {
        this.quickAddRef.current.setFocus();
        this.quickAddRef.current &&
          this.quickAddRef.current.getInputElement().then((el: any) => {
            el.select();
          });
      } else {
        this.quickAddRef.current.focus();
        this.quickAddRef.current.select && this.quickAddRef.current.select();
      }
    } else {
      throw Error('cannot focus in quick add: no current');
    }
  };

  show = () => {
    this.setState({ showQuickAdd: true });
    // return new Promise(resolve => {
    //   this.setState({ showQuickAdd: true });
    //   setTimeout(resolve, 100);
    // });
  };

  close = () => {
    this.setState({ showQuickAdd: false });
  };

  // handleKeyPress = (e: KeyboardEvent) => {
  //   if ((e.metaKey && e.key === 'n') || (e.ctrlKey && e.key === 'n')) {
  //     e.preventDefault();
  //     this.handleOpen();
  //   }
  // };

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: any): boolean {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  render() {
    const { form, children, isEmbed, hideQuickAdd } = this.props;
    const { showQuickAdd } = this.state;

    return (
      <>
        <MyIonModalAlwaysVisible
          cssClass="quick-add-modal"
          animated={false}
          isOpen={showQuickAdd}
          onDidDismiss={this.closeQuickAdd}
          keyboardClose={false}
          backdropDismiss={false}
        >
          {form({
            onClose: this.closeQuickAdd,
            ref: this.quickAddRef,
            onSave: this.closeQuickAdd,
            isOpen: showQuickAdd,
          })}
        </MyIonModalAlwaysVisible>
        {children}
        {!isEmbed && !hideQuickAdd && (
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={this.handleOpen}>
              <IonIcon icon={addOutline} />
            </IonFabButton>
          </IonFab>
        )}
      </>
    );
  }
}

const ReverseForwardRef = (p: Props) => {
  return <QuickAddInner {...p} ref={p.forwardedRef} />;
};

const QuickAdd = withClientType(withMagicHotkey(ReverseForwardRef));

// eslint-disable-next-line react/display-name
export default forwardRef<any, ExtProps>((props: MinProps, ref: React.Ref<any>) => (
  <QuickAdd forwardedRef={ref} {...props} />
));
