import React, { createContext, useContext } from 'react';
import {
  HotKeyHandler,
  Key,
  KeyCode,
  KeyModifier,
  NamedHotKeyHandler,
} from '../types/CoreTypes';
import MagicPanel from '../components/MagicPanel';
import { nanoid } from 'nanoid';
import MagicModal from './MagicModal';
import { head } from 'lodash';
import { logDebug } from '../lib/logger';

export interface MagicHotkeyProps {
  triggerMagicPanel: () => void;
  registerActions: (name: string, handlers: HotKeyHandler[]) => ActionSubscriber;
}

export interface ActionSubscriber {
  unsubscribe: () => void;
}

// @ts-ignore
export const MagicHotkeyContext = createContext<MagicHotkeyProps>(undefined);

interface Props {
  children: any;
}

interface State {
  isMagicPaneling: boolean;
  handlers: NamedHotKeyHandler[];
}

export class MagicHotkeyProvider extends React.PureComponent<Props> {
  // private handlers: Map<string, NamedHotKeyHandler> = new Map();

  state: State = {
    isMagicPaneling: false,
    handlers: [],
  };

  private readonly magicPanelRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.magicPanelRef = React.createRef();
  }

  handleOpenMagicPanel = () => {
    this.setState({ isMagicPaneling: true });
    // @ts-ignore
    this.magicPanelRef.current && this.magicPanelRef.current.setFocus();
    this.magicPanelRef.current &&
      this.magicPanelRef.current.getInputElement().then((el: any) => {
        el.select();
      });
  };

  // todo: listen all key press here!
  handleKeyPress = (e: KeyboardEvent) => {
    // todo: reimplement to use handlers too?
    if (
      (e.metaKey && e.code === KeyCode.KeyK) ||
      (e.ctrlKey && e.code === KeyCode.KeyK)
    ) {
      e.preventDefault();
      this.handleOpenMagicPanel();
    }

    const matches = this.state.handlers.filter((handler) => {
      if (this.isKeyMatch(e, handler.handler.combination.key)) {
        if (this.isModifierMatch(e, handler.handler.combination.modifiers)) {
          return true;
        }
      }
      return false;
    });
    if (matches.length > 1) {
      throw Error(
        `more than one match for the same hot key: ${JSON.stringify(matches)}`
      );
    }
    const handler = head(matches);
    if (handler) {
      logDebug([], `${handler.categoryName}: matches with`, handler);
      e.preventDefault();
      e.stopPropagation();
      handler.handler.action();
    }
  };

  isModifierMatch = (e: KeyboardEvent, modifiers?: KeyModifier[][]) => {
    if (!modifiers) return true;
    return modifiers.some((modifierSet) => {
      return Object.values(KeyModifier).every((k) => {
        const includes = modifierSet.includes(k);
        switch (k) {
          case KeyModifier.Ctrl: {
            return includes === e.ctrlKey;
          }
          case KeyModifier.Cmd: {
            return includes === e.metaKey;
          }
          case KeyModifier.Shift: {
            return includes === e.shiftKey;
          }
          case KeyModifier.Alt: {
            return includes === e.altKey;
          }
        }
        return false;
      });
      // return modifierSet.every(k => {
      //   switch (k) {
      //     case KeyModifier.Ctrl: {
      //       return e.ctrlKey;
      //     }
      //     case KeyModifier.Cmd: {
      //       return e.metaKey;
      //     }
      //     case KeyModifier.Shift: {
      //       return e.shiftKey;
      //     }
      //     case KeyModifier.Alt: {
      //       return e.altKey;
      //     }
      //   }
      //   return false;
      // });
    });
  };

  isKeyMatch = (e: KeyboardEvent, key: Key) => {
    switch (key) {
      case Key.Escape: {
        return e.code === KeyCode.Escape;
      }
      case Key.Enter: {
        return e.code === KeyCode.Enter;
      }
      case Key.Up: {
        return e.code === KeyCode.Up || e.code === KeyCode.ArrowUp;
      }
      case Key.Down: {
        return e.code === KeyCode.Down || e.code === KeyCode.ArrowDown;
      }
      case Key.F: {
        return e.code === KeyCode.KeyF;
      }
      case Key.N: {
        return e.code === KeyCode.KeyN;
      }
      case Key.E: {
        return e.code === KeyCode.KeyE;
      }
      case Key.Y: {
        return e.code === KeyCode.KeyY;
      }
      case Key.M: {
        return e.code === KeyCode.KeyM;
      }
      case Key.L: {
        return e.code === KeyCode.KeyL;
      }
      case Key.T: {
        return e.code === KeyCode.KeyT;
      }
      case Key.P: {
        return e.code === KeyCode.KeyP;
      }
      case Key.S: {
        return e.code === KeyCode.KeyS;
      }
      case Key.W: {
        return e.code === KeyCode.KeyW;
      }
      case Key.U: {
        return e.code === KeyCode.KeyU;
      }
      case Key.O: {
        return e.code === KeyCode.KeyO;
      }
      case Key.G: {
        return e.code === KeyCode.KeyG;
      }
      case Key.I: {
        return e.code === KeyCode.KeyI;
      }
      case Key.D: {
        return e.code === KeyCode.KeyD;
      }
      case Key._0: {
        return e.code === KeyCode.Digit0;
      }
      case Key._1: {
        return e.code === KeyCode.Digit1;
      }
      case Key._2: {
        return e.code === KeyCode.Digit2;
      }
      case Key._3: {
        return e.code === KeyCode.Digit3;
      }
      case Key._4: {
        return e.code === KeyCode.Digit4;
      }
      case Key._5: {
        return e.code === KeyCode.Digit5;
      }
      case Key._6: {
        return e.code === KeyCode.Digit6;
      }
      case Key._7: {
        return e.code === KeyCode.Digit7;
      }
      case Key._8: {
        return e.code === KeyCode.Digit8;
      }
      case Key._9: {
        return e.code === KeyCode.Digit9;
      }
    }
  };

  componentDidMount(): void {
    logDebug([], 'loading global actions key listener...');
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  componentWillUnmount() {
    logDebug([], 'unloading global actions key listener...');
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  handleCloseModal = () => {
    // this.magicPanelRef.current &&
    //   this.magicPanelRef.current.getInputElement().then((el: any) => el.blur());
    this.setState({ isMagicPaneling: false });
  };

  registerActions = (
    categoryName: string,
    handlers: HotKeyHandler[]
  ): ActionSubscriber => {
    logDebug([], `MagicHotKey: register handlers: ${categoryName}`);
    // const existingHandlers = this.state.handlers;
    const subscriptionId = this.makeId(categoryName);

    this.setHandlers((existingHandlers: NamedHotKeyHandler[]) => {
      handlers.forEach((handler) => {
        // const key = this.makeKey(handler);
        // const has = existingHandlers.has(key);
        // if (!has) {
        existingHandlers.push({ categoryName, subscriptionId, handler });
        // } else {
        // throw new Error(
        //   'handler already exists for keys ' + JSON.stringify(handler.combination)
        // );
        // }
      });
      return existingHandlers;
    });

    // logDebug([], `MagicHotKey: added handlers: ${subscriptionId}`, existingHandlers);

    return {
      unsubscribe: () => {
        this.deregisterActions(subscriptionId, categoryName);
      },
    };
  };

  setHandlers = (fn: (handlers: NamedHotKeyHandler[]) => NamedHotKeyHandler[]) => {
    this.setState((prevState: State) => ({
      handlers: fn(prevState.handlers),
    }));
  };

  private makeId(prefix: string) {
    return `${prefix}-${nanoid()}`;
  }

  makeKey(handler: HotKeyHandler): string {
    return (
      handler.combination.key +
      handler.combination.modifiers?.flat().sort().join('-')
    );
  }

  private deregisterActions = (subscriptionId: string, categoryName: string) => {
    logDebug([], `MagicHotKey: remove handlers: ${categoryName}`);
    // const newHandlers = this.state.handlers.filter(
    //   h => h.subscriptionId !== subscriptionId
    // );

    logDebug([], `MagicHotKey: removed handlers: ${subscriptionId}`);
    this.setHandlers((handlers: NamedHotKeyHandler[]) =>
      handlers.filter((h) => h.subscriptionId !== subscriptionId)
    );
  };

  render() {
    const { children } = this.props;
    const { handlers } = this.state;

    return (
      <MagicHotkeyContext.Provider
        value={{
          triggerMagicPanel: this.handleOpenMagicPanel,
          registerActions: this.registerActions,
        }}
      >
        {children}
        <MagicModal
          cssClass="magicPanel-modal"
          animated={true}
          isOpen={this.state.isMagicPaneling}
          onDidDismiss={this.handleCloseModal}
        >
          <MagicPanel
            isHidden={!this.state.isMagicPaneling}
            ref={this.magicPanelRef}
            onClose={this.handleCloseModal}
            handlers={handlers}
          />
        </MagicModal>
      </MagicHotkeyContext.Provider>
    );
  }
}

export const useMagicHotkey: () => MagicHotkeyProps = () =>
  useContext(MagicHotkeyContext);

export const withMagicHotkey = <P extends Partial<MagicHotkeyProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof MagicHotkeyProps>> => {
  return function withMagicHotkeyContext(props) {
    return (
      <MagicHotkeyContext.Consumer>
        {(contextProps: MagicHotkeyProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </MagicHotkeyContext.Consumer>
    );
  };
};
