import React, { useCallback, useEffect, useState } from 'react';
import { useStorage } from '../helpers/StorageProviders';
import EditContextForm from '../components/Contexts/EditContextForm';
import { ContextType } from '../types/CoreTypes';
import { RouteComponentProps } from 'react-router';
import { IonContent, IonPage, IonTitle } from '@ionic/react';
import CommonHeader from '../components/Common/CommonHeader';

interface Params {
  id?: string;
}

const ContextEditPage: React.FunctionComponent<RouteComponentProps<Params>> = (
  props: RouteComponentProps<Params>
) => {
  const [context, setContext] = useState<ContextType | null>(null);
  const {
    repos: { contextRepo },
  } = useStorage();

  const id = props.match.params.id;

  const loadData = useCallback(
    async function loadData(id: string) {
      contextRepo.getById(id).then(setContext);
    },
    [contextRepo]
  );

  useEffect(() => {
    if (!id) return;
    loadData(id);
  }, [id, loadData]);

  if (!id) return null;

  return (
    <IonPage>
      <CommonHeader>
        <IonTitle>Edit context</IonTitle>
      </CommonHeader>
      <IonContent>
        {context && (
          <EditContextForm
            context={context}
            onSave={(context) => {
              return contextRepo.edit(context).then(() => props.history.goBack());
            }}
            onCancel={() => props.history.goBack()}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default ContextEditPage;
