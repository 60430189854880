import React, { forwardRef } from 'react';
import TagListRowDesktop from './TagListRowDesktop';
import TagListRowMobile from './TagListRowMobile';
import { TagType } from '../../types/CoreTypes';
import deepEqual from 'fast-deep-equal';
import { useClientType } from '../../helpers/ClientTypeProvider';

interface Props {
  tag: TagType;
  ref?: any;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  compact?: boolean;
  onDelete?: (id: string) => void;
  manualReorder?: (up: boolean) => Promise<boolean>;
}

// eslint-disable-next-line react/display-name
const TagListRow: React.FunctionComponent<Props> = forwardRef<any, Props>(
  (props: Props, ref) => {
    const { isDesktop } = useClientType();
    if (isDesktop) {
      return (
        <TagListRowDesktop
          manualReorder={props.manualReorder}
          tag={props.tag}
          ref={ref}
          isSelected={props.isSelected}
          onAfterNavigate={props.onAfterNavigate}
          onDelete={props.onDelete}
        />
      );
    }

    return (
      <TagListRowMobile
        tag={props.tag}
        isSelected={props.isSelected}
        onAfterNavigate={props.onAfterNavigate}
        onDelete={props.onDelete}
      />
    );
  }
);

const Memoized = React.memo(TagListRow, deepEqual);

export default Memoized;
