import React, { useCallback, useEffect, useState } from 'react';
import { useStorage } from '../helpers/StorageProviders';
import {
  ItemType,
  ProjectEnrichedWithTaskStatsAndDependingProject,
} from '../types/CoreTypes';
import { RouteComponentProps } from 'react-router';
import { IonContent, IonPage, IonTitle } from '@ionic/react';
import ProjectDetails from '../components/Projects/ProjectDetails';
import CommonHeader from '../components/Common/CommonHeader';
import ProjectActionsWrapper from '../components/Projects/ProjectActionsWrapper';
import { RxChangeEvent } from 'rxdb/plugins/core';

interface TParams {
  id?: string;
}

const ProjectViewPage: React.FunctionComponent<RouteComponentProps<TParams>> = ({
  match,
}: RouteComponentProps<TParams>) => {
  const [
    project,
    setProject,
  ] = useState<ProjectEnrichedWithTaskStatsAndDependingProject | null>(null);
  const {
    subscribeForDbChanges,
    repos: { projectRepo },
  } = useStorage();

  const [loading, setLoading] = useState(false);

  const id = match.params.id;

  const loadData = useCallback(
    async function loadData() {
      if (!id) return;
      setLoading(true);
      projectRepo
        .getById(id)
        .then(setProject)
        .then(() => setLoading(false));
    },
    [projectRepo, id]
  );

  useEffect(() => {
    function handleDbChangeEvent(evt: RxChangeEvent) {
      if (evt.operation === 'INSERT') {
        if ([ItemType.task].includes(evt.collectionName as ItemType)) {
          loadData();
        }
      }
      if (evt.operation === 'UPDATE') {
        if (
          [ItemType.project, ItemType.task].includes(evt.collectionName as ItemType)
        ) {
          loadData();
        }
      }
    }

    const sub = subscribeForDbChanges(handleDbChangeEvent);
    return () => {
      sub.unsubscribe();
    };
  }, [loadData, subscribeForDbChanges]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!id) return null;

  return (
    <IonPage>
      <CommonHeader showBack>
        <IonTitle>Project</IonTitle>
      </CommonHeader>
      <IonContent>
        <ProjectActionsWrapper>
          {(projectHandlers) => (
            <ProjectDetails
              isLoading={loading}
              project={project}
              projectHandlers={projectHandlers}
            />
          )}
        </ProjectActionsWrapper>
      </IonContent>
    </IonPage>
  );
};

export default ProjectViewPage;
