import React from 'react';
import { IonIcon } from '@ionic/react';
import { TagType } from '../../types/CoreTypes';
import chroma from 'chroma-js';
import { getIconById } from '../../helpers/icons';

interface Props {
  tag: TagType;
  selected?: boolean;
  onClick?: (id: string) => void;
}

const Tag = ({ tag, onClick, selected }: Props) => {
  // if (!tag) return <span style={genericStyles.grayOutColor}>no tag</span>;

  const color = chroma(tag.color);

  return (
    <div
      onClick={onClick ? () => onClick(tag.id) : undefined}
      style={{
        backgroundColor: selected ? color.css() : color.alpha(0.1).css(),
        borderRadius: '2px',
        display: 'flex',
        margin: '2px',
        minWidth: 0,
        boxSizing: 'border-box',
        cursor: onClick ? 'pointer' : 'normal',
      }}
    >
      <div
        style={{
          display: 'flex',
          // backgroundColor: color.alpha(0.1).css(),
          color: selected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : tag.color,
          // fontWeight: 'bold',

          borderRadius: '2px',
          fontSize: '85%',
          overflow: 'hidden',
          padding: '3px',
          paddingLeft: '6px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',

          // // @ts-ignore
          // '&:hover': {
          //   color: color.alpha(0.3).css(),
          //   backgroundColor: tag.color
          // },
          // '&:active': {
          //   color: color.alpha(0.3).css(),
          //   backgroundColor: tag.color
          // }
        }}
      >
        <IonIcon
          style={{ marginRight: '2px' }}
          color={tag.color}
          icon={getIconById(tag.icon)}
        />
        <div
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {tag.name}
        </div>
      </div>
    </div>
  );
};

export default Tag;
