import React from 'react';
import { withStorage } from '../../helpers/StorageProviders';
import {
  ItemType,
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
} from '../../types/CoreTypes';
import { debounce, isEqual } from 'lodash';
import {
  IonBadge,
  IonButton,
  IonIcon,
  IonList,
  IonListHeader,
  IonNote,
  IonToolbar,
} from '@ionic/react';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import QuickAdd from '../Common/QuickAdd';
import QuickAddFormTask from './QuickAddFormTask';
import TaskListRow from './TaskListRow';
import TaskActionsWrapper from './TaskActionsWrapper';
import { RxChangeEvent } from 'rxdb/plugins/core';
import {
  formatDateWithoutTime,
  makeDateWithoutTime,
} from '../../helpers/RecurringHelper';
import subMonths from 'date-fns/subMonths';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import format from 'date-fns/format';
import { chevronBack, chevronForward, stopwatch } from 'ionicons/icons';
import { StorageDeps } from '../../helpers/InitStorage';
import { Subscription } from 'rxjs';
import { TaskHandlers } from '../../types/TaskHandlers';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { DateProps, withDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';
import { formatTime } from '../../lib/DateHelper';

interface Props extends WorkspaceProps, StorageDeps, DateProps {
  setCounter?: (count: number) => void;
  selectedIdx?: number;
  getRef?: (idx: number) => React.RefObject<any>;
}

function compareDates(a: Date, b: Date) {
  if (a.getTime() === b.getTime()) return 0;
  return a.getTime() > b.getTime() ? -1 : 1;
}

function compareTimestamps(a: number | undefined, b: number | undefined) {
  if (a === b) return 0;
  return (a || 0) > (b || 0) ? -1 : 1;
}

function makeDateInterval(date: Date) {
  const dateAdjusted = endOfMonth(date);
  return {
    from: startOfMonth(dateAdjusted),
    to: dateAdjusted,
  };
}

type TaskTypeToUseHere = TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;

interface DateTask {
  date: string;
  tasks: TaskTypeToUseHere[];
}

interface DateInterval {
  from: Date;
  to: Date;
}

interface State {
  dateTasks: DateTask[];
  tasksToIdx: Map<string, number>;
  dateInterval: DateInterval;
}

class ArchiveTaskListView extends React.Component<Props, State> {
  readonly state: State;

  dbUpdateSubscription?: Subscription;

  constructor(props: Props) {
    super(props);
    this.state = {
      dateTasks: [],
      tasksToIdx: new Map(),
      dateInterval: makeDateInterval(props.date),
    };
    this.refresh();
  }

  // constructor and vars go above ^^

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (!isEqual(prevState.dateTasks, this.state.dateTasks)) {
      this.refreshCounters();
    }
    if (!isEqual(prevProps.context, this.props.context)) {
      this.refresh();
    }
    if (!isEqual(prevProps.dateFormatted, this.props.dateFormatted)) {
      this.setState({
        dateInterval: makeDateInterval(this.props.date),
      });
    }
  }

  componentDidMount(): void {
    this.dbUpdateSubscription = this.props.subscribeForDbChanges(
      this.handleDbChangeEvent
    );
  }

  componentWillUnmount() {
    this.dbUpdateSubscription?.unsubscribe();
  }

  // lifecycle goes above ^^

  refreshCounters = () => {
    if (this.props.setCounter) {
      const len = Object.values(this.state.dateTasks).reduce(
        (acc, l) => acc + l.tasks.length,
        0
      );
      this.props.setCounter(len);
    }
  };

  setDateTasks = (dateTasks: DateTask[]) => {
    const { tasksToIdx } = this.computeTasksFiltered(dateTasks);
    this.setState({
      dateTasks,
      tasksToIdx,
    });
  };

  setDateInterval = (dateInterval: DateInterval) => {
    this.setState(
      {
        dateInterval,
      },
      this.loadData
    );
  };

  computeTasksFiltered(dateTasks: DateTask[]) {
    let idx = -1;

    const list: [string, number][][] = dateTasks.map((tf) =>
      tf.tasks.map((t) => {
        idx++;
        return [makeTaskIdxKey(tf, t), idx];
      })
    );
    const tasksToIdx = new Map(list.flat());

    return { tasksToIdx };
  }

  async loadDataNotDebounced() {
    const {
      repos: { taskRepo },
      context,
      dateFormatted,
    } = this.props;
    const { dateInterval } = this.state;
    // todo: load for each page. from and until - from
    const from = startOfDay(dateInterval.from);
    const to = endOfDay(dateInterval.to);
    taskRepo
      .getAllForFilterWithoutSort(
        context.id,
        {
          // status: TaskStatus.STATUS_DONE,
          completedAt: {
            from: from.getTime(),
            to: to.getTime(),
          },
        },
        dateFormatted
      )
      .then((tasks) =>
        tasks
          .reduce(
            (
              acc: [
                Date,
                TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats
              ][],
              task
            ) => {
              let dateCompleted;
              if (task.completedAt) {
                dateCompleted = new Date(task.completedAt);
                dateCompleted.setHours(0);
                dateCompleted.setMinutes(0);
                dateCompleted.setSeconds(0);
                dateCompleted.setMilliseconds(0);
                acc.push([dateCompleted, task]);
              }
              // todo: potentially, look back
              // if (task.recurringOptions) {
              //   const gen = generateNextScheduledDate(task, from);
              //   // eslint-disable-next-line no-constant-condition
              //   while (true) {
              //     const nextDue = gen.next().value;
              //     if (!nextDue) break;
              //     if (nextDue > until) break;
              //     acc.push([nextDue, task]);
              //   }
              // }
              return acc;
            },
            []
          )
          .sort(([aDate], [bDate]) => {
            return compareDates(aDate, bDate);
          })
          .reduce(
            (
              acc: {
                [key: string]: (TaskEnrichedWithProjectAndTags &
                  TaskEnrichedWithBlockStats)[];
              },
              [date, task]
            ) => {
              const dt = formatDateWithoutTime(date);
              (acc[dt] = acc[dt] || []).push(task);
              return acc;
            },
            {}
          )
      )
      .then((tasks) => {
        this.setDateTasks(
          Object.entries(tasks).map(([key, tx]) => {
            return {
              date: key,
              tasks: tx.sort((t1, t2) =>
                compareTimestamps(t1.completedAt, t2.completedAt)
              ),
            };
          })
        );
      });
  }

  loadData = debounce(this.loadDataNotDebounced, 200);

  handleDbChangeEvent = (evt: RxChangeEvent) => {
    if (evt.operation === 'INSERT') {
      if ([ItemType.task].includes(evt.collectionName as ItemType)) {
        this.loadData();
      }
    }
    if (evt.operation === 'UPDATE' || evt.operation === 'DELETE') {
      if (
        [ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)
      ) {
        this.loadData();
      }
    }
  };

  refresh = () => {
    this.loadData();
    this.loadData.flush();
    logDebug([], 'archive list refresh');
  };

  canGoForward = () => {
    return this.state.dateInterval.to < this.props.date;
  };

  goBack = () => {
    this.setDateInterval(
      makeDateInterval(subMonths(this.state.dateInterval.from, 1))
    );
  };

  goForward = () => {
    // let newFrom = subMonths(this.state.dateInterval.from, 2);
    // const monthStart = endOfMonth(new Date());
    // if (newFrom < monthStart) {
    //   newFrom = monthStart;
    // }
    this.setDateInterval(makeDateInterval(addMonths(this.state.dateInterval.to, 1)));
  };

  getRef = (idx: number) => {
    const { getRef } = this.props;
    return getRef ? getRef(idx) : undefined;
  };

  render() {
    const {
      _techDebt_daoCatalog,
      repos: { taskRepo, blockRepo },
      context,
      selectedIdx,
    } = this.props;

    const { dateInterval, dateTasks, tasksToIdx } = this.state;

    return (
      <QuickAdd
        form={({ onClose, ref, onSave, isOpen }) => (
          <QuickAddFormTask
            isOpen={isOpen}
            onClose={onClose}
            daoCatalog={_techDebt_daoCatalog}
            onSave={(args) =>
              taskRepo
                .create(args)
                .then((taskId) => {
                  if (args.blocks) {
                    return Promise.all(
                      args.blocks.map((block) =>
                        blockRepo.create({
                          ...block,
                          taskId,
                          contextId: args.contextId,
                        })
                      )
                    );
                  }
                })
                .then(onSave)
            }
            ref={ref}
          />
        )}
      >
        <IonToolbar>
          <IonButton slot="start" size="small" color="light" onClick={this.goBack}>
            <IonIcon icon={chevronBack} />
            &nbsp; Back
          </IonButton>
          <IonNote>
            {format(dateInterval.from, 'dd MMM yyyy')} -&nbsp;
            {format(dateInterval.to, 'dd MMM yyyy')}
          </IonNote>
          <IonButton
            disabled={!this.canGoForward()}
            slot="end"
            size="small"
            color="light"
            onClick={this.goForward}
          >
            <IonIcon icon={chevronForward} />
            &nbsp; Next
          </IonButton>
        </IonToolbar>
        <TaskActionsWrapper>
          {(taskHandlers) => (
            <IonList>
              {dateTasks.map((tf) => (
                <SimpleTasksList
                  tf={tf}
                  taskHandlers={taskHandlers}
                  key={tf.date}
                  tasksToIdx={tasksToIdx}
                  getRef={this.getRef}
                  selectedIdx={selectedIdx}
                />
              ))}
            </IonList>
          )}
        </TaskActionsWrapper>
      </QuickAdd>
    );
  }
}

const makeTaskIdxKey = (tf: DateTask, task: TaskTypeToUseHere): string =>
  `${tf.date}-${task.id}`;

interface SimpleTasksListProps {
  tf: DateTask;
  taskHandlers: TaskHandlers;
  getRef: (idx: number) => React.RefObject<any> | undefined;
  tasksToIdx: Map<string, number>;
  selectedIdx?: number;
}

const SimpleTasksList = ({
  getRef,
  tf,
  taskHandlers,
  tasksToIdx,
  selectedIdx,
}: SimpleTasksListProps) => {
  return (
    <>
      <IonListHeader>
        <span>
          <span>{format(makeDateWithoutTime(tf.date), 'eee, do MMMM yyyy')}</span>
          &nbsp;
          <IonBadge>{tf.tasks.length}</IonBadge>
          &nbsp;
          <IonBadge color="medium">
            <IonIcon icon={stopwatch} />
            {formatTime(tf.tasks.reduce((acc, t) => acc + (t.time || 0), 0))}
          </IonBadge>
        </span>
      </IonListHeader>
      {tf.tasks.map((task) => (
        <SimpleTaskRow
          key={`archive-${tf.date}-${task.id}`}
          tf={tf}
          task={task}
          getRef={getRef}
          tasksToIdx={tasksToIdx}
          selectedIdx={selectedIdx}
          taskHandlers={taskHandlers}
        />
      ))}
    </>
  );
};

interface SimpleTaskRowProps {
  tf: DateTask;
  task: TaskTypeToUseHere;
  selectedIdx?: number;
  getRef: (idx: number) => React.RefObject<any> | undefined;
  tasksToIdx: Map<string, number>;
  taskHandlers: TaskHandlers;
}

const SimpleTaskRow = ({
  tf,
  task,
  getRef,
  tasksToIdx,
  selectedIdx,
  taskHandlers,
}: SimpleTaskRowProps) => {
  const taskIdxKey = makeTaskIdxKey(tf, task);
  const idx = tasksToIdx.get(taskIdxKey);
  if (idx === undefined)
    throw Error(`no idx: ${idx} in ${JSON.stringify(tasksToIdx)}`);
  return (
    <div>
      <TaskListRow
        ref={getRef(idx)}
        // ref={idx ? getRef(idx) : undefined}
        isSelected={idx === selectedIdx}
        task={task}
        taskHandlers={taskHandlers}
      />
    </div>
  );
};

export default withDate(withStorage(withWorkspace(ArchiveTaskListView)));
