import React from 'react';
import TaskStatusList from '../components/Tasks/TaskStatusList';
import { IonContent, IonPage } from '@ionic/react';
import { withStorage } from '../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../helpers/WorkspaceProviders';
import { Repos, StorageDeps } from '../helpers/InitStorage';
import TaskListFilter from '../components/Tasks/TaskListFilter';
import CommonHeader from '../components/Common/CommonHeader';
import { TaskStatusKey } from '../helpers/TaskHelper';
import ListNavigation from '../components/Common/ListNavigation';
import { DateProps, withDate } from '../helpers/DateProvider';

// interface State {
//   // projectsWithSomeTasks: {
//   //   tasks: (TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats)[];
//   //   project: ProjectEnrichedWithTaskStats;
//   // }[];
// }

interface Props extends WorkspaceProps, StorageDeps, DateProps {
  repos: Repos;
}

// const PROJECT_SOME = 'PROJECT_SOME';

class SomeTime extends React.Component<Props> {
  // state: State = {
  //   projectsWithSomeTasks: [],
  // };

  // sub?: Subscription;

  // componentDidUpdate(prevProps: Props) {
  //   if (this.props.context.id !== prevProps.context.id) {
  //     this.loadProjectSomeTaskData();
  //   }
  // }
  //
  // handleDbChangeEvent = (evt: RxChangeEvent) => {
  //   if (evt.operation === 'INSERT') {
  //     if ([ItemType.task].includes(evt.collectionName as ItemType)) {
  //       this.loadProjectSomeTaskData();
  //     }
  //   }
  //   if (evt.operation === 'UPDATE' || evt.operation === 'DELETE') {
  //     if (
  //       [ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)
  //     ) {
  //       this.loadProjectSomeTaskData();
  //     }
  //   }
  // };
  //
  // componentDidMount(): void {
  //   this.loadProjectSomeTaskData();
  //   this.sub = this.props.subscribeForDbChanges(this.handleDbChangeEvent);
  // }
  // componentWillUnmount() {
  //   this.sub?.unsubscribe();
  // }

  // loadProjectSomeTaskData = async () => {
  //   const { context, repos, dateFormatted } = this.props;
  //   // get active projects
  //   const activeProjects = await repos.projectRepo.getAllForFilter(
  //     context.id,
  //     dateFormatted,
  //     {},
  //     ProjectPositionContextEnum.STATUS
  //   );
  //   const projectTaskPairs = activeProjects.map(async (project) => {
  //     const tasks = await getSomeTasksForProject(
  //       context,
  //       project,
  //       repos.taskRepo,
  //       dateFormatted
  //     );
  //     if (tasks) {
  //       return { project, tasks };
  //     }
  //     return null;
  //   });
  //   const projectsWithSomeTasks = (await Promise.all(projectTaskPairs)).filter(
  //     (Boolean as any) as ExcludesNull
  //   );
  //   const tagIds = projectsWithSomeTasks
  //     .map((p) => p.tasks.map((t) => t.tags || []).flat())
  //     .flat()
  //     .map((t) => t.id);
  //   const tags = await repos.tagRepo.getByIds(tagIds);
  //   const enriched = projectsWithSomeTasks.map((r) => ({
  //     project: r.project,
  //     tasks: r.tasks.map((task) => ({
  //       ...task,
  //       tagsData: (task.tags || []).map(
  //         (t) => tags.filter((tag) => tag.id === t.id)[0]
  //       ),
  //       project: r.project,
  //     })),
  //   }));
  //   this.setState({ projectsWithSomeTasks: enriched });
  // };

  render() {
    // const { projectsWithSomeTasks } = this.state;

    return (
      <IonPage>
        <CommonHeader />
        <IonContent>
          {/*<ListNavigation collections={[TaskStatusKey.SomeTime, PROJECT_SOME]}>*/}
          <ListNavigation collections={[TaskStatusKey.SomeTime]}>
            {(listNavigationApi) => (
              <TaskListFilter>
                {({ filter, setUniqueAndStatsFilterValues }) => (
                  <>
                    <TaskStatusList
                      // defaultStatus={TaskStatus.STATUS_SOME_TIME}
                      listNavigationApi={listNavigationApi}
                      filter={filter}
                      setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                      statuses={[TaskStatusKey.SomeTime]}
                      emptyMessage="No tasks here yet. Tasks that you're not sure when to work on will appear here"
                    />

                    {/*<ProjectSomedayTasks*/}
                    {/*  handle={PROJECT_SOME}*/}
                    {/*  filter={filter}*/}
                    {/*  projectsWithSomeTasks={projectsWithSomeTasks}*/}
                    {/*  listNavigationApi={listNavigationApi}*/}
                    {/*  setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}*/}
                    {/*/>*/}
                  </>
                )}
              </TaskListFilter>
            )}
          </ListNavigation>
        </IonContent>
      </IonPage>
    );
  }
}

export default withDate(withStorage(withWorkspace(SomeTime)));
