import React, { createContext, useContext } from 'react';
import {
  ContextType,
  ItemType,
  ProjectStatus,
  TaskStatus,
  TaskType,
} from '../types/CoreTypes';
import { withStorage } from './StorageProviders';
import { Repos, StorageDeps } from './InitStorage';
import { withWorkspace, WorkspaceProps } from './WorkspaceProviders';
import { groupBy, intersection } from 'lodash';
import { taskStatuses, TaskStatusKey } from './TaskHelper';
import { projectStatuses, ProjectStatusKey } from './ProjectHelper';
import TaskRepo from '../lib/TaskRepo';
import ProjectRepo from '../lib/ProjectRepo';
import { Subscription } from 'rxjs';
import { RxChangeEvent } from 'rxdb/plugins/core';
import { debouncedByArguments } from './func';
import { DateProps, withDate } from './DateProvider';
import { logDebug } from '../lib/logger';
import { Badge } from '@ionic-native/badge';
import { Capacitor } from '@capacitor/core';

// @ts-ignore
export const CountersContext = createContext<CountersProps>(undefined);

export interface CountersProps {
  counters: { [contextId: string]: CountersSources };
}

export interface CountersSources {
  taskSources: { [key in TaskStatusKey]?: Set<string> };
  projectSources: { [key in ProjectStatusKey]?: Set<string> };
}

interface Props extends StorageDeps, WorkspaceProps, DateProps {
  children: any;
  allContexts: ContextType[] | undefined;
}

interface State {
  counters: { [key: string]: CountersSources };
}

async function trySetBadge(num: number) {
  /*
   const info = await Device.getInfo();
   if (info.platform === 'ios' || info.platform === 'android') {
   */
  if (!Capacitor.isNative) return;
  if (!Badge) return;
  const check = await Badge.hasPermission();
  if (check) {
    Badge.set(num);
  } else {
    // const request = await Badge.requestPermission();
    // if (request) {
    //   Badge.set(num);
    // } else {
    //   alert('the app cannot set badges');
    // }
  }
}

class CountersProviderClass extends React.PureComponent<Props, State> {
  state: State = { counters: {} };
  dbUpdateSubscription?: Subscription;

  // setCounters = (sources: Partial<CountersSources>, contextId: string) => {
  //   const counters = this.state.counters;
  //   const counter: CountersSources = this.state.counters[contextId] || {
  //     projectSources: {},
  //     taskSources: {},
  //   };
  //   if (!sources.projectSources && !sources.taskSources) return;
  //   if (sources.projectSources) {
  //     counter.projectSources = sources.projectSources;
  //   }
  //   if (sources.taskSources) {
  //     counter.taskSources = sources.taskSources;
  //   }
  //
  //   this.setState({
  //     counters: {
  //       ...counters,
  //       [contextId]: counter,
  //     },
  //   });
  // };

  // updateOneContextCounts = async (contextId: string) => {
  //   logDebug([], 'counters: updateTaskCounts');
  //   const sourcesMap = await reloadCountersForContexts(this.props.repos, [
  //     contextId
  //   ]);
  //   const sources = sourcesMap.get(contextId);
  //   if (sources) {
  //     this.setCounters(sources, contextId);
  //   }
  // };

  private _updateContextCounts = (contextIds?: string[]) => {
    this.props.requestDbIdlePromise(async () => {
      const contexts = contextIds || this.props.allContexts?.map((c) => c.id);
      if (!contexts) return;
      logDebug([], `counters: updateContextCounts: ${contexts}`);

      const contextData = await reloadCountersForContexts(
        this.props.repos,
        contexts,
        this.props.dateFormatted
      );

      const counters = Object.assign({}, this.state.counters);

      Object.entries(contextData).forEach(([contextId, counterSources]) => {
        counters[contextId] = counterSources;
        Object.entries(counterSources.taskSources).forEach(([status, count]) => {
          if (count !== undefined) {
            this.props.countersChangeSubject.next({
              contextId,
              status: status as TaskStatusKey,
              count: count.size,
            });
          }
        });
      });

      const redCounters = Object.entries(counters).reduce((acc, [_, sources]) => {
        // note: need to keep in sync with components/Menu.getContextCounter
        const dueToday = new Set([
          ...(sources.taskSources[TaskStatusKey.Due] || []),
          ...(sources.taskSources[TaskStatusKey.Inbox] || []),
        ]).size;
        return acc + dueToday;
      }, 0);

      trySetBadge(redCounters);

      this.setState({ counters });
      logDebug([], 'counters: updateContextCounts ...done', counters);
    });
  };

  private updateContextCountsThrottled = debouncedByArguments(
    this._updateContextCounts,
    200
  );

  // handleTaskChanged = () => {
  //   this.updateOneContextCounts(this.props.context.id);
  // };
  // handleProjectChanged = () => {
  //   this.updateOneContextCounts(this.props.context.id);
  // };
  // handleContextChanged = () => {
  //   this.updateContextCounts();
  // };
  // handleSyncEvent = (event: ImportCallBackEvent) => {
  //   if (event.operation === SyncOperation.SYNCING && event.done) {
  //     this.updateContextCounts();
  //   }
  // };

  shouldTriggerCounterUpdate = (evt: RxChangeEvent): string | null => {
    if (evt.operation === 'UPDATE') {
      if (
        [ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)
      ) {
        const oldData = evt.previousData;
        const newData = evt.documentData;
        const contextId = newData['contextId'] as string;

        if (!oldData) return contextId;

        const changedFields = Object.entries(newData).reduce(
          (changed, [key, newValue]) => {
            const oldValue = oldData[key];
            if (oldValue !== newValue) {
              return [...changed, key];
            }
            return changed;
          },
          [] as string[]
        );

        if (
          intersection(
            ['status', 'projectId', 'dateScheduled', 'dateDue', 'recurringOptions'],
            changedFields
          ).length
        ) {
          return contextId;
        }
      }
    }
    if (evt.operation === 'INSERT') {
      if (
        [ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)
      ) {
        const newData = evt.documentData;
        return newData['contextId'] as string;
      }
      if ([ItemType.context].includes(evt.collectionName as ItemType)) {
        const newData = evt.documentData;
        return newData['id'] as string;
      }
    }
    if (evt.operation === 'DELETE') {
      if (
        [ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)
      ) {
        const newData = evt.documentData;
        return newData['contextId'] as string;
      }
      if ([ItemType.context].includes(evt.collectionName as ItemType)) {
        const newData = evt.documentData;
        return newData['id'] as string;
      }
    }
    return null;
  };

  handleDbChangeEvent = (evt: RxChangeEvent) => {
    const shouldUpdateContext = this.shouldTriggerCounterUpdate(evt);
    if (shouldUpdateContext) {
      this.updateContextCountsThrottled([shouldUpdateContext]);
    }
  };

  componentDidMount(): void {
    this.updateContextCountsThrottled();

    this.dbUpdateSubscription = this.props.subscribeForDbChanges(
      this.handleDbChangeEvent
    );
  }

  componentWillUnmount() {
    this.dbUpdateSubscription?.unsubscribe();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.dateFormatted !== prevProps.dateFormatted) {
      logDebug([], 'date changed, updating all contexts');
      this.updateContextCountsThrottled();
    }
  }

  render() {
    const { children } = this.props;
    const { counters } = this.state;

    return (
      <CountersContext.Provider
        value={{
          counters: counters,
        }}
      >
        {children}
      </CountersContext.Provider>
    );
  }
}

export const CountersProvider = withDate(
  withWorkspace(withStorage(CountersProviderClass))
);

export const useCounters: () => CountersProps = () => useContext(CountersContext);

export const withCounters = <P extends Partial<CountersProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof CountersProps>> => {
  return function withCountersContext(props) {
    return (
      <CountersContext.Consumer>
        {(eventProps: CountersProps) => (
          <Component {...(props as P)} {...eventProps} />
        )}
      </CountersContext.Consumer>
    );
  };
};

const getProjectNextInMem = (
  activeProjectIds: string[],
  contextTasks: TaskType[],
  dateFormatted: string
): { next: Set<string>; focus: Set<string>; projWithNext: Set<string> } => {
  const fn = TaskRepo.makeFilterFn(
    {
      hasProject: true,
      statusIn: [TaskStatus.STATUS_ACTION_LIST, TaskStatus.STATUS_IN_PROGRESS],
    },
    dateFormatted
  );
  const actions = contextTasks.filter(fn);
  const grouping = groupBy(
    actions.filter((a) => a.projectId && activeProjectIds.includes(a.projectId)),
    (a) => a.projectId
  );
  return Object.entries(grouping).reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (acc, [_, tasks]) => {
      const nextTask = tasks[0];
      // // don't double count task that is project next and is due soon
      // if (nextTask.status === TaskStatus.STATUS_ACTION_LIST && nextTask.dateDue) {
      //   if (
      //     nextTask.dateDue <=
      //       formatDateWithoutTime(addDays(makeDateWithoutTime(dateFormatted), 7)) &&
      //     nextTask.dateDue > dateFormatted
      //   ) {
      //     acc.next--;
      //   }
      // }
      if (nextTask.status === TaskStatus.STATUS_ACTION_LIST) {
        acc.next.add(nextTask.id);
        if (nextTask.projectId) {
          acc.projWithNext.add(nextTask.projectId);
        }
      }
      if (nextTask.status === TaskStatus.STATUS_IN_PROGRESS) {
        acc.focus.add(nextTask.id);
        if (nextTask.projectId) {
          acc.projWithNext.add(nextTask.projectId);
        }
      }
      return acc;
    },
    {
      next: new Set<string>(),
      focus: new Set<string>(),
      projWithNext: new Set<string>(),
    }
  );
};

// tasks and projects refresh at the same time!
async function reloadCountersForContexts(
  repos: Repos,
  contextIds: string[],
  dateFormatted: string
): Promise<{ [key: string]: CountersSources }> {
  const allProjects = await repos.projectRepo.getAllForStats();

  const activeProjectIds = allProjects
    .filter((p) => p.status === ProjectStatus.STATUS_ACTION)
    .map((p) => p.id);
  // return new Map(cs as [ProjectStatusKey, number][]);

  const projectsGrouped = groupBy(allProjects, (e) => e.contextId);

  const allTasks = await repos.taskRepo.getAllForStats();
  const tasksGrouped = groupBy(allTasks, (e) => e.contextId);

  return Object.fromEntries(
    contextIds.map((contextId) => {
      const contextTasks = tasksGrouped[contextId] || [];
      const taskSources = Object.fromEntries(
        Object.entries(taskStatuses).map(([key, status]) => {
          if (status.filter) {
            const fn = TaskRepo.makeFilterFn(status.filter, dateFormatted);

            return [
              key as TaskStatusKey,
              new Set(contextTasks.filter(fn).map((r) => r.id)),
            ];
          } else {
            return [key as TaskStatusKey, new Set<string>()];
          }
        })
      );
      const { next, focus, projWithNext } = getProjectNextInMem(
        activeProjectIds,
        contextTasks,
        dateFormatted
      );
      taskSources[TaskStatusKey.ProjectNext] = next;
      taskSources[TaskStatusKey.ProjectFocus] = focus;

      const contextProjects = projectsGrouped[contextId] || [];
      const projectSources = Object.fromEntries(
        Object.entries(projectStatuses).map(([key, status]) => {
          if (status.filter) {
            const fn = ProjectRepo.makeFilterFn(status.filter, dateFormatted);

            return [
              key as ProjectStatusKey,
              new Set(contextProjects.filter(fn).map((r) => r.id)),
            ];
          } else {
            return [key as ProjectStatusKey, new Set<string>()];
          }
        })
      );
      projectSources[ProjectStatusKey.ProjectWithNext] = projWithNext;

      return [contextId, { taskSources, projectSources }];
    })
  );
}
