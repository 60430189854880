import React from 'react';
import { ExcludesUndefined, TagType } from '../../types/CoreTypes';
import { genericStyles } from '../../helpers/styles';
import TagLink from './TagLink';
import { create } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

interface Props {
  tags: TagType[] | null;
  onEdit?: () => void;
  onElementClick?: (id: string) => void;
  disableLink?: boolean;
  onAfterNavigate?: () => void;
  compact?: boolean;
}

const TagsWidget = ({
  tags,
  disableLink,
  onEdit,
  compact,
  onAfterNavigate,
  onElementClick,
}: Props) => {
  return (
    <span
      className={onEdit && !tags?.length ? 'clickable-control' : 'inline'}
      style={{ display: 'flex' }}
    >
      {onEdit && (
        <div
          style={genericStyles.propertyClickable}
          className={onEdit && tags?.length ? 'clickable-control' : ''}
        >
          <IonIcon
            color="medium"
            style={genericStyles.icon}
            icon={create}
            onClick={onEdit}
          />
        </div>
      )}

      {tags && tags.length > 0 && (
        <div
          className="tags-list"
          style={{ display: 'flex', flexWrap: compact ? 'nowrap' : 'wrap' }}
        >
          {tags.filter((Boolean as any) as ExcludesUndefined).map((t) => (
            <TagLink
              onClick={onElementClick}
              onAfterNavigate={onAfterNavigate}
              key={t.id}
              tag={t}
              disableLink={disableLink}
            />
          ))}
        </div>
      )}

      {(!tags || (tags && !tags.length)) && (
        <span
          style={{
            ...genericStyles.grayOutColor,
            ...(onEdit ? genericStyles.clickable : {}),
          }}
          onClick={onEdit}
        >
          no tags
        </span>
      )}
    </span>
  );
};

export default TagsWidget;
