import {
  PartialUpdatedRecord,
  TaskCreateForm,
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
  TaskFields,
  TaskFilter,
  TaskPositionContextEnum,
  TaskStatus,
  TaskType,
} from '../types/CoreTypes';
import addDays from 'date-fns/addDays';
import { TASK_NAME_MAX_LENGTH } from './Constants';
import { changePositionsInEntities } from './SortHelper';
import { getTaskPositionKey } from '../helpers/PositionHelper';
import { getPriorityBoundaries, getPriorityScore } from '../helpers/PriorityHelper';
import { sanitizeSearchText } from './FilterHelper';
import PouchDB from 'pouchdb';
import { TaskModel } from './data/Models';
import { BaseRepo } from './BaseRepo';
import { first, pick } from 'lodash';
import { sort } from './data/PouchDAO';
import {
  formatDateWithoutTime,
  getLastCompleted,
  makeDateWithoutTime,
  mergeCompletionHistory,
  taskIsRepeatedScheduledOnDate,
  willHaveMoreRepeats,
} from '../helpers/RecurringHelper';
import { logDebug } from './logger';

export default class TaskRepo extends BaseRepo<TaskFields, TaskPositionContextEnum> {
  getAllForStatus(
    contextId: string,
    status: TaskStatus
  ): Promise<TaskEnrichedWithProjectAndTags[]> {
    return this.dao.findAll({
      selector: {
        status,
        contextId,
      },
      sortContext: TaskPositionContextEnum.STATUS,
      enrichments: [TaskModel.projectRelation, TaskModel.tagRelation],
    });
  }

  async getAllNotInStatuses(
    contextId: string,
    statuses: TaskStatus[]
  ): Promise<TaskEnrichedWithProjectAndTags[]> {
    return this.dao.findAll({
      selector: {
        status: {
          $in: this.convertNotInStatusIntoInStatus(statuses),
        },
        contextId,
        // TODO: may need custom sort
      },
      sortContext: TaskPositionContextEnum.STATUS,
      enrichments: [TaskModel.projectRelation, TaskModel.tagRelation],
    });
  }

  async getAllForStats(): Promise<TaskType[]> {
    return this.dao.findAll({
      selector: {
        status: {
          $ne: TaskStatus.STATUS_DONE,
        },
      },
    });
  }

  getStatus(args: TaskCreateForm): TaskStatus {
    if (args.dateScheduled) {
      return TaskStatus.STATUS_SCHEDULED;
    }
    return args.status ? args.status : TaskStatus.STATUS_INBOX;
  }

  _forceCreate(doc: TaskType) {
    return this.dao._forceCreate(doc);
  }

  async create(args: TaskCreateForm): Promise<string> {
    const nameTrimmed = args.name.trim();
    if (nameTrimmed.length < 2) {
      throw new Error('name should be at least 2 characters!');
    }
    if (nameTrimmed.length > TASK_NAME_MAX_LENGTH) {
      throw new Error(`name should be at max ${TASK_NAME_MAX_LENGTH} characters!`);
    }
    const entity = {
      name: nameTrimmed.substring(0, TASK_NAME_MAX_LENGTH),
      // description: args.description || null,
      contextId: args.contextId,
      projectId: args.project?.id || undefined,
      status: args.status, //this.getStatus(args),
      dateDue: args.dateDue || undefined,
      dateScheduled: args.dateScheduled || undefined,
      priorityValues: args.priorityValues || undefined,
      recurringOptions: args.recurringOptions || undefined,
      lastUpdated: new Date().getTime(),
      createdAt: new Date().getTime(),
      time: args.time || undefined,
      energy: args.energy || undefined,
      tags: args.tags?.map((t) => ({ id: t.id })) || undefined,
    };
    return this.dao.create(entity);
  }

  // todo refactor with mixins https://basarat.gitbook.io/typescript/type-system/mixins
  async reorder(
    positionContext: TaskPositionContextEnum,
    tasks: TaskType[],
    from: number,
    to: number
  ) {
    const positionKey = getTaskPositionKey(positionContext);
    const changeSets = changePositionsInEntities(tasks, from, to).map(
      (task, idx) => ({
        id: task.id,
        [positionKey]: idx,
      })
    );
    return this.dao.editManyWithDebounce(changeSets);
  }

  async reorderForTags(tagId: string, tasks: TaskType[], from: number, to: number) {
    const changeSets = changePositionsInEntities(tasks, from, to).map(
      (task, idx) => ({
        id: task.id,
        tags: [
          ...(task.tags?.filter((t) => t.id !== tagId) || []),
          {
            id: tagId,
            position: idx,
          },
        ],
      })
    );
    return this.dao.editManyWithDebounce(changeSets);
  }

  async complete(task: TaskType, endRecurring: boolean): Promise<void> {
    const now = new Date();
    if (!task.recurringOptions) {
      return this.edit({
        id: task.id,
        status: TaskStatus.STATUS_DONE,
        completedAt: now.getTime(),
      });
    }

    const current = taskIsRepeatedScheduledOnDate(now, task);
    let newCompletionHistory;
    let newCompletedAt;
    if (!endRecurring && !current.dueDate) {
      logDebug([], 'trying to complete task that is not due');
      newCompletionHistory = task.completionHistory;
      newCompletedAt = task.completedAt;
    } else {
      newCompletionHistory = mergeCompletionHistory(now, task.completionHistory);
      newCompletedAt = new Date().getTime();
    }
    const newStatus =
      !endRecurring &&
      willHaveMoreRepeats(now, {
        ...task,
        completionHistory: newCompletionHistory,
      })
        ? TaskStatus.STATUS_SCHEDULED
        : TaskStatus.STATUS_DONE;
    const changeSet: PartialUpdatedRecord<TaskType> = {
      id: task.id,
      status: newStatus,
      completedAt: newCompletedAt,
      completionHistory: newCompletionHistory,
      dateScheduled: undefined,
    };
    // if (endRecurring) {
    //   changeSet.recurringOptions = {
    //     ...task.recurringOptions,
    //     endsAfter: {
    //       date: formatDateWithoutTime(new Date())
    //     }
    //   };
    // }
    return this.edit(changeSet);
  }

  async unComplete(
    task: TaskType,
    prevStatus: TaskStatus,
    prevScheduled?: string
  ): Promise<void> {
    if (!task.recurringOptions) {
      return this.edit({
        id: task.id,
        status: prevStatus,
        dateScheduled: prevScheduled,
        completedAt: undefined,
      });
    }
    const lastCompleted = getLastCompleted(task.completionHistory);
    let newCompletionHistory = undefined;
    if (lastCompleted) {
      newCompletionHistory = task.completionHistory?.filter(
        (c) => c.dueDate !== lastCompleted.dueDate
      );
    }
    const anotherLastCompleted = getLastCompleted(newCompletionHistory);
    return this.edit({
      id: task.id,
      status: prevStatus,
      completedAt: anotherLastCompleted?.timestamp,
      completionHistory: newCompletionHistory,
      dateScheduled: prevScheduled,
    });
  }

  async edit(entity: PartialUpdatedRecord<TaskType>): Promise<void> {
    if (entity.name) {
      const nameTrimmed = entity.name.trim();
      if (nameTrimmed.length < 2) {
        throw new Error('name should be at least 2 characters!');
      }
      entity.name = nameTrimmed.substring(0, TASK_NAME_MAX_LENGTH);
    }
    // reset positions
    if (entity.status) {
      entity.positionStatus = undefined;
      entity.statusUpdatedAt = new Date().getTime();
    }
    if (entity.projectId) {
      entity.positionProject = undefined;
    }
    if (entity.dateScheduled || entity.dateDue) {
      entity.positionCalendar = undefined;
    }
    return this.dao.edit(entity);
  }

  // addManyWithValuesBelow = (
  //   task: TaskEnriched,
  //   values: BlockMinValue[],
  //   id?: string
  // ) => {
  //   return this.dao.edit({
  //     id: task.id,
  //     blocks: insertManyWithValuesAtAndBelow(task, values, id)
  //   });
  // };
  //
  // editBlock = (task: TaskEnriched, blockChangeSet: BlockChangeSet) => {
  //   return this.dao.edit({
  //     id: task.id,
  //     blocks: editBlock(task, blockChangeSet)
  //   });
  // };
  //
  // addBlockUnder = (task: TaskEnriched, id?: string) => {
  //   return this.dao.edit({
  //     id: task.id,
  //     blocks: addBlockUnder(task, id)
  //   });
  // };
  //
  // deleteBlock = (task: TaskEnriched, id: string) => {
  //   try {
  //     return this.dao.edit({
  //       id: task.id,
  //       blocks: deleteBlock(task, id)
  //     });
  //     // eslint-disable-next-line no-empty
  //   } catch (e) {
  //     return Promise.resolve();
  //   }
  // };
  //
  // moveBlockUp = (task: TaskEnriched, id: string) => {
  //   try {
  //     return this.dao.edit({
  //       id: task.id,
  //       blocks: moveBlockUp(task, id)
  //     });
  //     // eslint-disable-next-line no-empty
  //   } catch (e) {
  //     return Promise.resolve();
  //   }
  // };
  //
  // moveBlockDown = (task: TaskEnriched, id: string) => {
  //   try {
  //     return this.dao.edit({
  //       id: task.id,
  //       blocks: moveBlockDown(task, id)
  //     });
  //     // eslint-disable-next-line no-empty
  //   } catch (e) {
  //     return Promise.resolve();
  //   }
  // };

  getById(id: string): Promise<TaskEnrichedWithProjectAndTags | null> {
    return this.dao.getById(id, {
      enrichments: [TaskModel.projectRelation, TaskModel.tagRelation],
    });
  }

  async getIncomplete(contextId: string): Promise<TaskType[]> {
    const otherTasks = await this.getAllNotInStatuses(contextId, [
      TaskStatus.STATUS_INBOX,
      TaskStatus.STATUS_DONE,
    ]);
    const inbox = await this.getAllForStatus(contextId, TaskStatus.STATUS_INBOX);
    return [...inbox, ...otherTasks.filter(TaskRepo.isIncomplete)];
  }

  private static isIncomplete(entity: TaskType): boolean {
    return !entity.projectId;
  }

  getAllForFilter(
    contextId: string,
    filter: TaskFilter,
    context: TaskPositionContextEnum,
    formattedDate: string,
    limit?: number
  ): Promise<(TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats)[]> {
    const { selector, filterFn, enrichedFilterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    return this.dao.findAll({
      selector,
      filterFn,
      enrichedFilterFn,
      sortContext: context,
      limit,
      enrichments: [
        TaskModel.projectRelation,
        TaskModel.blockCounterEnrichment,
        TaskModel.tagRelation,
      ],
    });
  }

  getAllForFilterWithSort(config: {
    contextId: string;
    filter: TaskFilter;
    sortFn: (a: TaskType, b: TaskType) => number;
    enrichedSortFn?: (
      a: TaskEnrichedWithProjectAndTags,
      b: TaskEnrichedWithProjectAndTags
    ) => number;
    formattedDate: string;
    limit?: number;
  }): Promise<(TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats)[]> {
    const {
      contextId,
      filter,
      sortFn,
      formattedDate,
      limit,
      enrichedSortFn,
    } = config;
    const { selector, filterFn, enrichedFilterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    return this.dao.findAll({
      selector,
      filterFn,
      enrichedFilterFn,
      sortFn,
      enrichedSortFn,
      limit,
      enrichments: [
        TaskModel.projectRelation,
        TaskModel.blockCounterEnrichment,
        TaskModel.tagRelation,
      ],
    });
  }

  getAllForFilterWithoutSort(
    contextId: string,
    filter: TaskFilter,
    formattedDate: string
  ): Promise<(TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats)[]> {
    const { selector, filterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    return this.dao.findAll({
      selector,
      filterFn,
      enrichments: [
        TaskModel.projectRelation,
        TaskModel.blockCounterEnrichment,
        TaskModel.tagRelation,
      ],
    });
  }

  getAllForFilterForTags(
    contextId: string,
    filter: TaskFilter,
    tagContext: string,
    formattedDate: string
  ): Promise<(TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats)[]> {
    const { selector, filterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    const matchedTagFilter = filter.tags?.filter((t) => t === tagContext) || [];
    if (!matchedTagFilter.length) {
      throw new Error('Incorrect usage of getAllForFilterForTags: no filter tag!');
    }
    type TagSortPosition = { position?: number; createdAt?: number };
    return this.dao.findAll({
      selector,
      filterFn,
      sortFn: (a, b) => {
        const aTag: TagSortPosition =
          first(a.tags?.filter((t) => t.id === tagContext)) || {};
        const bTag: TagSortPosition =
          first(b.tags?.filter((t) => t.id === tagContext)) || {};

        return sort([{ position: 'asc' }, { createdAt: 'asc' }])(
          { createdAt: a.createdAt, ...aTag },
          { createdAt: b.createdAt, ...bTag }
        );
      },
      enrichments: [
        TaskModel.projectRelation,
        TaskModel.blockCounterEnrichment,
        TaskModel.tagRelation,
      ],
    });
  }

  getAllForFilterWithOnlyStatsEnrichment(
    contextId: string,
    filter: TaskFilter,
    context: TaskPositionContextEnum,
    formattedDate: string
  ): Promise<TaskEnrichedWithBlockStats[]> {
    const { selector, filterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    return this.dao.findAll({
      selector,
      filterFn,
      sortContext: context,
      enrichments: [TaskModel.blockCounterEnrichment],
    });
  }

  async getCountForFilter(
    contextId: string,
    filter: TaskFilter,
    formattedDate: string
  ): Promise<number> {
    const { selector, filterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    return this.dao.getCount({ selector, filterFn });
  }

  private convertNotInStatusIntoInStatus(notStatuses: TaskStatus[]): TaskStatus[] {
    return Object.values(TaskStatus).filter((s) => !notStatuses.includes(s));
  }

  // TODO: can modify this, by selecting some with index, and then doing filter!
  private makeSelector(
    contextId: string,
    filter: TaskFilter,
    formattedDate: string
  ): {
    selector: PouchDB.Find.Selector;
    filterFn: (r: TaskType) => boolean;
    enrichedFilterFn?: (
      r: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats
    ) => boolean;
  } {
    const memoryFilterFields: Set<keyof TaskFilter> = new Set(
      Object.keys(filter) as (keyof TaskFilter)[]
    );

    const selector: PouchDB.Find.Selector = {};
    selector.contextId = contextId;
    if (filter.status) {
      selector.status = filter.status;
      memoryFilterFields.delete('status');
    }
    if (filter.notStatus) {
      // can't use NE here, because it will be translated to lt&gt, which won't
      // allow using index for following possible search on date
      selector.status = {
        $in: this.convertNotInStatusIntoInStatus([filter.notStatus]),
      };
      memoryFilterFields.delete('notStatus');
    }
    if (filter.statusIn) {
      selector.status = {
        $in: filter.statusIn,
      };
      memoryFilterFields.delete('statusIn');
    }
    if (filter.projectId) {
      selector.projectId = filter.projectId;
      selector.contextId = undefined;
      memoryFilterFields.delete('projectId');
    }
    if (filter.hasNoProject) {
      selector.projectId = { $eq: null };
      memoryFilterFields.delete('hasNoProject');
    }
    // let not = {};
    if (filter.hasProject) {
      selector.projectId = { $gt: 0 };
      memoryFilterFields.delete('hasProject');
    }
    // if (Object.keys(not).length > 0) {
    //   selector.$not = not;
    // }
    // if (filter.name) {
    //   // todo: regex is not using indexes!
    //   // todo: not sure about case sensitive, maybe RegExp ... i helps
    //   selector.name = { $regex: RegExp(filter.name, 'i') };
    // }
    let or: any[] = [];
    if (filter.due) {
      // completely replaces fn filtering
      selector.dateDue = { $lte: filter.due, $gt: 0 };
      memoryFilterFields.delete('due');
    }
    if (filter.dueNow) {
      // completely replaces fn filtering
      selector.dateDue = { $lte: formattedDate, $gt: 0 };
      memoryFilterFields.delete('dueNow');
    }
    if (filter.dueSoon) {
      // first part here, remaining in a fn
      selector.dateDue = { $gt: formattedDate };
    }
    if (filter.agendaFor) {
      // completely replaces fn filtering (?)
      or = [
        { dateScheduled: { $lte: filter.agendaFor, $gt: 0 } },
        { dateDue: { $lte: filter.agendaFor, $gt: 0 } },
        {
          'recurringOptions.starting.date': {
            $lte: filter.agendaFor,
            $gt: 0,
          },
        },
      ];
      // memoryFilterFields.delete('agendaFor');
    }
    if (filter.completedAt) {
      // completely replaces fn filtering
      selector.completedAt = {
        $lte: filter.completedAt.to,
        $gt: filter.completedAt.from,
      };
      memoryFilterFields.delete('completedAt');
    }
    if (filter.createdAt) {
      // completely replaces fn filtering
      selector.createdAt = {
        $lte: filter.createdAt.to,
        $gt: filter.createdAt.from,
      };
      memoryFilterFields.delete('createdAt');
    }
    if (filter.updatedAt) {
      // completely replaces fn filtering
      selector.updatedAt = {
        $lte: filter.updatedAt.to,
        $gt: filter.updatedAt.from,
      };
      memoryFilterFields.delete('updatedAt');
    }
    // if (filter.tags) {
    //   // filters here, but will be in-memory only
    //   selector.tags = { $in: [filter.tags] };
    //   memoryFilterFields.delete('tags');
    // }
    if (filter.forToday) {
      // first part here, remaining in a fn
      or = [
        { dateScheduled: { $lte: formattedDate, $gt: 0 } },
        { dateDue: { $lte: formattedDate, $gt: 0 } },
        {
          'recurringOptions.starting.date': {
            $lte: formattedDate,
            $gt: 0,
          },
        },
      ];
    }

    if (or.length > 0) {
      selector.$or = or;
    }

    return {
      selector,
      enrichedFilterFn: TaskRepo.makeEnrichedFilterFn(filter),
      filterFn: TaskRepo.makeFilterFn(
        pick(filter, Array.from(memoryFilterFields)),
        formattedDate
      ),
    };
  }

  static makeEnrichedFilterFn = (filter: TaskFilter) => {
    let useEnrichedFilter = false;

    if (filter.projectStatuses) {
      useEnrichedFilter = true;
    }

    if (!useEnrichedFilter) {
      logDebug([], 'NOT applying enrichedFilterFn');
      return undefined;
    }

    return (task: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats) => {
      logDebug([], 'applying enrichedFilterFn');
      let isMatch = true;
      if (filter.projectStatuses) {
        // no project means match
        if (task.project) {
          isMatch = filter.projectStatuses.includes(task.project.status);
        }
      }
      return isMatch;
    };
  };

  static makeFilterFn = (filter: TaskFilter, formattedDate: string) => (
    task: TaskType
  ) => {
    let isMatch = true;
    if (filter.contextId) isMatch = isMatch && task.contextId === filter.contextId;
    if (filter.status) isMatch = isMatch && task.status === filter.status;
    if (filter.statusIn) isMatch = isMatch && filter.statusIn.includes(task.status);
    if (filter.notStatus) isMatch = isMatch && task.status !== filter.notStatus;
    if (filter.projectId) isMatch = isMatch && task.projectId === filter.projectId;
    if (filter.hasNoProject) isMatch = isMatch && !task.projectId;
    if (filter.hasProject) isMatch = isMatch && !!task.projectId;
    if (filter.energy !== undefined)
      isMatch =
        isMatch &&
        ((!task.energy && filter.energy === null) ||
          (!!task.energy &&
            filter.energy !== null &&
            task.energy === filter.energy));
    // selected time or less
    if (filter.time !== undefined) {
      isMatch =
        isMatch &&
        ((!task.time && filter.time === null) ||
          (!!task.time && filter.time !== null && task.time <= filter.time));
    }
    if (filter.priority !== undefined) {
      const taskPriorityScore = getPriorityScore(task.priorityValues);
      if (filter.priority) {
        const filterPriorityScoreBoundaries = getPriorityBoundaries(filter.priority);
        isMatch =
          isMatch &&
          taskPriorityScore !== undefined &&
          taskPriorityScore >= filterPriorityScoreBoundaries[0] &&
          taskPriorityScore <= filterPriorityScoreBoundaries[1];
      } else {
        isMatch = isMatch && taskPriorityScore === undefined;
      }
    }
    // Please don't use full text search on all DB, filter first!
    if (filter.name)
      isMatch = isMatch && task.name.toLowerCase().includes(filter.name);
    if (filter.anyText)
      // TODO: find a way to search by rich fields, like proj name
      isMatch =
        isMatch &&
        sanitizeSearchText(task.name).includes(sanitizeSearchText(filter.anyText));
    if (filter.forToday)
      isMatch =
        isMatch &&
        task.status !== TaskStatus.STATUS_IN_PROGRESS &&
        ((task.dateScheduled !== undefined && task.dateScheduled <= formattedDate) ||
          (task.dateDue !== undefined && task.dateDue <= formattedDate) ||
          (!task.dateScheduled &&
            taskIsRepeatedScheduledOnDate(makeDateWithoutTime(formattedDate), task)
              .dueDate !== null));
    if (filter.dueSoon)
      isMatch =
        isMatch &&
        !(!task.projectId && task.status === TaskStatus.STATUS_ACTION_LIST) &&
        task.dateDue !== undefined &&
        task.dateDue <=
          formatDateWithoutTime(addDays(makeDateWithoutTime(formattedDate), 7)) &&
        task.dateDue > formattedDate;
    if (filter.due)
      isMatch = isMatch && task.dateDue !== undefined && task.dateDue <= filter.due;
    if (filter.dueNow)
      isMatch =
        isMatch && task.dateDue !== undefined && task.dateDue <= formattedDate;
    if (filter.agendaFor)
      isMatch =
        isMatch &&
        ((task.dateScheduled !== undefined &&
          task.dateScheduled <= filter.agendaFor) ||
          (task.dateDue !== undefined && task.dateDue <= filter.agendaFor) ||
          !!task.recurringOptions);
    // ||
    // (!task.dateScheduled &&
    //   taskIsRepeatedScheduledOnDate(new Date(filter.agendaFor), task)
    //     .dueDate !== null)
    if (filter.tags && filter.tags.length) {
      isMatch =
        isMatch &&
        !!task.tags &&
        filter.tags.every((t) => task.tags?.filter((tt) => tt.id === t).length);
    }
    return isMatch;
  };
}
