import React from 'react';
import AchievementBadge from './AchievementBadge';
import { AchievementWithStreak } from '../../types/CoreTypes';

interface Props {
  achievements: AchievementWithStreak[];
}

const DaysAchievements = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '3px',
        flexWrap: 'wrap',
      }}
    >
      {props.achievements.map((a) => (
        <AchievementBadge
          key={`${a.achievement?.achievement.id}-${a.kind}`}
          achievement={a}
        />
      ))}
    </div>
  );
};

export default DaysAchievements;
