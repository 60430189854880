import React from 'react';
import { CompletionHistory, TaskStatus, TaskType } from '../../types/CoreTypes';
import { IonBadge, IonButton, IonIcon, IonNote } from '@ionic/react';
import format from 'date-fns/format';
import { genericStyles } from '../../helpers/styles';
import { alarm, checkbox, repeat } from 'ionicons/icons';
import {
  generateNextScheduledDate,
  taskIsRepeatedScheduledOnDate,
} from '../../helpers/RecurringHelper';

interface Props {
  task: TaskType;
  onClick?: () => void;
  date: Date;
}

// function getColor(recurring: RecurringOptions | undefined) {
//   if (!recurring) return genericStyles.grayOutColor;
//   return genericStyles.primaryColor;
// }
//
// function renderLabel(recurring: RecurringOptions | undefined) {
//   if (!recurring) return <span style={genericStyles.iconLabel}>none</span>;
//   return (
//     <span style={genericStyles.iconLabel}>
//       {recurring.repeatingEvery.days && (
//         <span>
//           Every {recurring.repeatingEvery.days.days} days{' '}
//           {recurring.repeatingEvery.days.afterLastCompleted && <>after completed</>}
//         </span>
//       )}
//       {recurring.repeatingEvery.weeks && (
//         <span>
//           Every {recurring.repeatingEvery.weeks.weeks} weeks on{' '}
//           {recurring.repeatingEvery.weeks.weekdays
//             .sort()
//             .map(d => daysFull[d])
//             .join(', ')}
//         </span>
//       )}
//       {recurring.repeatingEvery.months && (
//         <span>
//           Every {recurring.repeatingEvery.months.months} months{' '}
//           {recurring.repeatingEvery.months?.weekday && (
//             <>
//               {recurring.repeatingEvery.months?.weekday.weekdayNumber > 4
//                 ? 'last'
//                 : recurring.repeatingEvery.months?.weekday.weekdayNumber}{' '}
//               {daysFull[recurring.repeatingEvery.months?.weekday.weekday]} of month
//             </>
//           )}
//           {recurring.repeatingEvery.months?.date && (
//             <>on day {recurring.repeatingEvery.months?.date}</>
//           )}
//         </span>
//       )}
//       {recurring.repeatingEvery.years && (
//         <span>
//           Every {recurring.repeatingEvery.years.years} years{' '}
//           {recurring.repeatingEvery.years.afterLastCompleted && <>after completed</>}
//         </span>
//       )}
//       {recurring.endsAfter.occurrences && (
//         <span>{recurring.endsAfter.occurrences} times</span>
//       )}
//       {recurring.endsAfter.date && (
//         <span>
//           {' '}
//           until{' '}
//           {format(makeDateWithoutTime(recurring.endsAfter.date), 'do MMM yyyy')}
//         </span>
//       )}
//     </span>
//   );
// }

function renderLabel(task: TaskType, date: Date) {
  if (task.status === TaskStatus.STATUS_DONE) {
    return (
      <span
        style={{
          ...genericStyles.propertyClickable,
          ...genericStyles.grayOutColor,
          fontWeight: 'bold',
        }}
      >
        <IonIcon style={genericStyles.icon} icon={repeat} />
        <span style={genericStyles.iconLabel}>Completed</span>
      </span>
    );
  }
  const due = taskIsRepeatedScheduledOnDate(date, task);
  if (due.dueDate) {
    return (
      <span
        style={{
          ...genericStyles.propertyClickable,
          ...genericStyles.orangeColor,
          fontWeight: 'bold',
        }}
      >
        <IonIcon style={genericStyles.icon} icon={alarm} />
        <span style={genericStyles.iconLabel}>
          Due since {format(due.dueDate, 'do MMM yyyy')}
        </span>
      </span>
    );
  }

  const gen = generateNextScheduledDate(task, date);
  const nextDue = gen.next().value;
  if (nextDue) {
    return (
      <span
        style={{
          ...genericStyles.propertyClickable,
          ...genericStyles.grayOutColor,
          fontWeight: 'bold',
        }}
      >
        <IonIcon style={genericStyles.icon} icon={repeat} />
        <span style={genericStyles.iconLabel}>
          Due on {format(nextDue, 'do MMM yyyy')}
        </span>
      </span>
    );
  }
}

function renderCompletionHistory(history: CompletionHistory[] | undefined) {
  if (!history?.length) return null;
  return (
    <div>
      <IonNote>
        Last 5 completions:{' '}
        {history
          .slice(-5)
          .reverse()
          .map((r) => (
            <IonBadge
              key={r.timestamp}
              style={{ verticalAlign: 'middle', marginRight: 3 }}
            >
              {format(r.timestamp, 'do MMM yyyy')}
            </IonBadge>
          ))}
      </IonNote>
    </div>
  );
}

const RepeatingStatusWidget = ({ task, onClick, date }: Props) => {
  if (!task.recurringOptions) return null;
  return (
    <div>
      <div>
        {renderLabel(task, date)}
        {task.status !== TaskStatus.STATUS_DONE && (
          <IonButton size="small" color="danger" onClick={onClick}>
            <IonIcon icon={checkbox} />
            &nbsp; End recurring
          </IonButton>
        )}
      </div>
      {renderCompletionHistory(task.completionHistory)}
    </div>
  );
};

export default RepeatingStatusWidget;
