import { IonIcon, IonItem, IonLabel, IonReorder, IonRouterLink } from '@ionic/react';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import {
  HotKeyHandler,
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
  TaskStatus,
} from '../../types/CoreTypes';
import {
  create,
  reorderFourOutline,
  squareOutline,
  star,
  starOutline,
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import { genericStyles } from '../../helpers/styles';
import { TaskHandlers } from '../../types/TaskHandlers';
import { TableCell } from '../Common/TableCell';
import ProjectLink from '../Common/ProjectLink';
import PriorityInfo from '../Common/PriorityInfo';
import TaskDetailsIcon from './TaskDetailsIcon';
import TimeInfo from '../Common/TimeInfo';
import EnergyInfo from '../Common/EnergyInfo';
import TaskStatusInfo from './TaskStatusInfo';
import TagsWidget from '../Common/TagsWidget';
import TaskWrapperDateInfo from './TaskWrapperDateInfo';
import deepEqual from 'fast-deep-equal';
import TaskProductivityIcons from './TaskProductivityIcons';
import { useDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';

interface Props {
  task: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;
  taskHandlers: TaskHandlers;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  manualReorder?: (up: boolean) => Promise<boolean>;
  ref?: any;
  compact?: boolean;
  hideTags?: boolean;
  hideProject?: boolean;
  hideDate?: boolean;
}

// eslint-disable-next-line react/display-name
const TaskListRowDesktop: React.FunctionComponent<Props> = forwardRef<any, Props>(
  (props: Props, ref) => {
    const {
      task,
      isSelected,
      onAfterNavigate,
      compact,
      hideTags,
      hideProject,
      hideDate,
      manualReorder,
      taskHandlers: {
        onStartStateChange,
        onReschedule,
        onChangeState,
        onSetRecurring,
        onChangeDue,
        onChangeTime,
        onChangeEnergy,
        onChangePriority,
        onChangeTags,
        onChangeProject,
        onChangeName,
        getHandlers,
      },
    } = props;

    const { date } = useDate();
    const history = useHistory();
    const nodeRef = useRef<any>(null);

    const handleComplete = useCallback(() => {
      onChangeState && onChangeState(TaskStatus.STATUS_DONE, task);
    }, [onChangeState, task]);

    const url = `/tasks/details/${task.id}`;

    useImperativeHandle(ref, () => ({
      impress() {
        history.push(url, { direction: 'root' });
      },
      scrollIntoView() {
        nodeRef.current &&
          nodeRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      },
      canMove(): boolean {
        return manualReorder !== undefined;
      },
      async moveDown(): Promise<boolean | undefined> {
        if (manualReorder) {
          return await manualReorder(false);
        }
      },
      async moveUp(): Promise<boolean | undefined> {
        if (manualReorder) {
          return await manualReorder(true);
        }
      },
      getHandlers(): HotKeyHandler[] | undefined {
        if (getHandlers) {
          return getHandlers(task);
        }
      },
    }));

    logDebug([], 'refresh: task row render');

    return (
      <IonItem ref={nodeRef} color={isSelected ? 'light' : undefined}>
        <IonReorder slot="start" style={{ marginRight: 10 }}>
          <IonIcon color="medium" icon={reorderFourOutline} />
        </IonReorder>
        <IonLabel
          className={
            'list-desktop task-list-row table-list-row list-row' +
            ' ' +
            (compact ? 'table-container-compact' : 'table-container-regular') +
            ' ' +
            (hideDate ? 'hide-date' : 'no-hide-date') +
            ' ' +
            (hideTags ? 'hide-tags' : 'no-hide-tags') +
            ' ' +
            (hideProject ? 'hide-project' : 'no-hide-project')
          }
        >
          <TableCell
            className="table-cell-icon"
            onClick={onChangeState ? handleComplete : undefined}
          >
            <IonIcon
              color="success"
              className="done-button"
              style={genericStyles.icon}
              icon={squareOutline}
            />
          </TableCell>

          <TableCell className="table-cell-status">
            <TaskStatusInfo
              status={task.status}
              onClick={
                onStartStateChange ? () => onStartStateChange(task) : undefined
              }
            />
          </TableCell>

          <TableCell
            className="table-cell-icon"
            onClick={
              onChangeState
                ? () => {
                    const newState =
                      task.status === TaskStatus.STATUS_IN_PROGRESS
                        ? TaskStatus.STATUS_ACTION_LIST
                        : TaskStatus.STATUS_IN_PROGRESS;
                    onChangeState(newState, task);
                  }
                : undefined
            }
          >
            <IonIcon
              style={genericStyles.icon}
              color="warning"
              icon={
                task.status === TaskStatus.STATUS_IN_PROGRESS ? star : starOutline
              }
            />
          </TableCell>

          {onChangeName && (
            <TableCell className="table-cell-icon">
              <IonIcon
                color="medium"
                style={genericStyles.icon}
                icon={create}
                onClick={() => onChangeName(task)}
              />
            </TableCell>
          )}

          <TableCell className="table-cell-name" takeRemainingSpace>
            <IonRouterLink
              routerLink={url}
              routerDirection={'root'}
              onClick={onAfterNavigate}
            >
              {task.name}
            </IonRouterLink>
          </TableCell>

          {!hideProject && (
            <TableCell className="table-cell-project">
              <ProjectLink
                onAfterNavigate={onAfterNavigate}
                onEdit={onChangeProject ? () => onChangeProject(task) : undefined}
                project={task.project}
              />
            </TableCell>
          )}

          {!hideTags && (
            <TableCell className="table-cell-tags">
              <TagsWidget
                compact
                onAfterNavigate={onAfterNavigate}
                onEdit={onChangeTags ? () => onChangeTags(task) : undefined}
                tags={task.tagsData}
              />
            </TableCell>
          )}

          <TableCell className="table-cell-details-icons">
            <div style={{ display: 'flex' }}>
              <TaskDetailsIcon task={task} />
              <TaskProductivityIcons task={task} date={date} />
            </div>
          </TableCell>

          {!hideDate && (
            <TaskWrapperDateInfo
              currentDate={date}
              Wrapper={({ title, children, colspan }) => (
                <TableCell
                  title={title}
                  className={`table-cell-dates colspan-${colspan}`}
                >
                  {children}
                </TableCell>
              )}
              task={task}
              clickHandlers={{
                reschedule: onReschedule ? () => onReschedule(task) : undefined,
                recurring: onSetRecurring ? () => onSetRecurring(task) : undefined,
                due: onChangeDue ? () => onChangeDue(task) : undefined,
              }}
            />
          )}

          <TableCell className="table-cell-large-icon" title="Priority">
            <PriorityInfo
              onClick={onChangePriority ? () => onChangePriority(task) : undefined}
              priority={task.priorityValues}
            />
          </TableCell>

          <TableCell className="table-cell-large-icon" title="Time">
            <TimeInfo
              onClick={onChangeTime ? () => onChangeTime(task) : undefined}
              time={task.time}
            />
          </TableCell>

          <TableCell className="table-cell-large-icon" title="Energy">
            <EnergyInfo
              onClick={onChangeEnergy ? () => onChangeEnergy(task) : undefined}
              energy={task.energy}
            />
          </TableCell>
        </IonLabel>
      </IonItem>
    );
  }
);

const Memoized = React.memo(TaskListRowDesktop, deepEqual);

export default Memoized;
