import React, { useRef } from 'react';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonSlide,
  IonSlides,
} from '@ionic/react';
import { arrowForward, chevronForward } from 'ionicons/icons';
import { useAuth0 } from '../Auth0ReactFork';
import { useClientType } from '../helpers/ClientTypeProvider';
import { AUTH_TIMEOUT_SEC } from '../lib/Constants';

interface Props {
  onLocalMode: () => void;
}

const StartBtns = (props: Props & { onNextClick?: () => void }) => {
  const { loginWithPopup } = useAuth0();
  return (
    <div>
      {props.onNextClick && (
        <div style={{ marginBottom: 20 }}>
          <IonButton
            size="small"
            className="see-next-slide"
            onClick={props.onNextClick}
          >
            learn more
            <IonIcon slot="end" icon={chevronForward} />
          </IonButton>
        </div>
      )}

      <IonButton color="success" onClick={() => props.onLocalMode()}>
        Start now
        <IonIcon slot="end" icon={arrowForward} />
      </IonButton>

      <div style={{ marginTop: '16px', fontSize: '17px' }}>
        Already have account?{' '}
        <a
          href="#"
          onClick={() =>
            loginWithPopup(undefined, { timeoutInSeconds: AUTH_TIMEOUT_SEC })
          }
        >
          Log in
        </a>
      </div>
      <div className="terms-text">
        By clicking &quot;Start now&quot; or &quot;Log in&quot; above, you
        acknowledge that you have read and understood, and agree to EasyBusy&apos;s{' '}
        <a
          href="https://easybusydo.com/terms-and-conditions.html"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            display: 'inline',
            textDecoration: 'underline',
            userSelect: 'none',
            cursor: 'pointer',
            color: 'inherit',
          }}
        >
          Terms &amp; Conditions
        </a>{' '}
        and{' '}
        <a
          href="https://easybusydo.com/privacy-policy.html"
          rel="noopener noreferrer"
          target="_blank"
          style={{
            display: 'inline',
            textDecoration: 'underline',
            userSelect: 'none',
            cursor: 'pointer',
            color: 'inherit',
          }}
        >
          Privacy Policy
        </a>
        .
      </div>
    </div>
  );
};

const SetUpWizard = (props: Props) => {
  const slidesRef = useRef();
  const { isDesktop } = useClientType();

  // @ts-ignore
  const handleNext = () => slidesRef.current?.slideNext();

  // const { localSettingsRepo } = useDb();
  // const [token, setToken] = useState<string | null>(null);
  // const [userId, setUserId] = useState<string | null>(null);
  // const [encryptionPassword, setEncryptionPassword] = useState<string | null>(null);
  // const [error, setError] = useState<string | null>(null);
  //
  // function renderEditSettingsForm(
  //   id: string,
  //   placeholder: string,
  //   value: string | null,
  //   setValue: (v: string) => void
  // ) {
  //   return (
  //     <div>
  //       <IonInput
  //         id={id}
  //         autocorrect="on"
  //         placeholder={placeholder}
  //         value={value}
  //         clearInput
  //         onIonChange={(event: any) => setValue(event.target.value)}
  //       />
  //     </div>
  //   );
  // }

  const subdir = isDesktop ? 'desktop' : 'mobile';

  return (
    <IonPage>
      <IonContent>
        <IonSlides
          className="intro-slider"
          // @ts-ignore
          ref={slidesRef}
          pager
          options={{ preventClicks: false, preventClicksPropagation: false }}
        >
          <IonSlide>
            <div className="slide intro-slide">
              <img src={`/assets/slider/${subdir}/slide-1.png`} />
            </div>

            <StartBtns onNextClick={handleNext} onLocalMode={props.onLocalMode} />
          </IonSlide>
          <IonSlide>
            <div className="slide intro-slide">
              <img src={`/assets/slider/${subdir}/slide-2.png`} />
            </div>

            <StartBtns onNextClick={handleNext} onLocalMode={props.onLocalMode} />
          </IonSlide>
          <IonSlide>
            <div className="slide intro-slide">
              <img src={`/assets/slider/${subdir}/slide-3.png`} />
            </div>

            <StartBtns onNextClick={handleNext} onLocalMode={props.onLocalMode} />
          </IonSlide>
          <IonSlide>
            <div className="slide intro-slide">
              <img src={`/assets/slider/${subdir}/slide-4.png`} />
            </div>

            <StartBtns onNextClick={handleNext} onLocalMode={props.onLocalMode} />
          </IonSlide>
          <IonSlide>
            <div className="slide intro-slide">
              <img src={`/assets/slider/${subdir}/slide-5.png`} />
            </div>

            <StartBtns onNextClick={handleNext} onLocalMode={props.onLocalMode} />
          </IonSlide>
          <IonSlide>
            <div className="slide intro-slide">
              <img src={`/assets/slider/${subdir}/slide-6.png`} />
            </div>

            <StartBtns onNextClick={handleNext} onLocalMode={props.onLocalMode} />
          </IonSlide>
          <IonSlide>
            <div className="slide intro-slide">
              <img src={`/assets/slider/${subdir}/slide-7.png`} />
            </div>

            <StartBtns onNextClick={handleNext} onLocalMode={props.onLocalMode} />
          </IonSlide>
          <IonSlide>
            <div className="slide intro-slide">
              <img src={`/assets/slider/${subdir}/slide-8.png`} />
            </div>

            <StartBtns onLocalMode={props.onLocalMode} />
          </IonSlide>
        </IonSlides>

        {/*<IonCard*/}
        {/*  style={{*/}
        {/*    width: 400,*/}
        {/*    margin: '0 auto',*/}
        {/*    marginTop: 40,*/}
        {/*    textAlign: 'center',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <IonCardHeader>*/}
        {/*    <IonCardTitle>Welcome!</IonCardTitle>*/}
        {/*  </IonCardHeader>*/}

        {/*  <IonCardContent>*/}
        {/*    <div>*/}
        {/*      <IonIcon icon={rocketOutline} size="large" />*/}
        {/*    </div>*/}
        {/*    /!*{renderEditSettingsForm('token', 'token', token, setToken)}*!/*/}

        {/*    /!*{renderEditSettingsForm('userId', 'user ID', userId, setUserId)}*!/*/}

        {/*    /!*{renderEditSettingsForm(*!/*/}
        {/*    /!*  'encryptionPassword',*!/*/}
        {/*    /!*  'Encryption Password',*!/*/}
        {/*    /!*  encryptionPassword,*!/*/}
        {/*    /!*  setEncryptionPassword*!/*/}
        {/*    /!*)}*!/*/}

        {/*    /!*{error && <div style={{ color: 'red' }}>{error}</div>}*!/*/}

        {/*    <IonButton color="success" onClick={() => props.onLocalMode()}>*/}
        {/*      Start now*/}
        {/*    </IonButton>*/}

        {/*    <div>*/}
        {/*      Already have account?*/}
        {/*      <a href="#" onClick={() => loginWithPopup({ userId: null })}>*/}
        {/*        Log in*/}
        {/*      </a>*/}
        {/*    </div>*/}
        {/*  </IonCardContent>*/}

        {/*  /!*<IonButton*!/*/}
        {/*  /!*  onClick={async () => {*!/*/}
        {/*  /!*    if (!token || !userId || !encryptionPassword) {*!/*/}
        {/*  /!*      setError('To use sync mode, please enter your credentials!');*!/*/}
        {/*  /!*    } else {*!/*/}
        {/*  /!*      await localSettingsRepo.saveUserId(userId);*!/*/}
        {/*  /!*      await localSettingsRepo.saveToken(token);*!/*/}
        {/*  /!*      await localSettingsRepo.saveEncryptionPassword(encryptionPassword);*!/*/}
        {/*  /!*      props.onSubmit({*!/*/}
        {/*  /!*        userId,*!/*/}
        {/*  /!*        token,*!/*/}
        {/*  /!*        encryptionPassword*!/*/}
        {/*  /!*      });*!/*/}
        {/*  /!*    }*!/*/}
        {/*  /!*  }}*!/*/}
        {/*  /!*>*!/*/}
        {/*  /!*  Use synced mode*!/*/}
        {/*  /!*</IonButton>*!/*/}
        {/*</IonCard>*/}
      </IonContent>
    </IonPage>
  );
};

export default SetUpWizard;
