import { close } from 'ionicons/icons';
import {
  Nullable,
  PriorityValues,
  RecurringOptions,
  TagAssignment,
  TaskEnergy,
  TaskEnrichedWithProject,
  TaskStatus,
  TaskTime,
  TaskType,
} from '../../types/CoreTypes';
import { TaskHandlers, TaskHandlersInner } from '../../types/TaskHandlers';
import React, { useCallback } from 'react';
import { useStorage } from '../../helpers/StorageProviders';
import { ToastProviderProps, withToast } from '../../helpers/useToast';
import TaskActionsUI from './TaskActionsUI';
import { logDebug } from '../../lib/logger';

interface Props extends ToastProviderProps {
  handleChangeTask: (taskData: Partial<TaskType>, taskId: string) => void;
  handleCompleteTask: (task: TaskType, endRecurring: boolean) => void;
  handleUnCompleteTask: (
    task: TaskType,
    prevStatus: TaskStatus,
    prevScheduled?: string
  ) => void;
  children: (api: TaskHandlers) => React.ReactNode;
}

enum ChangeStatus {
  state = 'state',
  recurring = 'recurring',
  schedule = 'schedule',
  dueDate = 'dueDate',
  time = 'time',
  priority = 'priority',
  energy = 'energy',
  tags = 'tags',
  project = 'project',
  name = 'name',
}

interface State {
  selectedTask?: TaskEnrichedWithProject;
  selectedRow?: TaskEnrichedWithProject;
  changeStatus?: ChangeStatus;
  resolve?: () => void;
  // completedTask?: {
  //   task: TaskType;
  //   prevState: TaskStatus;
  //   prevScheduled?: number;
  // };
}

const UNDO_TASK_TRIM_LEN = 14;

class TaskActionsWrapperInternal extends React.PureComponent<Props, State> {
  state: State = {};

  setSelectedTask = (selectedTask?: TaskEnrichedWithProject) => {
    this.setState({ selectedTask });
  };
  setChangeStatus = (changeStatus?: ChangeStatus) => {
    this.setState({ changeStatus });
  };

  handleChangeTask = (taskData: Partial<TaskType>, taskId: string) =>
    this.props.handleChangeTask(taskData, taskId);

  private readonly modalRefs: Map<ChangeStatus, React.RefObject<any>> = new Map();

  constructor(props: Props) {
    super(props);

    Object.keys(ChangeStatus).forEach((k) => {
      this.modalRefs.set((k as unknown) as ChangeStatus, React.createRef());
    });
  }

  showToast(task: TaskType) {
    this.props.toast.show({
      message: `Task "${task.name.substr(0, UNDO_TASK_TRIM_LEN - 1)}${
        task.name.length && task.name.length > UNDO_TASK_TRIM_LEN ? '...' : ''
      }" completed!`,
      position: 'top',
      color: 'success',
      duration: 5000,
      buttons: [
        {
          icon: close,
          role: 'cancel',
          side: 'start',
        },
        {
          text: 'Undo',
          role: 'zzz',
          handler: () => {
            // if (!this.state.completedTask) return;
            // const { prevScheduled, prevState, task } = this.state.completedTask;
            this.props.handleUnCompleteTask(task, task.status, task.dateScheduled);
          },
        },
      ],
    });
  }

  changeStateHandler = (status: TaskStatus, task: TaskType) => {
    if (status === TaskStatus.STATUS_DONE) {
      this.showToast(task);
      return this.props.handleCompleteTask(task, false);
    }
    if (task.status === TaskStatus.STATUS_DONE) {
      return this.props.handleUnCompleteTask(task, status);
    }
    this.handleChangeTask({ dateScheduled: undefined, status }, task.id);
  };

  completeEndRecurringHandler = (task: TaskType) => {
    this.showToast(task);
    return this.props.handleCompleteTask(task, true);
  };

  makeHandleChangeState = (status: TaskStatus) => () => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    this.changeStateHandler(status, selectedTask);
  };

  handleChangeState = (status: TaskStatus | undefined) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    if (!status) return;
    this.changeStateHandler(status, selectedTask);
  };

  handleChangeStateForTask = (status: TaskStatus | undefined, task: TaskType) => {
    if (!status) return;
    this.changeStateHandler(status, task);
  };

  handleChangeTime = (time: Nullable<TaskTime>) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    this.handleChangeTask({ time }, selectedTask.id);
  };

  handleChangeEnergy = (energy: Nullable<TaskEnergy>) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    this.handleChangeTask({ energy }, selectedTask.id);
  };

  handleChangePriority = (priorityValues: Nullable<PriorityValues>) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    this.handleChangeTask({ priorityValues }, selectedTask.id);
  };

  handleChangeTags = (tags: TagAssignment[] | undefined) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    this.handleChangeTask({ tags }, selectedTask.id);
  };

  handleChangeProjectId = (projectId: string | undefined) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    const changes: Partial<TaskType> = { projectId };
    if (selectedTask.status === TaskStatus.STATUS_INBOX) {
      changes.status = TaskStatus.STATUS_ACTION_LIST;
    }
    this.handleChangeTask(changes, selectedTask.id);
  };

  handleChangeName = (name: string) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    this.handleChangeTask({ name }, selectedTask.id);
  };

  handleRecurring = (recurringOptions: RecurringOptions | undefined) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    if (recurringOptions) {
      this.handleChangeTask(
        {
          recurringOptions: recurringOptions,
          status: TaskStatus.STATUS_SCHEDULED,
          dateDue: undefined,
        },
        selectedTask.id
      );
      return;
    }
    this.handleChangeTask(
      { recurringOptions: undefined, status: TaskStatus.STATUS_INBOX },
      selectedTask.id
    );
  };

  handleReschedule = (date: string | undefined) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    if (date) {
      this.handleChangeTask(
        { dateScheduled: date, status: TaskStatus.STATUS_SCHEDULED },

        selectedTask.id
      );
      return;
    }
    this.handleChangeTask(
      { dateScheduled: undefined, status: TaskStatus.STATUS_INBOX },
      selectedTask.id
    );
  };

  handleChangeDue = (date: string | undefined) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return;
    if (date) {
      this.handleChangeTask({ dateDue: date }, selectedTask.id);
      return;
    }
    this.handleChangeTask({ dateDue: undefined }, selectedTask.id);
  };

  basicCloseHandler = () => {
    this.setState({ selectedTask: this.state.selectedRow, changeStatus: undefined });

    // for (const ref in this.modalRefs) {
    //   this.modalRefs.get((ref as unknown) as ChangeStatus)?.current?.blur();
    // }
  };

  componentDidUpdate() {
    const { resolve } = this.state;
    if (resolve) {
      this.setState({ resolve: undefined });
      setTimeout(resolve, 250);
    }
  }

  getRef = (status: ChangeStatus) => this.modalRefs.get(status);
  focusRef = (status: ChangeStatus) => {
    // console.log('$$$', this.modalRefs);
    // debugger;
    const current = this.modalRefs.get(status)?.current;
    if (current) {
      logDebug([], `$$$ focus: ${status}`, current);
      if (current.setFocus) {
        current.setFocus();
      } else if (current.focus) {
        current.focus();
      } else {
        throw Error('Cannot focus: dont know how to focus!');
      }
    } else {
      throw Error(`Cannot focus ${status}: no current ref!`);
    }
  };

  isChangeStatus = (desiredStatus: ChangeStatus) => {
    const { selectedTask } = this.state;
    if (!selectedTask) return false;
    return this.state.changeStatus === desiredStatus;
  };

  getValueForModal = <K extends keyof TaskType, A extends TaskType[K]>(
    desiredStatus: ChangeStatus,
    getter: (t: TaskType) => A
  ): A | undefined => {
    const { selectedTask } = this.state;
    if (!this.isChangeStatus(desiredStatus)) return undefined;
    if (!selectedTask) return undefined;
    return getter(selectedTask);
  };

  // wrapInSetSelectedTask = (fn: (() => void) | undefined) => (
  //   selectedTask: TaskEnrichedWithProject
  // ) => {
  //   this.setState({ selectedTask }, fn);
  // };

  // wrapInSetSelectedTaskKeys = (fn: (() => HotKeyHandler[]) | undefined) => (
  //   selectedTask: TaskEnrichedWithProject
  // ): HotKeyHandler[] => {
  //   this.setState({ selectedTask });
  //   if (!fn) return [];
  //   return fn();
  // };

  setSelectedTaskPromise = (
    selectedTask: TaskEnrichedWithProject
  ): Promise<void> => {
    return new Promise((resolve) => {
      this.setState({ selectedTask }, resolve);
    });
  };

  setSelectedRowPromise = (selectedTask: TaskEnrichedWithProject): Promise<void> => {
    return new Promise((resolve) => {
      this.setState({ selectedTask, selectedRow: selectedTask }, resolve);
    });
  };

  render() {
    const { children } = this.props;
    return (
      <TaskActionsUI
        selectedTask={this.state.selectedTask}
        onClose={this.basicCloseHandler}
        handleChangePriority={this.handleChangePriority}
        handleChangeTags={this.handleChangeTags}
        handleChangeProjectId={this.handleChangeProjectId}
        handleChangeName={this.handleChangeName}
        handleRecurring={this.handleRecurring}
        handleReschedule={this.handleReschedule}
        handleChangeDue={this.handleChangeDue}
        handleChangeTime={this.handleChangeTime}
        handleChangeEnergy={this.handleChangeEnergy}
        handleChangeState={this.handleChangeState}
      >
        {(taskHandlers) => (
          <TaskActionsWrapperInner
            taskHandlers={taskHandlers}
            setSelectedTask={this.setSelectedTaskPromise}
            setSelectedTaskAndRow={this.setSelectedRowPromise}
            handleChangeStateForTask={this.handleChangeStateForTask}
            completeEndRecurringHandler={this.completeEndRecurringHandler}
          >
            {children}
          </TaskActionsWrapperInner>
        )}
      </TaskActionsUI>
    );
  }
}

interface InnerProps {
  taskHandlers: TaskHandlersInner;
  setSelectedTask: (selectedTask: TaskEnrichedWithProject) => Promise<void>;
  setSelectedTaskAndRow: (selectedTask: TaskEnrichedWithProject) => Promise<void>;
  children: (api: TaskHandlers) => React.ReactNode;
  handleChangeStateForTask: (status: TaskStatus | undefined, task: TaskType) => void;
  completeEndRecurringHandler: (task: TaskType) => void;
}

class TaskActionsWrapperInner extends React.PureComponent<InnerProps> {
  onReschedule = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onReschedule);
  };
  onStartStateChange = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onStartStateChange);
  };
  onSetRecurring = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onSetRecurring);
  };
  onChangeDue = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onChangeDue);
  };
  onChangeTime = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onChangeTime);
  };
  onChangeEnergy = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onChangeEnergy);
  };
  onChangePriority = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onChangePriority);
  };
  onChangeTags = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onChangeTags);
  };
  onChangeProject = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onChangeProject);
  };
  onChangeName = (selectedTask: TaskEnrichedWithProject) => {
    this.props
      .setSelectedTask(selectedTask)
      .then(this.props.taskHandlers.onChangeName);
  };

  // triggers when row in list changes. selected row stays the same
  // while there's any rows
  getHandlers = (selectedTask: TaskEnrichedWithProject) => {
    this.props.setSelectedTaskAndRow(selectedTask);
    if (!this.props.taskHandlers.getHandlers) return [];
    return this.props.taskHandlers.getHandlers();
  };

  render() {
    const { children } = this.props;
    return (
      <>
        {children({
          onReschedule: this.onReschedule,
          onStartStateChange: this.onStartStateChange,
          onSetRecurring: this.onSetRecurring,
          onChangeDue: this.onChangeDue,
          onChangeTime: this.onChangeTime,
          onChangeEnergy: this.onChangeEnergy,
          onChangePriority: this.onChangePriority,
          onChangeTags: this.onChangeTags,
          onChangeProject: this.onChangeProject,
          onChangeName: this.onChangeName,
          onChangeState: this.props.handleChangeStateForTask,
          onCompleteEndRecurring: this.props.completeEndRecurringHandler,
          getHandlers: this.getHandlers,
        })}
      </>
    );
  }
}

interface WrapperProps extends ToastProviderProps {
  children: (api: TaskHandlers) => React.ReactNode;
}

const TaskActionsWrapper = ({ children, toast }: WrapperProps) => {
  const {
    repos: { taskRepo },
  } = useStorage();

  const updateTask = useCallback(
    (taskData: Partial<TaskType>, id: string) => {
      return taskRepo.edit({
        ...taskData,
        id: id,
      });
    },
    [taskRepo]
  );

  const completeTask = useCallback(
    (task: TaskType, endRecurring: boolean) => {
      return taskRepo.complete(task, endRecurring);
    },
    [taskRepo]
  );

  const unCompleteTask = useCallback(
    (task: TaskType, prevStatus: TaskStatus, prevScheduled?: string) => {
      return taskRepo.unComplete(task, prevStatus, prevScheduled);
    },
    [taskRepo]
  );

  return (
    <TaskActionsWrapperInternal
      toast={toast}
      handleCompleteTask={completeTask}
      handleUnCompleteTask={unCompleteTask}
      handleChangeTask={updateTask}
    >
      {children}
    </TaskActionsWrapperInternal>
  );
};

export default withToast(TaskActionsWrapper);
