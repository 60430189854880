import {
  PartialUpdatedRecord,
  ProjectCreateForm,
  ProjectEnrichedWithTaskStats,
  ProjectEnrichedWithTaskStatsAndDependingProject,
  ProjectFields,
  ProjectFilter,
  ProjectPositionContextEnum,
  ProjectStatus,
  ProjectType,
} from '../types/CoreTypes';
import { changePositionsInEntities } from './SortHelper';
import { getProjectPositionKey } from '../helpers/PositionHelper';
import { PROJECT_NAME_MAX_LENGTH } from './Constants';
import { sanitizeSearchText } from './FilterHelper';
import PouchDB from 'pouchdb';
import { ProjectModel } from './data/Models';
import { BaseRepo } from './BaseRepo';
import { pick } from 'lodash';
import { Observable } from 'rxjs';
import { Sorter } from './data/ModelTooling';

export default class ProjectRepo extends BaseRepo<
  ProjectFields,
  ProjectPositionContextEnum
> {
  async getAllForStatus(
    contextId: string,
    status: ProjectStatus
  ): Promise<ProjectEnrichedWithTaskStats[]> {
    return this.dao.findAll({
      selector: {
        status,
        contextId,
      },
      sortContext: ProjectPositionContextEnum.STATUS,
      enrichments: [ProjectModel.taskCounterEnrichment],
    });
  }

  async getAllForStatusWithoutEnrichment(
    contextId: string,
    status: ProjectStatus
  ): Promise<ProjectType[]> {
    return this.dao.findAll({
      selector: {
        status,
        contextId,
      },
      sortContext: ProjectPositionContextEnum.STATUS,
    });
  }

  async getAllForStats(): Promise<ProjectType[]> {
    return this.dao.findAll({
      selector: {
        status: {
          $ne: ProjectStatus.STATUS_ARCHIVE,
        },
      },
    });
  }

  async getAllNotInStatus(
    contextId: string,
    status: ProjectStatus
  ): Promise<ProjectEnrichedWithTaskStats[]> {
    return this.dao.findAll({
      selector: {
        status: {
          $ne: status,
        },
        contextId,
      },
      sortContext: ProjectPositionContextEnum.STATUS,
      enrichments: [ProjectModel.taskCounterEnrichment],
    });
  }

  _forceCreate(doc: ProjectType) {
    return this.dao._forceCreate(doc);
  }

  async create(args: ProjectCreateForm): Promise<string> {
    const nameTrimmed = args.name.trim();
    const entity = {
      name: nameTrimmed.substring(0, PROJECT_NAME_MAX_LENGTH),
      contextId: args.contextId,
      status: args.status || ProjectStatus.STATUS_NEXT,
      // howSuccessLooksLike: '',
      priorityValues: args.priorityValues || undefined,
    };
    return this.dao.create(entity);
  }

  async reorder(
    positionContext: ProjectPositionContextEnum,
    projects: ProjectType[],
    from: number,
    to: number
  ) {
    const positionKey = getProjectPositionKey(positionContext);
    const changeSets = changePositionsInEntities(projects, from, to).map(
      (project, idx) => ({
        id: project.id,
        [positionKey]: idx,
      })
    );
    return this.dao.editManyWithDebounce(changeSets);
  }

  async edit(entity: PartialUpdatedRecord<ProjectType>): Promise<void> {
    if (entity.name) {
      const nameTrimmed = entity.name.trim();
      if (nameTrimmed.length < 2) {
        throw new Error('name should be at least 2 characters!');
      }
      entity.name = nameTrimmed.substring(0, PROJECT_NAME_MAX_LENGTH);
    }
    // reset positions
    if (entity.status) {
      entity.positionStatus = undefined;
    }
    if (entity.dateDue) {
      entity.positionCalendar = undefined;
    }
    return this.dao.edit(entity);
  }

  async getById(
    id: string
  ): Promise<ProjectEnrichedWithTaskStatsAndDependingProject | null> {
    return this.dao.getById(id, {
      enrichments: [
        ProjectModel.taskCounterEnrichment,
        ProjectModel.getDependsOnProjectRelation(),
      ],
    });
  }

  async getAllForFilter(
    contextId: string,
    formattedDate: string,
    filter: ProjectFilter,
    context: ProjectPositionContextEnum
  ): Promise<ProjectEnrichedWithTaskStats[]> {
    const { selector, filterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    return this.dao.findAll({
      selector,
      filterFn,
      sortContext: context,
      enrichments: [ProjectModel.taskCounterEnrichment],
    });
  }

  getAllForFilterWithSort(
    contextId: string,
    filter: ProjectFilter,
    sortFn: (a: ProjectType, b: ProjectType) => number,
    formattedDate: string,
    limit?: number
  ): Promise<ProjectEnrichedWithTaskStats[]> {
    const { selector, filterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    return this.dao.findAll({
      selector,
      filterFn,
      sortFn,
      limit,
      enrichments: [ProjectModel.taskCounterEnrichment],
    });
  }

  getAllForFilter$(
    contextId: string,
    formattedDate: string,
    filter?: ProjectFilter,
    sortContext?: ProjectPositionContextEnum
  ): Observable<ProjectType[]> {
    const { selector, filterFn } = this.makeSelector(
      contextId,
      filter || {},
      formattedDate
    );
    return this.dao.findAll$(selector, filterFn, sortContext);
  }

  getAllForFilterEnriched$(config: {
    contextId: string;
    formattedDate: string;
    filter?: ProjectFilter;
    sortContext?: ProjectPositionContextEnum;
    sortFn?: (a: ProjectType, b: ProjectType) => number;
    sort?: Sorter<ProjectType>;
  }): Observable<ProjectEnrichedWithTaskStatsAndDependingProject[]> {
    const { selector, filterFn } = this.makeSelector(
      config.contextId,
      config.filter || {},
      config.formattedDate
    );
    return this.dao.findAllEnriched$({
      ...config,
      selector,
      filterFn,
      enrichments: [
        ProjectModel.taskCounterEnrichment,
        ProjectModel.getDependsOnProjectRelation(),
      ],
    });
  }

  async getCountForFilter(
    contextId: string,
    filter: ProjectFilter,
    formattedDate: string
  ): Promise<number> {
    const { selector, filterFn } = this.makeSelector(
      contextId,
      filter,
      formattedDate
    );
    return this.dao.getCount({ selector, filterFn });
  }

  private convertNotInStatusIntoInStatus(
    notStatuses: ProjectStatus[]
  ): ProjectStatus[] {
    return Object.values(ProjectStatus).filter((s) => !notStatuses.includes(s));
  }

  private makeSelector(
    contextId: string | undefined,
    filter: ProjectFilter,
    formattedDate: string
  ): { selector: PouchDB.Find.Selector; filterFn: (r: ProjectType) => boolean } {
    const memoryFilterFields: Set<keyof ProjectFilter> = new Set(
      Object.keys(filter) as (keyof ProjectFilter)[]
    );

    const selector: PouchDB.Find.Selector = {};
    if (contextId) {
      selector.contextId = contextId;
    }
    if (filter.status) {
      selector.status = filter.status;
      memoryFilterFields.delete('status');
    }
    if (filter.notStatus) {
      selector.status = {
        $ne: filter.notStatus,
      };
      memoryFilterFields.delete('notStatus');
    }
    if (filter.statusIn) {
      selector.status = {
        $in: filter.statusIn,
      };
      memoryFilterFields.delete('statusIn');
    }

    return {
      selector,
      filterFn: ProjectRepo.makeFilterFn(
        pick(filter, Array.from(memoryFilterFields)),
        formattedDate
      ),
    };
  }

  static makeFilterFn = (filter: ProjectFilter, formattedDate: string) => (
    project: ProjectType
  ) => {
    let isMatch = true;
    if (filter.contextId)
      isMatch = isMatch && project.contextId === filter.contextId;
    if (filter.status) isMatch = isMatch && project.status === filter.status;
    if (filter.notStatus) isMatch = isMatch && project.status !== filter.notStatus;
    if (filter.anyText)
      isMatch =
        isMatch &&
        sanitizeSearchText(project.name).includes(
          sanitizeSearchText(filter.anyText)
        );
    if (filter.dueToday)
      isMatch =
        isMatch && project.dateDue !== undefined && project.dateDue <= formattedDate;
    return isMatch;
  };
}
