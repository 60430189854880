import * as chrono from 'chrono-node';
import enUS from 'date-fns/locale/en-US';
import formatRelative from 'date-fns/formatRelative';
import { uniqWith } from 'lodash';
import isSameDay from 'date-fns/isSameDay';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import setDate from 'date-fns/setDate';
import format from 'date-fns/format';
import { ExcludesNull } from '../types/CoreTypes';
import startOfMonth from 'date-fns/startOfMonth';
import getDay from 'date-fns/getDay';
import addDays from 'date-fns/addDays';
import getDate from 'date-fns/getDate';
import { startOfDay } from 'date-fns';
import { logDebug, logWarn } from './logger';

export interface Suggestion {
  label?: string;
  date: Date;
}

type Relative = 'yesterday' | 'today' | 'tomorrow' | 'nextWeek' | 'other';
const formatRelativeLocale = {
  yesterday: "'Yesterday' (eee, do MMMM yyyy)",
  today: "'Today' (eee, do MMMM yyyy)",
  tomorrow: "'Tomorrow' (eee, do MMMM yyyy)",
  lastWeek: "'Last' eeee (do MMMM yyyy)",
  nextWeek: "'Next' eeee (do MMMM yyyy)",
  other: 'eee, do MMMM yyyy',
};

export const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const isoDaysShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const daysFull = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const locale = {
  ...enUS,
  formatRelative: (token: Relative) => formatRelativeLocale[token],
};

const customChrono = chrono.casual.clone();
const chronoOptions = {
  forwardDate: true,
};
function makeDateFromString(label: string): Date | null {
  const date = customChrono.parseDate(label, new Date(), chronoOptions);
  if (!date) {
    logWarn(['date'], 'could not parse string to date', { label });
  }
  return startOfDay(date || new Date());
}
// customChrono.parsers.push({
//   pattern: () => {
//     return /\bChristmas\b/i;
//   },
//   extract: (context, match) => {
//     return {
//       day: 25,
//       month: 12
//     };
//   }
// });
// customChrono.parsers.push({
//   pattern: () => {
//     return /\bweekend\b/i;
//   },
//   extract: (context, match) => {
//     return 'next wee';
//   }
// });
// customChrono.parsers.push({
//   pattern: () => {
//     return /\bnext week\b/i;
//   },
//   extract: (context, match) => {
//     return {
//       day: 2
//     };
//   }
// });

export function formatDate(d: Suggestion): string {
  if (d.label) {
    return format(d.date, `'${d.label}' (eee, do MMMM yyyy)`);
  }
  return formatRelative(d.date, new Date(), { locale });
}

export const createOptionForDate = (d: Suggestion): DateOption => {
  return {
    value: d.date,
    label: formatDate(d),
  };
};

export const createCalendarOptions: (date?: Date) => DateOption = (
  date = getCurrentDate()
) => {
  const daysInMonth = [...new Array(getDaysInMonth(date))].map((x, i) => {
    const d = setDate(date, i + 1);
    return { ...createOptionForDate({ date: d }), display: 'calendar' };
  });
  return {
    label: format(date, 'MMMM yyyy'),
    options: daysInMonth,
  };
};

export interface DateOption {
  display?: string;
  label: string;
  value?: Date;
  options?: DateOption[];
}

function getCurrentDate() {
  return startOfDay(new Date());
}

const defaultStrings = [
  'today',
  'tomorrow',
  '2 days from now',
  '3 days from now',
  'saturday',
  'next monday',
  // 'next saturday'
];

export type ExcludesNullDate = (r: {
  label: string;
  date: Date | null;
}) => r is { label: string; date: Date };

export const defaultDates = () =>
  uniqWith(
    defaultStrings
      .map((i) => ({
        label: i,
        date: makeDateFromString(i),
      }))
      .filter(((r) => r.date !== null) as ExcludesNullDate),
    (a, b) => isSameDay(a.date, b.date)
  );
export const defaultOptions = (startDate?: Date): DateOption[] => {
  const dO = defaultDates().map(createOptionForDate);
  dO.push(createCalendarOptions(startDate));
  return dO;
};

export type Suggestions = { [k: string]: string[] };

const replacements = {
  weekend: 'saturday',
  'next week': 'next monday',
  'week from now': '1 week from now',
  'month from now': '1 month from now',
};

const replace = (str: string) => {
  return Object.entries(replacements).reduce((acc, [k, v]) => {
    return acc.replace(k, v);
  }, str);
};

const suggestions = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'next monday',
  'next tuesday',
  'next wednesday',
  'next thursday',
  'next friday',
  'next saturday',
  'next sunday',
  // 'monday 1 week from now',
  // 'tuesday 1 week from now',
  // 'wednesday 1 week from now',
  // 'thursday 1 week from now',
  // 'friday 1 week from now',
  // 'saturday 1 week from now',
  // 'sunday 1 week from now',
  'december',
  'november',
  'october',
  'september',
  'august',
  'july',
  'june',
  'may',
  'april',
  'march',
  'february',
  'january',
  'yesterday',
  'tomorrow',
  'today',
  'weekend',
  'next weekend',
  'next week',
  'next month',
  '2 days from now',
  '3 days from now',
  '4 days from now',
  '5 days from now',
  '6 days from now',
  '7 days from now',
  '8 days from now',
  '9 days from now',
  '10 days from now',
  '11 days from now',
  '12 days from now',
  '13 days from now',
  '14 days from now',
  '15 days from now',
  '16 days from now',
  '17 days from now',
  '18 days from now',
  '19 days from now',
  '20 days from now',
  '21 days from now',
  '22 days from now',
  '23 days from now',
  '24 days from now',
  '25 days from now',
  '26 days from now',
  '27 days from now',
  '28 days from now',
  '29 days from now',
  '30 days from now',
  'week from now',
  '1 week from now',
  '2 weeks from now',
  '3 weeks from now',
  '4 weeks from now',
  '5 weeks from now',
  '6 weeks from now',
  '7 weeks from now',
  '8 weeks from now',
  '9 weeks from now',
  '10 weeks from now',
  '11 weeks from now',
  '12 weeks from now',
  'month from now',
  '1 month from now',
  '2 months from now',
  '3 months from now',
  '4 months from now',
  '5 months from now',
  '6 months from now',
  '7 months from now',
  '8 months from now',
  '9 months from now',
  '10 months from now',
  '11 months from now',
  '12 months from now',
  ...[...new Array(12)]
    .map((_, mInd) =>
      [...new Array(getDaysInMonth(new Date().setMonth(mInd)))].map((x, i) =>
        `${i + 1} ${format(new Date().setMonth(mInd), 'MMMM')}`.toLowerCase()
      )
    )
    .flat(),
].reduce((acc, str) => {
  for (let i = 1; i <= str.length; i++) {
    if (!acc[str.substr(0, i)]) {
      acc[str.substr(0, i)] = [];
    }
    acc[str.substr(0, i)].push(replace(str));
  }
  return acc;
}, {} as Suggestions);

// const suggest = (str: string): string[] => [
//   str,
//   ...str
//     .split(/\b/)
//     .map(i => suggestions[i] || [i])
//     .flat()
// ];
const suggest = (str: string): string[] =>
  suggestions[str.trim()] || (str.length ? [str] : []);

export const getDateSuggestions = (value: string): Suggestion[] => {
  const suggestions = suggest(value.toLowerCase());
  if (!suggestions.length) return defaultDates();
  logDebug([], 'getDateSuggestions', { value, suggestions });
  return uniqWith(
    suggestions
      .map((label) => {
        const date = makeDateFromString(label);
        if (date) {
          return { label, date };
        }
        return null;
      })
      .filter((Boolean as any) as ExcludesNull),
    (a, b) => isSameDay(a.date, b.date)
  )
    .sort((a, b) => a.date.getTime() - b.date.getTime())
    .slice(0, 5);
};

export function getWeekDayAndNth(val: Date) {
  let cur = startOfMonth(val);
  const date = getDate(val);
  const day = getDay(val);
  let nth = 0;
  let iter = 0;
  while (cur <= val) {
    if (getDay(cur) === day) {
      nth++;
    }
    iter++;
    if (iter > 31) {
      throw new Error('Error iterating in getWeekDayAndNth');
    }
    cur = addDays(cur, 1);
  }
  return { date, day, nth };
}

export function formatTime(time: number): string {
  // if (time >= 60 * 24) {
  //   return `${Math.round((time / 60 / 24) * 10) / 10}d`;
  // } else
  if (time >= 60) {
    return `${Math.round((time / 60) * 10) / 10}h`;
  }
  return `${time}m`;
}
