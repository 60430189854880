import React from 'react';
import TaskListElementMobile from './TaskListElementMobile';
import {
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from '@ionic/react';
import {
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
  TaskStatus,
} from '../../types/CoreTypes';
import {
  alarm,
  checkboxOutline,
  ellipsisVertical,
  star,
  starOutline,
} from 'ionicons/icons';
import { TaskHandlers } from '../../types/TaskHandlers';
import { RouteComponentProps, withRouter } from 'react-router';
import deepEqual from 'fast-deep-equal';

interface Props extends RouteComponentProps {
  task: TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;
  taskHandlers: TaskHandlers;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
  hideTags?: boolean;
  hideProject?: boolean;
  hideDate?: boolean;
}

class TaskListRowMobile extends React.Component<Props> {
  private readonly slidingRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.slidingRef = React.createRef();
  }

  closeOpenedSlider() {
    this.slidingRef.current && this.slidingRef.current.closeOpened();
  }

  render() {
    const {
      task,
      taskHandlers,
      onAfterNavigate,
      isSelected,
      hideTags,
      hideProject,
      hideDate,
    } = this.props;
    const { onStartStateChange, onReschedule, onChangeState } = taskHandlers;
    return (
      <IonItemSliding ref={this.slidingRef}>
        <TaskListElementMobile
          task={task}
          isSelected={isSelected}
          onAfterNavigate={onAfterNavigate}
          hideTags={hideTags}
          hideProject={hideProject}
          hideDate={hideDate}
        />

        <IonItemOptions side="start">
          {onChangeState && (
            <IonItemOption
              color="success"
              onClick={() => {
                this.closeOpenedSlider();
                onChangeState(TaskStatus.STATUS_DONE, task);
              }}
            >
              <IonIcon
                style={{ fontSize: '25px' }}
                slot="start"
                icon={checkboxOutline}
              />
            </IonItemOption>
          )}

          {onChangeState && (
            <IonItemOption
              color="warning"
              onClick={() => {
                this.closeOpenedSlider();
                const newState =
                  task.status === TaskStatus.STATUS_IN_PROGRESS
                    ? TaskStatus.STATUS_ACTION_LIST
                    : TaskStatus.STATUS_IN_PROGRESS;
                onChangeState(newState, task);
              }}
            >
              <IonIcon
                style={{ fontSize: '25px' }}
                slot="start"
                icon={
                  task.status === TaskStatus.STATUS_IN_PROGRESS ? star : starOutline
                }
              />
            </IonItemOption>
          )}
        </IonItemOptions>

        <IonItemOptions side="end">
          {onStartStateChange && (
            <IonItemOption
              color="primary"
              onClick={() => {
                this.closeOpenedSlider();
                onStartStateChange(task);
              }}
            >
              <IonIcon
                style={{ fontSize: '25px' }}
                slot="start"
                icon={ellipsisVertical}
              />
            </IonItemOption>
          )}

          {onReschedule && (
            <IonItemOption
              color="secondary"
              onClick={() => {
                this.closeOpenedSlider();
                onReschedule(task);
              }}
            >
              <IonIcon style={{ fontSize: '25px' }} slot="start" icon={alarm} />
            </IonItemOption>
          )}

          {/*<IonItemOption*/}
          {/*  color="warning"*/}
          {/*  onClick={() => {*/}
          {/*    this.closeOpenedSlider();*/}
          {/*    // probably IonItemOption works differently from IonItem,*/}
          {/*    // and onClick handler prevents routerLink from working*/}
          {/*    this.props.history.push(`/tasks/edit/${task.id}`, { direction: 'root' });*/}
          {/*    onAfterNavigate && onAfterNavigate();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <IonIcon style={{ fontSize: '25px' }} slot="start" icon={create} />*/}
          {/*</IonItemOption>*/}
        </IonItemOptions>
      </IonItemSliding>
    );
  }
}

const Memoized = React.memo(TaskListRowMobile, deepEqual);

export default withRouter(Memoized);
