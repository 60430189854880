import React from 'react';
import {
  Priority,
  TagType,
  TaskEnergy,
  TaskFilter,
  TaskTime,
  TaskType,
} from '../../types/CoreTypes';
import { IonActionSheet, IonChip, IonIcon, IonLabel } from '@ionic/react';
import { genericStyles } from '../../helpers/styles';
import {
  alertCircle,
  alertCircleOutline,
  appsOutline,
  cellular,
  cellularOutline,
  closeOutline,
  scanCircleOutline,
  stopwatch,
  stopwatchOutline,
} from 'ionicons/icons';
import {
  getEnergyLabel,
  getPriorityLabel,
  getTimeLabel,
} from '../../helpers/CopyHelper';
import { isEqual, some } from 'lodash';
import { getPriorityEnum } from '../../helpers/PriorityHelper';
import { Subscription } from 'rxjs';
import { withStorage } from '../../helpers/StorageProviders';
import { StorageDeps } from '../../helpers/InitStorage';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import Tag from '../Common/Tag';
import { logDebug } from '../../lib/logger';
import Tooltip from 'rc-tooltip';
import { formatTime } from '../../lib/DateHelper';

export interface TaskFilterAPI {
  filter: TaskFilter;
  setUniqueAndStatsFilterValues: (
    collectionName: string,
    values: TaskType[]
  ) => void;
}

interface Props extends StorageDeps, WorkspaceProps {
  children: (api: TaskFilterAPI) => React.ReactNode;
}

enum ChangeState {
  energy,
  priority,
  time,
}

export interface UniqueFilterValuesAndStats {
  uniqueFilterValues: UniqueFilterValues;
  summaryTime: number;
  summaryEnergy: {
    [key in TaskEnergy]: number;
  };
}

export interface UniqueFilterValues {
  tags: string[];
  time: TaskTime[];
  energy: TaskEnergy[];
  priority: Priority[];
}

interface State {
  filter: TaskFilter;
  uniqueFilterValues: UniqueFilterValues;
  summaryTime: number;
  summaryEnergy: {
    [key in TaskEnergy]: number;
  };
  changeState?: ChangeState;
  tags?: TagType[];
}

class TaskListFilter extends React.Component<Props, State> {
  private uniqueFilterValuesMap: Map<string, UniqueFilterValuesAndStats> = new Map();

  state: State = {
    uniqueFilterValues: {
      tags: [],
      time: [],
      energy: [],
      priority: [],
    },
    summaryTime: 0,
    summaryEnergy: {
      [TaskEnergy.ENERGY_LOW]: 0,
      [TaskEnergy.ENERGY_MED]: 0,
      [TaskEnergy.ENERGY_HIGH]: 0,
    },
    filter: {},
  };

  getUniqueFilterValuesAndStats(tasks: TaskType[]): UniqueFilterValuesAndStats {
    const tags = new Set<string>();
    const time = new Set<TaskTime>();
    const energy = new Set<TaskEnergy>();
    const priority = new Set<Priority>();

    let summaryTime = 0;
    const summaryEnergy: {
      [key in TaskEnergy]: number;
    } = {
      [TaskEnergy.ENERGY_LOW]: 0,
      [TaskEnergy.ENERGY_MED]: 0,
      [TaskEnergy.ENERGY_HIGH]: 0,
    };

    for (const task of tasks) {
      task.tags?.forEach((t) => tags.add(t.id));
      task.time && time.add(task.time);
      task.energy && energy.add(task.energy);
      const priorityEnum = getPriorityEnum(task.priorityValues);
      priorityEnum && priority.add(priorityEnum);
      summaryTime += task.time || 0;
      if (task.energy) {
        summaryEnergy[task.energy] += 1;
      }
    }

    return {
      summaryEnergy,
      summaryTime,
      uniqueFilterValues: {
        tags: Array.from(tags),
        time: Array.from(time),
        energy: Array.from(energy),
        priority: Array.from(priority),
      },
    };
  }

  mergeUniqueFilterValuesAndStats = (collectionName: string, tasks: TaskType[]) => {
    const values = this.getUniqueFilterValuesAndStats(tasks);
    logDebug([], 'mergeUniqueFilterValuesAndStats', { collectionName, values });
    this.uniqueFilterValuesMap.set(collectionName, values);

    const tags = new Set<string>();
    const time = new Set<TaskTime>();
    const energy = new Set<TaskEnergy>();
    const priority = new Set<Priority>();

    let summaryTime = 0;
    const summaryEnergy: {
      [key in TaskEnergy]: number;
    } = {
      [TaskEnergy.ENERGY_LOW]: 0,
      [TaskEnergy.ENERGY_MED]: 0,
      [TaskEnergy.ENERGY_HIGH]: 0,
    };

    this.uniqueFilterValuesMap.forEach((values) => {
      values.uniqueFilterValues.tags.forEach((e) => tags.add(e));
      values.uniqueFilterValues.time.forEach((e) => time.add(e));
      values.uniqueFilterValues.energy.forEach((e) => energy.add(e));
      values.uniqueFilterValues.priority.forEach((e) => priority.add(e));
      summaryTime += values.summaryTime;
      Object.keys(summaryEnergy).forEach((k) => {
        const key = (k as unknown) as TaskEnergy;
        summaryEnergy[key] += values.summaryEnergy[key];
      });
    });

    const uniqueFilterValues = {
      tags: Array.from(tags),
      time: Array.from(time),
      energy: Array.from(energy),
      priority: Array.from(priority),
    };

    if (
      !isEqual(this.state.uniqueFilterValues, uniqueFilterValues) ||
      !isEqual(this.state.summaryEnergy, summaryEnergy) ||
      !isEqual(this.state.summaryTime, summaryTime)
    ) {
      this.setState({
        uniqueFilterValues,
        summaryEnergy,
        summaryTime,
      });
    }
  };

  handleChangeEnergy = (energy?: TaskEnergy | null) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        energy,
      },
    });
  };

  handleChangeTime = (time?: TaskTime | null) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        time,
      },
    });
  };

  handleChangePriority = (priority?: Priority | null) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        priority,
      },
    });
  };

  handleChangeTags = (tags?: string[]) => {
    const { filter } = this.state;
    this.setState({
      filter: {
        ...filter,
        tags,
      },
    });
  };

  isChangeStatus = (desiredStatus: ChangeState) => {
    return this.state.changeState === desiredStatus;
  };

  resetChangeStatus = () => {
    this.setState({ changeState: undefined });
  };

  private tagsSub?: Subscription;

  componentDidMount(): void {
    this.initTagsSub();
  }

  componentWillUnmount() {
    this.tagsSub?.unsubscribe();
  }

  initTagsSub = () => {
    if (this.tagsSub) {
      this.tagsSub.unsubscribe();
    }
    const {
      repos: { tagRepo },
      context,
    } = this.props;
    this.tagsSub = tagRepo.getAllForFilter$(context.id, {}).subscribe((tags) => {
      this.setState({ tags: tags });
    });
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.context !== prevProps.context) {
      this.initTagsSub();
    }
  }

  render() {
    const { children } = this.props;
    const { uniqueFilterValues, filter, tags, summaryTime } = this.state;

    logDebug([], 'filter', filter);

    return (
      <>
        <div>
          <div style={genericStyles.chipWrapper}>
            <Tooltip
              destroyTooltipOnHide
              placement="top"
              trigger={['hover', 'click']}
              overlay={
                <div style={{ width: '150px', height: '40px' }}>
                  Summary time of shown tasks, with applied filters
                </div>
              }
            >
              <IonChip style={genericStyles.chip} color="dark" outline={true}>
                <IonIcon
                  icon={filter.energy !== undefined ? stopwatch : stopwatchOutline}
                />
                <IonLabel>{formatTime(summaryTime)}</IonLabel>
              </IonChip>
            </Tooltip>

            {/*<Tooltip*/}
            {/*  destroyTooltipOnHide*/}
            {/*  placement="top"*/}
            {/*  trigger={['hover', 'click']}*/}
            {/*  overlay={*/}
            {/*    <div style={{ width: '120px', height: '40px' }}>*/}
            {/*      Energy stats of shown tasks*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*>*/}
            {/*  <IonChip style={genericStyles.chip} color="dark" outline={true}>*/}
            {/*    <IonIcon*/}
            {/*      icon={filter.energy !== undefined ? cellular : cellularOutline}*/}
            {/*    />*/}
            {/*    <IonLabel>*/}
            {/*      {Object.entries(summaryEnergy).map(([k, v]) => (*/}
            {/*        <span key={k}>*/}
            {/*          {getEnergyLabel((parseInt(k, 10) as unknown) as TaskEnergy)}:{' '}*/}
            {/*          {v}{' '}*/}
            {/*        </span>*/}
            {/*      ))}*/}
            {/*    </IonLabel>*/}
            {/*  </IonChip>*/}
            {/*</Tooltip>*/}
            <IonChip
              style={genericStyles.chip}
              color="primary"
              outline={!filter.energy}
              onClick={() => this.setState({ changeState: ChangeState.energy })}
            >
              <IonIcon
                icon={filter.energy !== undefined ? cellular : cellularOutline}
              />
              <IonLabel>
                {filter.energy !== undefined
                  ? getEnergyLabel(filter.energy)
                  : 'energy'}
              </IonLabel>
            </IonChip>

            <IonChip
              style={genericStyles.chip}
              color="primary"
              outline={!filter.time}
              onClick={() => this.setState({ changeState: ChangeState.time })}
            >
              <IonIcon
                icon={filter.time !== undefined ? stopwatch : stopwatchOutline}
              />
              <IonLabel>
                {filter.time !== undefined ? getTimeLabel(filter.time) : 'time'}
              </IonLabel>
            </IonChip>

            <IonChip
              style={genericStyles.chip}
              color="primary"
              outline={!filter.priority}
              onClick={() => this.setState({ changeState: ChangeState.priority })}
            >
              <IonIcon
                icon={
                  filter.priority !== undefined ? alertCircle : alertCircleOutline
                }
              />
              <IonLabel>
                {filter.priority !== undefined
                  ? getPriorityLabel(
                      getPriorityEnum({
                        ice: undefined,
                        preset: filter.priority || undefined,
                      })
                    )
                  : 'priority'}
              </IonLabel>
            </IonChip>
          </div>

          {uniqueFilterValues.tags.length > 0 && (
            <div style={{ fontSize: '14px', display: 'flex', flexWrap: 'wrap' }}>
              {tags
                ?.filter((t) => uniqueFilterValues.tags.includes(t.id))
                .map((tag) => {
                  const isSelected = filter.tags?.includes(tag.id);
                  return (
                    <Tag
                      key={`tag-${tag.id}`}
                      selected={isSelected}
                      tag={tag}
                      onClick={
                        isSelected
                          ? () => {
                              const newTags = filter.tags?.filter(
                                (tt) => tt !== tag.id
                              );
                              this.setState({
                                filter: {
                                  ...filter,
                                  tags: newTags?.length ? newTags : undefined,
                                },
                              });
                            }
                          : () =>
                              this.setState({
                                filter: {
                                  ...filter,
                                  tags: [...(filter.tags || []), tag.id],
                                },
                              })
                      }
                    />
                  );
                })}
            </div>
          )}

          {some(filter, (v) =>
            Array.isArray(v) ? v.length > 0 : v !== undefined
          ) && (
            <IonChip
              style={genericStyles.chip}
              color="danger"
              outline={true}
              onClick={() => this.setState({ filter: {} })}
            >
              <IonIcon icon={closeOutline} />
              <IonLabel>reset filter</IonLabel>
            </IonChip>
          )}
        </div>
        {children({
          filter,
          setUniqueAndStatsFilterValues: this.mergeUniqueFilterValuesAndStats,
        })}

        {/* TIME */}
        <IonActionSheet
          isOpen={this.isChangeStatus(ChangeState.time)}
          onDidDismiss={this.resetChangeStatus}
          header="Filter tasks based on the time to complete the task"
          buttons={[
            {
              text: '2m or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_2_MIN);
              },
            },
            {
              text: '5m or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_5_MIN);
              },
            },
            {
              text: '10m or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_10_MIN);
              },
            },
            {
              text: '15m or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_15_MIN);
              },
            },
            {
              text: '30m or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_30_MIN);
              },
            },
            {
              text: '45m or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_45_MIN);
              },
            },
            {
              text: '1h or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_1_H);
              },
            },
            {
              text: '2h or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_2_H);
              },
            },
            {
              text: '3h or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_3_H);
              },
            },
            {
              text: '6h or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_6_H);
              },
            },
            {
              text: '8h or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_8_H);
              },
            },
            {
              text: '2d or less',
              handler: () => {
                this.handleChangeTime(TaskTime.TIME_2_D);
              },
            },
            {
              text: 'not set',
              icon: scanCircleOutline,
              handler: () => {
                this.handleChangeTime(null);
              },
            },
            {
              text: 'any',
              icon: appsOutline,
              handler: () => {
                this.handleChangeTime(undefined);
              },
            },
          ]}
        />

        {/* ENERGY */}
        <IonActionSheet
          isOpen={this.isChangeStatus(ChangeState.energy)}
          onDidDismiss={this.resetChangeStatus}
          header="Filter tasks based on your current energy level"
          buttons={[
            {
              text: 'low',
              handler: () => {
                this.handleChangeEnergy(TaskEnergy.ENERGY_LOW);
              },
            },
            {
              text: 'med',
              handler: () => {
                this.handleChangeEnergy(TaskEnergy.ENERGY_MED);
              },
            },
            {
              text: 'high',
              handler: () => {
                this.handleChangeEnergy(TaskEnergy.ENERGY_HIGH);
              },
            },
            {
              text: 'not set',
              icon: scanCircleOutline,
              handler: () => {
                this.handleChangeEnergy(null);
              },
            },
            {
              text: 'any',
              icon: appsOutline,
              handler: () => {
                this.handleChangeEnergy(undefined);
              },
            },
          ]}
        />

        {/* PRIORITY */}
        <IonActionSheet
          isOpen={this.isChangeStatus(ChangeState.priority)}
          onDidDismiss={this.resetChangeStatus}
          header="Filter tasks based on the task priority"
          buttons={[
            {
              text: 'low',
              handler: () => {
                this.handleChangePriority(Priority.PRIORITY_LOW);
              },
            },
            {
              text: 'med',
              handler: () => {
                this.handleChangePriority(Priority.PRIORITY_MEDIUM);
              },
            },
            {
              text: 'high',
              handler: () => {
                this.handleChangePriority(Priority.PRIORITY_HIGH);
              },
            },
            {
              text: 'not set',
              icon: scanCircleOutline,
              handler: () => {
                this.handleChangePriority(null);
              },
            },
            {
              text: 'any',
              icon: appsOutline,
              handler: () => {
                this.handleChangePriority(undefined);
              },
            },
          ]}
        />
      </>
    );
  }
}

export default withStorage(withWorkspace(TaskListFilter));
