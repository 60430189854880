import React from 'react';
import { withStorage } from '../helpers/StorageProviders';
import { ItemType, TaskEnrichedWithProjectAndTags } from '../types/CoreTypes';
import { RouteComponentProps } from 'react-router';
import TaskDetails from '../components/Tasks/TaskDetails';
import { IonContent, IonPage, IonTitle } from '@ionic/react';
import TaskActionsWrapper from '../components/Tasks/TaskActionsWrapper';
import { StorageDeps } from '../helpers/InitStorage';
import CommonHeader from '../components/Common/CommonHeader';
import { RxChangeEvent } from 'rxdb/plugins/core';
import { Subscription } from 'rxjs';

interface TParams {
  id?: string;
}

interface Props extends RouteComponentProps<TParams>, StorageDeps {}

interface State {
  task: TaskEnrichedWithProjectAndTags | null;
}

class TaskViewPage extends React.PureComponent<Props, State> {
  state: State = { task: null };

  sub?: Subscription;

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadTasksData();
    }
  }

  handleDbChangeEvent = (evt: RxChangeEvent) => {
    if ([ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)) {
      this.loadTasksData();
    }
  };

  componentDidMount(): void {
    this.loadTasksData();
    this.sub = this.props.subscribeForDbChanges(this.handleDbChangeEvent);
  }

  componentWillUnmount() {
    this.sub?.unsubscribe();
  }

  loadTasksData = () => {
    const { match } = this.props;
    const id = match.params.id;
    if (!id) return;
    this.props.repos.taskRepo.getById(id).then((task) => this.setState({ task }));
  };

  render() {
    const { task } = this.state;
    if (!task) return null;

    return (
      <IonPage>
        <CommonHeader showBack>
          <IonTitle>Task</IonTitle>
        </CommonHeader>
        <IonContent>
          <TaskActionsWrapper>
            {(taskHandlers) => (
              <TaskDetails
                onChange={this.loadTasksData}
                task={task}
                taskHandlers={taskHandlers}
              />
            )}
          </TaskActionsWrapper>
        </IonContent>
      </IonPage>
    );
  }
}

export default withStorage(TaskViewPage);
