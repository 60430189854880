import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { TaskTime } from '../../types/CoreTypes';
import SingleSelect from '../Common/SingleSelect';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import { getTimeLabel } from '../../helpers/CopyHelper';

interface BaseProps {
  show: boolean;
  time: TaskTime | undefined;
  onClose: () => void;
  onSave: (status: TaskTime | undefined) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

const times = Object.values(TaskTime)
  .filter((t) => typeof t === 'number')
  .map((t) => ({
    value: t.toString(),
    label: getTimeLabel(t as TaskTime),
  }));

const EditTimeModal = ({ onClose, onSave, time, show, forwardRef }: Props) => {
  const [selectedTimeId, setSelectedTimeId] = useState<TaskTime | undefined>(
    undefined
  );
  useEffect(() => {
    setSelectedTimeId(time);
  }, [time, show]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedTimeId(undefined);
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave(selectedTimeId);
    handleClose();
  }, [handleClose, onSave, selectedTimeId]);

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title="Choose time effort"
    >
      <SingleSelect
        ref={forwardRef}
        value={selectedTimeId?.toString()}
        placeholder="Time"
        options={times || []}
        onChange={(s) =>
          setSelectedTimeId(
            (typeof s === 'string' ? parseInt(s) : s) as TaskTime | undefined
          )
        }
      />
    </PropertyEditModalWrapper>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <EditTimeModal forwardRef={ref} {...props} />
));
