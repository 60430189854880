import React from 'react';
import TaskStatusList from '../components/Tasks/TaskStatusList';
import { IonContent, IonPage } from '@ionic/react';
import TaskListFilter from '../components/Tasks/TaskListFilter';
import CommonHeader from '../components/Common/CommonHeader';
import { TaskStatusKey } from '../helpers/TaskHelper';
import ListNavigation from '../components/Common/ListNavigation';
import WideMessage from '../components/Common/WideMessage';
import { colors } from '../helpers/styles';
import { mailOpen } from 'ionicons/icons';

class Inbox extends React.Component {
  render() {
    return (
      <IonPage>
        <CommonHeader />
        <IonContent>
          <ListNavigation collections={[TaskStatusKey.Inbox]}>
            {(listNavigationApi) => (
              <TaskListFilter>
                {({ filter, setUniqueAndStatsFilterValues }) => (
                  <TaskStatusList
                    // defaultStatus={TaskStatus.STATUS_INBOX}
                    listNavigationApi={listNavigationApi}
                    filter={filter}
                    setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                    statuses={[TaskStatusKey.Inbox]}
                    emptyMessage="No tasks here yet. Tasks that you create typically go to inbox, that you can sort out later"
                  />
                )}
              </TaskListFilter>
            )}
          </ListNavigation>
          <WideMessage text="Inbox framework" icon={mailOpen} color={colors.primary}>
            <ul style={{ color: colors.primary, fontSize: '13px' }}>
              <li>
                Add most of the new tasks to inbox, and review inbox periodically
              </li>
              <li>Will it take less than 2 minutes to complete? Do it now</li>
              <li>Can&lsquo;t do it soon? Schedule</li>
              <li>
                Just an idea, don&lsquo;t know when to do it? Send to &quot;Some
                time&quot;
              </li>
              <li>Blocked on somebody or something? Move to &quot;Waiting&quot;</li>
            </ul>
          </WideMessage>
        </IonContent>
      </IonPage>
    );
  }
}

export default Inbox;
