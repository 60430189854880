import { IonIcon, IonItem, IonLabel, IonReorder } from '@ionic/react';
import React from 'react';
import { ContextType } from '../../types/CoreTypes';
import { reorderFourOutline } from 'ionicons/icons';

interface Props {
  context: ContextType;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
}

const ContextListElementMobile: React.FunctionComponent<Props> = ({
  context,
  onAfterNavigate,
}: Props) => {
  return (
    <IonItem
      // color={isSelected ? 'light' : undefined}
      routerLink={`/contexts/edit/${context.id}`}
      routerDirection={'root'}
      onClick={onAfterNavigate}
    >
      <IonReorder slot="start" style={{ marginRight: 10 }}>
        <IonIcon style={{ fontSize: 25 }} color="medium" icon={reorderFourOutline} />
      </IonReorder>
      <IonLabel>
        <h2>{context.name}</h2>
      </IonLabel>
    </IonItem>
  );
};

export default ContextListElementMobile;
