import { IonList, IonReorderGroup } from '@ionic/react';
import React from 'react';
import { TagType } from '../../types/CoreTypes';
import TagListRow from './TagListRow';
import { ItemReorderEventDetail } from '@ionic/core';
import { withStorage } from '../../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import { swap } from '../../helpers/PositionHelper';
import { StorageDeps } from '../../helpers/InitStorage';
import { Subscription } from 'rxjs';
import { debounce, isEqual } from 'lodash';
import WideMessage from '../Common/WideMessage';
import { warning } from 'ionicons/icons';
import { colors } from '../../helpers/styles';
import AreYouSureModal from '../propertyEditModals/AreYouSureModal';
import ContextListRow from '../Contexts/ContextListRow';

interface Props extends WorkspaceProps, StorageDeps {
  parentId: string | null;
  onChangeTag: (tagData: Partial<TagType>, selectedTag: TagType | null) => void;
  onDeleteTag: (id: string) => Promise<void>;
  setCounter?: (count: number) => void;
  selectedIdx?: number;
  getRef?: (idx: number) => React.RefObject<any>;
}

interface State {
  tags: TagType[];
  selectedForDeletionId?: string;
}

class TagListView extends React.Component<Props, State> {
  state: State = {
    tags: [],
  };

  dbUpdateSubscription?: Subscription;

  // constructor and vars go above ^^

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (!isEqual(prevState.tags, this.state.tags)) {
      this.refreshCounters();
    }
    if (
      !isEqual(prevProps.parentId, this.props.parentId) ||
      !isEqual(prevProps.context, this.props.context)
    ) {
      this.removeDataSubscription();
      this.setUpDataSubscription();
    }
  }

  componentDidMount(): void {
    this.setUpDataSubscription();
  }

  setUpDataSubscription() {
    const { context, parentId } = this.props;
    this.dbUpdateSubscription = this.props.repos.tagRepo
      .getAllForFilter$(context.id, { parentId })
      .subscribe(this.setTagsDebounced);
  }

  removeDataSubscription() {
    this.dbUpdateSubscription?.unsubscribe();
  }

  componentWillUnmount() {
    this.removeDataSubscription();
  }

  // lifecycle goes above ^^

  refreshCounters = () => {
    this.props.setCounter && this.props.setCounter(this.state.tags.length);
  };

  setTags = (tags: TagType[]) => {
    this.setState({ tags });
  };

  setTagsDebounced = debounce((tags: TagType[]) => {
    this.setTags(tags);
  }, 200);

  doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    return (
      this.props.repos.tagRepo
        .reorder(this.state.tags, event.detail.from, event.detail.to)
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        .then(() => event.detail.complete())
    );
  };

  manualReorder = (up: boolean) => {
    const idx = this.props.selectedIdx;
    if (idx === undefined) return Promise.resolve(false);
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    const to = up ? idx - 1 : idx + 1;
    // important, to read it before all following ops
    const { tags } = this.state;
    if (to < 0 || to >= tags.length) return Promise.resolve(false);
    this.setTagsDebounced.cancel();
    this.setTagsDebounced(swap(tags, idx, to));
    this.setTagsDebounced.flush();
    return this.props.repos.tagRepo.reorder(tags, idx, to).then(() => true);
  };

  renderEmptyMessage() {
    const { tags } = this.state;
    if (tags.length > 0) return null;
    return (
      <WideMessage
        text="No tags here yet. Create new tags to organize your tasks"
        icon={warning}
        color={colors.grayOut}
      />
    );
  }

  selectForDelete = (id: string) => {
    this.setState({ selectedForDeletionId: id });
  };

  handleDelete = () => {
    if (this.state.selectedForDeletionId) {
      this.props.onDeleteTag(this.state.selectedForDeletionId);
      this.setState({ selectedForDeletionId: undefined });
    }
  };

  handleCloseDeleteModal = () => {
    if (this.state.selectedForDeletionId) {
      this.setState({ selectedForDeletionId: undefined });
    }
  };

  render() {
    const { selectedIdx, getRef } = this.props;
    const { tags, selectedForDeletionId } = this.state;

    return (
      <>
        <IonList>
          <IonReorderGroup disabled={false} onIonItemReorder={this.doReorder}>
            {tags.map((tag, idx) => (
              <TagListRow
                key={tag.id}
                tag={tag}
                onDelete={this.selectForDelete}
                manualReorder={this.manualReorder}
                ref={getRef ? getRef(idx) : undefined}
                isSelected={idx === selectedIdx}
              />
            ))}
          </IonReorderGroup>
          {this.renderEmptyMessage()}
        </IonList>
        <AreYouSureModal
          show={!!selectedForDeletionId}
          text={'If you delete the tag, you cannot recover it'}
          onClose={this.handleCloseDeleteModal}
          onAccept={this.handleDelete}
        />
      </>
    );
  }
}

export default withStorage(withWorkspace(TagListView));
