import React, { createContext, useContext } from 'react';
import MyIonModalAlwaysVisible from './MyIonModalAlwaysVisible';
import {
  ActionSubscriber,
  MagicHotkeyProps,
  withMagicHotkey,
} from './MagicHotkeyProvider';
import { Key, KeyModifier, SHIFT_ALT } from '../types/CoreTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import SearchWrapper from '../components/SearchWrapper';
import { logDebug } from '../lib/logger';

interface GlobalActionsProps {
  triggerSearch: () => void;
}

// @ts-ignore
export const GlobalActionsContext = createContext<GlobalActionsProps>(undefined);

interface Props extends RouteComponentProps, MagicHotkeyProps {
  children: any;
}

interface State {
  isSearching: boolean;
}

class GlobalActionsProviderBase extends React.PureComponent<Props> {
  state: State = {
    isSearching: false,
  };

  private readonly searchRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.searchRef = React.createRef();
  }

  handleOpenSearch = () => {
    this.setState({ isSearching: true });
    // @ts-ignore
    this.searchRef.current && this.searchRef.current.setFocus();
    this.searchRef.current &&
      this.searchRef.current.getInputElement().then((el: any) => {
        el.select();
      });
  };

  // handleKeyPress = (e: KeyboardEvent) => {
  //   if ((e.metaKey && e.key === 'f') || (e.ctrlKey && e.key === 'f')) {
  //     e.preventDefault();
  //     this.handleOpenSearch();
  //   }
  // };

  private makeNavigate = (url: string) => () =>
    this.props.history.push(url, { direction: 'root' });

  private actions = [
    {
      name: 'search',
      combination: {
        key: Key.F,
        modifiers: [[KeyModifier.Cmd], [KeyModifier.Ctrl]],
      },
      action: this.handleOpenSearch,
    },
    {
      name: 'open focus',
      combination: {
        key: Key._0,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/focus'),
    },
    {
      name: 'open next',
      combination: {
        key: Key._9,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/next'),
    },
    {
      name: 'open waiting',
      combination: {
        key: Key._8,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/waiting'),
    },
    {
      name: 'open some time',
      combination: {
        key: Key._7,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/sometime'),
    },
    {
      name: 'open inbox',
      combination: {
        key: Key._6,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/inbox'),
    },
    {
      name: 'open projects',
      combination: {
        key: Key._5,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/projects'),
    },
    {
      name: 'open tags',
      combination: {
        key: Key._4,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/tags'),
    },
    {
      name: 'open agenda',
      combination: {
        key: Key._3,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/agenda'),
    },
    {
      name: 'open archive',
      combination: {
        key: Key._2,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/archive'),
    },
    {
      name: 'open contexts',
      combination: {
        key: Key._1,
        modifiers: SHIFT_ALT,
      },
      action: this.makeNavigate('/contexts'),
    },
    // {
    //   name: 'open config / settings',
    //   combination: {
    //     key: Key._0,
    //     modifiers: [[KeyModifier.Ctrl, KeyModifier.Shift], [[KeyModifier.Cmd, KeyModifier.Shift]]
    //   },
    //   action: this.makeNavigate('/settings')
    // }
  ];

  componentDidMount(): void {
    logDebug([], 'loading global actions key listener...');
    this.actionSubscriber = this.props.registerActions('global', this.actions);
    // document.addEventListener('keydown', this.handleKeyPress, false);
  }

  private actionSubscriber?: ActionSubscriber;

  componentWillUnmount() {
    logDebug([], 'unloading global actions key listener...');
    this.actionSubscriber?.unsubscribe();
    // document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  handleCloseModal = () => {
    // this.searchRef.current &&
    //   this.searchRef.current.getInputElement().then((el: any) => el.blur());
    this.setState({ isSearching: false });
  };

  render() {
    const { children } = this.props;

    return (
      <GlobalActionsContext.Provider
        value={{
          triggerSearch: this.handleOpenSearch,
        }}
      >
        {children}

        <MyIonModalAlwaysVisible
          cssClass="search-modal"
          animated={true}
          isOpen={this.state.isSearching}
          onDidDismiss={this.handleCloseModal}
        >
          <SearchWrapper
            isHidden={!this.state.isSearching}
            searchRef={this.searchRef}
            onClose={this.handleCloseModal}
          />
        </MyIonModalAlwaysVisible>
      </GlobalActionsContext.Provider>
    );
  }
}

export const GlobalActionsProvider = withRouter(
  withMagicHotkey(GlobalActionsProviderBase)
);

export const useGlobalActions: () => GlobalActionsProps = () =>
  useContext(GlobalActionsContext);

export const withGlobalActions = <P extends Partial<GlobalActionsProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof GlobalActionsProps>> => {
  return function withGlobalActionsContext(props) {
    return (
      <GlobalActionsContext.Consumer>
        {(contextProps: GlobalActionsProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </GlobalActionsContext.Consumer>
    );
  };
};
