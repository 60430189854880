import React, { createContext, useContext } from 'react';
import { CountersProps, withCounters } from './CountersProvider';
import { isEqual } from 'lodash';

// @ts-ignore
export const FreeTierContext = createContext<FreeTierProps>(undefined);

interface Props extends CountersProps {
  children: any;
  isPaid: boolean;
}

interface State {
  isPaid: boolean;
  usedTier: UsedTier;
}

export type UsedTier = {
  [key in FreeTierEntity]: number;
};

export enum FreeTierEntity {
  task,
  project,
  context,
}

export interface FreeTierProps {
  isPaid: boolean;
  hasUsedFreeTier: (type: FreeTierEntity) => boolean;
}

class FreeTierProvider extends React.PureComponent<Props, State> {
  readonly state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      isPaid: props.isPaid,
      usedTier: {
        [FreeTierEntity.task]: 0,
        [FreeTierEntity.project]: 0,
        [FreeTierEntity.context]: 0,
      },
    };
  }

  private updateCounts = () => {
    const { counters } = this.props;
    const taskCount = Object.values(counters).reduce(
      (acc, cur) => acc + (cur.taskSources.All?.size || 0),
      0
    );
    const projectCount = Object.values(counters).reduce(
      (acc, cur) => acc + (cur.projectSources.All?.size || 0),
      0
    );
    const contextCount = Object.keys(counters).length;
    this.setState({
      usedTier: {
        [FreeTierEntity.task]: taskCount,
        [FreeTierEntity.project]: projectCount,
        [FreeTierEntity.context]: contextCount,
      },
    });
  };

  componentDidMount(): void {
    this.updateCounts();
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.counters, this.props.counters)) {
      this.updateCounts();
    }
    if (prevProps.isPaid !== this.props.isPaid) {
      this.setState({ isPaid: this.props.isPaid });
    }
  }

  private handleHasUsedFreeTier = (type: FreeTierEntity): boolean => {
    const { usedTier, isPaid } = this.state;
    if (isPaid) {
      return false;
    }
    switch (type) {
      case FreeTierEntity.task: {
        return usedTier[type] >= 20;
      }
      case FreeTierEntity.project: {
        return usedTier[type] >= 3;
      }
      case FreeTierEntity.context: {
        return usedTier[type] >= 2;
      }
    }
  };

  render() {
    const { children } = this.props;
    const { isPaid } = this.state;

    return (
      <FreeTierContext.Provider
        value={{ isPaid, hasUsedFreeTier: this.handleHasUsedFreeTier }}
      >
        {children}
      </FreeTierContext.Provider>
    );
  }
}

export default withCounters(FreeTierProvider);

export const useFreeTier: () => FreeTierProps = () => useContext(FreeTierContext);

export const withFreeTier = <P extends Partial<FreeTierProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof FreeTierProps>> => {
  return function withFreeTierContext(props) {
    return (
      <FreeTierContext.Consumer>
        {(contextProps: FreeTierProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </FreeTierContext.Consumer>
    );
  };
};
