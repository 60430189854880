import React, { Component } from 'react';
import {
  ProjectEnrichedWithTaskStatsAndDependingProject,
  ProjectStatus,
} from '../types/CoreTypes';
import { Subscription } from 'rxjs';
import { DateProps, withDate } from '../helpers/DateProvider';
import { withStorage } from '../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../helpers/WorkspaceProviders';
import { StorageDeps } from '../helpers/InitStorage';
import { addDays } from 'date-fns';
import {
  formatDateWithoutTime,
  makeDateWithoutTime,
} from '../helpers/RecurringHelper';
import { warning } from 'ionicons/icons';
import { colors } from '../helpers/styles';
import WideMessage from '../components/Common/WideMessage';
import { IonContent, IonPage, IonRouterLink } from '@ionic/react';
import CommonHeader from '../components/Common/CommonHeader';
import { Routes } from '../lib/routes';
import differenceInDays from 'date-fns/differenceInDays';
import FrappeGantt, { IGanttTask } from '../components/Common/FrappeGantt';
import { changeColorLightness, generateColorFromString } from '../helpers/colors';
import { getProjectEmoji, sortByStatusAndPosition } from '../helpers/ProjectHelper';
import DetailsModal from '../components/Common/DetailsModal';
import ProjectActionsWrapper from '../components/Projects/ProjectActionsWrapper';
import { formatTime } from '../lib/DateHelper';
import ProjectDetails from '../components/Projects/ProjectDetails';
import { ClientTypeProps, withClientType } from '../helpers/ClientTypeProvider';

interface StoredProjData extends IGanttTask {
  project: ProjectEnrichedWithTaskStatsAndDependingProject;
}

interface State {
  projects: StoredProjData[];
  selectedProject?: ProjectEnrichedWithTaskStatsAndDependingProject;
  mobileModalOpen: boolean;
  isLoading: boolean;
}

interface Props extends WorkspaceProps, StorageDeps, DateProps, ClientTypeProps {}

const ROOT = '0';

class GanttPage extends Component<Props, State> {
  state: State = {
    isLoading: true,
    mobileModalOpen: false,
    projects: [],
  };

  dbUpdateSubscription?: Subscription;

  computeEnd = (start: string, duration: number): string => {
    return formatDateWithoutTime(addDays(makeDateWithoutTime(start), duration - 1));
  };

  computeDuration = (start: string, end: string): number => {
    return differenceInDays(makeDateWithoutTime(end), makeDateWithoutTime(start));
  };

  private getProjectColor = (
    p: ProjectEnrichedWithTaskStatsAndDependingProject
  ): string => {
    return generateColorFromString(p.id);
  };

  private renderName = (
    p: ProjectEnrichedWithTaskStatsAndDependingProject
  ): string => {
    return `${getProjectEmoji(p.status)} ${p.name} (${
      p.remainingTasks
    } / ${formatTime(p.remainingTime)})`;
  };

  setProjects = (projects: ProjectEnrichedWithTaskStatsAndDependingProject[]) => {
    const idMap = new Map(projects.map((p) => [p.id, p]));
    const adjacencyMatrix: {
      [key: string]: string[];
    } = {};
    const reverseAdjacencyMatrix: {
      [key: string]: string;
    } = {};

    projects.forEach((p) => {
      const key =
        // to ensure project it depends on is in scope and is not completed
        (p.dependsOnProjectId && idMap.get(p.dependsOnProjectId)?.id) || ROOT;
      if (!adjacencyMatrix[key]) {
        adjacencyMatrix[key] = [];
      }
      adjacencyMatrix[key].push(p.id);
      reverseAdjacencyMatrix[p.id] = key;
    });

    // check connectivity to detect cycles and kill them
    projects.forEach((p) => {
      const visited = new Set();
      let current = p.id;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const parent = reverseAdjacencyMatrix[current];
        if (parent === undefined)
          throw new Error(`undefined reverse matrix parent for ${current}`);
        if (parent in visited) {
          // detected cycle
          // force break cycle: assign it to ROOT
          reverseAdjacencyMatrix[current] = ROOT;
          adjacencyMatrix[ROOT].push(current);
          // remove from previous parent's adj matr
          adjacencyMatrix[parent] = adjacencyMatrix[parent].filter(
            (e) => e !== current
          );
          break;
        }
        if (parent === ROOT) {
          // arrived to root
          break;
        }
        visited.add(parent);
        current = parent;
      }
    });

    // DFS to build a tree
    const queue: string[] = [];
    adjacencyMatrix[0].reverse().forEach((pid) => queue.push(pid));

    // console.log('gantt', { adjacencyMatrix, reverseAdjacencyMatrix, queue });

    const results: Map<string, StoredProjData> = new Map();
    while (queue.length) {
      const id = queue.pop();
      if (!id) throw new Error('attempted reading from empty queue');

      const p = idMap.get(id);
      if (!p) throw new Error(`project ${id} not found in idMap`);

      if (adjacencyMatrix[id]) queue.push(...adjacencyMatrix[id]);

      let start = p.dateStart;
      const duration = p.duration || 1;
      if (p.dependsOnProjectId) {
        const parentStarts = results.get(p.dependsOnProjectId);
        // if (!parentStarts)
        //   throw new Error(`no parentStarts of ${p.dependsOnProjectId}`);
        if (parentStarts && parentStarts.end) {
          if (!start || start <= parentStarts.end) {
            const newStartDate = addDays(makeDateWithoutTime(parentStarts.end), 1);
            start = formatDateWithoutTime(newStartDate);
          }
        }
      }
      if (!start) {
        start = formatDateWithoutTime(new Date());
      }
      const color = this.getProjectColor(p);
      results.set(id, {
        id: p.id,
        name: this.renderName(p),
        start,
        end: this.computeEnd(start, duration),
        progress:
          p.totalTasks > 0
            ? ((p.totalTasks - p.remainingTasks) / p.totalTasks) * 100
            : 0,
        color: color,
        lightColor: changeColorLightness(color, 0x50),
        dependencies: p.dependsOnProjectId ? [p.dependsOnProjectId] : [],
        project: p,
      });
    }

    const newProjects = [...results.values()];

    if (newProjects.length !== projects.length) {
      throw new Error(
        `expected ${projects.length} project data, but found ${newProjects.length}`
      );
    }

    let newSelectedProject;
    if (this.state.selectedProject) {
      newSelectedProject = this.findSelectedProject(
        this.state.selectedProject.id,
        newProjects
      );
    }
    this.setState({
      projects: newProjects,
      selectedProject: newSelectedProject,
      isLoading: false,
    });
  };

  componentDidMount(): void {
    this.dbUpdateSubscription = this.props.repos.projectRepo
      .getAllForFilterEnriched$({
        contextId: this.props.context.id,
        formattedDate: this.props.dateFormatted,
        filter: {
          statusIn: [
            ProjectStatus.STATUS_NEXT,
            ProjectStatus.STATUS_WAITING,
            ProjectStatus.STATUS_ACTION,
          ],
        },
        sortFn: sortByStatusAndPosition,
      })
      .subscribe(this.setProjects);
  }

  componentWillUnmount() {
    this.dbUpdateSubscription?.unsubscribe();
  }

  handleDateChange = async (task: IGanttTask, start: Date, end: Date) => {
    // logDebug(['gantt'], 'upd proj', { task, start, end });
    const { projectRepo } = this.props.repos;
    // const project = await projectRepo.getById(task.id);
    // if (!project) return;
    const dateStart = formatDateWithoutTime(start);
    const duration = this.computeDuration(dateStart, formatDateWithoutTime(end)) + 1;
    // logDebug(['gantt'], 'upd proj', {
    //   id: task.id,
    //   dateStart,
    //   duration,
    // });
    return projectRepo.edit({
      id: task.id,
      dateStart,
      duration,
    });
  };

  private findSelectedProject = (id: string, ppx: StoredProjData[]) => {
    return ppx.find((p) => p.id === id)?.project;
  };

  handleClick = (t: IGanttTask) => {
    this.setState({
      selectedProject: this.findSelectedProject(t.id, this.state.projects),
    });
  };

  handleMobileModalOpen = () => {
    this.setState({
      mobileModalOpen: true,
    });
  };

  handleCloseModal = () => {
    this.setState({ selectedProject: undefined, mobileModalOpen: false });
  };

  handleBlur = () => {
    if (this.shouldOptimizeForTouch()) {
      if (!this.state.mobileModalOpen) {
        this.setState({ selectedProject: undefined });
      }
    }
  };

  shouldOptimizeForTouch = () => {
    return this.props.isNative || !this.props.isDesktop;
  };

  render() {
    const { projects, selectedProject, mobileModalOpen, isLoading } = this.state;

    const optimizeForTouch = this.shouldOptimizeForTouch();

    if (!projects.length) {
      return (
        <WideMessage
          text={isLoading ? 'Loading...' : 'No projects here yet'}
          icon={warning}
          color={colors.grayOut}
        />
      );
    }

    const openModal = !optimizeForTouch
      ? !!selectedProject
      : !!selectedProject && mobileModalOpen;

    return (
      <IonPage>
        <CommonHeader>
          {/*Projects.{' '}*/}
          <span style={{ fontSize: 14 }}>
            Switch to{' '}
            <IonRouterLink
              routerLink={`${Routes.projects}`}
              routerDirection={'root'}
            >
              List view
            </IonRouterLink>
          </span>
        </CommonHeader>
        <IonContent>
          <FrappeGantt
            tasks={projects}
            viewMode="Day"
            onClick={this.handleClick}
            onBlur={this.handleBlur}
            onDateChange={this.handleDateChange}
          />
          <DetailsModal
            klass="project-details-popup"
            onClose={this.handleCloseModal}
            show={openModal}
            title="Project details"
          >
            {openModal && (
              <ProjectActionsWrapper>
                {(projectHandlers) =>
                  !!this.state.selectedProject && (
                    <ProjectDetails
                      isEmbed
                      isLoading={false}
                      project={this.state.selectedProject}
                      projectHandlers={projectHandlers}
                    />
                  )
                }
              </ProjectActionsWrapper>
            )}
          </DetailsModal>
          {optimizeForTouch && !!selectedProject && !mobileModalOpen && (
            <div>
              <div
                style={{
                  background: this.getProjectColor(selectedProject),
                  position: 'fixed',
                  bottom: 0,
                  width: '100%',
                  padding: '18px',
                  // height: '60px',
                  color: '#000',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={this.handleMobileModalOpen}
              >
                Open project details
              </div>
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

export default withClientType(withDate(withStorage(withWorkspace(GanttPage))));
