import React, { useCallback, useEffect, useState } from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonRouterLink,
  IonToast,
} from '@ionic/react';
import { Routes } from '../lib/routes';
import { PurchasesOffering } from '@ionic-native/purchases';
import {
  getAvailablePackages,
  getPurchaserInfo,
  nativeRegisterSubscription,
  shouldUseStripe,
  subscribeNative,
} from '../lib/SubscriptionHelper';
import { logInfo, logWarn } from '../lib/logger';
import { AuthError } from '../lib/Errors';
import { StripePriceType } from '@todo/common';
import { useAuthUser } from '../helpers/AuthUserProvider';
import { useSubscriptionStatus } from '../helpers/SubscriptionStatusProvider';
import RestorePurchase from './RestorePurchase';

function renderButton(
  offeringType: StripePriceType,
  offering: PurchasesOffering,
  useStripe: boolean,
  onClick: (offeringType: string | undefined) => void
) {
  let copy = '';
  let intro = undefined;
  switch (offeringType) {
    case 'monthly':
      copy = `Subscribe monthly for ${offering[offeringType]?.product.price_string}/mo`;
      break;
    case 'annual':
      copy = `Subscribe annually for ${offering[offeringType]?.product.price_string}/yr`;
      break;
  }
  if (useStripe) {
    return (
      <IonRouterLink
        style={{ margin: 5 }}
        routerLink={`${Routes.purchase}?product=${offering[offeringType]?.product.identifier}`}
        routerDirection={'root'}
      >
        <IonButton color="success">{copy}</IonButton>
      </IonRouterLink>
    );
  } else {
    try {
      const itroUnits =
        offering[offeringType]?.product.intro_price_period_number_of_units;
      if (itroUnits != null && itroUnits > 0) {
        let unitTypeStr;
        const unitsStr = itroUnits > 1 ? ` ${itroUnits}` : '';
        switch (offering[offeringType]?.product.intro_price_period_unit) {
          case 'DAY':
            unitTypeStr = itroUnits > 1 ? 'days' : 'day';
            break;
          case 'WEEK':
            unitTypeStr = itroUnits > 1 ? 'weeks' : 'week';
            break;
          case 'MONTH':
            unitTypeStr = itroUnits > 1 ? 'months' : 'month';
            break;
          case 'YEAR':
            unitTypeStr = itroUnits > 1 ? 'years' : 'year';
            break;
          default: {
            throw new Error('Not implemented yet: undefined case');
          }
        }

        intro = `Free for the first${unitsStr} ${unitTypeStr}`;
      }
    } catch (e) {
      logWarn([], 'error when getting into on native', { e });
    }

    return (
      <IonButton
        onClick={() => onClick(offering[offeringType]?.product.identifier)}
        color="success"
        className="subscription-button"
      >
        <div>
          <span className="subscription-button-main">{copy}</span>
          {intro && <span className="subscription-button-intro">{intro}</span>}
        </div>
      </IonButton>
    );
  }
}

interface Props {
  onUpdate: () => void;
}

const Subscribe = ({ onUpdate }: Props) => {
  const [offering, setOffering] = useState<PurchasesOffering | null>(null);
  const [useStripe, setUseStripe] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { userInfo, reloadAuthentication } = useAuthUser();
  const { saveNativeSubscription } = useSubscriptionStatus();

  useEffect(() => {
    getAvailablePackages()
      .then(setOffering)
      .catch((e) => logWarn([], e.toString()));

    const willUseStripe = shouldUseStripe();
    setUseStripe(willUseStripe);
  }, []);

  const handleNativeSub = useCallback(
    async (product: string | undefined) => {
      if (!userInfo) throw new Error('no user info...');

      const userId = userInfo.auth?.userId;
      const purchaserInfo = await getPurchaserInfo();

      // const auth = userInfo.auth;
      //
      // if (!auth) throw new Error('no auth');
      if (!product) return;

      try {
        // this.setState({ processing: true });
        logInfo([], 'purchase.subscribe');
        // reloadAuthentication(true);
        await subscribeNative({
          userId,
          productIdentifier: product,
        });
        setSuccess('Created Subscription!');
        logInfo([], 'purchase.subscribe_native.success');
        saveNativeSubscription('1');
        if (userInfo.auth) {
          await nativeRegisterSubscription({
            previousUserId: userId || purchaserInfo?.originalAppUserId,
            auth: userInfo.auth,
          });
          reloadAuthentication(true);
        }
        setTimeout(onUpdate, 1000);
        // setTimeout(this.redirectBack, 3000);
      } catch (e) {
        if (e instanceof AuthError) {
          logInfo([], 'purchase.subscribe.log_in');
          reloadAuthentication(true);
        } else {
          logWarn([], 'purchase.subscribe.error', { error: e });
          setError(e.localizedDescription || e.message);
          // setTimeout(this.redirectBack, 5000);
        }
      } finally {
        // this.setState({ processing: false });
      }
    },
    [onUpdate, reloadAuthentication, saveNativeSubscription, userInfo]
  );

  return (
    <div>
      {(!offering || useStripe === null) && <div>Loading...</div>}
      {offering && useStripe !== null && (
        <IonCard>
          <IonCardHeader>
            <IonCardSubtitle>
              {offering.monthly?.product.price_string}/mo or get 2 months free when
              paying yearly {offering.annual?.product.price_string}/yr
            </IonCardSubtitle>
            <IonCardTitle>Premium access</IonCardTitle>
          </IonCardHeader>

          <IonCardContent className="subscribe-container">
            <ul>
              <li>Create unlimited content</li>
              <li>Synchronize to up to 5 devices on mobile or computer</li>
              <li>Your data is stored encrypted</li>
              <li>Recurring tasks</li>
              <li>More features coming soon</li>
            </ul>
            {renderButton('monthly', offering, useStripe, handleNativeSub)}
            {renderButton('annual', offering, useStripe, handleNativeSub)}
          </IonCardContent>
        </IonCard>
      )}
      {!useStripe && <RestorePurchase />}
      <IonToast
        isOpen={Boolean(error)}
        onDidDismiss={() => setError(null)}
        message={error || ''}
        position="top"
        color="danger"
        duration={5000}
      />
      <IonToast
        isOpen={Boolean(success)}
        onDidDismiss={() => setSuccess(null)}
        message={success || ''}
        position="top"
        color="success"
        duration={5000}
      />
    </div>
  );
};
export default Subscribe;
