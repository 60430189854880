import { ItemType } from '@todo/common';

// const taskFields = `
//   id
//   userId
//   completedAt
//   completionHistory
//   contextId
//   createdAt
//   dateDue
//   dateScheduled
//   energy
//   lastUpdated
//   name
//   positionCalendar
//   positionProject
//   positionStatus
//   priorityValues
//   projectId
//   recurringOptions
//   status
//   tags
//   time
//   serverUpdatedAt
//   deleted
// `;
//
// const projectFields = `
//   id
//   userId
//   name
//   contextId
//   status
//   dateDue
//   priorityValues
//   positionStatus
//   positionCalendar
//   createdAt
//   lastUpdated
//   serverUpdatedAt
//   deleted
// `;
//
// const tagFields = `
//   id
//   userId
//   contextId
//   name
//   color
//   icon
//   parentId
//   position
//   createdAt
//   lastUpdated
//   serverUpdatedAt
//   deleted
// `;
//
// const blockFields = `
//   id
//   userId
//   type
//   content
//   completed
//   indent
//   bold
//   color
//   contextId
//   taskId
//   projectId
//   tagId
//   position
//   createdAt
//   lastUpdated
//   serverUpdatedAt
//   deleted
// `;
//
// const contextFields = `
//   id
//   userId
//   name
//   createdAt
//   lastUpdated
//   position
//   serverUpdatedAt
//   deleted
// `;
//
// const achievementFields = `
//   id
//   type
//   contextId
//   taskId
//   projectId
//   createdAt
//   lastUpdated
//   position
//   serverUpdatedAt
//   deleted
// `;

// export interface Fields {
//   manyQueryName: string;
//   fields: string;
//   inputName: string;
// }

interface CollectionsConfig {
  name: ItemType;
  // fields?: Fields;
  sync: boolean;
}

// [note 02/2021]: these fields are not really needed, since GQL server is fake
// theoretically can pass empty fields, and auto generate input name or query name

// this is an array sorted by priority of sync, like Contexts should be synced
// before, and it goes first
const collectionsConfig: CollectionsConfig[] = [
  {
    name: ItemType.settings,
    sync: false,
  },
  {
    name: ItemType.context,
    sync: true,
    // fields: {
    //   manyQueryName: 'contexts',
    //   inputName: 'ContextCreateInput',
    //   fields: contextFields,
    // },
  },
  {
    name: ItemType.project,
    sync: true,
    // fields: {
    //   manyQueryName: 'projects',
    //   inputName: 'ProjectCreateInput',
    //   fields: projectFields,
    // },
  },
  {
    name: ItemType.tag,
    sync: true,
    // fields: {
    //   manyQueryName: 'tags',
    //   inputName: 'TagCreateInput',
    //   fields: tagFields,
    // },
  },
  {
    name: ItemType.task,
    sync: true,
    // fields: {
    //   manyQueryName: 'tasks',
    //   inputName: 'TaskCreateInput',
    //   fields: taskFields,
    // },
  },
  {
    name: ItemType.block,
    sync: true,
    // fields: {
    //   manyQueryName: 'blocks',
    //   inputName: 'BlockCreateInput',
    //   fields: blockFields,
    // },
  },
  {
    name: ItemType.achievement,
    sync: true,
    // fields: {
    //   manyQueryName: 'achievements',
    //   inputName: 'AchievementCreateInput',
    //   fields: achievementFields,
    // },
  },
];

export default collectionsConfig;
