import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { OptionType } from '../../types/CoreTypes';
import SingleSelect from '../Common/SingleSelect';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import { ContextType } from '@todo/common';

interface BaseProps {
  show: boolean;
  contextId: string | undefined;
  onClose: () => void;
  onSave: (context: ContextType) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

const EditContextModal = ({
  onClose,
  onSave,
  contextId,
  show,
  forwardRef,
}: Props) => {
  const [allContextsOptions, setAllContextsOptions] = useState<
    OptionType[] | undefined
  >(undefined);
  const [allContexts, setAllContexts] = useState<ContextType[] | undefined>(
    undefined
  );
  const [selectedContextId, setSelectedContextId] = useState<string | undefined>(
    undefined
  );

  const {
    repos: { contextRepo },
  } = useStorage();
  const { context } = useWorkspace();

  useEffect(() => {
    const sub = contextRepo.getAll$().subscribe((contexts) => {
      setAllContexts(contexts);
      setAllContextsOptions(
        contexts
          .sort((a, b) => {
            if (a.position === b.position) {
              return 0;
            } else {
              return (a.position || 0) < (b.position || 0) ? -1 : 1;
            }
          })
          .map((context) => ({
            value: context.id,
            label: context.name,
          }))
      );
    });
    return () => sub.unsubscribe();
  }, [context.id, contextRepo]);

  useEffect(() => {
    setSelectedContextId(contextId);
  }, [contextId, show]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedContextId(undefined);
  }, [onClose]);

  const handleSave = useCallback(() => {
    const context = allContexts?.find((c) => c.id === selectedContextId);
    if (context) {
      onSave(context);
      handleClose();
    }
  }, [allContexts, handleClose, onSave, selectedContextId]);

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title="Choose a context"
    >
      <SingleSelect
        ref={forwardRef}
        value={selectedContextId}
        placeholder="Context"
        options={allContextsOptions || []}
        onChange={setSelectedContextId}
      />
    </PropertyEditModalWrapper>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <EditContextModal forwardRef={ref} {...props} />
));
