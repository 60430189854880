import React from 'react';
import { IonIcon } from '@ionic/react';
import { genericStyles } from '../../helpers/styles';
import { create } from 'ionicons/icons';

interface Props {
  duration?: number;
  onClick?: any;
}

const ProjectDuration = ({ duration, onClick }: Props) => {
  const durationValue = duration ? `${duration}d` : 'not set';
  return (
    <span
      className={onClick ? 'clickable-control' : ''}
      style={genericStyles.propertyClickable}
      onClick={onClick}
    >
      {onClick && (
        <IonIcon color="medium" style={genericStyles.icon} icon={create} />
      )}
      <span style={{ ...(duration ? {} : genericStyles.grayOutColor) }}>
        {durationValue}
      </span>
    </span>
  );
};

export default ProjectDuration;
