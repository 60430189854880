import React from 'react';
import { getEnvVarOrDie } from '../lib/EnvManager';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeFormInner from './StripeFormInner';
import { getStripePackage } from '../lib/SubscriptionHelper';
import { Capacitor } from '@capacitor/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const stripePromise = Capacitor.isNative
  ? null
  : loadStripe(getEnvVarOrDie('REACT_APP_STRIPE_API_KEY'));

interface Props {
  packageId: string;
  onSuccess: (
    stripeToken: string,
    handleNextAction: (secret: string) => Promise<string>
  ) => Promise<void>;
}

const StripeForm = (props: Props) => {
  const pkg = getStripePackage(props.packageId);
  return (
    <Elements stripe={stripePromise}>
      <StripeFormInner pkg={pkg} onSuccess={props.onSuccess} />
    </Elements>
  );
};

export default StripeForm;
