// eslint-disable-next-line import/named
// import * as CapacitorSQLPlugin from 'capacitor-data-storage-sqlite';
// import { CapacitorDataStorageSqlitePlugin } from 'capacitor-data-storage-sqlite';
// import { Plugins } from '@capacitor/core';
import TaskRepo from '../lib/TaskRepo';
import ProjectRepo from '../lib/ProjectRepo';
import ContextRepo from '../lib/ContextRepo';
import LocalSettingsRepo from '../lib/LocalSettingsRepo';
import ActionRepo from '../lib/ActionRepo';
import PouchDAO from '../lib/data/PouchDAO';
import { Model, ModelTypeConstraints } from '../lib/data/ModelTooling';
import {
  AchievementModel,
  BlockModel,
  ContextModel,
  ProjectModel,
  TagModel,
  TaskModel,
} from '../lib/data/Models';
import { BaseRepo } from '../lib/BaseRepo';
import { CountersChangeEvent, ItemEditEvent, Type } from '../lib/data/DaoTypes';
// eslint-disable-next-line import/named
import {
  CollectionsOfDatabase,
  RxChangeEvent,
  RxCollection,
  RxDatabase,
} from 'rxdb/plugins/core';
import { MyDatabaseCollections, MyDB } from '../types/CoreTypes';
import { Subject, Subscription } from 'rxjs';
import BlockRepo from '../lib/BlockRepo';
import TagRepo from '../lib/TagRepo';
import AchievementRepo from '../lib/AchievementRepo';
import { AchievementsProcessor } from '../lib/achievements';
import { logWarn } from '../lib/logger';
import { nanoid } from 'nanoid';

// const { CapacitorDataStorageSqlite, Device } = Plugins;

export interface DaoCatalog {
  getDaoByModel<K extends string, T extends ModelTypeConstraints>(
    model: Model<T, K>
  ): PouchDAO<T, K>;
}

class DaoCatalogImpl implements DaoCatalog {
  private map: Map<Model<any>, PouchDAO<any, any>> = new Map();

  getDaoByModel<Form extends Record<any, any>>(
    model: Model<Type<Form>>
  ): PouchDAO<Form, any> {
    const dao = this.map.get(model);
    if (!dao) {
      throw new Error(`Model ${model} doesn't have DAO in DaoCatalog!`);
    }
    return dao;
  }

  setDao<Form extends Record<any, any>>(
    model: Model<Type<Form>>,
    dao: PouchDAO<Form, any>
  ) {
    this.map.set(model, dao);
  }
}

// PLACE TO CHANGE 0
export interface Repos {
  taskRepo: TaskRepo;
  projectRepo: ProjectRepo;
  // goalRepo: GoalRepo;
  contextRepo: ContextRepo;
  actionRepo: ActionRepo;
  blockRepo: BlockRepo;
  tagRepo: TagRepo;
  achievementRepo: AchievementRepo;
}

export interface StorageDeps {
  repos: Repos;
  localSettingsRepo: LocalSettingsRepo;
  requestDbIdlePromise: (fn: (arg: any) => void) => void;
  subscribeForDbChanges: (fn: (changeEvent: RxChangeEvent) => void) => Subscription;
  eraseAll: () => Promise<void>;
  _techDebt_localBlockDb: RxCollection;
  _techDebt_daoCatalog: DaoCatalog;
  _techDebt_localDb: MyDB;
  achievementProcessor: AchievementsProcessor;
  countersChangeSubject: Subject<CountersChangeEvent>;
}

export async function initStorage(
  localSettingsRepo: LocalSettingsRepo,
  localDb: RxDatabase<MyDatabaseCollections>
): Promise<StorageDeps> {
  // const storage = StorageService.getInstance(storagePlugin);
  // const taskStore = new KeyValueStoreAdapter<TaskType>(storage, Types.TASK_KEY);
  // const projectStore = new KeyValueStoreAdapter<ProjectType>(
  //   storage,
  //   Types.PROJECT_KEY
  // );
  // const goalStore = new KeyValueStoreAdapter<GoalType>(storage, Types.GOAL_KEY);
  // const contextStore = new KeyValueStoreAdapter<ContextType>(
  //   storage,
  //   Types.CONTEXT_KEY
  // );

  // const storesMap: Map<EntityType, KeyValueStoreAdapter<any>> = new Map();
  //
  // // PLACE TO CHANGE 0
  // storesMap.set(Types.TASK_KEY, taskStore);
  // storesMap.set(Types.PROJECT_KEY, projectStore);
  // storesMap.set(Types.CONTEXT_KEY, contextStore);
  // storesMap.set(Types.GOAL_KEY, goalStore);
  // const importManager = new ImportManager(storesMap, storage);

  // const localSettingsRepo = new LocalSettingsRepo(storage);

  // const remoteSyncManager: RemoteSyncManager = new RemoteSyncManager(
  //   new DropboxRemoteStoreManager(localSettingsRepo, localSettingsRepo),
  //   importManager,
  //   new RevisionService(localSettingsRepo),
  //   localSettingsRepo,
  //   localSettingsRepo
  // );

  const deviceId = await localSettingsRepo.getDeviceId();
  if (!deviceId) {
    const newDeviceId = nanoid();
    await localSettingsRepo.saveDeviceId(newDeviceId);
  }

  // todo use it to get sqlite plugin?
  // const storagePlugin = await getStoragePlugin();

  const daoCatalog = new DaoCatalogImpl();

  const itemEditSubject = new Subject<ItemEditEvent>();
  const countersChangeSubject = new Subject<CountersChangeEvent>();

  const taskRepo = await setUpStorage(
    daoCatalog,
    localDb,
    TaskModel,
    TaskRepo,
    itemEditSubject
  );
  const projectRepo = await setUpStorage(
    daoCatalog,
    localDb,
    ProjectModel,
    ProjectRepo,
    itemEditSubject
  );
  // const goalRepo = await setUpStorage(daoCatalog, localBlockCollection, GoalModel, GoalRepo);
  const contextRepo = await setUpStorage(
    daoCatalog,
    localDb,
    ContextModel,
    ContextRepo,
    itemEditSubject
  );
  const blockRepo = await setUpStorage(
    daoCatalog,
    localDb,
    BlockModel,
    BlockRepo,
    itemEditSubject
  );
  const achievementRepo = await setUpStorage(
    daoCatalog,
    localDb,
    AchievementModel,
    AchievementRepo,
    itemEditSubject
  );
  const tagRepo = await setUpStorage(
    daoCatalog,
    localDb,
    TagModel,
    TagRepo,
    itemEditSubject
  );
  const actionRepo = makeActionRepo();

  // PLACE TO CHANGE 0
  const repos = {
    taskRepo,
    projectRepo,
    // goalRepo,
    contextRepo,
    actionRepo,
    blockRepo,
    tagRepo,
    achievementRepo,
  };

  const achievementProcessor = AchievementsProcessor.getInstance(
    repos,
    itemEditSubject,
    countersChangeSubject
  );

  return {
    repos,
    localSettingsRepo: localSettingsRepo,
    requestDbIdlePromise: (fn: (arg: any) => void) =>
      localDb.requestIdlePromise().then(fn),
    subscribeForDbChanges: (fn: (changeEvent: RxChangeEvent) => void) =>
      localDb.$.subscribe(fn),
    eraseAll: async () => {
      for (const collection of Object.values(
        localDb.collections as CollectionsOfDatabase
      )) {
        const name = collection.name;
        try {
          await collection.remove();
        } catch (error) {
          logWarn([], `failure in manual removing of collection ${name}`, { error });
        }
      }
      try {
        await localDb.remove();
      } catch (error) {
        logWarn([], 'failure in manual removing of localDb', { error });
      }
      try {
        // @ts-ignore
        await window.indexedDB.databases().then((r) => {
          for (let i = 0; i < r.length; i++)
            window.indexedDB.deleteDatabase(r[i].name);
        });
      } catch (error) {
        logWarn([], 'failure in manual removing of DBs', { error });
      }
      localStorage.clear();
    },
    // @ts-ignore
    _techDebt_localBlockDb: blockRepo._techDebt_dao?._techDebt_collection,
    // _techDebt_userId: userId,
    _techDebt_daoCatalog: daoCatalog,
    _techDebt_localDb: localDb,
    achievementProcessor,
    countersChangeSubject,
  };
}

const setUpStorage = async <
  Form extends Record<any, any>,
  C extends BaseRepo<Form, any>
>(
  daoCatalog: DaoCatalogImpl,
  db: MyDB,
  model: Model<Type<Form>>,
  klass: new (dao: PouchDAO<Form, any>) => C,
  itemEditSubject: Subject<ItemEditEvent>
): Promise<C> => {
  // @ts-ignore
  const existingCollection = db[model.type] as RxCollection<Type<Form>>;
  const collection = existingCollection
    ? existingCollection
    : await model.initCollection(db);
  const dao = new PouchDAO(daoCatalog, model, collection, itemEditSubject);
  daoCatalog.setDao(model, dao);
  return new klass(dao);
};

const makeActionRepo = (): ActionRepo => {
  return new ActionRepo();
};

// async function getStoragePlugin(): Promise<CapacitorDataStorageSqlitePlugin> {
//   const info = await Device.getInfo();
//
//   if (info.platform === 'ios' || info.platform === 'android') {
//     // @ts-ignore
//     return CapacitorDataStorageSqlite;
//   } else {
//     return CapacitorSQLPlugin.CapacitorDataStorageSqlite;
//   }
// }
