import React from 'react';
import { RouteComponentProps } from 'react-router';
import Projects from '../components/Projects/Projects';
import { IonPage } from '@ionic/react';

const ProjectsPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <IonPage>
      <Projects />
    </IonPage>
  );
};

export default ProjectsPage;
