import React, { useCallback } from 'react';
import { TagCreateFormFillable, TagType } from '../../types/CoreTypes';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';

interface Props {
  children: (api: TagEditAPI) => React.ReactNode;
}

export interface TagEditAPI {
  updateTag: (
    tagData: Partial<TagType>,
    selectedTag: TagType | null
  ) => Promise<void>;
  deleteTag: (id: string) => Promise<void>;
  createTag: (args: TagCreateFormFillable) => Promise<string>;
}

const TagEditWrapper: React.FunctionComponent<Props> = ({ children }: Props) => {
  const {
    repos: { tagRepo },
  } = useStorage();
  const { context } = useWorkspace();

  const updateTag = useCallback(
    (tagData: Partial<TagType>, selectedTag: TagType | null) => {
      if (!selectedTag) return Promise.resolve();
      return tagRepo.edit({
        id: selectedTag.id,
        ...tagData,
      });
    },
    [tagRepo]
  );

  const deleteTag = useCallback(
    (id: string) => {
      return tagRepo.delete(id);
    },
    [tagRepo]
  );

  const createTag = useCallback(
    (args: TagCreateFormFillable) =>
      tagRepo.create({
        ...args,
        contextId: context.id,
      }),
    [tagRepo, context.id]
  );

  return (
    <>
      {children({
        updateTag,
        deleteTag,
        createTag,
      })}
    </>
  );
};

export default TagEditWrapper;
