import {
  add,
  airplane,
  alarm,
  albums,
  alert,
  americanFootball,
  analytics,
  aperture,
  apps,
  archive,
  arrowBack,
  arrowDown,
  arrowForward,
  arrowRedo,
  arrowUndo,
  arrowUp,
  at,
  attach,
  backspace,
  bandage,
  barbell,
  barChart,
  barcode,
  baseball,
  basket,
  basketball,
  batteryCharging,
  batteryDead,
  batteryFull,
  batteryHalf,
  beaker,
  bed,
  beer,
  bicycle,
  bluetooth,
  boat,
  body,
  bonfire,
  book,
  bookmark,
  bookmarks,
  briefcase,
  browsers,
  brush,
  bug,
  build,
  bulb,
  bus,
  business,
  cafe,
  calculator,
  calendar,
  call,
  camera,
  cameraReverse,
  car,
  card,
  caretBack,
  caretDown,
  caretForward,
  caretUp,
  carSport,
  cart,
  cash,
  cellular,
  chatbox,
  chatboxEllipses,
  chatbubble,
  chatbubbleEllipses,
  chatbubbles,
  checkbox,
  checkmark,
  checkmarkDone,
  chevronBack,
  chevronDown,
  chevronForward,
  chevronUp,
  clipboard,
  close,
  cloud,
  cloudDone,
  cloudDownload,
  cloudOffline,
  cloudUpload,
  cloudy,
  cloudyNight,
  code,
  codeDownload,
  codeSlash,
  codeWorking,
  cog,
  colorFill,
  colorFilter,
  colorPalette,
  colorWand,
  compass,
  construct,
  contract,
  contrast,
  copy,
  create,
  crop,
  cube,
  cut,
  desktop,
  disc,
  document,
  documentAttach,
  documents,
  documentText,
  download,
  duplicate,
  ear,
  earth,
  easel,
  egg,
  ellipse,
  ellipsisHorizontal,
  ellipsisVertical,
  enter,
  exit,
  expand,
  eye,
  eyedrop,
  eyeOff,
  fastFood,
  female,
  fileTray,
  fileTrayFull,
  fileTrayStacked,
  film,
  filter,
  fingerPrint,
  fitness,
  flag,
  flame,
  flash,
  flashlight,
  flashOff,
  flask,
  flower,
  folder,
  folderOpen,
  football,
  funnel,
  gameController,
  gift,
  gitBranch,
  gitCommit,
  gitCompare,
  gitMerge,
  gitNetwork,
  gitPullRequest,
  glasses,
  globe,
  golf,
  grid,
  hammer,
  handLeft,
  handRight,
  happy,
  hardwareChip,
  headset,
  heart,
  heartDislike,
  heartHalf,
  help,
  helpBuoy,
  home,
  hourglass,
  iceCream,
  image,
  images,
  infinite,
  information,
  journal,
  key,
  keypad,
  language,
  laptop,
  layers,
  leaf,
  library,
  link,
  list,
  locate,
  location,
  lockClosed,
  lockOpen,
  logIn,
  logOut,
  magnet,
  mail,
  mailOpen,
  mailUnread,
  male,
  maleFemale,
  man,
  map,
  medal,
  medical,
  medkit,
  megaphone,
  menu,
  mic,
  micOff,
  moon,
  move,
  musicalNote,
  musicalNotes,
  navigate,
  newspaper,
  notifications,
  notificationsOff,
  nuclear,
  nutrition,
  open,
  options,
  paperPlane,
  partlySunny,
  pause,
  paw,
  pencil,
  people,
  person,
  personAdd,
  personRemove,
  phoneLandscape,
  phonePortrait,
  pieChart,
  pin,
  pint,
  pizza,
  planet,
  play,
  playBack,
  playForward,
  playSkipBack,
  playSkipForward,
  podium,
  power,
  pricetag,
  pricetags,
  print,
  pulse,
  push,
  qrCode,
  radio,
  radioButtonOff,
  radioButtonOn,
  rainy,
  reader,
  receipt,
  recording,
  refresh,
  reload,
  remove,
  reorderFour,
  reorderThree,
  reorderTwo,
  repeat,
  resize,
  restaurant,
  returnDownBack,
  returnDownForward,
  returnUpBack,
  returnUpForward,
  ribbon,
  rocket,
  rose,
  sad,
  save,
  scan,
  school,
  search,
  send,
  server,
  settings,
  shapes,
  share,
  shareSocial,
  shield,
  shieldCheckmark,
  shirt,
  shuffle,
  skull,
  snow,
  speedometer,
  square,
  star,
  starHalf,
  statsChart,
  stop,
  stopwatch,
  subway,
  sunny,
  swapHorizontal,
  swapVertical,
  sync,
  tabletLandscape,
  tabletPortrait,
  tennisball,
  terminal,
  text,
  thermometer,
  thumbsDown,
  thumbsUp,
  thunderstorm,
  time,
  timer,
  today,
  toggle,
  trailSign,
  train,
  transgender,
  trash,
  trashBin,
  trendingDown,
  trendingUp,
  triangle,
  trophy,
  tv,
  umbrella,
  videocam,
  volumeHigh,
  volumeLow,
  volumeMedium,
  volumeMute,
  volumeOff,
  walk,
  wallet,
  warning,
  watch,
  water,
  wifi,
  wine,
  woman,
} from 'ionicons/icons';

export const icons: { icon: string; id: string; name: string }[] = [
  { icon: add, id: 'add', name: 'add' },
  { icon: airplane, id: 'airplane', name: 'airplane' },
  { icon: alarm, id: 'alarm', name: 'alarm' },
  { icon: albums, id: 'albums', name: 'albums' },
  { icon: alert, id: 'alert', name: 'alert' },
  { icon: americanFootball, id: 'americanFootball', name: 'americanFootball' },
  { icon: analytics, id: 'analytics', name: 'analytics' },
  { icon: aperture, id: 'aperture', name: 'aperture' },
  { icon: apps, id: 'apps', name: 'apps' },
  { icon: archive, id: 'archive', name: 'archive' },
  { icon: arrowBack, id: 'arrowBack', name: 'arrowBack' },
  { icon: arrowDown, id: 'arrowDown', name: 'arrowDown' },
  { icon: arrowForward, id: 'arrowForward', name: 'arrowForward' },
  { icon: arrowRedo, id: 'arrowRedo', name: 'arrowRedo' },
  { icon: arrowUndo, id: 'arrowUndo', name: 'arrowUndo' },
  { icon: arrowUp, id: 'arrowUp', name: 'arrowUp' },
  { icon: at, id: 'at', name: 'at' },
  { icon: attach, id: 'attach', name: 'attach' },
  { icon: backspace, id: 'backspace', name: 'backspace' },
  { icon: bandage, id: 'bandage', name: 'bandage' },
  { icon: barChart, id: 'barChart', name: 'barChart' },
  { icon: barbell, id: 'barbell', name: 'barbell' },
  { icon: barcode, id: 'barcode', name: 'barcode' },
  { icon: baseball, id: 'baseball', name: 'baseball' },
  { icon: basket, id: 'basket', name: 'basket' },
  { icon: basketball, id: 'basketball', name: 'basketball' },
  { icon: batteryCharging, id: 'batteryCharging', name: 'batteryCharging' },
  { icon: batteryDead, id: 'batteryDead', name: 'batteryDead' },
  { icon: batteryFull, id: 'batteryFull', name: 'batteryFull' },
  { icon: batteryHalf, id: 'batteryHalf', name: 'batteryHalf' },
  { icon: beaker, id: 'beaker', name: 'beaker' },
  { icon: bed, id: 'bed', name: 'bed' },
  { icon: beer, id: 'beer', name: 'beer' },
  { icon: bicycle, id: 'bicycle', name: 'bicycle' },
  { icon: bluetooth, id: 'bluetooth', name: 'bluetooth' },
  { icon: boat, id: 'boat', name: 'boat' },
  { icon: body, id: 'body', name: 'body' },
  { icon: bonfire, id: 'bonfire', name: 'bonfire' },
  { icon: book, id: 'book', name: 'book' },
  { icon: bookmark, id: 'bookmark', name: 'bookmark' },
  { icon: bookmarks, id: 'bookmarks', name: 'bookmarks' },
  { icon: briefcase, id: 'briefcase', name: 'briefcase' },
  { icon: browsers, id: 'browsers', name: 'browsers' },
  { icon: brush, id: 'brush', name: 'brush' },
  { icon: bug, id: 'bug', name: 'bug' },
  { icon: build, id: 'build', name: 'build' },
  { icon: bulb, id: 'bulb', name: 'bulb' },
  { icon: bus, id: 'bus', name: 'bus' },
  { icon: business, id: 'business', name: 'business' },
  { icon: cafe, id: 'cafe', name: 'cafe' },
  { icon: calculator, id: 'calculator', name: 'calculator' },
  { icon: calendar, id: 'calendar', name: 'calendar' },
  { icon: call, id: 'call', name: 'call' },
  { icon: camera, id: 'camera', name: 'camera' },
  { icon: cameraReverse, id: 'cameraReverse', name: 'cameraReverse' },
  { icon: car, id: 'car', name: 'car' },
  { icon: carSport, id: 'carSport', name: 'carSport' },
  { icon: card, id: 'card', name: 'card' },
  { icon: caretBack, id: 'caretBack', name: 'caretBack' },
  { icon: caretDown, id: 'caretDown', name: 'caretDown' },
  { icon: caretForward, id: 'caretForward', name: 'caretForward' },
  { icon: caretUp, id: 'caretUp', name: 'caretUp' },
  { icon: cart, id: 'cart', name: 'cart' },
  { icon: cash, id: 'cash', name: 'cash' },
  { icon: cellular, id: 'cellular', name: 'cellular' },
  { icon: chatbox, id: 'chatbox', name: 'chatbox' },
  { icon: chatboxEllipses, id: 'chatboxEllipses', name: 'chatboxEllipses' },
  { icon: chatbubble, id: 'chatbubble', name: 'chatbubble' },
  { icon: chatbubbleEllipses, id: 'chatbubbleEllipses', name: 'chatbubbleEllipses' },
  { icon: chatbubbles, id: 'chatbubbles', name: 'chatbubbles' },
  { icon: checkbox, id: 'checkbox', name: 'checkbox' },
  { icon: checkmark, id: 'checkmark', name: 'checkmark' },
  { icon: checkmarkDone, id: 'checkmarkDone', name: 'checkmarkDone' },
  { icon: chevronBack, id: 'chevronBack', name: 'chevronBack' },
  { icon: chevronDown, id: 'chevronDown', name: 'chevronDown' },
  { icon: chevronForward, id: 'chevronForward', name: 'chevronForward' },
  { icon: chevronUp, id: 'chevronUp', name: 'chevronUp' },
  { icon: clipboard, id: 'clipboard', name: 'clipboard' },
  { icon: close, id: 'close', name: 'close' },
  { icon: cloud, id: 'cloud', name: 'cloud' },
  { icon: cloudDone, id: 'cloudDone', name: 'cloudDone' },
  { icon: cloudDownload, id: 'cloudDownload', name: 'cloudDownload' },
  { icon: cloudOffline, id: 'cloudOffline', name: 'cloudOffline' },
  { icon: cloudUpload, id: 'cloudUpload', name: 'cloudUpload' },
  { icon: cloudy, id: 'cloudy', name: 'cloudy' },
  { icon: cloudyNight, id: 'cloudyNight', name: 'cloudyNight' },
  { icon: code, id: 'code', name: 'code' },
  { icon: codeDownload, id: 'codeDownload', name: 'codeDownload' },
  { icon: codeSlash, id: 'codeSlash', name: 'codeSlash' },
  { icon: codeWorking, id: 'codeWorking', name: 'codeWorking' },
  { icon: cog, id: 'cog', name: 'cog' },
  { icon: colorFill, id: 'colorFill', name: 'colorFill' },
  { icon: colorFilter, id: 'colorFilter', name: 'colorFilter' },
  { icon: colorPalette, id: 'colorPalette', name: 'colorPalette' },
  { icon: colorWand, id: 'colorWand', name: 'colorWand' },
  { icon: compass, id: 'compass', name: 'compass' },
  { icon: construct, id: 'construct', name: 'construct' },
  { icon: contract, id: 'contract', name: 'contract' },
  { icon: contrast, id: 'contrast', name: 'contrast' },
  { icon: copy, id: 'copy', name: 'copy' },
  { icon: create, id: 'create', name: 'create' },
  { icon: crop, id: 'crop', name: 'crop' },
  { icon: cube, id: 'cube', name: 'cube' },
  { icon: cut, id: 'cut', name: 'cut' },
  { icon: desktop, id: 'desktop', name: 'desktop' },
  { icon: disc, id: 'disc', name: 'disc' },
  { icon: document, id: 'document', name: 'document' },
  { icon: documentAttach, id: 'documentAttach', name: 'documentAttach' },
  { icon: documentText, id: 'documentText', name: 'documentText' },
  { icon: documents, id: 'documents', name: 'documents' },
  { icon: download, id: 'download', name: 'download' },
  { icon: duplicate, id: 'duplicate', name: 'duplicate' },
  { icon: ear, id: 'ear', name: 'ear' },
  { icon: earth, id: 'earth', name: 'earth' },
  { icon: easel, id: 'easel', name: 'easel' },
  { icon: egg, id: 'egg', name: 'egg' },
  { icon: ellipse, id: 'ellipse', name: 'ellipse' },
  { icon: ellipsisHorizontal, id: 'ellipsisHorizontal', name: 'ellipsisHorizontal' },
  { icon: ellipsisVertical, id: 'ellipsisVertical', name: 'ellipsisVertical' },
  { icon: enter, id: 'enter', name: 'enter' },
  { icon: exit, id: 'exit', name: 'exit' },
  { icon: expand, id: 'expand', name: 'expand' },
  { icon: eye, id: 'eye', name: 'eye' },
  { icon: eyeOff, id: 'eyeOff', name: 'eyeOff' },
  { icon: eyedrop, id: 'eyedrop', name: 'eyedrop' },
  { icon: fastFood, id: 'fastFood', name: 'fastFood' },
  { icon: female, id: 'female', name: 'female' },
  { icon: fileTray, id: 'fileTray', name: 'fileTray' },
  { icon: fileTrayFull, id: 'fileTrayFull', name: 'fileTrayFull' },
  { icon: fileTrayStacked, id: 'fileTrayStacked', name: 'fileTrayStacked' },
  { icon: film, id: 'film', name: 'film' },
  { icon: filter, id: 'filter', name: 'filter' },
  { icon: fingerPrint, id: 'fingerPrint', name: 'fingerPrint' },
  { icon: fitness, id: 'fitness', name: 'fitness' },
  { icon: flag, id: 'flag', name: 'flag' },
  { icon: flame, id: 'flame', name: 'flame' },
  { icon: flash, id: 'flash', name: 'flash' },
  { icon: flashOff, id: 'flashOff', name: 'flashOff' },
  { icon: flashlight, id: 'flashlight', name: 'flashlight' },
  { icon: flask, id: 'flask', name: 'flask' },
  { icon: flower, id: 'flower', name: 'flower' },
  { icon: folder, id: 'folder', name: 'folder' },
  { icon: folderOpen, id: 'folderOpen', name: 'folderOpen' },
  { icon: football, id: 'football', name: 'football' },
  { icon: funnel, id: 'funnel', name: 'funnel' },
  { icon: gameController, id: 'gameController', name: 'gameController' },
  { icon: gift, id: 'gift', name: 'gift' },
  { icon: gitBranch, id: 'gitBranch', name: 'gitBranch' },
  { icon: gitCommit, id: 'gitCommit', name: 'gitCommit' },
  { icon: gitCompare, id: 'gitCompare', name: 'gitCompare' },
  { icon: gitMerge, id: 'gitMerge', name: 'gitMerge' },
  { icon: gitNetwork, id: 'gitNetwork', name: 'gitNetwork' },
  { icon: gitPullRequest, id: 'gitPullRequest', name: 'gitPullRequest' },
  { icon: glasses, id: 'glasses', name: 'glasses' },
  { icon: globe, id: 'globe', name: 'globe' },
  { icon: golf, id: 'golf', name: 'golf' },
  { icon: grid, id: 'grid', name: 'grid' },
  { icon: hammer, id: 'hammer', name: 'hammer' },
  { icon: handLeft, id: 'handLeft', name: 'handLeft' },
  { icon: handRight, id: 'handRight', name: 'handRight' },
  { icon: happy, id: 'happy', name: 'happy' },
  { icon: hardwareChip, id: 'hardwareChip', name: 'hardwareChip' },
  { icon: headset, id: 'headset', name: 'headset' },
  { icon: heart, id: 'heart', name: 'heart' },
  { icon: heartDislike, id: 'heartDislike', name: 'heartDislike' },
  { icon: heartHalf, id: 'heartHalf', name: 'heartHalf' },
  { icon: help, id: 'help', name: 'help' },
  { icon: helpBuoy, id: 'helpBuoy', name: 'helpBuoy' },
  { icon: home, id: 'home', name: 'home' },
  { icon: hourglass, id: 'hourglass', name: 'hourglass' },
  { icon: iceCream, id: 'iceCream', name: 'iceCream' },
  { icon: image, id: 'image', name: 'image' },
  { icon: images, id: 'images', name: 'images' },
  { icon: infinite, id: 'infinite', name: 'infinite' },
  { icon: information, id: 'information', name: 'information' },
  { icon: journal, id: 'journal', name: 'journal' },
  { icon: key, id: 'key', name: 'key' },
  { icon: keypad, id: 'keypad', name: 'keypad' },
  { icon: language, id: 'language', name: 'language' },
  { icon: laptop, id: 'laptop', name: 'laptop' },
  { icon: layers, id: 'layers', name: 'layers' },
  { icon: leaf, id: 'leaf', name: 'leaf' },
  { icon: library, id: 'library', name: 'library' },
  { icon: link, id: 'link', name: 'link' },
  { icon: list, id: 'list', name: 'list' },
  { icon: locate, id: 'locate', name: 'locate' },
  { icon: location, id: 'location', name: 'location' },
  { icon: lockClosed, id: 'lockClosed', name: 'lockClosed' },
  { icon: lockOpen, id: 'lockOpen', name: 'lockOpen' },
  { icon: logIn, id: 'logIn', name: 'logIn' },
  { icon: logOut, id: 'logOut', name: 'logOut' },
  { icon: magnet, id: 'magnet', name: 'magnet' },
  { icon: mail, id: 'mail', name: 'mail' },
  { icon: mailOpen, id: 'mailOpen', name: 'mailOpen' },
  { icon: mailUnread, id: 'mailUnread', name: 'mailUnread' },
  { icon: male, id: 'male', name: 'male' },
  { icon: maleFemale, id: 'maleFemale', name: 'maleFemale' },
  { icon: man, id: 'man', name: 'man' },
  { icon: map, id: 'map', name: 'map' },
  { icon: medal, id: 'medal', name: 'medal' },
  { icon: medical, id: 'medical', name: 'medical' },
  { icon: medkit, id: 'medkit', name: 'medkit' },
  { icon: megaphone, id: 'megaphone', name: 'megaphone' },
  { icon: menu, id: 'menu', name: 'menu' },
  { icon: mic, id: 'mic', name: 'mic' },
  { icon: micOff, id: 'micOff', name: 'micOff' },
  { icon: moon, id: 'moon', name: 'moon' },
  { icon: move, id: 'move', name: 'move' },
  { icon: musicalNote, id: 'musicalNote', name: 'musicalNote' },
  { icon: musicalNotes, id: 'musicalNotes', name: 'musicalNotes' },
  { icon: navigate, id: 'navigate', name: 'navigate' },
  { icon: newspaper, id: 'newspaper', name: 'newspaper' },
  { icon: notifications, id: 'notifications', name: 'notifications' },
  { icon: notificationsOff, id: 'notificationsOff', name: 'notificationsOff' },
  { icon: nuclear, id: 'nuclear', name: 'nuclear' },
  { icon: nutrition, id: 'nutrition', name: 'nutrition' },
  { icon: open, id: 'open', name: 'open' },
  { icon: options, id: 'options', name: 'options' },
  { icon: paperPlane, id: 'paperPlane', name: 'paperPlane' },
  { icon: partlySunny, id: 'partlySunny', name: 'partlySunny' },
  { icon: pause, id: 'pause', name: 'pause' },
  { icon: paw, id: 'paw', name: 'paw' },
  { icon: pencil, id: 'pencil', name: 'pencil' },
  { icon: people, id: 'people', name: 'people' },
  { icon: person, id: 'person', name: 'person' },
  { icon: personAdd, id: 'personAdd', name: 'personAdd' },
  { icon: personRemove, id: 'personRemove', name: 'personRemove' },
  { icon: phoneLandscape, id: 'phoneLandscape', name: 'phoneLandscape' },
  { icon: phonePortrait, id: 'phonePortrait', name: 'phonePortrait' },
  { icon: pieChart, id: 'pieChart', name: 'pieChart' },
  { icon: pin, id: 'pin', name: 'pin' },
  { icon: pint, id: 'pint', name: 'pint' },
  { icon: pizza, id: 'pizza', name: 'pizza' },
  { icon: planet, id: 'planet', name: 'planet' },
  { icon: play, id: 'play', name: 'play' },
  { icon: playBack, id: 'playBack', name: 'playBack' },
  { icon: playForward, id: 'playForward', name: 'playForward' },
  { icon: playSkipBack, id: 'playSkipBack', name: 'playSkipBack' },
  { icon: playSkipForward, id: 'playSkipForward', name: 'playSkipForward' },
  { icon: podium, id: 'podium', name: 'podium' },
  { icon: power, id: 'power', name: 'power' },
  { icon: pricetag, id: 'pricetag', name: 'pricetag' },
  { icon: pricetags, id: 'pricetags', name: 'pricetags' },
  { icon: print, id: 'print', name: 'print' },
  { icon: pulse, id: 'pulse', name: 'pulse' },
  { icon: push, id: 'push', name: 'push' },
  { icon: qrCode, id: 'qrCode', name: 'qrCode' },
  { icon: radio, id: 'radio', name: 'radio' },
  { icon: radioButtonOff, id: 'radioButtonOff', name: 'radioButtonOff' },
  { icon: radioButtonOn, id: 'radioButtonOn', name: 'radioButtonOn' },
  { icon: rainy, id: 'rainy', name: 'rainy' },
  { icon: reader, id: 'reader', name: 'reader' },
  { icon: receipt, id: 'receipt', name: 'receipt' },
  { icon: recording, id: 'recording', name: 'recording' },
  { icon: refresh, id: 'refresh', name: 'refresh' },
  { icon: reload, id: 'reload', name: 'reload' },
  { icon: remove, id: 'remove', name: 'remove' },
  { icon: reorderFour, id: 'reorderFour', name: 'reorderFour' },
  { icon: reorderThree, id: 'reorderThree', name: 'reorderThree' },
  { icon: reorderTwo, id: 'reorderTwo', name: 'reorderTwo' },
  { icon: repeat, id: 'repeat', name: 'repeat' },
  { icon: resize, id: 'resize', name: 'resize' },
  { icon: restaurant, id: 'restaurant', name: 'restaurant' },
  { icon: returnDownBack, id: 'returnDownBack', name: 'returnDownBack' },
  { icon: returnDownForward, id: 'returnDownForward', name: 'returnDownForward' },
  { icon: returnUpBack, id: 'returnUpBack', name: 'returnUpBack' },
  { icon: returnUpForward, id: 'returnUpForward', name: 'returnUpForward' },
  { icon: ribbon, id: 'ribbon', name: 'ribbon' },
  { icon: rocket, id: 'rocket', name: 'rocket' },
  { icon: rose, id: 'rose', name: 'rose' },
  { icon: sad, id: 'sad', name: 'sad' },
  { icon: save, id: 'save', name: 'save' },
  { icon: scan, id: 'scan', name: 'scan' },
  { icon: school, id: 'school', name: 'school' },
  { icon: search, id: 'search', name: 'search' },
  { icon: send, id: 'send', name: 'send' },
  { icon: server, id: 'server', name: 'server' },
  { icon: settings, id: 'settings', name: 'settings' },
  { icon: shapes, id: 'shapes', name: 'shapes' },
  { icon: share, id: 'share', name: 'share' },
  { icon: shareSocial, id: 'shareSocial', name: 'shareSocial' },
  { icon: shield, id: 'shield', name: 'shield' },
  { icon: shieldCheckmark, id: 'shieldCheckmark', name: 'shieldCheckmark' },
  { icon: shirt, id: 'shirt', name: 'shirt' },
  { icon: shuffle, id: 'shuffle', name: 'shuffle' },
  { icon: skull, id: 'skull', name: 'skull' },
  { icon: snow, id: 'snow', name: 'snow' },
  { icon: speedometer, id: 'speedometer', name: 'speedometer' },
  { icon: square, id: 'square', name: 'square' },
  { icon: star, id: 'star', name: 'star' },
  { icon: starHalf, id: 'starHalf', name: 'starHalf' },
  { icon: statsChart, id: 'statsChart', name: 'statsChart' },
  { icon: stop, id: 'stop', name: 'stop' },
  { icon: stopwatch, id: 'stopwatch', name: 'stopwatch' },
  { icon: subway, id: 'subway', name: 'subway' },
  { icon: sunny, id: 'sunny', name: 'sunny' },
  { icon: swapHorizontal, id: 'swapHorizontal', name: 'swapHorizontal' },
  { icon: swapVertical, id: 'swapVertical', name: 'swapVertical' },
  { icon: sync, id: 'sync', name: 'sync' },
  { icon: tabletLandscape, id: 'tabletLandscape', name: 'tabletLandscape' },
  { icon: tabletPortrait, id: 'tabletPortrait', name: 'tabletPortrait' },
  { icon: tennisball, id: 'tennisball', name: 'tennisball' },
  { icon: terminal, id: 'terminal', name: 'terminal' },
  { icon: text, id: 'text', name: 'text' },
  { icon: thermometer, id: 'thermometer', name: 'thermometer' },
  { icon: thumbsDown, id: 'thumbsDown', name: 'thumbsDown' },
  { icon: thumbsUp, id: 'thumbsUp', name: 'thumbsUp' },
  { icon: thunderstorm, id: 'thunderstorm', name: 'thunderstorm' },
  { icon: time, id: 'time', name: 'time' },
  { icon: timer, id: 'timer', name: 'timer' },
  { icon: today, id: 'today', name: 'today' },
  { icon: toggle, id: 'toggle', name: 'toggle' },
  { icon: trailSign, id: 'trailSign', name: 'trailSign' },
  { icon: train, id: 'train', name: 'train' },
  { icon: transgender, id: 'transgender', name: 'transgender' },
  { icon: trash, id: 'trash', name: 'trash' },
  { icon: trashBin, id: 'trashBin', name: 'trashBin' },
  { icon: trendingDown, id: 'trendingDown', name: 'trendingDown' },
  { icon: trendingUp, id: 'trendingUp', name: 'trendingUp' },
  { icon: triangle, id: 'triangle', name: 'triangle' },
  { icon: trophy, id: 'trophy', name: 'trophy' },
  { icon: tv, id: 'tv', name: 'tv' },
  { icon: umbrella, id: 'umbrella', name: 'umbrella' },
  { icon: videocam, id: 'videocam', name: 'videocam' },
  { icon: volumeHigh, id: 'volumeHigh', name: 'volumeHigh' },
  { icon: volumeLow, id: 'volumeLow', name: 'volumeLow' },
  { icon: volumeMedium, id: 'volumeMedium', name: 'volumeMedium' },
  { icon: volumeMute, id: 'volumeMute', name: 'volumeMute' },
  { icon: volumeOff, id: 'volumeOff', name: 'volumeOff' },
  { icon: walk, id: 'walk', name: 'walk' },
  { icon: wallet, id: 'wallet', name: 'wallet' },
  { icon: warning, id: 'warning', name: 'warning' },
  { icon: watch, id: 'watch', name: 'watch' },
  { icon: water, id: 'water', name: 'water' },
  { icon: wifi, id: 'wifi', name: 'wifi' },
  { icon: wine, id: 'wine', name: 'wine' },
  { icon: woman, id: 'woman', name: 'woman' },
];

const iconMap = new Map(icons.map((i) => [i.id, i.icon]));

export function generateRandomIcon() {
  return icons[Math.floor(Math.random() * icons.length)].id;
}

export function getIconById(id: string) {
  return iconMap.get(id) || pricetag;
}

export function getDefaultIcon() {
  return 'pricetag';
}
