import React, { useCallback } from 'react';
import ContextListView from './ContextListView';
import { ContextType } from '../../types/CoreTypes';
import QuickAdd from '../Common/QuickAdd';
import QuickAddForm from '../Common/QuickAddForm';
import { useStorage } from '../../helpers/StorageProviders';
import CommonHeader from '../Common/CommonHeader';
import { IonContent, IonTitle } from '@ionic/react';
import ListNavigation from '../Common/ListNavigation';
import WrapForCollectionBasedCallbacks from '../Common/WrapForCollectionBasedCallbacks';
import { CONTEXT_NAME_MAX_LENGTH } from '../../lib/Constants';

const COLLECTION_NAME = 'contexts';

const Contexts: React.FunctionComponent = () => {
  const {
    repos: { contextRepo },
  } = useStorage();

  const updateContext = useCallback(
    (contextData: Partial<ContextType>, selectedContext: ContextType | null) => {
      if (!selectedContext) return;
      return contextRepo.edit({
        id: selectedContext.id,
        ...contextData,
      });
    },
    [contextRepo]
  );

  const deleteContext = useCallback(
    (id: string) => {
      return contextRepo.delete(id);
    },
    [contextRepo]
  );

  return (
    <>
      <CommonHeader>
        <IonTitle>Contexts</IonTitle>
      </CommonHeader>
      <IonContent>
        <QuickAdd
          form={({ onClose, ref, onSave, isOpen }) => (
            <QuickAddForm
              maxLen={CONTEXT_NAME_MAX_LENGTH}
              placeholder="Context name"
              onClose={onClose}
              isOpen={isOpen}
              onSave={(args) =>
                contextRepo
                  .create(args)
                  // .then(loadData)
                  .then(onSave)
              }
              ref={ref}
            />
          )}
        >
          <ListNavigation collections={[COLLECTION_NAME]}>
            {(listNavigationApi) => (
              <WrapForCollectionBasedCallbacks
                setCounter={listNavigationApi.setCounter}
                collectionName={COLLECTION_NAME}
              >
                {({ setCounter }) => (
                  <ContextListView
                    selectedIdx={listNavigationApi.selectedCollectionIdx}
                    setCounter={setCounter}
                    getRef={
                      listNavigationApi?.getRef
                        ? listNavigationApi.getRef(COLLECTION_NAME)
                        : undefined
                    }
                    onChangeContext={updateContext}
                    onDeleteContext={deleteContext}
                  />
                )}
              </WrapForCollectionBasedCallbacks>
            )}
          </ListNavigation>
        </QuickAdd>
      </IonContent>
    </>
  );
};

export default Contexts;
