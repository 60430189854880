import React, { useEffect, useState } from 'react';
import Search from './Search';
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from '@ionic/react';
import FindTasksByCriteria from './FindTasksByCriteria';
import { filter, search, close } from 'ionicons/icons';

const MODE_SEARCH = 'MODE_SEARCH';
const MODE_FIND = 'MODE_FIND';

interface Props {
  isHidden: boolean;
  searchRef: React.RefObject<any>;
  onClose: () => void;
}

const SearchWrapper = ({ isHidden, onClose, searchRef }: Props) => {
  const [mode, setMode] = useState(MODE_SEARCH);

  useEffect(() => {
    setMode(MODE_SEARCH);
  }, [isHidden]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonSegment
            value={mode}
            onIonChange={(e) => e.detail.value && setMode(e.detail.value)}
          >
            <IonSegmentButton key={`segment-search`} value={MODE_SEARCH}>
              <IonIcon icon={search} />
              <IonLabel>Search</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton key={`segment-find`} value={MODE_FIND}>
              <IonIcon icon={filter} />
              <IonLabel>Find</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonButton slot="end" onClick={onClose} color="light">
            <IonIcon icon={close} />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {mode === MODE_SEARCH && (
          <Search isHidden={isHidden} ref={searchRef} onClose={onClose} />
        )}
        {mode === MODE_FIND && <FindTasksByCriteria onClose={onClose} />}
      </IonContent>
    </>
  );
};

export default SearchWrapper;
