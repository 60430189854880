import React from 'react';
import { genericStyles } from '../../helpers/styles';
import { TaskStatus } from '../../types/CoreTypes';
import { getTaskStatusLabel } from '../../helpers/CopyHelper';
import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { getTaskColorLabel, getTaskIcon } from '../../helpers/TaskHelper';

interface Props {
  status: TaskStatus;
  onClick?: any;
}

const TaskStatusInfo = ({ status, onClick }: Props) => {
  return (
    <IonChip
      onClick={onClick}
      style={{
        ...genericStyles.chip,
        ...genericStyles.iconLabel,
        ...genericStyles.propertyClickable,
      }}
      color={getTaskColorLabel(status)}
    >
      <IonIcon icon={getTaskIcon(status)} />
      <IonLabel>{getTaskStatusLabel(status)}</IonLabel>
    </IonChip>
  );
};

export default TaskStatusInfo;
