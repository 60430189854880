import React from 'react';
import { IonButton, IonFooter, IonInput, IonToolbar } from '@ionic/react';
import { PartialUpdatedRecord, TagType } from '../../types/CoreTypes';
import FormElement from '../Common/FormElement';
import { withStorage } from '../../helpers/StorageProviders';
import { Repos } from '../../helpers/InitStorage';
import { diff } from 'deep-object-diff';
import SingleSelect from '../Common/SingleSelect';
import { colors } from '../../helpers/colors';
import { icons } from '../../helpers/icons';

interface Props {
  tag: TagType;
  onSave: (tag: PartialUpdatedRecord<TagType>) => void;
  onCancel: () => void;
  repos: Repos;
}

interface State {
  tag: Partial<TagType>;
}

class EditTagForm extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      tag: props.tag,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.tag.id !== state.tag.id) {
      return {
        tag: props.tag,
      };
    }
    return null;
  }

  changeValue = (value: Partial<TagType>) => {
    this.setState({ tag: { ...this.state.tag, ...value } });
  };

  handleSave = () => {
    const differences = diff(this.props.tag, this.state.tag);
    this.props.onSave({
      id: this.props.tag.id,
      ...differences,
    });
    // do smth like reset the focus
  };

  render() {
    const { tag } = this.state;
    const { onCancel } = this.props;

    return (
      <>
        <div>
          <FormElement description="Tag name">
            <IonInput
              autocorrect="on"
              placeholder="Tag name"
              value={tag.name}
              clearInput
              onIonChange={(event: any) =>
                this.changeValue({ name: event.target.value })
              }
            />
          </FormElement>
          <FormElement description="Color">
            <SingleSelect
              value={tag.color}
              placeholder="color"
              options={colors.map((c) => ({
                color: c,
                value: c,
                label: 'color',
              }))}
              onChange={(color) => this.changeValue({ color })}
            />
          </FormElement>
          <FormElement description="Icon">
            <SingleSelect
              value={tag.icon}
              placeholder="icon"
              options={icons.map((i) => ({
                value: i.id,
                icon: i.icon,
                label: i.name,
              }))}
              onChange={(icon) => this.changeValue({ icon })}
            />
          </FormElement>
        </div>
        <IonFooter>
          <IonToolbar>
            <IonButton color="light" onClick={onCancel}>
              Cancel
            </IonButton>
            <IonButton
              color="primary"
              onClick={this.handleSave}
              style={{ float: 'right' }}
            >
              Submit
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }
}

export default withStorage(EditTagForm);
