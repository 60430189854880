import React from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { restorePurchasesNative } from '../lib/SubscriptionHelper';
import { logInfo } from '../lib/logger';

const RestorePurchase = () => {
  return (
    <div>
      <IonButton
        color="light"
        size="small"
        onClick={async () => {
          logInfo([], 'subscription.restore_purchase');
          await restorePurchasesNative();
          window.location.reload();
        }}
      >
        Restore purchase
        <IonIcon slot="end" icon={arrowForward} />
      </IonButton>
    </div>
  );
};

export default RestorePurchase;
