// import PouchDBFind from 'pouchdb-find';
// @ts-ignore
// import PouchDBDebug from 'pouchdb-debug';
// import PouchDBAuth from 'pouchdb-authentication';
// eslint-disable-next-line import/named
import { addRxPlugin, createRxDatabase } from 'rxdb/plugins/core';
import { Capacitor } from '@capacitor/core';
import pouchDbAdapterIdb from 'pouchdb-adapter-idb';
import { Auth, MyDatabaseCollections, MyDB, SyncEvent } from '../../types/CoreTypes';
import PouchAdapterMemory from 'pouchdb-adapter-memory';
// import { RxDBKeyCompressionPlugin } from 'rxdb/plugins/key-compression';
import { RxDBReplicationPlugin } from 'rxdb/plugins/replication';
// import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBNoValidatePlugin } from 'rxdb/plugins/no-validate';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
// import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
// import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
import { RxDBLocalDocumentsPlugin } from 'rxdb/plugins/local-documents';
// import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBReplicationGraphQLPlugin } from './replication-graphql';
import { Subscription } from 'rxjs';
import { debounce } from 'lodash';
import { ExtendedDbType, GraphQLReplicator } from './GraphQLReplicator';
import { logDebug } from '../logger';

// eslint-disable-next-line import/no-unresolved
import PouchAdapterCordovaSqlite from 'pouchdb-adapter-cordova-sqlite';

/**
 - https://docs.couchdb.org/en/latest/config/couch-peruser.html
 - In general pouchdb is very much setup towards having a database per user
 - filtered replicationState: can have a DB that will contain only a subset based on some rule
 - can use it for ACL or for data analysis, process etc
 - couchdb security settings: validate_doc_update
 */

// async function runWithRetry<T>(
//   fn: () => Promise<T>,
//   retriesLeft: number
// ): Promise<T> {
//   try {
//     const res = await fn();
//     return res;
//   } catch (e) {
//     if (retriesLeft === 0) {
//       console.error('CONFLICTS FAILED, GIVE UP', e);
//     }
//     console.error('CONFLICTS FAILED, retrying', e);
//     return runWithRetry(fn, retriesLeft - 1);
//   }
// }

export async function initDb(): Promise<MyDB> {
  // PouchDB.plugin(PouchDBFind);
  addRxPlugin(PouchAdapterMemory);
  // addRxPlugin(RxDBKeyCompressionPlugin);
  addRxPlugin(RxDBReplicationPlugin);
  // addRxPlugin(RxDBValidatePlugin);
  addRxPlugin(RxDBNoValidatePlugin);
  addRxPlugin(RxDBLeaderElectionPlugin);
  addRxPlugin(RxDBUpdatePlugin);
  // addRxPlugin(RxDBMigrationPlugin);
  // addRxPlugin(RxDBEncryptionPlugin);
  addRxPlugin(RxDBJsonDumpPlugin);
  addRxPlugin(RxDBLocalDocumentsPlugin);
  // PouchDB.plugin(PouchDBAuth);
  // addRxPlugin(PouchAdapterHttp);
  addRxPlugin(RxDBReplicationGraphQLPlugin);
  // addRxPlugin(RxDBDevModePlugin);
  // eslint-disable-next-line no-constant-condition
  // if (true) {
  //   PouchDB.plugin(PouchDBDebug);
  //   // PouchDB.debug.enable('*');
  //   PouchDB.debug.enable('pouchdb:find');
  // }

  let localDb;
  if (Capacitor.isNative) {
    // @ts-ignore
    addRxPlugin(PouchAdapterCordovaSqlite);
    localDb = await createRxDatabase<MyDatabaseCollections>({
      name: 'easybusy.db',
      adapter: 'cordova-sqlite',
      // password: 'myPassword', //If you want to use encrypted fields in the collections of a database, you have to set a password for it. The password must be a string with at least 12 characters.
      pouchSettings: {
        auto_compaction: true,
        skip_setup: true,
        location: 'default',
        // @ts-ignore
        androidDatabaseImplementation: 2,
      },
    });
  } else {
    addRxPlugin(pouchDbAdapterIdb);
    localDb = await createRxDatabase<MyDatabaseCollections>({
      name: 'easybusy_db',
      adapter: 'idb',
      // password: 'myPassword', //If you want to use encrypted fields in the collections of a database, you have to set a password for it. The password must be a string with at least 12 characters.
      pouchSettings: {
        auto_compaction: true,
        skip_setup: true,
      },
    });
  }

  //The version field is a number, starting with 0. When the version is greater than 0, you have to provide the migrationStrategies to create a collection with this schema.

  // todo https://github.com/rafamel/rxdb-utils
  // const task = await localDb.collection({
  //   name: ItemType.Task,
  //   schema: taskSchema,
  //   // add a key with a fn describing how to migrate to the new version
  //   migrationStrategies: {}
  // });
  //
  // await localDb.collection({
  //   name: ItemType.Project,
  //   schema: projectSchema,
  //   // add a key with a fn describing how to migrate to the new version
  //   migrationStrategies: {}
  // });
  //
  // await localDb.collection({
  //   name: ItemType.Context,
  //   schema: contextSchema,
  //   // add a key with a fn describing how to migrate to the new version
  //   migrationStrategies: {}
  // });

  // todo can use preInsert hook to populate position?

  // const localDb = new PouchDB('todo', {
  //   auto_compaction: true,
  //   skip_setup: true
  // });

  // // erase indexes
  // await localDb.getIndexes().then(async indexes => {
  //   for (const index of indexes.indexes) {
  //     if (index.ddoc) {
  //       await localDb.deleteIndex({ name: index.name, ddoc: index.ddoc });
  //     }
  //   }
  // });

  // await localDb.createIndex({
  //   index: {
  //     fields: ['type', 'status', 'contextId', 'projectId', 'taskId']
  //   }
  // });

  // $gt field should be the last in the index, and status is being used as $ne,
  // which is in-app optimized to $lt/gt
  // await localDb.createIndex({
  //   index: {
  //     fields: ['type', 'contextId', 'status']
  //   }
  // });
  // await localDb.createIndex({
  //   index: {
  //     fields: ['type', 'projectId', 'status']
  //   }
  // });
  // await localDb.createIndex({
  //   index: {
  //     fields: ['type', 'contextId', 'projectId', 'taskId']
  //   }
  // });
  // await localDb.createIndex({
  //   index: {
  //     fields: ['type', 'projectId', 'taskId']
  //   }
  // });
  // await localDb.createIndex({
  //   index: {
  //     fields: ['type', 'contextId', 'status', 'dateScheduled']
  //   }
  // });
  // await localDb.createIndex({
  //   index: {
  //     fields: ['type', 'contextId', 'status', 'dateDue']
  //   }
  // });
  // await localDb.createIndex({
  //   index: {
  //     fields: ['type', 'projectId']
  //   }
  // });
  // await localDb.createIndex({
  //   index: {
  //     fields: ['projectId']
  //   }
  // });
  // await localDb.createIndex({
  //   index: { fields: ['positionStatus', 'createdAt'] }
  // });
  // await localDb.createIndex({
  //   index: { fields: ['positionProject', 'createdAt'] }
  // });
  // await localDb.createIndex({
  //   index: { fields: ['positionCalendar', 'createdAt'] }
  // });
  // await localDb.createIndex({
  //   index: { fields: ['createdAt'] }
  // });

  // localDb
  //   .changes({
  //     since: 'now',
  //     live: true
  //   })
  //   .on('change', function(change) {
  //     logDebug([], 'local change', change);
  //     listeners.forEach(l => l());
  //   })
  //   .on('error', function(err) {
  //     console.error(err);
  //   });
  return localDb;
}

export interface Remote {
  username: string;
  password: string;
  url: string;
}
export type Remotes = { [key in keyof MyDatabaseCollections]: Remote };

// what time after last activity we stop waiting for the next activity
// before declaring the initial import done
const CHECK_INTERVAL_FIRST_MS = 10000;
const CHECK_INTERVAL_REG_MS = 200;

// how often to re-check if the import is done yet
const AFTER_INTERVAL_FIRST_MS = 4000;
const AFTER_INTERVAL_REG_MS = 10;

let replicatorRef: GraphQLReplicator | undefined;

export async function setUpLocal(localDb: MyDB): Promise<void> {
  await replicatorRef?.stop();
  replicatorRef = undefined;
  return migrate(localDb);
}

export interface SetUpSyncAttrs {
  localDb: MyDB;
  auth: Auth;
  deviceId: string;
  userId: string;
  isFirst: boolean;
  setSyncStatus: (event: SyncEvent) => void;
  reLogIn: () => void;
}

export async function setUpSync({
  localDb,
  auth,
  deviceId,
  userId,
  isFirst,
  setSyncStatus,
  reLogIn,
}: SetUpSyncAttrs): Promise<number> {
  const interval = isFirst ? CHECK_INTERVAL_FIRST_MS : CHECK_INTERVAL_REG_MS;

  //myCollection.sync({ remote: `https://${username}:${password}@remote-url.com` })
  const now = () => new Date().getTime();

  // const graphqlReplicator = new GraphQLReplicator(localDb);

  const lastActive = new Map<string, { ts: number }>();
  const subscriptions: Subscription[] = [];
  replicatorRef = new GraphQLReplicator({
    db: localDb as ExtendedDbType,
    auth,
    deviceId,
    userId,
    setSyncStatus,
    reLogIn,
    isNative: !!Capacitor.isNative,
  });
  const replicationState = await replicatorRef.start();

  // const remoteDbs: PouchDB.Database<any>[] = [];

  // for (const collection of Object.values(
  //   localDb.collections as CollectionsOfDatabase
  // )) {
  //   if (collection.name === 'settings') continue;
  //   const remote = remotes[collection.name as keyof MyDatabaseCollections];
  //   if (remote) {
  //     const { hash } = remote;
  //     // const authURL = url.replace('https://', `https://${username}:${password}@`);
  //
  //     const graphqlReplicator = new GraphQLReplicator(localDb);
  //     // todo
  //     replicationStates = await graphqlReplicator.restart(hash);
  //     // const remoteDb: PouchDB.Database = new PouchDB(authURL, {
  //     //   skip_setup: true
  //     // });
  //     //
  //     // remoteDb
  //     //   .changes({
  //     //     since: 'now',
  //     //     conflicts: true,
  //     //     include_docs: true,
  //     //     live: true,
  //     //     batch_size: 10
  //     //   })
  //     //   .on('change', async function(change) {
  //     //     if (!change.doc) return;
  //     //     const docId = change.doc._id;
  //     //     if (!change.doc._conflicts) return;
  //     //     logDebug([], 'FOUND CONFLICTS!', change.doc);
  //     //     await runWithRetry(() => merge(remoteDb, docId), 10);
  //     //     logDebug([], 'DONE WITH CONFLICTS!');
  //     //   });
  //     //
  //     // // todo
  //     // const replicationState = collection.sync({
  //     //   remote: remoteDb,
  //     //   direction: {
  //     //     pull: true,
  //     //     push: true
  //     //   },
  //     //   options: {
  //     //     live: true,
  //     //     retry: true
  //     //   }
  //     // });
  //     //
  //     // replicationStates.push([collection.name, replicationState]);
  //     // replicationState.error$.subscribe(msg =>
  //     //   console.error(collection.name, 'error$', msg)
  //     // );
  //     // replicationState.denied$.subscribe(msg =>
  //     //   console.error(collection.name, 'denied$', msg)
  //     // );
  //
  //     // replicationState.active$.subscribe(msg =>
  //     //   console.info(now(), collection.name, 'active$', msg)
  //     // );
  //     // replicationState.change$.subscribe(msg =>
  //     //   console.info(now(), collection.name, 'change$')
  //     // );
  //
  //     // remoteDbs.push(remoteDb);
  //
  //     // setTimeout(() => {
  //     //   remoteDb
  //     //     .changes({
  //     //       since: 0,
  //     //       conflicts: true,
  //     //       include_docs: true,
  //     //       live: false,
  //     //       batch_size: 1
  //     //     })
  //     //     .on('change', async function(change) {
  //     //       if (!change.doc) return;
  //     //       const docId = change.doc._id;
  //     //       if (!change.doc._conflicts) return;
  //     //       logDebug([], 'FOUND CONFLICTS!', change.doc);
  //     //       await runWithRetry(() => chooseUncompressed(remoteDb, docId), 10);
  //     //       logDebug([], 'DONE WITH CONFLICTS!');
  //     //     });
  //     // }, 5000);
  //   } else {
  //     reject('No remote found for ' + collection.name);
  //   }
  // }

  let imported = 0;
  let afterTimeout: any;
  // return await replicationState.awaitInitialReplication();

  return new Promise((resolve, reject) => {
    const checkIsCurrent = debounce(() => {
      logDebug([], 'checkIsCurrent');
      const isUpdating = Array.from(lastActive.values()).some((last) => {
        return now() - last.ts < interval;
      });
      if (!isUpdating) {
        logDebug([], 'will finalize import');
        const afterInterval =
          isFirst && imported > 100
            ? AFTER_INTERVAL_FIRST_MS
            : AFTER_INTERVAL_REG_MS;
        afterTimeout = setTimeout(() => {
          logDebug([], 'finalizing import');
          subscriptions.forEach((sub) => sub.unsubscribe());
          migrate(localDb)
            .then(() => resolve(imported))
            .catch(reject);
        }, afterInterval);
      } else {
        if (afterTimeout) {
          clearTimeout(afterTimeout);
        }
        logDebug([], 'still current...');
        checkIsCurrent();
      }
    }, interval);

    // for (const { tableName, replicationState } of replicationStates) {
    subscriptions.push(
      replicationState.recieved$.subscribe(() => {
        imported++;
        // logDebug([], ' sync change subscription!');

        // leftover from many sync threads, don't really need a map here
        lastActive.set('main', { ts: now() });
        checkIsCurrent();
      })
    );
    // subscriptions.push(
    //   replicationState.active$.subscribe(active => {
    //     if (active) {
    //       logDebug([], ' active subscription!');
    //       lastActive.set(tableName, { ts: now(), active: true });
    //       checkIsCurrent();
    //     } else {
    //       lastActive.set(tableName, {
    //         ts: lastActive.get(tableName)?.ts || now(),
    //         active: false
    //       });
    //     }
    //   })
    // );
    // }

    checkIsCurrent();
  });
}

async function migrate(localDb: MyDB): Promise<void> {
  return Promise.resolve();
  // logDebug([], 'checking migration need');
  // for (const collection of Object.values(
  //   localDb.collections as CollectionsOfDatabase
  // )) {
  //   if (collection.name === 'settings') continue;
  //   if (await collection.migrationNeeded()) {
  //     logInfo([], `${collection.name}: migration needed`);
  //     const startTime = new Date().getTime();
  //     const promise = new Promise<void>((resolve, reject) => {
  //       // pass in batch-size, how many docs will run at parallel
  //       const migrationState$ = collection.migrate(40);
  //
  //       // eslint-disable-next-line @typescript-eslint/no-empty-function
  //       migrationState$.subscribe((state) => {}, reject, resolve);
  //     });
  //     await promise;
  //     const endTime = new Date().getTime();
  //     logInfo([], `${collection.name}: migration done!`, {
  //       timeMs: endTime - startTime,
  //     });
  //   } else {
  //     logDebug([], `${collection.name}: migration NOT needed`);
  //   }
  // }
}
