import React, { forwardRef } from 'react';
import {
  ItemType,
  ProjectStatus,
  SearchResults,
  TaskStatus,
} from '../types/CoreTypes';
import { withStorage } from '../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../helpers/WorkspaceProviders';
import { debounce } from 'lodash';
import SearchResultsList from './SearchResultsList';
import { StorageDeps } from '../helpers/InitStorage';
import { RxChangeEvent } from 'rxdb/plugins/core';
import { Subscription } from 'rxjs';
import { DateProps, withDate } from '../helpers/DateProvider';
import { logDebug } from '../lib/logger';
import { sortByCreated } from '../helpers/ListHelper';
import { sortByPriority } from '../helpers/TaskHelper';

interface MinProps {
  query: string;
  onClose: () => void;
}

interface ExtProps extends MinProps {
  forwardedRef?: React.Ref<any>;
}

interface Props extends StorageDeps, WorkspaceProps, ExtProps, DateProps {}

// just for performance reasons
const SIZE = 50;

const EMPTY_RESULTS = {
  // actions: [],
  contexts: { ts: 0, data: [] },
  projects: { ts: 0, data: [] },
  tags: { ts: 0, data: [] },
  tasks: { ts: 0, data: [] },
};

interface State {
  // name: string;
  results: SearchResults;
}

class Searcher extends React.PureComponent<Props, State> {
  state: State = {
    // name: '',
    results: EMPTY_RESULTS,
  };

  sub?: Subscription;

  appendToResult<K extends keyof SearchResults>(key: K, newRes: SearchResults[K]) {
    this.setState((state: State) => {
      const results = { ...state.results };
      if (results[key].ts < newRes.ts) {
        results[key] = newRes;
        return { results };
      }
      return null;
    });
  }

  loadData = async () => {
    const ts = new Date().getTime();
    const { query } = this.props;
    const text = query.trim();
    if (!text.length) {
      this.setState({ results: EMPTY_RESULTS });
      return;
    }
    const { context, repos, dateFormatted } = this.props;
    // repos.actionRepo
    //   .getAllForFilter({ anyText: text })
    //   .then(actions => actions.slice(0, SIZE))
    //   .then(actions => {
    //     this.appendToResult({ actions });
    //   });
    repos.taskRepo
      // TODO: custom?
      .getAllForFilterWithSort({
        contextId: context.id,
        filter: {
          notStatus: TaskStatus.STATUS_DONE,
          // TODO: anytext vs name?
          anyText: text,
        },
        sortFn: sortByPriority,
        formattedDate: dateFormatted,
      })
      .then((tasks) => tasks.slice(0, SIZE))
      .then((tasks) => {
        this.appendToResult('tasks', { ts, data: tasks });
      });
    repos.projectRepo
      .getAllForFilterWithSort(
        context.id,
        {
          notStatus: ProjectStatus.STATUS_ARCHIVE,
          anyText: text,
        },
        sortByCreated,
        dateFormatted
      )
      .then((projects) => projects.slice(0, SIZE))
      .then((projects) => {
        this.appendToResult('projects', { ts, data: projects });
      });
    repos.tagRepo
      .getAllForFilterWithSort(
        context.id,
        {
          anyText: text,
        },
        sortByCreated
      )
      .then((tags) => tags.slice(0, SIZE))
      .then((tags) => {
        this.appendToResult('tags', { ts, data: tags });
      });
    repos.contextRepo
      .getAllForFilter({ anyText: text })
      .then((contexts) => contexts.slice(0, SIZE))
      .then((contexts) => {
        this.appendToResult('contexts', { ts, data: contexts });
      });
  };

  handleSearchChange = debounce(this.loadData, 200);

  handleDbChangeEvent = (evt: RxChangeEvent) => {
    if ([ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)) {
      this.handleSearchChange();
    }
  };

  mountEventListeners() {
    logDebug([], 'loading search modal event listeners...');
    this.sub = this.props.subscribeForDbChanges(this.handleDbChangeEvent);
  }

  unmountEventListeners() {
    logDebug([], 'unloading search modal event listeners...');
    this.sub?.unsubscribe();
  }

  componentDidMount(): void {
    this.mountEventListeners();

    // if (this.props.query !== '') {
    this.handleSearchChange();
    // }
  }

  componentWillUnmount() {
    this.unmountEventListeners();
    // this.unmountKeyListeners();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.query !== prevProps.query) {
      this.handleSearchChange();
    }
  }

  render() {
    const { onClose } = this.props;
    const { results } = this.state;

    return <SearchResultsList onClose={onClose} results={results} />;
  }
}

const SearcherWithDeps = withDate(withWorkspace(withStorage(Searcher)));

// eslint-disable-next-line react/display-name
export default forwardRef<any, ExtProps>((props: MinProps, ref: React.Ref<any>) => (
  <SearcherWithDeps forwardedRef={ref} {...props} />
));
