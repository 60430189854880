import React, { createContext, useContext } from 'react';
import { formatDateWithoutTime } from './RecurringHelper';
import { startOfDay } from 'date-fns';

// @ts-ignore
export const DateContext = createContext<DateProps>(undefined);

interface Props {
  children: any;
}

interface State {
  dateFormatted: string;
  dateNum: number;
  date: Date;
}

export interface DateProps {
  dateFormatted: string;
  date: Date;
}

const DATE_CHECK_INTERVAL_MS = 5 * 60 * 1000; // 5 min

export class DateProvider extends React.PureComponent<Props, State> {
  readonly state: State;

  constructor(props: Props) {
    super(props);

    this.state = DateProvider.getDateAndDateFormatted(new Date());
  }

  private interval?: number;

  checkDate = () => {
    const now = new Date();
    if (now.getDate() !== this.state.dateNum) {
      this.setState(DateProvider.getDateAndDateFormatted(now));
    }
  };

  static getDateAndDateFormatted = (date: Date) => {
    return {
      date: startOfDay(date),
      dateNum: date.getDate(),
      dateFormatted: formatDateWithoutTime(date),
    };
  };

  intervalHandler = () => {
    this.checkDate();
    this.initInterval();
  };

  initInterval = () => {
    this.interval = window.setTimeout(this.intervalHandler, DATE_CHECK_INTERVAL_MS);
  };

  componentDidMount() {
    this.initInterval();
  }

  componentWillUnmount() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  }

  // renderDebugTool() {
  //   const { dateFormatted } = this.state;
  //   return (
  //     <div
  //       style={{
  //         position: 'absolute',
  //         zIndex: 9999,
  //         background: 'red',
  //         padding: 10,
  //       }}
  //     >
  //       {dateFormatted}
  //       <input type="text" id="fakedate" />
  //       <button
  //         onClick={() => {
  //           // @ts-ignore
  //           const dateS = document.getElementById('fakedate').value;
  //           const date = new Date(dateS);
  //           this.setState(DateProvider.getDateAndDateFormatted(date));
  //         }}
  //       >
  //         set date
  //       </button>
  //     </div>
  //   );
  // }

  render() {
    const { children } = this.props;
    const { dateFormatted, date } = this.state;

    return (
      <DateContext.Provider value={{ dateFormatted, date }}>
        {/*{this.renderDebugTool()}*/}
        {children}
      </DateContext.Provider>
    );
  }
}

export const useDate: () => DateProps = () => useContext(DateContext);

export const withDate = <P extends Partial<DateProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof DateProps>> => {
  return function withDateContext(props) {
    return (
      <DateContext.Consumer>
        {(contextProps: DateProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </DateContext.Consumer>
    );
  };
};
