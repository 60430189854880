const schema = {
  keyCompression: false,
  title: 'project schema',
  version: 0,
  description: 'describes a project',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    name: {
      type: 'string',
    },
    dateDue: {
      type: 'string',
    },
    dateStart: {
      type: 'string',
    },
    dependsOnProjectId: {
      ref: 'project',
      type: 'string',
    },
    duration: {
      type: 'number',
    },
    status: {
      type: 'string',
    },
    contextId: {
      type: 'string',
    },
    positionStatus: {
      type: 'number',
    },
    positionCalendar: {
      type: 'number',
    },
    priorityValues: {
      type: 'object',
      properties: {
        preset: {
          type: 'number',
        },
        ice: {
          type: 'object',
          properties: {
            impact: {
              type: 'number',
            },
            confidence: {
              type: 'number',
            },
            ease: {
              type: 'number',
            },
          },
        },
      },
    },
    createdAt: {
      type: 'number',
      final: true,
    },
    lastUpdated: {
      type: 'number',
    },
    serverUpdatedAt: {
      type: 'number',
    },
    last_pulled_rev: {
      type: 'string',
    },
    deviceId: {
      type: 'string',
    },
    data: {
      type: 'object',
    },
    // userId: {
    //   ref: 'task',
    //   type: 'string',
    // },
  },
  required: ['id', 'name', 'status', 'contextId'],
  // encrypted: ['secret'],
  // attachments: {
  //   encrypted: true
  // },
  indexes: [
    ['contextId', 'status'],
    'status',
    ['contextId', 'dateStart'],
    ['contextId', 'dependsOnProjectId'],
  ],
};

export default schema;
