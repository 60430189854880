import { getEnvVarOrDie } from './EnvManager';
import { chain } from 'lodash';
import {
  PACKAGE_TYPE,
  PurchaserInfo,
  Purchases,
  PurchasesOffering,
  PurchasesPackage,
} from '@ionic-native/purchases';
import {
  maybeStringToMaybeDate,
  getLastEntitlementApi,
  EntitlementType,
  RevenuecatResponseWithSubscriber,
  PostSubscribeInput,
  PostSubscribeResponse,
  StripePriceType,
  Store,
} from '@todo/common';
import { Auth, UserInfo } from '../types/CoreTypes';
import { request } from 'gaxios';
import { AuthError } from './Errors';
import { logDebug, logInfo } from './logger';
import { Capacitor } from '@capacitor/core';

const accountsEndpoint = getEnvVarOrDie('REACT_APP_ACCOUNT_URL');
const syncSubStatusEndpoint = getEnvVarOrDie('REACT_APP_SYNC_SUB_STATUS_URL');
const cancelSubStripeEndpoint = getEnvVarOrDie('REACT_APP_CANCEL_SUB_STRIPE_URL');
const REVENUECAT_API_KEY = getEnvVarOrDie('REACT_APP_REVENUECAT_API_KEY');
const REVENUECAT_IS_PROD_RAW = getEnvVarOrDie('REACT_APP_REVENUECAT_IS_PROD');
const revenuecatIsProd = REVENUECAT_IS_PROD_RAW === '1';
const getRevenueCatSubscriberEndpoint = (id: string) =>
  `https://api.revenuecat.com/v1/subscribers/${id}`;

Purchases.setup(REVENUECAT_API_KEY);
// interface UserResponseType {
//   name: string;
//   Subscription: SubscriptionResponseType[];
// }
//
// export interface EnrichedUserResponseType extends UserResponseType {
//   activeSubscription: SubscriptionResponseType | null;
//   lastSubscription: SubscriptionResponseType | null;
// }
//
// type MaybeUserResponseType = UserResponseType | null;
//
// interface SubscribePostRequestType {
//   stripeToken?: string;
//   stripePriceType?: string;
// }

export async function getPurchaserInfo(): Promise<PurchaserInfo | null> {
  try {
    return await Purchases.getPurchaserInfo();
  } catch (e) {
    return null;
  }
}

function doFetch<T, V = undefined>(
  endpoint: string,
  token: string,
  method: 'GET' | 'POST',
  body?: V
): Promise<T> {
  return (
    request<T>({
      url: endpoint,
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: body ? JSON.stringify(body) : undefined,
    })
      // .then(async r => {
      //   if (r.ok) return r;
      //   let msg: string;
      //   try {
      //     const json: any = r.json();
      //     msg = json.message || json.error || json.errorMessage;
      //   } catch {
      //     msg = await r.text().then(txt => {
      //       if (!txt || txt.length === 0) {
      //         return r.statusText;
      //       }
      //       return txt;
      //     });
      //   }
      //   throw Error(msg);
      // })
      .catch((err) => {
        if (err.code === '401') {
          throw new AuthError();
        }
        throw new Error(
          err?.response?.data?.error ||
            err?.response?.data?.errors?.[0] ||
            err.message
        );
      })
      .then((r) => r.data)
  );
}

export function getLastEntitlementNative(
  purchaserInfo: PurchaserInfo
): EntitlementType | null {
  const lastEntitlement = chain(Object.values(purchaserInfo.entitlements.all))
    // in ascending order
    .sortBy((v) => v.expirationDate)
    .reverse()
    .head()
    .value();
  if (lastEntitlement) {
    return {
      entitlementId: lastEntitlement.identifier,
      store: lastEntitlement.store as Store,
      willRenew: lastEntitlement.willRenew,
      isActive: lastEntitlement.isActive,
      createdAt: new Date(lastEntitlement.originalPurchaseDate),
      expiresAt: maybeStringToMaybeDate(lastEntitlement.expirationDate),
      periodType: lastEntitlement.periodType,
      unsubscribeDetectedAt: maybeStringToMaybeDate(
        lastEntitlement.unsubscribeDetectedAt
      ),
      billingIssueDetectedAt: maybeStringToMaybeDate(
        lastEntitlement.billingIssueDetectedAt
      ),
    };
  }
  return null;
}

export async function getSubStatus(userId: string): Promise<EntitlementType | null> {
  // if (await shouldUseStripe()) {
  const res = await doFetch<RevenuecatResponseWithSubscriber>(
    getRevenueCatSubscriberEndpoint(userId),
    REVENUECAT_API_KEY,
    'GET'
  );
  if (res.subscriber) {
    return getLastEntitlementApi(res.subscriber, !revenuecatIsProd);
  }
  return null;
  // } else {
  //   const purchaserInfo = await Purchases.getPurchaserInfo();
  //   console.log('abc purchaserInfo', purchaserInfo);
  //   return getlastEntitlementNative(purchaserInfo);
  // }
}

// export async function fetchUserData(
//   token: string
// ): Promise<EnrichedUserResponseType | null> {
//   const data = await doFetchAccountsApi<MaybeUserResponseType>(token, 'GET');
//   return enrichUserData(data);
// }

// function enrichUserData(
//   user: UserResponseType | null
// ): EnrichedUserResponseType | null {
//   if (!user) {
//     return null;
//   }
//
//   const activeSubscription =
//     user.Subscription.find(s => s.expiresAt > new Date()) || null;
//   const lastSubscription = maxBy(user.Subscription, h => h.expiresAt) || null;
//
//   return {
//     ...user,
//     activeSubscription,
//     lastSubscription
//   };
// }

function postSubscribe(
  token: string,
  input?: PostSubscribeInput
): Promise<PostSubscribeResponse> {
  return doFetch<PostSubscribeResponse, PostSubscribeInput | undefined>(
    accountsEndpoint,
    token,
    'POST',
    input
  );
}

export function shouldUseStripe(): boolean {
  return !Capacitor.isNative;

  // const info = await Device.getInfo();
  //
  // // eslint-disable-next-line no-debugger
  // debugger;
  //
  // if (info.platform === 'ios' || info.platform === 'android') {
  //   // @ts-ignore
  //   return false;
  // } else {
  //   return true;
  // }
}

const stripeOffering: PurchasesOffering = {
  lifetime: null,
  identifier: '',
  serverDescription: '',
  // @ts-ignore
  availablePackages: [],
  sixMonth: null,
  threeMonth: null,
  twoMonth: null,
  weekly: null,
  monthly: {
    identifier: 'monthly',
    offeringIdentifier: 'monthly',
    packageType: PACKAGE_TYPE.MONTHLY,
    product: {
      identifier: 'monthly',
      description: '',
      title: 'Premium Monthly',
      price: 4.99,
      price_string: '$4.99',
      currency_code: '$',
      intro_price: null,
      intro_price_string: null,
      intro_price_period: null,
      intro_price_cycles: null,
      intro_price_period_unit: null,
      intro_price_period_number_of_units: null,
    },
  },
  annual: {
    identifier: 'annual',
    offeringIdentifier: 'annual',
    packageType: PACKAGE_TYPE.ANNUAL,
    product: {
      identifier: 'annual',
      description: '',
      title: 'Premium Yearly',
      price: 49.99,
      price_string: '$49.99',
      currency_code: '$',
      intro_price: null,
      intro_price_string: null,
      intro_price_period: null,
      intro_price_cycles: null,
      intro_price_period_unit: null,
      intro_price_period_number_of_units: null,
    },
  },
};

export function getStripePackage(id: string): PurchasesPackage {
  const idTransferred = id as 'annual' | 'monthly';
  const pkg = stripeOffering[idTransferred];
  if (pkg) {
    return pkg;
  }
  throw new Error(`No package found for stripe ${id}`);
}

export async function getAvailablePackages(): Promise<PurchasesOffering> {
  const willUseStripe = shouldUseStripe();
  if (willUseStripe) {
    return stripeOffering;
  } else {
    /**
     * If Offerings, products, or available packages are empty:
     * https://support.revenuecat.com/hc/en-us/articles/360041793174
     */
    // can also pass user ID later https://docs.revenuecat.com/docs/user-ids
    Purchases.setDebugLogsEnabled(true);
    // Purchases.getOfferings(
    //   offerings => {
    //     if (offerings.current !== null) {
    //       // Display current offering with offerings.current
    //     }
    //   },
    //   error => {}
    // );

    // try {
    const offerings = await Purchases.getOfferings();
    if (
      offerings.current &&
      offerings.current.availablePackages &&
      // @ts-ignore
      offerings.current.availablePackages.length !== 0
    ) {
      return offerings.current;
    }
    throw new Error('Cannot fetch offerings');
  }
  // } catch (e) {}
}

async function processPaymentNative(input: {
  productIdentifier: string;
  userId?: string;
}): Promise<string> {
  // Note: if you are using purchaseProduct to purchase Android In-app products, an optional third parameter needs to be provided when calling purchaseProduct. You can use the package system to avoid this
  // await Purchases.purchaseProduct("product_id", null, Purchases.PURCHASE_TYPE.INAPP);

  // todo If the error object is present, then the purchase failed.
  //  See our guide on Error Handling for the specific error types.
  //  https://docs.revenuecat.com/docs/errors
  if (input.userId) {
    await Purchases.identify(input.userId);
  }
  // Purchases.setAttributes({ $email: input.userEmail, $displayName: input.userName });
  return Purchases.purchaseProduct(input.productIdentifier).then(
    (r) => r.purchaserInfo.activeSubscriptions[0]
  );
}

export async function restorePurchasesNative() {
  await Purchases.restoreTransactions();
}

/**
 * Native subscription
 * - sets userID as an identifier in RevenueCat
 * - processes payment
 * - notifies backend by sending the user ID
 * - backend will verify using RevenueCat API if this userID indeed has sub
 * - backend will set entitlement in Auth0
 * - backend will return the current entitlement
 */
export async function subscribeNative(input: {
  userId?: string;
  productIdentifier: string;
}): Promise<void> {
  const currentSubStatus = input.userId
    ? await getSubStatus(input.userId)
    : undefined;

  if (currentSubStatus?.isActive) {
    throw new Error('Active subscription already exists!');
  }

  await processPaymentNative({
    productIdentifier: input.productIdentifier,
    userId: input.userId,
  });
}

/**
 * Will set name/email and set entitlement in Auth0
 */
export async function nativeRegisterSubscription(input: {
  previousUserId?: string;
  auth: Auth;
}): Promise<PostSubscribeResponse> {
  const currentSubStatus = input.previousUserId
    ? await getSubStatus(input.previousUserId)
    : undefined;
  const nativePurchaserInfo = await getPurchaserInfo();
  const lastEntitlement = nativePurchaserInfo
    ? getLastEntitlementNative(nativePurchaserInfo)
    : null;

  if (!currentSubStatus?.isActive && !lastEntitlement?.isActive) {
    throw new Error('No Active subscription found!');
  }

  await Purchases.identify(input.auth.userId);
  Purchases.setAttributes({
    $email: input.auth.userEmail,
    $displayName: input.auth.userName,
  });

  return postSubscribe(input.auth.token);
}

export async function subscribeStripe(input: {
  token: string;
  userId: string;
  productIdentifier: StripePriceType;
  stripeToken?: string;
  stripePaymentIntent?: string;
}): Promise<PostSubscribeResponse> {
  const currentSubStatus = await getSubStatus(input.userId);

  if (currentSubStatus?.isActive) {
    throw new Error('Active subscription already exists!');
  }

  if (
    (!input.stripeToken && !input.stripePaymentIntent) ||
    !input.productIdentifier
  ) {
    throw new Error('Payment token or price not found');
  }

  return postSubscribe(input.token, {
    stripeToken: input.stripeToken,
    stripePaymentIntent: input.stripePaymentIntent,
    stripePriceType: input.productIdentifier,
  });
}

export async function cancelSubStripe(auth: Auth): Promise<void> {
  await doFetch<void>(cancelSubStripeEndpoint, auth.token, 'POST');
}

export async function checkIfSubStatusIsInSyncAndRefetch(
  userInfo: UserInfo
): Promise<boolean> {
  if (!userInfo.auth?.userId) {
    logDebug([], 'user is not logged in');
    return true;
  }
  const currentSubStatus = await getSubStatus(userInfo.auth.userId);
  // if (!userInfo.auth) {
  //   throw new Error(
  //     'Checking subscription status for unauth user, should not happen!'
  //   );
  // }
  let inSync;
  if (userInfo.auth.activeEntitlement) {
    inSync = !!currentSubStatus?.isActive;
  } else {
    inSync = !currentSubStatus?.isActive;
  }

  if (!inSync) {
    logInfo([], 'sub.not_sync', {
      activeEntitlement: userInfo.auth.activeEntitlement,
      currentSubStatus: currentSubStatus?.isActive,
    });
    await doFetch<void>(syncSubStatusEndpoint, userInfo.auth.token, 'POST');
    return false;
  }
  return true;
}
