import { IonIcon, IonItem, IonLabel, IonReorder } from '@ionic/react';
import React from 'react';
import { TagType } from '../../types/CoreTypes';
import { reorderFourOutline } from 'ionicons/icons';
import { Routes } from '../../lib/routes';
import SingleTag from '../Common/SingleTag';

interface Props {
  tag: TagType;
  isSelected?: boolean;
  onAfterNavigate?: () => void;
}

const TagListElementMobile: React.FunctionComponent<Props> = ({
  tag,
  onAfterNavigate,
}: Props) => {
  const url = `/${Routes.tags}/details/${tag.id}`;
  return (
    <IonItem
      // color={isSelected ? 'light' : undefined}
      routerLink={url}
      routerDirection={'root'}
      onClick={onAfterNavigate}
    >
      <IonReorder slot="start" style={{ marginRight: 10 }}>
        <IonIcon style={{ fontSize: 25 }} color="medium" icon={reorderFourOutline} />
      </IonReorder>
      <IonLabel className="list-mobile tag-list-row table-list-row">
        <SingleTag disableLink tag={tag} />
      </IonLabel>
    </IonItem>
  );
};

export default TagListElementMobile;
