import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Next from '../pages/Next';
import TaskViewPage from '../pages/TaskViewPage';
import ProjectsPage from '../pages/ProjectsPage';
import ProjectViewPage from '../pages/ProjectViewPage';
import ContextEditPage from '../pages/ContextEditPage';
import ContextsPage from '../pages/ContextsPage';
import AgendaPage from '../pages/AgendaPage';
import ReviewPage from '../pages/ReviewPage';
import SettingsPage from '../pages/SettingsPage';
import { IonRouterOutlet } from '@ionic/react';
import Focus from '../pages/Focus';
import SomeTime from '../pages/SomeTime';
import Waiting from '../pages/Waiting';
import { HOME_URL } from '../lib/Constants';
import Inbox from '../pages/Inbox';
import TagsPage from '../pages/TagsPage';
import TagViewPage from '../pages/TagViewPage';
import TagEditPage from '../pages/TagEditPage';
import { Routes } from '../lib/routes';
import ArchivePage from '../pages/ArchivePage';
import PurchasePage from '../pages/PurchasePage';
import GanttPage from '../pages/GanttPage';
import HelpPage from '../pages/HelpPage';

export const Router = () => {
  return (
    <IonRouterOutlet id="main" animated={false}>
      <Route exact path={`/${Routes.next}`} component={Next} />
      <Route exact path={`/${Routes.waiting}`} component={Waiting} />
      <Route exact path={`/${Routes.focus}`} component={Focus} />
      <Route exact path={`/${Routes.sometime}`} component={SomeTime} />
      <Route exact path={`/${Routes.inbox}`} component={Inbox} />
      <Route exact path={`/${Routes.gantt}`} component={GanttPage} />
      <Route path={`/${Routes.tasks}/details/:id`} component={TaskViewPage} />

      <Route exact path={`/${Routes.projects}`} component={ProjectsPage} />
      <Route path={`/${Routes.projects}/details/:id`} component={ProjectViewPage} />

      <Route exact path={`/${Routes.tags}`} component={TagsPage} />
      <Route path={`/${Routes.tags}/details/:id`} component={TagViewPage} />
      <Route path={`/${Routes.tags}/edit/:id`} component={TagEditPage} />

      <Route path={`/${Routes.contexts}/edit/:id`} component={ContextEditPage} />
      <Route exact path={`/${Routes.contexts}`} component={ContextsPage} />

      <Route exact path={`/${Routes.archive}`} component={ArchivePage} />

      <Route exact path={`/${Routes.agenda}`} component={AgendaPage} />
      <Route exact path={`/${Routes.review}`} component={ReviewPage} />
      <Route exact path={`/${Routes.settings}`} component={SettingsPage} />
      <Route exact path={`/${Routes.help}`} component={HelpPage} />
      <Route exact path={`/${Routes.purchase}`} component={PurchasePage} />
      <Route exact path="/" render={() => <Redirect to={HOME_URL} />} />
    </IonRouterOutlet>
  );
};
