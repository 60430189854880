import React, { useCallback } from 'react';
import { TaskType } from '@todo/common';

interface WrapForCollectionBasedCallbacksProps {
  collectionName: string;
  children: (api: WrapForCollectionBasedCallbacksApi) => React.ReactNode;
  setCounter?: (collectionName: string, count: number) => void;
  setUniqueAndStatsFilterValues?: (
    collectionName: string,
    tasks: TaskType[]
  ) => void;
}

interface WrapForCollectionBasedCallbacksApi {
  setCounter?: (count: number) => void;
  setUniqueAndStatsFilterValues?: (values: TaskType[]) => void;
}

const WrapForCollectionBasedCallbacks = ({
  children,
  collectionName,
  setCounter,
  setUniqueAndStatsFilterValues,
}: WrapForCollectionBasedCallbacksProps) => {
  const setCounterMod = useCallback(
    (count: number) => {
      if (!setCounter) return;
      return setCounter(collectionName, count);
    },
    [collectionName, setCounter]
  );
  const setUniqueAndStatsFilterValuesMod = useCallback(
    (values: TaskType[]) => {
      if (!setUniqueAndStatsFilterValues) return;
      return setUniqueAndStatsFilterValues(collectionName, values);
    },
    [collectionName, setUniqueAndStatsFilterValues]
  );

  return (
    <>
      {children({
        setCounter: setCounter ? setCounterMod : undefined,
        setUniqueAndStatsFilterValues: setUniqueAndStatsFilterValues
          ? setUniqueAndStatsFilterValuesMod
          : undefined,
      })}
    </>
  );
};

export default WrapForCollectionBasedCallbacks;
