import React from 'react';
import { calendarOutline } from 'ionicons/icons';
import DateInfo from '../Common/DateInfo';
import { makeDateWithoutTime } from '../../helpers/RecurringHelper';

interface Props {
  dateScheduled?: string;
  currentDate: Date;
  onClick?: any;
}

const TaskDateScheduledInfo = ({ dateScheduled, onClick, currentDate }: Props) => {
  return (
    <DateInfo
      currentDate={currentDate}
      onClick={onClick}
      date={dateScheduled ? makeDateWithoutTime(dateScheduled) : undefined}
      icon={calendarOutline}
    />
  );
};

export default TaskDateScheduledInfo;
