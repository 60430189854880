import React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonIcon,
  IonPage,
  IonTitle,
} from '@ionic/react';
import CommonHeader from '../components/Common/CommonHeader';
import { getEnvVarOrDie } from '../lib/EnvManager';
import {
  chatbox,
  helpCircleOutline,
  linkOutline,
  mailOpenOutline,
  mapOutline,
} from 'ionicons/icons';
import { HelpProps, withHelp } from '../helpers/HelpProvider';

const CRISP_WEBSITE_ID = getEnvVarOrDie('REACT_APP_CRISP_WEBSITE_ID');

interface Props extends RouteComponentProps, HelpProps {}

class HelpPage extends React.Component<Props> {
  componentDidMount() {
    // @ts-ignore
    if (window.$crisp) return;

    // Include the Crisp code here, without the <script></script> tags
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;

    (function () {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      // @ts-ignore
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }

  openChat = () => {
    // @ts-ignore
    window.$crisp.push(['do', 'chat:open']);
  };

  render() {
    return (
      <IonPage>
        <CommonHeader>
          <IonTitle>Help</IonTitle>
        </CommonHeader>
        <IonContent>
          <div className="ion-padding">
            <div className="help-tiles">
              <IonCard>
                <IonCardContent>
                  <IonCardHeader>
                    <IonCardSubtitle>Getting started with EasyBusy</IonCardSubtitle>
                  </IonCardHeader>

                  <IonButton
                    color="primary"
                    onClick={() => this.props.triggerHelpPopup()}
                  >
                    <IonIcon slot="start" icon={helpCircleOutline} />
                    Explore help articles
                  </IonButton>
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardContent>
                  <IonCardHeader>
                    <IonCardSubtitle>
                      We strive to build EasyBusy with the features our users want.
                      Have a feature request? We want to hear from you!
                    </IonCardSubtitle>
                  </IonCardHeader>

                  <IonButton
                    color="primary"
                    target="_blank"
                    href="https://easybusy.convas.io/"
                  >
                    <IonIcon slot="start" icon={mailOpenOutline} />
                    Give feedback
                  </IonButton>
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardContent>
                  <IonCardHeader>
                    <IonCardSubtitle>
                      We&apos;re actively working to make the app better for you
                    </IonCardSubtitle>
                  </IonCardHeader>

                  <IonButton
                    color="primary"
                    target="_blank"
                    href="https://easybusy.convas.io/roadmap"
                  >
                    <IonIcon slot="start" icon={mapOutline} />
                    View our roadmap
                  </IonButton>
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardContent>
                  <IonCardHeader>
                    <IonCardSubtitle>
                      For more details, visit our website
                    </IonCardSubtitle>
                  </IonCardHeader>

                  <IonButton
                    color="primary"
                    target="_blank"
                    href="https://easybusydo.com"
                  >
                    <IonIcon slot="start" icon={linkOutline} />
                    easybusydo.com
                  </IonButton>
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardContent>
                  <IonCardHeader>
                    <IonCardSubtitle>If you need help</IonCardSubtitle>
                  </IonCardHeader>

                  <IonButton color="primary" onClick={this.openChat}>
                    <IonIcon slot="start" icon={chatbox} />
                    Ask us a question
                  </IonButton>
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardContent>
                  <IonButton
                    color="primary"
                    target="_blank"
                    href="https://easybusydo.com/terms-and-conditions.html"
                  >
                    <IonIcon slot="start" icon={linkOutline} />
                    Terms of use
                  </IonButton>

                  <IonButton
                    color="primary"
                    target="_blank"
                    href="https://easybusydo.com/privacy-policy.html"
                  >
                    <IonIcon slot="start" icon={linkOutline} />
                    Privacy policy
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withHelp(HelpPage);
