import React from 'react';
import { IonList } from '@ionic/react';
import { NamedHotKeyHandler } from '../types/CoreTypes';
import { withStorage } from '../helpers/StorageProviders';
import { StorageDeps } from '../helpers/InitStorage';
import { ListNavigationApi } from './Common/ListNavigationCommon';
import { isEqual } from 'lodash';
import MagicListElement from './Common/MagicListElement';

interface Props extends StorageDeps {
  listNavigationApi: ListNavigationApi;
  results: NamedHotKeyHandler[];
  onClose: () => void;
}

interface State {
  selectedIdx: number;
}

const HOTKEYS = 'hotkeys';

export const collections = [HOTKEYS];

class MagicResultsListView extends React.PureComponent<Props, State> {
  componentDidMount() {
    this.handleUpdate();
  }

  handleUpdate() {
    const {
      listNavigationApi: { setCounter },
    } = this.props;
    setCounter(HOTKEYS, this.props.results.length);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(prevProps.results, this.props.results) ||
      prevProps.listNavigationApi.setCounter !==
        this.props.listNavigationApi.setCounter
    ) {
      this.handleUpdate();
    }
  }

  render() {
    const {
      results,
      onClose,
      listNavigationApi: { getRef, selectedCollectionName, selectedCollectionIdx },
    } = this.props;

    return (
      <div>
        {results.length > 0 && (
          <>
            {/*<IonItemDivider>Actions</IonItemDivider>*/}
            <IonList onClick={onClose}>
              {results.map((handler, idx) => (
                <MagicListElement
                  ref={getRef(HOTKEYS)(idx)}
                  isSelected={
                    selectedCollectionName === HOTKEYS &&
                    idx === selectedCollectionIdx
                  }
                  key={JSON.stringify(handler)}
                  handler={handler}
                />
              ))}
            </IonList>
          </>
        )}
      </div>
    );
  }
}

export default withStorage(MagicResultsListView);
