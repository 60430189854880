import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import { IonInput } from '@ionic/react';

interface BaseProps {
  show: boolean;
  title: string;
  value: number | null;
  placeholder: string;
  onClose: () => void;
  onSave: (value: number | null) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

const EditNumberModal = ({
  onClose,
  onSave,
  value,
  show,
  placeholder,
  forwardRef,
  title,
}: Props) => {
  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);

  useEffect(() => {
    setSelectedNumber(value);
  }, [value, show]);

  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => {
  //       inputRef.current && inputRef.current.setFocus();
  //     }, 200);
  //   }
  // }, [show]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedNumber(null);
  }, [onClose]);

  const handleSave = useCallback(() => {
    onSave(selectedNumber ? parseInt(selectedNumber.toString(), 10) : null);
    handleClose();
  }, [handleClose, onSave, selectedNumber]);

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title={title}
      klass="edit-number"
    >
      <IonInput
        type="number"
        style={{ backgroundColor: 'white' }}
        id="editNumberInput"
        autocorrect="on"
        placeholder={placeholder}
        value={selectedNumber}
        clearInput
        ref={forwardRef}
        onIonChange={(event: any) => setSelectedNumber(event.target.value)}
      />
    </PropertyEditModalWrapper>
  );
};

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <EditNumberModal forwardRef={ref} {...props} />
));
