import React from 'react';
import TaskStatusList from '../components/Tasks/TaskStatusList';
import { IonContent, IonPage } from '@ionic/react';
import TaskListFilter from '../components/Tasks/TaskListFilter';
import CommonHeader from '../components/Common/CommonHeader';
import { TaskStatusKey } from '../helpers/TaskHelper';
import ListNavigation from '../components/Common/ListNavigation';

class Waiting extends React.Component {
  render() {
    return (
      <IonPage>
        <CommonHeader />
        <IonContent>
          <ListNavigation collections={[TaskStatusKey.Waiting]}>
            {(listNavigationApi) => (
              <TaskListFilter>
                {({ filter, setUniqueAndStatsFilterValues }) => (
                  <TaskStatusList
                    // defaultStatus={TaskStatus.STATUS_WAITING}
                    listNavigationApi={listNavigationApi}
                    filter={filter}
                    setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                    statuses={[TaskStatusKey.Waiting]}
                    emptyMessage="No tasks here yet. Tasks that you wait for other people are blocked will appear here"
                  />
                )}
              </TaskListFilter>
            )}
          </ListNavigation>
        </IonContent>
      </IonPage>
    );
  }
}

export default Waiting;
