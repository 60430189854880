import date_utils from './date_utils';
import { $, createSVG, ExtendedSvg } from './svg_utils';
import Bar from './bar';
import Arrow from './arrow';
import Popup from './popup';

import './gantt.scss';
import { ExcludesNull, ExcludesUndefined } from '../types/CoreTypes';

const VIEW_MODE: { [key: string]: viewMode } = {
  QUARTER_DAY: 'Quarter Day',
  HALF_DAY: 'Half Day',
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
};

export interface GanttTask {
  id: string;
  name: string;
  start: string;
  end: string | null;
  progress: number;
  dependencies: string[];
  color?: string;
  lightColor?: string;
  custom_class?: string;
}

interface GanttOptions {
  header_height: number;
  column_width: number;
  handle_width: number;
  step: number;
  view_modes: viewMode[];
  bar_height: number;
  bar_corner_radius: number;
  arrow_curve: number;
  padding: number;
  view_mode: viewMode;
  date_format: string;
  popup_trigger: string;
  showPopup: boolean;
  custom_popup_html?: string | ((task: EnrichedTask) => string);
  language: string;
  on_click?: (task: EnrichedTask) => void;
  on_blur?: () => void;
  on_date_change?: (task: EnrichedTask, start: Date, end: Date) => void;
  on_progress_change?: (task: EnrichedTask, progress: number) => void;
  on_view_change?: (mode: viewMode) => void;
  allowProgressChange: boolean;
}

export type GanttOptionsInput = Partial<GanttOptions>;

export interface EnrichedTask extends GanttTask {
  _start?: Date;
  _end?: Date;
  _index: number;
  invalid?: boolean;
}

export type viewMode =
  | 'Quarter Day'
  | 'Half Day'
  | 'Day'
  | 'Week'
  | 'Month'
  | 'Year';
type WrapperType = string | HTMLElement | SVGElement;

export default class Gantt {
  public static VIEW_MODE: { [key: string]: viewMode } = {};

  private $svg?: SVGElement;
  private $container?: Element;
  private popup_wrapper?: Element;

  private defaultViewMode: viewMode = 'Month';

  private default_options: GanttOptions = {
    showPopup: true,
    header_height: 50,
    column_width: 30,
    handle_width: 12,
    step: 24,
    view_modes: [...Object.values(VIEW_MODE)],
    bar_height: 25,
    bar_corner_radius: 3,
    arrow_curve: 5,
    padding: 18,
    view_mode: 'Day',
    date_format: 'YYYY-MM-DD',
    popup_trigger: 'click',
    // custom_popup_html: null,
    language: 'en',
    allowProgressChange: true,
  };

  public options: GanttOptions = this.default_options;
  private tasks: EnrichedTask[] = [];
  private dependency_map: Record<string, string[]> = {};
  public gantt_start?: Date | null;
  private gantt_end?: Date | null;
  private dates: Date[] = [];
  private layers: Record<string, SVGElement> = {};

  public selectedBars: Set<GanttTask> = new Set();

  public checkSelectedBars = () => {
    // console.log('gantt', this.selectedBars);
    if (this.selectedBars.size === 0) {
      // console.log('gantt blur');
      this.options.on_blur?.();
    }
  };

  constructor(wrapper: WrapperType, tasks: GanttTask[], options: GanttOptionsInput) {
    this.setup_wrapper(wrapper);
    this.setup_options(options);
    this.setup_tasks(tasks);
    // initialize with default view mode
    this.change_view_mode();
    this.bind_events();
  }

  setup_wrapper(elementInput: WrapperType) {
    let element: WrapperType | null | Element = elementInput;
    let svg_element, wrapper_element;

    // CSS Selector is passed
    if (typeof element === 'string') {
      element = document.querySelector(element);
    }

    // get the SVGElement
    if (element instanceof HTMLElement) {
      wrapper_element = element;
      svg_element = element.querySelector('svg');
    } else if (element instanceof SVGElement) {
      svg_element = element;
    } else {
      throw new TypeError(
        'Frappé Gantt only supports usage of a string CSS selector,' +
          " HTML DOM element or SVG DOM element for the 'element' parameter"
      );
    }

    // svg element
    if (!svg_element) {
      // create it
      this.$svg = createSVG('svg', {
        append_to: wrapper_element,
        class: 'gantt',
      });
    } else {
      this.$svg = svg_element;
      this.$svg.classList.add('gantt');
    }

    // wrapper element
    this.$container = document.createElement('div');
    this.$container.classList.add('gantt-container');

    const parent_element = this.$svg?.parentElement;
    parent_element?.appendChild(this.$container);
    if (this.$svg) {
      this.$container.appendChild(this.$svg);
    }

    // popup wrapper
    this.popup_wrapper = document.createElement('div');
    this.popup_wrapper.classList.add('popup-wrapper');
    this.$container.appendChild(this.popup_wrapper);
  }

  setup_options(options: GanttOptionsInput) {
    this.options = Object.assign({}, this.default_options, options);
  }

  setup_tasks(tasks: GanttTask[]) {
    // prepare tasks
    this.tasks = tasks.map((t, i) => {
      const task: EnrichedTask = t as EnrichedTask;
      // convert to Date objects
      task._start = date_utils.parse(task.start);
      task._end = date_utils.parse(task.end);

      // make task invalid if duration too large
      if (date_utils.diff(task._end, task._start, 'year') > 10) {
        task.end = null;
      }

      // cache index
      task._index = i;

      // invalid dates
      if (!task.start && !task.end) {
        const today = date_utils.today();
        task._start = today;
        task._end = date_utils.add(today, 2, 'day');
      }

      if (!task.start && task.end) {
        task._start = date_utils.add(task._end, -2, 'day');
      }

      if (task.start && !task.end) {
        task._end = date_utils.add(task._start, 2, 'day');
      }

      // if hours is not set, assume the last day is full day
      // e.g: 2018-09-09 becomes 2018-09-09 23:59:59
      const task_end_values = date_utils.get_date_values(task._end);
      if (task_end_values.slice(3).every((d) => d === 0)) {
        task._end = date_utils.add(task._end, 24, 'hour');
      }

      // invalid flag
      if (!task.start || !task.end) {
        task.invalid = true;
      }

      // dependencies
      // if (typeof task.dependencies === 'string' || !task.dependencies) {
      //   let deps: string[] = [];
      //   if (task.dependencies) {
      //     deps = task.dependencies
      //       .split(',')
      //       .map((d) => d.trim())
      //       .filter((d) => d);
      //   }
      //   task.dependencies = deps;
      // }

      // uids
      if (!task.id) {
        task.id = generate_id(task);
      }

      return task;
    });

    this.etup_dependencies();
  }

  etup_dependencies() {
    this.dependency_map = {};
    for (const t of this.tasks) {
      for (const d of t.dependencies) {
        this.dependency_map[d] = this.dependency_map[d] || [];
        this.dependency_map[d].push(t.id);
      }
    }
  }

  refresh(tasks: GanttTask[]) {
    const scroll_pos = this.$svg?.parentElement?.scrollLeft;
    this.setup_tasks(tasks);
    this.change_view_mode();
    if (scroll_pos && this.$svg?.parentElement) {
      this.$svg.parentElement.scrollLeft = scroll_pos;
    }
  }

  change_view_mode(mode = this.options.view_mode) {
    this.update_view_scale(mode);
    this.setup_dates();
    this.render();
    // fire viewmode_change event
    this.trigger_event('view_change', [mode]);
  }

  update_view_scale(view_mode: viewMode | undefined) {
    this.options.view_mode = view_mode || this.defaultViewMode;

    if (view_mode === VIEW_MODE.DAY) {
      this.options.step = 24;
      this.options.column_width = 38;
    } else if (view_mode === VIEW_MODE.HALF_DAY) {
      this.options.step = 24 / 2;
      this.options.column_width = 38;
    } else if (view_mode === VIEW_MODE.QUARTER_DAY) {
      this.options.step = 24 / 4;
      this.options.column_width = 38;
    } else if (view_mode === VIEW_MODE.WEEK) {
      this.options.step = 24 * 7;
      this.options.column_width = 140;
    } else if (view_mode === VIEW_MODE.MONTH) {
      this.options.step = 24 * 30;
      this.options.column_width = 120;
    } else if (view_mode === VIEW_MODE.YEAR) {
      this.options.step = 24 * 365;
      this.options.column_width = 120;
    }
  }

  setup_dates() {
    this.setup_gantt_dates();
    this.setup_date_values();
  }

  setup_gantt_dates() {
    this.gantt_start = this.gantt_end = null;

    for (const task of this.tasks) {
      // set global start and end date
      if (!this.gantt_start || (task._start && task._start < this.gantt_start)) {
        this.gantt_start = task._start;
      }
      if (!this.gantt_end || (task._end && task._end > this.gantt_end)) {
        this.gantt_end = task._end;
      }
    }

    this.gantt_start = date_utils.start_of(this.gantt_start, 'day');
    this.gantt_end = date_utils.start_of(this.gantt_end, 'day');

    // add date padding on both sides
    if (this.view_is([VIEW_MODE.QUARTER_DAY, VIEW_MODE.HALF_DAY])) {
      this.gantt_start = date_utils.add(this.gantt_start, -7, 'day');
      this.gantt_end = date_utils.add(this.gantt_end, 7, 'day');
    } else if (this.view_is(VIEW_MODE.MONTH)) {
      this.gantt_start = date_utils.start_of(this.gantt_start, 'year');
      this.gantt_end = date_utils.add(this.gantt_end, 1, 'year');
    } else if (this.view_is(VIEW_MODE.YEAR)) {
      this.gantt_start = date_utils.add(this.gantt_start, -2, 'year');
      this.gantt_end = date_utils.add(this.gantt_end, 2, 'year');
    } else {
      this.gantt_start = date_utils.add(this.gantt_start, -1, 'month');
      this.gantt_end = date_utils.add(this.gantt_end, 1, 'month');
    }
  }

  setup_date_values() {
    this.dates = [];
    let cur_date = null;

    while (cur_date === null || (this.gantt_end && cur_date < this.gantt_end)) {
      if (!cur_date) {
        cur_date = date_utils.clone(this.gantt_start);
      } else {
        if (this.view_is(VIEW_MODE.YEAR)) {
          cur_date = date_utils.add(cur_date, 1, 'year');
        } else if (this.view_is(VIEW_MODE.MONTH)) {
          cur_date = date_utils.add(cur_date, 1, 'month');
        } else {
          cur_date = date_utils.add(cur_date, this.options.step, 'hour');
        }
      }
      this.dates.push(cur_date);
    }
  }

  bind_events() {
    this.bind_grid_click();
    this.bind_bar_events();
  }

  render() {
    this.clear();
    this.setup_layers();
    this.make_grid();
    this.make_dates();
    this.make_bars();
    this.make_arrows();
    this.map_arrows_on_bars();
    this.set_width();
    this.set_scroll_position();
  }

  setup_layers() {
    this.layers = {};
    const layers = ['grid', 'date', 'arrow', 'progress', 'bar', 'details'];
    // make group layers
    for (const layer of layers) {
      this.layers[layer] = createSVG('g', {
        class: layer,
        append_to: this.$svg,
      });
    }
  }

  make_grid() {
    this.make_grid_background();
    this.make_grid_rows();
    this.make_grid_header();
    this.make_grid_ticks();
    this.make_grid_highlights();
  }

  make_grid_background() {
    const grid_width = this.dates.length * this.options.column_width;
    const grid_height =
      this.options.header_height +
      this.options.padding +
      (this.options.bar_height + this.options.padding) * this.tasks.length;

    createSVG('rect', {
      x: 0,
      y: 0,
      width: grid_width,
      height: grid_height,
      class: 'grid-background',
      append_to: this.layers.grid,
    });

    if (!this.$svg) throw new Error('no svg');
    $.attr(this.$svg, {
      height: grid_height + this.options.padding + 100,
      width: '100%',
    });
  }

  make_grid_rows() {
    const rows_layer = createSVG('g', { append_to: this.layers.grid });
    const lines_layer = createSVG('g', { append_to: this.layers.grid });

    const row_width = this.dates.length * this.options.column_width;
    const row_height = this.options.bar_height + this.options.padding;

    let row_y = this.options.header_height + this.options.padding / 2;

    for (const task of this.tasks) {
      createSVG('rect', {
        x: 0,
        y: row_y,
        width: row_width,
        height: row_height,
        class: 'grid-row',
        append_to: rows_layer,
      });

      createSVG('line', {
        x1: 0,
        y1: row_y + row_height,
        x2: row_width,
        y2: row_y + row_height,
        class: 'row-line',
        append_to: lines_layer,
      });

      row_y += this.options.bar_height + this.options.padding;
    }
  }

  make_grid_header() {
    const header_width = this.dates.length * this.options.column_width;
    const header_height = this.options.header_height + 10;
    createSVG('rect', {
      x: 0,
      y: 0,
      width: header_width,
      height: header_height,
      class: 'grid-header',
      append_to: this.layers.grid,
    });
  }

  make_grid_ticks() {
    let tick_x = 0;
    const tick_y = this.options.header_height + this.options.padding / 2;
    const tick_height =
      (this.options.bar_height + this.options.padding) * this.tasks.length;

    for (const date of this.dates) {
      let tick_class = 'tick';
      // thick tick for monday
      if (this.view_is(VIEW_MODE.DAY) && date.getDate() === 1) {
        tick_class += ' thick';
      }
      // thick tick for first week
      if (
        this.view_is(VIEW_MODE.WEEK) &&
        date.getDate() >= 1 &&
        date.getDate() < 8
      ) {
        tick_class += ' thick';
      }
      // thick ticks for quarters
      if (this.view_is(VIEW_MODE.MONTH) && (date.getMonth() + 1) % 3 === 0) {
        tick_class += ' thick';
      }

      createSVG('path', {
        d: `M ${tick_x} ${tick_y} v ${tick_height}`,
        class: tick_class,
        append_to: this.layers.grid,
      });

      if (this.view_is(VIEW_MODE.MONTH)) {
        tick_x +=
          (date_utils.get_days_in_month(date) * this.options.column_width) / 30;
      } else {
        tick_x += this.options.column_width;
      }
    }
  }

  private createHighlight(date: Date, className: string) {
    const x =
      (date_utils.diff(date, this.gantt_start, 'hour') / this.options.step) *
      this.options.column_width;
    const y = 0;

    const width = this.options.column_width;
    const height =
      (this.options.bar_height + this.options.padding) * this.tasks.length +
      this.options.header_height +
      this.options.padding / 2;

    createSVG('rect', {
      x,
      y,
      width,
      height,
      class: className,
      append_to: this.layers.grid,
    });
  }

  make_grid_highlights() {
    // highlight today's date
    if (this.view_is(VIEW_MODE.DAY)) {
      this.createHighlight(date_utils.today(), 'today-highlight');

      if (!this.gantt_start || !this.gantt_end) {
        return;
      }
      // highlight weekends
      let date = this.gantt_start;
      while (date <= this.gantt_end) {
        const day = date.getDay();
        if (day === 0 || day === 6) {
          this.createHighlight(date, 'weekend-highlight');
        }
        date = date_utils.add(date, 1, 'day');
      }
    }
  }

  make_dates() {
    for (const date of this.get_dates_to_draw()) {
      createSVG('text', {
        x: date.lower_x,
        y: date.lower_y,
        innerHTML: date.lower_text,
        class: 'lower-text',
        append_to: this.layers.date,
      });

      if (date.upper_text) {
        const $upper_text = createSVG('text', {
          x: date.upper_x,
          y: date.upper_y,
          innerHTML: date.upper_text,
          class: 'upper-text',
          append_to: this.layers.date,
        });

        // remove out-of-bound dates
        // @ts-ignore
        if ($upper_text.getBBox().x2 > this.layers.grid.getBBox().width) {
          $upper_text.remove();
        }
      }
    }
  }

  get_dates_to_draw() {
    let last_date: Date | null = null;
    const dates = this.dates.map((date, i) => {
      const d = this.get_date_info(date, last_date, i);
      last_date = date;
      return d;
    });
    return dates;
  }

  get_date_info(date: Date, last_date: Date | null, i: number) {
    if (!last_date) {
      last_date = date_utils.add(date, 1, 'year');
    }
    const date_text = {
      'Quarter Day_lower': date_utils.format(date, 'HH', this.options.language),
      'Half Day_lower': date_utils.format(date, 'HH', this.options.language),
      Day_lower:
        date.getDate() !== last_date.getDate()
          ? date_utils.format(date, 'D', this.options.language)
          : '',
      Week_lower:
        date.getMonth() !== last_date.getMonth()
          ? date_utils.format(date, 'D MMM', this.options.language)
          : date_utils.format(date, 'D', this.options.language),
      Month_lower: date_utils.format(date, 'MMMM', this.options.language),
      Year_lower: date_utils.format(date, 'YYYY', this.options.language),
      'Quarter Day_upper':
        date.getDate() !== last_date.getDate()
          ? date_utils.format(date, 'D MMM', this.options.language)
          : '',
      'Half Day_upper':
        date.getDate() !== last_date.getDate()
          ? date.getMonth() !== last_date.getMonth()
            ? date_utils.format(date, 'D MMM', this.options.language)
            : date_utils.format(date, 'D', this.options.language)
          : '',
      Day_upper:
        date.getMonth() !== last_date.getMonth()
          ? date_utils.format(date, 'MMMM', this.options.language)
          : '',
      Week_upper:
        date.getMonth() !== last_date.getMonth()
          ? date_utils.format(date, 'MMMM', this.options.language)
          : '',
      Month_upper:
        date.getFullYear() !== last_date.getFullYear()
          ? date_utils.format(date, 'YYYY', this.options.language)
          : '',
      Year_upper:
        date.getFullYear() !== last_date.getFullYear()
          ? date_utils.format(date, 'YYYY', this.options.language)
          : '',
    };

    const base_pos = {
      x: i * this.options.column_width,
      lower_y: this.options.header_height,
      upper_y: this.options.header_height - 25,
    };

    const x_pos = {
      'Quarter Day_lower': (this.options.column_width * 4) / 2,
      'Quarter Day_upper': 0,
      'Half Day_lower': (this.options.column_width * 2) / 2,
      'Half Day_upper': 0,
      Day_lower: this.options.column_width / 2,
      Day_upper: (this.options.column_width * 30) / 2,
      Week_lower: 0,
      Week_upper: (this.options.column_width * 4) / 2,
      Month_lower: this.options.column_width / 2,
      Month_upper: (this.options.column_width * 12) / 2,
      Year_lower: this.options.column_width / 2,
      Year_upper: (this.options.column_width * 30) / 2,
    };

    return {
      // @ts-ignore
      upper_text: date_text[`${this.options.view_mode}_upper`],
      // @ts-ignore
      lower_text: date_text[`${this.options.view_mode}_lower`],
      // @ts-ignore
      upper_x: base_pos.x + x_pos[`${this.options.view_mode}_upper`],
      upper_y: base_pos.upper_y,
      // @ts-ignore
      lower_x: base_pos.x + x_pos[`${this.options.view_mode}_lower`],
      lower_y: base_pos.lower_y,
    };
  }

  make_bars() {
    this.bars = this.tasks.map((task) => {
      const bar = new Bar(this, task);
      this.layers.bar.appendChild(bar.group);
      return bar;
    });
  }

  private bars: Bar[] = [];
  private arrows: Arrow[] = [];

  make_arrows() {
    this.arrows = [];
    for (const task of this.tasks) {
      let arrows = [];
      arrows = task.dependencies
        .map((task_id) => {
          const dependency = this.get_task(task_id);
          if (!dependency) return null;
          const arrow = new Arrow(
            this,
            this.bars[dependency._index], // from_task
            this.bars[task._index] // to_task
          );
          this.layers.arrow.appendChild(arrow.element);
          return arrow;
        })
        .filter((Boolean as any) as ExcludesNull); // filter falsy values
      this.arrows = this.arrows.concat(arrows);
    }
  }

  map_arrows_on_bars() {
    for (const bar of this.bars) {
      bar.arrows = this.arrows.filter((arrow) => {
        return (
          arrow.from_task.task.id === bar.task.id ||
          arrow.to_task.task.id === bar.task.id
        );
      });
    }
  }

  set_width() {
    if (!this.$svg) throw new Error('no svg');
    const cur_width = this.$svg.getBoundingClientRect().width;
    const actual_width = parseInt(
      this.$svg.querySelector('.grid .grid-row')?.getAttribute('width') || '0',
      10
    );
    if (cur_width < actual_width) {
      this.$svg.setAttribute('width', actual_width.toString());
    }
  }

  set_scroll_position() {
    if (!this.$svg) throw new Error('no svg');
    const parent_element = this.$svg.parentElement;
    if (!parent_element) throw new Error('no parent');

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    const hours_before_today = date_utils.diff(
      today,
      // this.get_oldest_starting_date(),
      this.gantt_start,
      'hour'
    );

    const scroll_pos =
      (hours_before_today / this.options.step) * this.options.column_width -
      this.options.column_width;

    // logDebug(['gantt'], 'scroll', {
    //   today,
    //   hours_before_today,
    //   gantt_start: this.gantt_start,
    //   scroll_pos,
    // });

    parent_element.scrollLeft = scroll_pos;
  }

  bind_grid_click() {
    if (!this.$svg) throw new Error('no svg');
    $.on(this.$svg, this.options.popup_trigger, '.grid-row, .grid-header', () => {
      this.unselect_all();
      this.hide_popup();
    });
  }

  public bar_being_dragged: string | null = null;

  bind_bar_events() {
    let is_dragging = false;
    let x_on_start = 0;
    let y_on_start = 0;
    let is_resizing_left = false;
    let is_resizing_right = false;
    let parent_bar_id: string | null = null;
    let bars: Bar[] = []; // instanceof Bar
    this.bar_being_dragged = null;

    function action_in_progress() {
      return is_dragging || is_resizing_left || is_resizing_right;
    }

    if (!this.$svg) throw new Error('no svg');

    const handleStart = (element: Element, x: number, y: number) => {
      const bar_wrapper = $.closest('.bar-wrapper', element);

      if (element.classList.contains('left')) {
        is_resizing_left = true;
      } else if (element.classList.contains('right')) {
        is_resizing_right = true;
      } else if (element.classList.contains('bar-wrapper')) {
        is_dragging = true;
      }

      if (!bar_wrapper) throw new Error('no bar wrapper');
      bar_wrapper.classList.add('active');

      x_on_start = x;
      y_on_start = y;

      parent_bar_id = bar_wrapper.getAttribute('data-id');
      if (!parent_bar_id) throw new Error('no bar parent_bar_id');
      const ids = [parent_bar_id, ...this.get_all_dependent_tasks(parent_bar_id)];
      bars = ids
        .map((id) => this.get_bar(id))
        .filter((Boolean as any) as ExcludesUndefined);

      this.bar_being_dragged = parent_bar_id;

      bars.forEach((bar) => {
        const $bar = bar.$bar;
        $bar.ox = $bar.getX();
        $bar.oy = $bar.getY();
        $bar.owidth = $bar.getWidth();
        $bar.finaldx = 0;
      });
    };

    const handleMove = (e: Event, x: number, y: number) => {
      if (!action_in_progress()) return;

      e.stopPropagation();
      e.preventDefault();
      const dx = x - x_on_start;
      const dy = y - y_on_start;

      bars.forEach((bar) => {
        const $bar = bar.$bar;
        $bar.finaldx = this.get_snap_position(dx);

        if (is_resizing_left) {
          if (parent_bar_id === bar.task.id) {
            bar.update_bar_position({
              x: $bar.ox + $bar.finaldx,
              width: $bar.owidth - $bar.finaldx,
            });
          } else {
            bar.update_bar_position({
              x: $bar.ox + $bar.finaldx,
            });
          }
        } else if (is_resizing_right) {
          if (parent_bar_id === bar.task.id) {
            bar.update_bar_position({
              width: $bar.owidth + $bar.finaldx,
            });
          }
        } else if (is_dragging) {
          bar.update_bar_position({ x: $bar.ox + $bar.finaldx });
        }
      });
    };

    const handleEnd = () => {
      is_dragging = false;
      is_resizing_left = false;
      is_resizing_right = false;
      if (is_dragging || is_resizing_left || is_resizing_right) {
        bars.forEach((bar) => bar.group.classList.remove('active'));
      }
    };

    const handleMouseUp = () => {
      if (this.bar_being_dragged) {
        this.bar_being_dragged = null;
        bars.forEach((bar) => {
          const $bar = bar.$bar;
          if (!$bar.finaldx) return;
          bar.date_changed();
          bar.set_action_completed();
        });
      }
    };

    $.on(
      this.$svg,
      'touchstart',
      '.bar-wrapper, .handle',
      (e: Touch & Event, element: Element) => {
        handleStart(element, e.pageX, e.pageY);
      }
    );

    $.on(
      this.$svg,
      'mousedown',
      '.bar-wrapper, .handle',
      (e: MouseEvent, element: Element) => {
        handleStart(element, e.offsetX, e.offsetY);
      }
    );

    $.on(this.$svg, 'touchmove', (e: Touch & Event) => {
      handleMove(e, e.pageX, e.pageY);
    });

    $.on(this.$svg, 'mousemove', (e: MouseEvent) => {
      handleMove(e, e.offsetX, e.offsetY);
    });

    document.addEventListener('touchend', () => {
      handleEnd();
    });

    document.addEventListener('mouseup', () => {
      handleEnd();
    });

    $.on(this.$svg, 'mouseup', (e) => {
      handleMouseUp();
    });

    $.on(this.$svg, 'touchend', (e) => {
      handleMouseUp();
    });

    this.bind_bar_progress();
  }

  bind_bar_progress() {
    let x_on_start = 0;
    let y_on_start = 0;
    let is_resizing: boolean | null = null;
    let bar: Bar | undefined | null = null;
    let $bar_progress: ExtendedSvg | null = null;
    let $bar = null;

    if (!this.$svg) throw new Error('no svg');
    $.on(this.$svg, 'mousedown', '.handle.progress', (e, handle) => {
      is_resizing = true;
      x_on_start = e.offsetX;
      y_on_start = e.offsetY;

      const $bar_wrapper = $.closest('.bar-wrapper', handle);
      if (!$bar_wrapper) throw new Error('no bar wrapper');
      const id = $bar_wrapper.getAttribute('data-id');
      if (id) {
        bar = this.get_bar(id);
      }

      if (!bar) throw new Error('no bar');

      $bar_progress = bar.$bar_progress;

      if (!$bar_progress) throw new Error('no bar progress');

      $bar = bar.$bar;

      $bar_progress.finaldx = 0;
      $bar_progress.owidth = $bar_progress.getWidth();
      $bar_progress.min_dx = -$bar_progress.getWidth();
      $bar_progress.max_dx = $bar.getWidth() - $bar_progress.getWidth();
    });

    $.on(this.$svg, 'mousemove', (e) => {
      if (!is_resizing) return;
      let dx = e.offsetX - x_on_start;
      const dy = e.offsetY - y_on_start;

      if (!$bar_progress) throw new Error('no bar progress');

      if (dx > $bar_progress.max_dx) {
        dx = $bar_progress.max_dx;
      }
      if (dx < $bar_progress.min_dx) {
        dx = $bar_progress.min_dx;
      }

      if (!bar) throw new Error('no bar');

      const $handle = bar.$handle_progress;
      $.attr($bar_progress, 'width', $bar_progress.owidth + dx);
      if ($handle) {
        $.attr($handle, 'points', bar.get_progress_polygon_points());
      }
      $bar_progress.finaldx = dx;
    });

    $.on(this.$svg, 'mouseup', () => {
      is_resizing = false;
      if (!($bar_progress && $bar_progress.finaldx)) return;

      if (!bar) throw new Error('no bar');
      bar.progress_changed();
      bar.set_action_completed();
    });
  }

  get_all_dependent_tasks(task_id: string) {
    let out: string[] = [];
    let to_process = [task_id];
    while (to_process.length) {
      const deps = to_process.reduce((acc, curr) => {
        acc = acc.concat(this.dependency_map[curr]);
        return acc;
      }, [] as string[]);

      out = out.concat(deps);
      to_process = deps.filter((d) => !to_process.includes(d));
    }

    return out.filter(Boolean);
  }

  get_snap_position(dx: number) {
    const odx = dx;
    let rem, position;

    if (this.view_is(VIEW_MODE.WEEK)) {
      rem = dx % (this.options.column_width / 7);
      position =
        odx -
        rem +
        (rem < this.options.column_width / 14 ? 0 : this.options.column_width / 7);
    } else if (this.view_is(VIEW_MODE.MONTH)) {
      rem = dx % (this.options.column_width / 30);
      position =
        odx -
        rem +
        (rem < this.options.column_width / 60 ? 0 : this.options.column_width / 30);
    } else {
      rem = dx % this.options.column_width;
      position =
        odx -
        rem +
        (rem < this.options.column_width / 2 ? 0 : this.options.column_width);
    }
    return position;
  }

  unselect_all() {
    if (!this.$svg) throw new Error('no svg');
    [...this.$svg.querySelectorAll('.bar-wrapper')].forEach((el) => {
      el.classList.remove('active');
    });
  }

  view_is(modes: string | string[]) {
    if (typeof modes === 'string') {
      return this.options.view_mode === modes;
    }

    if (Array.isArray(modes)) {
      return modes.some((mode) => this.options.view_mode === mode);
    }

    return false;
  }

  get_task(id: string) {
    return this.tasks.find((task) => {
      return task.id === id;
    });
  }

  get_bar(id: string) {
    return this.bars.find((bar) => {
      return bar.task.id === id;
    });
  }

  private popup: Popup | null = null;

  show_popup(options: any) {
    if (!this.options.showPopup) return;
    if (!this.popup) {
      this.popup = new Popup(this.popup_wrapper, this.options.custom_popup_html);
    }
    this.popup.show(options);
  }

  hide_popup() {
    this.popup && this.popup.hide();
  }

  trigger_event(
    event: 'click' | 'date_change' | 'progress_change' | 'view_change',
    args: any[]
  ) {
    // @ts-ignore
    if (this.options['on_' + event]) {
      // @ts-ignore
      this.options['on_' + event].apply(null, args);
    }
  }

  /**
   * Gets the oldest starting date from the list of tasks
   *
   * @returns Date
   * @memberof Gantt
   */
  get_oldest_starting_date() {
    return this.tasks
      .map((task) => task._start)
      .reduce((prev_date, cur_date) =>
        (cur_date || 0) <= (prev_date || 0) ? cur_date : prev_date
      );
  }

  /**
   * Clear all elements from the parent svg element
   *
   * @memberof Gantt
   */
  clear() {
    if (!this.$svg) throw new Error('no svg');
    this.$svg.innerHTML = '';
  }
}

Gantt.VIEW_MODE = VIEW_MODE;

function generate_id(task: GanttTask) {
  return task.name + '_' + Math.random().toString(36).slice(2, 12);
}
