import { OverlayEventDetail } from '@ionic/core';
import React from 'react';
import ReactDOM from 'react-dom';

import { attachEventProps } from './utils';

interface OverlayElement extends HTMLElement {
  present: () => Promise<void>;
  dismiss: (data?: any, role?: string | undefined) => Promise<boolean>;
}

export interface ReactOverlayProps {
  children?: React.ReactNode;
  isOpen: boolean;
  onDidDismiss?: (event: CustomEvent<OverlayEventDetail>) => void;
}

export const createOverlayComponent = <
  T extends any,
  OverlayType extends OverlayElement
>(
  displayName: string,
  controller: { create: (options: any) => Promise<OverlayType> }
) => {
  const dismissEventName = `onIonModalDidDismiss`;

  type Props = T & ReactOverlayProps;

  return class extends React.Component<Props> {
    overlay?: OverlayType;
    el: HTMLDivElement;

    constructor(props: Props) {
      super(props);
      this.el = document.createElement('div');
    }

    static get displayName() {
      return displayName;
    }

    componentDidMount() {
      this.attach();
    }

    async componentDidUpdate(prevProps: Props) {
      if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen === true) {
        this.present(prevProps);
      }
      if (
        this.overlay &&
        prevProps.isOpen !== this.props.isOpen &&
        this.props.isOpen === false
      ) {
        // this.overlay.style.visibility = 'hidden';
        this.overlay.classList.remove('always-modal-visible');
        this.overlay.classList.add('always-modal-hidden');
        // await this.overlay.dismiss();
      }
    }

    async attach(prevProps?: Props) {
      const {
        children,
        onDidDismiss = () => {
          return;
        },
        ...cProps
      } = this.props;
      const elementProps = {
        ...cProps,
        [dismissEventName]: (e: any) => {
          onDidDismiss(e);
          this.attach();
        },
      };

      this.overlay = this.overlay = await controller.create({
        ...elementProps,
        component: this.el,
        componentProps: {},
      });
      // this.overlay.style.visibility = 'hidden';
      this.overlay.classList.remove('always-modal-visible');
      this.overlay.classList.add('always-modal-hidden');

      attachEventProps(this.overlay, elementProps, prevProps);

      this.overlay && (await this.overlay.present());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async present(prevProps?: Props) {
      if (this.overlay) {
        // this.overlay.style.visibility = 'visible';
        this.overlay.classList.remove('always-modal-hidden');
        this.overlay.classList.add('always-modal-visible');
      }
    }

    render() {
      return ReactDOM.createPortal(this.props.children, this.el);
    }
  };
};
