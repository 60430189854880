import startOfWeek from 'date-fns/startOfWeek';
import addWeeks from 'date-fns/addWeeks';
import addDays from 'date-fns/addDays';
import isWeekend from 'date-fns/isWeekend';
import lastDayOfWeek from 'date-fns/lastDayOfWeek';
import format from 'date-fns/format';
import isThisYear from 'date-fns/isThisYear';
import {
  differenceInCalendarDays,
  isFuture,
  isPast,
  isToday,
  isTomorrow,
} from 'date-fns';

export function isoDateToDate(isoDate: string): string {
  return new Date(isoDate).toLocaleDateString('en-US');
}

export function dateObjectToNumber(date: Date): number {
  return date.getTime();
}

export function dateAsHuman(date: Date | number): string {
  const dateObj = new Date(date);
  if (isToday(dateObj)) {
    return 'now';
  }
  if (isTomorrow(dateObj)) {
    return 'Tmr';
  }
  const dayDiff = Math.abs(differenceInCalendarDays(new Date(), dateObj));
  if (isFuture(dateObj) && dayDiff < 7) {
    return `in ${dayDiff}d`;
    // return format(dateObj, 'EEE');
  }
  if (isPast(dateObj) && dayDiff < 7) {
    return `${dayDiff}d ago`;
  }
  if (isThisYear(dateObj)) {
    return format(dateObj, 'MMM d');
  }
  return format(dateObj, 'M/d/yy');
}

export function getTomorrow(): number {
  return dateObjectToNumber(addDays(new Date(), 1));
}

export function getThisWeekend(): number {
  let date = new Date();
  if (isWeekend(date)) {
    date = startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 });
  }
  // saturday
  return dateObjectToNumber(lastDayOfWeek(date, { weekStartsOn: 0 }));
}

export function getNextWeek(): number {
  // monday
  return dateObjectToNumber(
    startOfWeek(addWeeks(new Date(), 1), { weekStartsOn: 1 })
  );
}
