import React from 'react';
import { genericStyles } from '../../helpers/styles';
import { Nullable, TaskTime } from '../../types/CoreTypes';
import { getTimeLabel } from '../../helpers/CopyHelper';

interface Props {
  time: Nullable<TaskTime>;
  onClick?: any;
}

function getColor(priority: Nullable<TaskTime>) {
  if (!priority) {
    return genericStyles.grayOutColor;
  }
  if (priority <= TaskTime.TIME_5_MIN) {
    return genericStyles.greenColor;
  }
  if (priority >= TaskTime.TIME_1_D) {
    return genericStyles.dangerColor;
  }
  if (priority >= TaskTime.TIME_6_H) {
    return genericStyles.orangeColor;
  }
  return genericStyles.primaryColor;
}

const TimeInfo = ({ time, onClick }: Props) => {
  return (
    <span
      className={onClick ? 'clickable-control' : ''}
      onClick={onClick}
      style={{
        ...genericStyles.iconLabel,
        ...genericStyles.propertyClickable,
        ...getColor(time),
      }}
    >
      {getTimeLabel(time)}
    </span>
  );
};

export default TimeInfo;
