import React, { createContext, useContext } from 'react';
import { ContextType } from '../types/CoreTypes';

// @ts-ignore
export const WorkspaceContext = createContext<WorkspaceProps>(undefined);

interface Props {
  context: ContextType;
  setContext: (context: ContextType) => void;
  children: any;
}

export interface WorkspaceProps {
  context: ContextType;
  setContext: (context: ContextType) => void;
}

export const WorkspaceProvider = ({ context, setContext, children }: Props) => {
  return (
    <WorkspaceContext.Provider value={{ context, setContext }}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspace: () => WorkspaceProps = () => useContext(WorkspaceContext);

export const withWorkspace = <P extends Partial<WorkspaceProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof WorkspaceProps>> => {
  return function withWorkspaceContext(props) {
    return (
      <WorkspaceContext.Consumer>
        {(contextProps: WorkspaceProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </WorkspaceContext.Consumer>
    );
  };
};
