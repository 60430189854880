import React from 'react';
import { genericStyles } from '../../helpers/styles';
import { Nullable, TaskEnergy } from '../../types/CoreTypes';
import { getEnergyLabel } from '../../helpers/CopyHelper';

interface Props {
  energy: Nullable<TaskEnergy>;
  onClick?: any;
}

function getColor(energy: Nullable<TaskEnergy>) {
  switch (energy) {
    case TaskEnergy.ENERGY_LOW:
      return genericStyles.greenColor;
    case TaskEnergy.ENERGY_MED:
      return genericStyles.primaryColor;
    case TaskEnergy.ENERGY_HIGH:
      return genericStyles.orangeColor;
    default:
      return genericStyles.grayOutColor;
  }
}

const EnergyInfo = ({ energy, onClick }: Props) => {
  return (
    <span
      className={onClick ? 'clickable-control' : ''}
      onClick={onClick}
      style={{
        ...genericStyles.iconLabel,
        ...genericStyles.propertyClickable,
        ...getColor(energy),
      }}
    >
      {getEnergyLabel(energy)}
    </span>
  );
};

export default EnergyInfo;
