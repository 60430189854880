import React, { forwardRef } from 'react';
import { ProjectEnrichedWithTaskStats } from '../../types/CoreTypes';
import ProjectListRowDesktop from './ProjectListRowDesktop';
import ProjectListRowMobile from './ProjectListRowMobile';
import { ProjectHandlers } from '../../types/ProjectHandlers';
import deepEqual from 'fast-deep-equal';
import { useClientType } from '../../helpers/ClientTypeProvider';

interface Props {
  project: ProjectEnrichedWithTaskStats;
  projectHandlers: ProjectHandlers;
  ref?: any;
  isSelected?: boolean;
  manualReorder?: (up: boolean) => Promise<boolean>;
  onAfterNavigate?: () => void;
  compact?: boolean;
}

// eslint-disable-next-line react/display-name
const ProjectListRow: React.FunctionComponent<Props> = forwardRef<any, Props>(
  (props: Props, ref) => {
    const { isDesktop } = useClientType();
    if (isDesktop) {
      return (
        <ProjectListRowDesktop
          manualReorder={props.manualReorder}
          project={props.project}
          projectHandlers={props.projectHandlers}
          ref={ref}
          isSelected={props.isSelected}
          onAfterNavigate={props.onAfterNavigate}
          compact={props.compact}
        />
      );
    }

    return (
      <ProjectListRowMobile
        project={props.project}
        projectHandlers={props.projectHandlers}
        isSelected={props.isSelected}
        onAfterNavigate={props.onAfterNavigate}
      />
    );
  }
);

const Memoized = React.memo(ProjectListRow, deepEqual);

export default Memoized;
