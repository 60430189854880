import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IonToast, ToastOptions } from '@ionic/react';
import { ReactControllerProps } from '@ionic/react/dist/types/components/createControllerComponent';

type ReactToastOptions = ToastOptions & Partial<ReactControllerProps>;

type ToastInstance = {
  present: (options?: ReactToastOptions) => void;
  dismiss: () => void;
};

type ToastProviderOptions = {
  create: (options: ReactToastOptions) => ToastInstance;
  show: (options: ReactToastOptions) => void;
  success: (message: string) => ToastInstance;
  error: (message: string) => ToastInstance;
  warning: (message: string) => ToastInstance;
};

export interface ToastProviderProps {
  toast: ToastProviderOptions;
}

// @ts-ignore
const ToastContext = createContext<ToastProviderOptions>(undefined);
const { Provider } = ToastContext;

interface Props {
  value?: ToastOptions;
}

export const withToast = <P extends Partial<ToastProviderProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof ToastProviderProps>> => {
  return function withToast(props) {
    return (
      <ToastContext.Consumer>
        {(toastProps: ToastProviderOptions) => (
          <Component {...(props as P)} toast={toastProps} />
        )}
      </ToastContext.Consumer>
    );
  };
};

export const useToast: () => ToastProviderOptions = () =>
  useContext(ToastContext) as ToastProviderOptions;

// eslint-disable-next-line react/prop-types
export const ToastProvider: FC<Props> = ({ value, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<ReactToastOptions>();
  const ref = useRef<HTMLIonToastElement | null>(null);

  const create = useCallback(
    (options: ReactToastOptions) => {
      const present = (options: ReactToastOptions) => () => {
        setOptions({
          ...value,
          ...options,
        });
        setIsOpen(true);
      };

      const dismiss = () => {
        ref.current?.dismiss();
      };

      return {
        present: present(options),
        dismiss,
      };
    },
    [value]
  );

  const show = useCallback(
    async (options: ReactToastOptions) => {
      await ref.current?.dismiss();
      setOptions({
        ...value,
        ...options,
      });
      setIsOpen(true);
    },
    [value]
  );

  const contextValue = useMemo(() => {
    const translateToOptions = (color: 'success' | 'warning' | 'danger') => (
      message: string
    ) => {
      const toast = create({ message, color });
      toast.present();
      return toast;
    };

    return {
      create,
      show,
      success: translateToOptions('success'),
      error: translateToOptions('danger'),
      warning: translateToOptions('warning'),
    };
  }, [create, show]);

  return (
    <Provider value={contextValue}>
      {children}
      <IonToast
        ref={ref}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        {...options}
      />
    </Provider>
  );
};
