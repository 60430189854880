import React from 'react';
import { IonButton, IonFooter, IonInput, IonToolbar } from '@ionic/react';
import { ContextType, PartialUpdatedRecord } from '../../types/CoreTypes';
import FormElement from '../Common/FormElement';
import { StorageContext, withStorage } from '../../helpers/StorageProviders';
import { Repos } from '../../helpers/InitStorage';
import { diff } from 'deep-object-diff';

interface Props {
  context: ContextType;
  onSave: (context: PartialUpdatedRecord<ContextType>) => void;
  onCancel: () => void;
  repos: Repos;
}

interface State {
  context: Partial<ContextType>;
}

class EditContextForm extends React.Component<Props, State> {
  static contextType = StorageContext;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      context: props.context,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.context.id !== state.context.id) {
      return {
        context: props.context,
      };
    }
    return null;
  }

  changeValue = (value: Partial<ContextType>) => {
    this.setState({ context: { ...this.state.context, ...value } });
  };

  handleSave = () => {
    const differences = diff(this.props.context, this.state.context);
    this.props.onSave({
      id: this.props.context.id,
      ...differences,
    });
    // do smth like reset the focus
  };

  render() {
    const { context } = this.state;
    const { onCancel } = this.props;

    return (
      <>
        <div>
          <FormElement description="Context name">
            <IonInput
              autocorrect="on"
              placeholder="Context name"
              value={context.name}
              clearInput
              onIonChange={(event: any) =>
                this.changeValue({ name: event.target.value })
              }
            />
          </FormElement>
        </div>
        <IonFooter>
          <IonToolbar>
            <IonButton color="light" onClick={onCancel}>
              Cancel
            </IonButton>
            <IonButton
              color="primary"
              onClick={this.handleSave}
              style={{ float: 'right' }}
            >
              Submit
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }
}

export default withStorage(EditContextForm);
