import React from 'react';
import { RouteComponentProps } from 'react-router';
import Contexts from '../components/Contexts/Contexts';
import { IonPage } from '@ionic/react';

const ContextsPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <IonPage>
      <Contexts />
    </IonPage>
  );
};

export default ContextsPage;
