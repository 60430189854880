import React from 'react';
import { IonContent, IonPage, IonTitle } from '@ionic/react';
import CommonHeader from '../components/Common/CommonHeader';
import ListNavigation from '../components/Common/ListNavigation';
import WrapForCollectionBasedCallbacks from '../components/Common/WrapForCollectionBasedCallbacks';
import ArchiveTaskListView from '../components/Tasks/ArchiveTaskListView';

const TASKS_COLLECTION_NAME = 'tasks';
const ArchivePage: React.FunctionComponent = () => {
  return (
    <IonPage>
      <CommonHeader>
        <IonTitle>Archived tasks</IonTitle>
      </CommonHeader>
      <IonContent>
        <ListNavigation collections={[TASKS_COLLECTION_NAME]}>
          {(listNavigationApi) => (
            <WrapForCollectionBasedCallbacks
              setCounter={listNavigationApi.setCounter}
              collectionName={TASKS_COLLECTION_NAME}
            >
              {({ setCounter }) => (
                <ArchiveTaskListView
                  selectedIdx={listNavigationApi.selectedCollectionIdx}
                  setCounter={setCounter}
                  getRef={
                    listNavigationApi?.getRef
                      ? listNavigationApi.getRef(TASKS_COLLECTION_NAME)
                      : undefined
                  }
                />
              )}
            </WrapForCollectionBasedCallbacks>
          )}
        </ListNavigation>
      </IonContent>
    </IonPage>
  );
};

export default ArchivePage;
