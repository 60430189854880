import {
  BlockType,
  ExcludesNull,
  Identifiable,
  ItemType,
  MyDB,
  ProjectTaskStats,
  ProjectType,
  TaskBlockStats,
  TaskStatus,
  TaskType,
  TimeStampable,
} from '../../types/CoreTypes';
import { DaoCatalog } from '../../helpers/InitStorage';
// eslint-disable-next-line import/named
import { RxCollection } from 'rxdb/plugins/core';
import { BlockModel, TaskModel } from './Models';
import { getMinutesFromTaskTime } from '../../helpers/TaskHelper';
import { logDebug } from '../logger';
import { ExtractEnrichmentResult, Type } from './DaoTypes';
import { TaskPositionContextEnum } from '@todo/common';

export type SortSettings<M> = {
  dir: 'asc' | 'desc';
  substitute?: string[];
};

export type SortCondition<M> = {
  [k in keyof M]?: SortSettings<M>;
};
export type ModelTypeConstraints = Identifiable & TimeStampable;
export type StringKey<T> = Extract<keyof T, string>;
export type Sorter<T> = Array<
  StringKey<T> | { [k in StringKey<T>]?: SortSettings<T> }
>;

export abstract class Model<
  T extends ModelTypeConstraints = ModelTypeConstraints,
  K extends string | undefined = any
> {
  abstract readonly type: ItemType;
  readonly defaultSort: (sortContext: K | undefined) => Sorter<T> = sortByTime<T>();
  abstract initCollection(localDb: MyDB): Promise<RxCollection<T>>;

  getSort(
    sortContext: K | undefined
  ): Array<StringKey<T> | { [k in StringKey<T>]+?: SortSettings<T> }> {
    return this.defaultSort(sortContext);
  }
}

export const sortByTime = <T extends TimeStampable>() => (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sortContext: string | undefined
): Sorter<T> => {
  return [{ createdAt: { dir: 'desc' } }];
};

export const sortByPosition = <K extends string, T extends TimeStampable>(
  getPositionKey: (sc: K) => keyof T
) => (sortContext: K | undefined): Sorter<T> => {
  const key = sortContext ? getPositionKey(sortContext) : undefined;
  const conditions: Sorter<T> = [];
  if (key) {
    conditions.push({ [key]: { dir: 'asc' } } as SortCondition<T>);
  }
  conditions.push({ createdAt: { dir: 'asc' } });
  return conditions;
};

export const sortByPositionTask = (
  getPositionKey: (sc: TaskPositionContextEnum) => keyof TaskType
) => (sortContext: TaskPositionContextEnum | undefined): Sorter<TaskType> => {
  const key = sortContext ? getPositionKey(sortContext) : undefined;
  const conditions: Sorter<TaskType> = [];
  if (key) {
    conditions.push({ [key]: { dir: 'asc' } } as SortCondition<TaskType>);
  }
  if (sortContext === TaskPositionContextEnum.STATUS) {
    conditions.push({ statusUpdatedAt: { dir: 'asc', substitute: ['createdAt'] } });
  } else {
    conditions.push({ createdAt: { dir: 'asc' } });
  }
  return conditions;
};

export const sortByPositionSimple = <T extends TimeStampable>(
  key: keyof T,
  direction: 'asc' | 'desc'
) => (): Sorter<T> => {
  const conditions: Sorter<T> = [];
  if (key) {
    conditions.push({ [key]: { dir: direction } } as SortCondition<T>);
  }
  conditions.push({ createdAt: { dir: direction } });
  return conditions;
};

export abstract class Enrichment<This extends ModelTypeConstraints, NewFields> {
  enrich(daoCatalog: DaoCatalog, res: This): Promise<NewFields | undefined> {
    return this.enrichMany(daoCatalog, [res]).then((r) => r.get(res.id));
  }
  abstract enrichMany(
    daoCatalog: DaoCatalog,
    res: This[]
  ): Promise<Map<string, NewFields>>;
}

export class BelongsToRelation<
  This extends ModelTypeConstraints,
  Other extends ModelTypeConstraints,
  K extends string
> extends Enrichment<This, { [k in K]: Other | null }> {
  // TODO: can improve: allow only fields with string values
  readonly field: keyof This;
  readonly thisModel: Model<This>;
  readonly model: Model<Other>;
  readonly setTo: K;
  constructor(
    thisModel: Model<This>,
    model: Model<Other>,
    field: keyof This,
    setTo: K
  ) {
    super();
    this.thisModel = thisModel;
    this.model = model;
    this.field = field;
    this.setTo = setTo;
  }

  async enrichMany(
    daoCatalog: DaoCatalog,
    resx: This[]
  ): Promise<Map<string, { [k in K]: Other | null }>> {
    // TODO refactor it to use less memory (maps)
    const dao = daoCatalog.getDaoByModel(this.model);
    const recToRelIds = new Map(resx.map((r) => [r.id, r[this.field]]));
    const ids = Array.from(recToRelIds.values()).filter(
      (Boolean as any) as ExcludesNull
    );
    let relatedRecords: Other[] = [];
    if (ids.length && typeof ids[0] == 'string') {
      logDebug([], 'enrich ids', { $in: Array.from(new Set(ids)) });
      relatedRecords = await dao.findAll({
        selector: {
          _id: { $in: Array.from(new Set(ids)) },
        },
      });
    }
    const k: K = this.setTo;
    const relatedRecordsMap = new Map(relatedRecords.map((r) => [r.id, { [k]: r }]));
    return new Map(
      // @ts-ignore
      resx
        .map((res) => {
          const relId = recToRelIds.get(res.id);
          if (relId && typeof relId === 'string') {
            const rel = relatedRecordsMap.get(relId);
            if (rel !== undefined) {
              return [res.id, rel];
            }
          }
          return null;
        })
        .filter((Boolean as any) as ExcludesNull)
    );
  }
}
export class BelongsToManyRelation<
  This extends ModelTypeConstraints,
  Other extends ModelTypeConstraints,
  K extends string
> extends Enrichment<This, { [k in K]: Other[] }> {
  // TODO: can improve: allow only fields with string values
  readonly field: keyof This;
  readonly thisModel: Model<This>;
  readonly model: Model<Other>;
  readonly setTo: K;
  constructor(
    thisModel: Model<This>,
    model: Model<Other>,
    field: keyof This,
    setTo: K
  ) {
    super();
    this.thisModel = thisModel;
    this.model = model;
    this.field = field;
    this.setTo = setTo;
  }

  async enrichMany(
    daoCatalog: DaoCatalog,
    resx: This[]
  ): Promise<Map<string, { [k in K]: Other[] }>> {
    // TODO refactor it to use less memory (maps)
    const dao = daoCatalog.getDaoByModel(this.model);
    const recToRelIds = new Map(
      resx.map((r) => [
        r.id,
        ((r[this.field] || []) as { id: string }[]).map((r) => r.id),
      ])
    );
    const ids: string[] = Array.from(recToRelIds.values()).flat();
    let relatedRecords: Other[] = [];
    if (ids.length && typeof ids[0] == 'string') {
      // console.info('enrich many ids', { $in: Array.from(new Set(ids)) });
      relatedRecords = await dao.findAll({
        selector: {
          _id: { $in: Array.from(new Set(ids)) },
        },
      });
    }
    const k: K = this.setTo;
    const relatedRecordsMap = new Map(
      relatedRecords.map((r) => [r.id, { key: k, value: r }])
    );
    return new Map(
      // @ts-ignore
      resx
        .map((res) => {
          const relIds = recToRelIds.get(res.id);
          if (relIds && relIds.length) {
            const rels = relIds
              .map((relId) => {
                if (relId) {
                  const rel = relatedRecordsMap.get(relId);
                  if (rel !== undefined) {
                    return rel;
                  }
                }
                return null;
              })
              .filter((Boolean as any) as ExcludesNull)
              .reduce((acc, val) => {
                acc[val.key] = [...(acc[val.key] || []), val.value];
                return acc;
              }, {} as { [k in K]: Other[] });
            return [res.id, rels];
          }
          return null;
        })
        .filter((Boolean as any) as ExcludesNull)
    );
  }
}

export class TaskCounterEnrichment<
  Other extends ModelTypeConstraints,
  K extends string
> extends Enrichment<ProjectType, ProjectTaskStats> {
  async enrichMany(
    daoCatalog: DaoCatalog,
    projects: ProjectType[]
  ): Promise<Map<string, ProjectTaskStats>> {
    const projectIds = projects.map((p) => p.id);
    const counterDefaultValue: ProjectTaskStats = {
      totalTasks: 0,
      remainingTasks: 0,
      remainingNext: 0,
      remainingWaiting: 0,
      remainingTime: 0,
      totalTime: 0,
      unpointedTime: 0,
      unpointedEnergy: 0,
      unpointedPriority: 0,
    };

    // issuing many queries since index on $in is not working
    const tasksCounter = await daoCatalog
      .getDaoByModel(TaskModel)
      .findAll({ selector: { projectId: { $in: projectIds } } })
      .then((tasks: (Type<TaskType> & ExtractEnrichmentResult<[]>)[]) =>
        tasks.reduce((acc, task) => {
          if (task.projectId && projectIds.includes(task.projectId)) {
            let {
              totalTasks,
              remainingTasks,
              remainingNext,
              remainingWaiting,
              remainingTime,
              totalTime,
              unpointedTime,
              unpointedEnergy,
              unpointedPriority,
            } = acc.get(task.projectId) || counterDefaultValue;
            totalTasks += 1;
            const time = getMinutesFromTaskTime(task.time);
            if (task.status !== TaskStatus.STATUS_DONE) {
              remainingTasks += 1;
              remainingTime += time;
              if (!task.time) {
                unpointedTime += 1;
              }
              if (!task.energy) {
                unpointedEnergy += 1;
              }
              if (!task.priorityValues) {
                unpointedPriority += 1;
              }
            }
            totalTime += time;
            if (
              [
                TaskStatus.STATUS_ACTION_LIST,
                TaskStatus.STATUS_IN_PROGRESS,
              ].includes(task.status)
            ) {
              remainingNext += 1;
            }
            if (task.status === TaskStatus.STATUS_WAITING) {
              remainingWaiting += 1;
            }
            acc.set(task.projectId, {
              totalTasks,
              remainingTasks,
              remainingNext,
              remainingWaiting,
              remainingTime,
              totalTime,
              unpointedTime,
              unpointedEnergy,
              unpointedPriority,
            });
          }
          return acc;
        }, new Map<string, ProjectTaskStats>())
      );

    // const tasks = await taskStore.getByIds(taskIds.taskIds);

    return new Map(
      projects.map((project) => {
        const counter = tasksCounter.get(project.id) || counterDefaultValue;
        return [
          project.id,
          {
            totalTasks: counter.totalTasks,
            remainingTasks: counter.remainingTasks,
            remainingNext: counter.remainingNext,
            remainingWaiting: counter.remainingWaiting,
            remainingTime: counter.remainingTime,
            totalTime: counter.totalTime,
            unpointedTime: counter.unpointedTime,
            unpointedEnergy: counter.unpointedEnergy,
            unpointedPriority: counter.unpointedPriority,
            // nextTask: project.nextTaskId
            //   ? tasks.get(project.nextTaskId) || null
            //   : null
          },
        ];
      })
    );
  }
}

export class BlockCounterEnrichment<
  Other extends ModelTypeConstraints,
  K extends string
> extends Enrichment<TaskType, TaskBlockStats> {
  async enrichMany(
    daoCatalog: DaoCatalog,
    tasks: TaskType[]
  ): Promise<Map<string, TaskBlockStats>> {
    const taskIds = tasks.map((p) => p.id);
    const counterDefaultValue = {
      totalCheckboxes: 0,
      completedCheckboxes: 0,
      anyBlocks: false,
    };

    // issuing many queries since index on $in is not working
    const blockCounter = await daoCatalog
      .getDaoByModel<string, BlockType>(BlockModel)
      .findAll({ selector: { taskId: { $in: taskIds } } })
      .then((blocks) =>
        // @ts-ignore
        blocks.reduce((acc, block) => {
          if (block.taskId && taskIds.includes(block.taskId)) {
            let { totalCheckboxes, completedCheckboxes, anyBlocks } =
              acc.get(block.taskId) || counterDefaultValue;
            anyBlocks = true;
            if (block.type === 'checkbox') {
              totalCheckboxes += 1;
              if (block.completed) {
                completedCheckboxes += 1;
              }
            }
            acc.set(block.taskId, {
              totalCheckboxes,
              completedCheckboxes: completedCheckboxes,
              anyBlocks,
            });
          }
          return acc;
        }, new Map<string, TaskBlockStats>())
      );

    return new Map(
      tasks.map((task) => {
        return [task.id, blockCounter.get(task.id) || counterDefaultValue];
      })
    );
  }
}
