import { debounce } from 'lodash';

export function debouncedByArguments<A extends any[]>(
  fn: (...args: A) => void,
  timeMs: number
) {
  const functions = new Map<string, (...args: A) => void>();
  return (...args: A) => {
    const hash = (args || []).flat().join('|');
    if (!functions.has(hash)) {
      functions.set(
        hash,
        debounce((...args2: A) => fn(...args2), timeMs)
      );
    }
    const cachedFn = functions.get(hash);
    if (!cachedFn) {
      throw Error('debouncedByArguments: function is not found!');
    }
    return cachedFn(...args);
  };
}

export function getOrDefault<T>(a: T | undefined, def: T): T {
  if (a === undefined) return def;
  return a;
}
