import React, { CSSProperties } from 'react';
import { IonItemDivider, IonList } from '@ionic/react';
import ContextSwitchListElement from './Contexts/ContextSwitchListElement';
import { SearchResults } from '../types/CoreTypes';
import TaskActionsWrapper from './Tasks/TaskActionsWrapper';
import { withStorage } from '../helpers/StorageProviders';
import { StorageDeps } from '../helpers/InitStorage';
import TaskListRow from './Tasks/TaskListRow';
import ProjectListRow from './Projects/ProjectListRow';
import ProjectActionsWrapper from './Projects/ProjectActionsWrapper';
import TagListRow from './Tags/TagListRow';
import { ListNavigationApi } from './Common/ListNavigationCommon';
import { isEqual } from 'lodash';
import { colors } from '../helpers/styles';

interface Props extends StorageDeps {
  listNavigationApi: ListNavigationApi;
  results: SearchResults;
  onClose: () => void;
}

type Keys = 'tasks' | 'projects' | 'contexts' | 'tags';

interface State {
  // selectedIdx: number;
  expand: { [key in Keys]?: boolean };
}

// const ACTIONS = 'actions';
const TASKS = 'tasks';
const PROJECTS = 'projects';
const CONTEXTS = 'contexts';
const TAGS = 'tags';

// ACTIONS,
export const collections = [TASKS, PROJECTS, CONTEXTS, TAGS];

// show top results by default
const SIZE = 8;

const expandStyle: CSSProperties = {
  margin: '0 5px',
  cursor: 'pointer',
  textDecoration: 'underline',
  fontSize: '14px',
  color: colors.primary,
  float: 'right',
};

class SearchResultsListView extends React.PureComponent<Props, State> {
  readonly state: State = {
    expand: {},
  };

  componentDidMount() {
    this.handleUpdate();
  }

  handleUpdate() {
    const {
      listNavigationApi: { setCounter },
    } = this.props;
    // setCounter(ACTIONS, this.props.results.actions.length);
    setCounter(TASKS, this.props.results.tasks.data.length);
    setCounter(PROJECTS, this.props.results.projects.data.length);
    setCounter(TAGS, this.props.results.tags.data.length);
    setCounter(CONTEXTS, this.props.results.contexts.data.length);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(prevProps.results, this.props.results) ||
      prevProps.listNavigationApi.setCounter !==
        this.props.listNavigationApi.setCounter
    ) {
      this.handleUpdate();
    }
  }

  toggleExpand = (k: Keys) => {
    const { expand } = this.state;
    this.setState({ expand: { ...expand, [k]: !expand[k] } });
  };

  toggleExpandTasks = () => {
    this.toggleExpand('tasks');
  };
  toggleExpandProjects = () => {
    this.toggleExpand('projects');
  };
  toggleExpandTags = () => {
    this.toggleExpand('tags');
  };
  toggleExpandContexts = () => {
    this.toggleExpand('contexts');
  };

  render() {
    const {
      results,
      onClose,
      listNavigationApi: { getRef, selectedCollectionName, selectedCollectionIdx },
    } = this.props;
    const { expand } = this.state;

    return (
      <div>
        {/*{results.actions.length > 0 && (*/}
        {/*  <>*/}
        {/*    <IonItemDivider>Actions</IonItemDivider>*/}
        {/*    <IonList onClick={onClose}>*/}
        {/*      {results.actions.map((action, idx) => (*/}
        {/*        <ActionListElement*/}
        {/*          ref={getRef(ACTIONS)(idx)}*/}
        {/*          isSelected={*/}
        {/*            selectedCollectionName === ACTIONS &&*/}
        {/*            idx === selectedCollectionIdx*/}
        {/*          }*/}
        {/*          key={`${action.name}`}*/}
        {/*          action={action}*/}
        {/*        />*/}
        {/*      ))}*/}
        {/*    </IonList>*/}
        {/*  </>*/}
        {/*)}*/}
        {results.tasks.data.length > 0 && (
          <>
            <IonItemDivider>Tasks</IonItemDivider>
            <TaskActionsWrapper>
              {(taskHandlers) => (
                <IonList>
                  {(expand.tasks
                    ? results.tasks.data
                    : results.tasks.data.slice(0, SIZE)
                  ).map((task, idx) => (
                    <TaskListRow
                      taskHandlers={taskHandlers}
                      ref={getRef(TASKS)(idx)}
                      isSelected={
                        selectedCollectionName === TASKS &&
                        idx === selectedCollectionIdx
                      }
                      key={`${task.id}`}
                      task={task}
                      onAfterNavigate={onClose}
                      compact
                      hideTags
                    />
                  ))}
                </IonList>
              )}
            </TaskActionsWrapper>
            {results.tasks.data.length > SIZE && (
              <div style={expandStyle} onClick={this.toggleExpandTasks}>
                {expand.tasks ? 'collapse' : 'show more'} tasks
              </div>
            )}
          </>
        )}
        {results.projects.data.length > 0 && (
          <>
            <IonItemDivider>Projects</IonItemDivider>
            <ProjectActionsWrapper>
              {(projectHandlers) => (
                <IonList>
                  {(expand.projects
                    ? results.projects.data
                    : results.projects.data.slice(0, SIZE)
                  ).map((project, idx) => (
                    <ProjectListRow
                      ref={getRef(PROJECTS)(idx)}
                      isSelected={
                        selectedCollectionName === PROJECTS &&
                        idx === selectedCollectionIdx
                      }
                      key={`${project.id}`}
                      onAfterNavigate={onClose}
                      project={project}
                      projectHandlers={projectHandlers}
                      compact
                    />
                  ))}
                </IonList>
              )}
            </ProjectActionsWrapper>
            {results.projects.data.length > SIZE && (
              <div style={expandStyle} onClick={this.toggleExpandProjects}>
                {expand.projects ? 'collapse' : 'show more'} projects
              </div>
            )}
          </>
        )}
        {results.tags.data.length > 0 && (
          <>
            <IonItemDivider>Tags</IonItemDivider>
            <IonList>
              {(expand.tags
                ? results.tags.data
                : results.tags.data.slice(0, SIZE)
              ).map((tag, idx) => (
                <TagListRow
                  ref={getRef(TAGS)(idx)}
                  isSelected={
                    selectedCollectionName === TAGS && idx === selectedCollectionIdx
                  }
                  key={`${tag.id}`}
                  onAfterNavigate={onClose}
                  tag={tag}
                  compact
                />
              ))}
            </IonList>
            {results.tags.data.length > SIZE && (
              <div style={expandStyle} onClick={this.toggleExpandTags}>
                {expand.tags ? 'collapse' : 'show more'} tags
              </div>
            )}
          </>
        )}
        {results.contexts.data.length > 0 && (
          <>
            <IonItemDivider>Contexts</IonItemDivider>
            <IonList onClick={onClose}>
              {(expand.contexts
                ? results.contexts.data
                : results.contexts.data.slice(0, SIZE)
              ).map((context, idx) => (
                <ContextSwitchListElement
                  ref={getRef(CONTEXTS)(idx)}
                  isSelected={
                    selectedCollectionName === CONTEXTS &&
                    idx === selectedCollectionIdx
                  }
                  key={`${context.id}`}
                  context={context}
                />
              ))}
            </IonList>
            {results.contexts.data.length > SIZE && (
              <div style={expandStyle} onClick={this.toggleExpandContexts}>
                {expand.contexts ? 'collapse' : 'show more'} contexts
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withStorage(SearchResultsListView);
