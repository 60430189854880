import React from 'react';
import { IonButton, IonButtons, IonHeader, IonToolbar } from '@ionic/react';
import { MenuOpener } from './MenuOpener';
import { RouteComponentProps, withRouter } from 'react-router';
import GlobalSearch from '../GlobalSearch';
import ActionOnKeyPress from './ActionOnKeyPress';
import { ModalsProps, withModalsState } from '../../helpers/ModalsProvider';
import { Key, KeyModifier } from '../../types/CoreTypes';
import MagicButton from '../MagicButton';
import HelpButton from '../HelpButton';

interface Props extends RouteComponentProps, ModalsProps {
  children?: React.ReactNode;
  showBack?: boolean;
}

const CommonHeader = ({
  showBack,
  history: { goBack },
  children,
  nonMagicModalIsOpen,
}: Props) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <MenuOpener />
          {showBack && <IonButton onClick={goBack}>&lt; Back</IonButton>}
        </IonButtons>

        <ActionOnKeyPress
          name="Common Header"
          enabled={!nonMagicModalIsOpen}
          handlers={[
            {
              action: goBack,
              name: 'go back',
              combination: {
                key: Key.Up,
                modifiers: [[KeyModifier.Cmd], [KeyModifier.Ctrl]],
              },
            },
          ]}
        />
        {children}

        <IonButtons slot="end">
          <HelpButton />
          <MagicButton />
          <GlobalSearch />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default withModalsState(withRouter(CommonHeader));
