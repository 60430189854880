export function changePositionsInEntities<A>(
  entities: A[],
  from: number,
  to: number
) {
  if (from === to) throw Error('did not move');
  if (from === undefined || to === undefined) throw Error('Invalid movement!');
  // By default, JS sorts alphabetically, and 2 > 10. Changing this to use number comparison
  const [first, second] = [from, to].sort((a, b) => a - b);
  const movingUp = first === to;
  return [
    entities.slice(0, first),
    movingUp ? [entities[from]] : [],
    entities.slice(movingUp ? first : first + 1, movingUp ? second : second + 1),
    !movingUp ? [entities[from]] : [],
    entities.slice(second + 1),
  ].flat();
}
