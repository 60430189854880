import React from 'react';
import { genericStyles } from '../../helpers/styles';
import { IonIcon } from '@ionic/react';
import { dateAsHuman } from '../../lib/utils/DateTimeUtils';
import { differenceInDays, isFuture, isPast, isToday } from 'date-fns';

interface Props {
  date: number | Date | undefined;
  currentDate: Date;
  icon: string;
  onClick?: any;
}

function getColor(date: number | Date | undefined, currentDate: Date) {
  if (!date) return genericStyles.grayOutColor;
  const dateObj = new Date(date);
  if (isPast(dateObj)) return genericStyles.dangerColor;
  if (isToday(dateObj)) return genericStyles.dangerColor;
  if (isFuture(dateObj)) {
    // if (Math.abs(differenceInDays(new Date(), dateObj)) <= 2) {
    //   return genericStyles.dangerColor;
    // }
    if (Math.abs(differenceInDays(currentDate, dateObj)) <= 5) {
      return genericStyles.orangeColor;
    }
  }
  return genericStyles.primaryColor;
}

const DateInfo = ({ date, icon, onClick, currentDate }: Props) => {
  return (
    <span
      className={onClick ? 'clickable-control' : ''}
      onClick={onClick}
      style={{
        ...(onClick ? genericStyles.propertyClickable : genericStyles.property),
        ...getColor(date, currentDate),
      }}
    >
      <IonIcon style={genericStyles.icon} icon={icon} />
      <span style={genericStyles.iconLabel}>
        {date ? dateAsHuman(date) : 'none'}
      </span>
    </span>
  );
};

export default DateInfo;
