import {
  IonBadge,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
} from '@ionic/react';
import React, { useCallback, useEffect } from 'react';
import { ContextType, SyncStatus } from '../types/CoreTypes';
import { useWorkspace } from '../helpers/WorkspaceProviders';
import {
  archive,
  cog,
  folderOpen,
  helpCircle,
  pricetag,
  send,
  timer,
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import { HOME_URL } from '../lib/Constants';
import { CountersSources, useCounters } from '../helpers/CountersProvider';
import { taskStatuses, TaskStatusKey } from '../helpers/TaskHelper';
import { ProjectStatusKey } from '../helpers/ProjectHelper';
import { Routes } from '../lib/routes';
import SyncStatusIcon from './SyncStatusIcon';
import { logDebug } from '../lib/logger';

interface Props {
  allContexts: ContextType[] | undefined;
  syncStatus: SyncStatus;
}

interface Counter {
  normal?: number;
  danger?: number;
  muted?: number;
}

interface RouteConfig {
  path: string;
  icon: string;
  label: string;
  getCounter?: (sources: CountersSources) => Counter;
}

const routes: RouteConfig[] = [
  {
    path: `/${Routes.focus}`,
    icon: taskStatuses[TaskStatusKey.InProgress].icon,
    label: taskStatuses[TaskStatusKey.InProgress].label,
    getCounter: ({ taskSources }) => {
      const dueToday = taskSources[TaskStatusKey.Due]?.size || 0;
      const forToday = taskSources[TaskStatusKey.DueToday]?.size || 0;
      const progress = taskSources[TaskStatusKey.InProgress]?.size || 0;
      const normalCount = progress + forToday - dueToday;
      return {
        danger: dueToday || undefined,
        normal: normalCount,
      };
    },
  },
  {
    path: `/${Routes.next}`,
    icon: taskStatuses[TaskStatusKey.Next].icon,
    label: taskStatuses[TaskStatusKey.Next].label,
    getCounter: ({ taskSources }) => {
      const nextTasks = taskSources[TaskStatusKey.Next] || new Set<string>();
      const projectNextTasks =
        taskSources[TaskStatusKey.ProjectNext] || new Set<string>();
      const projectFocusTasks =
        taskSources[TaskStatusKey.ProjectFocus] || new Set<string>();
      const dueSoon = taskSources[TaskStatusKey.DueSoon] || new Set<string>();
      const count = new Set([
        ...nextTasks,
        ...projectNextTasks,
        ...projectFocusTasks,
        ...dueSoon,
      ]).size;
      return {
        normal: count,
      };
    },
  },
  {
    path: `/${Routes.waiting}`,
    icon: taskStatuses[TaskStatusKey.Waiting].icon,
    label: taskStatuses[TaskStatusKey.Waiting].label,
    getCounter: ({ taskSources }) => {
      return {
        normal: taskSources[TaskStatusKey.Waiting]?.size || 0,
      };
    },
  },
  {
    path: `/${Routes.sometime}`,
    icon: taskStatuses[TaskStatusKey.SomeTime].icon,
    label: taskStatuses[TaskStatusKey.SomeTime].label,
    getCounter: ({ taskSources }) => {
      return {
        normal: taskSources[TaskStatusKey.SomeTime]?.size || 0,
      };
    },
  },
  {
    path: `/${Routes.inbox}`,
    icon: taskStatuses[TaskStatusKey.Inbox].icon,
    label: taskStatuses[TaskStatusKey.Inbox].label,
    getCounter: ({ taskSources }) => {
      const inbox = taskSources[TaskStatusKey.Inbox]?.size || 0;
      return {
        danger: inbox || undefined,
      };
    },
  },
  {
    path: `/${Routes.projects}`,
    icon: folderOpen,
    label: 'Projects',
    getCounter: ({ projectSources }) => {
      const withNext =
        projectSources[ProjectStatusKey.ProjectWithNext] || new Set<string>();
      const action = projectSources[ProjectStatusKey.Action] || new Set<string>();
      const dueToday =
        projectSources[ProjectStatusKey.DueToday] || new Set<string>();

      const withoutNext = [...action].filter((x) => !withNext.has(x));

      const danger = new Set([...dueToday, ...withoutNext]);
      const normal = [...action].filter((x) => !danger.has(x));
      return {
        normal: normal.length,
        danger: danger.size,
      };
    },
  },
  {
    path: `/${Routes.tags}`,
    icon: pricetag,
    label: 'Tags',
  },
  { path: `/${Routes.agenda}`, icon: timer, label: 'Agenda' },
  { path: `/${Routes.archive}`, icon: archive, label: 'Archive' },
  { path: `/${Routes.contexts}`, icon: send, label: 'Contexts' },
  { path: `/${Routes.settings}`, icon: cog, label: 'Settings' },
  { path: `/${Routes.help}`, icon: helpCircle, label: 'Help' },
];

const Menu: React.FunctionComponent<Props> = ({
  allContexts,
  syncStatus,
}: Props) => {
  const { context, setContext } = useWorkspace();
  const { counters } = useCounters();
  const history = useHistory();

  const onSetContext = useCallback(
    function onSetContext(context: ContextType) {
      setContext(context);
      history.push(HOME_URL, { direction: 'root' });
    },
    [setContext, history]
  );

  useEffect(() => {
    if (!allContexts || !allContexts.length) {
      return;
    }
    const contexts = allContexts;

    // @ts-ignore
    const validIndexes = [...Array(contexts.length + 1).keys()]
      .map((c) => c.toString())
      .slice(1);

    function handleKeyPress(e: KeyboardEvent) {
      //cmd + num or ctrl + num
      if (
        (e.metaKey || e.ctrlKey) &&
        !e.shiftKey &&
        !e.altKey &&
        validIndexes.includes(e.key)
      ) {
        e.preventDefault();
        onSetContext(contexts[parseInt(e.key) - 1]);
      }
    }

    logDebug([], 'loading menu key listener...');
    document.addEventListener('keydown', handleKeyPress, false);

    return function cleanupListener() {
      logDebug([], 'unloading menu key listener...');
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [allContexts, setContext, onSetContext]);

  const getRouteCounter = useCallback(
    (r: RouteConfig, sources?: CountersSources): Counter | null => {
      if (!r.getCounter) return null;
      if (!sources) return null;
      return r.getCounter(sources);
    },
    []
  );

  const getContextCounter = useCallback(
    (sources?: CountersSources): Counter | null => {
      if (!sources) return null;
      const waitingTasks = sources.taskSources[TaskStatusKey.Waiting]?.size || 0;
      const nextTasks = sources.taskSources[TaskStatusKey.Next]?.size || 0;
      const projectNextTasks =
        sources.taskSources[TaskStatusKey.ProjectNext]?.size || 0;
      const dueSoon = sources.taskSources[TaskStatusKey.DueSoon]?.size || 0;
      // note: need to keep in sync with helpers/CountersProvider.redCounters
      const dueToday = sources.taskSources[TaskStatusKey.Due] || new Set<string>();
      const danger = new Set([
        ...dueToday,
        ...(sources.taskSources[TaskStatusKey.Inbox] || new Set<string>()),
      ]).size;
      const forToday =
        sources.taskSources[TaskStatusKey.DueToday] || new Set<string>();
      const progress =
        sources.taskSources[TaskStatusKey.InProgress] || new Set<string>();
      const normalCount = new Set([...progress, ...forToday, ...dueToday]).size;

      return {
        danger: danger,
        normal: normalCount,
        muted: waitingTasks + nextTasks + projectNextTasks + dueSoon,
      };
    },
    []
  );

  const renderBadge = useCallback((counter: Counter | null) => {
    if (!counter) return null;
    return (
      <div
        slot="end"
        style={{
          marginInlineStart: '12px',
        }}
      >
        {counter.muted !== undefined && counter.muted > 0 && (
          <IonBadge color="light">{counter.muted}</IonBadge>
        )}
        {counter.normal !== undefined && counter.normal > 0 && (
          <IonBadge color="medium">{counter.normal}</IonBadge>
        )}
        {counter.danger !== undefined && counter.danger > 0 && (
          <IonBadge color="danger">{counter.danger}</IonBadge>
        )}
      </div>
    );
  }, []);

  return (
    <IonMenu menuId="main" side="start" contentId="main" type="overlay">
      <IonHeader>
        <IonToolbar>
          <img
            style={{
              width: '160px',
              marginLeft: '6px',
            }}
            src="/assets/logo-320.png"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonMenuToggle autoHide={false}>
          <IonList>
            {routes.map((r) => (
              <IonItem
                key={`menu=${r.label}`}
                color={history.location.pathname === r.path ? 'primary' : ''}
                routerLink={r.path}
                routerDirection={'root'}
                style={{ fontSize: '15px' }}
              >
                <IonIcon
                  style={{ fontSize: '17px' }}
                  color={history.location.pathname === r.path ? '' : 'primary'}
                  icon={r.icon}
                />
                <IonLabel>{r.label}</IonLabel>
                {renderBadge(getRouteCounter(r, counters[context.id]))}
              </IonItem>
            ))}
          </IonList>

          <SyncStatusIcon syncStatus={syncStatus} />

          {allContexts && (
            <IonList style={{ marginTop: '2px' }}>
              {allContexts.map((c, idx) => (
                <IonItem
                  onClick={(e: any) => {
                    e.preventDefault();
                    onSetContext(c);
                  }}
                  href=""
                  color={c.id === context.id ? 'primary' : ''}
                  key={`context-key-${c.id}`}
                  style={{ fontSize: '15px' }}
                >
                  <IonLabel>
                    <span className="context-hot-key">⌘{idx + 1}</span>
                    {c.name}
                  </IonLabel>
                  {renderBadge(getContextCounter(counters[c.id]))}
                </IonItem>
              ))}
            </IonList>
          )}
        </IonMenuToggle>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
