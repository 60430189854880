import React from 'react';
import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonIcon,
  IonToolbar,
} from '@ionic/react';
import {
  add,
  chevronBack,
  chevronDown,
  chevronForward,
  chevronUp,
  close,
  repeat,
  trash,
} from 'ionicons/icons';
import { BlockKind } from '../../types/CoreTypes';
import { ClientTypeProps, withClientType } from '../../helpers/ClientTypeProvider';

interface Props extends ClientTypeProps {
  onDelete: () => void;
  onMoveRight: () => void;
  onMoveLeft: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onChangeType: (kind: BlockKind) => void;
  onAddBelow: (kind: BlockKind) => void;
  onBlur: () => void;
  onPreserveBlur: () => void;
  onStopPreservingBlur: () => void;
  elementTop?: number;
}

interface State {
  showTypeSelect: boolean;
  showAddBelow: boolean;
}

class DocumentRowToolbar extends React.Component<Props, State> {
  state: State = {
    showTypeSelect: false,
    showAddBelow: false,
  };

  // notDebouncedHandleChangeViewport = () => {
  //   const top = window.scrollY;
  //   this.setState({ top });
  // };
  //
  // debouncedHandleChangeViewport = debounce(
  //   this.notDebouncedHandleChangeViewport,
  //   100
  // );
  //
  // componentDidMount() {
  //   if (!this.props.isDesktop) {
  //     // window.addEventListener('resize', this.debouncedHandleChangeViewport);
  //     window.addEventListener('scroll', this.debouncedHandleChangeViewport);
  //     this.debouncedHandleChangeViewport();
  //   }
  // }
  //
  // componentWillUnmount() {
  //   if (!this.props.isDesktop) {
  //     // window.removeEventListener('resize', this.debouncedHandleChangeViewport);
  //     window.removeEventListener('scroll', this.debouncedHandleChangeViewport);
  //   }
  // }

  setShowAddBelow = (showAddBelow: boolean) => {
    this.setState({ showAddBelow });
  };
  setShowTypeSelect = (showTypeSelect: boolean) => {
    this.setState({ showTypeSelect });
  };

  render() {
    const {
      onBlur,
      onChangeType,
      onAddBelow,
      onMoveDown,
      onMoveLeft,
      onMoveRight,
      onDelete,
      onMoveUp,
      onPreserveBlur,
      onStopPreservingBlur,
      isNative,
      isDesktop,
    } = this.props;
    let klass;
    const styles: {
      [key: string]: any;
    } = {};
    if (!isNative && isDesktop) {
      klass = 'desktop';
    } else {
      klass = 'mobile';
      if (this.props.elementTop) styles.top = this.props.elementTop - 40;
    }

    return (
      // <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0 }}>
      <div className={`document-row-toolbar ${klass}`} style={styles}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              size="small"
              color="dark"
              onClick={() => {
                onPreserveBlur();
                this.setShowAddBelow(true);
              }}
            >
              <IonIcon size="small" slot="icon-only" icon={add} />
            </IonButton>
            <IonButton
              size="small"
              color="dark"
              onClick={() => {
                onPreserveBlur();
                this.setShowTypeSelect(true);
              }}
            >
              <IonIcon size="small" slot="start" icon={repeat} />
              Turn into
            </IonButton>
            <IonButton size="small" color="dark" onClick={onMoveLeft}>
              <IonIcon size="small" slot="icon-only" icon={chevronBack} />
            </IonButton>
            <IonButton size="small" color="dark" onClick={onMoveRight}>
              <IonIcon size="small" slot="icon-only" icon={chevronForward} />
            </IonButton>
            <IonButton size="small" color="dark" onClick={onMoveUp}>
              <IonIcon size="small" slot="icon-only" icon={chevronUp} />
            </IonButton>
            <IonButton size="small" color="dark" onClick={onMoveDown}>
              <IonIcon size="small" slot="icon-only" icon={chevronDown} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton size="small" color="dark" onClick={onDelete}>
              <IonIcon size="small" slot="icon-only" icon={trash} />
            </IonButton>
            <IonButton size="small" color="dark" onClick={onBlur}>
              <IonIcon size="small" slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonActionSheet
          animated={false}
          isOpen={this.state.showTypeSelect}
          onDidDismiss={() => {
            this.setShowTypeSelect(false);
            onStopPreservingBlur();
          }}
          header="Turn into"
          buttons={[
            {
              text: 'plain text',
              handler: () => {
                onChangeType('text');
              },
            },
            {
              text: 'checkbox',
              handler: () => {
                onChangeType('checkbox');
              },
            },
            {
              text: 'bullet list',
              handler: () => {
                onChangeType('bullet');
              },
            },
            {
              text: 'Header 1',
              handler: () => {
                onChangeType('h1');
              },
            },
            {
              text: 'Header 2',
              handler: () => {
                onChangeType('h2');
              },
            },
            {
              text: 'Header 3',
              handler: () => {
                onChangeType('h3');
              },
            },
            {
              text: 'Cancel',
              icon: 'close',
              role: 'cancel',
            },
          ]}
        />

        <IonActionSheet
          animated={false}
          isOpen={this.state.showAddBelow}
          onDidDismiss={() => {
            this.setShowAddBelow(false);
            onStopPreservingBlur();
          }}
          header="Add block below"
          buttons={[
            {
              text: 'plain text',
              handler: () => {
                onAddBelow('text');
              },
            },
            {
              text: 'checkbox',
              handler: () => {
                onAddBelow('checkbox');
              },
            },
            {
              text: 'bullet list',
              handler: () => {
                onAddBelow('bullet');
              },
            },
            {
              text: 'Header 1',
              handler: () => {
                onAddBelow('h1');
              },
            },
            {
              text: 'Header 2',
              handler: () => {
                onAddBelow('h2');
              },
            },
            {
              text: 'Header 3',
              handler: () => {
                onAddBelow('h3');
              },
            },
            {
              text: 'Cancel',
              icon: 'close',
              role: 'cancel',
            },
          ]}
        />
      </div>
      // </div>
    );
  }
}

export default withClientType(DocumentRowToolbar);
