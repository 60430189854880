import React, { useCallback, useState } from 'react';
import { useAuth0 } from '../Auth0ReactFork';
import { IonButton, IonIcon, IonLoading } from '@ionic/react';
import { useStorage } from '../helpers/StorageProviders';
import { exitOutline } from 'ionicons/icons';

const LogOut = () => {
  const { eraseAll } = useStorage();
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const [needClickMoreToEraseLocal, setNeedClickMoreToEraseLocal] = useState(false);
  const [isErasing, setIsErasing] = useState(false);

  const logOutAndErase = useCallback(() => {
    if (needClickMoreToEraseLocal) {
      setIsErasing(true);
      eraseAll().finally(() => {
        // setToastMessage('Local data erased');
        setTimeout(() => {
          if (isAuthenticated) {
            logout({ returnTo: window.location.origin }, () =>
              window.location.reload()
            );
          } else {
            window.location.reload();
          }
          setIsErasing(false);
        }, 500);
      });
    } else {
      setNeedClickMoreToEraseLocal(true);
    }
  }, [eraseAll, isAuthenticated, logout, needClickMoreToEraseLocal]);

  if (isLoading) {
    return null;
  }

  if (isErasing) {
    return <IonLoading isOpen={isErasing} message={'please wait...'} />;
  }

  return (
    <IonButton color="danger" onClick={logOutAndErase}>
      <IonIcon slot="start" icon={exitOutline} />
      {needClickMoreToEraseLocal
        ? 'Confirm erase all data'
        : 'Exit and erase all data'}
    </IonButton>
  );
};

export default LogOut;
