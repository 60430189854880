import React, { useCallback, useEffect, useState } from 'react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import './theme/app.scss';
import 'react-circular-progressbar/dist/styles.css';
import 'rc-tooltip/assets/bootstrap.css';
import { useDb } from './helpers/DbProvider';
import FreshStartApp from './FreshStartApp';
import MainApp from './MainApp';
import { initStorage, StorageDeps } from './helpers/InitStorage';
import { StorageProvider } from './helpers/StorageProviders';
import { useAuthUser } from './helpers/AuthUserProvider';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonLoading,
} from '@ionic/react';
import { debounce } from 'lodash';
import LocalSettingsRepo from './lib/LocalSettingsRepo';
import { RxDatabase } from 'rxdb/plugins/core';
import { GrantedPermissions, MyDatabaseCollections } from './types/CoreTypes';
import { useAuth0 } from './Auth0ReactFork';
import { arrowForward } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';
import { Badge } from '@ionic-native/badge';
import PermissionsWalkthrough from './components/PermissionsWalkthrough';
import { AUTH_TIMEOUT_SEC } from './lib/Constants';
import { SubscriptionStatusProvider } from './helpers/SubscriptionStatusProvider';

function morePermissionsToCheck(grantedPermissions: GrantedPermissions): boolean {
  if (!Capacitor.isNative || !Badge) {
    return false;
  }
  if (!grantedPermissions.badge) {
    return true;
  }
  return false;
}

const App: React.FunctionComponent = () => {
  const { localSettingsRepo, localDb } = useDb();
  const {
    userInfo,
    loadingUserInfo,
    userIdConflict,
    reLoginRequired,
  } = useAuthUser();
  const { loginWithPopup, logout } = useAuth0();
  // const [userId, setUserId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [
    grantedPermissions,
    setGrantedPermissions,
  ] = useState<GrantedPermissions | null>(null);

  const [storageDeps, setStorageDeps] = useState<StorageDeps | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callInitStorage = useCallback(
    debounce(
      async (
        localSettingsRepo: LocalSettingsRepo,
        localDb: RxDatabase<MyDatabaseCollections>
      ) => {
        if (userInfo) {
          setLoading(false);
          return;
        }
        await initStorage(localSettingsRepo, localDb)
          .then(setStorageDeps)
          .then(() => setLoading(false));
      },
      100
    ),
    []
  );

  useEffect(() => {
    callInitStorage(localSettingsRepo, localDb);
  }, [callInitStorage, localDb, localSettingsRepo]);

  const reloadGrantedPermissions = useCallback(async () => {
    if (storageDeps) {
      const askedForPermissions = await storageDeps.localSettingsRepo.getAskedForPermissions();
      setGrantedPermissions(askedForPermissions);
    }
  }, [storageDeps]);

  const recordGrantedPermissions = useCallback(
    async (value: GrantedPermissions) => {
      if (storageDeps) {
        await storageDeps.localSettingsRepo.saveAskedForPermissions(value);
        await reloadGrantedPermissions();
      }
    },
    [reloadGrantedPermissions, storageDeps]
  );

  useEffect(() => {
    (async (): Promise<void> => {
      await reloadGrantedPermissions();
      // const check = await Badge.hasPermission();
      // if (!check) {
      //   setGrantedPermissions({ badge: false });
      // }
    })();
  }, [reloadGrantedPermissions]);

  // todo
  // useEffect(() => {
  //   refreshUserId().then(() => setLoading(false));
  // }, [refreshUserId]);

  if (loading) {
    return <IonLoading isOpen={loading} message={'Initializing...'} />;
  }

  if (loadingUserInfo) {
    return <IonLoading isOpen={loadingUserInfo} message={'Authenticating...'} />;
  }

  if (reLoginRequired) {
    return (
      <IonCard
        style={{
          width: '400px',
          margin: '30px auto',
        }}
      >
        <IonCardHeader>
          <IonCardTitle>Welcome back! 👋</IonCardTitle>
          <IonCardSubtitle>
            It&apos;s been a while, it&apos;s nice to see you! We have something new
            for you. Check it out!
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <IonButton
            color="success"
            onClick={() =>
              loginWithPopup(undefined, { timeoutInSeconds: AUTH_TIMEOUT_SEC })
            }
          >
            Continue to the app
            <IonIcon slot="end" icon={arrowForward} />
          </IonButton>
        </IonCardContent>
      </IonCard>
    );
  }

  if (userIdConflict) {
    return (
      <IonCard
        style={{
          width: '400px',
          margin: '30px auto',
        }}
      >
        <IonCardHeader>
          <IonCardTitle>You previously signed in as a different user</IonCardTitle>
          <IonCardSubtitle>
            Please sign in with the account you used before, or set up the app from
            scratch
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <IonButton
            color="primary"
            onClick={() => {
              logout({ returnTo: window.location.origin });
              // loginWithPopup(undefined, { timeoutInSeconds: AUTH_TIMEOUT_SEC });
            }}
          >
            Log in as different user
            <IonIcon slot="end" icon={arrowForward} />
          </IonButton>
          <IonButton
            color="danger"
            onClick={async () => {
              const storage: StorageDeps =
                storageDeps || (await initStorage(localSettingsRepo, localDb));
              await storage.eraseAll();
              logout({ returnTo: window.location.origin });
            }}
          >
            Set up from scratch
            <IonIcon slot="end" icon={arrowForward} />
          </IonButton>
        </IonCardContent>
      </IonCard>
    );
  }

  // if user id is set, proceed with the main view
  // otherwise, show initial set up screen
  if (!userInfo?.setUpAt) {
    return <FreshStartApp />;
  } else {
    if (!storageDeps || !grantedPermissions) {
      return (
        <IonLoading isOpen={!storageDeps} message={'Initializing Storage...'} />
      );
    }
    if (grantedPermissions && morePermissionsToCheck(grantedPermissions)) {
      return (
        <PermissionsWalkthrough
          grantedPermissions={grantedPermissions}
          onComplete={recordGrantedPermissions}
        />
      );
    }
    return (
      <StorageProvider storageDeps={storageDeps}>
        <SubscriptionStatusProvider>
          <MainApp />
        </SubscriptionStatusProvider>
      </StorageProvider>
    );
  }
};

export default App;
