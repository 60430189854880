const schema = {
  keyCompression: false,
  title: 'context schema',
  version: 0,
  description: 'describes a context',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    name: {
      type: 'string',
    },
    position: {
      type: 'number',
    },
    contextId: {
      type: 'string',
    },
    createdAt: {
      type: 'number',
      final: true,
    },
    lastUpdated: {
      type: 'number',
    },
    serverUpdatedAt: {
      type: 'number',
    },
    // userId: {
    //   ref: 'task',
    //   type: 'string',
    // },
    last_pulled_rev: {
      type: 'string',
    },
    deviceId: {
      type: 'string',
    },
    data: {
      type: 'object',
    },
  },
  required: ['name'],
  // encrypted: ['secret'],
  // attachments: {
  //   encrypted: true
  // },
  indexes: ['id', 'name'],
};

export default schema;
