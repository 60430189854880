import {
  BlockFields,
  BlockKind,
  BlockMinValue,
  BlockType,
  PartialUpdatedRecord,
} from '../types/CoreTypes';
import { BaseRepo } from './BaseRepo';

export default class BlockRepo extends BaseRepo<BlockFields, undefined> {
  async create(
    args: Omit<BlockType, 'id' | 'lastUpdated' | 'createdAt'>
  ): Promise<string> {
    const entity = {
      ...args,
      lastUpdated: new Date().getTime(),
      createdAt: new Date().getTime(),
    };
    return this.dao.create(entity);
  }

  findAll$(selector?: { [k in keyof BlockType]?: BlockType[k] }) {
    return this.dao.findAll$(selector || {});
  }

  findAll(selector?: { [k in keyof BlockType]?: BlockType[k] }) {
    return this.dao.findAll({ selector: selector || {} });
  }

  async insertManyWithValuesAtAndBelow(
    selector: { [k in keyof BlockType]?: BlockType[k] } | undefined,
    contextId: string,
    position: number,
    values: BlockMinValue[]
  ) {
    // assumes that in-mem DB only has blocks for this task
    // const allBlocks = await this.dao.getAll();
    const allBlocks = await this.dao.findAll({ selector: selector || {} });

    await Promise.all(
      values.slice(1).map((value, idx) =>
        this.create({
          ...selector,
          contextId,
          content: value.value,
          position: position + idx + 1,
          indent: 0,
          type: value.type,
        })
      )
    );

    const thisPosChangeSet = allBlocks
      .filter((r) => r.position === position)
      .map((r) => ({
        id: r.id,
        position: r.position,
        content: values[0].value,
        type: values[0].value,
      }));

    // sorted by pos asc
    const changeSets = allBlocks
      .filter((r) => r.position > position)
      .map((r) => ({ id: r.id, position: r.position + values.length }));

    await this.dao.editMany([...thisPosChangeSet, ...changeSets]);
  }

  async insertAfter(
    selector: { [k in keyof BlockType]?: BlockType[k] } | undefined,
    contextId: string,
    position: number,
    type?: BlockKind,
    indent?: number
  ) {
    // todo reuse with the above?
    // assumes that in-mem DB only has blocks for this task
    // const allBlocks = await this.dao.getAll();
    const allBlocks = await this.dao.findAll({ selector: selector || {} });

    // sorted by pos asc
    const changeSets = allBlocks
      .filter((r) => r.position > position)
      .map((r) => ({ id: r.id, position: r.position + 1 }));

    await this.dao.editMany(changeSets, [
      {
        ...selector,
        contextId,
        content: '',
        position: position + 1,
        indent: indent || 0,
        type: type || 'text',
      },
    ]);
  }

  async swap(
    selector: { [k in keyof BlockType]?: BlockType[k] } | undefined,
    id: string,
    up: boolean
  ) {
    // assumes that in-mem DB only has blocks for this task
    // const allBlocks = await this.dao.getAll();
    const allBlocks = await this.dao.findAll({ selector: selector || {} });

    const block = allBlocks.filter((r) => r.id === id)[0];
    if (!block) return;

    // sorted by pos asc
    let recordToSwapWith;
    if (up) {
      const blocksBefore = allBlocks.filter((r) => r.position < block.position);
      recordToSwapWith = blocksBefore.length
        ? blocksBefore[blocksBefore.length - 1]
        : undefined;
    } else {
      const blocksAfter = allBlocks.filter((r) => r.position > block.position);
      recordToSwapWith = blocksAfter.length ? blocksAfter[0] : undefined;
    }
    if (recordToSwapWith) {
      await this.dao.editMany([
        { id: recordToSwapWith.id, position: block.position },
        {
          id: block.id,
          position: up ? block.position - 1 : block.position + 1,
        },
      ]);
    }
  }

  _forceCreate(doc: BlockType) {
    return this.dao._forceCreate(doc);
  }

  async edit(entity: PartialUpdatedRecord<BlockType>): Promise<void> {
    return this.dao.edit(entity);
  }

  async delete(id: string): Promise<void> {
    return this.dao.delete(id);
  }

  async getById(id: string): Promise<BlockType | null> {
    return this.dao.getById(id);
  }

  async getAll(): Promise<BlockType[]> {
    return this.dao.getAll();
  }
}
