import React from 'react';
import { IonIcon } from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import { useHelp } from '../helpers/HelpProvider';
import { useLocation } from 'react-router';

const HelpButton = () => {
  const { triggerHelpPopup } = useHelp();
  const { pathname } = useLocation();

  return (
    <IonIcon
      onClick={() => triggerHelpPopup(pathname)}
      style={{ fontSize: '24px', padding: '10px', cursor: 'pointer' }}
      icon={helpCircleOutline}
    />
  );
};

export default HelpButton;
