import React, { useCallback, useEffect, useState } from 'react';
import { useStorage } from '../../helpers/StorageProviders';
import {
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProject,
  TaskEnrichedWithProjectAndTags,
  TaskFilter,
  TaskPositionContextEnum,
  TaskStatus,
} from '../../types/CoreTypes';
import {
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
} from '@ionic/react';
import TaskListElementMobile from './TaskListElementMobile';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import { useDate } from '../../helpers/DateProvider';

interface Props {
  filter: TaskFilter;
}

// eslint-disable-next-line react/display-name
const ReviewTaskListView: React.FunctionComponent<Props> = (props: Props) => {
  const [tasks, setTasks] = useState<
    (TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats)[]
  >([]);
  const {
    repos: { taskRepo },
  } = useStorage();
  const { context } = useWorkspace();

  const { dateFormatted } = useDate();

  const loadData = useCallback(
    async function loadData() {
      taskRepo
        .getAllForFilter(
          context.id,
          props.filter,
          TaskPositionContextEnum.STATUS,
          dateFormatted
        )
        .then(setTasks);
    },
    [dateFormatted, props.filter, context.id, taskRepo]
  );

  useEffect(() => {
    loadData();
  }, [props.filter, loadData]);

  const updateState = (selectedTask: TaskEnrichedWithProject | null) => {
    if (!selectedTask) return;
    return taskRepo.edit({
      id: selectedTask.id,
      status: TaskStatus.STATUS_ACTION_LIST,
    });
    // .then(loadData);
  };

  return (
    <IonList>
      {tasks.map((task) => (
        <IonItemSliding key={task.id}>
          <TaskListElementMobile task={task} />

          <IonItemOptions side="start">
            <IonItemOption color="success" onClick={() => updateState(task)}>
              CHOOSE
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
    </IonList>
  );
};

export default ReviewTaskListView;
