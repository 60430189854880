import { TimeStampable } from '@todo/common';

export function sortByProp<T, K extends keyof T>(
  a: T,
  b: T,
  c: K,
  dir: 'asc' | 'desc'
): number {
  if (a[c] === b[c]) return 0;
  const multiplier = dir === 'asc' ? -1 : 1;
  return a[c] > b[c] ? -1 * multiplier : 1 * multiplier;
}

export const sortByLastUpdated = (a: TimeStampable, b: TimeStampable): number => {
  if (a.lastUpdated === b.lastUpdated) {
    return sortByProp(a, b, 'createdAt', 'desc');
  }
  return a.lastUpdated > b.lastUpdated ? -1 : 1;
};

export const sortByCreated = (a: TimeStampable, b: TimeStampable): number => {
  if (a.createdAt === b.createdAt) {
    return sortByProp(a, b, 'lastUpdated', 'desc');
  }
  return a.createdAt > b.createdAt ? -1 : 1;
};
