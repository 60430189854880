import React, { useCallback, useEffect, useState } from 'react';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import subDays from 'date-fns/subDays';
import { groupBy, last } from 'lodash';
import { RxChangeEvent } from 'rxdb/dist/types/core';
import { DataPoint, ItemType } from '../../types/CoreTypes';
import WideMessage from '../Common/WideMessage';
import { colors } from '../../helpers/styles';
import {
  chevronForwardCircle,
  ellipsisHorizontalCircle,
  medal,
  rocket,
  thumbsUp,
} from 'ionicons/icons';
import { format } from 'date-fns';
import DailyHistogram from '../Common/DailyHistogram';
import { isoDaysShort } from '../../lib/DateHelper';
import { useDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';

function getMessageAndIcon(
  completed: number,
  goal: number
): { message: string; icon: string } {
  const diff = completed / goal;
  if (diff > 2) {
    return {
      icon: medal,
      message: `You completed ${completed} tasks today! You're killing it, it's more than 2x your goal!!`,
    };
  }
  if (diff > 1.5) {
    return {
      icon: rocket,
      message: `You completed ${completed} tasks today, great job going way over your goal!`,
    };
  }
  if (diff >= 1) {
    return {
      icon: thumbsUp,
      message: `You completed ${completed} tasks today, you hit your goal!`,
    };
  }
  if (diff > 0) {
    return {
      icon: ellipsisHorizontalCircle,
      message: `You completed ${completed} ${
        completed === 1 ? 'task' : 'tasks'
      } today. A little more and you will hit your goal!`,
    };
  }
  return {
    icon: chevronForwardCircle,
    message: 'Nothing completed today yet, but you still have time!',
  };
}

const StatsCompletedToday = () => {
  const {
    subscribeForDbChanges,
    repos: { taskRepo },
  } = useStorage();
  const { context } = useWorkspace();

  const { dateFormatted, date } = useDate();

  const [dataPoints, setDataPoints] = useState<DataPoint[] | undefined>(undefined);

  const loadData = useCallback(
    function loadData() {
      logDebug([], 'load data: StatsCompletedToday');
      const today = date;
      taskRepo
        .getAllForFilterWithoutSort(
          context.id,
          {
            completedAt: {
              from: subDays(startOfDay(today), 6).getTime(),
              to: endOfDay(today).getTime(),
            },
          },
          dateFormatted
        )
        .then((resx) =>
          groupBy(resx, (r) => (r.completedAt ? format(r.completedAt, 'i') : null))
        )
        .then((d) =>
          Object.entries(d).map(
            ([key, tasks]) => [parseInt(key), tasks.length] as [number, number]
          )
        )
        .then((s) => new Map(s))
        .then((counts) => {
          const dow = parseInt(format(today, 'i')); // 1 ... 7
          const pastDays: number[] = [];
          for (let diff = 6; diff >= 0; diff--) {
            let newDow = dow - diff;
            if (newDow <= 0) {
              newDow = 7 + newDow;
            }
            pastDays.push(newDow);
          }
          const dataPoints = pastDays.map((d) => ({
            label: isoDaysShort[d - 1],
            value: counts.get(d) || 0,
          }));
          setDataPoints(dataPoints);
        });
    },
    [date, taskRepo, context.id, dateFormatted]
  );

  useEffect(() => {
    function handleDbChangeEvent(evt: RxChangeEvent) {
      if (evt.operation === 'UPDATE') {
        if ([ItemType.task].includes(evt.collectionName as ItemType)) {
          loadData();
        }
      }
    }

    const sub = subscribeForDbChanges(handleDbChangeEvent);
    return () => {
      sub.unsubscribe();
    };
  }, [loadData, subscribeForDbChanges]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // means it's loading
  if (dataPoints === undefined) return null;
  return <StatsCompletedTodayView dataPoints={dataPoints} />;
};

interface ViewProps {
  dataPoints: DataPoint[];
}

// function calcMedian(arr: number[]) {
//   const half = Math.floor(arr.length / 2);
//
//   arr.sort(function(a, b) {
//     return a - b;
//   });
//
//   if (arr.length % 2) {
//     return arr[half];
//   } else {
//     return (arr[half - 1] + arr[half]) / 2.0;
//   }
// }

function calcAvg(arr: number[]) {
  const avg = arr.reduce((acc, v) => acc + v, 0) / arr.length;
  return Math.floor(avg);
}

const StatsCompletedTodayView = ({ dataPoints }: ViewProps) => {
  const todaysCounts = last(dataPoints)?.value || 0;
  const dailyVals = dataPoints.map((d) => d.value);

  const goal = calcAvg(dailyVals) || 1;
  const { message, icon } = getMessageAndIcon(todaysCounts, goal);

  const hit = dailyVals.filter((v) => v >= goal).length;
  const goalText = `Your auto set goal is ${goal}. You hit it ${hit} times in the past 7 days;`;

  return (
    <WideMessage
      subText={goalText}
      text={message}
      icon={icon}
      color={colors.primary}
    >
      <DailyHistogram
        color={colors.primary}
        dataPoints={dataPoints}
        overlayLine={goal}
      />
    </WideMessage>
  );
};

export default StatsCompletedToday;
