import { AchievementKind, ItemType, TaskStatus, TaskType } from '@todo/common';
import { some } from 'lodash';
import deepEqual from 'fast-deep-equal';
import { Subject, Subscription } from 'rxjs';
import {
  formatDateWithoutTime,
  taskIsRepeatedScheduledOnDate,
} from '../helpers/RecurringHelper';
import { CountersChangeEvent, ItemEditEvent } from './data/DaoTypes';
import { Repos } from '../helpers/InitStorage';
import { TaskStatusKey } from '../helpers/TaskHelper';
import { debouncedByArguments } from '../helpers/func';
import {
  getTaskAge,
  TaskAge,
  taskTooLongInStatus,
} from '../helpers/productivityHelper';
import { logDebug } from './logger';

interface DelayedStatusStateValue {
  lastUpdateEvent?: {
    taskId: string;
    timestamp: number;
  };
  lastCounter?: { timestamp: number; count: number };
}

export class AchievementsProcessor {
  private static instance: AchievementsProcessor;
  private repos: Repos;

  private delayedStatusState: {
    [key1: string]: {
      [key2 in TaskStatusKey]?: DelayedStatusStateValue;
    };
  } = {};

  static getInstance(
    repos: Repos,
    itemEditEventSubject: Subject<ItemEditEvent>,
    countersChangeEventSubject: Subject<CountersChangeEvent>
  ): AchievementsProcessor {
    if (!AchievementsProcessor.instance) {
      AchievementsProcessor.instance = new AchievementsProcessor(repos);
    }

    AchievementsProcessor.instance.resubscribe(
      repos,
      itemEditEventSubject,
      countersChangeEventSubject
    );
    return AchievementsProcessor.instance;
  }

  private itemEditEventSubscription?: Subscription;
  private countersChangeEventSubscription?: Subscription;

  private resubscribe(
    repos: Repos,
    itemEditEventSubject: Subject<ItemEditEvent>,
    countersChangeEventSubject: Subject<CountersChangeEvent>
  ) {
    if (this.itemEditEventSubscription) {
      this.itemEditEventSubscription.unsubscribe();
    }
    if (this.countersChangeEventSubscription) {
      this.countersChangeEventSubscription.unsubscribe();
    }
    this.repos = repos;
    this.itemEditEventSubscription = itemEditEventSubject.subscribe({
      next: this.processAchievementEvent,
    });
    this.countersChangeEventSubscription = countersChangeEventSubject.subscribe({
      next: this.processCountersChangeEvent,
    });

    // .pipe(filter((e) => e.type === ItemType.task))
    // .subscribe({
    //   next: (event) =>
    //     this.processAchievementEventTask(
    //       (event as unknown) as ItemEditEvent<TaskType>
    //     ),
    // });
  }

  private constructor(repos: Repos) {
    // singleton
    this.repos = repos;
  }
  //
  // private countFocus(contextId: string): Promise<number> {
  //
  // }
  //
  // private countNext(contextId: string): Promise<number> {
  //
  // }

  private updateDelayedStatusState = (
    contextId: string,
    status: TaskStatusKey,
    value: DelayedStatusStateValue
  ) => {
    if (!this.delayedStatusState[contextId]) {
      this.delayedStatusState[contextId] = {};
    }
    logDebug([], 'updateDelayedStatusState', { contextId, status });
    const currentValue = this.delayedStatusState[contextId][status] || {};

    this.delayedStatusState[contextId][status] = {
      ...currentValue,
      ...value,
    };
    // setTimeout(() => {
    this.reconcileCountersDebounced(contextId, status);
    // }, 5000);
  };

  private processCountersChangeEvent = (event: CountersChangeEvent) => {
    if (
      [TaskStatusKey.Next, TaskStatusKey.Inbox, TaskStatusKey.InProgress].includes(
        event.status
      )
    ) {
      this.updateDelayedStatusState(event.contextId, event.status, {
        lastCounter: {
          timestamp: new Date().getTime(),
          count: event.count,
        },
      });
    }
  };

  private _reconcileCounters = (contextId: string, status: TaskStatusKey) => {
    const value = this.delayedStatusState[contextId][status];
    if (!value) {
      console.error(
        `should not happen: reconcileCounters cannot find value for ${contextId}, ${status}`
      );
      return;
    }

    const { lastCounter, lastUpdateEvent } = value;
    logDebug([], 'reconcileCounters', { contextId, status });

    if (!lastCounter || !lastUpdateEvent) {
      logDebug([], `no counter or event for ${contextId}, ${status}`);
      return;
    }
    const now = new Date().getTime();

    // if both were updated recently
    if (
      now - lastUpdateEvent.timestamp < 10000 &&
      now - lastCounter.timestamp < 10000
    ) {
      if (lastCounter.count === 0) {
        switch (status) {
          case TaskStatusKey.Inbox:
            this.issueAchievement(AchievementKind.clearedInbox, contextId);
            break;
          case TaskStatusKey.Next:
            this.issueAchievement(AchievementKind.clearedNext, contextId);
            break;
          case TaskStatusKey.InProgress:
            this.issueAchievement(AchievementKind.clearedFocus, contextId);
            break;
          default:
            break;
        }
      }
    }
  };

  private reconcileCountersDebounced = debouncedByArguments(
    this._reconcileCounters,
    5000
  );

  private issueAchievement = async (
    type: AchievementKind,
    contextId: string,
    objectIds?: { taskId?: string }
  ) => {
    await this.repos.achievementRepo.create({
      type,
      contextId,
      taskId: objectIds?.taskId,
    });
  };

  private handleUpdateEventWithCount = (
    contextId: string,
    status: TaskStatusKey,
    itemId: string
  ) => {
    logDebug([], 'handleUpdateEventWithCount', { contextId, status });
    if (
      [TaskStatusKey.Next, TaskStatusKey.Inbox, TaskStatusKey.InProgress].includes(
        status
      )
    ) {
      logDebug([], ' handleUpdateEventWithCount proceeds');
      this.updateDelayedStatusState(contextId, status, {
        lastUpdateEvent: {
          timestamp: new Date().getTime(),
          taskId: itemId,
        },
      });
    }
  };

  private processAchievementEvent = async (event: ItemEditEvent): Promise<void> => {
    if (event.type === ItemType.task) {
      return this.processAchievementEventTask(
        (event as unknown) as ItemEditEvent<TaskType>
      );
    }
  };

  private anyFieldsChanged<K>(
    changeSet: Partial<K>,
    recordBefore: K,
    fields: (keyof K)[]
  ) {
    return some(fields, (field) =>
      this.fieldChanged(changeSet, recordBefore, field)
    );
  }

  private fieldChanged<K>(
    changeSet: Partial<K>,
    recordBefore: K,
    field: keyof K
  ): boolean {
    return changeSet[field] && !deepEqual(changeSet[field], recordBefore[field]);
  }

  private processAchievementEventTask = async (
    event: ItemEditEvent<TaskType>
  ): Promise<void> => {
    const contextId = event.recordBefore.contextId;
    const itemId = event.recordBefore.id;
    const now = new Date();

    if (
      event.changeSet.status &&
      event.recordBefore.status !== event.changeSet.status
    ) {
      // status change
      // DONE vv
      if (event.changeSet.status === TaskStatus.STATUS_DONE) {
        // completed

        let completedDueToday = false;
        // let completedFocusTab = false;

        // counting completed
        this.issueAchievement(AchievementKind.completedTask, contextId, {
          taskId: itemId,
        });
        // may have multiple levels, 1, 5, 10, etc
        // + more than yesterday, most in the week/month

        const formattedNow = formatDateWithoutTime(now);
        if (event.recordBefore.dateDue) {
          const dueFormatted = event.recordBefore.dateDue;
          if (dueFormatted === formattedNow) {
            completedDueToday = true;
          }
          // if (dueFormatted <= formattedNow) {
          //   completedFocusTab = true;
          // }
        }
        if (event.recordBefore.dateScheduled) {
          const dueFormatted = event.recordBefore.dateScheduled;
          if (dueFormatted === formattedNow) {
            completedDueToday = true;
          }
          // if (dueFormatted <= formattedNow) {
          //   completedFocusTab = true;
          // }
        }
        if (completedDueToday) {
          // completing at due date
          this.issueAchievement(AchievementKind.completedAtDueDate, contextId, {
            taskId: itemId,
          });
        }
        // if (completedFocusTab) {
        //   // clearing the focus tab, check it's len
        //   this.handleUpdateEventWithCount(
        //     contextId,
        //     TaskStatusKey.InProgress,
        //     itemId
        //   );
        // }

        const taskAge = getTaskAge(event.recordBefore, new Date());
        if (taskAge === TaskAge.ancient) {
          // completing old tasks
          this.issueAchievement(AchievementKind.completedAncientTask, contextId, {
            taskId: itemId,
          });
        } else if (taskAge === TaskAge.old) {
          // completing ancient tasks
          this.issueAchievement(AchievementKind.completedOldTask, contextId, {
            taskId: itemId,
          });
        }
      }
      // ^^ end DONE

      if (event.recordBefore.status === TaskStatus.STATUS_IN_PROGRESS) {
        this.handleUpdateEventWithCount(contextId, TaskStatusKey.InProgress, itemId);
      }
      if (event.recordBefore.status === TaskStatus.STATUS_INBOX) {
        // inbox change
        this.handleUpdateEventWithCount(contextId, TaskStatusKey.Inbox, itemId);
      }
      if (event.recordBefore.status === TaskStatus.STATUS_ACTION_LIST) {
        if (taskTooLongInStatus(event.recordBefore, new Date())) {
          // cleared old task from next
          this.issueAchievement(AchievementKind.movedOldTaskFromNext, contextId, {
            taskId: itemId,
          });
        }
      }
      if (
        event.recordBefore.status === TaskStatus.STATUS_ACTION_LIST &&
        !event.recordBefore.projectId
        // || event.changeSet.status === TaskStatus.STATUS_ACTION_LIST
      ) {
        // count next size
        this.handleUpdateEventWithCount(contextId, TaskStatusKey.Next, itemId);
      }
      // ^^ end STATUS CHANGE
    } else {
      if (event.recordBefore.recurringOptions && event.changeSet.completedAt) {
        const due = taskIsRepeatedScheduledOnDate(now, event.recordBefore);
        if (
          due.dueDate &&
          formatDateWithoutTime(now) ===
            formatDateWithoutTime(event.changeSet.completedAt)
        ) {
          if (formatDateWithoutTime(due.dueDate) === formatDateWithoutTime(now)) {
            this.issueAchievement(AchievementKind.completedAtDueDate, contextId, {
              taskId: itemId,
            });
          }
          this.issueAchievement(AchievementKind.completedTask, contextId, {
            taskId: itemId,
          });
        }
      }
      // if (
      //   this.anyFieldsChanged(event.changeSet, event.recordBefore, [
      //     'dateDue',
      //     'dateScheduled',
      //     'recurringOptions',
      //   ])
      // ) {
      //   // clearing the focus tab, check it's len
      //   this.handleUpdateEventWithCount(contextId, TaskStatusKey.InProgress, itemId);
      // }
      if (
        this.anyFieldsChanged(event.changeSet, event.recordBefore, [
          'time',
          'energy',
          'priorityValues',
          'tags',
          'projectId',
        ])
      ) {
        // classification change
        this.issueAchievement(AchievementKind.sortOutTasks, contextId, {
          taskId: itemId,
        });
      }
    }
  };
  /*
  [.] clearing focus
  [.] clearing all due today, scheduled for today, repeating for today, and all focus (leaving query for focus empty)
    - if due, scheduled, repeat are in today or in the past, and query for focus is empty now
  [.] keep next small
    - on status change when in next?
      - changed status of task in next
      - especially if task in next hasn't been updated in a while
        - especially if time of status change is old - record that?
        - last updated can be changed by ordering etc
      - if after status change length of next is small
        - give a medal if at least once a day the len of next was < THRESHOLD?
  - adding to inbox
   - don't need?
  [.] clearing inbox
  [.] completing repeating / scheduled tasks on the first day
  [.] setting time, energy etc
  [.] completing old tasks
  [.] completing ancient tasks (6+ months old)
  [.] completing X tasks in a day
    - dynamically compute? or have achievement for every completed one?
    - already have index on completed at :)
    - check it on task completion!

  - how to make streaks work? capture failures to do it as well?

  for some, set a goal of how many this kind of actions I want to achieve?
  - or no, just keep it simple

  a score in each aspect and try to improve each aspect?
   */
}
