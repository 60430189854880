import {
  IonBadge,
  IonContent,
  IonIcon,
  IonLabel,
  IonRouterLink,
  IonSegment,
  IonSegmentButton,
  IonToolbar,
} from '@ionic/react';
import React from 'react';
import { ProjectPositionContextEnum } from '../../types/CoreTypes';
import ProjectListView from './ProjectListView';
import { RouteComponentProps, withRouter } from 'react-router';
import { withStorage } from '../../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import QuickAdd, { QuickAddAPI } from '../Common/QuickAdd';
import { StorageDeps } from '../../helpers/InitStorage';
import CommonHeader from '../Common/CommonHeader';
import { CountersProps, withCounters } from '../../helpers/CountersProvider';
import { projectStatuses, ProjectStatusKey } from '../../helpers/ProjectHelper';
import QuickAddFormProject from './QuickAddFormProject';
import ListNavigation from '../Common/ListNavigation';
import ProjectStatusListDesktop from './ProjectStatusListDesktop';
import WideMessage from '../Common/WideMessage';
import { warning } from 'ionicons/icons';
import { colors } from '../../helpers/styles';
import { ClientTypeProps, withClientType } from '../../helpers/ClientTypeProvider';
import { Routes } from '../../lib/routes';

interface State {
  key: ProjectStatusKey;
}

interface Props
  extends RouteComponentProps,
    StorageDeps,
    WorkspaceProps,
    CountersProps,
    ClientTypeProps {}

const statuses = Object.entries(projectStatuses)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .filter(([_, v]) => v.showInList);

const statusesNames = statuses.map(([key]) => key as ProjectStatusKey);

class Projects extends React.Component<Props, State> {
  state: State = { key: ProjectStatusKey.Action };

  getCount(statusKey: ProjectStatusKey): number | undefined {
    const counters = this.props.counters[this.props.context.id];
    if (!counters) return undefined;
    return counters.projectSources[statusKey]?.size;
  }

  renderBlock(statusKey: ProjectStatusKey) {
    const filter = projectStatuses[statusKey].filter;
    if (!filter) {
      throw new Error(
        'misconfiguration: project status used in filter that does not have a filter'
      );
    }
    return (
      <>
        <ProjectListView
          positionContext={ProjectPositionContextEnum.STATUS}
          filter={filter}
        />
      </>
    );
  }

  // renderList(statusKey: ProjectStatusKey) {
  //   const status = projectStatuses[statusKey];
  //   const count = this.getCount(statusKey) || 0;
  //   return (
  //     <>
  //       <IonListHeader>
  //         <span>
  //           <IonIcon icon={status.icon} />
  //           &nbsp;
  //           <span>{status.label}</span>
  //           &nbsp;
  //           <IonBadge>{count}</IonBadge>
  //         </span>
  //       </IonListHeader>
  //       <div>{this.renderBlock(statusKey)}</div>
  //     </>
  //   );
  // }

  renderQuickAddForm = ({ onClose, ref, onSave, isOpen }: QuickAddAPI) => {
    const {
      repos: { projectRepo, blockRepo },
      context,
    } = this.props;
    return (
      <QuickAddFormProject
        isOpen={isOpen}
        onClose={onClose}
        daoCatalog={this.props._techDebt_daoCatalog}
        onSave={(args) =>
          projectRepo
            .create({ ...args, contextId: context.id })
            .then((projectId) => {
              if (args.blocks) {
                return Promise.all(
                  args.blocks.map((block) => {
                    return blockRepo.create({
                      ...block,
                      projectId,
                      contextId: context.id,
                    });
                  })
                );
              }
            })
            .then(onSave)
        }
        ref={ref}
      />
    );
  };

  renderEmptyMessage(key: ProjectStatusKey | undefined = undefined) {
    const total = statuses.reduce((acc, [itemKey, params]) => {
      const count = this.getCount(itemKey as ProjectStatusKey) || 0;
      return acc + count;
    }, 0);
    if (total > 0) {
      if (key && this.getCount(key) === 0) {
        return (
          <WideMessage
            text="No projects with this status yet, but you can check other statuses."
            icon={warning}
            color={colors.grayOut}
          />
        );
      }
      return null;
    }
    return (
      <WideMessage
        text="No projects here yet. New projects will appear here"
        icon={warning}
        color={colors.grayOut}
      />
    );
  }

  renderDesktop() {
    return (
      <>
        {this.renderHeader()}
        <IonContent>
          <QuickAdd form={this.renderQuickAddForm}>
            <ListNavigation collections={statusesNames}>
              {(listNavigationApi) => (
                <ProjectStatusListDesktop
                  statuses={statusesNames}
                  listNavigationApi={listNavigationApi}
                />
              )}
            </ListNavigation>
            {this.renderEmptyMessage()}
          </QuickAdd>
        </IonContent>
      </>
    );
  }

  renderHeader = () => {
    return (
      <CommonHeader>
        {/*Projects.{' '}*/}
        <span style={{ fontSize: 14 }}>
          Switch to{' '}
          <IonRouterLink routerLink={`${Routes.gantt}`} routerDirection={'root'}>
            Timeline view
          </IonRouterLink>
        </span>
      </CommonHeader>
    );
  };

  renderMobile() {
    const { key } = this.state;
    return (
      <>
        {this.renderHeader()}
        <IonContent>
          <IonToolbar>
            <IonSegment
              value={key}
              onIonChange={(e) =>
                e.detail.value &&
                this.setState({ key: e.detail.value as ProjectStatusKey })
              }
            >
              {statuses.map(([itemKey, params], idx) => (
                <IonSegmentButton key={`segment-${idx}`} value={itemKey}>
                  <IonBadge>{this.getCount(itemKey as ProjectStatusKey)}</IonBadge>
                  <IonIcon icon={params.icon} />
                  <IonLabel>{params.label}</IonLabel>
                </IonSegmentButton>
              ))}
            </IonSegment>
          </IonToolbar>
          <QuickAdd form={this.renderQuickAddForm}>
            {this.renderBlock(key)}
            {this.renderEmptyMessage(key)}
          </QuickAdd>
        </IonContent>
      </>
    );
  }

  render() {
    return <>{this.props.isDesktop ? this.renderDesktop() : this.renderMobile()}</>;
  }
}

export default withClientType(
  withCounters(withRouter(withStorage(withWorkspace(Projects))))
);
