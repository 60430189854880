import React from 'react';
import TagListView from './TagListView';
import QuickAdd from '../Common/QuickAdd';
import TagEditWrapper from './TagEditWrapper';
import ListNavigation from '../Common/ListNavigation';
import WrapForCollectionBasedCallbacks from '../Common/WrapForCollectionBasedCallbacks';
import QuickAddFormTag from './QuickAddFormTag';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';

const COLLECTION_NAME = 'tags';

const Tags: React.FunctionComponent = () => {
  const {
    _techDebt_daoCatalog,
    repos: { blockRepo },
  } = useStorage();
  const { context } = useWorkspace();

  return (
    <TagEditWrapper>
      {({ createTag, updateTag, deleteTag }) => (
        <QuickAdd
          form={({ onClose, ref, onSave, isOpen }) => (
            <QuickAddFormTag
              onClose={onClose}
              daoCatalog={_techDebt_daoCatalog}
              isOpen={isOpen}
              onSave={(p) =>
                createTag(p)
                  .then((tagId) => {
                    if (p.blocks) {
                      return Promise.all(
                        p.blocks.map((block) => {
                          return blockRepo.create({
                            ...block,
                            tagId,
                            contextId: context.id,
                          });
                        })
                      );
                    }
                  })
                  .then(onSave)
              }
              ref={ref}
            />
          )}
        >
          <ListNavigation collections={[COLLECTION_NAME]}>
            {(listNavigationApi) => (
              <WrapForCollectionBasedCallbacks
                setCounter={listNavigationApi.setCounter}
                collectionName={COLLECTION_NAME}
              >
                {({ setCounter }) => (
                  <TagListView
                    parentId={null}
                    selectedIdx={listNavigationApi.selectedCollectionIdx}
                    setCounter={setCounter}
                    getRef={
                      listNavigationApi?.getRef
                        ? listNavigationApi.getRef(COLLECTION_NAME)
                        : undefined
                    }
                    onChangeTag={updateTag}
                    onDeleteTag={deleteTag}
                  />
                )}
              </WrapForCollectionBasedCallbacks>
            )}
          </ListNavigation>
        </QuickAdd>
      )}
    </TagEditWrapper>
  );
};

export default Tags;
