import React, { CSSProperties } from 'react';
import linkifyHtml from 'linkifyjs/html';
import sanitizeHtml from 'sanitize-html';
import { debounce } from 'lodash';
import {
  BlockFields,
  BlockKind,
  BlockMinValue,
  BlockType,
  ExcludesNull,
  Identifiable,
} from '../../types/CoreTypes';
import ContentEditable, { ContentEditableEvent } from './Contenteditable';
import { IonIcon } from '@ionic/react';
import { checkbox, ellipse, squareOutline } from 'ionicons/icons';
import { BLOCK_MAX_LENGTH } from '../../lib/Constants';

const INDENT_PIXELS = 20;

const PLACEHOLDERS: { [key in BlockKind]: string } = {
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  text: 'Text',
  bullet: 'Bulleted list',
  checkbox: 'Checkbox',
  code: 'Code block',
  task: 'Task',
};

// type BlockState = 'neutral' | 'selected' | 'editing';
//
// enum BlockStates {
//   NEUTRAL = 'neutral',
//   SELECTED = 'selected',
//   EDITING = 'editing'
// }

// interface CodeProps {
//   language?: string;
// }

const basicStyles: CSSProperties = {
  maxWidth: '100%',
  width: '100%',
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',
  padding: '3px 2px',
};

type Block = BlockType;
type BlockChangeSet = Partial<BlockFields> & Identifiable;

interface PrefixProps {
  block: Block;
  onChange: (value: BlockChangeSet) => void;
}

const BulletPrefix = () => {
  return (
    <IonIcon
      style={{
        verticalAlign: 'middle',
        display: 'inline-block',
        margin: '5px 5px 0px 5px',
        fontSize: '11px',
      }}
      icon={ellipse}
    />
  );
};

const CheckboxPrefix = ({ block, onChange }: PrefixProps) => {
  return (
    <IonIcon
      onClick={(e) => {
        e.preventDefault();
        onChange({
          id: block.id,
          completed: !block.completed,
        });
      }}
      color="primary"
      size="small"
      className="checkbox"
      style={{
        verticalAlign: 'middle',
        display: 'inline-block',
        margin: '5px 5px 0px 5px',
        cursor: 'pointer',
      }}
      icon={block.completed ? checkbox : squareOutline}
    />
  );
};

function getStyles(block: Block): CSSProperties {
  switch (block.type) {
    case 'h1':
      return {
        fontWeight: 'bold',
        fontSize: '20px',
        marginTop: '10px',
        marginBottom: '10px',
      };
    case 'h2':
      return {
        fontWeight: 'bold',
        fontSize: '17px',
        marginTop: '8px',
        marginBottom: '8px',
      };
    case 'h3':
      return {
        fontWeight: 'bold',
        fontSize: '14px',
        marginTop: '6px',
        marginBottom: '6px',
      };
    default:
      return {};
  }
}

function processShortCuts(value: string) {
  const patterns: { pattern: string; type: BlockKind }[] = [
    { pattern: '* ', type: 'bullet' },
    { pattern: '- ', type: 'bullet' },
    { pattern: '[]', type: 'checkbox' },
    { pattern: '# ', type: 'h1' },
    { pattern: '## ', type: 'h2' },
    { pattern: '### ', type: 'h3' },
  ];
  for (const { pattern, type } of patterns) {
    if (value.substring(0, pattern.length) === pattern) {
      return { value: value.substring(pattern.length), type };
    }
  }
  return null;
}

interface BlockProps {
  saveDebounceMs: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  innerRef?: React.RefObject<HTMLElement> | Function;
  onChange: (value: BlockChangeSet) => void;
  onDelete: (id: string) => void;
  onCreateBelow: (position: number, type?: BlockKind, indent?: number) => void;
  onMoveUp: (id: string) => void;
  onMoveDown: (id: string) => void;
  onMoveBlockUp: (id: string) => void;
  onMoveBlockDown: (id: string) => void;
  onFocus: (id: string) => void;
  onBlur: (id: string) => void;
  onInsertManyWithValuesAtAndBelow: (
    position: number,
    values: BlockMinValue[]
  ) => void;
  block: Block;
  supportVirtualKeyboard: boolean;
}

function setCaret(el: any) {
  const range = document.createRange();
  const selection = window.getSelection();
  if (!selection) {
    throw Error('no selection!');
  }
  selection.removeAllRanges();
  range.selectNodeContents(el);
  range.collapse(false);
  selection.addRange(range);
  // el.focus();
}

function caretPositionIndex(selection: any, contenteditableDiv: any): null | number {
  const range = selection.getRangeAt(0);
  const { endContainer, endOffset } = range;

  const countBeforeEnd = countUntilEndContainer(contenteditableDiv, endContainer);
  if (countBeforeEnd.error) return null;
  return countBeforeEnd.count + endOffset;

  function countUntilEndContainer(
    parent: any,
    endNode: any,
    countingState: { count: number; done?: boolean; error?: boolean } = { count: 0 }
  ): { count: number; done?: boolean; error?: boolean } {
    for (const node of parent.childNodes) {
      if (countingState.done) break;
      if (node === endNode) {
        countingState.done = true;
        return countingState;
      }
      if (node.nodeType === Node.TEXT_NODE) {
        countingState.count += node.length;
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        countUntilEndContainer(node, endNode, countingState);
      } else {
        countingState.error = true;
      }
    }
    return countingState;
  }
}

function getTextNodeAtPosition(root: any, index: any) {
  // @ts-ignore
  const treeWalker = document.createTreeWalker(
    root,
    NodeFilter.SHOW_TEXT,
    // @ts-ignore
    function next(elem) {
      if (index >= elem.textContent.length) {
        index -= elem.textContent.length;
        return NodeFilter.FILTER_REJECT;
      }
      return NodeFilter.FILTER_ACCEPT;
    }
  );
  const c = treeWalker.nextNode();
  return {
    node: c ? c : root,
    position: c ? index : 0,
  };
}

const setCursorToPos = (context: any, len: number) => () => {
  const selection = window.getSelection();

  const pos = getTextNodeAtPosition(context, len);
  if (len === context.textContent.length) return;

  if (!selection) {
    throw Error('no selection!');
  }
  selection.removeAllRanges();
  const range = new Range();
  range.setStart(pos.node, pos.position);
  selection.addRange(range);
};

function getCaretPosition(context: any) {
  const selection = window.getSelection();

  if (!selection) {
    throw Error('no selection!');
  }

  return caretPositionIndex(selection, context);
}

function saveCaretPosition(context: any) {
  const selection = window.getSelection();

  if (!selection) {
    throw Error('no selection!');
  }

  const len = caretPositionIndex(selection, context);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  if (len === context.textContent.length) return () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  if (len == null) return () => {};

  return setCursorToPos(context, len);
}

// eslint-disable-next-line react/display-name

function getBlockPrefix(block: Block): React.ElementType<PrefixProps> {
  switch (block.type) {
    case 'bullet':
      return BulletPrefix;
    case 'checkbox':
      return CheckboxPrefix;
    default:
      return () => null;
  }
}

function linkify(text: string) {
  return linkifyHtml(text, {
    defaultProtocol: 'https',
    attributes: (href) => ({
      style: 'cursor: pointer',
      onclick: `window.open('${href}')`,
    }),
  });
}

interface BlockState {
  contentInner: string;
}

class BlockElement extends React.Component<BlockProps, BlockState> {
  readonly state: BlockState; // = { contentInner: '' };
  readonly contentEditable: React.RefObject<HTMLDivElement>;

  constructor(props: BlockProps) {
    super(props);
    this.contentEditable = React.createRef();
    this.focus = this.focus.bind(this);
    this.state = {
      contentInner: linkify(props.block.content),
    };
  }

  focus() {
    const current = this.contentEditable.current;
    if (current) {
      setCaret(current);
    }
  }

  // static getDerivedStateFromProps(props: BlockProps, state: BlockState) {
  //   const linkifiedValue = linkify(props.block.content);
  //   if (state.contentInner != linkifiedValue) {
  //     console.log('$$$ getDerivedStateFromProps', {
  //       state: state.contentInner,
  //       props: linkifiedValue
  //     });
  //     return {
  //       contentInner: linkifiedValue
  //     };
  //   }
  //   return null;
  // }

  // componentDidUpdate(prevProps: BlockProps) {
  //   // when content changed outside
  //   console.log(
  //     '[block sync] componentDidUpdate',
  //     prevProps.block,
  //     this.props.block
  //   );
  //   if (prevProps.block.content !== this.props.block.content) {
  //     console.log('[block sync] content outside changed', {
  //       outside: this.props.block.content,
  //       inside: this.state.contentInner
  //     });
  //     this.syncContentDebounced(this.props.block.content, this.state.contentInner);
  //     // const linkifiedValue = linkify(this.props.block.content);
  //     // if (this.state.contentInner !== linkifiedValue) {
  //     //   console.log('$$$ componentDidUpdate', {
  //     //     state: this.state.contentInner,
  //     //     props: linkifiedValue
  //     //   });
  //     //   this.setState({
  //     //     contentInner: linkifiedValue
  //     //   });
  //     // }
  //   }
  //   // Typical usage (don't forget to compare props):
  //   // if (this.props.userID !== prevProps.userID) {
  //   //   this.fetchData(this.props.userID);
  //   // }
  // }
  //
  // longLinkify = (text: string): Promise<string> => {
  //   console.log('$$$ [block sync] longLinkify');
  //   return new Promise(resolve => {
  //     setTimeout(() => {
  //       resolve(linkify(text));
  //     }, 5000);
  //   });
  // };

  syncContentNotDebounced(outsideContent: string, insideContent: string) {
    // only inside content is linkified
    // const outsideContent = this.props.block.content;
    // const insideContent = this.state.contentInner;
    const linkifiedOutside = linkify(outsideContent);
    if (insideContent !== linkifiedOutside) {
      // console.log('$$$ [block sync] SyncContent not equal', {
      //   insideContent,
      //   linkifiedOutside
      // });
      this.setState({
        contentInner: linkifiedOutside,
      });
    }
  }

  syncContentDebounced = debounce(this.syncContentNotDebounced, 5000);

  shouldComponentUpdate(
    nextProps: Readonly<BlockProps>,
    nextState: Readonly<BlockState>
  ): boolean {
    if (nextProps.block.content !== this.props.block.content) {
      // console.log('[block sync] content outside changed', {
      //   outside: nextProps.block.content,
      //   inside: this.state.contentInner
      // });
      this.syncContentDebounced(nextProps.block.content, this.state.contentInner);
    }
    for (const key of Object.keys(nextProps)) {
      // @ts-ignore
      if (nextProps[key] !== this.props[key]) {
        if (key === 'block') {
          for (const blockKey of Object.keys(nextProps.block)) {
            if (!['position', 'lastUpdated', '_rev'].includes(blockKey)) {
              // @ts-ignore
              if (nextProps.block[blockKey] !== this.props.block[blockKey]) {
                // if (blockKey === 'content') {
                //   console.log('[block sync] content outside changed', {
                //     outside: this.props.block.content,
                //     inside: this.state.contentInner
                //   });
                //   this.syncContentDebounced(
                //     this.props.block.content,
                //     this.state.contentInner
                //   );
                //   // const linkifiedValue = linkify(nextProps.block.content);
                //   // if (this.state.contentInner !== linkifiedValue) {
                //   //   console.log('$$$ changed content:', nextProps.block[blockKey]);
                //   //   return true;
                //   // }
                // } else {
                // console.log('$$$ changed blockKey:', blockKey);
                return true;
                // }
              }
            }
          }
        } else {
          // console.log('$$$ changed key:', key);
          return true;
        }
      }
    }
    if (this.state.contentInner !== nextState.contentInner) {
      // console.log('$$$ changed contentInner');
      return true;
    }
    return false;
  }

  handleChange = (e: ContentEditableEvent) => {
    const { block, onInsertManyWithValuesAtAndBelow } = this.props;

    // strip tags.
    // local state will have link, otherwise cursor position will jump on edit
    // Need to get to the value that will be exactly the same as in state, to
    // avoid re-trigger of onChange.
    // Also, cut to max len
    const value = this.removeLinks(e.target.value).substring(0, BLOCK_MAX_LENGTH);

    const linkifiedValue = linkify(value);

    // if not changed, stop here
    if (this.state.contentInner === linkifiedValue) return;

    this.operationWithSaveOfCursor(() => {
      // if source has new lines, create new bock for each line
      if (value.indexOf('\n') !== -1) {
        let splitValues: string[];
        if (value.indexOf('\r\n') !== -1) {
          splitValues = value.split('\r\n');
        } else {
          splitValues = value.split('\n');
        }
        if (splitValues.length > 0) {
          const validValues = splitValues.filter((value) => value.trim());
          const values = validValues
            .map((v) => {
              const shortcutResult = processShortCuts(v);
              if (shortcutResult) {
                return shortcutResult;
              } else {
                return {
                  type: 'text' as BlockKind,
                  value: v,
                };
              }
            })
            .filter((Boolean as any) as ExcludesNull);
          onInsertManyWithValuesAtAndBelow(block.position, values);
          const first = values[0];
          this.updateContent({
            id: block.id,
            content: first.value,
            type: first.type,
          });
        }
      } else {
        const shortcutResult =
          block.type === 'text' ? processShortCuts(value) : null;
        if (shortcutResult) {
          // handle typing shortcuts, like [], -, *
          this.updateContent({
            id: block.id,
            content: shortcutResult.value,
            type: shortcutResult.type,
          });

          return {
            timeout: 150, // need higher here because in-mem db needs to update
            restoreFn: setCursorToPos(this.contentEditable.current, 0),
          };
        } else {
          // regular update
          this.updateContent({
            id: block.id,
            content: value,
          });
        }
      }

      return {
        timeout: 1, // need very fast changes for regular typing, so it doesn't visually jump
      };
    });
  };

  removeLinks = (txt: string) => {
    return sanitizeHtml(txt, {
      allowedTags: ['b', 'i', 'em', 'strong'],
    });
  };

  callOnChangeDebounced = debounce((changeSet: BlockChangeSet) => {
    this.props.onChange(changeSet);
  }, this.props.saveDebounceMs);

  callOnChangeDirect = (changeSet: BlockChangeSet) => {
    this.callOnChangeDebounced.cancel();
    this.props.onChange(changeSet);
  };

  setContent = (text: string) => {
    const contentInner = linkify(text);
    this.setState({ contentInner });
    // console.log('[block sync] set content', {
    //   outside: this.props.block.content,
    //   inside: contentInner
    // });
    this.syncContentDebounced.cancel();
    // this.syncContentDebounced(this.props.block.content, contentInner);
  };

  // make links clickable
  updateContent = (changeSet: BlockChangeSet) => {
    if (changeSet.content !== undefined) {
      this.setContent(changeSet.content);
    }
    // save to storage the original, non linkified and strip of tags text
    const onChangeFn = changeSet.type
      ? this.callOnChangeDirect
      : this.callOnChangeDebounced;
    onChangeFn(changeSet);
  };

  getContentEditable(): HTMLDivElement | null {
    return this.contentEditable.current;
  }

  handleFocus = () => {
    this.props.onFocus(this.props.block.id);
    this.contentEditable.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
    if (this.props.supportVirtualKeyboard) {
      setTimeout(() => {
        this.contentEditable.current?.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }, 800);
    }
  };

  handleBlur = () => {
    this.props.onBlur(this.props.block.id);
  };

  getTypeFromPrevious(block: Block): BlockKind {
    switch (block.type) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'text':
        return 'text';
      case 'bullet':
        return 'bullet';
      case 'checkbox':
        return 'checkbox';
      case 'code':
        return 'code';
      case 'task':
        return 'task';
    }
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const {
      block,
      onMoveBlockUp,
      onMoveUp,
      onMoveBlockDown,
      onMoveDown,
      onChange,
      onCreateBelow,
      onDelete,
    } = this.props;

    // console.log(e.key);
    if (e.key === 'Up' || e.key === 'ArrowUp') {
      e.preventDefault();
      if ((e.metaKey || e.ctrlKey) && e.shiftKey) {
        onMoveBlockUp(block.id);
      } else {
        onMoveUp(block.id);
      }
    }
    if (e.key === 'Down' || e.key === 'ArrowDown') {
      e.preventDefault();
      if ((e.metaKey || e.ctrlKey) && e.shiftKey) {
        onMoveBlockDown(block.id);
      } else {
        onMoveDown(block.id);
      }
    }
    // KNOWN BUG: enter will not create new line when fast typing:
    // because of subsequent updates, that overwrite older update, in key-value store
    // todo: check if it's solved
    if (e.key === 'Enter') {
      if (
        (e.metaKey || e.ctrlKey) &&
        !e.shiftKey &&
        !e.altKey &&
        block.type === 'checkbox'
      ) {
        // completing checkbox
        e.preventDefault();
        e.stopPropagation();
        onChange({
          id: block.id,
          completed: !block.completed,
        });
      } else if (
        !e.metaKey &&
        !e.ctrlKey &&
        !e.altKey &&
        !e.shiftKey &&
        // @ts-ignore
        e.target.innerHTML === 0 &&
        block.type !== 'text'
      ) {
        // ???
        e.preventDefault();
        onChange({
          id: block.id,
          type: 'text',
        });
      } else if (e.metaKey || e.ctrlKey) {
        // do nothing when task is completed
        e.preventDefault();
      } else {
        e.preventDefault();
        // new line, create below
        const type = this.getTypeFromPrevious(block);
        onCreateBelow(block.position, type, block.indent);
      }
    }
    if (e.key === 'Tab') {
      e.preventDefault();
      const mod = e.shiftKey ? -1 : 1;
      const newIndent = block.indent + mod;
      this.operationWithSaveOfCursor(() => {
        onChange({
          id: block.id,
          indent: newIndent >= 0 ? newIndent : 0,
        });
      });
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      const current = this.contentEditable.current;
      if (current) {
        current.blur();
      }
    }
    if (e.key === 'Backspace') {
      const caretPos = getCaretPosition(this.contentEditable.current);
      if (caretPos === 0) {
        e.preventDefault();
        if (block.type !== 'text') {
          this.operationWithSaveOfCursor(() => {
            onChange({
              id: block.id,
              type: 'text',
            });
          });
        } else if (block.indent > 0) {
          this.operationWithSaveOfCursor(() => {
            onChange({
              id: block.id,
              indent: block.indent - 1,
            });
          });
        } else if (
          // @ts-ignore
          e.target.innerHTML.length === 0
        ) {
          onDelete(block.id);
        }
      }
    }
  };

  operationWithSaveOfCursor = (
    op: () => { timeout?: number; restoreFn?: () => void } | void
  ) => {
    const restore = saveCaretPosition(this.contentEditable.current);
    const customOpts = op();
    const timeout = (customOpts && customOpts.timeout) || 100;
    const newRestore = (customOpts && customOpts.restoreFn) || restore;
    // weirdly need to set a timeout to reset focus. Probably because of react's re-render
    setTimeout(newRestore, timeout);
  };

  render() {
    const { block, onChange } = this.props;

    const Prefix = getBlockPrefix(block);

    return (
      <div
        style={{
          display: 'flex',
          marginLeft: `${block.indent * INDENT_PIXELS}px`,
        }}
      >
        {Prefix && (
          <div style={{}}>
            {/* @ts-ignore TODO */}
            <Prefix onChange={onChange} block={block} />
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flex: '1',
          }}
        >
          <ContentEditable
            innerRef={this.contentEditable}
            style={{
              ...basicStyles,
              ...getStyles(block),
              // @ts-ignore
              '&::empty:before': {
                content: 'attr(placeholder)',
                display: 'block',
              },
            }}
            html={this.state.contentInner}
            disabled={false}
            // @ts-ignore TODO
            placeholder={PLACEHOLDERS[block.type]}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        </div>
      </div>
    );
  }
}
export default BlockElement;
