import { IonItem, IonLabel } from '@ionic/react';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { ContextType } from '../../types/CoreTypes';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import { HOME_URL } from '../../lib/Constants';
import { useHistory } from 'react-router';

interface Props {
  context: ContextType;
  isSelected?: boolean;
  ref?: any;
}

// eslint-disable-next-line react/display-name
const ContextSwitchListElement: React.FunctionComponent<Props> = forwardRef<
  any,
  Props
>(({ context, isSelected }: Props, ref) => {
  const { setContext } = useWorkspace();
  const history = useHistory();
  const nodeRef = useRef<any>(null);

  const onSetContext = useCallback(
    function onSetContext(context: ContextType) {
      setContext(context);
      history.push(HOME_URL, { direction: 'root' });
    },
    [setContext, history]
  );

  useImperativeHandle(ref, () => ({
    impress() {
      onSetContext(context);
    },
    scrollIntoView() {
      nodeRef.current &&
        nodeRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    },
    canMove() {
      return false;
    },
    moveDown() {
      return undefined;
    },
    moveUp() {
      return undefined;
    },
    getHandlers() {
      return undefined;
    },
  }));

  return (
    <IonItem
      ref={nodeRef}
      color={isSelected ? 'light' : undefined}
      style={{ cursor: 'pointer' }}
      onClick={(e: any) => {
        e.preventDefault();
        onSetContext(context);
      }}
    >
      <IonLabel>
        Switch to <b>{context.name}</b>
      </IonLabel>
    </IonItem>
  );
});

export default ContextSwitchListElement;
