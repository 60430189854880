import React, { useEffect, useState } from 'react';
import { useStorage } from '../../helpers/StorageProviders';
import { useWorkspace } from '../../helpers/WorkspaceProviders';
import { differenceInCalendarDays, subDays } from 'date-fns';
import { AchievementKind } from '@todo/common';
import { formatDateWithoutTime } from '../../helpers/RecurringHelper';
import DaysAchievements from './DaysAchievements';
import {
  AchievementWithMetadata,
  AchievementWithStreak,
} from '../../types/CoreTypes';
import { chain } from 'lodash';
import AchievementsCalendar from './AchievementsCalendar';
import { colors } from '../../helpers/styles';
import { useDate } from '../../helpers/DateProvider';

const DAYS = 30;

const AchievementsWidget = () => {
  const {
    repos: { achievementRepo },
  } = useStorage();

  const { context } = useWorkspace();
  const { date, dateFormatted } = useDate();

  const [currentDay, setCurrentDay] = useState(dateFormatted);
  const [startingDate, setStartingDate] = useState<Date | null>(null);
  const [achievements, setAchievements] = useState<{
    [key: string]: AchievementWithMetadata[];
  } | null>(null);
  const [todaysAchievements, setTodaysAchievements] = useState<
    AchievementWithStreak[] | null
  >(null);

  useEffect(() => {
    setStartingDate(subDays(date, DAYS - 1));
    setCurrentDay(dateFormatted);
  }, [date, dateFormatted]);

  // fetch last DAYS days of achievements
  useEffect(() => {
    if (!startingDate) return;
    const sub = achievementRepo
      .getAllForDates$(context.id, startingDate)
      .subscribe((allAchievements) => {
        const res = chain(allAchievements)
          .groupBy((a) => formatDateWithoutTime(a.createdAt))
          .map(
            (datesAchievements, key) =>
              [
                key,
                chain(datesAchievements)
                  .groupBy((a) => a.type)
                  .map((a) => {
                    const achievements = chain(a)
                      .orderBy((a) => a.createdAt, 'desc')
                      .groupBy((a) => `${a.taskId}-${a.projectId}`)
                      .map((a) =>
                        // get most recent for the same item ID
                        chain(a).head().value()
                      )
                      .value();
                    // get most recent of all
                    const lastAch = chain(achievements).head().value();
                    return {
                      achievement: lastAch,
                      counter: achievements.length,
                      streak: {
                        hit: achievements.length, // todo
                        total: DAYS,
                      },
                    };
                  })
                  .value(),
              ] as [string, AchievementWithMetadata[]]
          )
          .value();
        setAchievements(Object.fromEntries(res));
      });
    return () => sub.unsubscribe();
  }, [startingDate, achievementRepo, context, currentDay]);

  // filter today's achievements
  useEffect(() => {
    if (!achievements) return;
    if (!startingDate) return;

    // on purpose comparing 2 dates without time, to get diff in UTC
    const days = differenceInCalendarDays(
      new Date(currentDay),
      new Date(formatDateWithoutTime(startingDate))
    );

    const newTodaysAchievementsWithMetadata = chain(achievements[currentDay] || [])
      .keyBy((a) => a.achievement.type)
      .value();

    const streaks: { [key in AchievementKind]?: number } = chain(
      Object.entries(achievements)
    )
      .filter(([k, v]) => k <= currentDay)
      .sortBy(([k, v]) => k)
      .reduce((acc, [k, v]) => {
        v.forEach((a) => {
          acc[a.achievement.type] = (acc[a.achievement.type] || 0) + a.counter;
        });
        return acc;
      }, {} as { [key in AchievementKind]?: number })
      .value();

    const newTodaysAchievements = chain(
      Object.entries(streaks) as [AchievementKind, number][]
    )
      .map(([kind, count]) => {
        return {
          kind,
          streak: {
            hit: count,
            days,
          },
          achievement: newTodaysAchievementsWithMetadata[kind],
        };
      })
      .orderBy((r) => r.achievement?.achievement.createdAt || 9999999999, 'desc')
      .value();
    setTodaysAchievements(newTodaysAchievements);

    // .filter((a) => formatDateWithoutTime(a.createdAt) === currentDay)
  }, [startingDate, achievements, currentDay]);

  // loading
  if (!startingDate) return null;
  if (!achievements) return null;
  if (!todaysAchievements) return null;

  return (
    <div
      style={{
        borderColor: colors.grayOut,
        borderWidth: '2px',
        borderRadius: '8px',
        padding: '10px',
        margin: '5px',
        borderStyle: 'solid',
      }}
    >
      <AchievementsCalendar
        achievements={achievements}
        startingDate={startingDate}
        selectedDate={currentDay}
        onSelectedChange={setCurrentDay}
        daysToRender={DAYS}
      />
      <DaysAchievements achievements={todaysAchievements} />
    </div>
  );
};

export default AchievementsWidget;
