import React, { createContext, useContext } from 'react';
import { debounce } from 'lodash';
import { Capacitor } from '@capacitor/core';

// @ts-ignore
export const ClientTypeContext = createContext<ClientTypeProps>(undefined);

interface Props {
  children: any;
}

interface State {
  isDesktop: boolean;
  isNative: boolean;
}

export interface ClientTypeProps {
  isDesktop: boolean;
  isNative: boolean;
}

export class ClientTypeProvider extends React.PureComponent<Props, State> {
  state: State = {
    isDesktop: ClientTypeProvider.getIsDesktop(),
    isNative: Capacitor.isNative || false,
  };

  notDebouncedHandleResize = () => {
    this.setState({ isDesktop: ClientTypeProvider.getIsDesktop() });
  };

  static getIsDesktop() {
    return window.innerWidth > 1000;
  }

  debouncedHandleResize = debounce(this.notDebouncedHandleResize, 1000);

  componentDidMount() {
    window.addEventListener('resize', this.debouncedHandleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedHandleResize);
  }

  render() {
    const { children } = this.props;
    const { isDesktop, isNative } = this.state;

    return (
      <ClientTypeContext.Provider value={{ isDesktop, isNative }}>
        {children}
      </ClientTypeContext.Provider>
    );
  }
}

export const useClientType: () => ClientTypeProps = () =>
  useContext(ClientTypeContext);

export const withClientType = <P extends Partial<ClientTypeProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof ClientTypeProps>> => {
  return function withClientTypeContext(props) {
    return (
      <ClientTypeContext.Consumer>
        {(contextProps: ClientTypeProps) => (
          <Component {...(props as P)} {...contextProps} />
        )}
      </ClientTypeContext.Consumer>
    );
  };
};
