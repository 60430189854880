import React from 'react';
import { IonButton, IonIcon, IonRouterLink } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { Routes } from '../../lib/routes';

interface Props {
  children: React.ReactNode;
  onAfterNavigate?: () => void;
}

const UpgradeBanner = ({ children, onAfterNavigate }: Props) => {
  return (
    <div
      style={{
        border: '1px solid #3880ff',
        padding: '10px',
        margin: '10px',
        borderRadius: '10px',
        background: '#eff5ff',
      }}
    >
      <div>{children}</div>

      <IonRouterLink
        onClick={onAfterNavigate}
        routerLink={`/${Routes.settings}`}
        routerDirection={'root'}
      >
        <IonButton size="small" color="success">
          Upgrade
          <IonIcon slot="end" icon={arrowForward} />
        </IonButton>
      </IonRouterLink>
    </div>
  );
};

export default UpgradeBanner;
