import React from 'react';

interface Props {
  len: number;
  max: number;
}

const CharLimit = ({ len, max }: Props) => {
  const rem = max - len;
  return (
    <div className={`char-limit ${rem >= 0 ? 'positive' : 'negative'}`}>{rem}</div>
  );
};

export default CharLimit;
