import React, { useCallback, useEffect, useState } from 'react';
import { useStorage } from '../helpers/StorageProviders';
import { ItemType, TagEnrichedWithParent } from '../types/CoreTypes';
import { RouteComponentProps } from 'react-router';
import { IonContent, IonPage, IonTitle } from '@ionic/react';
import TagDetails from '../components/Tags/TagDetails';
import CommonHeader from '../components/Common/CommonHeader';
import { RxChangeEvent } from 'rxdb/plugins/core';

interface TParams {
  id?: string;
}

const TagViewPage: React.FunctionComponent<RouteComponentProps<TParams>> = ({
  match,
}: RouteComponentProps<TParams>) => {
  const [tag, setTag] = useState<TagEnrichedWithParent | null>(null);
  const {
    subscribeForDbChanges,
    repos: { tagRepo },
  } = useStorage();

  const id = match.params.id;

  const loadData = useCallback(
    async function loadData() {
      if (!id) return;
      tagRepo.getById(id).then(setTag);
    },
    [tagRepo, id]
  );

  useEffect(() => {
    function handleDbChangeEvent(evt: RxChangeEvent) {
      if (evt.operation === 'UPDATE') {
        if ([ItemType.tag].includes(evt.collectionName as ItemType)) {
          loadData();
        }
      }
    }

    const sub = subscribeForDbChanges(handleDbChangeEvent);
    return () => {
      sub.unsubscribe();
    };
  }, [loadData, subscribeForDbChanges]);

  // const updateTag = useCallback(
  //   (tagData: Partial<TagType>, id: string) => {
  //     return tagRepo.edit({
  //       ...tagData,
  //       id: id
  //     });
  //   },
  //   [tagRepo]
  // );

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (!id) return null;

  return (
    <IonPage>
      <CommonHeader showBack>
        <IonTitle>Tag</IonTitle>
      </CommonHeader>
      <IonContent>
        <TagDetails tag={tag} />
      </IonContent>
    </IonPage>
  );
};

export default TagViewPage;
