import React from 'react';
import { IonIcon } from '@ionic/react';

interface Props {
  text: string;
  subText?: string;
  icon: string;
  color: string;
  children?: React.ReactNode;
}

const WideMessage = ({ text, subText, icon, color, children }: Props) => {
  return (
    <div
      style={{
        border: `2px solid ${color}`,
        borderRadius: '8px',
        padding: '10px',
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IonIcon
        icon={icon}
        style={{
          color,
          fontSize: '20px',
          marginBottom: '5px',
        }}
      />
      <div style={{ color, fontSize: '14px' }}>{text}</div>
      {children && <div>{children}</div>}
      {subText && <div style={{ color, fontSize: '12px' }}>{subText}</div>}
    </div>
  );
};

export default WideMessage;
