export function $(expr: any, con: any) {
  return typeof expr === 'string'
    ? (con || document).querySelector(expr)
    : expr || null;
}

export interface ExtendedSvg extends SVGElement {
  getX(): number;
  getY(): number;
  getEndX(): number;
  getWidth(): number;
  getHeight(): number;
  finaldx: number;
  ox: number;
  oy: number;
  owidth: number;
  min_dx: number;
  max_dx: number;
}

export function createSVG(tag: string, attrs: Record<string, any>): ExtendedSvg {
  const elem = document.createElementNS('http://www.w3.org/2000/svg', tag);
  for (const attr in attrs) {
    if (attr === 'append_to') {
      const parent = attrs.append_to;
      parent.appendChild(elem);
    } else if (attr === 'innerHTML') {
      elem.innerHTML = attrs.innerHTML;
    } else {
      elem.setAttribute(attr, attrs[attr]);
    }
  }
  return elem as ExtendedSvg;
}

export function animateSVG(
  svgElement: SVGElement,
  attr: string,
  from: number,
  to: number
) {
  const animatedSvgElement = getAnimationElement(svgElement, attr, from, to);

  if (animatedSvgElement === svgElement) {
    // triggered 2nd time programmatically
    // trigger artificial click event
    const event = document.createEvent('HTMLEvents');
    event.initEvent('click', true, true);
    // @ts-ignore
    event.eventName = 'click';
    animatedSvgElement.dispatchEvent(event);
  }
}

function getAnimationElement(
  svgElement: SVGElement,
  attr: string,
  from: number,
  to: number,
  dur = '0.4s',
  begin = '0.1s'
) {
  const animEl = svgElement.querySelector('animate');
  if (animEl) {
    $.attr(animEl, {
      attributeName: attr,
      from,
      to,
      dur,
      begin: 'click + ' + begin, // artificial click
    });
    return svgElement;
  }

  const animateElement = createSVG('animate', {
    attributeName: attr,
    from,
    to,
    dur,
    begin,
    calcMode: 'spline',
    values: from + ';' + to,
    keyTimes: '0; 1',
    keySplines: cubic_bezier('ease-out'),
  });
  svgElement.appendChild(animateElement);

  return svgElement;
}

function cubic_bezier(name: string) {
  // @ts-ignore
  return {
    ease: '.25 .1 .25 1',
    linear: '0 0 1 1',
    'ease-in': '.42 0 1 1',
    'ease-out': '0 0 .58 1',
    'ease-in-out': '.42 0 .58 1',
  }[name];
}

$.on = (
  element: Element,
  event: string,
  selector: string | ((...args: any[]) => void),
  callback?: (...args: any[]) => void
) => {
  if (!callback && typeof selector !== 'string') {
    callback = selector;
    $.bind(element, event, callback);
  } else {
    $.delegate(element, event, selector, callback);
  }
};

$.off = (element: Element, event: string, handler: () => void) => {
  element.removeEventListener(event, handler);
};

$.bind = (element: Element, event: string, callback: () => void) => {
  event.split(/\s+/).forEach(function (event) {
    element.addEventListener(event, callback);
  });
};

$.delegate = (
  element: Element,
  event: string,
  selector: string | ((...args: any[]) => void),
  callback?: (...args: any[]) => void
) => {
  element.addEventListener(event, function (e: Event) {
    // @ts-ignore
    const delegatedTarget = e.target.closest(selector);
    if (delegatedTarget) {
      // @ts-ignore
      e.delegatedTarget = delegatedTarget;
      if (callback) {
        // @ts-ignore
        callback.call(this, e, delegatedTarget);
      }
    }
  });
};

$.closest = (selector: string, element: Element | null): Element | null => {
  if (!element) return null;

  if (element.matches(selector)) {
    return element;
  }

  return $.closest(selector, element.parentNode as Element);
};

$.attr = (element: Element, attr: any, value?: any) => {
  if (!value && typeof attr === 'string') {
    return element.getAttribute(attr);
  }

  if (typeof attr === 'object') {
    for (const key in attr) {
      $.attr(element, key, attr[key]);
    }
    return;
  }

  element.setAttribute(attr, value);
};
