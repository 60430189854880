import React from 'react';
import { ModalsProps, withModalsState } from '../../helpers/ModalsProvider';
import { isEqual } from 'lodash';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import {
  ActionSubscriber,
  MagicHotkeyProps,
} from '../../helpers/MagicHotkeyProvider';
import { HotKeyHandler, Key, KeyModifier } from '../../types/CoreTypes';
import { ClientTypeProps, withClientType } from '../../helpers/ClientTypeProvider';
import { logDebug, logWarn } from '../../lib/logger';

interface ListNavRef {
  impress: () => void;
  scrollIntoView: () => void;
  moveDown: () => Promise<boolean | undefined>;
  moveUp: () => Promise<boolean | undefined>;
  getHandlers(): HotKeyHandler[] | undefined;
  canMove(): boolean;
}

export interface ListNavigationApi {
  selectedCollectionName?: string;
  selectedCollectionIdx?: number;
  setCounter: (collectionName: string, count: number) => void;
  getRef: (collectionName: string) => (idx: number) => React.RefObject<any>;
}

interface Props
  extends ModalsProps,
    WorkspaceProps,
    MagicHotkeyProps,
    ClientTypeProps {
  onAfterNavigate?: () => void;
  useSecondLevelModal?: boolean;
  allowNavWhenMagicOpen?: boolean;
  isDisabled?: boolean;
  collections: string[];
  children: (api: ListNavigationApi) => React.ReactNode;
}

interface State {
  countMap: Map<string, number>;
  selectedIdx: number;
  selectedCollectionName?: string;
  selectedCollectionIdx?: number;
}
export class ListNavigationBase extends React.Component<Props, State> {
  private listRef: Map<string, React.RefObject<ListNavRef>> = new Map();

  getDefaultIndex() {
    return this.props.isDesktop ? 0 : -1;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      countMap: new Map<string, number>(),
      selectedIdx: this.getDefaultIndex(),
    };
  }

  setCounter = (collectionName: string, count: number) => {
    const { countMap, selectedIdx } = this.state;
    // const prevMax = this.getMaxIndex();
    countMap.set(collectionName, count);
    const newMax = this.getMaxIndex();

    const newSelectedIndex =
      selectedIdx > newMax ? (newMax >= 0 ? newMax : 0) : selectedIdx;
    this.log('setCounter', collectionName, count);
    this.updateIdx(countMap, newSelectedIndex);
  };

  private LOG_PREFIX = 'ListNavigationCommon';
  private log = (msg: string, ...params: any[]) => {
    logDebug([], `${this.LOG_PREFIX}: ${msg}`, ...params);
  };
  private error = (msg: string, ...params: any[]) => {
    logWarn([], `${this.LOG_PREFIX}: ${msg}`, { params });
  };

  private actionSubscriberListRow?: ActionSubscriber;
  private actionSubscriberListReorder?: ActionSubscriber;

  updateIdx(countMap: Map<string, number>, selectedIdx: number) {
    const { collection, idx } = this.computeSelectedPosition(countMap, selectedIdx);

    this.setState(
      {
        countMap,
        selectedIdx,
        selectedCollectionName: collection,
        selectedCollectionIdx: idx,
      },
      this.bindCurrentNodeActions
    );
  }

  bindCurrentNodeActions = (attempt?: number) => {
    if (this.props.isDesktop) {
      const modalKeyListRowActions = this.getModalKeyListRowActions();
      if (this.props[modalKeyListRowActions] || this.props.isDisabled) return;
      const node = this.getNode();
      if (node) {
        const handlers = node.getHandlers();
        this.actionSubscriberListRow?.unsubscribe();
        if (handlers) {
          this.actionSubscriberListRow = this.props.registerActions(
            'list row',
            handlers
          );
        }
        this.actionSubscriberListReorder?.unsubscribe();
        if (node.canMove()) {
          this.actionSubscriberListReorder = this.props.registerActions(
            'list reorder',
            this.reorderActions
          );
        }
      } else {
        if (node === undefined) {
          this.actionSubscriberListRow?.unsubscribe();
          this.actionSubscriberListReorder?.unsubscribe();
        }
        if (node === null) {
          if (attempt && attempt > 5) {
            this.error('updateIdx: exhausted retry attempts');
            this.actionSubscriberListRow?.unsubscribe();
          }
          setTimeout(() => {
            this.bindCurrentNodeActions(attempt ? attempt + 1 : 1);
          }, 100);
        }
      }
    }
  };

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any
  ): boolean {
    return (
      !isEqual(nextProps, this.props) ||
      this.state.selectedIdx !== nextState.selectedIdx ||
      this.state.selectedCollectionName !== nextState.selectedCollectionName ||
      this.state.selectedCollectionIdx !== nextState.selectedCollectionIdx
    );
  }

  getRef = (collectionName: string) => (idx: number) => {
    const key = `${collectionName}-${idx}`;
    const idxRef = this.listRef.get(key);
    if (idxRef) {
      return idxRef;
    }
    const newRef = React.createRef<ListNavRef>();
    this.listRef.set(key, newRef);
    return newRef;
  };

  getMaxIndex() {
    return (
      Array.from(this.state.countMap.values()).reduce((acc, cnt) => acc + cnt, 0) - 1
    );
  }

  scrollNodeIntoView() {
    this.getNode()?.scrollIntoView();
  }

  /**
   * @return null if there's current row, but no ref. Undefined if no current row
   */
  getNode(): ListNavRef | null | undefined {
    const { selectedCollectionIdx, selectedCollectionName } = this.state;
    if (
      selectedCollectionName !== undefined &&
      selectedCollectionIdx !== undefined
    ) {
      const node = this.getRef(selectedCollectionName)(selectedCollectionIdx)
        .current;
      if (!node)
        this.error(
          `no ref to the current row ${selectedCollectionName}, idx ${selectedCollectionIdx}!`
        );
      return node;
    } else {
      this.log('no current collection and index');
      return undefined;
    }
  }

  handleKeyPress = async (e: KeyboardEvent) => {
    this.log('handle list nav list key press');
    const index = this.state.selectedIdx;

    let newIdx;
    const maxIndex = this.getMaxIndex();
    // keys.reduce((acc, key) => acc + this.props.results[key].length, 0) - 1;
    switch (e.key) {
      case 'Down':
      case 'ArrowDown':
        if (e.ctrlKey || e.metaKey || e.shiftKey) break;
        e.preventDefault();
        newIdx = index + 1;
        if (newIdx > maxIndex) {
          newIdx = this.getDefaultIndex();
        }
        if (newIdx <= maxIndex) {
          this.updateIdx(this.state.countMap, newIdx);
          this.scrollNodeIntoView();
        }
        break;
      case 'Up':
      case 'ArrowUp':
        if (e.ctrlKey || e.metaKey || e.shiftKey) break;
        e.preventDefault();
        newIdx = index - 1;
        if (newIdx < 0) {
          newIdx = maxIndex;
        }
        if (newIdx >= 0) {
          this.updateIdx(this.state.countMap, newIdx);
          this.scrollNodeIntoView();
        }
        break;
      case 'Enter':
        if (e.ctrlKey || e.metaKey || e.shiftKey) break;
        e.preventDefault();
        this.getNode()?.impress();
        if (this.props.onAfterNavigate) this.props.onAfterNavigate();
        break;
      default:
        break;
    }
  };

  moveDown = async () => {
    const node = this.getNode();
    const index = this.state.selectedIdx;
    if (node) {
      const res = await node.moveDown();
      if (res) {
        this.updateIdx(this.state.countMap, index + 1);
      }
    }
  };

  moveUp = async () => {
    const node = this.getNode();
    const index = this.state.selectedIdx;
    if (node) {
      const res = await node.moveUp();
      if (res) {
        this.updateIdx(this.state.countMap, index - 1);
      }
    }
  };

  private reorderActions = [
    {
      name: 'move down',
      combination: {
        key: Key.Down,
        modifiers: [
          [KeyModifier.Cmd, KeyModifier.Shift],
          [KeyModifier.Ctrl, KeyModifier.Shift],
        ],
      },
      action: this.moveDown,
    },
    {
      name: 'move up',
      combination: {
        key: Key.Up,
        modifiers: [
          [KeyModifier.Cmd, KeyModifier.Shift],
          [KeyModifier.Ctrl, KeyModifier.Shift],
        ],
      },
      action: this.moveUp,
    },
  ];

  componentDidUpdate(prevProps: Props) {
    // special handling for Always Open modal
    // if path or context changed
    const modalKeyLocalKeys = this.getModalKeyForLocalActions();
    const modalKeyListRowActions = this.getModalKeyListRowActions();
    if (
      this.props[modalKeyLocalKeys] !== prevProps[modalKeyLocalKeys] ||
      this.props.isDisabled !== prevProps.isDisabled
    ) {
      if (this.props[modalKeyLocalKeys] || this.props.isDisabled) {
        this.log('unloading list nav local listener...');
        document.removeEventListener('keydown', this.handleKeyPress, false);
      } else {
        this.log('loading list nav local listener...');
        document.addEventListener('keydown', this.handleKeyPress, false);
      }
    }
    if (
      this.props[modalKeyListRowActions] !== prevProps[modalKeyListRowActions] ||
      this.props.isDisabled !== prevProps.isDisabled
    ) {
      if (this.props[modalKeyListRowActions] || this.props.isDisabled) {
        this.log('unloading list nav list key listener...');
        this.actionSubscriberListReorder?.unsubscribe();
        this.actionSubscriberListRow?.unsubscribe();
      } else {
        // this.registerListReorder();
        // this will subscribe to row actions
        this.bindCurrentNodeActions();
      }
    }

    if (prevProps.context.id !== this.props.context.id) {
      this.updateIdx(this.state.countMap, this.getDefaultIndex());
    }
  }

  getModalKeyForLocalActions() {
    return this.props.useSecondLevelModal
      ? this.props.allowNavWhenMagicOpen
        ? 'secondLayerModalIsOpen'
        : 'secondLayerModalIsOpenIncMagic'
      : 'modalIsOpen';
  }

  getModalKeyListRowActions() {
    return this.props.useSecondLevelModal
      ? 'secondLayerModalIsOpen'
      : 'nonMagicModalIsOpen';
  }

  // registerListReorder(attempt?: number) {
  //   if (!this.props.isDesktop) return;
  //   this.log('loading list nav list key listener...');
  //
  //   const node = this.getNode();
  //   if (node) {
  //     if (node.canMove()) {
  //       this.actionSubscriberListReorder = this.props.registerActions(
  //         'list reorder',
  //         this.reorderActions
  //       );
  //     }
  //   } else {
  //     if (node === null) {
  //       if (attempt && attempt > 5) {
  //         this.error('registerListReorder: exhausted retry attempts');
  //       }
  //       setTimeout(() => {
  //         this.registerListReorder(attempt ? attempt + 1 : 1);
  //       }, 100);
  //     }
  //   }
  // }

  componentDidMount() {
    if (!this.props[this.getModalKeyForLocalActions()] && !this.props.isDisabled) {
      this.log('loading list nav local keys listener...');
      document.addEventListener('keydown', this.handleKeyPress, false);
    }
    // if (!this.props[this.getModalKeyListRowActions()] && !this.props.isDisabled) {
    //   this.registerListReorder();
    // }
  }

  componentWillUnmount() {
    if (!this.props[this.getModalKeyForLocalActions()] && !this.props.isDisabled) {
      this.log('unloading list nav local keys listener...');
      document.removeEventListener('keydown', this.handleKeyPress, false);
    }
    this.log('unloading list nav list key listener...');
    this.actionSubscriberListReorder?.unsubscribe();
    this.actionSubscriberListRow?.unsubscribe();
  }

  computeSelectedPosition(countMap: Map<string, number>, selectedIdx: number) {
    const { collections } = this.props;

    if (selectedIdx === -1) {
      return {};
    }
    let remainder = selectedIdx;

    for (const collection of collections) {
      const len = countMap.get(collection) || 0;
      // if (!len) this.error(`no len for collection ${collection}`);
      if (remainder < len) {
        return {
          collection,
          idx: remainder,
        };
      } else {
        remainder -= len;
      }
    }
    // this.error(`cannot find collection index for idx ${selectedIdx}`);
    return {};
  }

  render() {
    const { children } = this.props;

    const { selectedCollectionName, selectedCollectionIdx } = this.state;

    return (
      <>
        {children({
          selectedCollectionName,
          selectedCollectionIdx,
          setCounter: this.setCounter,
          getRef: this.getRef,
        })}
      </>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
const noopUndef = () => undefined;

const withNoOpMagicHotkey = <P extends Partial<MagicHotkeyProps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof MagicHotkeyProps>> => {
  return function withMagicHotkeyContext(props) {
    return (
      <Component
        {...(props as P)}
        triggerMagicPanel={noop}
        registerActions={noopUndef}
      />
    );
  };
};
export const ListNavigationNoHotKey = withClientType(
  withNoOpMagicHotkey(withWorkspace(withModalsState(ListNavigationBase)))
);
