import PouchDB from 'pouchdb';
import { PartialUpdatedRecord } from '../../types/CoreTypes';
import { Model, SortCondition, Sorter, SortSettings } from './ModelTooling';
import { DaoCatalog } from '../../helpers/InitStorage';
import { E, ExtractEnrichmentResult, ItemEditEvent, Type } from './DaoTypes';
import { RxCollection } from 'rxdb/plugins/core';
import { nanoid } from 'nanoid';
import { debounce, omit, take, uniqWith } from 'lodash';
import { map, mergeMap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { logDebug } from '../logger';

function normalizeSorter<M>(conditions: Sorter<M>): SortCondition<M>[] {
  return conditions.map((condition) => {
    if (typeof condition === 'string') {
      return { [condition]: { dir: 'asc' } } as SortCondition<M>;
    }
    return condition as SortCondition<M>;
  });
}

function getValueWithSubstitutions<M extends any>(
  rec: M,
  key: keyof M,
  subs: string[] | undefined
): M[keyof M] | undefined {
  if (rec[key] !== undefined) {
    return rec[key];
  }

  if (subs) {
    for (const k of subs) {
      if (rec[k as keyof M] !== undefined) {
        return rec[k as keyof M];
      }
    }
  }

  return undefined;
}

function sortOneCondition<M extends any>(
  condition: SortCondition<M>,
  a: M,
  b: M
): number {
  // condition should only have one key, iterating here to just get it
  for (const key in condition) {
    const sortSettings = condition[key] as SortSettings<M>;
    const direction = sortSettings.dir;
    const valA = getValueWithSubstitutions(
      a,
      key,
      sortSettings.substitute
    ) as M[Extract<keyof M, string>];
    const valB = getValueWithSubstitutions(
      b,
      key,
      sortSettings.substitute
    ) as M[Extract<keyof M, string>];

    let sortValues;
    if (direction === 'asc') {
      sortValues = { more: 1, less: -1 };
    } else {
      sortValues = { more: -1, less: 1 };
    }

    if (notUndefinedOrNull(valA) && notUndefinedOrNull(valB)) {
      if (valA === valB) return 0;
      return valA > valB ? sortValues.more : sortValues.less;
    }
    // to show empty values at the bottom:
    if (notUndefinedOrNull(valA)) return sortValues.less;
    if (notUndefinedOrNull(valB)) return sortValues.more;
  }
  return 0;
}

function notUndefinedOrNull<T>(a: T | undefined | null): boolean {
  return a !== undefined && a !== null;
}

export const sort = <M>(conditions: Sorter<M>) => (a: M, b: M): number => {
  let res = 0;
  for (const condition of normalizeSorter(conditions)) {
    res = sortOneCondition(condition, a, b);
    if (res !== 0) {
      return res;
    }
  }
  return res;
};

// const getCircularReplacer = () => {
//   const seen = new WeakSet();
//   return (key: any, value: any) => {
//     if (typeof value === 'object' && value !== null) {
//       if (seen.has(value)) {
//         return;
//       }
//       seen.add(value);
//     }
//     return value;
//   };
// };

// const withTimer = (fn: (...args: any[]) => Promise<any>) => (
//   ...args: any[]
// ): Promise<any> => {
//   const startTime = new Date().getTime();
//   return fn(...args).then(res => {
//     const endTime = new Date().getTime();
//     console.info(`$$$ time taken: ${endTime - startTime}ms`, args);
//     return res;
//   });
// };

export default class PouchDAO<
  Form extends Record<any, any>,
  Context extends string | undefined
> {
  private readonly model: Model<Type<Form>>;
  private readonly daoCatalog: DaoCatalog;
  private readonly collection: RxCollection<Type<Form>>;
  private readonly itemEditSubject?: Subject<ItemEditEvent>;
  // private readonly userId: string;

  get _techDebt_collection(): RxCollection<Type<Form>> {
    return this.collection;
  }

  constructor(
    daoCatalog: DaoCatalog,
    model: Model<Type<Form>>,
    collection: RxCollection<Type<Form>>,
    itemEditSubject?: Subject<ItemEditEvent>
    // userId: string
  ) {
    this.model = model;
    this.daoCatalog = daoCatalog;
    this.collection = collection;
    this.itemEditSubject = itemEditSubject;
    // this.userId = userId;
  }

  private makeId() {
    return `${this.model.type[0]}-${new Date().getTime().toString(36)}-${nanoid()}`;
  }

  private static getNow() {
    return new Date().getTime();
  }

  private convertNullToUndef(doc: any) {
    if (typeof doc !== 'object') return doc;
    const newDoc = {};
    for (const [k, v] of Object.entries(doc)) {
      // @ts-ignore
      newDoc[k] = v === null ? undefined : this.convertNullToUndef(v);
    }
    return newDoc;
  }

  _forceCreate(doc: Type<Form>) {
    const record = {
      ...this.convertNullToUndef(doc),
    };
    logDebug([], 'creating: ', record);
    return this.collection.insert(record);
  }

  build(doc: Form): Type<Form> {
    return {
      ...doc,
      id: this.makeId(),
      // userId: this.userId,
      lastUpdated: PouchDAO.getNow(),
      createdAt: PouchDAO.getNow(),
    };
  }

  create(form: Form): Promise<string> {
    return this.collection.insert(this.build(form)).then((res) => res.id);
  }

  async update(id: string, changeSet: Partial<Form>) {
    // logDebug(['blocksync'], 'update', { id, changeSet });
    await this.collection.findByIds([id]).then(async (resx) => {
      const res = resx.get(id);
      if (res) {
        const oldData = Object.assign({}, res._data);
        // logDebug(['blocksync'], 'oldData', { ...oldData });
        await res
          .update({
            $set: {
              ...changeSet,
              lastUpdated: PouchDAO.getNow(),
            },
          })
          .then(() => {
            // setting timeout will allow listeners to this promise to complete
            // before event handling, making it kind of a BG task
            setTimeout(() => {
              this.itemEditSubject?.next({
                type: this.model.type,
                recordBefore: oldData,
                changeSet: changeSet,
              });
            }, 100);
          });
      }
    });
  }

  editManyWithDebounce = debounce(this.editMany.bind(this), 200);

  edit(entity: PartialUpdatedRecord<Form>): Promise<void> {
    return this.update(entity.id, entity);
  }

  async editMany(
    entities: PartialUpdatedRecord<Form>[],
    toCreate?: Form[]
  ): Promise<void> {
    const em = new Map(entities.map((e) => [e.id, e]));
    await this.collection.findByIds(Array.from(em.keys())).then(async (resx) => {
      // can also consider query.update
      await Array.from(resx.entries()).map(([id, value]) =>
        value.update({
          $set: {
            ...em.get(id),
            lastUpdated: PouchDAO.getNow(),
          },
        })
      );
    });

    if (toCreate) {
      for (const newItem of toCreate) {
        await this.create(newItem);
      }
    }

    // const entitiesMap = new Map(entities.map(e => [e.id, e]));
    // const useDocs: RxDocumentType[] = Array.from(
    //   (await this.collection.findByIds(Array.from(entitiesMap.keys()))).values()
    // ).map(doc => ({
    //   ...doc._data,
    //   ...entitiesMap.get(doc.id),
    //   lastUpdated: PouchDAO.getNow()
    // }));
    //
    // if (toCreate) {
    //   for (const newItem of toCreate) {
    //     useDocs.push(this.build(newItem));
    //   }
    // }
    //
    // // logDebug([], '$$$ useDocs', useDocs, entities);
    //
    // await Promise.all(
    //   useDocs.map(doc => {
    //     this.collection.schema.validate(doc);
    //     return doc;
    //   })
    // ).then(docs => {
    //   const updateDocs: RxDocumentType[] = docs.map(d =>
    //     this.collection._handleToPouch(d)
    //   );
    //   const docsMap: Map<string, RxDocumentType> = new Map();
    //   docs.forEach(d => {
    //     docsMap.set((d as any)[this.collection.schema.primaryPath] as any, d);
    //   });
    //
    //   return this.collection.database.lockedRun(() => {
    //     return this.collection.pouch.bulkDocs(updateDocs).then(results => {
    //       const emitEvent = createUpdateEvent(
    //         this.collection as any,
    //         JSON.stringify(results[0]) as any,
    //         results[0] as any,
    //         results[0] as any
    //       );
    //       this.collection.$emit(emitEvent);
    //       // omitting event emitting logic
    //       // https://github.com/pubkey/rxdb/blob/a29dc68b4856ef16d9fb98c7f8b186ddd1567c71/src/rx-collection.ts#L410
    //     });
    //   });
    // });
  }

  private convertSort(sortContext?: Context, sort?: Sorter<Type<Form>>) {
    const gotSort = sort || this.model.getSort(sortContext);
    return gotSort as Array<string | { [k: string]: 'asc' | 'desc' }>;
  }

  private _optimizeQueries = (
    selector: PouchDB.Find.Selector
  ): PouchDB.Find.Selector[] => {
    const queries = [];
    for (const key in selector) {
      // $in -> many queries for each element
      if (typeof selector[key] === 'object' && selector[key].$in) {
        for (const $in of selector[key].$in) {
          queries.push({
            ...selector,
            [key]: $in,
          });
        }
        if (queries.length > 0) {
          return queries.map(this._optimizeQueries).flat();
        }
      }

      // $ne -> 2 queries, lt and gt
      if (typeof selector[key] === 'object' && selector[key].$ne) {
        queries.push({
          ...selector,
          [key]: { $lt: selector[key].$ne },
        });
        queries.push({
          ...selector,
          [key]: { $gt: selector[key].$ne },
        });
        if (queries.length > 0) {
          return queries.map(this._optimizeQueries).flat();
        }
      }

      // $or -> many queries for each condition
      if (key === '$or' && typeof selector[key] !== undefined) {
        const ors = selector[key] || [];
        for (const $or of ors) {
          queries.push({
            ...omit(selector, '$or'),
            ...$or,
          });
        }
        if (queries.length > 0) {
          return queries.map(this._optimizeQueries).flat();
        }
      }
    }
    return [selector];
  };

  private _findAllOptimized(selector: PouchDB.Find.Selector) {
    logDebug([], '-- _findAllOptimized selector: ', selector);
    const queries = this._optimizeQueries(selector);

    if (queries.length) {
      logDebug([], '-- _findAllOptimized queries: ', queries);
    }
    return Promise.all(
      queries.map((selector) => this.collection.find({ selector }).exec())
    )
      .then((results) => results.flat())
      .then((results) => {
        if (selector.$or) {
          return uniqWith(results, (a, b) => a.id === b.id);
        }
        return results;
      });

    // return this.collection
    //   .find({
    //     selector
    //   })
    //   .exec();
  }

  // use `selector` to run mango query, and then
  //  .filter using some other conditions, passed in a different config param
  // This is done for both performance reasons (to not keep index on all fields) and
  // because some filters should be code-based and can't be expressed in Mango
  findAll<A, B, C, Z extends E<Type<Form>, A, B, C> = []>(config: {
    selector: PouchDB.Find.Selector;
    filterFn?: (r: Type<Form>) => boolean;
    enrichedFilterFn?: (r: Type<Form> & ExtractEnrichmentResult<Z>) => boolean;
    sortFn?: (a: Type<Form>, b: Type<Form>) => number;
    enrichedSortFn?: (
      a: Type<Form> & ExtractEnrichmentResult<Z>,
      b: Type<Form> & ExtractEnrichmentResult<Z>
    ) => number;
    sortContext?: Context;
    sort?: Sorter<Type<Form>>;
    enrichments?: Z;
    limit?: number;
  }): Promise<(Type<Form> & ExtractEnrichmentResult<Z>)[]> {
    const sortFnToUse: (a: Type<Form>, b: Type<Form>) => number = config.sortFn
      ? config.sortFn
      : // @ts-ignore
        sort(this.convertSort(config.sortContext, config.sort));
    const selector = {
      ...config.selector,
    };
    logDebug([], 'findAll selector: ', { selector, type: this.model.type });
    // Todo
    // @ts-ignore
    return (
      this._findAllOptimized(selector)
        .then((r) => (config.filterFn ? r.filter(config.filterFn) : r))
        .then((resx) => {
          if (resx.length > 1) {
            // logDebug([], '$$$ sort: ', sortFnToUse);
            return resx.sort(sortFnToUse);
          }
          return resx;
        })
        .then((r) => (config.limit ? take(r, config.limit) : r))
        .then(async (resx) => {
          const enrichmentMap = new Map<string, ExtractEnrichmentResult<Z>>();
          for (const e of config.enrichments || []) {
            const zs = await e.enrichMany(this.daoCatalog, resx);
            zs.forEach((value: any, key: string) => {
              const cur = enrichmentMap.get(key) || {};
              enrichmentMap.set(key, { ...cur, ...value });
            });
          }

          return resx.map((res) => ({
            ...res._data,
            ...enrichmentMap.get(res.id),
          }));
        })
        // @ts-ignore
        .then((r: (Type<Form> & ExtractEnrichmentResult<Z>)[]) =>
          config.enrichedFilterFn ? r.filter(config.enrichedFilterFn) : r
        )
        .then((r: (Type<Form> & ExtractEnrichmentResult<Z>)[]) =>
          config.enrichedSortFn ? r.sort(config.enrichedSortFn) : r
        )
    );
  }

  findAll$: (
    selector: PouchDB.Find.Selector,
    filterFn?: (r: Type<Form>) => boolean,
    sortContext?: Context
  ) => Observable<Type<Form>[]> = (
    selector: PouchDB.Find.Selector,
    filterFn?: (r: Type<Form>) => boolean,
    sortContext?: Context
  ) => {
    logDebug([], 'selector', selector);
    return this.collection.find({ selector }).$.pipe(
      map((resx) => resx.map((r) => r._data as Type<Form>)),
      map((resx) => (filterFn ? resx.filter(filterFn) : resx)),
      map((resx) => {
        const sortToUse: Sorter<Type<Form>> = this.model.getSort(sortContext);
        return resx.sort(sort(sortToUse));
      })
    );
  };

  findAllEnriched$: <A, B, C, Z extends E<Type<Form>, A, B, C> = []>(config: {
    selector: PouchDB.Find.Selector;
    filterFn?: (r: Type<Form>) => boolean;
    sortContext?: Context;
    sortFn?: (a: Type<Form>, b: Type<Form>) => number;
    sort?: Sorter<Type<Form>>;
    enrichments?: Z;
  }) => Observable<(Type<Form> & ExtractEnrichmentResult<Z>)[]> = <
    A,
    B,
    C,
    Z extends E<Type<Form>, A, B, C> = []
  >(config: {
    selector: PouchDB.Find.Selector;
    filterFn?: (r: Type<Form>) => boolean;
    sortContext?: Context;
    sortFn?: (a: Type<Form>, b: Type<Form>) => number;
    sort?: Sorter<Type<Form>>;
    enrichments?: Z;
  }) => {
    logDebug([], 'selector', config.selector);
    return this.collection.find({ selector: config.selector }).$.pipe(
      map((resx) => resx.map((r) => r._data as Type<Form>)),
      map((resx) => (config.filterFn ? resx.filter(config.filterFn) : resx)),
      map((resx) => {
        const sortFnToUse: (a: Type<Form>, b: Type<Form>) => number = config.sortFn
          ? config.sortFn
          : // @ts-ignore
            sort(this.convertSort(config.sortContext, config.sort));
        // const sortToUse: (a: Type<Form>, b: Type<Form>) => number = sortFn
        //   ? sortFn
        //   : sort(this.model.getSort(sortContext));

        return resx.sort(sortFnToUse);
      }),
      mergeMap(async (resx) => {
        const enrichmentMap = new Map<string, ExtractEnrichmentResult<Z>>();
        for (const e of config.enrichments || []) {
          const zs = await e.enrichMany(this.daoCatalog, resx);
          zs.forEach((value: any, key: string) => {
            const cur = enrichmentMap.get(key) || {};
            enrichmentMap.set(key, { ...cur, ...value });
          });
        }

        return resx.map((res) => {
          // @ts-ignore
          const enriched: ExtractEnrichmentResult<Z> = enrichmentMap.get(res.id);
          return {
            ...res,
            ...enriched,
          };
        });
      })
    );
  };

  getAll$ = () => {
    return this.collection.find().$.pipe(
      map((resx) => resx.map((r) => r._data)),
      map((resx) => {
        const sortToUse: Sorter<Type<Form>> = this.model.getSort(undefined);
        return resx.sort(sort(sortToUse));
      })
    );
  };

  async getAll(): Promise<Type<Form>[]> {
    return this.collection
      .find()
      .exec()
      .then((resx) => {
        if (resx.length > 1) {
          const sortToUse: Sorter<Type<Form>> = this.model.getSort(undefined);
          // logDebug([], '$$$ sort: ', sortToUse);
          return resx.sort(sort(sortToUse));
        }
        return resx;
      });
  }

  delete = async (id: string) => {
    // logDebug(['blocksync'], 'delete', { id });
    await this.collection.findByIds([id]).then(async (resx) => {
      const res = resx.get(id);
      if (res) {
        await res.update({ $set: { lastUpdated: PouchDAO.getNow() } });
        await res.remove();
      }
    });
  };

  // findAll = withTimer(this._findAll.bind(this));

  getCount(config: {
    selector: PouchDB.Find.Selector;
    filterFn?: (r: Type<Form>) => boolean;
  }): Promise<number> {
    return this._findAllOptimized(config.selector)
      .then((r) => (config.filterFn ? r.filter(config.filterFn) : r))
      .then((r) => r.length);
  }

  private findOne(id: string): Promise<Type<Form> | null> {
    return this.collection.findByIds([id]).then((r) => r.get(id) || null);
  }

  async getById<A, B, C, Z extends E<Type<Form>, A, B, C> = []>(
    id: string,
    config?: {
      enrichments?: Z;
    }
  ): Promise<(Type<Form> & ExtractEnrichmentResult<Z>) | null> {
    const res = await this.findOne(id);
    if (!res) return null;
    const enrichments = [];
    const iterator = config && config.enrichments ? config.enrichments : [];
    for (const e of iterator) {
      const z = await e.enrich(this.daoCatalog, res);
      if (z) {
        enrichments.push(z);
      }
    }
    const enrichmentsFlat = enrichments.reduce((r, e) => ({ ...r, ...e }), {});

    // @ts-ignore
    return { ...res._data, ...enrichmentsFlat };
  }

  // function mapping<K extends Identifiable>(r: PouchDB.Core.AllDocsResponse<any>): K[] {
  //   return r.rows.map(e => ({ ...e.doc })).filter(Boolean);
  // }
}
