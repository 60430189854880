import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import PropertyEditModalWrapper from './PropertyEditModalWrapper';
import DatePicker from '../Common/DatePicker';
import {
  formatDateWithoutTime,
  makeDateWithoutTime,
} from '../../helpers/RecurringHelper';

interface BaseProps {
  show: boolean;
  date: string | undefined;
  onClose: () => void;
  onSave: (date: string | undefined) => void;
  title: string;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

const EditDateModal = ({
  onClose,
  onSave,
  date,
  show,
  forwardRef,
  title,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedDate(date ? makeDateWithoutTime(date).toISOString() : undefined);
  }, [date, show]);

  // const selectRef = useRef<any>(null);
  //
  // useEffect(() => {
  //   if (show) {
  //     setTimeout(() => {
  //       selectRef.current && selectRef.current.focus();
  //     }, 200);
  //   }
  // }, [show]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedDate(undefined);
  }, [onClose]);

  const handleSave = useCallback(() => {
    const stringDate = selectedDate;
    if (stringDate) {
      const date = new Date(stringDate);
      const formattedDate = formatDateWithoutTime(date);
      onSave(formattedDate);
    } else {
      onSave(undefined);
    }
    handleClose();
  }, [handleClose, onSave, selectedDate]);

  return (
    <PropertyEditModalWrapper
      onClose={handleClose}
      onSave={handleSave}
      show={show}
      title={title}
      klass="date"
    >
      {show && (
        <DatePicker
          ref={forwardRef}
          value={selectedDate}
          placeholder="Date"
          onChange={setSelectedDate}
        />
      )}
    </PropertyEditModalWrapper>
  );
};

const Memoized = React.memo(EditDateModal);

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <Memoized forwardRef={ref} {...props} />
));
