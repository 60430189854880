import React, { forwardRef } from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonRow,
} from '@ionic/react';
import { withWorkspace, WorkspaceProps } from '../helpers/WorkspaceProviders';
import { ModalsProps, withModalsState } from '../helpers/ModalsProvider';
import { logDebug } from '../lib/logger';
import Searcher from './Searcher';

interface MinProps {
  onClose: () => void;
  isHidden: boolean;
}

interface ExtProps extends MinProps {
  forwardedRef?: React.Ref<any>;
}

interface Props extends WorkspaceProps, ExtProps, ModalsProps {}

interface State {
  query: string;
}

class Search extends React.PureComponent<Props, State> {
  state: State = {
    query: '',
  };

  handleType = (event: any) => {
    const text = event.target.value;
    this.setState({ query: text });
  };

  handleClose = () => {
    // this.setState({ name: '' });
    this.props.onClose();
  };

  keyBinding() {
    if (this.props.isHidden) {
      this.unmountKeyListeners();
    } else {
      if (this.props.secondLayerModalIsOpenIncMagic) {
        this.unmountKeyListeners();
      } else {
        this.mountKeyListeners();
      }
    }
  }

  mountKeyListeners() {
    logDebug([], 'loading search modal keyboard listeners...');
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  unmountKeyListeners() {
    logDebug([], 'unloading search modal keyboard listeners...');
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  componentDidMount(): void {
    this.keyBinding();
  }

  componentWillUnmount() {
    // this.unmountEventListeners();
    this.unmountKeyListeners();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.isHidden !== prevProps.isHidden ||
      this.props.secondLayerModalIsOpenIncMagic !==
        prevProps.secondLayerModalIsOpenIncMagic
    ) {
      this.keyBinding();
    }
    if (this.props.context !== prevProps.context) {
      this.setState({ query: '' });
    }
  }

  handleKeyPress = (e: KeyboardEvent) => {
    logDebug([], 'handling key press in search');
    // need this for "AlwaysVisible" modal
    if (e.key === 'Escape') {
      e.preventDefault();
      this.handleClose();
    }
  };

  render() {
    const { forwardedRef, isHidden } = this.props;
    const { query } = this.state;

    return (
      <>
        <IonHeader>
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonInput
                  id="search"
                  autocorrect="on"
                  placeholder={'search...'}
                  value={query}
                  clearInput
                  ref={forwardedRef}
                  onIonChange={this.handleType}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonHeader>
        <IonContent>
          {isHidden ? null : <Searcher onClose={this.handleClose} query={query} />}
        </IonContent>
      </>
    );
  }
}

const SearchWithDeps = withModalsState(withWorkspace(Search));

// eslint-disable-next-line react/display-name
export default forwardRef<any, ExtProps>((props: MinProps, ref: React.Ref<any>) => (
  <SearchWithDeps forwardedRef={ref} {...props} />
));
