import React from 'react';
import {
  ProjectEnrichedWithTaskStatsAndDependingProject,
  ProjectStatus,
} from '../../types/CoreTypes';
import { withStorage } from '../../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import { IonButton, IonIcon, IonLoading, IonNote } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';
import formatRelative from 'date-fns/formatRelative';
import { StorageDeps } from '../../helpers/InitStorage';
import TaskDateDueInfo from '../Tasks/TaskDateDueInfo';
import { TableCell } from '../Common/TableCell';
import { genericStyles } from '../../helpers/styles';
import { checkbox, create } from 'ionicons/icons';
import ProjectStatusInfo from './ProjectStatusInfo';
import { ProjectHandlers } from '../../types/ProjectHandlers';
import { DocumentEditorSync } from '../Common/DocumentEditor';
import PriorityInfo from '../Common/PriorityInfo';
import ProjectProgressIcon from './ProjectProgressIcon';
import { ModalsProps, withModalsState } from '../../helpers/ModalsProvider';
import ActionOnKeyPress from '../Common/ActionOnKeyPress';
import ProjectTimeWidget from './ProjectTimeWidget';
import { DateProps, withDate } from '../../helpers/DateProvider';
import ProjectDateStartInfo from './ProjectDateStartInfo';
import ProjectLink from '../Common/ProjectLink';
import FieldLabel from '../Common/FieldLabel';
import ProjectDuration from './ProjectDuration';

interface Props
  extends StorageDeps,
    RouteComponentProps,
    WorkspaceProps,
    ModalsProps,
    DateProps {
  project: ProjectEnrichedWithTaskStatsAndDependingProject | null;
  isLoading: boolean;
  projectHandlers: ProjectHandlers;
  onBlockSelectedChange?: (v: boolean) => void;
}

class ProjectDetailsInner extends React.PureComponent<Props> {
  setBlockSelected = () => {
    this.props.onBlockSelectedChange?.(true);
  };

  setBlockNotSelected = () => {
    this.props.onBlockSelectedChange?.(false);
  };

  render() {
    const {
      isLoading,
      project,
      projectHandlers: {
        onStartStateChange,
        onChangeDue,
        onChangeStart,
        onChangeDependsOn,
        onChangeDuration,
        onChangePriority,
        onChangeState,
        onChangeName,
        getHandlers,
      },
      date,
    } = this.props;
    if (!project) {
      if (isLoading) {
        return <IonLoading isOpen={isLoading} message={'Loading...'} />;
      }
      return <div>No project details found..</div>;
    }

    return (
      <div className="details-page details-page-project">
        <div>
          {getHandlers && (
            <ActionOnKeyPress
              name="project details"
              enabled={!this.props.nonMagicModalIsOpen}
              handlers={getHandlers(project)}
            />
          )}
          {onChangeName && (
            <h1
              style={genericStyles.clickable}
              onClick={() => onChangeName(project)}
            >
              <IonIcon color="medium" style={genericStyles.icon} icon={create} />
              {project.name}
            </h1>
          )}
          {!onChangeName && <h1>{project.name}</h1>}

          <div>
            <IonNote>
              Created: {formatRelative(project.createdAt, this.props.date)}
            </IonNote>
          </div>
          {project.lastUpdated && (
            <div>
              <IonNote>
                Modified: {formatRelative(project.lastUpdated, this.props.date)}
              </IonNote>
            </div>
          )}

          <div className="list-row">
            {project.status !== ProjectStatus.STATUS_ARCHIVE && (
              <TableCell spaceOut className="table-cell-status">
                <IonButton
                  size="small"
                  color="success"
                  onClick={() =>
                    onChangeState &&
                    onChangeState(ProjectStatus.STATUS_ARCHIVE, project)
                  }
                >
                  <IonIcon icon={checkbox} />
                  &nbsp; Done
                </IonButton>
              </TableCell>
            )}

            <TableCell spaceOut className="table-cell-status">
              <ProjectStatusInfo
                status={project.status}
                onClick={() => onStartStateChange && onStartStateChange(project)}
              />
            </TableCell>

            <TableCell
              spaceOut
              className="table-cell-small-text-with-label clickable"
            >
              <FieldLabel>Due:</FieldLabel>
              <TaskDateDueInfo
                currentDate={date}
                dateDue={project.dateDue}
                onClick={onChangeDue ? () => onChangeDue(project) : undefined}
              />
            </TableCell>
          </div>

          <div className="list-row list-row-wrap">
            <TableCell spaceOut className="large-icon">
              <PriorityInfo
                onClick={
                  onChangePriority ? () => onChangePriority(project) : undefined
                }
                priority={project.priorityValues}
              />
            </TableCell>

            <TableCell spaceOut className="table-cell clickable">
              <FieldLabel>Start:</FieldLabel>
              <ProjectDateStartInfo
                currentDate={date}
                dateStart={project.dateStart}
                onClick={onChangeStart ? () => onChangeStart(project) : undefined}
              />
            </TableCell>

            <TableCell spaceOut className="table-cell clickable">
              <FieldLabel>Duration:</FieldLabel>
              <ProjectDuration
                onClick={
                  onChangeDuration ? () => onChangeDuration(project) : undefined
                }
                duration={project.duration}
              />
            </TableCell>

            <TableCell spaceOut className="table-cell">
              <FieldLabel>Depends on:</FieldLabel>
              <ProjectLink
                onEdit={
                  onChangeDependsOn ? () => onChangeDependsOn(project) : undefined
                }
                project={project.dependsOnProject}
              />
            </TableCell>
          </div>

          <DocumentEditorSync
            isHidden={this.props.modalIsOpen}
            contextId={project.contextId}
            syncParams={{
              selector: { projectId: project.id },
              localBlockCollection: this.props._techDebt_localBlockDb,
            }}
            daoCatalog={this.props._techDebt_daoCatalog}
            onBlockBlur={this.setBlockNotSelected}
            onBlockFocus={this.setBlockSelected}
          />
        </div>

        <div className="list-row">
          <h2>Tasks</h2>
          <div
            style={{
              marginTop: '16px',
              marginLeft: '10px',
            }}
          >
            <ProjectProgressIcon project={project} width={30} />
          </div>

          <div
            style={{
              marginTop: '21px',
              marginLeft: '10px',
            }}
          >
            <ProjectTimeWidget project={project} />
          </div>
        </div>
      </div>
    );
  }
}

export default withDate(
  withModalsState(withRouter(withStorage(withWorkspace(ProjectDetailsInner))))
);
