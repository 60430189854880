import React from 'react';
import { IonButton, IonIcon, IonItemDivider } from '@ionic/react';
import { checkmark, close } from 'ionicons/icons';
import ActionOnKeyPress from '../Common/ActionOnKeyPress';
import MyIonModal from '../../helpers/MyIonModal';
import { Key, KeyModifier } from '../../types/CoreTypes';

interface Props {
  title: string;
  klass?: string;
  show: boolean;
  onClose: () => void;
  onSave: () => void;
  children: React.ReactNode;
  saveText?: string;
}

const PropertyEditModalWrapper = ({
  title,
  klass,
  onSave,
  onClose,
  show,
  children,
  saveText,
}: Props) => {
  const name = saveText ? saveText : 'save';
  const cssClass = klass || '';
  return (
    <MyIonModal
      animated={false}
      cssClass={`property-modal ${cssClass}`}
      isOpen={show}
      backdropDismiss={true}
      onDidDismiss={onClose}
    >
      {show && (
        <>
          <IonItemDivider>{title}</IonItemDivider>
          <div className="ion-padding">
            {children}

            <ActionOnKeyPress
              name={title}
              enabled={true}
              handlers={[
                {
                  action: onSave,
                  name,
                  combination: {
                    key: Key.Enter,
                    modifiers: [[KeyModifier.Cmd], [KeyModifier.Ctrl]],
                  },
                },
              ]}
            />

            <div style={{ display: 'flex' }}>
              <IonButton color="success" onClick={onSave}>
                <IonIcon slot="start" icon={checkmark} />
                {name}
              </IonButton>
              <IonButton slot="end" color="light" onClick={onClose}>
                <IonIcon slot="start" icon={close} />
                Close
              </IonButton>
            </div>
          </div>
        </>
      )}
    </MyIonModal>
  );
};

export default PropertyEditModalWrapper;
