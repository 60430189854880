import { IonList, IonReorderGroup } from '@ionic/react';
import React from 'react';
import { ContextType } from '../../types/CoreTypes';
import ContextListRow from './ContextListRow';
import { ItemReorderEventDetail } from '@ionic/core';
import { withStorage } from '../../helpers/StorageProviders';
import { swap } from '../../helpers/PositionHelper';
import { debounce, isEqual } from 'lodash';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import { StorageDeps } from '../../helpers/InitStorage';
import { Subscription } from 'rxjs';
import AreYouSureModal from '../propertyEditModals/AreYouSureModal';

interface Props extends WorkspaceProps, StorageDeps {
  onChangeContext: (
    contextData: Partial<ContextType>,
    selectedContext: ContextType | null
  ) => void;
  onDeleteContext: (id: string) => Promise<void>;
  setCounter?: (count: number) => void;
  selectedIdx?: number;
  getRef?: (idx: number) => React.RefObject<any>;
}

interface State {
  contexts: ContextType[];
  selectedForDeletionId?: string;
}

class ContextListView extends React.Component<Props, State> {
  state: State = {
    contexts: [],
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (!isEqual(prevState.contexts, this.state.contexts)) {
      this.refreshCounters();
    }
  }

  refreshCounters = () => {
    this.props.setCounter && this.props.setCounter(this.state.contexts.length);
  };

  setContexts = (contexts: ContextType[]) => {
    this.setState({ contexts });
  };

  setContextsDebounced = debounce((contexts: ContextType[]) => {
    this.setContexts(contexts);
  }, 200);

  dbUpdateSubscription?: Subscription;

  componentDidMount(): void {
    this.dbUpdateSubscription = this.props.repos.contextRepo
      .getAll$()
      .subscribe(this.setContextsDebounced);
  }

  doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    return (
      this.props.repos.contextRepo
        .reorder(this.state.contexts, event.detail.from, event.detail.to)
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        .then(() => event.detail.complete())
    );
  };

  manualReorder = (up: boolean) => {
    const idx = this.props.selectedIdx;
    if (idx === undefined) return Promise.resolve(false);
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    const to = up ? idx - 1 : idx + 1;
    // important, to read it before all following ops
    const { contexts } = this.state;
    if (to < 0 || to >= contexts.length) return Promise.resolve(false);
    this.setContextsDebounced.cancel();
    this.setContextsDebounced(swap(contexts, idx, to));
    this.setContextsDebounced.flush();
    return this.props.repos.contextRepo.reorder(contexts, idx, to).then(() => true);
  };

  componentWillUnmount() {
    this.dbUpdateSubscription?.unsubscribe();
  }

  selectForDelete = (id: string) => {
    this.setState({ selectedForDeletionId: id });
  };

  handleDelete = () => {
    if (this.state.selectedForDeletionId) {
      this.props.onDeleteContext(this.state.selectedForDeletionId);
      this.setState({ selectedForDeletionId: undefined });
    }
  };

  handleCloseDeleteModal = () => {
    if (this.state.selectedForDeletionId) {
      this.setState({ selectedForDeletionId: undefined });
    }
  };

  render() {
    const { getRef, selectedIdx } = this.props;
    const { contexts, selectedForDeletionId } = this.state;

    return (
      <>
        <IonList>
          <IonReorderGroup disabled={false} onIonItemReorder={this.doReorder}>
            {contexts.map((context, idx) => (
              <ContextListRow
                manualReorder={this.manualReorder}
                ref={getRef ? getRef(idx) : undefined}
                key={context.id}
                isSelected={idx === selectedIdx}
                context={context}
                onDelete={this.selectForDelete}
              />
            ))}
          </IonReorderGroup>
        </IonList>
        <AreYouSureModal
          show={!!selectedForDeletionId}
          text={
            'If you delete the context, all data in this context will be lost and you cannot recover it'
          }
          onClose={this.handleCloseDeleteModal}
          onAccept={this.handleDelete}
        />
      </>
    );
  }
}

export default withStorage(withWorkspace(ContextListView));
