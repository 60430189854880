const schema = {
  keyCompression: false,
  title: 'setting schema',
  version: 0,
  description: 'describes a setting',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    value: {
      type: 'string',
    },
  },
  required: ['id', 'value'],
};

export default schema;
