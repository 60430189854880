import React from 'react';
import { IonContent, IonPage, IonTitle } from '@ionic/react';
import { TaskStatus } from '../types/CoreTypes';
import ReviewTaskListView from '../components/Tasks/ReviewTaskListView';
import CommonHeader from '../components/Common/CommonHeader';

const ReviewPage: React.FunctionComponent = () => {
  return (
    <IonPage>
      <CommonHeader>
        <IonTitle>Review your tasks</IonTitle>
      </CommonHeader>
      <IonContent>
        <ReviewTaskListView
          filter={{
            statusIn: [TaskStatus.STATUS_SOME_TIME, TaskStatus.STATUS_WAITING],
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default ReviewPage;
