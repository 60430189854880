import {
  Nullable,
  Priority,
  ProjectStatus,
  TaskEnergy,
  TaskStatus,
  TaskTime,
} from '../types/CoreTypes';
import { ItemType } from '@todo/common';

export function getPriorityLabel(priority?: Priority): string {
  switch (priority) {
    case Priority.PRIORITY_LOW:
      return 'P3';
    case Priority.PRIORITY_MEDIUM:
      return 'P2';
    case Priority.PRIORITY_HIGH:
      return 'P1';
    default:
      return 'P?';
  }
}

export function getTimeLabel(time: Nullable<TaskTime> | null): string {
  switch (time) {
    case TaskTime.TIME_2_MIN:
      return '2m';
    case TaskTime.TIME_5_MIN:
      return '5m';
    case TaskTime.TIME_10_MIN:
      return '10m';
    case TaskTime.TIME_15_MIN:
      return '15m';
    case TaskTime.TIME_30_MIN:
      return '30m';
    case TaskTime.TIME_45_MIN:
      return '45m';
    case TaskTime.TIME_1_H:
      return '1h';
    case TaskTime.TIME_2_H:
      return '2h';
    case TaskTime.TIME_3_H:
      return '3h';
    case TaskTime.TIME_6_H:
      return '6h';
    case TaskTime.TIME_8_H:
      return '8h';
    case TaskTime.TIME_1_D:
      return '1d';
    case TaskTime.TIME_2_D:
      return '2d';
    case TaskTime.TIME_3_D:
      return '3d';
    case TaskTime.TIME_4_D:
      return '4d';
    case TaskTime.TIME_5_D:
      return '5d';
    case TaskTime.TIME_6_D:
      return '6d';
    case TaskTime.TIME_7_D:
      return '7d';
    case TaskTime.TIME_8_D:
      return '8d';
    case TaskTime.TIME_9_D:
      return '9d';
    case TaskTime.TIME_10_D:
      return '10d';
    case TaskTime.TIME_11_D:
      return '11d';
    case TaskTime.TIME_12_D:
      return '12d';
    case TaskTime.TIME_13_D:
      return '13d';
    case TaskTime.TIME_14_D:
      return '14d';
    default:
      return 'T?';
  }
}

export function getEnergyLabel(energy: Nullable<TaskEnergy> | null) {
  switch (energy) {
    case TaskEnergy.ENERGY_LOW:
      return 'low';
    case TaskEnergy.ENERGY_MED:
      return 'med';
    case TaskEnergy.ENERGY_HIGH:
      return 'high';
    default:
      return 'E?';
  }
}

export function getTaskStatusLabel(status: TaskStatus) {
  switch (status) {
    case TaskStatus.STATUS_INBOX:
      return 'Inbox';
    case TaskStatus.STATUS_SOME_TIME:
      return 'Someday';
    case TaskStatus.STATUS_SCHEDULED:
      return 'Planned';
    case TaskStatus.STATUS_ACTION_LIST:
      return 'Next';
    case TaskStatus.STATUS_IN_PROGRESS:
      return 'Focus';
    case TaskStatus.STATUS_WAITING:
      return 'Waiting';
    case TaskStatus.STATUS_DONE:
      return 'Done';
  }
}

export function getProjectStatusLabel(status: ProjectStatus) {
  switch (status) {
    case ProjectStatus.STATUS_SOME_TIME:
      return 'Someday';
    case ProjectStatus.STATUS_ACTION:
      return 'Progress';
    case ProjectStatus.STATUS_NEXT:
      return 'Next';
    case ProjectStatus.STATUS_WAITING:
      return 'Waiting';
    case ProjectStatus.STATUS_ARCHIVE:
      return 'Archive';
  }
}

export function getItemTypeLabel(kind: ItemType) {
  switch (kind) {
    case ItemType.project: {
      return 'project';
    }
    case ItemType.context: {
      return 'context';
    }
    case ItemType.settings: {
      return 'setting';
    }
    case ItemType.block: {
      return 'block';
    }
    case ItemType.task: {
      return 'task';
    }
    case ItemType.tag: {
      return 'tag';
    }
    case ItemType.achievement: {
      return 'achievement';
    }
  }
}
