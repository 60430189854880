const schema = {
  keyCompression: false,
  title: 'task schema',
  version: 0,
  description: 'describes a task',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    name: {
      type: 'string',
    },
    dateScheduled: {
      type: 'string',
    },
    dateDue: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    projectId: {
      ref: 'project',
      type: 'string',
    },
    contextId: {
      ref: 'context',
      type: 'string',
    },
    time: {
      type: 'number',
    },
    energy: {
      type: 'number',
    },
    positionProject: {
      type: 'number',
    },
    positionStatus: {
      type: 'number',
    },
    positionCalendar: {
      type: 'number',
    },
    priorityValues: {
      type: 'object',
      properties: {
        preset: {
          type: 'number',
        },
        ice: {
          type: 'object',
          properties: {
            impact: {
              type: 'number',
            },
            confidence: {
              type: 'number',
            },
            ease: {
              type: 'number',
            },
          },
        },
      },
    },
    tags: {
      type: 'array',
      maxItems: 10,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'string',
          },
          position: {
            type: 'number',
          },
        },
      },
    },
    recurringOptions: {
      type: 'object',
      properties: {
        starting: {
          type: 'object',
          properties: {
            date: {
              type: 'string',
            },
          },
        },
        endsAfter: {
          type: 'object',
          properties: {
            date: {
              type: 'string',
            },
            occurrences: {
              type: 'number',
            },
          },
        },
        repeatingEvery: {
          type: 'object',
          properties: {
            // every X days
            days: {
              type: 'object',
              properties: {
                days: {
                  type: 'number',
                },
              },
            },
            // every Y weeks on these weekdays
            weeks: {
              type: 'object',
              properties: {
                weeks: {
                  type: 'number',
                },
                weekdays: {
                  type: 'array',
                  maxItems: 7,
                  uniqueItems: true,
                  items: {
                    type: 'number',
                  },
                },
              },
            },
            // every Z months on first weekday, or on day Z
            months: {
              type: 'object',
              properties: {
                months: {
                  type: 'number',
                },
                date: {
                  type: 'number',
                },
                weekday: {
                  type: 'object',
                  properties: {
                    weekday: {
                      type: 'number',
                    },
                    weekdayNumber: {
                      type: 'number',
                    },
                  },
                },
              },
            },
            // every Z years?
            years: {
              type: 'object',
              properties: {
                years: {
                  type: 'number',
                },
              },
            },
          },
        },
      },
    },
    completionHistory: {
      type: 'array',
      maxItems: 100,
      uniqueItems: true,
      items: {
        type: 'object',
        properties: {
          timestamp: {
            type: 'number',
          },
          dueDate: {
            type: 'string',
          },
        },
      },
    },
    completedAt: {
      type: 'number',
    },
    createdAt: {
      type: 'number',
      final: true,
    },
    lastUpdated: {
      type: 'number',
    },
    statusUpdatedAt: {
      type: 'number',
    },
    serverUpdatedAt: {
      type: 'number',
    },
    last_pulled_rev: {
      type: 'string',
    },
    deviceId: {
      type: 'string',
    },
    data: {
      type: 'object',
    },
    // userId: {
    //   ref: 'task',
    //   type: 'string',
    // },
  },
  required: ['id', 'name', 'status', 'contextId'],
  // encrypted: ['secret'],
  // attachments: {
  //   encrypted: true
  // },
  indexes: [
    ['contextId', 'status', 'dateDue'],
    ['contextId', 'status', 'dateScheduled'],
    ['contextId', 'status', 'recurringOptions.starting.date'],
    ['contextId', 'status', 'statusUpdatedAt'],
    ['projectId', 'completedAt'],
    ['contextId', 'completedAt'],
    ['projectId', 'status'],
    ['contextId', 'status', 'projectId'],
    ['contextId', 'createdAt'],
    'status',
  ],
};

export default schema;
