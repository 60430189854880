import React from 'react';
import { TagType } from '../../types/CoreTypes';
import TagLink from './TagLink';

interface Props {
  tag: TagType | null;
  disableLink?: boolean;
}

const SingleTag = ({ tag, disableLink }: Props) => {
  return (
    <div
      style={{
        fontSize: '120%',
        fontWeight: 'bold',
        display: 'flex',
      }}
    >
      <TagLink tag={tag} disableLink={disableLink} />
    </div>
  );
};

export default SingleTag;
