import React from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { colors } from '../../helpers/styles';

interface Props {
  width: number;
  percentage: number;
  color: string;
  text: string | number;
}

const ProgressIcon = ({ width, color, percentage, text }: Props) => {
  return (
    <div style={{ width, border: '2px solid #eee', borderRadius: 100 }}>
      <CircularProgressbarWithChildren
        styles={buildStyles({
          strokeLinecap: 'butt',
          backgroundColor: '#fff',
          pathColor: color,
          trailColor: colors.white,
        })}
        background
        backgroundPadding={6}
        value={percentage}
        strokeWidth={10}
      >
        <span
          style={{
            color,
          }}
        >
          {text}
        </span>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default ProgressIcon;
