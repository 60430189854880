import React, { forwardRef } from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonRow,
  IonToast,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import {
  ContextType,
  Key,
  KeyModifier,
  Nullable,
  PriorityValues,
  ProjectType,
  RecurringOptions,
  TagAssignment,
  TagType,
  TaskCreateFormFillable,
  TaskEnergy,
  TaskStatus,
  TaskTime,
} from '../../types/CoreTypes';
import { DocumentEditorSync } from '../Common/DocumentEditor';
import { DaoCatalog, StorageDeps } from '../../helpers/InitStorage';
import TaskNameMention, { MentionType, Ops } from './TaskNameMention';
import { TASK_NAME_MAX_LENGTH } from '../../lib/Constants';
import { first, isEqual, uniqWith } from 'lodash';
import HotKeyQuickAdd from '../Common/HotKeyQuickAdd';
import ActionOnKeyPress from '../Common/ActionOnKeyPress';
import CharLimit from '../Common/CharLimit';
import ProjectLink from '../Common/ProjectLink';
import { withStorage } from '../../helpers/StorageProviders';
import TagsWidget from '../Common/TagsWidget';
import { ModalsProps, withModalsState } from '../../helpers/ModalsProvider';
import PriorityInfo from '../Common/PriorityInfo';
import { getTaskColorLabel, getTaskIcon } from '../../helpers/TaskHelper';
import { getTaskStatusLabel } from '../../helpers/CopyHelper';
import TaskWrapperDateInfo from './TaskWrapperDateInfo';
import TaskActionsUI from './TaskActionsUI';
import { TaskHandlersInner } from '../../types/TaskHandlers';
import TimeInfo from '../Common/TimeInfo';
import EnergyInfo from '../Common/EnergyInfo';
import { ClientTypeProps, withClientType } from '../../helpers/ClientTypeProvider';
import { DateProps, withDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';
import QuickAddContextSwitcher from '../Common/QuickAddContextSwitcher';
import { ItemType } from '@todo/common';
import {
  FreeTierEntity,
  FreeTierProps,
  withFreeTier,
} from '../../helpers/FreeTierProvider';
import UpgradeBanner from '../Common/UpgradeBanner';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';

interface MinProps {
  onClose: () => void;
  onSave: (args: TaskCreateFormFillable) => Promise<void>;
  isOpen: boolean;
  daoCatalog: DaoCatalog;
  defaultStatus?: TaskStatus;
  defaultProject?: ProjectType;
  defaultTag?: TagType;
}

interface ExtProps extends MinProps {
  forwardedRef?: React.Ref<any>;
}

interface Props
  extends ExtProps,
    StorageDeps,
    ModalsProps,
    ClientTypeProps,
    FreeTierProps,
    WorkspaceProps,
    DateProps {}

interface Status {
  status: TaskStatus;
}

const statuses: Status[] = [
  {
    status: TaskStatus.STATUS_INBOX,
  },
  {
    status: TaskStatus.STATUS_SOME_TIME,
  },
  {
    status: TaskStatus.STATUS_ACTION_LIST,
  },
  {
    status: TaskStatus.STATUS_IN_PROGRESS,
  },
  {
    status: TaskStatus.STATUS_WAITING,
  },
  {
    status: TaskStatus.STATUS_SCHEDULED,
  },
];

interface State {
  task: TaskCreateFormFillable;
  error?: string;
  editOpsStatus?: Ops;
}

// eslint-disable-next-line react/display-name
class QuickAddFormTask extends React.Component<Props, State> {
  private documentEditorRef: React.RefObject<DocumentEditorSync>;
  private readonly modalRefs: Map<Ops, React.RefObject<any>> = new Map();

  constructor(props: Props) {
    super(props);
    this.documentEditorRef = React.createRef();

    Object.keys(Ops).forEach((k) => {
      this.modalRefs.set((k as unknown) as Ops, React.createRef());
    });

    this.state = {
      task: this.getDefaultTaskData(props),
    };
  }

  getDefaultTaskData(props: Props): TaskCreateFormFillable {
    const status =
      props.defaultStatus ||
      (props.defaultProject
        ? TaskStatus.STATUS_ACTION_LIST
        : TaskStatus.STATUS_INBOX);
    return {
      name: '',
      project: props.defaultProject || null,
      tags: props.defaultTag ? [{ id: props.defaultTag.id }] : undefined,
      tagsData: props.defaultTag ? [props.defaultTag] : [],
      status,
      contextId: props.context.id,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.context.id !== prevProps.context.id) {
      this.setState((st) => ({
        task: {
          ...st.task,
          contextId: this.props.context.id,
        },
      }));
    }
  }

  setTask(task: TaskCreateFormFillable) {
    this.setState({ task });
  }
  setError(error?: string) {
    this.setState({ error });
  }

  setValue = (fields: Partial<TaskCreateFormFillable>) => {
    this.setTask({ ...this.state.task, ...fields });
  };

  handleSave = async () => {
    this.setError(undefined);
    const dump = await this.documentEditorRef.current?.dump();
    // const { name, mentions } = this.parseTextInput(this.state.name);
    const task = { ...this.state.task, blocks: dump ? dump.docs : [] };

    this.props
      .onSave(task)
      .then(() => {
        this.setState({
          task: this.getDefaultTaskData(this.props),
        });
        this.documentEditorRef.current?.eraseDb();
      })
      .catch((err) => this.setError(err.toString()));
  };

  handleArrowUp = () => {
    this.focus();
  };

  focus = () => {
    // @ts-ignore
    if (this.props.forwardedRef && this.props.forwardedRef.current) {
      // @ts-ignore
      this.props.forwardedRef.current.focus();
    }
  };

  appendDependentFields = (
    form: Partial<TaskCreateFormFillable>
  ): Partial<TaskCreateFormFillable> => {
    const { task } = this.state;
    // form status changed
    if (form.status) {
      if (
        form.status === TaskStatus.STATUS_SCHEDULED &&
        !task.dateScheduled &&
        !task.recurringOptions
      ) {
        form.status = form.project
          ? TaskStatus.STATUS_ACTION_LIST
          : this.props.defaultStatus || TaskStatus.STATUS_INBOX;
      }
      if (form.status !== TaskStatus.STATUS_SCHEDULED && task.dateScheduled) {
        form.dateScheduled = undefined;
      }
      if (form.status !== TaskStatus.STATUS_SCHEDULED && task.recurringOptions) {
        form.recurringOptions = undefined;
      }
    }

    // project or schedule changed
    if (!form.status) {
      if (task.status === TaskStatus.STATUS_INBOX) {
        if (form.project) {
          form.status = TaskStatus.STATUS_ACTION_LIST;
        }
        if (form.dateScheduled || form.recurringOptions) {
          form.status = TaskStatus.STATUS_SCHEDULED;
        }
      }
      if (task.status !== TaskStatus.STATUS_INBOX) {
        if (!form.project && 'project' in form) {
          form.status =
            task.dateScheduled || task.recurringOptions
              ? TaskStatus.STATUS_SCHEDULED
              : this.props.defaultStatus || TaskStatus.STATUS_INBOX;
        }
        if (!form.dateScheduled && 'dateScheduled' in form) {
          form.status = task.project
            ? TaskStatus.STATUS_ACTION_LIST
            : this.props.defaultStatus || TaskStatus.STATUS_INBOX;
        }
        if (!form.recurringOptions && 'recurringOptions' in form) {
          form.status = task.project
            ? TaskStatus.STATUS_ACTION_LIST
            : this.props.defaultStatus || TaskStatus.STATUS_INBOX;
        }
        if (form.dateScheduled || form.recurringOptions) {
          form.status = TaskStatus.STATUS_SCHEDULED;
        }
      }
    }
    return form;
  };

  private REGEX = /(@{{(\w+)\|\|([\w-]+)\|\|(.+)}})/gm;

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: any): boolean {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  setProject = (projectId: string | undefined) => {
    if (projectId) {
      this.props.repos.projectRepo.getById(projectId).then((project) => {
        if (project) this.updateTaskData({ projectId, project });
      });
    } else {
      this.updateTaskData({ project: undefined, projectId: undefined });
    }
  };

  handleTagsChange = (tags: TagAssignment[] | undefined) =>
    this.setTags(tags?.map((t) => t.id));

  setTags = (tagsIds: string[] | undefined) => {
    if (tagsIds) {
      tagsIds.forEach((tagId) => {
        this.props.repos.tagRepo.getById(tagId).then((tag) => {
          if (tag) {
            const { task } = this.state;
            const tagsData = uniqWith([...(task.tagsData || []), tag], isEqual);
            const tags = uniqWith([...(task.tags || []), { id: tag.id }], isEqual);
            this.updateTaskData({
              tagsData,
              tags,
            });
          }
        });
      });
    } else {
      this.updateTaskData({ tags: undefined, tagsData: undefined });
    }
  };

  setSnooze = (dateScheduled?: string) => {
    this.updateTaskData({ dateScheduled });
  };

  setRecurring = (recurringOptions: RecurringOptions | undefined) => {
    if (recurringOptions) {
      this.updateTaskData({
        recurringOptions: recurringOptions,
        dateDue: undefined,
      });
      return;
    }
    this.updateTaskData({
      recurringOptions: undefined,
    });
  };

  setDue = (dateDue?: string) => {
    this.updateTaskData({ dateDue });
  };

  setPriorities = (priorityValues?: PriorityValues) => {
    this.updateTaskData({ priorityValues });
  };

  handleNameChangeInner = (name: string) => {
    this.setState(({ task }) => ({
      task: {
        ...task,
        name,
      },
    }));
  };

  handleNameChange = (taskHandlers: TaskHandlersInner) => (fullName: string) => {
    const { name, mentions } = this.parseTextInput(fullName);
    logDebug([], 'mentions', { mentions, name });

    const projOrOpsId = first(mentions.get(MentionType.project));
    if (projOrOpsId) {
      if (Object.keys(Ops).includes(projOrOpsId)) {
        taskHandlers.onChangeProject && taskHandlers.onChangeProject();
        // this.startEditing(projOrOpsId as Ops);
      } else {
        this.setProject(projOrOpsId);
      }
    }

    const tagsIds = mentions.get(MentionType.tag);
    if (tagsIds) {
      this.setTags(tagsIds);
    }

    let newName = name.replace(/\n/g, '');

    if (mentions.size) {
      // if mentions were added, there could be consecutive spaces at the end, so leave only one
      newName = newName.replace(/  +/g, ' ');
    }

    this.handleNameChangeInner(newName);
  };

  updateTaskData = (updatedTask: Partial<TaskCreateFormFillable>) => {
    const { task } = this.state;
    const form = this.appendDependentFields(updatedTask);
    this.setState({
      task: {
        ...task,
        ...form,
      },
    });
  };

  getPlainText = (str: string) => str.replace(this.REGEX, ''); //.trim();

  getMatches = (str: string) =>
    Array.from(str.matchAll(this.REGEX), (m) => ({
      type: (m[2] as unknown) as MentionType,
      id: m[3],
    }));

  parseTextInput = (str: string) => {
    const mentionSet = uniqWith(this.getMatches(str), isEqual);
    const mentions: Map<MentionType, string[]> = new Map();

    for (const mention of Array.from(mentionSet.values())) {
      mentions.set(mention.type, [
        ...(mentions.get(mention.type) || []),
        mention.id,
      ]);
    }

    return { name: this.getPlainText(str), mentions };
  };

  getRef = (status: Ops) => this.modalRefs.get(status);
  focusRef = (status: Ops) => {
    // console.log('$$$', this.modalRefs);
    // debugger;
    const current = this.modalRefs.get(status)?.current;
    if (current) {
      logDebug([], `focus: ${status}`, current);
      if (current.setFocus) {
        current.setFocus();
      } else if (current.focus) {
        current.focus();
      } else {
        throw Error('Cannot focus: dont know how to focus!');
      }
    } else {
      throw Error(`Cannot focus ${status}: no current ref!`);
    }
  };

  removeProject = () => {
    this.updateTaskData({ project: undefined });
  };

  removeTag = (id: string) => {
    const { task } = this.state;
    this.updateTaskData({ tags: task.tags?.filter((t) => t.id !== id) });
  };

  isOps = (desiredStatus: Ops) => {
    return this.state.editOpsStatus === desiredStatus;
  };

  basicCloseHandler = () => {
    this.setState({ editOpsStatus: undefined });
    this.focus();
  };

  setStatus = (status: TaskStatus | undefined) => {
    if (!status) return;
    this.updateTaskData({ status });
    this.focus();
  };

  setTime = (time: Nullable<TaskTime>) => {
    this.updateTaskData({ time });
  };

  setEnergy = (energy: Nullable<TaskEnergy>) => {
    this.updateTaskData({ energy });
  };

  render() {
    const { task, error } = this.state;
    const { onClose, forwardedRef, isDesktop, hasUsedFreeTier } = this.props;

    if (hasUsedFreeTier(FreeTierEntity.task)) {
      return (
        <div ref={forwardedRef}>
          <IonHeader>
            <IonToolbar>
              <IonButton slot="end" color="light" onClick={onClose}>
                <IonIcon icon={close} />
                Close
              </IonButton>
            </IonToolbar>
          </IonHeader>
          <UpgradeBanner onAfterNavigate={onClose}>
            Please upgrade to Premium Access to create more tasks!
          </UpgradeBanner>
        </div>
      );
    }

    return (
      <TaskActionsUI
        selectedTask={this.state.task}
        onClose={this.basicCloseHandler}
        handleChangePriority={this.setPriorities}
        handleChangeTags={this.handleTagsChange}
        handleChangeProjectId={this.setProject}
        handleChangeName={this.handleNameChangeInner}
        handleRecurring={this.setRecurring}
        handleReschedule={this.setSnooze}
        handleChangeDue={this.setDue}
        handleChangeTime={this.setTime}
        handleChangeEnergy={this.setEnergy}
        handleChangeState={this.setStatus}
      >
        {(taskHandlers) => (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.handleSave();
            }}
          >
            <IonHeader>
              <IonToolbar>
                <IonGrid>
                  <IonRow>
                    <IonCol size="12">
                      <QuickAddContextSwitcher
                        onClose={this.focus}
                        kind={ItemType.task}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonButton slot="end" color="light" onClick={onClose}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonToolbar>
            </IonHeader>
            <div className="ion-padding">
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    {isDesktop && (
                      <TaskNameMention
                        ref={forwardedRef}
                        value={task.name}
                        onChange={this.handleNameChange(taskHandlers)}
                      />
                    )}
                    {!isDesktop && (
                      <IonInput
                        maxlength={TASK_NAME_MAX_LENGTH}
                        id="quickAddInput"
                        autocorrect="on"
                        placeholder="Task name"
                        value={task.name}
                        clearInput
                        ref={forwardedRef}
                        onIonChange={(event: any) =>
                          this.updateTaskData({ name: event.target.value })
                        }
                      />
                    )}
                    <CharLimit
                      len={this.getPlainText(this.state.task.name).length}
                      max={TASK_NAME_MAX_LENGTH}
                    />
                  </IonCol>
                </IonRow>
                <DocumentEditorSync
                  contextId="fake"
                  inMemory={true}
                  ref={this.documentEditorRef}
                  isHidden={!this.props.isOpen || this.props.secondLayerModalIsOpen}
                  onArrowUp={this.handleArrowUp}
                  daoCatalog={this.props.daoCatalog}
                />

                <div style={{ display: 'flex' }}>
                  <IonGrid>
                    <IonCol sizeXs="12" sizeSm="12" className="list-row">
                      <div className="task-status-input list-row">
                        {statuses.map((status) => (
                          <IonButton
                            key={`status-button-${status.status}`}
                            size="small"
                            color={
                              status.status === task.status
                                ? getTaskColorLabel(status.status)
                                : 'light'
                            }
                            onClick={() => {
                              if (status.status === TaskStatus.STATUS_SCHEDULED) {
                                taskHandlers.onReschedule &&
                                  taskHandlers.onReschedule();
                              } else {
                                this.setStatus(status.status);
                              }
                            }}
                          >
                            <IonIcon
                              slot="start"
                              icon={getTaskIcon(status.status)}
                            />
                            {getTaskStatusLabel(status.status)}
                          </IonButton>
                        ))}
                      </div>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="6" className="list-row">
                      <div>
                        <ProjectLink
                          disableLink
                          onElementClick={taskHandlers.onChangeProject}
                          onEdit={taskHandlers.onChangeProject}
                          project={task.project || null}
                        />
                      </div>
                    </IonCol>
                    <IonCol sizeXs="12" sizeSm="6" className="list-row">
                      <div>
                        <TagsWidget
                          disableLink
                          onElementClick={taskHandlers.onChangeTags}
                          onEdit={taskHandlers.onChangeTags}
                          tags={task.tagsData || null}
                        />
                      </div>
                    </IonCol>
                    <IonCol sizeXs="12" className="list-row">
                      <TaskWrapperDateInfo
                        currentDate={this.props.date}
                        Wrapper={({ children }) => <div>{children}</div>}
                        task={task}
                        clickHandlers={{
                          reschedule: taskHandlers.onReschedule,
                          recurring: taskHandlers.onSetRecurring,
                          due: taskHandlers.onChangeDue,
                        }}
                      />
                    </IonCol>
                    <IonCol sizeXs="12" className="list-row">
                      <div>
                        <PriorityInfo
                          onClick={taskHandlers.onChangePriority}
                          priority={task.priorityValues}
                        />
                      </div>

                      <div>
                        <TimeInfo
                          onClick={taskHandlers.onChangeTime}
                          time={task.time}
                        />
                      </div>

                      <div>
                        <EnergyInfo
                          onClick={taskHandlers.onChangeEnergy}
                          energy={task.energy}
                        />
                      </div>
                    </IonCol>
                  </IonGrid>
                </div>
              </IonGrid>
              <HotKeyQuickAdd />
              <ActionOnKeyPress
                name="quick add form task"
                enabled={
                  this.props.isOpen && !this.props.secondLayerModalIsOpenIncMagic
                }
                handlers={[
                  {
                    action: this.handleSave,
                    name: 'save',
                    combination: {
                      key: Key.Enter,
                      modifiers: [[KeyModifier.Cmd], [KeyModifier.Ctrl]],
                    },
                  },
                  {
                    action: this.props.onClose,
                    name: 'close',
                    combination: {
                      key: Key.Escape,
                    },
                  },
                ]}
              />
              {taskHandlers.getHandlers && (
                <ActionOnKeyPress
                  name="quick add form task"
                  enabled={this.props.isOpen && !this.props.secondLayerModalIsOpen}
                  handlers={taskHandlers.getHandlers()}
                />
              )}
              <IonButton type="submit">Submit</IonButton>
              <IonToast
                isOpen={Boolean(error)}
                onDidDismiss={() => this.setError(undefined)}
                message={error || ''}
                position="top"
                color="danger"
                duration={3000}
              />
            </div>
          </form>
        )}
      </TaskActionsUI>
    );
  }
}

const WithDeps = withFreeTier(
  withWorkspace(
    withDate(withClientType(withModalsState(withStorage(QuickAddFormTask))))
  )
);

// eslint-disable-next-line react/display-name
export default forwardRef<any, ExtProps>((props: MinProps, ref: React.Ref<any>) => (
  <WithDeps forwardedRef={ref} {...props} />
));
