import React from 'react';
import { IonRouterLink } from '@ionic/react';
import { TagType } from '../../types/CoreTypes';
import { genericStyles } from '../../helpers/styles';
import { Routes } from '../../lib/routes';
import Tag from './Tag';

interface Props {
  tag: TagType | null;
  disableLink?: boolean;
  onAfterNavigate?: () => void;
  onClick?: (id: string) => void;
}

const TagLink = ({ tag, disableLink, onAfterNavigate, onClick }: Props) => {
  if (!tag) return <span style={genericStyles.grayOutColor}>no tag</span>;

  const content = <Tag onClick={onClick} tag={tag} />;

  if (disableLink) {
    return <div>{content}</div>;
  }

  return (
    <IonRouterLink
      onClick={onAfterNavigate}
      routerLink={`/${Routes.tags}/details/${tag.id}`}
      routerDirection={'root'}
    >
      {content}
    </IonRouterLink>
  );
};

export default TagLink;
