import React from 'react';
import { RecurringOptions } from '../../types/CoreTypes';
import format from 'date-fns/format';
import { daysFull } from '../../lib/DateHelper';
import { IonIcon } from '@ionic/react';
import { genericStyles } from '../../helpers/styles';
import { repeat } from 'ionicons/icons';
import {
  makeDateWithoutTime,
  recurringIsRepeatedScheduledOnDate,
} from '../../helpers/RecurringHelper';
import { dateAsHuman } from '../../lib/utils/DateTimeUtils';
import { CompletionHistory } from '@todo/common';
import { isPast, isToday } from 'date-fns';

interface Props {
  date: Date;
  recurring?: RecurringOptions;
  completionHistory?: CompletionHistory[];
  onClick?: () => void;
}

function getColor(recurring: RecurringOptions | undefined, dueSince: Date | null) {
  if (!recurring) return genericStyles.grayOutColor;
  if (dueSince) {
    if (isPast(dueSince)) return genericStyles.dangerColor;
    if (isToday(dueSince)) return genericStyles.dangerColor;
  }
  return genericStyles.primaryColor;
}

function renderLabel(
  recurring: RecurringOptions | undefined,
  completionHistory: CompletionHistory[] | undefined,
  dueSince: Date | null
) {
  if (!recurring) return <span style={genericStyles.iconLabel}>none</span>;

  let fullMsg = ``;
  let shortMsg = ``;
  if (recurring.repeatingEvery.days) {
    fullMsg = `Every ${recurring.repeatingEvery.days.days} ${
      recurring.repeatingEvery.days.days === 1 ? 'day' : 'days'
    }`;
    if (recurring.repeatingEvery.days.afterLastCompleted) {
      fullMsg += ' after completed';
    }
    shortMsg = `${recurring.repeatingEvery.days.days}d`;
  }
  if (recurring.repeatingEvery.weeks) {
    fullMsg = `Every ${recurring.repeatingEvery.weeks.weeks} ${
      recurring.repeatingEvery.weeks.weeks === 1 ? 'week' : 'weeks'
    } on ${recurring.repeatingEvery.weeks.weekdays
      .sort()
      .map((d) => daysFull[d])
      .join(', ')}`;
    shortMsg = `${recurring.repeatingEvery.weeks.weeks}wk`;
  }
  if (recurring.repeatingEvery.months) {
    fullMsg = `Every ${recurring.repeatingEvery.months.months} ${
      recurring.repeatingEvery.months.months === 1 ? 'month' : 'months'
    }`;
    if (recurring.repeatingEvery.months?.weekday) {
      fullMsg += ` ${
        recurring.repeatingEvery.months?.weekday.weekdayNumber > 4
          ? 'last'
          : recurring.repeatingEvery.months?.weekday.weekdayNumber
      } 
                ${
                  daysFull[recurring.repeatingEvery.months?.weekday.weekday]
                } of month`;
    }
    if (recurring.repeatingEvery.months?.date) {
      fullMsg += ` on day ${recurring.repeatingEvery.months?.date}`;
    }
    shortMsg = `${recurring.repeatingEvery.months.months}mo`;
  }
  if (recurring.repeatingEvery.years) {
    fullMsg = `Every ${recurring.repeatingEvery.years.years} ${
      recurring.repeatingEvery.years.years === 1 ? 'year' : 'years'
    }`;
    if (recurring.repeatingEvery.years.afterLastCompleted) {
      fullMsg += ' after completed';
    }
    shortMsg = `${recurring.repeatingEvery.years.years}yr`;
  }

  if (recurring.endsAfter.occurrences) {
    fullMsg += ` ${recurring.endsAfter.occurrences} times`;
  }

  if (recurring.endsAfter.date) {
    fullMsg += ` until 
            ${format(makeDateWithoutTime(recurring.endsAfter.date), 'do MMM yyyy')}`;
  }

  if (dueSince) {
    shortMsg += ' | ' + dateAsHuman(dueSince);
  }

  /*
    <Tooltip
      destroyTooltipOnHide
      placement="top"
      trigger={['hover', 'click']}
      overlay={<div style={{ width: '120px' }}>{fullMsg}</div>}
    >
   */

  return <span style={genericStyles.iconLabel}>{shortMsg}</span>;
}

const TaskRecurringInfo = ({
  recurring,
  onClick,
  completionHistory,
  date,
}: Props) => {
  const due = recurring
    ? recurringIsRepeatedScheduledOnDate(date, recurring, completionHistory)
    : { dueDate: null };
  return (
    <span
      className={onClick ? 'clickable-control' : ''}
      onClick={onClick}
      style={{
        ...genericStyles.propertyClickable,
        ...getColor(recurring, due.dueDate),
        fontWeight: 'bold',
      }}
    >
      <IonIcon style={genericStyles.icon} icon={repeat} />
      {renderLabel(recurring, completionHistory, due.dueDate)}
    </span>
  );
};

export default TaskRecurringInfo;
