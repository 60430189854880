import React from 'react';
import { withStorage } from '../../helpers/StorageProviders';
import {
  ItemType,
  ProjectEnrichedWithTaskStats,
  ProjectFilter,
  ProjectPositionContextEnum,
} from '../../types/CoreTypes';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import { ItemReorderEventDetail } from '@ionic/core';
import { IonList, IonReorderGroup } from '@ionic/react';
import ProjectListRow from './ProjectListRow';
import ProjectActionsWrapper from './ProjectActionsWrapper';
import { RxChangeEvent } from 'rxdb/plugins/core';
import { debounce, isEqual } from 'lodash';
import { swap } from '../../helpers/PositionHelper';
import { StorageDeps } from '../../helpers/InitStorage';
import { Subscription } from 'rxjs';
import { DateProps, withDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';

interface Props extends WorkspaceProps, StorageDeps, DateProps {
  filter: ProjectFilter;
  setCounter?: (count: number) => void;
  getRef?: (idx: number) => React.RefObject<any>;
  selectedIdx?: number;
  // onChange: () => void;
  positionContext: ProjectPositionContextEnum;
}

interface State {
  projects: ProjectEnrichedWithTaskStats[];
}

class ProjectListView extends React.Component<Props, State> {
  state: State = {
    projects: [],
  };

  dbUpdateSubscription?: Subscription;

  constructor(props: Props) {
    super(props);
    this.refresh();
  }

  // constructor and vars go above ^^

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (!isEqual(prevState.projects, this.state.projects)) {
      this.refreshCounters();
    }
    if (
      !isEqual(prevProps.filter, this.props.filter) ||
      !isEqual(prevProps.context, this.props.context)
    ) {
      this.refresh();
    }
  }

  componentDidMount(): void {
    this.dbUpdateSubscription = this.props.subscribeForDbChanges(
      this.handleDbChangeEvent
    );
  }

  componentWillUnmount() {
    this.dbUpdateSubscription?.unsubscribe();
  }

  // lifecycle goes above ^^

  refreshCounters = () => {
    this.props.setCounter && this.props.setCounter(this.state.projects.length);
  };

  refresh = () => {
    this.loadData();
    this.loadData.flush();
    logDebug([], 'project list refresh');
  };

  setProjects = (projects: ProjectEnrichedWithTaskStats[]) => {
    this.setState({ projects });
  };

  setProjectsDebounced = debounce((projects: ProjectEnrichedWithTaskStats[]) => {
    this.setProjects(projects);
  }, 200);

  async loadDataNotDebounced() {
    const {
      repos: { projectRepo },
      context,
      dateFormatted,
    } = this.props;

    projectRepo
      .getAllForFilter(
        context.id,
        dateFormatted,
        this.props.filter,
        ProjectPositionContextEnum.STATUS
      )
      .then((projects) => {
        this.setProjectsDebounced(projects);
        this.props.setCounter && this.props.setCounter(projects.length);
      });
  }

  loadData = debounce(this.loadDataNotDebounced, 200);

  handleDbChangeEvent = (evt: RxChangeEvent) => {
    if ([ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)) {
      this.loadData();
    }
  };

  doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    const { projects } = this.state;
    return (
      this.props.repos.projectRepo
        .reorder(
          this.props.positionContext,
          projects,
          event.detail.from,
          event.detail.to
        )
        // need solely to replace `projects` to allow subsequent sorting
        // .then(loadData)
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        .then(() => event.detail.complete())
    );
    // .catch(() => event.detail.);
  };

  manualReorder = (up: boolean) => {
    const idx = this.props.selectedIdx;
    if (idx === undefined) return Promise.resolve(false);
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    const to = up ? idx - 1 : idx + 1;
    // important, to read it before all following ops
    const { projects } = this.state;
    if (to < 0 || to >= projects.length) return Promise.resolve(false);
    this.loadData.cancel();
    this.setProjectsDebounced.cancel();
    this.setProjectsDebounced(swap(projects, idx, to));
    this.setProjectsDebounced.flush();
    return this.props.repos.projectRepo
      .reorder(this.props.positionContext, projects, idx, to)
      .then(() => true);
  };

  render() {
    const { projects } = this.state;

    return (
      <ProjectActionsWrapper>
        {(projectHandlers) => (
          <IonList>
            <IonReorderGroup disabled={false} onIonItemReorder={this.doReorder}>
              {projects.map((project, idx) => (
                <ProjectListRow
                  manualReorder={this.manualReorder}
                  ref={this.props.getRef ? this.props.getRef(idx) : undefined}
                  key={project.id}
                  project={project}
                  isSelected={idx === this.props.selectedIdx}
                  projectHandlers={projectHandlers}
                />
              ))}
            </IonReorderGroup>
          </IonList>
        )}
      </ProjectActionsWrapper>
    );
  }
}

export default withDate(withStorage(withWorkspace(ProjectListView)));
