import React from 'react';
import { IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import { useGlobalActions } from '../helpers/GlobalActionsProvider';

const GlobalSearch = () => {
  const { triggerSearch } = useGlobalActions();

  return (
    <IonIcon
      onClick={triggerSearch}
      style={{ fontSize: '24px', padding: '10px', cursor: 'pointer' }}
      icon={search}
    />
  );
};

export default GlobalSearch;
