const schema = {
  keyCompression: false,
  title: 'achievement schema',
  version: 0,
  description: 'describes a achievement',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    type: {
      type: 'string',
    },
    taskId: {
      ref: 'task',
      type: 'string',
    },
    projectId: {
      ref: 'project',
      type: 'string',
    },
    contextId: {
      type: 'string',
    },
    createdAt: {
      type: 'number',
      final: true,
    },
    lastUpdated: {
      type: 'number',
    },
    serverUpdatedAt: {
      type: 'number',
    },
    last_pulled_rev: {
      type: 'string',
    },
    deviceId: {
      type: 'string',
    },
    // can always set userId on the server like serverUpdatedAt
    // userId: {
    //   ref: 'task',
    //   type: 'string',
    // },
  },
  required: ['type'],
  // encrypted: ['secret'],
  // attachments: {
  //   encrypted: true
  // },
  indexes: [
    ['contextId', 'type', 'createdAt'],
    ['contextId', 'createdAt'],
    ['contextId', 'type'],
  ],
};

export default schema;
