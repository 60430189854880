import {
  PartialUpdatedRecord,
  TagCreateForm,
  TagEnrichedWithParent,
  TagFields,
  TagFilter,
  TagType,
  TaskFilter,
  TimeStampable,
} from '../types/CoreTypes';
import { BaseRepo } from './BaseRepo';
import { changePositionsInEntities } from './SortHelper';
import PouchDB from 'pouchdb';
import { pick } from 'lodash';
import { generateColorFromString } from '../helpers/colors';
import { nanoid } from 'nanoid';
import { getDefaultIcon } from '../helpers/icons';
import { TagModel } from './data/Models';
import { TAG_NAME_MAX_LENGTH } from './Constants';

export default class TagRepo extends BaseRepo<TagFields, undefined> {
  async create(args: TagCreateForm): Promise<string> {
    const nameTrimmed = args.name.trim();
    const entity: TagFields & TimeStampable = {
      name: nameTrimmed.substring(0, TAG_NAME_MAX_LENGTH),
      color: args.color || generateColorFromString(nanoid(20)),
      icon: args.icon || getDefaultIcon(),
      parentId: args.parentId,
      contextId: args.contextId,
      lastUpdated: new Date().getTime(),
      createdAt: new Date().getTime(),
    };
    return this.dao.create(entity);
  }

  _forceCreate(doc: TagType) {
    return this.dao._forceCreate(doc);
  }

  async reorder(tags: TagType[], from: number, to: number) {
    const positionKey = 'position';
    const changeSets = changePositionsInEntities(tags, from, to).map((tag, idx) => ({
      id: tag.id,
      [positionKey]: idx,
    }));
    return this.dao.editManyWithDebounce(changeSets);
  }

  async edit(entity: PartialUpdatedRecord<TagType>): Promise<void> {
    return this.dao.edit(entity);
  }

  async delete(id: string): Promise<void> {
    return this.dao.delete(id);
  }

  async getById(id: string): Promise<TagEnrichedWithParent | null> {
    return this.dao.getById(id, {
      enrichments: [TagModel.getTagRelation()],
    });
  }

  async getByIds(ids: string[]): Promise<TagType[]> {
    return this.dao.findAll({
      selector: {
        _id: { $in: Array.from(new Set(ids)) },
      },
    });
  }

  async getAllForFilter(contextId: string, filter: TagFilter): Promise<TagType[]> {
    const { selector, filterFn } = this.makeSelector(contextId, filter);
    return this.dao.findAll({
      selector,
      filterFn,
    });
  }

  getAllForFilterWithSort(
    contextId: string,
    filter: TagFilter,
    sortFn: (a: TagType, b: TagType) => number,
    limit?: number
  ): Promise<TagType[]> {
    const { selector, filterFn } = this.makeSelector(contextId, filter);
    return this.dao.findAll({
      selector,
      filterFn,
      sortFn,
      limit,
    });
  }

  getAllForFilter$(contextId: string, filter?: TagFilter) {
    const { selector, filterFn } = this.makeSelector(contextId, filter || {});
    return this.dao.findAll$(selector, filterFn);
  }

  async getCountForFilter(contextId: string, filter: TaskFilter): Promise<number> {
    const { selector, filterFn } = this.makeSelector(contextId, filter);
    return this.dao.getCount({ selector, filterFn });
  }

  // todo: repetitive, use mixin!
  private makeSelector(contextId: string, filter: TagFilter) {
    const memoryFilterFields: Set<keyof TagFilter> = new Set(
      Object.keys(filter) as (keyof TagFilter)[]
    );

    const selector: PouchDB.Find.Selector = {};
    selector.contextId = contextId;
    if (filter.parentId !== undefined) {
      if (filter.parentId !== null) {
        selector.parentId = filter.parentId;
        selector.contextId = undefined;
      } else {
        selector.parentId = { $eq: null };
      }
      memoryFilterFields.delete('parentId');
    }

    return {
      selector,
      filterFn: TagRepo.makeFilterFn(pick(filter, Array.from(memoryFilterFields))),
    };
  }

  static makeFilterFn = (filter: TagFilter) => (tag: TagType) => {
    let isMatch = true;
    if (filter.anyText)
      isMatch =
        isMatch && tag.name.toLowerCase().includes(filter.anyText.toLowerCase());
    if (filter.parentId !== undefined)
      isMatch = isMatch && tag.parentId === filter.parentId;
    return isMatch;
  };
}
