import React from 'react';
import { IonNote } from '@ionic/react';
import {
  Block,
  BlockChangeSet,
  BlockKind,
  BlockMinValue,
  BlockType,
  MyDB,
} from '../../types/CoreTypes';
import { debounce } from 'lodash';
import { createRxDatabase, RxCollection } from 'rxdb/plugins/core';
import PouchDAO from '../../lib/data/PouchDAO';
import { DaoCatalog } from '../../helpers/InitStorage';
import { BlockModel } from '../../lib/data/Models';
import BlockRepo from '../../lib/BlockRepo';
import BlockElement from './BlockElement';
import { customAlphabet } from 'nanoid';
import deepEqual from 'fast-deep-equal';
import DocumentRowToolbar from './DocumentRowToolbar';
import { ClientTypeProps, withClientType } from '../../helpers/ClientTypeProvider';
import { logDebug } from '../../lib/logger';

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz_', 20);

// todo render links
// todo support moving items and changing indent on mobile
// TODO cmd-z will undo block removal/adding
// TODO: selected state
// TODO delete block if it's selected but not editing
// TODO select multiple blocks
// TODO cmd-z will undo type conversion

interface Props extends ClientTypeProps {
  onChange: (value: BlockChangeSet) => Promise<void>;
  onDelete: (id: string) => void;
  inMemory?: boolean;
  onCreateBelow: (
    position: number,
    type?: BlockKind,
    indent?: number
  ) => Promise<void>;
  onMoveBlockUp: (id: string) => void;
  onMoveBlockDown: (id: string) => void;
  onArrowUp?: () => void;
  onInsertManyWithValuesAtAndBelow: (
    position: number,
    values: BlockMinValue[]
  ) => void;
  blocks: Block[];
  isHidden?: boolean;
  onBlockFocus: () => void;
  onBlockBlur: () => void;
}

interface State {
  currentFocusId?: string;
  currentFocusElementTop?: number;
}

// to avoid loosing some typed text when fast typing
// needed because visual editor component maintains it's own state
const SAVE_DEBOUNCE_MS = 800;

class DocumentEditorInner extends React.PureComponent<Props, State> {
  // private currentFocus: string | null = null;
  state: State = {};
  private readonly listRefs: Map<string, BlockElement> = new Map();
  private shouldFocusInNewElt = false;

  focusById(elementId?: string): boolean {
    if (elementId) {
      const ref = this.listRefs.get(elementId);
      if (ref) {
        ref.focus();
        // this.setState({ currentFocusId: elementId });
        return true;
      }
    }
    return false;
  }

  findElementInDirection(id: string, direction: number) {
    const idx = this.props.blocks.findIndex((block) => block.id === id);
    if (idx !== -1) {
      const block = this.props.blocks[idx + direction];
      if (block) {
        return block.id;
      }
    }
  }

  setRef(id: string, ref: any) {
    const hasRef = this.listRefs.has(id);
    this.listRefs.set(id, ref);
    if (!hasRef && this.shouldFocusInNewElt) {
      this.shouldFocusInNewElt = false;
      ref.focus();
      // this.focusById(id);
    }
  }

  handleKeyPress = (e: KeyboardEvent) => {
    logDebug([], 'document editor: handleKeyPress...');
    if (this.props.blocks.length) {
      if (
        (e.shiftKey || this.props.onArrowUp) &&
        !e.metaKey &&
        !e.ctrlKey &&
        (e.key === 'Down' || e.key === 'ArrowDown') &&
        !this.state.currentFocusId
      ) {
        e.preventDefault();
        const block = this.props.blocks[0];
        if (block) {
          this.focusById(block.id);
        }
      }
    } else {
      if (e.shiftKey && e.key === 'Enter' && !e.ctrlKey && !e.metaKey && !e.altKey) {
        e.preventDefault();
        this.handleCreate(0);
      }
    }
  };

  enterKeyBinding() {
    if (this.props.isHidden) {
      logDebug([], 'unloading document editor key listener...');
      document.removeEventListener('keydown', this.handleKeyPress, false);
    } else {
      logDebug([], 'loading document editor key listener...');
      document.addEventListener('keydown', this.handleKeyPress, false);
    }
  }

  componentDidMount() {
    this.enterKeyBinding();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  componentDidUpdate(prevProps: Props) {
    // if number of blocks changed, and now or before there was 0 blocks
    if (
      this.props.isHidden !== prevProps.isHidden ||
      (this.props.blocks.length !== prevProps.blocks.length &&
        (this.props.blocks.length === 0 || prevProps.blocks.length === 0))
    ) {
      this.enterKeyBinding();
    }
  }

  handleCreate = (position: number, type?: BlockKind, indent?: number) => {
    this.shouldFocusInNewElt = true;
    this.props.onCreateBelow(position, type, indent);
  };

  handleCreateBelow = (position: number, type?: BlockKind, indent?: number) =>
    this.handleCreate(position, type, indent);

  handleDelete = (id: string) => {
    const above = this.findElementInDirection(id, -1);
    const below = this.findElementInDirection(id, 1);
    this.props.onDelete(id);
    // TODO: only if in edit mode
    let succeeded;
    if (above) {
      succeeded = this.focusById(above);
    } else {
      succeeded = this.focusById(below);
    }
    // for ex, focus on the name field in quick add
    if (!succeeded && this.props.onArrowUp) {
      this.props.onArrowUp();
    }
  };

  // focus if in edit mode. select if not. Selected should be a prop in this class
  handleMoveDown = (id: string) =>
    this.focusById(this.findElementInDirection(id, 1));

  // focus if in edit mode. select if not. Selected should be a prop in this class
  handleMoveUp = (id: string) => {
    const movedCursorUp = this.focusById(this.findElementInDirection(id, -1));
    if (!movedCursorUp && this.props.onArrowUp) {
      this.props.onArrowUp();
    }
  };

  handleFocus = (id: string) => {
    this.setState({
      currentFocusId: id,
      currentFocusElementTop: this.getElementTop(id),
    });
    this.props.onBlockFocus();
  };

  private blurTimeouts: NodeJS.Timeout[] = [];
  private preservingBlur = false;

  clearBlurTimeout(setFocusBack: boolean, waitTime?: number) {
    const id = this.state.currentFocusId;
    if (setFocusBack) {
      const setFocusAgain = () => {
        // logDebug([], `timing focusById`);
        this.focusById(id);
        // logDebug([], `timing preservingBlur = false`);
      };
      while (this.blurTimeouts.length) {
        const timeout = this.blurTimeouts.pop();
        if (timeout) {
          clearTimeout(timeout);
        }
      }
      this.preservingBlur = false;
      if (waitTime) {
        setTimeout(setFocusAgain, waitTime);
      } else {
        setFocusAgain();
      }
    } else {
      this.preservingBlur = false;
    }
  }

  handleBlur = (id: string) => {
    // logDebug([], `timing handleBlur`);
    if (this.preservingBlur) return;
    this.blurTimeouts.push(
      setTimeout(() => {
        this.executeDelayedBlur(id);
      }, 150)
    );
  };

  executeDelayedBlur = (id: string) => {
    if (this.preservingBlur) return;
    // logDebug([], `timing executeDelayedBlur`);
    if (id === this.state.currentFocusId) {
      this.setState({
        currentFocusId: undefined,
      });
      this.props.onBlockBlur();
    }
  };

  handleDeleteCurrent = () => {
    const id = this.state.currentFocusId;
    if (!id) return;
    this.handleDelete(id);
  };
  handleMoveRightCurrent = () => {
    const id = this.state.currentFocusId;
    const block = this.props.blocks.filter((b) => b.id === id)[0];
    if (!block) return;
    const newIndent = block.indent + 1;
    this.props.onChange({
      id: block.id,
      indent: newIndent >= 0 ? newIndent : 0,
    });
    this.clearBlurTimeout(true);
  };
  handleMoveLeftCurrent = () => {
    const id = this.state.currentFocusId;
    const block = this.props.blocks.filter((b) => b.id === id)[0];
    if (!block) return;
    const newIndent = block.indent - 1;
    this.props.onChange({
      id: block.id,
      indent: newIndent >= 0 ? newIndent : 0,
    });
    this.clearBlurTimeout(true);
  };
  handleMoveUpCurrent = () => {
    const id = this.state.currentFocusId;
    if (!id) return;
    this.props.onMoveBlockUp(id);
    this.clearBlurTimeout(true);
    this.adjustRowToolbarDelayed();
  };
  handleMoveDownCurrent = () => {
    const id = this.state.currentFocusId;
    if (!id) return;
    this.props.onMoveBlockDown(id);
    this.clearBlurTimeout(true);
    this.adjustRowToolbarDelayed();
  };
  handleChangeTypeCurrent = async (kind: BlockKind) => {
    // logDebug([], `timing handleChangeTypeCurrent`);
    const id = this.state.currentFocusId;
    if (!id) return;
    await this.props.onChange({
      id,
      type: kind,
    });
    this.clearBlurTimeout(true, 10);
    // logDebug([], `timing handleChangeTypeCurrent: done`);
  };
  handleCreateBelowCurrent = async (kind: BlockKind) => {
    const id = this.state.currentFocusId;
    const block = this.props.blocks.filter((b) => b.id === id)[0];
    if (!block) return;
    await this.handleCreate(block.position, kind, block.indent);
    // setTimeout(() => this.handleCreate(block.position, kind, block.indent), 200);
    this.clearBlurTimeout(true);
  };
  handleBlurCurrent = () => {
    const id = this.state.currentFocusId;
    if (!id) return;
    this.executeDelayedBlur(id);
  };
  handlePreserveBlur = () => {
    this.preservingBlur = true;
  };
  handleStopPreservingBlur = () => {
    // logDebug([], `timing handleStopPreservingBlur`);
    if (this.preservingBlur) this.clearBlurTimeout(true, 10);
  };

  getElementTop = (id: string) => {
    const ce = this.listRefs.get(id)?.getContentEditable();
    return ce?.offsetTop || 0;
  };

  adjustRowToolbar = () => {
    const { isDesktop } = this.props;
    if (isDesktop) return;
    const id = this.state.currentFocusId;
    if (!id) return;
    this.setState({
      currentFocusElementTop: this.getElementTop(id),
    });
  };

  adjustRowToolbarDelayed = debounce(this.adjustRowToolbar, 200);

  render() {
    // logDebug([], 'timing DocumentEditor render');
    const {
      blocks,
      onChange,
      onMoveBlockDown,
      onMoveBlockUp,
      onInsertManyWithValuesAtAndBelow,
      isDesktop,
      isNative,
    } = this.props;

    if (blocks.length === 0) {
      const msg = isDesktop
        ? 'Press Shift + Enter or click here to add a content block'
        : 'click here to add a content block';
      return (
        <div style={{ margin: 5 }}>
          <IonNote onClick={() => this.handleCreate(0)}>{msg}</IonNote>
        </div>
      );
    }

    return (
      <>
        {blocks.map((block) => (
          <BlockElement
            supportVirtualKeyboard={isNative}
            saveDebounceMs={this.props.inMemory ? 0 : SAVE_DEBOUNCE_MS}
            ref={(elt) => this.setRef(block.id, elt)}
            key={block.id}
            block={block}
            onChange={onChange}
            onCreateBelow={this.handleCreateBelow}
            onInsertManyWithValuesAtAndBelow={onInsertManyWithValuesAtAndBelow}
            onDelete={this.handleDelete} // then set focus above, if was in edit mode
            onMoveDown={this.handleMoveDown}
            onMoveUp={this.handleMoveUp}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onMoveBlockDown={onMoveBlockDown}
            onMoveBlockUp={onMoveBlockUp}
          />
        ))}
        {this.state.currentFocusId && (
          <DocumentRowToolbar
            elementTop={this.state.currentFocusElementTop}
            onDelete={this.handleDeleteCurrent}
            onMoveRight={this.handleMoveRightCurrent}
            onMoveLeft={this.handleMoveLeftCurrent}
            onMoveUp={this.handleMoveUpCurrent}
            onMoveDown={this.handleMoveDownCurrent}
            onChangeType={this.handleChangeTypeCurrent}
            onAddBelow={this.handleCreateBelowCurrent}
            onBlur={this.handleBlurCurrent}
            onPreserveBlur={this.handlePreserveBlur}
            onStopPreservingBlur={this.handleStopPreservingBlur}
          />
        )}
      </>
    );
  }
}

const DocumentEditor = withClientType(DocumentEditorInner);

interface WrapperProps {
  isHidden?: boolean;
  onArrowUp?: () => void;
  // userId: string;
  daoCatalog: DaoCatalog;
  contextId: string;
  inMemory?: boolean;
  syncParams?: {
    selector: {
      [k in keyof BlockType]?: BlockType[k];
    };
    localBlockCollection: RxCollection<BlockType>;
  };
  onBlockBlur?: () => void;
  onBlockFocus?: () => void;
}

interface WrapperState {
  blocks: BlockType[];
}

export class DocumentEditorSync extends React.Component<WrapperProps, WrapperState> {
  state: WrapperState = {
    blocks: [],
  };

  private repo?: BlockRepo;
  private db?: MyDB;
  private collection?: RxCollection<BlockType>;

  componentDidMount() {
    logDebug([], 'componentDidMount');
    this.initDb();
  }

  componentWillUnmount() {
    logDebug([], 'componentWillUnmount');
    this.removeDb();
  }

  UNSAFE_componentWillReceiveProps(nextProps: WrapperProps) {
    if (!deepEqual(this.props.syncParams, nextProps.syncParams)) {
      this.eraseDb();
    }
  }

  eraseDb() {
    this.removeDb().then(this.initDb);
  }

  private async removeDb() {
    logDebug([], 'remove in-memory DB ' + this.db?.name);
    // this.collection && this.collection._subs.map(s => s.unsubscribe());
    await this.db?.remove().then(() => logDebug([], '...removed!'));
  }

  dump = () => {
    return this.collection?.dump();
  };

  // TODO: in quick add, ideally the DB should only be created when it's visible?
  private initDb = async () => {
    const { syncParams } = this.props;
    const name =
      'mem_block_db_' + (syncParams ? 'sync' : 'quickadd') + '_' + nanoid();
    logDebug([], 'set up in-memory DB ' + name);

    this.db = await createRxDatabase({
      name,
      adapter: 'memory',
    });

    const getDumpPromise = syncParams?.localBlockCollection
      .find({ selector: syncParams.selector })
      .exec();
    const collectionPromise = BlockModel.initCollection(this.db);

    const [dumpRows, collection] = await Promise.all([
      getDumpPromise,
      collectionPromise,
    ]);
    this.collection = collection;

    if (syncParams && dumpRows) {
      // copied with modifications from here: https://github.com/pubkey/rxdb/blob/00a128920a1ef2aca150325982baa571e3c32fdf/src/plugins/in-memory.ts#L261
      const docs = dumpRows
        .map((doc: any) => doc._data)
        .map((doc: any) =>
          syncParams.localBlockCollection.schema.swapPrimaryToId(doc)
        );
      if (docs.length > 0) {
        await collection.pouch.bulkDocs(
          { docs },
          {
            new_edits: false,
          }
        );
      }
    }

    const dao = new PouchDAO(
      this.props.daoCatalog,
      BlockModel,
      collection
      // this.props.userId
    );
    this.repo = new BlockRepo(dao);

    if (syncParams) {
      collection.sync({
        remote: syncParams.localBlockCollection,
        options: {
          live: true,
          retry: true,
          filter: (doc: any) => {
            const data = doc as BlockType; //collection._keyCompressor.decompress(doc) as BlockType;
            let match = true;
            for (const [syncParam, syncValue] of Object.entries(
              syncParams.selector
            )) {
              match = match && data[syncParam as keyof BlockType] === syncValue;
            }
            return match;
          },
        },
      });
      // .change$.subscribe(console.log);
    }

    dao.getAll$().subscribe((blocks) => this.setState({ blocks }));
    // this.repo = this.props.repo;
    //
    // this.repo
    //   .findAll$(this.props.taskId)
    //   .subscribe(blocks => this.setState({ blocks }));
  };

  handleInsertManyWithValuesAtAndBelow = async (
    position: number,
    values: BlockMinValue[]
  ) => {
    await this.repo?.insertManyWithValuesAtAndBelow(
      this.props.syncParams?.selector,
      this.props.contextId,
      position,
      values
    );
  };

  handleBlockCreateBelow = async (
    position: number,
    type?: BlockKind,
    indent?: number
  ) => {
    // logDebug(['blocksync'], 'handleBlockCreateBelow', { position, type, indent });
    await this.repo?.insertAfter(
      this.props.syncParams?.selector,
      this.props.contextId,
      position,
      type,
      indent
    );
  };

  handleBlockChange = async (changeSet: BlockChangeSet) => {
    // logDebug(['blocksync'], 'updateContent', { ...changeSet });
    await this.repo?.edit({ ...changeSet });
  };

  handleBlockDelete = (blockId: string) => {
    // logDebug(['blocksync'], 'handleBlockDelete', { blockId });
    return this.repo?.delete(blockId);
  };

  handleBlockMoveBlockUp = (blockId: string) => {
    // logDebug(['blocksync'], 'handleBlockMoveBlockUp', { blockId });
    return this.repo?.swap(this.props.syncParams?.selector, blockId, true);
  };

  handleBlockMoveBlockDown = (blockId: string) => {
    // logDebug(['blocksync'], 'handleBlockMoveBlockDown', { blockId });
    return this.repo?.swap(this.props.syncParams?.selector, blockId, false);
  };

  shouldComponentUpdate(nextProps: WrapperProps, nextState: WrapperState): boolean {
    const { props, state } = this;
    return !deepEqual(props, nextProps) || !deepEqual(state, nextState);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dummy() {}

  render() {
    const { blocks } = this.state;

    logDebug([], 'doc render');

    return (
      <DocumentEditor
        inMemory={this.props.inMemory}
        isHidden={this.props.isHidden}
        onArrowUp={this.props.onArrowUp}
        blocks={blocks}
        onInsertManyWithValuesAtAndBelow={this.handleInsertManyWithValuesAtAndBelow}
        onChange={this.handleBlockChange}
        onCreateBelow={this.handleBlockCreateBelow}
        onDelete={this.handleBlockDelete}
        onMoveBlockUp={this.handleBlockMoveBlockUp}
        onMoveBlockDown={this.handleBlockMoveBlockDown}
        onBlockBlur={this.props.onBlockBlur || this.dummy}
        onBlockFocus={this.props.onBlockFocus || this.dummy}
      />
    );
  }
}
