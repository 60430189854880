import {
  ProjectType,
  ProjectPositionContextEnum,
  TaskType,
  TaskPositionContextEnum,
} from '../types/CoreTypes';

export function getTaskPositionKey(
  positionContext: TaskPositionContextEnum
): keyof TaskType {
  switch (positionContext) {
    case TaskPositionContextEnum.PROJECT:
      return 'positionProject';
    case TaskPositionContextEnum.STATUS:
      return 'positionStatus';
    case TaskPositionContextEnum.CALENDAR:
      return 'positionCalendar';
  }
}

export function getProjectPositionKey(
  positionContext: ProjectPositionContextEnum
): keyof ProjectType {
  switch (positionContext) {
    case ProjectPositionContextEnum.STATUS:
      return 'positionStatus';
    case ProjectPositionContextEnum.CALENDAR:
      return 'positionCalendar';
  }
}

export function swap<T>(list: T[], idx: number, to: number): T[] {
  const clone = [...list];
  const b = clone[idx];
  clone[idx] = clone[to];
  clone[to] = b;
  return clone;
}
