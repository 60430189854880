import React from 'react';
import { generateColorFromString } from '../../helpers/colors';
import { IonIcon, IonRouterLink } from '@ionic/react';
import { ProjectType } from '../../types/CoreTypes';
import { genericStyles } from '../../helpers/styles';
import { create } from 'ionicons/icons';

interface Props {
  project: ProjectType | null;
  onEdit?: () => void;
  onElementClick?: (id: string) => void;
  disableLink?: boolean;
  onAfterNavigate?: () => void;
}

const ProjectLinkInner = ({
  project,
  disableLink,
  onAfterNavigate,
  onElementClick,
  onEdit,
}: Props) => {
  if (!project)
    return (
      <span
        style={{
          ...genericStyles.grayOutColor,
          ...(onEdit ? genericStyles.clickable : {}),
        }}
        onClick={onEdit}
      >
        no project
      </span>
    );

  const content = (
    <span
      onClick={onElementClick ? () => onElementClick(project.id) : undefined}
      style={{
        color: generateColorFromString(project.id),
        fontWeight: 'bold',
        ...(onElementClick ? genericStyles.clickable : {}),
      }}
    >
      {project.name}
    </span>
  );

  if (disableLink) {
    return content;
  }

  return (
    <IonRouterLink
      onClick={onAfterNavigate}
      routerLink={`/projects/details/${project.id}`}
      routerDirection={'root'}
    >
      {content}
    </IonRouterLink>
  );
};

const ProjectLink = (props: Props) => {
  const { onEdit, project } = props;
  return (
    <div className={onEdit && !project ? 'clickable-control' : 'inline'}>
      {onEdit && (
        <span
          style={genericStyles.propertyClickable}
          className={onEdit && project ? 'clickable-control' : ''}
        >
          <IonIcon
            color="medium"
            style={genericStyles.icon}
            icon={create}
            onClick={onEdit}
          />
        </span>
      )}
      <ProjectLinkInner {...props} />
    </div>
  );
};

export default ProjectLink;
