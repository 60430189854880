import React from 'react';
import { withStorage } from '../../helpers/StorageProviders';
import {
  ItemType,
  TaskEnrichedWithBlockStats,
  TaskEnrichedWithProjectAndTags,
  TaskPositionContextEnum,
  TaskStatus,
} from '../../types/CoreTypes';
import { debounce, isEqual } from 'lodash';
import {
  IonBadge,
  IonButton,
  IonIcon,
  IonList,
  IonListHeader,
  IonNote,
  IonToolbar,
} from '@ionic/react';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import QuickAdd from '../Common/QuickAdd';
import QuickAddFormTask from './QuickAddFormTask';
import TaskListRow from './TaskListRow';
import TaskActionsWrapper from './TaskActionsWrapper';
import { RxChangeEvent } from 'rxdb/plugins/core';
import {
  formatDateWithoutTime,
  generateNextScheduledDate,
  makeDateWithoutTime,
} from '../../helpers/RecurringHelper';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import format from 'date-fns/format';
import { chevronBack, chevronForward, warning } from 'ionicons/icons';
import { StorageDeps } from '../../helpers/InitStorage';
import { Subscription } from 'rxjs';
import { TaskHandlers } from '../../types/TaskHandlers';
import { colors } from '../../helpers/styles';
import WideMessage from '../Common/WideMessage';
import { DateProps, withDate } from '../../helpers/DateProvider';
import { logDebug } from '../../lib/logger';

interface Props extends WorkspaceProps, StorageDeps, DateProps {
  setCounter?: (count: number) => void;
  selectedIdx?: number;
  getRef?: (idx: number) => React.RefObject<any>;
}

function compareDates(a: Date, b: Date) {
  if (a.getTime() === b.getTime()) return 0;
  return a.getTime() > b.getTime() ? 1 : -1;
}

function makeDateInterval(date: Date) {
  const dateAdjusted = startOfMonth(date);
  return {
    from: dateAdjusted,
    to: addMonths(dateAdjusted, 2),
  };
}

type TaskTypeToUseHere = TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats;

interface DateTask {
  date: string;
  tasks: TaskTypeToUseHere[];
}

interface DateInterval {
  from: Date;
  to: Date;
}

interface State {
  dateTasks: DateTask[];
  tasksFiltered: DateTask[];
  tasksToIdx: Map<string, number>;
  dateInterval: DateInterval;
}

class AgendaTaskListView extends React.Component<Props, State> {
  readonly state: State;

  dbUpdateSubscription?: Subscription;

  constructor(props: Props) {
    super(props);
    this.state = {
      dateTasks: [],
      tasksFiltered: [],
      tasksToIdx: new Map(),
      dateInterval: makeDateInterval(props.date),
    };
    this.refresh();
  }

  // constructor and vars go above ^^

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (!isEqual(prevState.tasksFiltered, this.state.tasksFiltered)) {
      this.refreshCounters();
    }
    if (!isEqual(prevProps.context, this.props.context)) {
      this.refresh();
    }
    if (!isEqual(prevProps.dateFormatted, this.props.dateFormatted)) {
      this.setState({
        dateInterval: makeDateInterval(this.props.date),
      });
    }
  }

  componentDidMount(): void {
    this.dbUpdateSubscription = this.props.subscribeForDbChanges(
      this.handleDbChangeEvent
    );
  }

  componentWillUnmount() {
    this.dbUpdateSubscription?.unsubscribe();
  }

  // lifecycle goes above ^^

  refreshCounters = () => {
    if (this.props.setCounter) {
      const len = Object.values(this.state.tasksFiltered).reduce(
        (acc, l) => acc + l.tasks.length,
        0
      );
      this.props.setCounter(len);
    }
  };

  setDateTasks = (dateTasks: DateTask[]) => {
    const { tasksFiltered, tasksToIdx } = this.computeTasksFiltered(
      dateTasks,
      this.state.dateInterval
    );
    this.setState({
      dateTasks,
      tasksFiltered,
      tasksToIdx,
    });
  };

  setDateInterval = (dateInterval: DateInterval) => {
    const { tasksFiltered, tasksToIdx } = this.computeTasksFiltered(
      this.state.dateTasks,
      dateInterval
    );
    this.setState({
      dateInterval,
      tasksFiltered,
      tasksToIdx,
    });
  };

  computeTasksFiltered(dateTasks: DateTask[], dateInterval: DateInterval) {
    const tasksFiltered = dateTasks.filter(
      (dt) =>
        makeDateWithoutTime(dt.date) >= dateInterval.from &&
        makeDateWithoutTime(dt.date) < dateInterval.to
    );
    let idx = -1;

    const list: [string, number][][] = tasksFiltered.map((tf) =>
      tf.tasks.map((t) => {
        idx++;
        return [makeTaskIdxKey(tf, t), idx];
      })
    );
    const tasksToIdx = new Map(list.flat());

    return { tasksFiltered, tasksToIdx };
  }

  async loadDataNotDebounced() {
    const {
      repos: { taskRepo },
      context,
      dateFormatted,
      date,
    } = this.props;
    const from = date;
    const until = addYears(date, 5);
    taskRepo
      .getAllForFilter(
        context.id,
        {
          notStatus: TaskStatus.STATUS_DONE,
          agendaFor: formatDateWithoutTime(until),
        },
        TaskPositionContextEnum.CALENDAR,
        dateFormatted
      )
      .then((tasks) =>
        tasks
          .reduce(
            (
              acc: [
                Date,
                TaskEnrichedWithProjectAndTags & TaskEnrichedWithBlockStats
              ][],
              task
            ) => {
              let dateDue;
              if (task.dateDue) {
                dateDue = makeDateWithoutTime(task.dateDue);
                dateDue.setHours(0);
                dateDue.setMinutes(0);
                dateDue.setSeconds(0);
                dateDue.setMilliseconds(0);
                acc.push([dateDue, task]);
              }
              if (task.dateScheduled) {
                const dateScheduled = makeDateWithoutTime(task.dateScheduled);
                dateScheduled.setHours(0);
                dateScheduled.setMinutes(0);
                dateScheduled.setSeconds(0);
                dateScheduled.setMilliseconds(0);
                if (dateScheduled.getTime() !== dateDue?.getTime())
                  acc.push([dateScheduled, task]);
              }
              if (task.recurringOptions) {
                const gen = generateNextScheduledDate(task, from);
                // eslint-disable-next-line no-constant-condition
                while (true) {
                  const nextDue = gen.next().value;
                  if (!nextDue) break;
                  if (nextDue > until) break;
                  acc.push([nextDue, task]);
                }
              }
              return acc;
            },
            []
          )
          .sort(([aDate], [bDate]) => {
            return compareDates(aDate, bDate);
          })
          .reduce(
            (
              acc: {
                [key: string]: (TaskEnrichedWithProjectAndTags &
                  TaskEnrichedWithBlockStats)[];
              },
              [date, task]
            ) => {
              const dt = formatDateWithoutTime(date);
              (acc[dt] = acc[dt] || []).push(task);
              return acc;
            },
            {}
          )
      )
      .then((tasks) => {
        this.setDateTasks(
          Object.entries(tasks).map(([key, tx]) => {
            return {
              date: key,
              tasks: tx,
            };
          })
        );
      });
  }

  loadData = debounce(this.loadDataNotDebounced, 200);

  handleDbChangeEvent = (evt: RxChangeEvent) => {
    if (evt.operation === 'INSERT') {
      if ([ItemType.task].includes(evt.collectionName as ItemType)) {
        this.loadData();
      }
    }
    if (evt.operation === 'UPDATE' || evt.operation === 'DELETE') {
      if (
        [ItemType.task, ItemType.project].includes(evt.collectionName as ItemType)
      ) {
        this.loadData();
      }
    }
  };

  refresh = () => {
    this.loadData();
    this.loadData.flush();
    logDebug([], 'agenda list refresh');
  };

  canGoBack = () => {
    return this.state.dateInterval.from > this.props.date;
  };

  goBack = () => {
    let newFrom = subMonths(this.state.dateInterval.from, 2);
    const monthStart = startOfMonth(this.props.date);
    if (newFrom < monthStart) {
      newFrom = monthStart;
    }
    this.setDateInterval(makeDateInterval(newFrom));
  };

  goForward = () => {
    this.setDateInterval(makeDateInterval(this.state.dateInterval.to));
  };

  getRef = (idx: number) => {
    const { getRef } = this.props;
    return getRef ? getRef(idx) : undefined;
  };

  render() {
    const {
      _techDebt_daoCatalog,
      repos: { taskRepo, blockRepo },
      context,
      selectedIdx,
    } = this.props;

    const { dateInterval, tasksFiltered, tasksToIdx } = this.state;

    return (
      <QuickAdd
        form={({ onClose, ref, onSave, isOpen }) => (
          <QuickAddFormTask
            isOpen={isOpen}
            onClose={onClose}
            daoCatalog={_techDebt_daoCatalog}
            onSave={(args) =>
              taskRepo
                .create(args)
                .then((taskId) => {
                  if (args.blocks) {
                    return Promise.all(
                      args.blocks.map((block) =>
                        blockRepo.create({
                          ...block,
                          taskId,
                          contextId: args.contextId,
                        })
                      )
                    );
                  }
                })
                .then(onSave)
            }
            ref={ref}
          />
        )}
      >
        <IonToolbar>
          <IonButton
            slot="start"
            disabled={!this.canGoBack()}
            size="small"
            color="light"
            onClick={this.goBack}
          >
            <IonIcon icon={chevronBack} />
            &nbsp; Back
          </IonButton>
          <IonNote>
            {format(dateInterval.from, 'dd MMM yyyy')} -&nbsp;
            {format(dateInterval.to, 'dd MMM yyyy')}
          </IonNote>
          <IonButton slot="end" size="small" color="light" onClick={this.goForward}>
            <IonIcon icon={chevronForward} />
            &nbsp; Next
          </IonButton>
        </IonToolbar>
        {tasksFiltered.length === 0 && (
          <WideMessage
            text="No tasks here yet. Tasks scheduled to the above dates will be shown here"
            icon={warning}
            color={colors.grayOut}
          />
        )}
        <TaskActionsWrapper>
          {(taskHandlers) => (
            <IonList>
              {tasksFiltered.map((tf) => (
                <SimpleTasksList
                  tf={tf}
                  taskHandlers={taskHandlers}
                  key={tf.date}
                  tasksToIdx={tasksToIdx}
                  getRef={this.getRef}
                  selectedIdx={selectedIdx}
                />
              ))}
            </IonList>
          )}
        </TaskActionsWrapper>
      </QuickAdd>
    );
  }
}

const makeTaskIdxKey = (tf: DateTask, task: TaskTypeToUseHere): string =>
  `${tf.date}-${task.id}`;

interface SimpleTasksListProps {
  tf: DateTask;
  taskHandlers: TaskHandlers;
  getRef: (idx: number) => React.RefObject<any> | undefined;
  tasksToIdx: Map<string, number>;
  selectedIdx?: number;
}

const SimpleTasksList = ({
  getRef,
  tf,
  taskHandlers,
  tasksToIdx,
  selectedIdx,
}: SimpleTasksListProps) => {
  return (
    <>
      <IonListHeader>
        <span>
          <span>{format(makeDateWithoutTime(tf.date), 'eee, do MMMM yyyy')}</span>
          &nbsp;
          <IonBadge>{tf.tasks.length}</IonBadge>
        </span>
      </IonListHeader>
      {tf.tasks.map((task) => (
        <SimpleTaskRow
          key={`agenda-${tf.date}-${task.id}`}
          tf={tf}
          task={task}
          getRef={getRef}
          tasksToIdx={tasksToIdx}
          selectedIdx={selectedIdx}
          taskHandlers={taskHandlers}
        />
      ))}
    </>
  );
};

interface SimpleTaskRowProps {
  tf: DateTask;
  task: TaskTypeToUseHere;
  selectedIdx?: number;
  getRef: (idx: number) => React.RefObject<any> | undefined;
  tasksToIdx: Map<string, number>;
  taskHandlers: TaskHandlers;
}

const SimpleTaskRow = ({
  tf,
  task,
  getRef,
  tasksToIdx,
  selectedIdx,
  taskHandlers,
}: SimpleTaskRowProps) => {
  const taskIdxKey = makeTaskIdxKey(tf, task);
  const idx = tasksToIdx.get(taskIdxKey);
  if (idx === undefined)
    throw Error(`no idx: ${idx} in ${JSON.stringify(tasksToIdx)}`);
  return (
    <div>
      <TaskListRow
        ref={getRef(idx)}
        // ref={idx ? getRef(idx) : undefined}
        isSelected={idx === selectedIdx}
        task={task}
        taskHandlers={taskHandlers}
      />
    </div>
  );
};

export default withDate(withStorage(withWorkspace(AgendaTaskListView)));
