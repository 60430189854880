import { AchievementFields, AchievementType } from '../types/CoreTypes';
import { BaseRepo } from './BaseRepo';
import { startOfDay } from 'date-fns';

export default class AchievementRepo extends BaseRepo<AchievementFields, undefined> {
  async create(
    args: Omit<AchievementType, 'id' | 'lastUpdated' | 'createdAt'>
  ): Promise<string> {
    const entity = {
      ...args,
      lastUpdated: new Date().getTime(),
      createdAt: new Date().getTime(),
    };
    return this.dao.create(entity);
  }

  // findAll$(selector?: { [k in keyof AchievementType]?: AchievementType[k] }) {
  //   return this.dao.findAll$(selector || {});
  // }
  //
  // findAll(selector?: { [k in keyof AchievementType]?: AchievementType[k] }) {
  //   return this.dao.findAll({ selector: selector || {} });
  // }

  _forceCreate(doc: AchievementType) {
    return this.dao._forceCreate(doc);
  }

  getAllForDates(contextId: string, startingDate: Date) {
    const startDate = startOfDay(startingDate);
    return this.dao.findAll({
      selector: {
        contextId,
        createdAt: { $gte: startDate.getTime() },
      },
    });
  }

  getAllForDates$(contextId: string, startingDate: Date) {
    const startDate = startOfDay(startingDate);
    return this.dao.findAll$({
      contextId,
      createdAt: { $gte: startDate.getTime() },
    });
  }

  // async edit(entity: PartialUpdatedRecord<AchievementType>): Promise<void> {
  //   return this.dao.edit(entity);
  // }
  //
  // async delete(id: string): Promise<void> {
  //   return this.dao.delete(id);
  // }
  //
  // async getById(id: string): Promise<AchievementType | null> {
  //   return this.dao.getById(id);
  // }
  //
  // async getAll(): Promise<AchievementType[]> {
  //   return this.dao.getAll();
  // }
}
