import React, { useCallback, useEffect, useState } from 'react';
import { useStorage } from '../helpers/StorageProviders';
import EditTagForm from '../components/Tags/EditTagForm';
import { TagType } from '../types/CoreTypes';
import { RouteComponentProps } from 'react-router';
import { IonContent, IonPage, IonTitle } from '@ionic/react';
import CommonHeader from '../components/Common/CommonHeader';

interface Params {
  id?: string;
}

const TagEditPage: React.FunctionComponent<RouteComponentProps<Params>> = (
  props: RouteComponentProps<Params>
) => {
  const [tag, setTag] = useState<TagType | null>(null);
  const {
    repos: { tagRepo },
  } = useStorage();

  const id = props.match.params.id;

  const loadData = useCallback(
    async function loadData(id: string) {
      tagRepo.getById(id).then(setTag);
    },
    [tagRepo]
  );

  useEffect(() => {
    if (!id) return;
    loadData(id);
  }, [id, loadData]);

  if (!id) return null;

  return (
    <IonPage>
      <CommonHeader>
        <IonTitle>Edit tag</IonTitle>
      </CommonHeader>
      <IonContent>
        {tag && (
          <EditTagForm
            tag={tag}
            onSave={(tag) => {
              return tagRepo.edit(tag).then(() => props.history.goBack());
            }}
            onCancel={() => props.history.goBack()}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TagEditPage;
