import React, { CSSProperties } from 'react';
import { genericStyles } from '../../helpers/styles';

interface TableCellProps {
  // width: number;
  className: string;
  style?: CSSProperties;
  children: React.ReactNode;
  takeRemainingSpace?: boolean;
  onClick?: any;
  title?: string;
  spaceOut?: boolean;
}

export const TableCell = ({
  takeRemainingSpace,
  children,
  className,
  style,
  onClick,
  title,
  spaceOut,
}: TableCellProps) => {
  const classes = `${className} ${
    spaceOut ? 'list-field-space-out' : 'list-field-basic'
  }`;
  let css: CSSProperties = {};
  if (takeRemainingSpace) {
    css.flex = 1;
  }
  if (style) {
    css = {
      ...css,
      ...style,
    };
  }
  return (
    <div title={title} onClick={onClick} style={css} className={classes}>
      <div style={genericStyles.ellipsis}>{children}</div>
    </div>
  );
};
