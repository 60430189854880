import React from 'react';
import { TaskFields } from '../../types/CoreTypes';
import TaskDateScheduledInfo from './TaskDateScheduledInfo';
import TaskDateDueInfo from './TaskDateDueInfo';
import TaskRecurringInfo from './TaskRecurringInfo';

interface ClickHandlers {
  due?: () => void;
  reschedule?: () => void;
  recurring?: () => void;
}

interface Wrapper {
  children: React.ReactNode;
  colspan: number;
  title: string;
}

interface Props {
  task: TaskFields;
  currentDate: Date;
  clickHandlers?: ClickHandlers;
  Wrapper: React.ComponentType<Wrapper>;
}

const TaskWrapperDateInfo = ({
  task,
  clickHandlers,
  Wrapper,
  currentDate,
}: Props) => {
  if (task.recurringOptions) {
    return (
      <>
        <Wrapper colspan={1} title="Scheduled">
          <TaskDateScheduledInfo
            currentDate={currentDate}
            onClick={clickHandlers?.reschedule}
            dateScheduled={task.dateScheduled}
          />
        </Wrapper>
        <Wrapper colspan={2} title="Recurring">
          <TaskRecurringInfo
            date={currentDate}
            completionHistory={task.completionHistory}
            recurring={task.recurringOptions}
            onClick={clickHandlers?.recurring}
          />
        </Wrapper>
      </>
    );
  }
  if (task.dateDue) {
    return (
      <>
        <Wrapper colspan={1} title="Scheduled">
          <TaskDateScheduledInfo
            currentDate={currentDate}
            onClick={clickHandlers?.reschedule}
            dateScheduled={task.dateScheduled}
          />
        </Wrapper>
        <Wrapper colspan={2} title="Due date">
          <TaskDateDueInfo
            currentDate={currentDate}
            onClick={clickHandlers?.due}
            dateDue={task.dateDue}
          />
        </Wrapper>
      </>
    );
  }

  return (
    <>
      <Wrapper colspan={1} title="Scheduled">
        <TaskDateScheduledInfo
          currentDate={currentDate}
          onClick={clickHandlers?.reschedule}
          dateScheduled={task.dateScheduled}
        />
      </Wrapper>
      <Wrapper colspan={1} title="Recurring">
        <TaskRecurringInfo
          date={currentDate}
          completionHistory={task.completionHistory}
          recurring={task.recurringOptions}
          onClick={clickHandlers?.recurring}
        />
      </Wrapper>
      <Wrapper colspan={1} title="Due date">
        <TaskDateDueInfo
          currentDate={currentDate}
          onClick={clickHandlers?.due}
          dateDue={task.dateDue}
        />
      </Wrapper>
    </>
  );
};

export default TaskWrapperDateInfo;
