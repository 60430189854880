import React from 'react';
import { genericStyles } from '../../helpers/styles';
import { ProjectStatus } from '../../types/CoreTypes';
import { getProjectStatusLabel } from '../../helpers/CopyHelper';
import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { getProjectColorLabel, getProjectIcon } from '../../helpers/ProjectHelper';

interface Props {
  status: ProjectStatus;
  onClick?: any;
}

const ProjectStatusInfo = ({ status, onClick }: Props) => {
  return (
    <IonChip
      onClick={onClick}
      style={{
        ...genericStyles.chip,
        ...genericStyles.iconLabel,
        ...(onClick ? genericStyles.propertyClickable : genericStyles.property),
      }}
      color={getProjectColorLabel(status)}
    >
      <IonIcon icon={getProjectIcon(status)} />
      <IonLabel>{getProjectStatusLabel(status)}</IonLabel>
    </IonChip>
  );
};

export default ProjectStatusInfo;
