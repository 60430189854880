import React, { createContext, useContext } from 'react';
import LocalSettingsRepo from '../lib/LocalSettingsRepo';
import { RxDatabase } from 'rxdb/dist/types/core';
import { MyDatabaseCollections } from '../types/CoreTypes';

export interface DbDeps {
  localDb: RxDatabase<MyDatabaseCollections>;
  localSettingsRepo: LocalSettingsRepo;
}

// @ts-ignore
export const DbContext = createContext<DbDeps>(undefined);

interface Props {
  dbDeps: DbDeps;
  children: any;
}

export const DbProvider = ({ dbDeps, children }: Props) => (
  <DbContext.Provider value={dbDeps}>{children}</DbContext.Provider>
);

export const useDb: () => DbDeps = () => useContext(DbContext);

export const withDb = <P extends Partial<DbDeps>>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof DbDeps>> => {
  return function withDbContext(props) {
    return (
      <DbContext.Consumer>
        {(dbProps: DbDeps) => <Component {...(props as P)} {...dbProps} />}
      </DbContext.Consumer>
    );
  };
};
