import React from 'react';
import { IonButton, IonHeader, IonIcon, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';
import MyIonModal from '../../helpers/MyIonModal';

interface Props {
  title: string;
  klass?: string;
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const DetailsModal = ({ title, klass, onClose, show, children }: Props) => {
  const cssClass = klass || '';
  return (
    <MyIonModal
      animated={false}
      cssClass={`property-modal ${cssClass}`}
      isOpen={show}
      backdropDismiss={true}
      onDidDismiss={onClose}
    >
      {show && (
        <>
          <IonHeader>
            <IonToolbar>
              {title}

              <IonButton slot="end" color="light" onClick={onClose}>
                <IonIcon slot="start" icon={close} />
                Close
              </IonButton>
            </IonToolbar>
          </IonHeader>

          <div className="ion-padding">{children}</div>
        </>
      )}
    </MyIonModal>
  );
};

export default DetailsModal;
