import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonIcon,
  IonPage,
  IonTitle,
  IonToast,
} from '@ionic/react';
import { useStorage } from '../helpers/StorageProviders';
import { unregister } from '../serviceWorkerRegistration';
import CommonHeader from '../components/Common/CommonHeader';
import { linkOutline, refreshOutline } from 'ionicons/icons';
import LogOut from '../components/LogOut';
import UserProfile from '../components/UserProfile';
import { getEnvVarOrDie } from '../lib/EnvManager';
import { useClientType } from '../helpers/ClientTypeProvider';

const reload = () => {
  unregister();
  window.location.reload();
};

const VERSION = getEnvVarOrDie('REACT_APP_VERSION');

const readJsonFile = (cb: (o: any) => void) => (event: any) => {
  const files = event.target.files;
  if (!files.length) {
    alert('No file select');
    return;
  }
  const file_to_read = files[0];
  const fileread = new FileReader();
  fileread.onload = function (e) {
    if (!e.target) return;
    const content = e.target.result;
    if (!content) return;
    // console.log(content);
    const intern = JSON.parse(content.toString()); // Array of Objects.
    cb(intern); // You can index every object
  };
  fileread.readAsText(file_to_read);
};

const SettingsPage: React.FunctionComponent<RouteComponentProps> = () => {
  const { _techDebt_localDb } = useStorage();
  // const [token, setToken] = useState<string | null>(null);
  // const [encryptionPassword, setEncryptionPassword] = useState<string | null>(null);
  // const [editTokenMode, setEditTokenMode] = useState(false);
  // const [editUserIdMode, setEditUserIdMode] = useState(false);
  // const [editEncryptionPasswordMode, setEditEncryptionPasswordMode] = useState(
  //   false
  // );
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const { isNative } = useClientType();

  // const loadData = useCallback(
  //   async function loadData() {
  //     localSettingsRepo.getToken().then(setToken);
  //     localSettingsRepo.getUserId().then(setUserId);
  //     localSettingsRepo.getEncryptionPassword().then(setEncryptionPassword);
  //   },
  //   [localSettingsRepo]
  // );

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);

  // const convertTime = (old?: string): number | undefined => {
  //   switch (old) {
  //     case '2_MIN':
  //       return 2;
  //     case '5_MIN':
  //       return 5;
  //     case '10_MIN':
  //       return 10;
  //     case '15_MIN':
  //       return 15;
  //     case '30_MIN':
  //       return 30;
  //     case '45_MIN':
  //       return 45;
  //     case '1_H':
  //       return 60;
  //     case '2_H':
  //       return 120;
  //     case '3_H':
  //       return 180;
  //     case '6_H':
  //       return 360;
  //     case '8_H':
  //       return 480;
  //     case '2_D':
  //       return 960;
  //     default:
  //       return undefined;
  //   }
  // };
  //
  // const convertEnergy = (old?: string): number | undefined => {
  //   switch (old) {
  //     case 'LOW':
  //       return 1;
  //     case 'MED':
  //       return 100;
  //     case 'HIGH':
  //       return 1000;
  //     default:
  //       return undefined;
  //   }
  // };
  //
  // const convertDate = (date?: string): number | undefined => {
  //   if (!date) return undefined;
  //   return new Date(date).getTime();
  // };
  //
  // const makeId = (type: string, oldId: string) => {
  //   return `${type}-${_techDebt_userId}-${oldId}`;
  // };

  const exportAll = async () => {
    return _techDebt_localDb.dump();
  };

  const importAll = async (json: any) => {
    return _techDebt_localDb.importDump(json);
  };
  // const importAll = async (objects: { [k in EntityType]: any }) => {
  // const fieldsToRemove = [
  //   'id',
  //   'blocks',
  //   'project',
  //   'context',
  //   'priority',
  //   'checklists',
  //   'positions',
  //   'totalTasks',
  //   'nextTaskId'
  // ];
  //
  // for (const context of objects.contexts) {
  //   const record = {
  //     name: 'unknown name',
  //     createdAt: 0,
  //     ...omit(context, ...fieldsToRemove),
  //     id: makeId('context', context.id)
  //   } as ContextType;
  //   await repos.contextRepo._forceCreate(record);
  // }
  //
  // for (const task of objects.tasks) {
  //   const taskId = makeId('task', task.id);
  //   const taskContextId = makeId('context', task.contextId);
  //   const record = {
  //     name: 'unknown name',
  //     createdAt: 0,
  //     status: 'SOME_TIME',
  //     ...omit(task, ...fieldsToRemove),
  //     contextId: taskContextId,
  //     projectId: task.projectId ? makeId('project', task.projectId) : undefined,
  //     id: taskId,
  //     time: convertTime(task.time),
  //     energy: convertEnergy(task.energy),
  //     dateDue: convertDate(task.dateDue),
  //     dateScheduled: convertDate(task.dateScheduled)
  //   } as TaskType;
  //   await repos.taskRepo._forceCreate(record);
  //
  //   let position = 0;
  //   if (task.blocks) {
  //     for (const block of task.blocks) {
  //       await repos.blockRepo.create({
  //         type: 'text',
  //         indent: 0,
  //         ...block,
  //         position: position++,
  //         taskId,
  //         contextId: taskContextId
  //       });
  //     }
  //   }
  //   if (task.description) {
  //     const descLines = task.description.split('\n');
  //     for (const line of descLines) {
  //       if (line.trim() !== '') {
  //         await repos.blockRepo.create({
  //           content: line,
  //           type: 'text',
  //           indent: 0,
  //           position: position++,
  //           taskId,
  //           contextId: taskContextId
  //         });
  //       }
  //     }
  //   }
  // }
  //
  // for (const project of objects.projects) {
  //   const projectId = makeId('project', project.id);
  //   const projectContextId = makeId('context', project.contextId);
  //   const record = {
  //     name: 'unknown name',
  //     createdAt: 0,
  //     status: 'SOME_TIME',
  //     ...omit(project, ...fieldsToRemove),
  //     contextId: projectContextId,
  //     id: projectId,
  //     dateDue: convertDate(project.dateDue)
  //   } as ProjectType;
  //   await repos.projectRepo._forceCreate(record);
  //
  //   let position = 0;
  //   if (project.howSuccessLooksLike) {
  //     const descLines = project.howSuccessLooksLike.split('\n');
  //     for (const line of descLines) {
  //       if (line.trim() !== '') {
  //         await repos.blockRepo.create({
  //           content: line,
  //           type: 'text',
  //           indent: 0,
  //           position: position++,
  //           projectId,
  //           contextId: projectContextId
  //         });
  //       }
  //     }
  //   }
  // }
  //
  // setToastMessage('Data from file imported!');
  // setTimeout(reload, 2000);
  // };

  // function renderEditSettingsForm(
  //   isEditMode: boolean,
  //   setEditMode: (v: boolean) => void,
  //   id: string,
  //   name: string,
  //   value: string | null,
  //   setValue: (v: string) => void,
  //   persist: (v: string | null) => Promise<void>
  // ) {
  //   return (
  //     <div>
  //       {isEditMode && (
  //         <>
  //           <IonInput
  //             id={id}
  //             autocorrect="on"
  //             placeholder={name}
  //             value={value}
  //             clearInput
  //             onIonChange={(event: any) => setValue(event.target.value)}
  //           />
  //           <IonButton
  //             color="danger"
  //             onClick={() => {
  //               if (value) persist(value);
  //               if (value === '') persist(null);
  //               setEditMode(false);
  //             }}
  //           >
  //             Update {name}
  //           </IonButton>
  //         </>
  //       )}
  //       {!isEditMode && (
  //         <IonButton color="primary" onClick={() => setEditMode(true)}>
  //           Edit {name}
  //         </IonButton>
  //       )}
  //     </div>
  //   );
  // }

  return (
    <IonPage>
      <CommonHeader>
        <IonTitle>Settings</IonTitle>
      </CommonHeader>
      <IonContent>
        <div style={{ display: 'none' }}>
          <input type="file" onChange={readJsonFile(importAll)} />

          <IonButton
            color="danger"
            onClick={() => {
              exportAll().then((codeInJson) => {
                window.open(
                  URL.createObjectURL(
                    new Blob([JSON.stringify(codeInJson)], {
                      type: 'application/binary',
                    })
                  )
                );
              });
            }}
          >
            Export
          </IonButton>
        </div>

        <div className="ion-padding">
          {/*{renderEditSettingsForm(*/}
          {/*  editTokenMode,*/}
          {/*  setEditTokenMode,*/}
          {/*  'token',*/}
          {/*  'token',*/}
          {/*  token,*/}
          {/*  setToken,*/}
          {/*  localSettingsRepo.saveToken*/}
          {/*)}*/}

          {/*{renderEditSettingsForm(*/}
          {/*  editUserIdMode,*/}
          {/*  setEditUserIdMode,*/}
          {/*  'userId',*/}
          {/*  'user ID',*/}
          {/*  userId,*/}
          {/*  setUserId,*/}
          {/*  localSettingsRepo.saveUserId*/}
          {/*)}*/}

          {/*{renderEditSettingsForm(*/}
          {/*  editEncryptionPasswordMode,*/}
          {/*  setEditEncryptionPasswordMode,*/}
          {/*  'encryptionPassword',*/}
          {/*  'Encryption Password',*/}
          {/*  encryptionPassword,*/}
          {/*  setEncryptionPassword,*/}
          {/*  localSettingsRepo.saveEncryptionPassword*/}
          {/*)}*/}

          {/*<div style={{ marginTop: 100, marginLeft: 100 }}>*/}
          {/*  <IonButton*/}
          {/*    size="small"*/}
          {/*    color="danger"*/}
          {/*    onClick={() => {*/}
          {/*      */}
          {/*    }}*/}
          {/*  >*/}
          {/*    */}
          {/*  </IonButton>*/}
          {/*</div>*/}

          <div style={{ margin: 6 }}>
            <IonButton color="light" onClick={reload}>
              <IonIcon icon={refreshOutline} />
              Restart Application
            </IonButton>
          </div>

          <div style={{ margin: 6 }}>
            <LogOut />
          </div>

          <UserProfile />

          <IonCard>
            <IonCardContent>
              <IonCardHeader>
                <IonCardSubtitle>Your app version is {VERSION}</IonCardSubtitle>
              </IonCardHeader>

              {!isNative && (
                <IonButton
                  color="primary"
                  target="_blank"
                  href="https://easybusydo.com/versions/desktop"
                >
                  <IonIcon slot="start" icon={linkOutline} />
                  Check for newer versions here
                </IonButton>
              )}
            </IonCardContent>
          </IonCard>
        </div>
        <IonToast
          isOpen={Boolean(toastMessage)}
          onDidDismiss={() => setToastMessage(null)}
          message={toastMessage || ''}
          position="top"
          duration={300}
        />
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
