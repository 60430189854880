import React from 'react';
import { ModalOptions } from '@ionic/core';
import { ReactOverlayProps } from '@ionic/react/dist/types/components/createOverlayComponent';
import { omit } from 'lodash';
import { IonModalAlwaysVisible } from '../ionicFork/IonModalAlwaysVisible';
import { ModalsProps, withModalsState } from './ModalsProvider';

type Props = Pick<
  ModalOptions<import('@ionic/core').ComponentRef>,
  | 'mode'
  | 'animated'
  | 'id'
  | 'cssClass'
  | 'backdropDismiss'
  | 'keyboardClose'
  | 'enterAnimation'
  | 'leaveAnimation'
  | 'showBackdrop'
  | 'presentingElement'
  | 'delegate'
  | 'swipeToClose'
> &
  ReactOverlayProps &
  ModalsProps;

class MagicModal extends React.Component<Props> {
  componentWillUnmount() {
    if (this.props.isOpen) {
      this.props.recordMagicModalOpen(false);
    }
  }

  componentDidUpdate(prevProps: Props) {
    // special handling for Always Open modal
    // if path or context changed
    if (this.props.isOpen !== prevProps.isOpen) {
      this.props.recordMagicModalOpen(this.props.isOpen);
    }
  }

  render() {
    return (
      <IonModalAlwaysVisible {...omit(this.props, 'children')}>
        {this.props.children}
      </IonModalAlwaysVisible>
    );
  }
}

export default withModalsState(MagicModal);
