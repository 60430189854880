import { IonBadge, IonIcon, IonListHeader } from '@ionic/react';
import { colors } from '../../helpers/styles';
import React from 'react';
import './Tasks.css';
import {
  TaskCreateFormFillable,
  TaskFilter,
  TaskPositionContextEnum,
  TaskStatus,
} from '../../types/CoreTypes';
import TaskListView from './TaskListView';
import { withStorage } from '../../helpers/StorageProviders';
import QuickAdd from '../Common/QuickAdd';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import QuickAddFormTask from './QuickAddFormTask';
import { StorageDeps } from '../../helpers/InitStorage';
import { CountersProps, withCounters } from '../../helpers/CountersProvider';
import { taskStatuses, TaskStatusKey } from '../../helpers/TaskHelper';
import { isEqual } from 'lodash';
import { ListNavigationApi } from '../Common/ListNavigationCommon';
import WideMessage from '../Common/WideMessage';
import { warning } from 'ionicons/icons';
import { TaskType } from '@todo/common';

interface Props extends StorageDeps, WorkspaceProps, CountersProps {
  listNavigationApi?: ListNavigationApi;
  statuses: TaskStatusKey[];
  filter?: TaskFilter;
  defaultStatus?: TaskStatus;
  emptyMessage?: string;
  setUniqueAndStatsFilterValues?: (
    collectionName: string,
    values: TaskType[]
  ) => void;
}

class TaskStatusList extends React.Component<Props> {
  private readonly listRef: Map<TaskStatusKey, React.RefObject<any>>;

  private readonly setUniqueAndStatsFilterValuesFnMap: Map<
    TaskStatusKey,
    (values: TaskType[]) => void
  >;

  private readonly setCounterFnMap: Map<TaskStatusKey, (cnt: number) => void>;

  constructor(props: Props) {
    super(props);
    const rs: [TaskStatusKey, React.RefObject<any>][] = Object.keys(
      taskStatuses
    ).map((key) => [key as TaskStatusKey, React.createRef()]);
    this.listRef = new Map(rs);

    const setUniqueAndStatsFilterValuesFns: [
      TaskStatusKey,
      (values: TaskType[]) => void
    ][] = Object.keys(taskStatuses).map((key) => [
      key as TaskStatusKey,
      this.handleUniqueFilterValues(key as TaskStatusKey),
    ]);
    this.setUniqueAndStatsFilterValuesFnMap = new Map(
      setUniqueAndStatsFilterValuesFns
    );

    const setCounterFns: [TaskStatusKey, (cnt: number) => void][] = Object.keys(
      taskStatuses
    ).map((key) => [key as TaskStatusKey, this.setCounter(key as TaskStatusKey)]);
    this.setCounterFnMap = new Map(setCounterFns);

    this.reportCounters(props);
  }

  getCount(statusKey: TaskStatusKey): number | undefined {
    const counters = this.props.counters[this.props.context.id];
    if (!counters) return undefined;
    return counters.taskSources[statusKey]?.size;
  }

  handleUniqueFilterValues = (statusKey: TaskStatusKey) => (values: TaskType[]) => {
    const { setUniqueAndStatsFilterValues } = this.props;
    if (!setUniqueAndStatsFilterValues) return;
    setUniqueAndStatsFilterValues(statusKey, values);
  };

  setCounter = (statusKey: TaskStatusKey) => (cnt: number) => {
    const { listNavigationApi } = this.props;
    listNavigationApi?.setCounter(statusKey, cnt);
  };

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: any,
    nextContext: any
  ): boolean {
    return !isEqual(nextProps, this.props);
  }

  renderBlock = (statusKey: TaskStatusKey, filter: TaskFilter) => {
    const { listNavigationApi } = this.props;
    const status = taskStatuses[statusKey];
    const count = this.getCount(statusKey) || 0;
    const selectedIdxForBlock =
      listNavigationApi?.selectedCollectionName === statusKey
        ? listNavigationApi.selectedCollectionIdx
        : undefined;
    return (
      <div key={statusKey}>
        <IonListHeader>
          <span>
            <IonIcon icon={status.icon} />
            &nbsp;
            <span>{status.label}</span>
            &nbsp;
            <IonBadge>{count}</IonBadge>
          </span>
        </IonListHeader>
        {count > 0 && (
          <TaskListView
            setUniqueAndStatsFilterValues={this.setUniqueAndStatsFilterValuesFnMap.get(
              statusKey
            )}
            setCounter={this.setCounterFnMap.get(statusKey)}
            getRef={
              listNavigationApi?.getRef
                ? listNavigationApi.getRef(statusKey)
                : undefined
            }
            positionContext={TaskPositionContextEnum.STATUS}
            selectedIdx={selectedIdxForBlock}
            filter={{
              ...status.filter,
              ...filter,
            }}
          />
        )}
      </div>
    );
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      !isEqual(
        prevProps.listNavigationApi?.setCounter,
        this.props.listNavigationApi?.setCounter
      ) ||
      !isEqual(prevProps.filter, this.props.filter) ||
      !isEqual(prevProps.counters, this.props.counters) ||
      !isEqual(prevProps.context.id, this.props.context.id)
    ) {
      this.reportCounters(this.props);
    }
  }

  reportCounters(nextProps: Readonly<Props>) {
    nextProps.statuses.forEach((statusKey) => {
      const count = this.getCount(statusKey) || 0;
      if (count === 0) {
        const fn = this.setCounterFnMap.get(statusKey);
        if (fn) fn(0);
      }
    });
  }

  makeHandleSave = (onSave: () => void) => (args: TaskCreateFormFillable) => {
    return this.props.repos.taskRepo
      .create(args)
      .then((taskId) => {
        if (args.blocks) {
          return Promise.all(
            args.blocks.map((block) => {
              return this.props.repos.blockRepo.create({
                ...block,
                taskId,
                contextId: args.contextId,
              });
            })
          );
        }
      })
      .then(onSave);
  };

  renderEmptyMessage() {
    const total = this.props.statuses.reduce((acc, statusKey) => {
      const count = this.getCount(statusKey) || 0;
      return acc + count;
    }, 0);
    if (total > 0) return null;
    return (
      <WideMessage
        text={this.props.emptyMessage || 'No tasks here yet'}
        icon={warning}
        color={colors.grayOut}
      />
    );
  }

  render() {
    const { filter } = this.props;
    return (
      <QuickAdd
        form={({ onClose, ref, onSave, isOpen }) => (
          <QuickAddFormTask
            isOpen={isOpen}
            onClose={onClose}
            daoCatalog={this.props._techDebt_daoCatalog}
            // defaultStatus={this.props.defaultStatus}
            onSave={this.makeHandleSave(onSave)}
            ref={ref}
          />
        )}
      >
        {this.props.statuses.map((statusKey) =>
          this.renderBlock(statusKey, filter || {})
        )}
        {this.renderEmptyMessage()}
      </QuickAdd>
    );
  }
}

export default withCounters(withStorage(withWorkspace(TaskStatusList)));
