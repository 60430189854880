import React, { CSSProperties } from 'react';
import { SyncStatus } from '../types/CoreTypes';
import { IonIcon } from '@ionic/react';
import {
  alertCircleOutline,
  cloudOfflineOutline,
  flashOffOutline,
  syncCircleOutline,
} from 'ionicons/icons';

import Tooltip from 'rc-tooltip';

interface Props {
  syncStatus: SyncStatus;
}

function mapSynStatusToIcon(
  syncStatus: SyncStatus
): { icon: string; style?: CSSProperties; title: string } {
  switch (syncStatus) {
    case SyncStatus.Succeeding: {
      return {
        icon: syncCircleOutline,
        title: 'The app is syncing successfully',
        style: {
          color: 'var(--ion-color-primary)',
        },
      };
    }
    case SyncStatus.Offline: {
      return {
        icon: cloudOfflineOutline,
        title: 'The app is offline',
        style: {
          color: 'var(--ion-color-medium)',
        },
      };
    }
    case SyncStatus.NoSyncError: {
      return {
        icon: syncCircleOutline,
        title: 'The app is not syncing at the moment',
        style: {
          color: 'var(--ion-color-medium)',
        },
      };
    }
    case SyncStatus.Error: {
      return {
        icon: alertCircleOutline,
        title: 'There was an error syncing the app',
        style: {
          color: 'var(--ion-color-danger)',
        },
      };
    }
    case SyncStatus.SyncEnabledNotYetSucceeded: {
      return {
        icon: syncCircleOutline,
        title: 'The app will begin syncing shortly',
        style: {
          color: 'var(--ion-color-secondary)',
        },
      };
    }
    case SyncStatus.LocalMode: {
      return {
        icon: flashOffOutline,
        title: 'The app is in local only mode',
        style: {
          color: 'var(--ion-color-medium)',
        },
      };
    }
  }
}

const SyncStatusIcon = ({ syncStatus }: Props) => {
  const { icon, style, title } = mapSynStatusToIcon(syncStatus);
  return (
    <div>
      <Tooltip
        destroyTooltipOnHide
        placement="right"
        trigger={['hover', 'click']}
        overlay={<div style={{ width: '120px', height: '40px' }}>{title}</div>}
      >
        <IonIcon
          size="large"
          icon={icon}
          style={{ ...style, maxHeight: '22px', marginLeft: '3px' }}
        />
      </Tooltip>
    </div>
  );
};

export default SyncStatusIcon;
