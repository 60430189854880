import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { components, MultiValueProps, ValueType } from 'react-select';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  ExcludesUndefined,
  OptionGroup,
  OptionTypeWithColor,
} from '../../types/CoreTypes';
import { first } from 'lodash';
import { formatOptionLabel, MySelect, selectStyles } from './selectHelper';

interface BaseProps {
  placeholder: string;
  values?: string[];
  options: OptionGroup<OptionTypeWithColor>[];
  onChange: (values?: string[]) => void;
  onCreate?: (value: string) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

function arrayMove(array: string[], from: number, to: number) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<OptionTypeWithColor>) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  }
);

const SortableSelect = SortableContainer(MySelect);

function MultiSelect({
  values,
  onChange,
  onCreate,
  options,
  placeholder,
  forwardRef,
}: Props) {
  const [plainOptions, setPlainOptions] = useState<OptionTypeWithColor[]>([]);

  useEffect(() => {
    const flat = options.map((g) => g.options).flat();
    // console.log('$$$flat', flat);
    setPlainOptions(flat);
  }, [options]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      if (!values) return;
      const newValues = arrayMove(values, oldIndex, newIndex);
      onChange(newValues);
    },
    [onChange, values]
  );

  const handleChange = useCallback(
    (selectedOption: ValueType<OptionTypeWithColor>) => {
      if (!selectedOption) {
        return onChange(undefined);
      } else if (!Array.isArray(selectedOption)) {
        throw new Error('Unexpected type passed to ReactSelect onChange handler');
      }
      if (!selectedOption.length) {
        setTimeout(() => {
          // @ts-ignore
          forwardRef?.current?.blur();
        }, 10);
      }
      onChange(selectedOption.map((r) => r.value));
    },
    [forwardRef, onChange]
  );

  return (
    <SortableSelect
      forwardRef={forwardRef}
      // @ts-ignore
      placeholder={placeholder}
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={options}
      value={values
        ?.map((v) => first(plainOptions.filter((o) => o.value === v)))
        .filter((Boolean as any) as ExcludesUndefined)}
      onChange={handleChange}
      formatOptionLabel={formatOptionLabel}
      // formatGroupLabel={}
      components={{
        // @ts-ignore
        MultiValue: SortableMultiValue,
      }}
      // @ts-ignore
      styles={selectStyles}
      onCreateOption={onCreate}
      isCreatable={!!onCreate}
      openMenuOnFocus
      closeMenuOnSelect
    />
  );
}

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <MultiSelect forwardRef={ref} {...props} />
));
