import React from 'react';
import { genericStyles } from '../../helpers/styles';
import { Nullable, Priority, PriorityValues } from '../../types/CoreTypes';
import { getPriorityLabel } from '../../helpers/CopyHelper';
import {
  getPriorityBoundaries,
  getPriorityEnum,
  getPriorityScore,
} from '../../helpers/PriorityHelper';
import { IonIcon } from '@ionic/react';
import { alertCircle } from 'ionicons/icons';

interface Props {
  priority: Nullable<PriorityValues>;
  onClick?: any;
}

function getColor(priority: Nullable<PriorityValues>) {
  const score = getPriorityScore(priority);
  if (!score) return genericStyles.grayOutColor;
  if (score <= getPriorityBoundaries(Priority.PRIORITY_LOW)[1])
    return genericStyles.greenColor;
  if (score >= getPriorityBoundaries(Priority.PRIORITY_HIGH)[0])
    return genericStyles.dangerColor;
  return genericStyles.primaryColor;
}

function getPriorityValuesLabel(priority: Nullable<PriorityValues>) {
  return getPriorityLabel(getPriorityEnum(priority));
}

const PriorityInfo = ({ priority, onClick }: Props) => {
  return (
    <span
      className={onClick ? 'clickable-control' : ''}
      onClick={onClick}
      style={{
        ...genericStyles.iconLabel,
        ...(onClick ? genericStyles.propertyClickable : genericStyles.property),
        ...getColor(priority),
        fontWeight: 'bold',
      }}
    >
      <IonIcon style={genericStyles.icon} icon={alertCircle} />
      <span style={genericStyles.iconLabel}>{getPriorityValuesLabel(priority)}</span>
    </span>
  );
};

export default PriorityInfo;
