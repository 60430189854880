const schema = {
  keyCompression: false,
  title: 'tag schema',
  version: 0,
  description: 'describes a tag',
  type: 'object',
  properties: {
    id: {
      type: 'string',
      primary: true,
    },
    name: {
      type: 'string',
    },
    color: {
      type: 'string',
    },
    icon: {
      type: 'string',
    },
    contextId: {
      type: 'string',
    },
    parentId: {
      type: 'string',
    },
    position: {
      type: 'number',
    },
    createdAt: {
      type: 'number',
      final: true,
    },
    lastUpdated: {
      type: 'number',
    },
    serverUpdatedAt: {
      type: 'number',
    },
    last_pulled_rev: {
      type: 'string',
    },
    deviceId: {
      type: 'string',
    },
    data: {
      type: 'object',
    },
    // userId: {
    //   ref: 'task',
    //   type: 'string',
    // },
  },
  required: ['id', 'name', 'color', 'icon', 'contextId'],
  // encrypted: ['secret'],
  // attachments: {
  //   encrypted: true
  // },
  indexes: [['contextId', 'parentId'], 'parentId'],
};

export default schema;
