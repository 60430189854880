import React from 'react';
import { TagEnrichedWithParent, TaskStatus } from '../../types/CoreTypes';
import QuickAdd from '../Common/QuickAdd';
import { withStorage } from '../../helpers/StorageProviders';
import { withWorkspace, WorkspaceProps } from '../../helpers/WorkspaceProviders';
import { IonButton, IonIcon, IonLabel, IonNote, IonRouterLink } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router';
import formatRelative from 'date-fns/formatRelative';
import QuickAddFormTask from '../Tasks/QuickAddFormTask';
import { StorageDeps } from '../../helpers/InitStorage';
import TaskListFilter from '../Tasks/TaskListFilter';
import { TableCell } from '../Common/TableCell';
import { genericStyles } from '../../helpers/styles';
import { chevronForward, create } from 'ionicons/icons';
import { DocumentEditorSync } from '../Common/DocumentEditor';
import SingleTag from '../Common/SingleTag';
import TagListView from './TagListView';
import TagEditWrapper from './TagEditWrapper';
import QuickAddForm from '../Common/QuickAddForm';
import TaskListViewTags from '../Tasks/TaskListViewTags';
import ListNavigation from '../Common/ListNavigation';
import WrapForCollectionBasedCallbacks from '../Common/WrapForCollectionBasedCallbacks';
import { DateProps, withDate } from '../../helpers/DateProvider';
import { ModalsProps, withModalsState } from '../../helpers/ModalsProvider';
import { TAG_NAME_MAX_LENGTH } from '../../lib/Constants';

interface Props
  extends StorageDeps,
    ModalsProps,
    RouteComponentProps,
    WorkspaceProps,
    DateProps {
  tag: TagEnrichedWithParent | null;
}

interface State {
  addTagOpen: boolean;
  blockIsSelected: boolean;
}

const TASKS_COLLECTION_NAME = 'tasks';

class TagDetails extends React.PureComponent<Props, State> {
  state: State = {
    addTagOpen: false,
    blockIsSelected: false,
  };

  private readonly quickAddRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.quickAddRef = React.createRef();
  }

  setBlockSelected = () => {
    this.setState({ blockIsSelected: true });
  };

  setBlockNotSelected = () => {
    this.setState({ blockIsSelected: false });
  };

  render() {
    const { tag } = this.props;
    if (!tag) {
      return <div>No tag details found..</div>;
    }

    return (
      <QuickAdd
        form={({ onClose, ref, onSave, isOpen }) => (
          <QuickAddFormTask
            isOpen={isOpen}
            onClose={onClose}
            daoCatalog={this.props._techDebt_daoCatalog}
            // defaultStatus={TaskStatus.STATUS_ACTION_LIST}
            defaultTag={this.props.tag || undefined}
            onSave={(args) =>
              this.props.repos.taskRepo
                .create(args)
                .then((taskId) => {
                  if (args.blocks) {
                    return Promise.all(
                      args.blocks.map((block) => {
                        return this.props.repos.blockRepo.create({
                          ...block,
                          taskId,
                          contextId: args.contextId,
                        });
                      })
                    );
                  }
                })
                .then(onSave)
            }
            ref={ref}
          />
        )}
      >
        <div className="ion-padding detail-page-with-blocks">
          <div className="tag-details-page">
            <div className="list-row">
              {tag.parent && (
                <>
                  <SingleTag tag={tag.parent} />
                  <IonIcon icon={chevronForward} style={{ marginTop: '6px' }} />
                </>
              )}
              <SingleTag disableLink tag={tag} />
            </div>

            <div>
              <IonNote>
                Created: {formatRelative(tag.createdAt, this.props.date)}
              </IonNote>
            </div>
            {tag.lastUpdated && (
              <div>
                <IonNote>
                  Modified: {formatRelative(tag.lastUpdated, this.props.date)}
                </IonNote>
              </div>
            )}

            <DocumentEditorSync
              isHidden={this.props.modalIsOpen}
              contextId={tag.contextId}
              syncParams={{
                selector: { tagId: tag.id },
                localBlockCollection: this.props._techDebt_localBlockDb,
              }}
              daoCatalog={this.props._techDebt_daoCatalog}
              onBlockBlur={this.setBlockNotSelected}
              onBlockFocus={this.setBlockSelected}
            />

            <div className="list-row">
              <TableCell className="table-cell-name">
                <IonRouterLink
                  routerLink={`/tags/edit/${tag.id}`}
                  routerDirection={'root'}
                  color="medium"
                >
                  <IonIcon style={genericStyles.icon} icon={create} />
                  <IonLabel>Edit tag</IonLabel>
                </IonRouterLink>
              </TableCell>
            </div>
          </div>

          <h2>Tasks</h2>
          <div
            style={{
              marginLeft: '-15px',
              marginRight: '-15px',
            }}
          >
            <ListNavigation
              isDisabled={this.state.blockIsSelected}
              collections={[TASKS_COLLECTION_NAME]}
            >
              {(listNavigationApi) => (
                <TaskListFilter>
                  {({ filter, setUniqueAndStatsFilterValues }) => (
                    <WrapForCollectionBasedCallbacks
                      setCounter={listNavigationApi.setCounter}
                      setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                      collectionName={TASKS_COLLECTION_NAME}
                    >
                      {({ setCounter, setUniqueAndStatsFilterValues }) => (
                        <TaskListViewTags
                          selectedIdx={listNavigationApi.selectedCollectionIdx}
                          setUniqueAndStatsFilterValues={
                            setUniqueAndStatsFilterValues
                          }
                          setCounter={setCounter}
                          getRef={
                            listNavigationApi?.getRef
                              ? listNavigationApi.getRef(TASKS_COLLECTION_NAME)
                              : undefined
                          }
                          tagContext={tag.id}
                          filter={{
                            ...filter,
                            notStatus: TaskStatus.STATUS_DONE,
                            tags: [...(filter.tags || []), tag.id],
                          }}
                          emptyMessage="No tasks here yet. Add more tasks to this tag to categorize them"
                        />
                      )}
                    </WrapForCollectionBasedCallbacks>
                  )}
                </TaskListFilter>
              )}
            </ListNavigation>
          </div>

          <h2>Child tags</h2>
          <TagEditWrapper>
            {({ createTag, updateTag, deleteTag }) => (
              <>
                <TagListView
                  parentId={tag.id}
                  onChangeTag={updateTag}
                  onDeleteTag={deleteTag}
                />
                <QuickAdd
                  ref={this.quickAddRef}
                  form={({ onClose, ref, onSave, isOpen }) => (
                    <QuickAddForm
                      placeholder="Tag name"
                      maxLen={TAG_NAME_MAX_LENGTH}
                      onClose={onClose}
                      isOpen={isOpen}
                      onSave={(p) =>
                        createTag({
                          ...p,
                          parentId: tag.id,
                          color: tag.color,
                          icon: tag.icon,
                        }).then(onSave)
                      }
                      ref={ref}
                    />
                  )}
                  isEmbed={true}
                />
                <IonButton
                  color="primary"
                  onClick={() =>
                    this.quickAddRef.current && this.quickAddRef.current.handleOpen()
                  }
                  style={{ float: 'right' }}
                >
                  Add new child tag
                </IonButton>
              </>
            )}
          </TagEditWrapper>
        </div>
      </QuickAdd>
    );
  }
}

export default withDate(
  withModalsState(withRouter(withStorage(withWorkspace(TagDetails))))
);
