import React from 'react';
import { RouteComponentProps } from 'react-router';
import Tags from '../components/Tags/Tags';
import { IonContent, IonPage, IonTitle } from '@ionic/react';
import CommonHeader from '../components/Common/CommonHeader';

const TagsPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <IonPage>
      <CommonHeader>
        <IonTitle>Tags</IonTitle>
      </CommonHeader>
      <IonContent>
        <Tags />
      </IonContent>
    </IonPage>
  );
};

export default TagsPage;
