import React, { FormEvent, useCallback, useState } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonSpinner,
} from '@ionic/react';
import { PACKAGE_TYPE, PurchasesPackage } from '@ionic-native/purchases';
import * as stripeJs from '@stripe/stripe-js';

interface Props {
  pkg: PurchasesPackage;
  onSuccess: (
    stripeToken: string,
    handleNextAction: (secret: string) => Promise<string>
  ) => Promise<void>;
}

const CARD_OPTIONS: stripeJs.StripeCardExpiryElementOptions = {
  style: {
    base: {
      // iconStyle: 'solid',
      iconColor: '#444',
      color: '#444',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      backgroundColor: '#eee',
      ':-webkit-autofill': { color: '#999' },
      '::placeholder': { color: '#999' },
    },
    invalid: {
      iconColor: '#dc0d0d',
      color: '#dc0d0d',
    },
  },
};

const StripeFormInner = ({ pkg, onSuccess }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent<any>) => {
      // Block native form submission.
      event.preventDefault();

      setErrorMsg(null);

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement('card');

      if (!cardElement) {
        throw new Error('No card element found!');
      }

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setErrorMsg(error.message || 'Unknown error');
      } else {
        if (!paymentMethod) {
          throw new Error('No payment method!');
        }
        await onSuccess(paymentMethod.id, (secret: string) => {
          // stripe.confirmCardPayment(secret);
          // stripe.handleCardAction(secret);
          return stripe.confirmCardPayment(secret).then((result) => {
            if (result.error) {
              throw new Error(result.error.message);
            } else {
              return result.paymentIntent.id;
            }
          });
        });
      }
    },
    [elements, onSuccess, stripe]
  );

  const handleSubmitWrapper = useCallback(
    (event: FormEvent<any>) => {
      setLoading(true);
      handleSubmit(event).finally(() => {
        setLoading(false);
      });
    },
    [handleSubmit]
  );

  return (
    <form onSubmit={handleSubmitWrapper}>
      <IonCard style={{ maxWidth: 400 }}>
        <IonCardHeader>
          <IonCardTitle>Purchase {pkg.product.title} package</IonCardTitle>

          <IonCardSubtitle>
            for {pkg.product.price_string} /{' '}
            {pkg.packageType === PACKAGE_TYPE.MONTHLY ? 'mo' : 'yr'}
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <IonGrid>
            {/*<IonRow>*/}
            {/*  <IonCol size="12">*/}
            {/*    <h1></h1>*/}
            {/*  </IonCol>*/}
            {/*</IonRow>*/}
            <IonRow>
              Card details
              <IonCol
                size="12"
                style={{
                  backgroundColor: '#eee',
                  padding: '5px',
                  borderRadius: '10px',
                }}
              >
                <CardElement options={CARD_OPTIONS} />
              </IonCol>
              {/*<IonCol size="12">*/}
              {/*  <CardNumberElement options={CARD_OPTIONS} />*/}
              {/*</IonCol>*/}
              {/*<IonCol size="6">*/}
              {/*  <CardExpiryElement options={CARD_OPTIONS} />*/}
              {/*</IonCol>*/}
              {/*<IonCol size="6">*/}
              {/*  <CardCvcElement options={CARD_OPTIONS} />*/}
              {/*</IonCol>*/}
            </IonRow>
            {errorMsg && (
              <IonRow>
                <IonCol size="12" style={{ color: 'red' }}>
                  {errorMsg}
                </IonCol>
              </IonRow>
            )}
            <IonRow>
              <IonCol size="12">
                <IonButton type="submit" disabled={!stripe || loading}>
                  {loading && <IonSpinner />}
                  Pay {pkg.product.price_string}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </form>
  );
};

export default StripeFormInner;
