import PouchDAO from './data/PouchDAO';

export abstract class BaseRepo<
  Form extends Record<any, any>,
  Context extends string | undefined
> {
  protected readonly dao: PouchDAO<Form, Context>;

  get _techDebt_dao() {
    return this.dao;
  }

  constructor(dao: PouchDAO<Form, Context>) {
    this.dao = dao;
  }
}
