import React from 'react';
import TaskListFilter from './Tasks/TaskListFilter';
import ListNavigation from './Common/ListNavigation';
import { TaskStatus } from '../types/CoreTypes';
import TaskListView from './Tasks/TaskListView';
import WrapForCollectionBasedCallbacks from './Common/WrapForCollectionBasedCallbacks';
import { some } from 'lodash';
import WideMessage from './Common/WideMessage';
import { checkbox, squareOutline, warning } from 'ionicons/icons';
import { colors } from '../helpers/styles';
import { logDebug } from '../lib/logger';
import { IonIcon, IonLabel } from '@ionic/react';
import { ProjectStatus, TaskType } from '@todo/common';
import { sortByPriority, sortByProjectStatus } from '../helpers/TaskHelper';

const TASKS_COLLECTION_NAME = 'tasks';

interface Props {
  onClose: () => void;
}

interface State {
  onlyInProgressAndNextProjects: boolean;
}

class FindTasksByCriteria extends React.Component<Props, State> {
  readonly state: State = {
    onlyInProgressAndNextProjects: true,
  };

  handleKeyPress = (e: KeyboardEvent) => {
    logDebug([], 'handling key press in search');
    // need this for "AlwaysVisible" modal
    if (e.key === 'Escape') {
      e.preventDefault();
      this.props.onClose();
    }
  };

  mountKeyListeners() {
    logDebug([], 'loading search modal keyboard listeners...');
    document.addEventListener('keydown', this.handleKeyPress, false);
  }

  unmountKeyListeners() {
    logDebug([], 'unloading search modal keyboard listeners...');
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  componentDidMount(): void {
    this.mountKeyListeners();
  }

  componentWillUnmount() {
    this.unmountKeyListeners();
  }

  renderEmtpy(
    setUniqueAndStatsFilterValues?: (
      collectionName: string,
      values: TaskType[]
    ) => void
  ) {
    setUniqueAndStatsFilterValues &&
      setUniqueAndStatsFilterValues(TASKS_COLLECTION_NAME, []);
    return (
      <WideMessage
        text="Please use filters to find tasks"
        icon={warning}
        color={colors.grayOut}
      />
    );
  }

  render() {
    const { onlyInProgressAndNextProjects } = this.state;
    return (
      <ListNavigation useSecondLevelModal collections={[TASKS_COLLECTION_NAME]}>
        {(listNavigationApi) => (
          <div>
            <div>
              <IonIcon
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    onlyInProgressAndNextProjects: !onlyInProgressAndNextProjects,
                  });
                }}
                color="primary"
                size="small"
                className="checkbox"
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  margin: '5px 5px 0px 5px',
                  cursor: 'pointer',
                }}
                icon={onlyInProgressAndNextProjects ? checkbox : squareOutline}
              />
              <IonLabel
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  margin: '5px 5px 0px 5px',
                  fontSize: 13,
                }}
              >
                Exclude Waiting and Someday projects
              </IonLabel>
            </div>
            <TaskListFilter>
              {({ filter, setUniqueAndStatsFilterValues }) =>
                some(filter, (v) =>
                  Array.isArray(v) ? v.length > 0 : v !== undefined
                ) ? (
                  <WrapForCollectionBasedCallbacks
                    setCounter={listNavigationApi.setCounter}
                    setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                    collectionName={TASKS_COLLECTION_NAME}
                  >
                    {({ setCounter, setUniqueAndStatsFilterValues }) => (
                      <TaskListView
                        selectedIdx={listNavigationApi.selectedCollectionIdx}
                        sortFn={sortByPriority}
                        enrichedSortFn={sortByProjectStatus}
                        setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                        setCounter={setCounter}
                        getRef={
                          listNavigationApi?.getRef
                            ? listNavigationApi.getRef(TASKS_COLLECTION_NAME)
                            : undefined
                        }
                        filter={{
                          notStatus: TaskStatus.STATUS_DONE,
                          projectStatuses: onlyInProgressAndNextProjects
                            ? [
                                ProjectStatus.STATUS_NEXT,
                                ProjectStatus.STATUS_ACTION,
                              ]
                            : undefined,
                          ...filter,
                        }}
                        onAfterNavigate={this.props.onClose}
                        disableReorder
                        compact
                        hideTags
                        limit={60}
                        emptyMessage="No tasks here yet. Try using a different filter"
                      />
                    )}
                  </WrapForCollectionBasedCallbacks>
                ) : (
                  this.renderEmtpy(setUniqueAndStatsFilterValues)
                )
              }
            </TaskListFilter>
          </div>
        )}
      </ListNavigation>
    );
  }
}

export default FindTasksByCriteria;
