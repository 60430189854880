import React, { Component, forwardRef } from 'react';
import {
  RecurringEndsOptions,
  RecurringOptions,
  RecurringRepeatingOptions,
} from '../../types/CoreTypes';
import SingleSelect from './SingleSelect';
import {
  IonChip,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonRow,
  IonToggle,
} from '@ionic/react';
import getDate from 'date-fns/getDate';
import DatePicker from './DatePicker';
import { days, daysFull, getWeekDayAndNth } from '../../lib/DateHelper';
import { genericStyles } from '../../helpers/styles';
import { ToggleChangeEventDetail } from '@ionic/core';
import addMonths from 'date-fns/addMonths';
import {
  formatDateWithoutTime,
  makeDateWithoutTime,
} from '../../helpers/RecurringHelper';

interface BaseProps {
  placeholder: string;
  value: RecurringOptions;
  onChange: (value: RecurringOptions) => void;
}

interface Props extends BaseProps {
  forwardRef?: React.Ref<any>;
}

enum MonthlyOptions {
  NTH = 'NTH',
  DATE = 'DATE',
}

enum RepeatEvery {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

enum EndingOptions {
  DATE = 'DATE',
  OCCURRENCES = 'OCCURRENCES',
  NEVER = 'NEVER',
}

const endingOptions = [
  {
    value: EndingOptions.DATE,
    label: 'Date',
  },
  {
    value: EndingOptions.OCCURRENCES,
    label: 'Occurrences',
  },
  {
    value: EndingOptions.NEVER,
    label: 'Never',
  },
];

const repeatingEveryOptions = [
  {
    value: RepeatEvery.DAYS,
    label: 'days',
  },
  {
    value: RepeatEvery.WEEKS,
    label: 'weeks',
  },
  {
    value: RepeatEvery.MONTHS,
    label: 'months',
  },
  {
    value: RepeatEvery.YEARS,
    label: 'years',
  },
];

// todo: use start of day or nvm?
class RecurringDatePicker extends Component<Props> {
  // constructor(props: Props) {
  //   super(props);
  //
  //   this.state = {
  //     recurringOptions: props.value || {
  //       starting: {
  //         date: new Date().getTime()
  //       },
  //       endsAfter: {},
  //       repeatingEvery: {
  //         months: {
  //           months: 1
  //         }
  //       }
  //     }
  //   };
  // }

  // handleInputChange = (value: string) => {
  //   if (!value) {
  //     this.setState({ options: defaultOptions() });
  //     return;
  //   }
  //
  //   const dates = getDateSuggestions(value);
  //
  //   if (dates) {
  //     const firstDate = first(dates);
  //     this.setState({
  //       options: [
  //         ...dates.map(createOptionForDate),
  //         createCalendarOptions(firstDate?.date)
  //       ]
  //     });
  //   } else {
  //     this.setState({
  //       options: []
  //     });
  //   }
  // };

  // handleChange = (selectedOption: ValueType<DateOption>) => {
  //   if (!selectedOption) {
  //     setTimeout(() => {
  //       // @ts-ignore
  //       this.props.forwardRef?.current?.blur();
  //     }, 10);
  //   }
  //   if (Array.isArray(selectedOption)) {
  //     throw new Error('Unexpected type passed to ReactSelect onChange handler');
  //   }
  //   const value = (selectedOption as OptionTypeWithColor | undefined)?.value;
  //   this.props.onChange(value);
  // };

  getRepeatingType = (value: RecurringRepeatingOptions) => {
    if (value.days) return RepeatEvery.DAYS;
    if (value.weeks) return RepeatEvery.WEEKS;
    if (value.months) return RepeatEvery.MONTHS;
    if (value.years) return RepeatEvery.YEARS;
  };

  getRepeatingFrequency = (value: RecurringRepeatingOptions) => {
    if (value.days) return value.days.days;
    if (value.weeks) return value.weeks.weeks;
    if (value.months) return value.months.months;
    if (value.years) return value.years.years;
  };

  setValue = (newValue: Partial<RecurringOptions>) => {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      ...newValue,
    });
  };

  setType = (val: string | undefined) => {
    if (!val) return;
    const value = val as RepeatEvery;

    if (value === RepeatEvery.DAYS) {
      this.setValue({
        repeatingEvery: {
          days: {
            days: 1,
          },
        },
      });
    }
    if (value === RepeatEvery.WEEKS) {
      this.setValue({
        repeatingEvery: {
          weeks: {
            weeks: 1,
            weekdays: [1],
          },
        },
      });
    }
    if (value === RepeatEvery.MONTHS) {
      this.setValue({
        repeatingEvery: {
          months: {
            months: 1,
            date: getDate(new Date()),
          },
        },
      });
    }
    if (value === RepeatEvery.YEARS) {
      this.setValue({
        repeatingEvery: {
          years: {
            years: 1,
          },
        },
      });
    }
  };

  setFrequency = (val: string | undefined) => {
    if (!val) return;
    const newValue = parseInt(val);
    if (isNaN(newValue)) return;
    const { value } = this.props;

    if (value.repeatingEvery.days) {
      this.setValue({
        repeatingEvery: {
          days: {
            ...value.repeatingEvery.days,
            days: newValue,
          },
        },
      });
    }
    if (value.repeatingEvery.weeks) {
      this.setValue({
        repeatingEvery: {
          weeks: {
            ...value.repeatingEvery.weeks,
            weeks: newValue,
          },
        },
      });
    }
    if (value.repeatingEvery.months) {
      this.setValue({
        repeatingEvery: {
          months: {
            ...value.repeatingEvery.months,
            months: newValue,
          },
        },
      });
    }
    if (value.repeatingEvery.years) {
      this.setValue({
        repeatingEvery: {
          years: {
            ...value.repeatingEvery.years,
            years: newValue,
          },
        },
      });
    }
  };

  toggleWeekDay = (idx: number) => () => {
    const { value } = this.props;
    const currentWeeks = value.repeatingEvery.weeks;
    if (!currentWeeks) return;
    const wasSelected = currentWeeks.weekdays.includes(idx);
    this.setValue({
      repeatingEvery: {
        weeks: {
          ...currentWeeks,
          weekdays: wasSelected
            ? currentWeeks.weekdays.filter((v) => v !== idx)
            : [...currentWeeks.weekdays, idx],
        },
      },
    });
  };

  makeMonthOptions = () => {
    const { value } = this.props;
    const { date, day, nth } = getWeekDayAndNth(
      makeDateWithoutTime(value.starting.date)
    );
    return [
      {
        value: MonthlyOptions.NTH,
        label: `${nth > 4 ? 'last' : nth} ${daysFull[day]} of month`,
      },
      {
        value: MonthlyOptions.DATE,
        label: `Monthly on day ${date}`,
      },
    ];
  };

  getCurrentMonthOption = () => {
    const { value } = this.props;

    if (value.repeatingEvery.months?.date) {
      return MonthlyOptions.DATE;
    } else {
      return MonthlyOptions.NTH;
    }
  };

  setMonthOption = (newValue: string | undefined) => {
    const val = newValue as MonthlyOptions;
    const newRecurringValue = this.setMonthOptionFromStartDate(
      this.props.value.starting.date,
      val
    );
    if (newRecurringValue) {
      this.setValue({
        repeatingEvery: newRecurringValue,
      });
    }
  };

  setMonthOptionFromStartDate(
    startDate: string,
    val: MonthlyOptions
  ): RecurringRepeatingOptions | null {
    const { value } = this.props;
    const currentMonths = value.repeatingEvery.months;
    if (!currentMonths) return null;
    const { date, day, nth } = getWeekDayAndNth(makeDateWithoutTime(startDate));
    switch (val) {
      case MonthlyOptions.NTH: {
        return {
          months: {
            ...currentMonths,
            date: undefined,
            weekday: {
              weekday: day,
              weekdayNumber: nth,
            },
          },
        };
      }
      case MonthlyOptions.DATE: {
        return {
          months: {
            ...currentMonths,
            date: date,
            weekday: undefined,
          },
        };
      }
    }
  }

  setStarting = (date: string | undefined) => {
    const dateStr = formatDateWithoutTime(date ? new Date(date) : new Date());
    const changes: Partial<RecurringOptions> = {
      starting: {
        date: dateStr,
      },
    };
    const newRecurringValue = this.setMonthOptionFromStartDate(
      dateStr,
      this.getCurrentMonthOption()
    );
    if (newRecurringValue) {
      changes.repeatingEvery = newRecurringValue;
    }
    this.setValue(changes);
  };

  setAfterLastCompleted = (e: CustomEvent<ToggleChangeEventDetail>) => {
    const { value } = this.props;

    if (value.repeatingEvery.days) {
      this.setValue({
        repeatingEvery: {
          days: {
            ...value.repeatingEvery.days,
            afterLastCompleted: e.detail.checked,
          },
        },
      });
    }
    if (value.repeatingEvery.years) {
      this.setValue({
        repeatingEvery: {
          years: {
            ...value.repeatingEvery.years,
            afterLastCompleted: e.detail.checked,
          },
        },
      });
    }
  };

  getEndingType = (endingOptions: RecurringEndsOptions): EndingOptions => {
    if (endingOptions.date) return EndingOptions.DATE;
    if (endingOptions.occurrences) return EndingOptions.OCCURRENCES;
    return EndingOptions.NEVER;
  };

  setEndingType = (val: string | undefined) => {
    if (!val) return;
    const value = val as EndingOptions;

    switch (value) {
      case EndingOptions.DATE: {
        this.setValue({
          endsAfter: {
            date: formatDateWithoutTime(addMonths(new Date(), 3)),
          },
        });
        break;
      }
      case EndingOptions.OCCURRENCES: {
        this.setValue({
          endsAfter: {
            occurrences: 10,
          },
        });
        break;
      }
      case EndingOptions.NEVER: {
        this.setValue({
          endsAfter: {},
        });
        break;
      }
    }
  };

  render() {
    const { value } = this.props;
    return (
      <div>
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <IonLabel>Repeat every</IonLabel>
            </IonCol>
            <IonCol size="2">
              <IonInput
                ref={this.props.forwardRef}
                placeholder="Frequency"
                value={this.getRepeatingFrequency(value.repeatingEvery)}
                clearInput={false}
                onIonChange={(event: any) => this.setFrequency(event.target.value)}
              />
            </IonCol>
            <IonCol size="6">
              <SingleSelect
                placeholder="type"
                isClearable={false}
                value={this.getRepeatingType(value.repeatingEvery)}
                onChange={this.setType}
                options={repeatingEveryOptions}
              />
            </IonCol>
          </IonRow>
          {value.repeatingEvery.weeks && (
            <IonRow>
              <IonCol size="4">
                <IonLabel>Repeat on</IonLabel>
              </IonCol>
              <IonCol size="8">
                {days.map((d, idx) => (
                  <IonChip
                    onClick={this.toggleWeekDay(idx)}
                    style={{
                      ...genericStyles.chip,
                      ...genericStyles.iconLabel,
                      ...genericStyles.propertyClickable,
                    }}
                    color={
                      value.repeatingEvery.weeks?.weekdays.includes(idx)
                        ? 'success'
                        : 'light'
                    }
                    key={`day-${idx}`}
                  >
                    <IonLabel>{d}</IonLabel>
                  </IonChip>
                ))}
              </IonCol>
            </IonRow>
          )}
          {value.repeatingEvery.months && (
            <IonRow>
              <IonCol size="4">
                <IonLabel>Repeat on</IonLabel>
              </IonCol>
              <IonCol size="8">
                <SingleSelect
                  value={this.getCurrentMonthOption()}
                  placeholder="Repeat on"
                  isClearable={false}
                  options={this.makeMonthOptions()}
                  onChange={this.setMonthOption}
                />
              </IonCol>
            </IonRow>
          )}
          {(value.repeatingEvery.days || value.repeatingEvery.years) && (
            <IonRow>
              <IonCol size="4">
                <IonLabel>After last completed</IonLabel>
              </IonCol>
              <IonCol size="8">
                <IonToggle
                  checked={
                    value.repeatingEvery.days?.afterLastCompleted ||
                    value.repeatingEvery.years?.afterLastCompleted
                  }
                  onIonChange={this.setAfterLastCompleted}
                />
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol size="4">
              <IonLabel>Starting at</IonLabel>
            </IonCol>
            <IonCol size="8">
              <DatePicker
                // ref={forwardRef}
                value={makeDateWithoutTime(value.starting.date).toISOString()}
                placeholder="Start Date"
                onChange={this.setStarting}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="4">
              <IonLabel>Ends</IonLabel>
            </IonCol>
            <IonCol size="8">
              <SingleSelect
                placeholder="type"
                isClearable={false}
                value={this.getEndingType(value.endsAfter)}
                onChange={this.setEndingType}
                options={endingOptions}
              />
            </IonCol>
          </IonRow>
          {value.endsAfter.date && (
            <IonRow>
              <IonCol size="4">
                <IonLabel>Ending on</IonLabel>
              </IonCol>
              <IonCol size="8">
                <DatePicker
                  value={
                    value.endsAfter.date
                      ? makeDateWithoutTime(value.endsAfter.date).toISOString()
                      : undefined
                  }
                  placeholder="End Date"
                  onChange={(date) =>
                    this.setValue({
                      endsAfter: {
                        date: formatDateWithoutTime(
                          date ? new Date(date) : new Date()
                        ),
                      },
                    })
                  }
                />
              </IonCol>
            </IonRow>
          )}
          {value.endsAfter.occurrences && (
            <IonRow>
              <IonCol size="4">
                <IonLabel>Ending after</IonLabel>
              </IonCol>
              <IonCol size="2">
                <IonInput
                  ref={this.props.forwardRef}
                  placeholder="how many"
                  value={value.endsAfter.occurrences}
                  clearInput={false}
                  onIonChange={(event: any) =>
                    this.setValue({
                      endsAfter: {
                        occurrences: parseInt(event.target.value) || undefined,
                      },
                    })
                  }
                />
              </IonCol>
              <IonCol size="6">
                <IonLabel>occurrences</IonLabel>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </div>
    );
  }
}

// eslint-disable-next-line react/display-name
export default forwardRef<any, Props>((props: BaseProps, ref: React.Ref<any>) => (
  <RecurringDatePicker forwardRef={ref} {...props} />
));
