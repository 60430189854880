import React from 'react';
import TaskStatusList from '../components/Tasks/TaskStatusList';
import { IonContent, IonPage } from '@ionic/react';
import TaskListFilter from '../components/Tasks/TaskListFilter';
import CommonHeader from '../components/Common/CommonHeader';
import { TaskStatusKey } from '../helpers/TaskHelper';
import ListNavigation from '../components/Common/ListNavigation';
import StatsCompletedToday from '../components/Tasks/StatsCompletedToday';
import StatsCreatedToday from '../components/Tasks/StatsCreatedToday';
import AchievementsWidget from '../components/Achievements/AchievementsWidget';

class Focus extends React.Component {
  private statuses = [TaskStatusKey.DueToday, TaskStatusKey.InProgress];

  render() {
    return (
      <IonPage>
        <CommonHeader />
        <IonContent>
          <ListNavigation collections={this.statuses}>
            {(listNavigationApi) => (
              <TaskListFilter>
                {({ filter, setUniqueAndStatsFilterValues }) => (
                  <TaskStatusList
                    // defaultStatus={TaskStatus.STATUS_IN_PROGRESS}
                    listNavigationApi={listNavigationApi}
                    filter={filter}
                    setUniqueAndStatsFilterValues={setUniqueAndStatsFilterValues}
                    statuses={this.statuses}
                    emptyMessage="No tasks here yet. You can 'star' tasks to show it here, and use this list to focus on a few tasks"
                  />
                )}
              </TaskListFilter>
            )}
          </ListNavigation>
          <div className="focus-wrapper">
            <div className="focus-achievements">
              <AchievementsWidget />
            </div>
            <div className="focus-stats">
              <StatsCompletedToday />
              <StatsCreatedToday />
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default Focus;
