import { IonItem, IonLabel } from '@ionic/react';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import {
  HotKeyCombination,
  Key,
  KeyModifier,
  NamedHotKeyHandler,
} from '../../types/CoreTypes';
import { desktopListStyles, genericStyles } from '../../helpers/styles';
import { TableCell } from './TableCell';
import { useClientType } from '../../helpers/ClientTypeProvider';

interface Props {
  handler: NamedHotKeyHandler;
  isSelected?: boolean;
  ref?: any;
}

const HotKey = ({
  hotkey,
  modifiers,
}: {
  hotkey: Key;
  modifiers: KeyModifier[];
}) => {
  const mod = modifiers.map(modifierToIcon).join(' ');
  return (
    <span className="context-hot-key">
      {mod}
      {mod ? ' ' : ''}
      {keyToIcon(hotkey)}
    </span>
  );
};

function keyToIcon(key: Key): string {
  switch (key) {
    case Key.Escape:
      return 'Esc';
    case Key.Enter:
      return '↵';
    case Key.Up:
      return '↑';
    case Key.Down:
      return '↓';
    default:
      return key.toString();
  }
}

function modifierToIcon(mod: KeyModifier): string {
  switch (mod) {
    case KeyModifier.Cmd:
      return '⌘';
    default:
      return mod.toString();
  }
}

const HotKeyCombo = ({ combination }: { combination: HotKeyCombination }) => {
  return (
    <div>
      {(combination.modifiers || [[]]).map((m) => (
        <HotKey
          key={`${combination.key}-${JSON.stringify(m)}`}
          hotkey={combination.key}
          modifiers={m}
        />
      ))}
    </div>
  );
};

// eslint-disable-next-line react/display-name
const MagicListElement: React.FunctionComponent<Props> = forwardRef<any, Props>(
  ({ handler, isSelected }: Props, ref) => {
    const nodeRef = useRef<any>(null);
    const { isDesktop } = useClientType();

    const handleMagic = useCallback(() => {
      handler.handler.action();
      // execute({ navigate: url => history.push(url, { direction: 'root' }) });
    }, [handler]);

    useImperativeHandle(ref, () => ({
      impress() {
        handleMagic();
      },
      scrollIntoView() {
        nodeRef.current &&
          nodeRef.current.scrollIntoView({ block: 'end', behavior: 'auto' });
      },
      canMove(): boolean {
        return false;
      },
      moveDown() {
        return undefined;
      },
      moveUp() {
        return undefined;
      },
      getHandlers() {
        return undefined;
      },
    }));

    return (
      <IonItem
        ref={nodeRef}
        color={isSelected ? 'light' : undefined}
        style={{ cursor: 'pointer' }}
        onClick={(e: any) => {
          e.preventDefault();
          handleMagic();
        }}
      >
        <IonLabel style={desktopListStyles.listRow}>
          {isDesktop && (
            <TableCell className="hotkey-list-combo">
              <HotKeyCombo combination={handler.handler.combination} />
            </TableCell>
          )}

          <TableCell className="hotkey-list-name">{handler.handler.name}</TableCell>

          <TableCell className="hotkey-list-category">
            <span style={genericStyles.grayOutColor}>{handler.categoryName}</span>
          </TableCell>
        </IonLabel>
      </IonItem>
    );
  }
);

export default MagicListElement;
